import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  Textarea as TextareaComponent,
  TextareaValidateOption,
} from '~/shared/components/parts/Textarea/Textarea';
import { getProperty } from '../common';

export interface TextareaProps {
  name: string;
  className?: string;
  labelId?: string;
  value?: string;
  disabled?: boolean;
  isCounter?: boolean;
  validator?: (v: string) => string[];
  validateOption?: TextareaValidateOption;
  properties?: Array<Property>;
  columns?: Array<string>;
  isParentDeliver?: boolean; // 入力イベントをリアルタイムで親に変更を伝えるかどうかを指定
  onChangeState?: (arg: string) => void;
}

export function Textarea(props: TextareaProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <TextareaComponent
        name={props.name}
        className={props.className}
        value={props.value}
        isCounter={props.isCounter}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        isParentDeliver={props.isParentDeliver}
        onChangeState={props.onChangeState}
      ></TextareaComponent>
    </div>
  );
}
