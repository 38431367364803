import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { SimpleListDialog } from '~/shared/components/ui/Dialog/SimpleListDialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sampleData: any[] = new Array(15).fill(null).map((v, i) => ({
  id: i,
  code: `E000${i}A`,
  request_unit_display_name: `〇〇製作所（E000${i}A0${i}）`,
  to: `xxxx(xxxxx@xxx.mail.com),yyyy(yyyy@mail.com)`,
}));

export const UISampleSimpleListDialogs = () => {
  const [isEstimateUnit, setEstimateUnitShow] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">依頼先情報ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEstimateUnitShow(true);
              }}
            />
            <SimpleListDialog
              isOpen={isEstimateUnit}
              messageOption={{
                headerLabelId: {
                  id: 'estimateUnit',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              data={sampleData}
              actionOption={{}}
              viewOption={{
                readonly: true,
                previewRowCount: 6,
                keyColumn: 'id',
                columns: [
                  {
                    header: {
                      id: 'code',
                      prefixId: 'estimateUnitDialog',
                    },
                    propertyName: 'code',
                    width: '8rem',
                  },
                  {
                    header: {
                      id: 'request_unit_display_name',
                      prefixId: 'estimateUnitDialog',
                    },
                    propertyName: 'request_unit_display_name',
                    width: '18rem',
                  },
                  {
                    header: {
                      id: 'to',
                      prefixId: 'estimateUnitDialog',
                    },
                    propertyName: 'to',
                  },
                ],
              }}
              onCancel={() => {
                setEstimateUnitShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output"></pre>
          </div>
        </div>
      </div>
    </div>
  );
};
