import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { CompanySearch } from './pages/CompanySearch';
import { CompanyList } from './pages/CompanyList';
import { CompanyConfirmation } from './pages/CompanyConfirmation';

export const CompanyRoutes = () => {
  return (
    <Routes>
      <Route
        path="/search"
        element={
          <RequireAuth>
            <CompanySearch />
          </RequireAuth>
        }
      />
      <Route
        path="/list"
        element={
          <RequireAuth>
            <CompanyList />
          </RequireAuth>
        }
      />
      <Route
        path="/confirmation"
        element={
          <RequireAuth>
            <CompanyConfirmation />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
