import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import { FileItem } from '~/shared/components/file';
import {
  ConfirmationDialog,
  ListView,
  ViewMenu,
  InitialFilter,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  getJoinedAndAltColumnSchema,
  getJoinedUserNameEmailSchema,
  getLongDateFormatterSchema,
} from '~/shared/components/ui';
import {
  FormSettingDialog,
  FormSettingDialogResult,
} from '~/shared/components/ui/Dialog/FormSettingDialog';
import {
  BuildedInputFormDialog,
  BuildedInputFormDialogRef,
} from '~/shared/components/ui/FormBuilder';
import {
  FormValuesTypeNames,
  getFileName,
} from '~/shared/components/ui/FormBuilder/utils';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  CommmonAttachedFileSystemCode,
  FullMethodName_ListSampleWorkForms,
  ViewId,
  autoDownloadErrorFile,
  autoDownloadFileOnlyName,
  getExceptionMessage,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'IFM_SAMPLE_FORM_DEFINITION_LIST';
const SAMPLE_ASSET_SYSTEM_CODE: CommmonAttachedFileSystemCode = 'B10';
const TYPE_NAME = 'mtechnavi.api.form.SampleWorkForm' as FormValuesTypeNames;
type SmapleWorkForm = mtechnavi.api.form.ISampleWorkForm; //変更予定
export const IfmSampleFormDefinitionList = () => {
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const formInputDialogRef =
    useRef<BuildedInputFormDialogRef<SmapleWorkForm>>(null);
  const myEmail = useAuth().user?.email ?? '';
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '削除' },
  };
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });
  const [isFormSettingDialogShow, setFormSettingDialogShow] = useState(false);
  const [isReload, setReload] = useState(false);
  const sampleWorkFormList = useRef<SmapleWorkForm[]>([]);
  const deleteIds = useRef<string[]>([]);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  //サンプル入力確認ダイアログProps
  const [headerMessageID, setHeaderMessageID] = useState('');
  // 入力ダイアログ用
  const [inputSampleForm, setInputSampleForm] =
    useState<mtechnavi.api.form.ISampleWorkForm>();
  const [editValues, setEditValues] = useState<mtechnavi.api.form.IFormValue[]>(
    []
  );

  // メニューの「登録」処理
  const handleMenuInput = () => {
    setFormSettingDialogShow(true);
  };

  // メニューの「確認」処理
  const handleMenuConfirm = async (ids?: string[]) => {
    if (!ids || !ids.length) {
      return;
    }
    const sampleForm = sampleWorkFormList.current
      ?.filter((item) => ids?.includes(item.sampleWorkFormId || ''))
      .at(0) as mtechnavi.api.form.ISampleWorkForm | undefined;
    if (!sampleForm) {
      return;
    }
    try {
      setLoading(true);
      const [form, formSetting, formSettingItems, formValues] =
        await getFormDetail(sampleForm);

      setHeaderMessageID('sampleFormConfirmationDialog');
      formInputDialogRef.current?.open(
        form,
        formSetting,
        formSettingItems,
        sampleForm,
        SAMPLE_ASSET_SYSTEM_CODE,
        formValues,
        false,
        false
      );
    } catch (err) {
      getExceptionMessage(intl, err);
    } finally {
      setLoading(false);
    }
  };

  // メニューの「入力」処理
  const handleMenuEdit = async (
    ids?: string[],
    newAddedForm?: mtechnavi.api.form.ISampleWorkForm
  ) => {
    const sampleForm =
      (sampleWorkFormList.current
        ?.filter((item) => ids?.includes(item.sampleWorkFormId || ''))
        .at(0) as mtechnavi.api.form.ISampleWorkForm | undefined) ??
      newAddedForm;
    if (!sampleForm) {
      return;
    }
    setInputSampleForm(sampleForm);
    try {
      setLoading(true);
      const [form, formSetting, formSettingItems, formValues] =
        await getFormDetail(sampleForm);
      setEditValues(formValues);

      setHeaderMessageID('sampleFormInputDialog');
      formInputDialogRef.current?.open(
        form,
        formSetting,
        formSettingItems,
        sampleForm,
        SAMPLE_ASSET_SYSTEM_CODE,
        formValues,
        true,
        true
      );
    } catch (err) {
      getExceptionMessage(intl, err);
    } finally {
      setLoading(false);
    }
  };

  const getFormDetail = async (
    sampleForm: mtechnavi.api.form.ISampleWorkForm
  ) => {
    const forms = (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: 'mtechnavi.api.form.FormService/ListForms',
      filter: {
        formId: { $eq: sampleForm.formId || '' },
      },
      sort: [],
    })) as mtechnavi.api.form.ListFormsResponse;
    const formSettings = await window.App.services.formService.listFormSettings(
      {
        formIds: [sampleForm.formId || ''],
      }
    );
    const formSettingItems = (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: 'mtechnavi.api.form.FormService/ListFormSettingItems',
      filter: {},
      requestBody: {
        formIds: [sampleForm.formId || ''],
      },
      sort: [{ 'item.sort': 'asc' }],
    })) as mtechnavi.api.form.ListFormSettingItemsResponse;
    const formValues = await window.App.services.formService.listFormValues({
      recordId: sampleForm.sampleWorkFormId,
      typeName: TYPE_NAME,
    });
    return [
      forms.items.at(0) || {},
      formSettings.items.at(0) || {},
      formSettingItems.items,
      formValues.items,
    ] as const;
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const handleMenuDelete = async () => {
    const deleteItems = sampleWorkFormList.current.filter((item) =>
      deleteIds.current.includes(item.sampleWorkFormId ?? '')
    );
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteSampleWorkForm',
        request: deleteItems,
      });
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteFormWithRelatedItems',
        request: deleteItems.map((v) => {
          if (v.formId) {
            return v.formId;
          }
        }) as string[],
      });
      success([toastSuccess]);
      clearCheckBox();
      setReload(true);
    } catch (err) {
      getWorkerExceptionMessage(intl, err);
    }
    setMenuModalIsOpen(false);
    setLoading(false);
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: () => handleMenuInput(),
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        deleteIds.current = v as string[];
        setMenuModalIsOpen(true);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // 編集
    menuItems.push({
      name: 'edit',
      func: (v?: string[]) => handleMenuEdit(v),
    });
    return menuItems;
  };

  // フォーム設定ダイアログの「確定」時処理
  const handleFormSettingDecision = async (result: FormSettingDialogResult) => {
    setLoading(true);
    setReload(false);
    try {
      const generatedForm = (await window.App.services.ui.worker.apiCall({
        actionName: 'generateForm',
        request: {
          baseFormId: result.baseFormId,
        },
      })) as mtechnavi.api.form.IGenerateFormResponse[];
      const formData = generatedForm.at(0)?.form;
      const resultSwf = (await window.App.services.ui.worker.apiCall({
        actionName: 'createSampleWorkForm',
        request: {
          sampleWorkForm: {
            formId: formData?.formId,
            baseDisplayName: formData?.formProperties?.displayName,
            managementOrganization:
              formData?.formProperties?.managementOrganization,
            displayName: result.displayName,
            formType1: formData?.formProperties?.formType1,
            formType2: formData?.formProperties?.formType2,
            formType3: formData?.formProperties?.formType3,
            attribute1: result.attribute1,
            attribute2: result.attribute2,
            attribute3: result.attribute3,
            notice: result.notice,
            required: result.required,
          },
        },
      })) as mtechnavi.api.form.SampleWorkForm[];
      success([toastSuccess]);
      setReload(true);
      setFormSettingDialogShow(false);
      if (resultSwf.at(0)?.sampleWorkFormId) {
        handleMenuEdit(
          [],
          resultSwf.at(0) as mtechnavi.api.form.ISampleWorkForm
        );
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // フォーム入力ダイアログの「保存」処理
  const handleFormInputDecision = async (
    formId: string,
    formValues?: mtechnavi.api.form.IFormValue[] | undefined,
    isDecision?: boolean
  ) => {
    setLoading(true);
    setReload(false);

    const items = (formValues || []).map(
      (newValue): mtechnavi.api.form.IFormValue => {
        const oldValue = editValues?.find(
          (val) => val.formSettingItemId === newValue.formSettingItemId
        );
        if (!oldValue) {
          return {
            formId: formId,
            formSettingItemId: newValue.formSettingItemId,
            itemAutoName: newValue.itemAutoName,
            typeName: TYPE_NAME,
            recordId: inputSampleForm?.sampleWorkFormId,
            input: newValue.input,
          };
        }
        // 以前の値があったらそれをベースにマージする
        return {
          formValueId: oldValue.formValueId,
          formId: formId,
          formSettingItemId: newValue.formSettingItemId,
          itemAutoName: newValue.itemAutoName,
          urn: `${newValue.typeName}:${inputSampleForm?.sampleWorkFormId}`,
          typeName: TYPE_NAME,
          recordId: inputSampleForm?.sampleWorkFormId,
          input: newValue.input,
          updatedAt: oldValue.updatedAt,
        };
      }
    );

    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveFormValue',
        request: { items },
      });

      const status = window.App.services.ui
        .getNameOptionWithSystemName('A5010001', isDecision ? 'B01' : 'B00')
        .at(0);

      await window.App.services.ui.worker.apiCall({
        actionName: 'updateSampleWorkForm',
        request: {
          sampleWorkForm: {
            ...inputSampleForm,
            status: status,
          },
        },
      });

      success([toastSuccess]);
      setEditValues([]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // フォーム入力ダイアログの「ダウンロード」処理
  const handleFormInputDownload = async (
    workForm?: mtechnavi.api.form.ISampleWorkForm
  ) => {
    if (!workForm?.sampleWorkFormId) {
      return;
    }
    try {
      const assetId = (await window.App.services.ui.worker.apiCall({
        actionName: 'exportSampleWorkForms',
        request: workForm?.sampleWorkFormId,
      })) as mtechnavi.api.form.IExportSampleWorkFormsResponse[];
      if (assetId?.length === 1 && assetId[0].assetId) {
        autoDownloadFileOnlyName(
          workForm.displayName || '',
          assetId[0].assetId || ''
        );
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  // フォーム入力ダイアログの「インポート」処理
  const handleFormInputImport = async (
    result: FileItem[],
    workForm?: mtechnavi.api.form.ISampleWorkForm
  ) => {
    if (result.length === 0 || !result[0].url) {
      return;
    }
    const assetId = (await window.App.services.ui.worker.apiCall({
      actionName: 'importSampleWorkForms',
      request: result[0].url,
    })) as mtechnavi.api.form.IImportSampleWorkFormsResponse[];
    if (assetId && assetId.length > 0 && assetId[0].assetId) {
      // アップロードファイル名の拡張子部分を除いたものをファイル名とする
      autoDownloadErrorFile(
        getFileName(result[0].file.name || ''),
        'csv',
        assetId[0].assetId || ''
      );
      error([GetMessageWithIntl(intl, { id: 'E1000018' })]);
      return;
    }
    const updatedFormValues =
      await window.App.services.formService.listFormValues({
        recordId: workForm?.sampleWorkFormId,
        typeName: TYPE_NAME,
      });
    return updatedFormValues.items;
  };

  useEffect(() => {
    (async () => {
      try {
        const filter: InitialFilter = {
          info: [],
        };
        const userOrganizationUnits =
          await window.App.services.ui.getUserOrganizationUnits(myEmail);
        userOrganizationUnits.length &&
          userOrganizationUnits[0].componentUnit?.displayNameLang &&
          filter.info.push({
            targetKey: 'managementOrganization.displayNameLang',
            targetValue:
              userOrganizationUnits[0].componentUnit?.displayNameLang?.ja,
          });

        filter.info.push({
          targetKey: 'updatedProperties.updatedBy.email',
          targetValue: myEmail,
        });

        setInitialFilter(filter);

        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListSampleWorkForms,
          ]); //後で変更

        // ja変換
        const jaColumn: string[] = [
          'formType1.displayNameLang',
          'formType2.displayNameLang',
          'formType3.displayNameLang',
          'managementOrganization.displayNameLang',
          'status.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');

        // Boolean'○' : '-'に変換
        const booleanColumn = ['usable'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );

        const joinedUserNameSch = getJoinedUserNameEmailSchema(
          booleanSch,
          'updatedProperties.updatedBy.displayName',
          'updatedProperties.updatedBy.email'
        );

        const joinedSch = getJoinedAndAltColumnSchema(
          joinedUserNameSch,
          [
            'formType1.displayNameLang',
            'formType2.displayNameLang',
            'formType3.displayNameLang',
          ],
          [
            `formType1.displayNameLang.${intl.locale}`,
            `formType2.displayNameLang.${intl.locale}`,
            `formType3.displayNameLang.${intl.locale}`,
          ],
          ' / ',
          true
        );

        const longToDateSch = getLongDateFormatterSchema(joinedSch, [
          'updatedProperties.updatedAt',
        ]);

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(longToDateSch);
        setPreset(preset);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    })();
    // 初回起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // 後日修正
  const onOriginalItem = (items: unknown) => {
    sampleWorkFormList.current = items as unknown as SmapleWorkForm[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="sampleWorkFormList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'sampleWorkFormId',
              headerTitle: { viewId: VIEW_ID, prefixId: 'header' },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListSampleWorkForms}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
          />
          <ConfirmationDialog
            isOpen={menuModalIsOpen}
            viewMessage={deleteMessage}
            messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
            onDecision={() => {
              handleMenuDelete();
            }}
            onCancel={() => setMenuModalIsOpen(false)}
          />
          {/* フォーム設定ダイアログ */}
          <FormSettingDialog
            isOpen={isFormSettingDialogShow}
            messageOption={{
              headerLabelId: {
                id: 'form-setting',
                prefixId: 'DIALOG_TITLE',
              },
            }}
            onDecision={handleFormSettingDecision}
            onCancel={() => setFormSettingDialogShow(false)}
          />
          <BuildedInputFormDialog
            messageOption={{
              headerLabelId: { prefixId: 'FORM_BUILDER', id: headerMessageID },
            }}
            ref={formInputDialogRef}
            onDownload={handleFormInputDownload}
            onImport={handleFormInputImport}
            onDecision={handleFormInputDecision}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
