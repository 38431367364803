import { IntlShape } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { FormSettingDialogResult } from '~/shared/components/ui/Dialog/FormSettingDialog';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { nameOptionToLocaleString } from '~/shared/utils';

export const SURVEY_PURPOSES_CODE = '2';

/**
 * ベースフォーム構成情報
 */
export interface BaseFormConfigurations {
  baseFormList: mtechnavi.api.form.IBaseForm[];
  baseFormSettingList?: mtechnavi.api.form.IBaseFormSetting[];
  baseFormSettingItemList?: mtechnavi.api.form.IBaseFormSettingItem[];
}

/**
 * フォーム構成情報
 */
export interface FormConfigurations {
  formList: mtechnavi.api.form.IForm[];
  formSettingList: mtechnavi.api.form.IFormSetting[];
  formSettingItemList: mtechnavi.api.form.IFormSettingItem[];
  formValueList: mtechnavi.api.form.IFormValue[];
  targetId: string;
}

/**
 * 依頼フォームの付属情報・フォーム構成・入力値をまとめて持つための定義
 */
export interface SurveyRequestWorkFormWithValues {
  type: 'request';
  /**
   * ID
   */
  id?: string;
  /**
   * 入力状況
   */
  statusName?: string;
  /**
   * フォーム名
   */
  displayName?: string;
  /**
   * 情報１
   */
  attribute1?: string;
  /**
   * 情報２
   */
  attribute2?: string;
  /**
   * 情報３
   */
  attribute3?: string;
  /**
   * 業務フォーム情報(未保存)
   */
  tempForm?: FormSettingDialogResult;
  /**
   * 業務フォーム情報
   */
  workForm?: mtechnavi.api.survey.ISurveyRequestWorkForm;
  /**
   * フォーム情報
   */
  form?: mtechnavi.api.form.IForm;
  /**
   * フォーム構成情報
   */
  formSetting?: mtechnavi.api.form.IFormSetting;
  /**
   * フォーム構成情報
   */
  formSettingItems?: mtechnavi.api.form.IFormSettingItem[];
  /**
   * フォーム入力値
   */
  formValues?: mtechnavi.api.form.IFormValue[];
  /**
   * 表示順
   */
  order?: Long.Long | null;
}

/**
 * 依頼側ステータス欄テキスト作成
 */
export interface RequestWorkFormDisplayOption {
  intl: IntlShape;
  notEnteredStatus?: sharelib.INameOption | null;
  enteredStatus?: sharelib.INameOption | null;
}

/**
 * フォーム関連の永続データから画面用のデータを復元する
 */
export const convertToRequestWorkFormWithValues = (
  requestFormList: mtechnavi.api.survey.ISurveyRequestWorkForm[],
  formConfig: FormConfigurations,
  displayOption: RequestWorkFormDisplayOption
) => {
  const {
    formList,
    formSettingList,
    formSettingItemList,
    formValueList,
    targetId,
  } = formConfig;
  const { intl, enteredStatus, notEnteredStatus } = displayOption;
  return requestFormList.map((requestForm): SurveyRequestWorkFormWithValues => {
    const formId = requestForm.formId;
    const form = formList.find((item) => item?.formId === formId);
    const formSetting = formSettingList.find((item) => item?.formId === formId);
    const formSettingItems = formSettingItemList.filter(
      (item) => item?.formId === formId
    );
    const formValues = formValueList.filter(
      (item) =>
        item?.formId === formId &&
        ((item.urn ?? '').split(':')?.at(1) || '') === targetId
    );

    return {
      type: 'request',
      id: requestForm.surveyRequestWorkFormId || '',
      statusName:
        formValues.length > 0
          ? nameOptionToLocaleString(intl, enteredStatus)
          : nameOptionToLocaleString(intl, notEnteredStatus),
      displayName: requestForm.displayName || '',
      attribute1: requestForm.attribute1 || '',
      attribute2: requestForm.attribute2 || '',
      attribute3: requestForm.attribute3 || '',
      form,
      tempForm: {
        baseFormId: form?.baseFormId || '',
        baseDisplayName: form?.formProperties?.displayName || '',
        displayName: requestForm.displayName || '',
        attribute1: requestForm.attribute1 || '',
        attribute2: requestForm.attribute2 || '',
        attribute3: requestForm.attribute3 || '',
        notice: requestForm.notice || '',
        required: requestForm.required || false,
      },
      workForm: requestForm,
      formSetting,
      formSettingItems,
      formValues,
      order: requestForm.order,
    };
  });
};

/**
 * 回答フォームの付属情報・フォーム構成・入力値をまとめて持つための定義
 * (依頼側)
 */
export interface SurveyResultWorkFormWithValues {
  type: 'result';
  /**
   * ID
   */
  id?: string;
  /**
   * フォーム名
   */
  displayName?: string;
  /**
   * 情報１
   */
  attribute1?: string;
  /**
   * 情報２
   */
  attribute2?: string;
  /**
   * 情報３
   */
  attribute3?: string;
  /**
   * 代行
   */
  agencyLabel?: string;
  /**
   * 回答状況ラベル
   */
  answerLabel?: string;
  /**
   * 受信側回答を持つかどうか
   */
  isReplied?: boolean;
  /**
   * 受信側回答完了かどうか
   */
  isComplete?: boolean;
  /**
   * 必須
   */
  requiredLabel?: string;
  /**
   * 業務フォーム情報(未保存)
   */
  tempForm?: FormSettingDialogResult;
  /**
   * 業務フォーム情報
   */
  workForm?: mtechnavi.api.survey.ISurveyResultWorkForm;
  /**
   * フォーム情報
   */
  form?: mtechnavi.api.form.IBaseForm | mtechnavi.api.form.IForm;
  /**
   * フォーム構成情報
   */
  formSetting?:
    | mtechnavi.api.form.IBaseFormSetting
    | mtechnavi.api.form.IFormSetting;
  /**
   * フォーム構成情報
   */
  formSettingItems?:
    | mtechnavi.api.form.IBaseFormSettingItem[]
    | mtechnavi.api.form.IFormSettingItem[];
  /**
   * フォーム入力値
   */
  formValues?: mtechnavi.api.form.IFormValue[];
  /**
   * 表示順
   */
  order?: Long.Long | null;
}

/**
 * フォーム関連の永続データから画面用のデータを復元する
 * 展開前の回答フォーム
 */
export const convertToPlanResultWorkFormWithValues = (
  resultFormList: mtechnavi.api.survey.ISurveyResultWorkForm[],
  baseFormConfig: BaseFormConfigurations
) => {
  const { baseFormList, baseFormSettingList, baseFormSettingItemList } =
    baseFormConfig;
  return resultFormList.map((resultForm): SurveyResultWorkFormWithValues => {
    const baseForm = (baseFormList || []).find(
      (baseForm) => baseForm.baseFormId === resultForm?.baseFormId
    );
    const baseFormId = resultForm.baseFormId;
    const baseFormSetting = (baseFormSettingList || []).find(
      (item) => item?.baseFormId === baseFormId
    );
    const baseFormSettingItems = (baseFormSettingItemList || []).filter(
      (item) => item?.baseFormId === baseFormId
    );
    return {
      type: 'result',
      id: resultForm.surveyResultWorkFormId || '',
      displayName: resultForm.displayName || '',
      attribute1: resultForm.attribute1 || '',
      attribute2: resultForm.attribute2 || '',
      attribute3: resultForm.attribute3 || '',
      agencyLabel: '',
      answerLabel: '',
      requiredLabel: resultForm.required ? '○' : '-',
      workForm: resultForm,
      tempForm: {
        baseFormId: resultForm?.baseFormId || '',
        baseDisplayName: baseForm?.formProperties?.displayName || '',
        displayName: resultForm.displayName || '',
        attribute1: resultForm.attribute1 || '',
        attribute2: resultForm.attribute2 || '',
        attribute3: resultForm.attribute3 || '',
        notice: resultForm.notice || '',
        required: resultForm.required || false,
      },
      form: baseForm,
      formSetting: baseFormSetting,
      formSettingItems: baseFormSettingItems,
      order: resultForm.order,
    };
  });
};

/**
 * フォーム関連の永続データから画面用のデータを復元する
 * 展開後の回答フォーム(依頼側)
 */
export const convertToActualResultWorkFormWithValues = (
  resultFormList: mtechnavi.api.survey.ISurveyResultWorkForm[],
  formConfig: FormConfigurations,
  lockedFormIds: string[],
  hasChangeRequest: boolean
) => {
  const {
    formList,
    formSettingList,
    formSettingItemList,
    formValueList,
    targetId,
  } = formConfig;
  return resultFormList.map((resultForm): SurveyResultWorkFormWithValues => {
    const form = (formList || []).find(
      (item) => item.formId === resultForm?.formId
    );
    const formId = resultForm.formId || '';
    const formSetting = (formSettingList || []).find(
      (item) => item?.formId === formId
    );
    const formSettingItems = (formSettingItemList || []).filter(
      (item) => item?.formId === formId
    );
    const allFormValues = formValueList.filter(
      (item) =>
        item?.formId === formId &&
        ((item.urn ?? '').split(':')?.at(1) || '') === targetId
    );
    const sharedReplyValues = allFormValues.filter(
      (values) => !!values.sharedProperties?.sharedAt
    );
    const agencyReplyValues = allFormValues.filter(
      (values) => !values.sharedProperties?.sharedAt
    );
    const isProxyReply =
      sharedReplyValues.length === 0 && agencyReplyValues.length > 0;
    // 代行判定の時だけ代行回答の入力値をセットする
    const formValues = isProxyReply ? agencyReplyValues : sharedReplyValues;
    // シェアされた入力値があれば、受信側回答ありとする
    const isReplied = sharedReplyValues.length > 0;
    // 変更依頼がなく確定していて入力値がシェアされたものであれば回答完了済とする
    const isComplete =
      !hasChangeRequest &&
      lockedFormIds.includes(formId) &&
      formValues.some((value) => !!value.sharedProperties?.sharedAt);
    return {
      type: 'result',
      id: resultForm.surveyResultWorkFormId || '',
      displayName: resultForm.displayName || '',
      attribute1: resultForm.attribute1 || '',
      attribute2: resultForm.attribute2 || '',
      attribute3: resultForm.attribute3 || '',
      agencyLabel: isProxyReply ? '[代行]' : '',
      answerLabel: isComplete ? '○' : '-',
      isComplete,
      isReplied,
      requiredLabel: resultForm.required ? '○' : '-',
      workForm: resultForm,
      tempForm: {
        baseFormId: resultForm?.baseFormId || '',
        baseDisplayName: form?.formProperties?.displayName || '',
        displayName: resultForm.displayName || '',
        attribute1: resultForm.attribute1 || '',
        attribute2: resultForm.attribute2 || '',
        attribute3: resultForm.attribute3 || '',
        notice: resultForm.notice || '',
        required: resultForm.required || false,
      },
      form,
      formSetting,
      formSettingItems,
      formValues,
      order: resultForm.order,
    };
  });
};

/**
 * 回答フォームの付属情報・フォーム構成・入力値をまとめて持つための定義
 * (受信側)
 */
export interface ReceptionSurveyResultWorkFormWithValues {
  type: 'result';
  /**
   * ID
   */
  id?: string;
  /**
   * フォーム名
   */
  displayName?: string;
  /**
   * 情報１
   */
  attribute1?: string;
  /**
   * 情報２
   */
  attribute2?: string;
  /**
   * 情報３
   */
  attribute3?: string;
  /**
   * ステータスラベル
   */
  statusLabel?: string;
  /**
   * 必須
   */
  requiredLabel?: string;
  /**
   * 編集不可
   */
  disabled?: boolean;
  /**
   * 業務フォーム情報(未保存)
   */
  tempForm?: FormSettingDialogResult;
  /**
   * 業務フォーム情報
   */
  workForm?: mtechnavi.api.survey.ISurveyResultWorkForm;
  /**
   * フォーム情報
   */
  form?: mtechnavi.api.form.IForm;
  /**
   * フォーム構成情報
   */
  formSetting?: mtechnavi.api.form.IFormSetting;
  /**
   * フォーム構成情報
   */
  formSettingItems?: mtechnavi.api.form.IFormSettingItem[];
  /**
   * フォーム入力値
   */
  formValues?: mtechnavi.api.form.IFormValue[];
  /**
   * 表示順
   */
  order?: Long.Long | null;
}

export interface ResultWorkFormDisplayOption {
  intl: IntlShape;
  completeStatus?: mtechnavi.api.programoption.IProgramOption | null;
  incompleteStatus?: mtechnavi.api.programoption.IProgramOption | null;
}

/**
 * フォーム関連の永続データから画面用のデータを復元する
 * 回答フォーム(受信側)
 */
export const convertToResultWorkFormWithValues = (
  resultFormList: mtechnavi.api.survey.ISurveyResultWorkForm[],
  formConfig: FormConfigurations,
  lockedFormIds: string[],
  sharedFormIds: string[],
  displayOption: ResultWorkFormDisplayOption
) => {
  const {
    formList,
    formSettingList,
    formSettingItemList,
    formValueList,
    targetId,
  } = formConfig;
  return resultFormList.map(
    (resultForm): ReceptionSurveyResultWorkFormWithValues => {
      const form = (formList || []).find(
        (item) => item.formId === resultForm?.formId
      );
      const formId = resultForm.formId || '';
      const formSetting = (formSettingList || []).find(
        (item) => item?.formId === formId
      );
      const formSettingItems = (formSettingItemList || []).filter(
        (item) => item?.formId === formId
      );
      const formValues = formValueList.filter(
        (item) =>
          item?.formId === formId &&
          ((item.urn ?? '').split(':')?.at(1) || '') === targetId
      );

      const disabled =
        sharedFormIds.includes(formId) && lockedFormIds.includes(formId);
      return {
        type: 'result',
        id: resultForm.surveyResultWorkFormId || '',
        displayName: resultForm.displayName || '',
        attribute1: resultForm.attribute1 || '',
        attribute2: resultForm.attribute2 || '',
        attribute3: resultForm.attribute3 || '',
        statusLabel: toReceiverReceptionStatusText(
          formId,
          lockedFormIds,
          sharedFormIds,
          displayOption
        ),
        requiredLabel: resultForm.required ? '○' : '-',
        disabled,
        workForm: resultForm,
        tempForm: {
          baseFormId: resultForm?.baseFormId || '',
          baseDisplayName: form?.formProperties?.displayName || '',
          displayName: resultForm.displayName || '',
          attribute1: resultForm.attribute1 || '',
          attribute2: resultForm.attribute2 || '',
          attribute3: resultForm.attribute3 || '',
          notice: resultForm.notice || '',
          required: resultForm.required || false,
        },
        form,
        formSetting,
        formSettingItems,
        formValues,
        order: resultForm.order,
      };
    }
  );
};

/**
 * 回答側ステータス欄テキスト作成
 */
export const toReceiverReceptionStatusText = (
  formId: string,
  lockedFormIds: string[],
  sharedFormIds: string[],
  displayOption: ResultWorkFormDisplayOption
) => {
  const { intl, incompleteStatus, completeStatus } = displayOption;
  let statusLabel = '';
  if (!lockedFormIds?.includes(formId)) {
    statusLabel = nameOptionToLocaleString(intl, incompleteStatus);
  } else {
    statusLabel = nameOptionToLocaleString(intl, completeStatus);
    if (!sharedFormIds?.includes(formId)) {
      statusLabel += `(${GetMessageWithIntl(displayOption.intl, {
        prefixId: 'SURVEY_REQUEST_RECEIVER_CONFIRMATION',
        id: 'unsent',
      })})`;
    }
  }
  return statusLabel;
};

/** 依頼フォームかどうかの判定 */
export const isSurveyRequestWorkFormWithValues = (
  workFormWithValues?:
    | SurveyRequestWorkFormWithValues
    | SurveyResultWorkFormWithValues
    | null
): workFormWithValues is SurveyRequestWorkFormWithValues => {
  return workFormWithValues?.type === 'request';
};

/** 回答フォームかどうかの判定 */
export const isSurveyResultWorkFormWithValues = (
  workFormWithValues?:
    | SurveyRequestWorkFormWithValues
    | SurveyResultWorkFormWithValues
    | null
): workFormWithValues is SurveyResultWorkFormWithValues => {
  return workFormWithValues?.type === 'result';
};
