import { useIntl } from 'react-intl';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  CaptionButton,
  IconButton,
  IconWithCaptionButton,
} from '../../ui/Button';
import { ForumThreadComment } from './ForumThreadComment';
import {
  getWorkerExceptionMessage,
  includeInputValidateError,
  nameOptionToLocaleString,
} from '~/shared/utils';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { useEffect, useRef, useState } from 'react';
import { error } from '../../parts/Toast/Toast';
import { Textarea, Textbox } from '../../ui';
import { ForumLatestComment } from './ForumLatestComment';
import { getGenerallyIconElement } from '../../parts/Button/GenerallyIcons';
import {
  ForumCommentMaxLength,
  ForumMaxCommentCount,
  ForumTitleMaxLength,
  ForumPrefixId as prefixId,
} from './utils/util';
import './ForumThread.css';

export interface ForumThreadInputOption {
  thread?: mtechnavi.api.forum.IThread;
  commentList: mtechnavi.api.forum.IComment[];
  markerList: mtechnavi.api.forum.IMarker[];
}

export interface ForumThreadProps {
  isOpen?: boolean;
  isParentWorking?: boolean;
  myCompanyId?: string;
  unreadMarker?: sharelib.INameOption;
  inputOption: ForumThreadInputOption;
  onThreadOpen: (threadId: string) => void;
  onThreadClose: () => void;
  onEditThread: (thread: mtechnavi.api.forum.IThread) => Promise<void> | void;
  onSubmitComment: (
    comment: mtechnavi.api.forum.IComment
  ) => Promise<void> | void;
  onReadComment: (
    comment: mtechnavi.api.forum.IComment
  ) => Promise<void> | void;
  onUnReadComment: (
    comment: mtechnavi.api.forum.IComment
  ) => Promise<void> | void;
  onDeleteComment: (
    comment: mtechnavi.api.forum.IComment
  ) => Promise<void> | void;
}

/**
 * スレッドコンポーネント
 */
export const ForumThread = ({
  isOpen,
  isParentWorking,
  inputOption,
  myCompanyId,
  unreadMarker,
  onThreadOpen,
  onThreadClose,
  onEditThread,
  onSubmitComment,
  onReadComment,
  onUnReadComment,
  onDeleteComment,
}: ForumThreadProps) => {
  const { thread, commentList, markerList } = inputOption;
  const intl = useIntl();
  const [isEdit, setEdit] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [editThreadTitle, setEditThreadTitle] = useState('');
  const [draftComment, setDraftComment] = useState('');
  const [replyComment, setReplyComment] =
    useState<mtechnavi.api.forum.IComment | null>(null);
  const latestComment = commentList.at(commentList.length - 1) || {};
  const isDisabled = isWorking || isParentWorking;

  const listContainerRef = useRef<HTMLDivElement>(null);
  const commentListRef = useRef<HTMLUListElement>(null);
  const commentFormRef = useRef<HTMLDivElement>(null);

  const handleThreadOpen = () => {
    if (!onThreadOpen || !thread?.threadId) {
      return;
    }
    onThreadOpen(thread?.threadId);
  };

  const handleThreadClose = () => {
    if (!onThreadClose) {
      return;
    }
    onThreadClose();
  };

  const handleEditTitle = () => {
    setEditThreadTitle(thread?.displayName || '');
    setEdit(true);
  };

  const handleCancelThreadTitle = () => {
    setEdit(false);
    setEditThreadTitle('');
  };

  const handleSaveThreadTitle = async () => {
    if (!editThreadTitle) {
      return;
    }
    try {
      setWorking(true);
      await onEditThread({
        threadId: thread?.threadId,
        baseThreadId: thread?.baseThreadId,
        displayName: editThreadTitle,
        shareScope: thread?.shareScope,
        updatedAt: thread?.updatedAt,
      });
      setEdit(false);
      setEditThreadTitle('');
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  const handleSubmitComment = async () => {
    if (isInputError()) {
      return;
    }
    if (commentList.length >= ForumMaxCommentCount) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000136',
          value: { $1: ForumMaxCommentCount },
        }),
      ]);
      return;
    }

    try {
      setWorking(true);

      const newComment: mtechnavi.api.forum.IComment = {
        threadId: thread?.threadId,
        text: draftComment,
      };
      if (replyComment) {
        newComment.replyCommentId = replyComment.commentId;
      }
      await onSubmitComment(newComment);
      setReplyComment(null);
      setDraftComment('');

      setTimeout(() => {
        scrollToLatestComment();
      }, 0);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  const isInputError = () => {
    return includeInputValidateError(commentFormRef.current, intl, []);
  };

  const handleReply = (comment: mtechnavi.api.forum.IComment) => {
    setReplyComment(comment);
  };
  const handleCancelReply = () => {
    setReplyComment(null);
  };

  /** コメントの削除 */
  const handleDeleteComment = async (
    deletedComment: mtechnavi.api.forum.IComment
  ) => {
    await onDeleteComment(deletedComment);
  };

  /** 最新コメントが見えるようスクロール */
  const scrollToLatestComment = () => {
    if (!commentListRef.current || commentList.length === 0) {
      return;
    }
    listContainerRef.current?.scroll({
      top:
        (commentListRef.current?.getBoundingClientRect().height || 0) -
        (listContainerRef.current?.getBoundingClientRect().height || 0),
    });
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    scrollToLatestComment();
    // スレッドを表示した時だけ動作して欲しい
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className={`ForumThread ${isOpen ? 'active' : ''}`}
      id={`${thread?.threadId}`}
    >
      <div className="header">
        <div className="thread-info">
          <span className="label">
            {/* release-202406 暫定対応 */}
            {/* {nameOptionToLocaleString(intl, thread?.shareScope)} */}
            {'DM'}
          </span>
        </div>
        {!isEdit && (
          <div className="title">
            {thread?.displayName}
            {isOpen && !thread?.sharedProperties?.sharedBy && (
              <IconButton
                name="edit"
                iconType="edit"
                onClick={handleEditTitle}
              />
            )}
          </div>
        )}
        {isEdit && (
          <div className="title-edit">
            <Textbox
              type="text"
              name="displayName"
              labelId="thread.displayName"
              onChangeState={(v) => {
                setEditThreadTitle(v);
              }}
              value={editThreadTitle}
              validateOption={{
                required: true,
                maxLength: ForumTitleMaxLength,
              }}
              columns={['displayName']}
              properties={[]}
              disabled={isDisabled}
            />
            <div className="actions">
              <CaptionButton
                buttonType="cancel"
                name="cancel"
                caption={GetMessageWithIntl(intl, { id: 'cancel' })}
                onClick={handleCancelThreadTitle}
                disabled={isDisabled}
              />
              <CaptionButton
                buttonType="basic"
                name="send"
                caption={GetMessageWithIntl(intl, { id: 'decision' })}
                onClick={handleSaveThreadTitle}
                disabled={isDisabled || !editThreadTitle}
              />
            </div>
          </div>
        )}
      </div>
      <ForumLatestComment
        comment={latestComment || {}}
        myCompanyId={myCompanyId}
        replyComment={
          latestComment.replyCommentId
            ? commentList.find(
                (item) => item.commentId === latestComment.replyCommentId
              )
            : undefined
        }
      />
      {!isOpen ? (
        <>
          <IconWithCaptionButton
            className="threadOpen"
            name="showAllMessage"
            caption={GetMessageWithIntl(intl, {
              prefixId,
              id: 'openThreadDetail',
            })}
            iconType="down"
            onClick={handleThreadOpen}
          />
        </>
      ) : (
        <>
          <div className="comment-list-container" ref={listContainerRef}>
            <ul className="comment-list" ref={commentListRef}>
              {commentList.map((comment) => (
                <li key={comment.commentId}>
                  <ForumThreadComment
                    comment={comment || {}}
                    markerList={
                      markerList.filter(
                        (marker) =>
                          (marker.urn || '').split(':')?.at(1) ===
                          comment.commentId
                      ) || []
                    }
                    unreadMarker={unreadMarker}
                    myCompanyId={myCompanyId}
                    isEditable={true}
                    isParentWorking={isDisabled}
                    onReply={handleReply}
                    onRead={onReadComment}
                    onUnRead={onUnReadComment}
                    onDelete={handleDeleteComment}
                    replyComment={
                      comment.replyCommentId
                        ? commentList.find(
                            (item) => item.commentId === comment.replyCommentId
                          )
                        : undefined
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="comment-form" ref={commentFormRef}>
            {replyComment && (
              <div className="reply-comment">
                <div className="reply-detail">
                  {getGenerallyIconElement('reply')}
                  <p>{replyComment.text}</p>
                </div>
                <IconButton
                  name="cancel-reply"
                  className="cancel-reply"
                  iconType="clear"
                  onClick={handleCancelReply}
                />
              </div>
            )}
            <Textarea
              name="comment"
              className={`comment-input ${replyComment ? 'reply' : ''}`}
              labelId="comment"
              value={draftComment ?? ''}
              columns={['comment']}
              isParentDeliver={true}
              onChangeState={(v) => {
                setDraftComment(v);
              }}
              validateOption={{
                maxLength: ForumCommentMaxLength,
              }}
              disabled={isDisabled}
            />
            <div className="action">
              <CaptionButton
                buttonType="basic"
                name="submitComment"
                caption={GetMessageWithIntl(intl, { id: 'register' })}
                onClick={handleSubmitComment}
                disabled={isDisabled || !draftComment}
              />
            </div>
          </div>
          <IconWithCaptionButton
            className="threadClose"
            name="closeAllMessage"
            caption={GetMessageWithIntl(intl, {
              prefixId,
              id: 'closeThreadDetail',
            })}
            iconType="up"
            onClick={handleThreadClose}
          />
        </>
      )}
    </div>
  );
};
