import { sharelib } from '~/shared/libs/clientsdk';

// 編集可能なステータスか
export const isCanBeEdit = (status?: sharelib.INameOption | null) => {
  return ['B00', 'B02'].includes(status?.systemName ?? '');
};

// 削除可能なステータスか
export const isCanBeDelete = (status?: sharelib.INameOption | null) => {
  return ['B00', 'B02'].includes(status?.systemName ?? '');
};

// 破棄可能なステータスか
export const isCanBeDiscard = (status?: sharelib.INameOption | null) => {
  return ['B04'].includes(status?.systemName ?? '');
};

// 通知設定可能なステータスか（依頼側）
export const isCanBeNotificationSetting = (
  status?: sharelib.INameOption | null
) => {
  return ['B04', 'B05', 'B06'].includes(status?.systemName ?? '');
};

// 回答タブを確認可能なステータスか（依頼側）
export const isCanBeVisibleResult = (status?: sharelib.INameOption | null) => {
  return !['B00', 'B01', 'B02'].includes(status?.systemName ?? '');
};

// 社内メモ設定可能なステータスか
export const isCanBeCompanyMemo = (status?: sharelib.INameOption | null) => {
  return ['B04', 'B05', 'B06'].includes(status?.systemName ?? '');
};

// 修正依頼可能なステータスか
//  ※見積依頼詳細ステータスでのチェック（確認画面用）
export const isCanBeChangeRequest = (status?: sharelib.INameOption | null) => {
  return ['B06', 'B11'].includes(status?.systemName ?? '');
};

/** 発注出力可能なステータスか */
export const isCanBeExportOrder = (status?: string | null) => {
  return ['B07', 'B08', 'B09', 'B10', 'B12'].includes(status || '');
};

/** 選考可能なステータスか */
export const isCanBeSelection = (status?: string | null) => {
  return ['B06', 'B07', 'B09'].includes(status || '');
};

// 完了可能なステータスか
export const isCanBeComplete = (status?: sharelib.INameOption | null) => {
  return ['B04'].includes(status?.systemName ?? '');
};

// 回答編集可能なステータスか
//  ※見積回答ステータスでのチェック
export const isCanBeEditResult = (status?: sharelib.INameOption | null) => {
  return ['B00', 'B01'].includes(status?.systemName ?? '');
};
