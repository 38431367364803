import { DummyUser } from '~/worker';
import { FilterViewQuery } from '../FilterView';
import { MenuActionType, NavigatorSelectBoxtItemType } from './commonType';
import { Selectbox } from '../Selectbox';
import { IconButton } from '../../parts/Button/IconButton';
import { useMemo } from 'react';

export type NavigatorPosition = 'header' | 'footer';

export interface NavigatorSelectBoxProps {
  items?: NavigatorSelectBoxtItemType[];
  pageSize: number;
  setPageSize: (
    e: React.SetStateAction<number>
  ) => void | ((value: string) => void);
}

export interface NavigatorProps {
  navigatorPosition?: NavigatorPosition;
  renderIconMenu?: (e: MenuActionType) => JSX.Element[] | JSX.Element;
  renderFooterButton?: () => JSX.Element;
  selectBoxProps: NavigatorSelectBoxProps;
  handleMovePage: (e: number) => void;
  currentPage: number;
  maxPage: number;
  itemSize: number;
  onReload?: (() => void) | undefined | (() => FilterViewQuery);
  handleDownload?: () => Promise<void>;
  items: {}[] | DummyUser[];
}

export function ListNavigator(props: NavigatorProps) {
  const { selectBoxProps } = props;
  const selectBoxItems = useMemo<NavigatorSelectBoxtItemType[]>(() => {
    if (selectBoxProps.items && selectBoxProps.items.length) {
      return selectBoxProps.items;
    } else {
      return [
        { value: '10', displayName: '10' },
        { value: '20', displayName: '20' },
        { value: '30', displayName: '30' },
        { value: '40', displayName: '40' },
        { value: '50', displayName: '50' },
      ];
    }
  }, [selectBoxProps]);
  return (
    <>
      <div className="navgate">
        <div className="icon-area">
          {props.navigatorPosition === 'header' &&
            props.renderIconMenu &&
            props.renderIconMenu('headerIconMenu')}
          {props.navigatorPosition === 'footer' &&
            props.renderFooterButton &&
            props.renderFooterButton()}
        </div>
        <div className="count-number">
          {props.itemSize}件中
          <label htmlFor="selectItems" className="label">
            <Selectbox
              name="filterItem"
              items={selectBoxItems}
              value={[String(selectBoxProps.pageSize)]}
              columns={['filterItem']}
              multiple={false}
              onChangeState={(e) =>
                selectBoxProps.setPageSize(parseInt(e[0] ?? 10, 10))
              }
            />
          </label>
          <span className="unit">件表示</span>
        </div>
        <div className="pages-btns">
          <IconButton
            name="firstPageIcon"
            className="list-icon"
            buttonType="basic"
            iconType="first"
            onClick={() => props.handleMovePage(1)}
            disabled={props.currentPage === 1}
          />
          <IconButton
            name="prevIcon"
            className="list-icon"
            buttonType="basic"
            onClick={() => props.handleMovePage(props.currentPage - 1)}
            iconType="prev"
            disabled={props.currentPage === 1}
          />
          <span className="page-counter">
            {props.currentPage} / {props.maxPage}
          </span>
          <IconButton
            name="nextIcon"
            className="list-icon"
            buttonType="basic"
            onClick={() => props.handleMovePage(props.currentPage + 1)}
            iconType="next"
            disabled={props.currentPage === props.maxPage}
          />
          <IconButton
            name="lastPageIcon"
            className="list-icon"
            buttonType="basic"
            onClick={() => props.handleMovePage(props.maxPage)}
            disabled={props.currentPage === props.maxPage}
            iconType="last"
          />
          {props.onReload && (
            <IconButton
              name="headReloadIcon"
              className="list-icon"
              buttonType="basic"
              onClick={() => {
                props.onReload && props.onReload();
              }}
              iconType="refresh"
            />
          )}
          {props.handleDownload && (
            <IconButton
              name="headDownloadIcon"
              className="list-icon"
              buttonType="basic"
              onClick={() => {
                props.handleDownload && props.handleDownload();
              }}
              iconType="download"
            />
          )}
        </div>
      </div>
    </>
  );
}
