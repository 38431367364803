import { PropsWithoutRef } from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { IconButton } from '~/shared/components/ui/Button';
import { inputTypeOptions } from './FormItemDialog';
import './InputSelector.css';
import { GetMessage } from '~/shared/components';

export interface InputSelectorProps {
  item: mtechnavi.api.form.IFormSettingItem;
  onChangeState?: (arg: string[]) => void;
}
export const InputSelector = ({
  item,
  onChangeState,
}: PropsWithoutRef<InputSelectorProps>) => {
  return (
    <div className={`InputSelector ${item.item?.required ? 'required' : ''}`}>
      {item.item?.inputType !== 'spacer' && (
        <>
          <div className="label">
            <span className="label-text">
              {['label', 'labelMultiLine'].includes(item.item?.inputType ?? '')
                ? ''
                : item.item?.displayName}
            </span>
            {!!item.item?.description && (
              <IconButton
                name="help"
                className="HelpIcon"
                iconType="help"
                buttonType="basic"
                onClick={() => {}}
                caption={item.item?.description}
              />
            )}
          </div>
        </>
      )}
      <div className={`preview preview-${item.item?.inputType}`}>
        <select
          value={`${item.item?.inputType}`}
          onChange={(e) => {
            if (onChangeState) {
              onChangeState([e.target.value]);
            }
          }}
        >
          {inputTypeOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.displayName}
            </option>
          ))}
        </select>
      </div>
      <p className="message-area">
        {['select', 'selectMultiple'].some(
          (type) => type === item.item?.inputType
        ) &&
          !item.item?.selectItems?.length &&
          GetMessage({ id: 'E0000107' })}
        {['file', 'fileMultiple'].some(
          (type) => type === item.item?.inputType
        ) &&
          !item.item?.fileTypes?.length &&
          GetMessage({ id: 'E0000095' })}
      </p>
    </div>
  );
};
