import { useRef, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { Checkbox, Textarea, Textbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import './base.css';
import { includeInputValidateError } from '~/shared/utils';
import { ButtonType } from '~/shared/components/parts/Button/CaptionButton';

export type ContactRequestTaegetType =
  | 'documentRecipientAddress'
  | 'notificationUser';

export interface ContactRequestDialogMessageOption {
  decisionLabelId?: MessageProps;
}

export interface ContactRequestDialogInputOption {
  decisionButtonType?: ButtonType;
  id?: string;
  displayName?: string;
}

export interface ContactRequestResult {
  id?: string;
  displayName: string;
  comment: string;
  requestTargetCheck: string[];
}

export interface ContactRequestDialogProps {
  isOpen: boolean;
  messageOption: ContactRequestDialogMessageOption;
  inputOption?: ContactRequestDialogInputOption;
  onDecision: (result: ContactRequestResult) => void;
  onCancel: () => void;
}

export const ContactRequestDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onDecision,
  onCancel,
}: ContactRequestDialogProps) => {
  const intl = useIntl();
  const [displayName, setDisplayName] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [requestTargetCheck, setRequestTargetCheck] = useState<string[]>([]);
  const requiredDisplayNameArea = useRef(null);
  const [workingBlurRequestTarget, setWorkingBlurRequestTarget] =
    useState<Date>();
  const [isSendConfirmOpen, setSendConfirmOpen] = useState(false);

  const clearDisplay = () => {
    setDisplayName('');
    setComment('');
    setRequestTargetCheck([]);
    setWorkingBlurRequestTarget(undefined);
  };

  const handleCancel = () => {
    onCancel();
    clearDisplay();
  };

  const handleDecision = () => {
    const result: ContactRequestResult = {
      id: inputOption?.id,
      displayName: displayName,
      comment: comment,
      requestTargetCheck: requestTargetCheck,
    };

    onDecision(result);
    setSendConfirmOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      clearDisplay();
      setDisplayName(inputOption?.displayName ?? '');
    }
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isInputError = (): boolean => {
    setWorkingBlurRequestTarget(new Date());

    const targetElm = document.querySelector('.contact-request-dialog');
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: displayName ?? '',
          ref: requiredDisplayNameArea,
        },
      ]) ||
      requestTargetCheck.length === 0
    ) {
      return true;
    }
    return false;
  };

  const decisionLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.decisionLabelId) {
      msgId.push(messageOption.decisionLabelId.prefixId ?? '');
      msgId.push(messageOption.decisionLabelId.viewId ?? '');
      msgId.push(messageOption.decisionLabelId.id ?? '');
    } else {
      msgId.push('send');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.decisionLabelId]);

  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: decisionLabelId }),
    },
  };

  const elements = (
    <div className="contact-request-dialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100 value-compare-group">
            <div className="input" ref={requiredDisplayNameArea}>
              <Textbox
                name="displayName"
                labelId="ContactRequestDialog.displayName"
                type="text"
                validateOption={{ required: true }}
                columns={['displayName']}
                value={displayName}
                onChangeState={setDisplayName}
                disabled={(inputOption?.displayName ?? '') !== ''}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="comment"
                labelId="ContactRequestDialog.comment"
                columns={['comment']}
                className="w-100 mh-middle"
                onChangeState={setComment}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="group">
                <Checkbox
                  name="requestTargetCheck"
                  items={[
                    {
                      value: 'documentRecipientAddress',
                      displayName: GetMessageWithIntl(intl, {
                        prefixId: 'ContactRequestDialog',
                        id: 'documentRecipientAddress',
                      }),
                    },
                    {
                      value: 'notificationUser',
                      displayName: GetMessageWithIntl(intl, {
                        prefixId: 'ContactRequestDialog',
                        id: 'notificationUser',
                      }),
                    },
                  ]}
                  columns={['requestTargetCheck']}
                  labelId="ContactRequestDialog.requestTargetCheck"
                  validateOption={{ required: true }}
                  value={requestTargetCheck}
                  onChangeState={setRequestTargetCheck}
                  workingBlur={workingBlurRequestTarget}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType={inputOption?.decisionButtonType ?? 'high'}
            className="button"
            caption={GetMessageWithIntl(intl, { id: decisionLabelId })}
            onClick={() => {
              if (isInputError()) {
                return;
              }
              setSendConfirmOpen(true);
            }}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'contactRequest',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
      {/* 送信 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSendConfirmOpen}
        viewMessage={sendMessage}
        onDecision={() => {
          handleDecision();
        }}
        onCancel={() => {
          setSendConfirmOpen(false);
        }}
      />
    </>
  );
};
