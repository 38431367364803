import { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  success,
  Toast,
} from '~/shared/components';
import {
  Preset,
  MenuItem,
  Property,
  ConfirmationDialog,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  MenuListView,
} from '~/shared/components/ui';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';

import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useIntl } from 'react-intl';
import {
  getPresetAndSchema,
  getExceptionMessage,
  autoDownloadFileWithDate,
  FullMethodName_ListUserGroupAttributes,
} from '~/shared/utils';

const VIEW_ID = 'ADMIN_USER_GROUP_LIST';

export function AdminUserGroupList(): JSX.Element {
  const intl = useIntl();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });

  const exportAttachedRolesMessage = {
    id: 'C0000001',
    value: { $1: 'ロール出力' },
  };
  const exportAllowedMenuItemsMessage = {
    id: 'C0000001',
    value: { $1: 'メニュー画面出力' },
  };

  const targetUserGroupAttributeIds = useRef<string[]>([]);

  const [
    isOpenExportAttachedRolesConfirmDialog,
    setIsOpenExportAttachedRolesConfirmDialog,
  ] = useState(false);
  const [
    isOpenExportAllowedMenuItemsConfirmDialog,
    setIsOpenExportAllowedMenuItemsConfirmDialog,
  ] = useState(false);

  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'adminUserGroupListImport')
        ?.property ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
  }, [childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListUserGroupAttributes,
          ]);
        const jaColumn = ['status.status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['systemGroup'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        setChildrenPresetItem(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'userBelongsUserGroup',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'userBelongsUserGroup',
    headerColumns: [],
  };

  // サンプルでバーガーメニューに関数を渡すためのサンプル関数(ダミー関数)
  const handleMenu = (prop?: string[]) => {
    console.debug('preset menuFunc:');
    console.debug(prop);
  };

  const handleMenuImport = () => {
    // 取込ダイアログ表示
    setDialogOpen(true);
  };

  // ロール出力
  const handleExportAttachedRoles = () => {
    (async () => {
      try {
        const fileFormat = await window.App.services.ui.getFileFormat(
          'userGroupAttachedRoles',
          intl
        );
        const exportRes =
          await window.App.services.tenantAdminService.exportAttachedRoles({
            ids: targetUserGroupAttributeIds.current,
            fileFormat: fileFormat,
          });
        if (!exportRes) {
          return;
        }
        autoDownloadFileWithDate(
          GetMessageWithIntl(intl, {
            prefixId: 'EXPORT_USER_GROUP_ATTACHED_ROLES_FILE_NAME',
          }),
          'csv',
          exportRes.assetId
        );
        success([successMessage]);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setIsOpenExportAttachedRolesConfirmDialog(false);
      }
    })();
  };

  // メニュー画面出力
  const handleExportAllowedMenuItems = () => {
    (async () => {
      try {
        const fileFormat = await window.App.services.ui.getFileFormat(
          'userGroupAllowedMenuItem',
          intl
        );
        const exportRes =
          await window.App.services.tenantAdminService.exportAllowedMenuItems({
            ids: targetUserGroupAttributeIds.current,
            fileFormat: fileFormat,
          });
        if (!exportRes) {
          return;
        }
        autoDownloadFileWithDate(
          GetMessageWithIntl(intl, {
            prefixId: 'EXPORT_USER_GROUP_ALLOWED_MENU_ITEM_FILE_NAME',
          }),
          'csv',
          exportRes.assetId
        );
        success([successMessage]);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setIsOpenExportAllowedMenuItemsConfirmDialog(false);
      }
    })();
  };
  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // メンバー取込
    menuItems.push({
      name: 'import',
      func: () => handleMenuImport(),
    });
    // ロール出力
    menuItems.push({
      name: 'exportRoleItems',
      func: (ids?: string[]) => {
        targetUserGroupAttributeIds.current = ids ?? [];
        setIsOpenExportAttachedRolesConfirmDialog(true);
      },
    });
    // メニュー画面出力
    menuItems.push({
      name: 'exportMenuItems',
      func: (ids?: string[]) => {
        targetUserGroupAttributeIds.current = ids ?? [];
        setIsOpenExportAllowedMenuItemsConfirmDialog(true);
      },
    });
    menuItems.push({ name: 'add', func: (ids?: string[]) => handleMenu(ids) });
    menuItems.push({
      name: 'delete',
      func: (ids?: string[]) => handleMenu(ids),
    });
    return menuItems;
  };
  const menuViewlist = burgerMenuEvent().map((v) => v.name);

  return (
    <Container viewId={VIEW_ID}>
      <div className="AdminUserGroupList">
        <MenuListView
          headerTitle={{ viewId: VIEW_ID }}
          fullMethodName={FullMethodName_ListUserGroupAttributes}
          schema={schema}
          preset={preset}
          presetItems={childrenPresetItem}
          menuEvent={burgerMenuEvent()}
          listSkipType={{
            isTotal: true,
            isOutput: true,
          }}
          menuTarget={'userGroupAttributeId'}
          menuViewlist={menuViewlist}
          importDialogOption={{
            isDisplay: true,
            isDialogOpen,
            dialogPreset,
            headerLabelId: {
              id: 'importAdminUserGroupListDialog',
              viewId: VIEW_ID,
            },
            handleExport,
            handleImport,
            onChangeState: setDialogOpen,
          }}
        />
        {/* 確認ダイアログ(ロール出力) */}
        <ConfirmationDialog
          isOpen={isOpenExportAttachedRolesConfirmDialog}
          viewMessage={exportAttachedRolesMessage}
          onDecision={() => handleExportAttachedRoles()}
          onCancel={() => setIsOpenExportAttachedRolesConfirmDialog(false)}
        />
        {/* 確認ダイアログ(メニュー画面出力) */}
        <ConfirmationDialog
          isOpen={isOpenExportAllowedMenuItemsConfirmDialog}
          viewMessage={exportAllowedMenuItemsMessage}
          onDecision={() => handleExportAllowedMenuItems()}
          onCancel={() => setIsOpenExportAllowedMenuItemsConfirmDialog(false)}
        />
      </div>
      <Toast />
    </Container>
  );
}
