import { IconButton } from '~/shared/components/ui/Button';

export const UISampleIconButtons = () => {
  return (
    <div className="categories">
      <h3 id="icon-buttons">アイコンボタン</h3>
      <h4>enabled</h4>
      <div className="input-line iconVariations">
        <div className="item-group">
          <span>high</span>
          <IconButton
            buttonType="high"
            iconType="search"
            caption="high"
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
            properties={[
              {
                name: '',
                propertyName: 'textAlign',
                propertyValue: 'center',
              },
            ]}
          />
        </div>
        <div className="item-group">
          <span>basic</span>
          <IconButton
            buttonType="basic"
            iconType="search"
            caption="basic"
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>sub</span>
          <IconButton
            buttonType="sub"
            iconType="search"
            caption="sub"
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>danger</span>
          <IconButton
            buttonType="danger"
            iconType="search"
            caption="danger"
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>cancel</span>
          <IconButton
            buttonType="cancel"
            iconType="search"
            caption="cancel"
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>no style</span>
          <IconButton iconType="search" name="" onClick={() => {}} />
        </div>
      </div>

      <h4>disabled</h4>
      <div className="input-line iconVariations">
        <div className="item-group">
          <span>high</span>
          <IconButton
            buttonType="high"
            iconType="search"
            caption="high"
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
            properties={[
              {
                name: '',
                propertyName: 'disabled',
                propertyValue: true,
              },
            ]}
          />
        </div>
        <div className="item-group">
          <span>basic</span>
          <IconButton
            buttonType="basic"
            iconType="search"
            caption="basic"
            disabled={true}
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
            properties={[
              {
                name: '',
                propertyName: 'textAlign',
                propertyValue: 'center',
              },
            ]}
          />
        </div>
        <div className="item-group">
          <span>sub</span>
          <IconButton
            buttonType="sub"
            iconType="search"
            caption="sub"
            disabled={true}
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>danger</span>
          <IconButton
            buttonType="danger"
            iconType="search"
            caption="danger"
            disabled={true}
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>cancel</span>
          <IconButton
            buttonType="cancel"
            iconType="search"
            caption="cancel"
            disabled={true}
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
        <div className="item-group">
          <span>no style</span>
          <IconButton
            iconType="search"
            caption="no style"
            disabled={true}
            name=""
            onClick={() => {
              console.debug('IconButton');
            }}
          />
        </div>
      </div>
    </div>
  );
};
