import { GenerallyIconType } from '../parts/Button/GenerallyIcons';

// フォーラムが使用可能なViewID一覧
export const FORUM_USABLE_VIEW_IDS: string[] = [
  // 'ESTIMATE_ESTIMATE_CONFIRMATION',
  // 'ESTIMATE_ESTIMATE_REQUEST_CONFIRMATION',
  'SURVEY_REQUEST_RECEIVER_CONFIRMATION',
  'SURVEY_REQUEST_SENDER_CONFIRMATION',
  // 'WT_WORKTASK_CONFIRMATION',
];

export type ToolType = 'Forum' | 'None';

export interface ToolItem {
  name: ToolType;
  icon: GenerallyIconType;
}

export const toolItems: Array<ToolItem> = [
  {
    name: 'Forum',
    icon: 'message_text',
  },
];
