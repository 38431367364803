import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
  error,
} from '../..';
import { useState } from 'react';
import { CaptionButton, IconButton } from '../Button';
import './SurveyRequestRegistrationDialog.css';

interface SurveyRequestRegistrationDialogProps {
  isOpen: boolean;
  surveyCount: number;
  onDecision: (result: string) => void;
  onCancel: () => void;
}

export const SurveyRequestRegistrationTypeValues = [
  'newSurvey',
  'existingSurvey',
];

export const SurveyRequestRegistrationDialog = (
  props: SurveyRequestRegistrationDialogProps
) => {
  const intl = useIntl();

  const radios: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: SurveyRequestRegistrationTypeValues[0],
        prefixId: 'SurveyRequestRegistrationDialog',
      }),
      value: SurveyRequestRegistrationTypeValues[0],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: SurveyRequestRegistrationTypeValues[1],
        prefixId: 'SurveyRequestRegistrationDialog',
      }),
      value: SurveyRequestRegistrationTypeValues[1],
    },
  ];

  const BiReportUrl = GetMessageWithIntl(intl, {
    id: 'L0000001',
  });

  const [registrationType, setRegistrationType] = useState<string>('');

  const handleDecision = () => {
    // チェックがされていない場合、エラー出力
    if (!registrationType) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000122',
        }),
      ]);
      return;
    }
    // すでに10件選択されている場合、エラー出力
    if (props.surveyCount >= 10) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000123',
          value: { $1: props.surveyCount },
        }),
      ]);
      return;
    }
    props.onDecision(registrationType);
    setRegistrationType('');
  };

  const handleCancell = () => {
    setRegistrationType('');
    props.onCancel();
  };

  const elements = (
    <div className="select-connecting-dialog dialog-with-description">
      <div className="comment-area detail-area">
        <div className="bireport-area">
          <div className="icon-area">
            <IconButton
              name="openInNew"
              iconType="open_in_new"
              buttonType="danger"
              onClick={() => (window.location.href = BiReportUrl)}
            />
          </div>
          <div className="url-area">
            <a href={BiReportUrl} target="_blank" rel="noreferrer">
              {GetMessageWithIntl(intl, {
                id: 'biReportLabel',
                prefixId: 'SurveyRequestRegistrationDialog',
              })}
            </a>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <p className="ragio-label">
                {GetMessageWithIntl(intl, {
                  id: 'ragioLabel',
                  prefixId: 'SurveyRequestRegistrationDialog',
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="registrationType"
                value={registrationType}
                items={radios}
                isVertical={false}
                onChangeState={setRegistrationType}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancell}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'decision' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'SURVEY_REQUEST_REGISTRATION',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
