import { Checkbox } from '~/shared/components/ui';
import { useIntl } from 'react-intl';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';

export interface NotificationSendTargetSettingDialogInputOption {}

export interface NotificationSendTargetSettingDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface NotificationSendTargetSettingDialogProps {
  isOpen: boolean;
  messageOption?: NotificationSendTargetSettingDialogMessageOption;
  inputOption?: NotificationSendTargetSettingDialogInputOption;
  onDecision: () => void;
  onCancel: () => void;
}

export const NotificationSendTargetSettingDialog = (
  props: NotificationSendTargetSettingDialogProps
) => {
  const messageOption = props.messageOption;
  const intl = useIntl();
  const PREFIX_ID = 'notificationSendTargetSettingDialog';

  const handleCancel = () => {
    props.onCancel();
  };

  const handleDecision = () => {
    props.onDecision();
  };

  const elements = (
    <div className="notification-send-target-setting-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'notification_send_target_setting_1',
            })}
          </span>
        </p>
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'notification_send_target_setting_2',
            })}
          </span>
        </p>
      </div>
      <div className="detail-area-deep">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <span className="text">
                {GetMessage({
                  prefixId: PREFIX_ID,
                  id: 'newRequest',
                })}
              </span>
            </div>
            <div className="w-20">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: PREFIX_ID,
                      id: 'to',
                    }),
                  },
                ]}
                value={[]}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-20">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: PREFIX_ID,
                      id: 'cc',
                    }),
                  },
                ]}
                value={['1']}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <span className="text">
                {GetMessage({
                  prefixId: PREFIX_ID,
                  id: 'receiveAnswer',
                })}
              </span>
            </div>
            <div className="w-20">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: PREFIX_ID,
                      id: 'to',
                    }),
                  },
                ]}
                value={['1']}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-20">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: PREFIX_ID,
                      id: 'cc',
                    }),
                  },
                ]}
                value={[]}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <span className="text">
                {GetMessage({
                  prefixId: PREFIX_ID,
                  id: 'replyDueDateChangeRequest',
                })}
              </span>
            </div>
            <div className="w-20">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: PREFIX_ID,
                      id: 'to',
                    }),
                  },
                ]}
                value={['1']}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-20">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: PREFIX_ID,
                      id: 'cc',
                    }),
                  },
                ]}
                value={[]}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => {
              handleCancel();
            }}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'decision' })}
            onClick={() => handleDecision}
            disabled={true} // 次ステップ開発で対応予定
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: messageOption?.headerLabelId ?? {
      id: 'NOTIFICATION_SEND_TARGET_SETTING',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
    </>
  );
};
