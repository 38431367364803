export interface ResourceInfo {
  typeName: string;
  resourceId: string;
}

/** 辞書 prefix */
export const ForumPrefixId = 'Forum';

/** 最大スレッド数 */
export const ForumMaxThreadCount = 20;

/** 最大コメント数 */
export const ForumMaxCommentCount = 50;

/** スレッドタイトル文字数 */
export const ForumTitleMaxLength = 40;

/** コメント文字数 */
export const ForumCommentMaxLength = 100;
