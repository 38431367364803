import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { success, error } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
export const UISampleMisc = () => {
  const navi = useNavigate();
  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログInfo
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: {},
    onDecision: () => {},
  });

  return (
    <div className="categories">
      <h4>
        GoogleAnalyticsへイベント送信
        <br />
        <small>
          イベント名:button、targetName:inputTestPageSendGoogleAnalytics
          でGAに送信
        </small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              caption="送信"
              buttonType="sub"
              name="inputTestPageSendGoogleAnalytics"
              properties={[]}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      <h4>管理場所確認ページ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <button
              onClick={() => {
                navi('/company/component-unit?debug=on', {
                  state: {
                    ComponentUnitIds: ['4a898b85-0dc8-08e3-5773-97d4d0b85b98'],
                  },
                });
              }}
            >
              管理場所確認ページへ
            </button>
          </div>
        </div>
      </div>
      <h4>
        プロビジョニング
        <br />
        <small>開発用ユーザーのみ実行可能</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              caption="名称マスタ取込"
              buttonType="high"
              name="provisionProgramOption"
              properties={[]}
              onClick={() => {
                setConfirmDialogInfo({
                  viewMessage: {
                    id: 'C0000001',
                    value: { $1: 'データ更新' },
                  },
                  onDecision: async () => {
                    try {
                      await window.App.services.programOptionService.provisionProgramOptions(
                        {}
                      );
                      success(['正常に処理が完了しました']);
                      setOpenConfirmDialog(false);
                    } catch (err) {
                      error([`${err}`]);
                    }
                  },
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmDialogInfo.viewMessage}
        onDecision={confirmDialogInfo.onDecision}
        onCancel={() => {
          setOpenConfirmDialog(false);
        }}
      />
    </div>
  );
};
