import React from 'react';
import { Textbox } from '~/shared/components/ui';
import { IconButton } from '~/shared/components/ui/Button';

interface GroupSearchProps {
  onChangeSearchWord: (searchWord: string | undefined) => void;
  onSearch: () => void;
}

/**
 * ツリー検索 UI コンポーネント
 */
export const GroupSearch = ({
  onChangeSearchWord,
  onSearch,
}: GroupSearchProps) => {
  const handleChangeSearchWord = (v: string) => {
    onChangeSearchWord(v);
  };

  return (
    <div className="GroupSearch">
      <Textbox
        name="search"
        className="search-text"
        type="text"
        columns={['search']}
        labelId="searchWord"
        onChangeState={handleChangeSearchWord}
      />
      <IconButton
        name="search"
        className="search-button"
        iconType="search"
        buttonType="basic"
        onClick={() => {
          onSearch();
        }}
      />
    </div>
  );
};
