import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import {
  Container,
  GetMessage,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  ConfirmationDialog,
  HistoryDialog,
  DeadlineChangeDialog,
  DeadlineChangeResult,
  FileUploadDialog,
  CommentDialog,
  SurveyReplyDialog,
  SurveyReplyResult,
  SystemNotificationSettingDialog,
  SurveyReplyDialogMode,
  FileUploadDialogResult,
  FileUploadAppendListDialog,
  FileUploadAppendListDialogFormOption,
} from '~/shared/components/ui/Dialog';

import './SurveyRequestReceiverConfirmation.css';

import {
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui/PageNavigation/PageNavigation';

import {
  Textarea,
  SimpleListView,
  Accordion,
  SimpleIconListView,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { AggregateStage } from '~/worker';
import {
  PageState,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  FullMethodName_ListSurveyReceptionContents,
  FullMethodName_ListUserAttributes,
  getDateFormat,
  getSurveyRequestAutoName,
  getDateFormatWithTimezone,
  autoDownloadFileOnlyName,
  autoBulkDownload,
  getExceptionMessage,
  getWorkerExceptionMessage,
  convertUserReference,
  convertDatetime,
  convertDate,
  HistoryItems,
  getHistoryItems,
  isShowNotificationArea,
  ViewId,
  createAttachmentFiles,
  autoDownloadFileWithDate,
  autoDownloadErrorFile,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useForum } from '~/shared/components/tool/Forum';
import {
  ReceptionSurveyResultWorkFormWithValues,
  SurveyRequestWorkFormWithValues,
  SurveyResultWorkFormWithValues,
  convertToRequestWorkFormWithValues,
  convertToResultWorkFormWithValues,
  exportSurveyResultWorkForms,
  getLatestSharedSurveyReplyDueDateChangeResult,
  listFormSettingItems,
  listFormSettings,
  listFormValues,
  listForms,
  listResultFormShareManagements,
  listSharedSurveyRequestWorkForms,
  listSharedSurveyResultWorkForms,
  toReceiverReceptionStatusText,
} from '../utils';
import { IconButton } from '~/shared/components/ui/Button';
import {
  BuildedInputFormDialog,
  BuildedInputFormDialogRef,
} from '~/shared/components/ui/FormBuilder';
import {
  FormValuesTypeNames,
  getFileName,
} from '~/shared/components/ui/FormBuilder/utils';

// インターフェースここから
interface AttachmentItems {
  id?: string;
  category?: sharelib.INameOption;
  assetId?: string;
  filename: string;
  url?: string;
  mimeType?: string;
  remarks?: string;
  linkType?: sharelib.INameOption;
}
// インターフェースここまで

const VIEW_ID: ViewId = 'SURVEY_REQUEST_RECEIVER_CONFIRMATION';
const FORM_TYPE_NAME: FormValuesTypeNames =
  'mtechnavi.api.survey.SurveyRequest';

const FieldName_MergedUserAttributes = 'mergedUserAttributes';
const aggregateStages: AggregateStage[] = [
  {
    $lookup: {
      from: FullMethodName_ListUserAttributes,
      localField: 'surveyRequest.requestUnit.mainContact.email',
      foreignField: 'user.email',
      as: FieldName_MergedUserAttributes,
      option: {
        useQuery: false, // リロードしない
      },
    },
  },
];

// 依頼フォームカラム設定
const requestFormListColumns = [
  {
    header: { id: 'requestDetail', viewId: VIEW_ID },
    propertyName: 'displayName',
  },
  {
    header: { id: 'attribute1', viewId: VIEW_ID },
    propertyName: 'attribute1',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute2', viewId: VIEW_ID },
    propertyName: 'attribute2',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute3', viewId: VIEW_ID },
    propertyName: 'attribute3',
    readonly: true,
    width: '20%',
  },
];

// 回答フォームカラム設定
const resultFormListColumns = [
  {
    header: { id: 'status', viewId: VIEW_ID },
    propertyName: 'statusLabel',
    readonly: true,
    width: '8rem',
  },
  {
    header: { id: 'required', viewId: VIEW_ID },
    propertyName: 'requiredLabel',
    readonly: true,
    width: '3rem',
  },
  {
    header: { id: 'formName', viewId: VIEW_ID },
    propertyName: 'displayName',
  },
  {
    header: { id: 'attribute1', viewId: VIEW_ID },
    propertyName: 'attribute1',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute2', viewId: VIEW_ID },
    propertyName: 'attribute2',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute3', viewId: VIEW_ID },
    propertyName: 'attribute3',
    readonly: true,
    width: '20%',
  },
];

export function SurveyRequestReceiverConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const myTenantId = useAuth().tenant?.tenantId ?? '';
  const navi = useNavigate();
  const handleError = useErrorHandler();
  const intl = useIntl();
  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // コミュニケーション機能設定
  const { setTypeName, setResourceId } = useForum();

  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  const successMessage = GetMessage({ id: 'I0000001' });
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };
  const resultFormStatusCodes = useMemo(
    () => window.App.services.ui.getProgramOption('A5010001'),
    []
  );
  const completeStatus = resultFormStatusCodes.find(
    (item) => item.systemName === 'B01'
  );
  const incompleteStatus = resultFormStatusCodes.find(
    (item) => item.systemName === 'B00'
  );

  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 1) {
        const idPath = splitPath[splitPath.length - 1];
        const endpointPath = splitPath[splitPath.length - 2];
        if (
          idPath !== 'survey-request-receiver-confirmation' &&
          endpointPath === 'survey-request-receiver-confirmation'
        ) {
          return [idPath];
        }
      }
    }
    return [];
  };

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListSurveyReceptionContents,
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      'surveyRequest.surveyRequestId': { $in: getTargetId() },
    },
    sort: getSortSettingFromLocalStorage(
      'SURVEY_REQUEST_RECEIVER_LIST',
      myEmail
    ),
    items: [],
    originalItems: [],
    aggregate: aggregateStages,
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const [isSystemNotificationDialogShow, setSystemNotificationDialogShow] =
    useState(false);
  const [isHistoryDialogShow, setHistoryDialogShow] = useState(false);
  const [isDeadlineChangeDialogShow, setDeadlineChangeDialogShow] =
    useState(false);
  const [isFileUploaderDialogShow, setFileUploaderDialogShow] = useState(false);

  const [isSurveyDeclineDialogShow, setSurveyDeclineDialogShow] =
    useState(false);
  const [comment, setComment] = useState('');
  const [isSurveyReplyDialogShow, setSurveyReplyDialogShow] = useState(false);
  const formInputDialogRef =
    useRef<
      BuildedInputFormDialogRef<mtechnavi.api.survey.ISurveyResultWorkForm>
    >(null);
  const [formInputDialogHeader, setFormInputDialogHeader] =
    useState<MessageProps>({});
  const [isFileUploadDialogShow, setFileUploadDialogShow] = useState(false);

  // ローディング
  const [isLoading, setLoading] = useState(false);
  // ページ情報
  const [item, setItem] =
    useState<mtechnavi.api.survey.SurveyReceptionContent>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  const [isNotificationShow, setNotificationShow] = useState<boolean>(false);
  // 依頼添付ファイル
  const [requestAttachmentData, setRequestAttachmentData] =
    useState<AttachmentItems[]>();
  // 回答添付ファイル
  const [answerAttachmentData, setAnswerAttachmentData] =
    useState<AttachmentItems[]>();
  // 履歴情報
  const [histories, setHistories] = useState<HistoryItems[]>([]);
  const historyMessage = GetMessage({
    id: 'I0000005',
    value: { $1: histories?.length > 0 ? histories[0].slip_category : '' },
  });
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    sharelib.IUserReference[]
  >([]);
  const [surveyRequestAutoName, setSurveyRequestAutoName] =
    useState<string>('');
  const [mainContentHeight, setMainContentHeight] = useState('');

  // ファイル追加ボタン表示制御
  const [isFileAdd, setFileAdd] = useState(true);
  // 回答期日変更依頼ボタン表示制御
  const [isDeadlineChange, setDeadlineChange] = useState(true);
  // 回答ボタン表示制御
  const [isAnswer, setAnswer] = useState(true);
  // 回答辞退ボタン表示制御
  const [isDecline, setDecline] = useState(true);
  const [surveyReplyDialogMode, setSurveyReplyDialogMode] =
    useState<SurveyReplyDialogMode>('confirmation');

  // 回答期日変更データ（最新）
  const surveyReplyDueDateChangeResultData =
    useRef<mtechnavi.api.survey.ISurveyReplyDueDateChangeResult | null>();

  // 回答フォーム送信済み管理情報
  const resultFormShareManagementsData = useRef<
    mtechnavi.api.survey.IResultFormShareManagement[] | null
  >();
  // 開封リクエスト重複防止制御用
  const isSurveyOpenRequested = useRef<boolean>(false);

  // 依頼フォーム情報
  const [requestForms, setRequestForms] = useState<
    SurveyRequestWorkFormWithValues[]
  >([]);
  // 回答フォーム情報
  const [resultForms, setResultForms] = useState<
    ReceptionSurveyResultWorkFormWithValues[]
  >([]);

  // ターゲット回答フォーム情報
  const [targetForm, setTargetForm] =
    useState<SurveyResultWorkFormWithValues>();

  // 権限エラー時の表示処理
  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // ページ変更時
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.survey.SurveyReceptionContent[];
    if (!items.length) return;
    const item = items[0];
    setTypeName('mtechnavi.api.survey.SurveyRequest');
    setResourceId(item.surveyRequest?.surveyRequestId ?? '');
  }, [page, setTypeName, setResourceId, myTenantId]);

  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListSurveyReceptionContents,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();
    handleReload();
    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 履歴データ取得
  const listHistoryData = async () => {
    try {
      // 履歴一覧を取得
      const data = await getHistoryItems(
        intl,
        'mtechnavi.api.survey.SurveyReception',
        [item?.surveyReception?.surveyReceptionId ?? '']
      );
      setHistories(data);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  // 履歴データ変更時
  useEffect(() => {
    // 最新の履歴通知レベルによる制御
    setNotificationShow(isShowNotificationArea(histories));
  }, [histories]);

  // ページデータの変更を受けて表示する情報を取得する
  useEffect(() => {
    if (!item) {
      return;
    }

    // システム通知先データを取得
    setSystemNotificationUsers(
      item?.surveyReception?.systemNotificationUsers ?? []
    );

    setSurveyRequestAutoName(
      getSurveyRequestAutoName(
        item?.surveyRequest?.surveyRequestAutoName,
        item?.surveyRequest?.surveyRequestDetailAutoName
      )
    );

    // ボタンの表示制御
    const systemName = item?.status?.systemName ?? '';
    switch (systemName) {
      case 'B00':
      case 'B01':
      case 'B06': {
        setFileAdd(true);
        setDeadlineChange(true);
        setAnswer(false);
        setDecline(false);
        // 完了区分が確認不要以外
        if (item?.surveyRequest?.completeCondition?.systemName !== 'B03') {
          setAnswer(true);
          // 辞退許可がTrue
          if (item?.surveyRequest?.decline) {
            setDecline(true);
          }
        }
        break;
      }
      default: {
        setFileAdd(false);
        setAnswer(false);
        setDecline(false);
        setDeadlineChange(false);
        break;
      }
    }

    //回答送信ダイアログラジオボタン制御
    if (item?.surveyRequest?.completeCondition?.systemName === 'B01') {
      setSurveyReplyDialogMode('reply');
    }
    if (item?.surveyRequest?.completeCondition?.systemName === 'B02') {
      setSurveyReplyDialogMode('confirmation');
    }
    if (item?.surveyRequest?.completeCondition?.systemName === 'B03') {
      setSurveyReplyDialogMode('unnecessary');
    }

    // 添付ファイルをセット
    setAttachments();

    setLoading(true);
    (async () => {
      // 未開封の場合は開封リクエストをしてリロードする
      if (
        item?.status?.systemName === 'B06' &&
        !isSurveyOpenRequested.current
      ) {
        try {
          isSurveyOpenRequested.current = true;
          await window.App.services.ui.worker.apiCall({
            actionName: 'execSurveyOpen',
            request: {
              surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
            },
          });
          handleReload();
          return;
        } catch (err) {
          error(getWorkerExceptionMessage(intl, err));
          return;
        } finally {
          isSurveyOpenRequested.current = false;
        }
      }

      // 各種データの取得
      try {
        await Promise.all([
          listHistoryData(), //履歴データ
          getReplyDueDateChangeData(), // 回答期日変更データ
          getWorkFormData(), // 依頼・回答フォーム
        ]);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();

    // データ取得時だけにしたいので例外的に除去
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.survey.SurveyReceptionContent[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setNotificationShow(false);
  }, [page]);

  // 添付ファイルのセット
  const setAttachments = () => {
    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      'B03'
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      'B03'
    );
    setAnswerAttachmentData(
      (item?.surveyReception?.replyAttachments ?? []).map((v) => {
        return {
          id: v.assetId ?? '',
          assetId: v.assetId ?? '',
          filename: v.filename ?? '',
          mimeType: v.mimeType ?? '',
          category: category.length > 0 ? category[0] : {},
          linkType: linkType.length > 0 ? linkType[0] : {},
        };
      })
    );
    const requestAttachments: AttachmentItems[] = [];
    (item?.surveyRequest?.businessUnitRequestAttachments ?? []).map((v) => {
      requestAttachments.push({
        id: v.assetId ?? '',
        filename: v.filename ?? '',
        assetId: v.assetId ?? '',
      });
    });
    (item?.surveyRequest?.commonRequestAttachments ?? []).map((v) => {
      requestAttachments.push({
        id: v.assetId ?? '',
        filename: v.filename ?? '',
        assetId: v.assetId ?? '',
      });
    });
    setRequestAttachmentData(requestAttachments);
  };

  /**
   * 回答期日変更依頼データの取得
   */
  const getReplyDueDateChangeData = async () => {
    const surveyRequestId = item?.surveyRequest?.surveyRequestId || '';
    const changeResult = await getLatestSharedSurveyReplyDueDateChangeResult(
      surveyRequestId
    );
    surveyReplyDueDateChangeResultData.current = changeResult;
  };

  /**
   * 依頼・回答フォームデータの取得
   */
  const getWorkFormData = async () => {
    if (!item) {
      return;
    }
    // 依頼フォーム情報・回答フォーム情報・回答フォーム送信済み管理情報を取得
    const [requestWorkForms, resultWorkForms, resultFormShareManagements] =
      await Promise.all([
        item?.surveyRequest?.requestFormUsable &&
        item.surveyRequest?.requestFormIds?.length
          ? listSharedSurveyRequestWorkForms(
              item.surveyRequest?.requestFormIds || []
            )
          : Promise.resolve(
              {} as mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
            ),
        item?.surveyRequest?.resultFormUsable &&
        item.surveyRequest?.resultFormIds?.length
          ? listSharedSurveyResultWorkForms(
              item.surveyRequest?.resultFormIds || []
            )
          : Promise.resolve(
              {} as mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
            ),
        listResultFormShareManagements([
          item.surveyReception?.surveyReceptionId || '',
        ]),
      ]);
    resultFormShareManagementsData.current = resultFormShareManagements.items;

    // 各フォームの formId (依頼 / 回答実績) を抽出
    const formIds = [
      ...(requestWorkForms?.items || []).map((form) => form.formId),
      ...(resultWorkForms?.items || []).map((form) => form.formId),
    ].filter((formId) => !!formId) as string[];

    // 各フォームの構成情報をまとめて取得
    const [
      formListRes,
      formSettingListRes,
      formSettingItemListRes,
      formValueListRes,
    ] = await Promise.all(
      formIds.length > 0
        ? [
            listForms(formIds),
            listFormSettings(formIds),
            listFormSettingItems(formIds),
            listFormValues(formIds),
          ]
        : [
            {} as Partial<mtechnavi.api.form.ListFormsResponse>,
            {} as Partial<mtechnavi.api.form.ListFormSettingsResponse>,
            {} as Partial<mtechnavi.api.form.ListFormSettingItemsResponse>,
            {} as Partial<mtechnavi.api.form.ListFormValuesResponse>,
          ]
    );

    // 依頼フォームを画面用に整形
    const requestFormWithValues = convertToRequestWorkFormWithValues(
      requestWorkForms?.items || [],
      {
        formList: formListRes?.items || [],
        formSettingList: formSettingListRes?.items || [],
        formSettingItemList: formSettingItemListRes?.items || [],
        formValueList: formValueListRes?.items || [],
        targetId: item.surveyRequest?.surveyBaseRequestId || '',
      },
      { intl }
    );
    setRequestForms(requestFormWithValues);

    // 回答(実績)フォームを画面用に整形
    const resultFormWithValues = convertToResultWorkFormWithValues(
      resultWorkForms.items || [],
      {
        formList: formListRes.items || [],
        formSettingList: formSettingListRes.items || [],
        formSettingItemList: formSettingItemListRes.items || [],
        formValueList: formValueListRes.items || [],
        targetId: item?.surveyRequest?.surveyRequestId || '',
      },
      item.surveyReception?.lockedFormIds || [],
      resultFormShareManagements.items.at(0)?.formIds || [],
      { intl, completeStatus, incompleteStatus }
    );
    setResultForms(resultFormWithValues);
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListSurveyReceptionContents,
      pageNumber: n,
    });
  };

  // 依頼フォーム確認
  const handleOpenRequestForm = (
    targetItem: SurveyRequestWorkFormWithValues
  ) => {
    setFormInputDialogHeader({
      prefixId: VIEW_ID,
      id: 'requestFormConfirmationDialog',
    });
    formInputDialogRef.current?.open(
      targetItem.form || {},
      targetItem.formSetting || {},
      targetItem.formSettingItems || [],
      targetItem.workForm || {},
      'B10',
      targetItem.formValues,
      false,
      false
    );
  };

  // 回答フォーム入力
  const handleInputResultForm = async (
    targetItem: SurveyResultWorkFormWithValues
  ) => {
    setTargetForm(targetItem);
    setFormInputDialogHeader({
      prefixId: VIEW_ID,
      id: 'resultFormInputDialog',
    });
    formInputDialogRef.current?.open(
      targetItem.form || {},
      targetItem.formSetting || {},
      targetItem.formSettingItems || [],
      targetItem.workForm || {},
      'B10',
      targetItem.formValues,
      true,
      true,
      item?.surveyReception?.lockedFormIds?.includes(
        targetItem.workForm?.formId || ''
      )
    );
  };

  // 回答フォーム確認
  const handleShowDetailResultForm = async (
    targetItem: SurveyResultWorkFormWithValues
  ) => {
    setFormInputDialogHeader({
      prefixId: VIEW_ID,
      id: 'resultFormConfirmationDialog',
    });
    formInputDialogRef.current?.open(
      targetItem.form || {},
      targetItem.formSetting || {},
      targetItem.formSettingItems || [],
      targetItem.workForm || {},
      'B10',
      targetItem.formValues,
      false,
      true,
      item?.surveyReception?.lockedFormIds?.includes(
        targetItem.workForm?.formId || ''
      )
    );
  };

  // 回答フォーム入力確定
  const handleFormInputDecision = async (
    formId: string,
    formValues?: mtechnavi.api.form.IFormValue[] | undefined,
    isDecision?: boolean
  ) => {
    setLoading(true);
    try {
      const formValuesUpdate = window.App.services.ui.worker.apiCall({
        actionName: 'saveFormValue',
        request: {
          items: formValues?.map((formValue) => {
            const oldValue = (targetForm?.formValues ?? []).find(
              (val) => val.formSettingItemId === formValue.formSettingItemId
            );
            return {
              ...formValue,
              formValueId: oldValue?.formValueId,
              formId,
              typeName: FORM_TYPE_NAME,
              recordId: item?.surveyRequest?.surveyRequestId,
              updatedAt: oldValue?.updatedAt,
            };
          }),
          tag: myTenantId,
        },
      }) as Promise<mtechnavi.api.form.IFormValue[]>;

      const lockedFormIds = [
        ...(item?.surveyReception?.lockedFormIds?.filter(
          (id) => id !== formId
        ) || []),
        ...(isDecision ? [formId] : []),
      ];

      const receptionUpdate = window.App.services.ui.worker.apiCall({
        actionName: 'updateSurveyReception',
        request: {
          surveyReceptionId: item?.surveyReception?.surveyReceptionId,
          systemNotificationUsers:
            item?.surveyReception?.systemNotificationUsers,
          replyAttachments: answerAttachmentData,
          lockedFormIds,
          updatedAt: item?.surveyReception?.updatedAt,
        },
      }) as Promise<mtechnavi.api.survey.ISurveyReception[]>;

      const [formValuesResp, receptionResp] = await Promise.all([
        formValuesUpdate,
        receptionUpdate,
      ]);

      if (item && receptionResp.at(0)) {
        item.surveyReception = receptionResp.at(0);
      }

      const targetWorkForm = resultForms.find(
        (form) => form.form?.formId === formId
      );
      if (targetWorkForm && formValuesResp) {
        targetWorkForm.formValues = formValuesResp;
        targetWorkForm.statusLabel = toReceiverReceptionStatusText(
          formId,
          item?.surveyReception?.lockedFormIds || [],
          resultFormShareManagementsData.current?.at(0)?.formIds || [],
          { intl, completeStatus, incompleteStatus }
        );
      }
      success([successMessage]);
    } catch (err) {
      setLoading(false);
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 添付ファイル(回答)の編集確定時処理
   */
  const handleFileEdit = async (
    result: FileUploadAppendListDialogFormOption
  ) => {
    setLoading(true);
    try {
      // アセットデータの作成
      const attachments = await createAttachmentFiles(
        result.files ?? [],
        'B06'
      );
      // 依頼とファイルの紐付けの永続化
      const response = await (window.App.services.ui.worker.apiCall({
        actionName: 'updateSurveyReception',
        request: {
          surveyReceptionId: item?.surveyReception?.surveyReceptionId,
          systemNotificationUsers:
            item?.surveyReception?.systemNotificationUsers,
          replyAttachments: attachments,
          lockedFormIds: item?.surveyReception?.lockedFormIds,
          updatedAt: item?.surveyReception?.updatedAt,
        },
      }) as Promise<mtechnavi.api.survey.ISurveyReception[]>);
      setAnswerAttachmentData(attachments);
      setFileUploaderDialogShow(false);
      if (item && response.at(0)) {
        item.surveyReception = response.at(0);
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 回答ファイルエクスポート
   */
  const handleExportResultWorkForms = async () => {
    try {
      setLoading(true);
      const result = await exportSurveyResultWorkForms(
        item?.surveyRequest?.surveyRequestId || ''
      );
      autoDownloadFileWithDate(
        item?.surveyRequest?.displayName || '',
        'xlsx',
        result.items.at(0)?.assetId || ''
      );
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 回答ファイルインポート
   */
  const handleImportResultWorkForms = () => {
    // 確定済みチェック
    if ((item?.surveyReception?.lockedFormIds || []).length > 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000142' })]);
      return;
    }
    setFileUploadDialogShow(true);
  };
  const handleImportResultWorkFormsDecision = async (
    result: FileUploadDialogResult
  ) => {
    try {
      setLoading(true);
      const importResult = (await window.App.services.ui.worker.apiCall({
        actionName: 'importSurveyResultWorkForms',
        request: {
          url: result.files.at(0)?.url,
          exportError: true,
        },
      })) as mtechnavi.api.survey.IImportSurveyResultWorkFormsResponse[];
      if (
        importResult &&
        importResult.length > 0 &&
        importResult.at(0)?.assetId
      ) {
        // アップロードファイル名の拡張子部分を除いたものをファイル名とする
        autoDownloadErrorFile(
          getFileName(result.files.at(0)?.file.name || ''),
          'xlsx',
          importResult.at(0)?.assetId || ''
        );
        error([GetMessageWithIntl(intl, { id: 'E1000018' })]);
        setLoading(false);
        return;
      }
      setFileUploadDialogShow(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      // 正常終了時は再取得処理に続くので、エラー時のみローディング解除
      setLoading(false);
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  // システム通知設定ダイアログ
  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const userReference = await convertUserReference(myEmail);
      const req: mtechnavi.api.worker.ISystemNotificationSetting = {
        surveyReceptionId: item?.surveyRequest?.surveyRequestId ?? '',
        appendUser:
          result === 'setAsNotificationDestination' ? userReference : null,
        removeUser:
          result !== 'setAsNotificationDestination' ? userReference : null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateReceiverSystemNotificationSetting',
        request: req,
      });
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
  };

  // 回答期日変更依頼ダイアログ
  const handleDeadlineChangeSave = async (
    deadlineChangeResult: DeadlineChangeResult
  ) => {
    setLoading(true);
    try {
      const datetime: sharelib.IDatetime = convertDatetime(
        deadlineChangeResult.deadline,
        'YYYY/MM/DD'
      );
      const req: mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest = {
        surveyReplyDueDateChangeId:
          surveyReplyDueDateChangeResultData.current
            ?.surveyReplyDueDateChangeResultId ?? '',
        surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
        replyDueDateDt: datetime,
        comment: deadlineChangeResult.comment,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'surveyReplyDueDateChangeRequest',
        request: req,
      });
      setDeadlineChangeDialogShow(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
  };

  // 回答辞退ダイアログ
  const handleSurveyDeclineSave = async () => {
    // 確認処理
    if (!(await confirmation(sendMessage))) {
      return;
    }
    setLoading(true);
    try {
      const requestData: mtechnavi.api.survey.ISurveyResult = {
        surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
        partialReplyed: false,
        declined: true,
        comment: comment,
        replyAttachments: answerAttachmentData,
        lockedFormIds: item?.surveyReception?.lockedFormIds,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'execSurveyResult',
        request: requestData,
      });
      await shareFormValues();
      await updateFormShareManagement();

      setSurveyDeclineDialogShow(false);
      success([successMessage]);
      setComment('');
      handleReload();
    } catch (err) {
      // 正常終了時は再取得処理に続くので、エラー時のみローディング解除
      setLoading(false);
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  // 回答送信ダイアログ
  const handleSurveyReplySave = async (
    surveyReplyResult: SurveyReplyResult
  ) => {
    // 回答完了の場合は、回答必須を満たしているかチェック
    if (surveyReplyResult.replyStatus === 'completed' && !isCanBeComplete()) {
      return;
    }
    // 確認処理
    if (!(await confirmation(sendMessage))) {
      return;
    }
    setLoading(true);
    try {
      const requestData: mtechnavi.api.survey.ISurveyResult = {
        surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
        partialReplyed:
          surveyReplyResult.replyStatus === 'partial_replyed' ? true : false,
        declined: false,
        comment: surveyReplyResult.comment,
        replyAttachments: answerAttachmentData,
        lockedFormIds: item?.surveyReception?.lockedFormIds,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'execSurveyResult',
        request: requestData,
      });
      await shareFormValues();
      await updateFormShareManagement();

      setSurveyReplyDialogShow(false);
      success([successMessage]);
      setComment('');
      handleReload();
    } catch (err) {
      // 正常終了時は再取得処理に続くので、エラー時のみローディング解除
      setLoading(false);
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  /**
   * フォーム入力値の share
   */
  const shareFormValues = async () => {
    // 確定済みのフォームに含まれる入力値IDに絞り込む
    const lockFormValueIds = resultForms
      .filter((formInfo) =>
        item?.surveyReception?.lockedFormIds?.includes(
          formInfo.form?.formId || ''
        )
      )
      .flatMap((formInfo) =>
        (formInfo.formValues || [])
          .filter(
            (value) => value.recordId === item?.surveyRequest?.surveyRequestId
          )
          .map((value) => value.formValueId || '')
      );
    if (lockFormValueIds.length === 0) {
      return;
    }
    return window.App.services.ui.worker.apiCall({
      actionName: 'sharetoFormValue',
      request: {
        formValueIds: lockFormValueIds,
        companyIds: [item?.surveyRequest?.requestingCompany?.companyId || ''],
      },
    });
  };

  /**
   * 送信済み管理データの upsert
   */
  const updateFormShareManagement = async () => {
    if (
      resultFormShareManagementsData.current &&
      resultFormShareManagementsData.current.length > 0
    ) {
      const shareManagement = resultFormShareManagementsData.current.at(0);
      return window.App.services.ui.worker.apiCall({
        actionName: 'updateResultFormShareManagement',
        request: {
          resultFormShareManagement: {
            resultFormShareManagementId:
              shareManagement?.resultFormShareManagementId,
            formIds: item?.surveyReception?.lockedFormIds,
            updatedAt: shareManagement?.updatedAt,
          },
        },
      });
    } else {
      return window.App.services.ui.worker.apiCall({
        actionName: 'createResultFormShareManagement',
        request: {
          surveyReceptionId: item?.surveyReception?.surveyReceptionId,
          resultFormShareManagement: {
            formIds: item?.surveyReception?.lockedFormIds,
          },
        },
      });
    }
  };

  // フッタの高さ制御
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // ナビゲーションアイコンボタン
  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    return [
      {
        name: 'history',
        displayName: '履歴',
        func: async () => {
          setHistoryDialogShow(true);
          await listHistoryData();
        },
      },
      {
        name: 'recipients',
        displayName: '通知設定',
        func: () => {
          setSystemNotificationDialogShow(true);
        },
      },
    ];
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 回答完了可能チェック
   */
  const isCanBeComplete = (): boolean => {
    // 回答ファイル必須のチェック
    if (
      item?.surveyRequest?.requiredAttachment &&
      (!answerAttachmentData || answerAttachmentData.length === 0)
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000133' })]);
      return false;
    }

    // 回答必須フォームの確定済みチェック
    const requiredFormIds = resultForms
      .filter((form) => form.workForm?.required)
      .map((form) => form.workForm?.formId || '');
    const isAllRequiredFormIsCompleted = requiredFormIds.every(
      (requiredFormId) =>
        item?.surveyReception?.lockedFormIds?.includes(requiredFormId)
    );
    if (!isAllRequiredFormIsCompleted) {
      error([GetMessageWithIntl(intl, { id: 'E0000125' })]);
      return false;
    }

    return true;
  };

  // 依頼元情報取得
  const renderRequesters = (target: number) => {
    if (item?.surveyRequest?.requesters) {
      if (item?.surveyRequest?.requesters.length > target) {
        return item?.surveyRequest?.requesters[target];
      }
    }
    return '';
  };

  // TO 表記の取得
  const renderSurveyRequestTo = () => {
    const mainContact = item?.surveyRequest?.requestUnit?.mainContact ?? [];
    const mainContactAssistant =
      item?.surveyRequest?.requestUnit?.mainContactAssistant ?? [];
    const results: string[] = [];
    mainContact.map((v) => {
      if (!v.displayName && !v.email) {
        return;
      }
      const displayName = v.displayName ?? '';
      const email = v.email ?? '';
      const to = `${displayName}(${email})`;
      results.push(to);
    });
    mainContactAssistant.map((v) => {
      if (!v.displayName && !v.email) {
        return;
      }
      const displayName = v.displayName ?? '';
      const email = v.email ?? '';
      const to = `${displayName}(${email})`;
      results.push(to);
    });
    return results.join(',');
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestReceiverConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/supplier-base/survey-request-receiver-list"
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                navi('/supplier-base/survey-request-receiver-list');
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="info">
              <div className="basic-info-body">
                {/* ////通知枠//// */}
                <div
                  className={`notification-area ${
                    isNotificationShow ? '' : 'close'
                  }`}
                >
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <div className="notification-display-area">
                          {historyMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ////依頼//// */}
                <Accordion
                  title={GetMessageWithIntl(intl, {
                    id: 'request',
                    viewId: VIEW_ID,
                  })}
                >
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-77">
                        {/* 依頼名 */}
                        <h3 className="strong">
                          {item?.surveyRequest?.displayName ?? ''}
                        </h3>
                      </div>
                      <div className="w-23 right underline">
                        {/* 依頼元情報 */}
                        {GetMessage({
                          id: 'surveyRequesterInfomation',
                          prefixId: VIEW_ID,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-25">
                        {/* ステータス */}
                        {item?.status?.displayNameLang?.ja ?? ''}
                      </div>
                      <div className="w-75 right">
                        {/* 依頼通知番号 */}
                        {GetMessage({
                          id: 'surveyRequestNotificationAutoName',
                          prefixId: VIEW_ID,
                        })}{' '}
                        :{surveyRequestAutoName}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-25">
                        {/* 回答期日 */}
                        {`${GetMessage({
                          id: 'replyDueDateDt',
                          prefixId: VIEW_ID,
                        })} : ${getDateFormatWithTimezone(
                          item?.replyDueDateDt
                        )}`}
                      </div>
                      <div className="w-50">
                        {isDeadlineChange && (
                          <CaptionButton
                            className="button-margin"
                            name=""
                            caption="回答期日変更依頼"
                            onClick={() => {
                              setDeadlineChangeDialogShow(true);
                            }}
                            buttonType="basic"
                          />
                        )}
                      </div>
                      <div className="w-25 right">
                        {/* 依頼日 */}
                        {GetMessage({ id: 'sendedAt', prefixId: VIEW_ID })} :
                        {getDateFormat(
                          item?.surveyReception?.receiptedAt ?? '',
                          'YYYY/MM/DD'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-100 right">
                        {/* 依頼元 */}
                        {item?.surveyRequest?.requestingCompany?.displayNameLang
                          ?.ja ?? ''}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-100 right">
                        {/* 依頼元1 */}
                        {renderRequesters(0)}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-50">
                        {/* TO */}
                        TO : {renderSurveyRequestTo()}
                      </div>
                      <div className="w-50 right">
                        {/* 依頼元2 */}
                        {renderRequesters(1)}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-100 right">
                        {/* 案内文 */}
                        <Textarea
                          name="guidance"
                          labelId=""
                          className="w-100  mh-middle"
                          value={item?.surveyRequest?.guidance ?? ''}
                          columns={preset.columns}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-33">
                        {/* 添付ファイル（依頼） */}
                        <SimpleListView
                          data={requestAttachmentData ?? []}
                          viewOptions={{
                            readonly: true,
                            previewRowCount: 5,
                            keyColumn: 'id',
                            columns: [
                              {
                                header: {
                                  id: 'attachmentRequest',
                                  prefixId: VIEW_ID,
                                },
                                propertyName: 'filename',
                              },
                            ],
                          }}
                          actionOptions={{
                            onRowClick: (item: AttachmentItems) => {
                              autoDownloadFileOnlyName(
                                item.filename ?? '',
                                item.assetId ?? ''
                              );
                            },
                            onFullDownLoad: () => {
                              autoBulkDownload(
                                (requestAttachmentData ?? []).map(
                                  (item) => item.assetId ?? ''
                                ),
                                intl,
                                VIEW_ID
                              );
                            },
                          }}
                        />
                      </div>
                      {/* 詳細情報(依頼フォーム) */}
                      {item?.surveyRequest?.requestFormUsable && (
                        <div className="w-66">
                          <div className="area-container">
                            <SimpleListView
                              data={requestForms}
                              viewOptions={{
                                previewRowCount: 10,
                                omitFooter: true,
                                keyColumn: 'id',
                                columns: requestFormListColumns,
                              }}
                              actionOptions={{
                                onRowClick: handleOpenRequestForm,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion>
                {/* ////回答//// */}
                <Accordion
                  title={GetMessageWithIntl(intl, {
                    id: 'answer',
                    viewId: VIEW_ID,
                  })}
                >
                  {/* 回答フォーム */}
                  {item?.surveyRequest?.resultFormUsable && (
                    <div className="area-container">
                      <div className="input-line">
                        <div className="item-group-100">
                          <div className="w-75">
                            <IconButton
                              name="download"
                              buttonType="basic"
                              iconType="download"
                              onClick={handleExportResultWorkForms}
                            />
                            <IconButton
                              name="upload"
                              buttonType="basic"
                              iconType="upload"
                              onClick={handleImportResultWorkForms}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-line">
                        <div className="item-group-100">
                          <div className="w-100">
                            <SimpleIconListView
                              data={resultForms}
                              viewOptions={{
                                previewRowCount: 10,
                                keyColumn: 'id',
                                columns: resultFormListColumns,
                              }}
                              iconMenuOptions={{
                                iconMenu: [
                                  {
                                    name: 'edit',
                                    displayName: '入力',
                                    func: handleInputResultForm,
                                    disabledFunc: (
                                      data: ReceptionSurveyResultWorkFormWithValues
                                    ) => !isAnswer || !!data.disabled,
                                  },
                                  {
                                    name: 'description',
                                    displayName: '確認',
                                    func: handleShowDetailResultForm,
                                  },
                                ],
                              }}
                              actionOptions={{}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-33">
                        {/* 添付ファイル（回答） */}
                        <SimpleListView
                          data={answerAttachmentData ?? []}
                          viewOptions={{
                            previewRowCount: 5,
                            keyColumn: 'filename',
                            columns: [
                              {
                                header: {
                                  id: 'attachmentAnswer',
                                  prefixId: VIEW_ID,
                                },
                                propertyName: 'filename',
                              },
                            ],
                          }}
                          actionOptions={{
                            onRowClick: (item: AttachmentItems) => {
                              autoDownloadFileOnlyName(
                                item.filename ?? '',
                                item.assetId ?? ''
                              );
                            },
                            onFullDownLoad: () => {
                              autoBulkDownload(
                                (answerAttachmentData ?? []).map(
                                  (item) => item.assetId ?? ''
                                ),
                                intl,
                                VIEW_ID
                              );
                            },
                          }}
                        />
                      </div>
                      <div className="w-20">
                        {isFileAdd && (
                          <CaptionButton
                            className="button-margin"
                            name=""
                            caption={GetMessageWithIntl(intl, {
                              id: 'attachmentEdit',
                              viewId: VIEW_ID,
                            })}
                            onClick={() => {
                              if (
                                answerAttachmentData &&
                                answerAttachmentData.length >= 10
                              ) {
                                error([
                                  GetMessageWithIntl(intl, {
                                    id: 'E0000077',
                                    value: { $1: 10 },
                                  }),
                                ]);
                                return;
                              }
                              setFileUploaderDialogShow(true);
                            }}
                            buttonType="basic"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                {isAnswer && (
                  <CaptionButton
                    className="button-margin"
                    name=""
                    caption="回答"
                    onClick={() => {
                      setSurveyReplyDialogShow(true);
                    }}
                    buttonType="basic"
                  />
                )}
                {isDecline && (
                  <CaptionButton
                    className="button-margin"
                    name=""
                    caption="回答辞退"
                    onClick={() => {
                      setSurveyDeclineDialogShow(true);
                    }}
                    buttonType="basic"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* システム通知設定ダイアログ */}
        <SystemNotificationSettingDialog
          isOpen={isSystemNotificationDialogShow}
          inputData={systemNotificationUsers}
          onDecision={(result) => {
            handleSystemNotificationSetting(result);
          }}
          onCancel={() => {
            setSystemNotificationDialogShow(false);
          }}
        />
        {/* 履歴ダイアログ */}
        <HistoryDialog
          isOpen={isHistoryDialogShow}
          messageOption={{
            headerLabelId: {
              id: 'history',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          inputOption={{
            data: histories ?? [],
            keyColumn: 'history_id',
            columns: [
              {
                propertyName: 'slip_category',
                width: '12rem',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'slip_category',
                },
              },
              {
                propertyName: 'occurred_at',
                width: '12rem',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'occurred_at',
                },
              },
              {
                propertyName: 'content',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'content',
                },
              },
            ],
          }}
          onCancel={() => {
            setHistoryDialogShow(false);
          }}
        />
        {/* 回答期日変更依頼ダイアログ */}
        <DeadlineChangeDialog
          isOpen={isDeadlineChangeDialogShow}
          outputOption={{
            currentDeadline: convertDate(item?.replyDueDateDt ?? null),
          }}
          messageOption={{
            headerLabelId: {
              id: 'deadline_change_request',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'deadline_change_request',
              prefixId: 'DIALOG_DESCRIPTION',
            },
          }}
          onCancel={() => {
            setDeadlineChangeDialogShow(false);
          }}
          onDecision={(result) => {
            handleDeadlineChangeSave(result);
          }}
        />
        {/* ファイル添付編集ダイアログ */}
        <FileUploadAppendListDialog
          isOpen={isFileUploaderDialogShow}
          uploaderOption={{
            validateOption: {
              maxFileSizeInMebis: 50,
              maxFileCount: 10,
            },
          }}
          inputOption={{
            files: answerAttachmentData,
          }}
          displayOption={{
            isDnd: true,
          }}
          messageOption={{
            dialogTitle: {
              prefixId: 'DIALOG_TITLE',
              id: 'answer-attach-file-edit',
            },
            buttonType: {
              id: 'save',
            },
            simpleListViewHeader: {
              prefixId: 'FileManagementDialog',
              id: 'simpleListViewHeader',
            },
          }}
          onDecision={handleFileEdit}
          onCancel={() => {
            setFileUploaderDialogShow(false);
          }}
          onChangeLoadingState={(v) => {
            setLoading(v);
          }}
        />
        {/* ファイルアップロードダイアログ(回答入力インポート) */}
        <FileUploadDialog
          isOpen={isFileUploadDialogShow}
          messageOption={{
            headerLabelId: { viewId: VIEW_ID, id: 'replyImportDialog' },
            captionLabelId: { viewId: VIEW_ID, id: 'importCaption' },
            decisionLabelId: { id: 'import' },
          }}
          fileUploadOption={{
            isDnd: false,
            validateOption: {
              allowedFileExtensions: ['xlsx'],
              maxFileCount: 1,
            },
          }}
          onDecision={handleImportResultWorkFormsDecision}
          onCancel={() => setFileUploadDialogShow(false)}
          onChangeLoadingState={(v) => {
            setLoading(v);
          }}
        />
        {/* コメントダイアログ */}
        <CommentDialog
          isOpen={isSurveyDeclineDialogShow}
          inputOption={{ comment: comment, butonType: 'high' }}
          inputStateOption={{ onChangeComment: setComment }}
          messageOption={{
            headerLabelId: {
              id: 'survey_decline',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'survey_decline',
              prefixId: 'DIALOG_DESCRIPTION',
            },
          }}
          onDecision={handleSurveyDeclineSave}
          onCancel={() => {
            setSurveyDeclineDialogShow(false);
            setComment('');
          }}
        />
        {/* 回答ダイアログ */}
        <SurveyReplyDialog
          isOpen={isSurveyReplyDialogShow}
          outputOption={{
            mode: surveyReplyDialogMode,
            isCanPartialReply: item?.surveyRequest?.partialReply ?? false,
          }}
          messageOption={{
            headerLabelId: {
              id: 'survey_reply',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          onCancel={() => {
            setSurveyReplyDialogShow(false);
          }}
          onDecision={handleSurveyReplySave}
        />
        {/* フォーム入力ダイアログ */}
        <BuildedInputFormDialog
          messageOption={{
            headerLabelId: formInputDialogHeader,
          }}
          ref={formInputDialogRef}
          onDecision={handleFormInputDecision}
        />
        {/* 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmMessage}
          onDecision={handleConfirmed}
          onCancel={handleCancel}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
