import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';

type ThemeType = 'dark' | '';

// themeカラー制御用コンテクスト
const ThemeContext = createContext<ThemeType>('');
const ThemeDispatchContext = createContext<
  React.Dispatch<React.SetStateAction<ThemeType>>
>(() => {});

export function useTheme() {
  return {
    theme: useContext(ThemeContext),
    setTheme: useContext(ThemeDispatchContext),
  };
}

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = useState<ThemeType>('');
  return (
    <ThemeContext.Provider value={theme}>
      <ThemeDispatchContext.Provider value={setTheme}>
        <Theme>{children}</Theme>
      </ThemeDispatchContext.Provider>
    </ThemeContext.Provider>
  );
}

const Theme = ({ children }: PropsWithChildren) => {
  const { theme } = useTheme();
  // themeのバックグランドカラーとメインコンテンツ用のrefを取得
  const backGroundThemeColorRef = useRef<HTMLDivElement>(null);
  const themeRef = useRef<HTMLDivElement>(null);

  backGroundThemeColorRef?.current?.setAttribute('theme', theme);
  themeRef?.current?.setAttribute('theme', theme);

  return (
    <>
      <div id="back-ground-color-theme" ref={backGroundThemeColorRef}></div>
      <div id="theme" ref={themeRef}>
        {children}
      </div>
    </>
  );
};
