import Long from 'long';
import { useEffect, useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  BusinessUnitFacilityDialog,
  BusinessUnitFacilityFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitFacilityDialog';

import { error } from '~/shared/components';

export const UISampleBusinessUnitFacilityDialogs = () => {
  const [inputResult, setInputResult] = useState('');
  const [isInputShow, setInputShow] = useState(false);
  const [editResult, setEditResult] = useState('');
  const [isEditShow, setEditShow] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState('');
  const [isConfirmationShow, setConfirmationShow] = useState(false);

  const [companyId, setCompanyId] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const res = await window.App.services.companyService.getCompany({});
        setCompanyId(res.companyId);
      } catch (err) {
        error(['自社企業取得に失敗しました']);
        throw err;
      }
    })();
  }, []);

  const inputOption: BusinessUnitFacilityFormOption = {
    buFacilities: {
      companyId: companyId,
      categorys: [
        {
          categoryName: 'A4000003',
          code: '0',
          systemName: 'B00',
        },
      ],
      manufacture: 'テスト設備',
      modelNumber: 'AB0123',
      assetsAmount: Long.fromString('30'),
      accuracy: {
        categoryName: 'A4000004',
        code: '0',
        systemName: 'B00',
      },
      processableTon: Long.fromString('20'),
      sizeX: Long.fromString('3000'),
      sizeY: Long.fromString('40000'),
      sizeZ: Long.fromString('20000'),
      processableMaterial: {
        categoryName: 'A4000005',
        code: '0',
        systemName: 'B00',
      },
      remarks: 'テスト',
    },
    companyId: companyId,
  };

  return (
    <div className="categories">
      <h3 id="dialog">設備情報登録/確認ダイアログ</h3>
      <h4>新規登録</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setInputResult('');
                setInputShow(true);
              }}
            />
            <BusinessUnitFacilityDialog
              isOpen={isInputShow}
              inputOption={{ companyId: companyId }}
              inputMode={true}
              onCancel={() => {
                setInputResult('キャンセルしました');
                setInputShow(false);
              }}
              onDecision={(v) => {
                setInputResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setInputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{inputResult}</pre>
          </div>
        </div>
      </div>

      <h4>編集</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEditResult('');
                setEditShow(true);
              }}
            />
            <BusinessUnitFacilityDialog
              isOpen={isEditShow}
              inputMode={true}
              inputOption={inputOption}
              onCancel={() => {
                setEditResult('キャンセルしました');
                setEditShow(false);
              }}
              onDecision={(v) => {
                setEditResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setEditShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{editResult}</pre>
          </div>
        </div>
      </div>

      <h4>確認</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setConfirmationResult('');
                setConfirmationShow(true);
              }}
            />
            <BusinessUnitFacilityDialog
              isOpen={isConfirmationShow}
              inputMode={false}
              inputOption={inputOption}
              onCancel={() => {
                setConfirmationResult('キャンセルしました');
                setConfirmationShow(false);
              }}
              onDecision={(v) => {
                setConfirmationResult(
                  `出力しました。${JSON.stringify(v, null, 2)}`
                );
                setConfirmationShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{confirmationResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
