import {
  FILE_VIEWER_LOCALE_STORAGE_PREFIX,
  ListColumnSetting,
  SimpleIconListView,
} from '~/shared/components/ui';
import './EstimateConfirmationHeader.css';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { openWindowOrTab, setLocalStorage } from '~/shared/utils';

/**
 * 見積回答共通ヘッダー
 * @prop displayName: 件名
 * @prop estimateDeadlineDt: 見積期日
 * @prop requestAutoName: 依頼番号
 * @prop desiredReceivedDt: 希望納期
 * @prop requester: 依頼元
 * @prop requestedDt: 依頼日
 * @prop deliveryPoint: 納入先
 * @prop contactProperties: 問合せ
 */
export interface EstimateConfirmationHeaderInfo {
  displayName: string;
  estimateDeadlineDt: string;
  requestAutoName: string;
  requestAutoNameLabelId: string;
  desiredReceivedDt: string;
  requester?: string;
  requestedDt?: string;
  deliveryPoint: string;
  contactProperties: string;
}

interface ListIconViewInfo {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewData: any[];
  columnInfo: ListColumnSetting[];
  keyColumn: string;
  assetIdColumn: string; //アセットID特定用 assetId
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (v: any) => void;
}

/**
 * 見積回答共通ヘッダー
 * @prop estimateResultHeaderInfo: ヘッダーに表示する値
 * @prop listIconViewInfo: リストビュー情報
 * @prop 全図面表示処理
 */
export interface EstimateConfirmationHeaderProps {
  estimateResultHeaderInfo: EstimateConfirmationHeaderInfo;
  listIconViewInfo: ListIconViewInfo;
}

export const EstimateConfirmationHeader = (
  props: EstimateConfirmationHeaderProps
) => {
  const { estimateResultHeaderInfo, listIconViewInfo } = props;
  const intl = useIntl();
  const prefixId = 'EST_ESTIMATE_CONFIRMATION_HEADER';

  return (
    <>
      <div className="EstimateResultHeader">
        <div className="input-line label-margin">
          <div className="item-group-100">
            <div className="w-100">
              {/* 件名 */}
              <p className="font-b">{estimateResultHeaderInfo.displayName}</p>
            </div>
          </div>
        </div>
        <div className="input-line label-margin">
          <div className="item-group-100">
            <div className="w-66">
              {/* 見積期日 */}
              <span className="label">
                {GetMessageWithIntl(intl, {
                  id: 'estimateDeadlineDt',
                  prefixId,
                })}
              </span>
              <span>{estimateResultHeaderInfo.estimateDeadlineDt}</span>
            </div>
            <div className="w-33 right">
              {/* 依頼(通知)番号*/}
              <span className="label">
                {GetMessageWithIntl(intl, {
                  id: estimateResultHeaderInfo.requestAutoNameLabelId,
                  prefixId,
                })}
              </span>
              <span>{estimateResultHeaderInfo.requestAutoName}</span>
            </div>
          </div>
        </div>
        <div className="input-line label-margin">
          <div className="item-group-100">
            <div className="w-66">
              {/* 希望納期 */}
              <span className="label">
                {GetMessageWithIntl(intl, {
                  id: 'desiredReceivedDt',
                  prefixId,
                })}
              </span>
              <span>{estimateResultHeaderInfo.desiredReceivedDt}</span>
            </div>
            {estimateResultHeaderInfo.requester && (
              <div className="w-33 right">
                {/* 依頼元 */}
                <span>{estimateResultHeaderInfo.requester}</span>
              </div>
            )}
            {estimateResultHeaderInfo.requestedDt && (
              <div className="w-33 right">
                {/* 依頼日 */}
                <span className="label">
                  {GetMessageWithIntl(intl, {
                    id: 'requestedDt',
                    prefixId,
                  })}
                </span>
                <span>{estimateResultHeaderInfo.requestedDt}</span>
              </div>
            )}
          </div>
        </div>
        <div className="input-line label-margin">
          <div className="item-group-100">
            <div className="w-66">
              {/* 納入先 */}
              <span className="label">
                {GetMessageWithIntl(intl, { id: 'deliveryPoint', prefixId })}
              </span>
              <span>{estimateResultHeaderInfo.deliveryPoint}</span>
            </div>
            <div className="w-33 right">
              {/* 問合せ先 */}
              <span className="label">
                {GetMessageWithIntl(intl, {
                  id: 'contactProperties',
                  prefixId,
                })}
              </span>
              <span>{estimateResultHeaderInfo.contactProperties}</span>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              {/* リスト */}
              <SimpleIconListView
                data={listIconViewInfo.viewData}
                actionOptions={{}}
                viewOptions={{
                  readonly: true,
                  omitFooter: true,
                  previewRowCount: 3,
                  keyColumn: listIconViewInfo.keyColumn,
                  columns: listIconViewInfo.columnInfo,
                }}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v) => {
                        listIconViewInfo.func(v);
                      },
                    },
                  ],
                }}
              />
            </div>
            <div className="w-33">
              {/* 全図面確認 */}
              <CaptionButton
                name="showAllPicture"
                className="showAllPicture"
                caption={GetMessageWithIntl(intl, {
                  id: 'showAllPicture',
                  prefixId,
                })}
                buttonType="basic"
                onClick={() => {
                  const assetIds = listIconViewInfo.viewData
                    .filter((item) => item[listIconViewInfo.assetIdColumn])
                    .map((item) => {
                      return item[listIconViewInfo.assetIdColumn];
                    });
                  handleFileViewer(assetIds);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// ビューワー処理
export const handleFileViewer = (assetIds: string[]) => {
  const key = setLocalStorage(FILE_VIEWER_LOCALE_STORAGE_PREFIX, assetIds);
  openWindowOrTab('file-viewer', key);
};
