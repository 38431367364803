import { useIntl } from 'react-intl';
import { useState, useEffect, useRef } from 'react';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { Textarea } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import './TenantInvitationDialog.css';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  includeInputValidateError,
  FullMethodName_ListBusinessUnitManagementContents,
  getExceptionMessage,
} from '~/shared/utils';
import { error } from '../../parts/Toast/Toast';
import { FilterRequest } from '~/worker';

/**
 * ページから辞書IDを渡したい時のインターフェイス
 * ダイアログタイトルや説明文、その他ボタンキャプションなど適宜対応できる様にする
 */
export interface TenantInvitationDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
}

/**
 * 入力項目に関する項目用インターフェイス
 * ダイアログによってページから呼び出す項目が異なるが
 * 引数の増減は極力減らしたいため
 */
export interface TenantInvitationDialogInputOption {
  businessUnitManagementIds: string[];
  companyName: string;
  staffName: string;
  staffEmail: string;
}

export interface TenantInvitationDialogResult {
  message: string;
  contact: string;
}

export interface TenantInvitationDialogProps {
  isOpen: boolean;
  messageOption: TenantInvitationDialogMessageOption;
  inputOption: TenantInvitationDialogInputOption;
  onDecision: (result: TenantInvitationDialogResult) => void;
  onCancel: () => void;
}

export function TenantInvitationDialog(props: TenantInvitationDialogProps) {
  const intl = useIntl();

  const { isOpen, messageOption, inputOption, onCancel, onDecision } = props;

  const [message, setMessage] = useState<string>();
  const [contact, setContact] = useState<string>();
  const [preview, setPreview] = useState<string>();

  const previewMessage =
    '{companyName}\n' +
    '{staffName}様\n' +
    '\n' +
    '{message}\n' +
    '\n' +
    '下記手順に従いシステム登録をお願いします。\n' +
    '-------------------------------\n' +
    '◆登録手順\n' +
    '下記URLから利用開始登録をお願いします。\n' +
    'https://sample.com\n' +
    '\n' +
    '◆お問合せ\n' +
    '本メールは自動送信メールの為、このメールへの返信はできません。\n' +
    '何かご質問やご不明な点がございましたら、下記の連絡先までお問い合わせください。\n' +
    '{contact}';

  const requiredInputArea = useRef(null);
  const isInputError = async (): Promise<boolean> => {
    const errorMessages: string[] = [];
    try {
      // 対象の送信先メールアドレス・スタッフの存在チェック
      const option: FilterRequest = {
        action: 'query',
        fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
        filter: {
          'businessUnitManagement.businessUnitManagementId': {
            $in: inputOption.businessUnitManagementIds,
          },
        },
        sort: [],
      };
      option.requestBody = {
        dataFilter: {
          businessUnitManagementIds: inputOption.businessUnitManagementIds,
        },
      };
      const bumcRes = (await window.App.services.ui.worker.filter(
        option
      )) as mtechnavi.api.company.ListBusinessUnitManagementContentsResponse;
      bumcRes.items.forEach((bumc) => {
        const invitees = bumc.businessUnitManagement?.invitees ?? [];
        if (invitees.length > 0) {
          const invitee = invitees.at(0);
          const staffName = invitee?.displayName;
          const staffEmail = invitee?.email;
          if (!staffName || !staffEmail) {
            errorMessages.push(
              GetMessageWithIntl(intl, {
                id: 'E0000118',
                value: {
                  $1: bumc.businessUnitManagement?.code ?? '',
                  $2: bumc.businessUnitManagement?.displayName ?? '',
                },
              })
            );
          }
        } else {
          errorMessages.push(
            GetMessageWithIntl(intl, {
              id: 'E0000118',
              value: {
                $1: bumc.businessUnitManagement?.code ?? '',
                $2: bumc.businessUnitManagement?.displayName ?? '',
              },
            })
          );
        }
      });
    } catch (err) {
      getExceptionMessage(intl, err);
      throw err;
    }

    if (errorMessages.length > 0) {
      error(errorMessages);
      return true;
    }

    const targetElm = document.querySelector('.tenant-invitation-dialog');
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: contact ?? '',
          ref: requiredInputArea,
        },
      ])
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let tmpPreviewMessage = previewMessage;
    tmpPreviewMessage = tmpPreviewMessage.replace(
      '{companyName}',
      inputOption.companyName ?? ''
    );
    tmpPreviewMessage = tmpPreviewMessage.replace(
      '{staffName}',
      inputOption.staffName ?? ''
    );

    tmpPreviewMessage = tmpPreviewMessage.replace('{message}', message ?? '');
    tmpPreviewMessage = tmpPreviewMessage.replace('{contact}', contact ?? '');
    setPreview(tmpPreviewMessage);
  }, [
    message,
    contact,
    previewMessage,
    inputOption.companyName,
    inputOption.staffName,
  ]);

  useEffect(() => {
    setMessage('');
    setContact('');
  }, [isOpen]);

  const handleCancel = () => {
    onCancel();
    setMessage('');
    setContact('');
  };

  const handleDecision = async (result: TenantInvitationDialogResult) => {
    if (await isInputError()) {
      return;
    }
    onDecision(result);
  };
  // ダイアログ関係
  const view = (
    <div className="tenant-invitation-dialog">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {messageOption.messageLabelId &&
              GetMessageWithIntl(intl, messageOption.messageLabelId)}
          </span>
        </p>
      </div>
      <div className="tenant-invitation-dialog-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              {GetMessageWithIntl(intl, {
                id: 'sendBusinessUnitManagement',
                prefixId: 'TenantInvitation',
              })}
              : {inputOption.businessUnitManagementIds.length}件
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <Textarea
                name="message"
                labelId="TenantInvitation.message"
                value={message}
                columns={['message']}
                className="w-100 mh-normal"
                properties={[
                  {
                    name: 'message',
                    propertyName: 'message',
                    propertyValue: 'message',
                  },
                ]}
                onChangeState={setMessage}
              />
            </div>
          </div>
        </div>
        <div className="input-line" ref={requiredInputArea}>
          <div className="item-group-100">
            <div className="w-75">
              <Textarea
                name="contact"
                labelId="TenantInvitation.contact"
                value={contact}
                columns={['contact']}
                className="w-100 mh-short"
                properties={[
                  {
                    name: 'contact',
                    propertyName: 'contact',
                    propertyValue: 'contact',
                  },
                ]}
                validateOption={{
                  required: true,
                }}
                onChangeState={setContact}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="preview"
                labelId="TenantInvitation.preview"
                value={preview}
                columns={['preview']}
                className="w-100 mh-high"
                properties={[
                  {
                    name: 'preview',
                    propertyName: 'preview',
                    propertyValue: 'preview',
                  },
                ]}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => handleCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="high"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: 'send',
          })}
          onClick={() =>
            handleDecision({ message: message ?? '', contact: contact ?? '' })
          }
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
}
