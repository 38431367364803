import {
  ListView,
  ViewMenu,
  MenuActionItem,
  Preset,
  Property,
} from '~/shared/components/ui';
import { PresetItem } from '~/shared/services';
import { FullMethodName_ListProgramOptions } from '~/shared/utils';

const VIEW_ID = 'EXTEND_MASTER_PROGRAMOPTION_LIST';

const childrenPresetItem: PresetItem[] = [
  {
    name: 'listView',
    property: [
      { name: 'categoryName', propertyName: 'width', propertyValue: '10rem' },
      {
        name: 'category.displayNameLang',
        propertyName: 'width',
        propertyValue: 'auto',
      },
      { name: 'code', propertyName: 'width', propertyValue: '10rem' },
    ],
  },
];

const schema: Property[] = [
  { name: 'categoryName', typeName: 'string' },
  { name: 'category.displayNameLang', typeName: 'string' },
  { name: 'code', typeName: 'string' },
];

const preset: Preset = {
  filter: {},
  propertyNames: ['categoryName', 'category.displayNameLang', 'code'],
};

export const UISampleListViews = () => {
  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: () => {},
    });
    return menuItems;
  };

  return (
    <div className="categories">
      <h3 id="extend-list-view">
        拡張版リストビュー
        <br />
        <small>※APIエンドポイントを指定してデータを閲覧するリスト</small>
      </h3>
      <h4>
        ■グループ化リスト
        <br />
        <small>
          ユーザ名称を種別コード(と種別名)ごとにグループ化し、種別コード・種別名・グループ内のユーザーコードの数を表示する
        </small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <ListView
              fullMethodName={FullMethodName_ListProgramOptions}
              pageInfo={{
                headerTitle: { viewId: VIEW_ID },
                preset: preset,
                schema: schema,
                menuItem: setMenuActionItem(),
                menuTarget: 'programOptionId',
                presetItems: childrenPresetItem,
                listSkipType: {
                  isTotal: true,
                  isOutput: true,
                  isFilter: true,
                  isFooterMenu: true,
                  isListActionMenu: true,
                  isCheckbox: true,
                },
              }}
              isReload={true}
              filterItemOption={{
                aggregateStages: [
                  {
                    $group: {
                      _id: {
                        categoryName: 'categoryName',
                        'category.displayNameLang': 'category.displayNameLang',
                      },
                      code: {
                        $count: {},
                      },
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
