import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import {
  Checkbox,
  ConfirmationDialog,
  DetailConfirmationDialog,
  HistoryDialog,
  NavigationIconMenu,
  PageNavigation,
  SurveyReplyDialog,
  SystemNotificationSettingDialog,
  TabItem,
  Tabs,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  FullMethodName_ListEstimateResultDetails,
  FullMethodName_ListEstimateResultSummarys,
  FullMethodName_ListEstimateResults,
  FullMethodName_SharedListEstimateSelections,
  HistoryItems,
  PageState,
  ViewId,
  getDateFormatWithTimezone,
  getHistoryItems,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getWorkerExceptionMessage,
  isShowNotificationArea,
} from '~/shared/utils';
import {
  EstimateConfirmationHeader,
  handleFileViewer,
} from '~/tenant/estimate/pages/components/EstimateConfirmationHeader';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { FilterRequest } from '~/worker';
import { EstimateRequestTabItem } from './parts/EstimateRequestTabItem';
import { EstimateResultTabItem } from './parts/EstimateResultTabItem';
import './EstEstimateResultConfirmation.css';
import { CaptionButton } from '~/shared/components/ui/Button';
import { formatDisplayRequiredItems } from './parts/formatter';
import { toCommaTypeString } from '~/shared/utils/formatter';
import {
  convertUserReference,
  convertLongToString,
} from '~/shared/utils/converter';

const VIEW_ID: ViewId = 'EST_ESTIMATE_RESULT_CONFIRMATION';
const historyTypeName = 'mtechnavi.api.estimation.EstimateResult';
type EstimateResultSummary = mtechnavi.api.estimation.IEstimateResultSummary;
type EstimateResult = mtechnavi.api.estimation.IEstimateResult;
type EstimateResultDetail = mtechnavi.api.estimation.IEstimateResultDetail;
type SharedEstimateSelection = mtechnavi.api.estimation.IEstimateSelection;

interface HeaderViewlistData {
  estimateResultDetailId: string;
  assetId: string;
  detailNumber: string;
  displayName: string;
  amount: string;
  requiredItems: string;
  assetName: JSX.Element;
}

export function EstEstimateResultConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const handleError = useErrorHandler();
  const [isLoading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoadingResult, setLoadingResult] = useState(false);
  const [isLoadingResultDetail, setLoadingResultDetail] = useState(false);
  const [isLoadingSelection, setLoadingSelection] = useState(false);
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // 開封リクエスト重複防止制御用
  const isEstimateResultOpenRequested = useRef<boolean>(false);

  // PageInfo
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  // 履歴通知表示
  const [isNotificationShow, setNotificationShow] = useState<boolean>(false);

  // ダイアログ
  // 明細確認ダイアログ
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  // 履歴ダイアログ
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  // システム通知設定ダイアログ
  const [systemNotificationDialogOpen, setSystemNotificationDialogOpen] =
    useState(false);
  // 回答ダイアログ
  const [sendReplyDialogOpen, setSendReplyDialogOpen] = useState(false);
  // 回答送信確認ダイアログ
  const [sendReplyConfirmationOpen, setSendReplyConfirmationOpen] =
    useState(false);

  // Item情報
  const [item, setItem] = useState<EstimateResultSummary>();
  const estimateResultData = useRef<EstimateResult>({});
  const estimateResultDetailData = useRef<EstimateResultDetail[]>([]);
  const estimateSelectionData = useRef<SharedEstimateSelection>({});
  const [historys, setHistorys] = useState<HistoryItems[]>([]);
  const [isSendReply, setSendReply] = useState(false);
  const [replyComment, setReplyCommnet] = useState('');
  const [declineCheckbox, setDeclineCheckbox] = useState<string[] | undefined>(
    []
  );

  // アクションID一時管理
  const detailConfirmationDialogId = useRef<string>();

  // メッセージ
  const confirmationMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };
  const historyMessage = GetMessageWithIntl(intl, {
    id: 'I0000005',
    value: { $1: historys?.length > 0 ? historys[0].slip_category : '' },
  });
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  // ローディング制御
  useEffect(() => {
    if (isLoadingResult || isLoadingResultDetail || isLoadingSelection) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoadingResult, isLoadingResultDetail, isLoadingSelection]);

  // エラー
  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    iconItems.push({
      name: 'history',
      displayName: '履歴',
      func: () => setHistoryOpen(true),
    });
    iconItems.push({
      name: 'recipients',
      displayName: '通知設定',
      func: () => {
        setSystemNotificationDialogOpen(true);
      },
    });
    return iconItems;
  };

  // paginator の ID 取得処理
  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 1) {
        const idPath = splitPath[splitPath.length - 1];
        const endpointPath = splitPath[splitPath.length - 2];
        if (
          idPath !== 'est-estimate-result-confirmation' &&
          endpointPath === 'est-estimate-result-confirmation'
        ) {
          return [idPath];
        }
      }
    }
    return [];
  };

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListEstimateResultSummarys,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    requestBody: {
      estimateResultIds: getTargetId(),
    },
    filter: {
      $or: [{ estimateResultSummaryId: { $in: getTargetId() } }],
    },
    sort: getSortSettingFromLocalStorage('EST_ESTIMATE_RESULT_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  // pageデータ変更時の処理
  useEffect(() => {
    const items = page.originalItems as unknown as EstimateResultSummary[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setNotificationShow(false);
  }, [page]);

  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListEstimateResultSummarys,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListEstimateResultSummarys,
      pageNumber: n,
    });
  };

  // 初回画面用データ
  useEffect(() => {
    handleReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!item) {
      return;
    }
    (async () => {
      setLoading(true);
      // 未開封の場合は開封リクエストをしてリロードする
      if (
        item?.status?.systemName == 'B00' &&
        !isEstimateResultOpenRequested.current
      ) {
        try {
          isEstimateResultOpenRequested.current = true;
          await window.App.services.ui.worker.apiCall({
            actionName: 'openEstimateRequestUnit',
            request: {
              estimateRequestUnitId: item?.estimateResultSummaryId,
            },
          });
          handleReload();
          return;
        } catch (err) {
          error(getWorkerExceptionMessage(intl, err));
          setLoading(false);
          return;
        } finally {
          isEstimateResultOpenRequested.current = false;
        }
      }

      try {
        await Promise.all([
          getEstiamteResult(), // 回答データ
          getEstimateResultDetail(), // 回答詳細データ
          getEstimateSelection(), // 回答選考データ
          getHistory(), // 履歴データ,
        ]);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
      } finally {
        setLoading(false);
      }
    })();

    // データ取得時だけにしたいので例外的に除去
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  // 履歴一覧を取得
  const getHistory = async () => {
    try {
      const datas = await getHistoryItems(intl, historyTypeName, [
        item?.estimateResultSummaryId ?? '',
      ]);

      setHistorys(datas);
      // 最新の履歴通知レベルによる制御
      setNotificationShow(isShowNotificationArea(datas));
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
  };

  // 見積回答情報
  const getEstiamteResult = async () => {
    setLoadingResult(true);
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListEstimateResults,
        filter: {},
        requestBody: {
          estimateResultIds: [item?.estimateResultSummaryId ?? ''],
        },
        sort: [],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.estimation.IListEstimateResultsResponse;
      const listItem: EstimateResult[] = resList.items ?? [];
      estimateResultData.current = listItem.length > 0 ? listItem[0] : {};
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoadingResult(false);
    }
  };

  // 見積回答詳細
  const getEstimateResultDetail = async () => {
    setLoadingResultDetail(true);
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListEstimateResultDetails,
        filter: {},
        requestBody: {
          estimateResultIds: [item?.estimateResultSummaryId ?? ''],
        },
        sort: [
          {
            'estimateRequestDetail.detailAutoName': 'asc',
          },
        ],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.estimation.IListEstimateResultDetailsResponse;
      const listItem: EstimateResultDetail[] = resList.items ?? [];
      estimateResultDetailData.current = listItem;
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoadingResultDetail(false);
    }
  };

  // 見積選考
  const getEstimateSelection = async () => {
    setLoadingSelection(true);
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_SharedListEstimateSelections,
        filter: {},
        requestBody: {
          estimateResultIds: [item?.estimateResultSummaryId ?? ''],
        },
        sort: [],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.estimation.ISharedListEstimateSelectionsResponse;
      const listItem: SharedEstimateSelection[] = resList.items ?? [];
      estimateSelectionData.current = listItem.length > 0 ? listItem[0] : {};
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoadingSelection(false);
    }
  };

  const tabItems = (): TabItem[] => {
    const tabItems: TabItem[] = [];
    tabItems.push({
      label: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'request',
      }),
      tabContent: (
        <EstimateRequestTabItem
          viewId={VIEW_ID}
          estimateResult={estimateResultData.current ?? {}}
          onChangeLoadingState={setLoading}
          handleReload={handleReload}
        />
      ),
    });
    tabItems.push({
      label: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'result',
      }),
      tabContent: (
        <EstimateResultTabItem
          viewId={VIEW_ID}
          estimateResult={estimateResultData.current ?? {}}
          estimateResultDetails={estimateResultDetailData.current ?? {}}
          estimateResultSummary={item ?? {}}
          onChangeLoadingState={setLoading}
          handleReload={handleReload}
        />
      ),
    });

    // setTabs(tabItems);
    return tabItems;
  };

  const isEmptyRequiredAmount = () => {
    return estimateResultDetailData.current.some((v) => {
      if (v.estimateRequestDetail?.requiredMaterialCost) {
        if (v.materialCost === null || v.materialCost?.amountNum === '') {
          return true;
        }
        return false;
      }
      if (v.estimateRequestDetail?.requiredProcessingCost) {
        if (v.processingCost === null || v.processingCost?.amountNum === '') {
          return true;
        }
        return false;
      }
      if (v.estimateRequestDetail?.requiredAdministrativeCost) {
        if (
          v.administrativeCost === null ||
          v.administrativeCost?.amountNum === ''
        ) {
          return true;
        }
        return false;
      }
      if (v.estimateRequestDetail?.requiredFreightCost) {
        if (v.freightCost === null || v.freightCost?.amountNum === '') {
          return true;
        }
        return false;
      }
    });
  };

  const renderFooter = () => {
    if (
      currentTab === 1 &&
      ['B00', 'B01'].includes(item?.status?.systemName ?? '')
    ) {
      return (
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line no-space">
              <div className="item-group-100 no-space">
                <CaptionButton
                  name="button"
                  buttonType="basic"
                  caption={GetMessageWithIntl(intl, {
                    id: 'estimateResult.sendReply',
                    prefixId: VIEW_ID,
                  })}
                  onClick={() => {
                    if (declineCheckbox && declineCheckbox.length === 0) {
                      if (
                        estimateResultDetailData.current.some(
                          (v) => !v.amount?.amountNum
                        )
                      ) {
                        error([GetMessageWithIntl(intl, { id: 'E0000134' })]);
                        return;
                      }
                      if (isEmptyRequiredAmount()) {
                        error([GetMessageWithIntl(intl, { id: 'E0000135' })]);
                        return;
                      }
                    }
                    setSendReplyDialogOpen(true);
                  }}
                />
                <div className="decline-checkbox">
                  <Checkbox
                    name={'decline'}
                    items={[
                      {
                        value: '1',
                        displayName: GetMessageWithIntl(intl, {
                          id: 'estimateResult.decline',
                          prefixId: VIEW_ID,
                        }),
                      },
                    ]}
                    columns={['decline']}
                    value={declineCheckbox}
                    onChangeState={setDeclineCheckbox}
                    validateOption={{ isSkippedValidation: true }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const setHeaderViewList = () => {
    const viewData: HeaderViewlistData[] = [];
    estimateResultDetailData?.current.map((data) => {
      const tmp: HeaderViewlistData = {
        estimateResultDetailId: data?.estimateResultDetailId ?? '',
        assetId: data?.estimateRequestDetail?.attachment?.assetId ?? '',
        detailNumber: convertLongToString(
          data?.estimateRequestDetail?.detailAutoName
        ),
        displayName: data?.estimateRequestDetail?.displayName ?? '',
        amount: [
          toCommaTypeString(data?.estimateRequestDetail?.quantity?.amountNum),
          data?.estimateRequestDetail?.quantity?.unit?.displayNameLang?.ja ??
            '',
        ].join(''),
        requiredItems: formatDisplayRequiredItems(
          intl,
          data?.estimateRequestDetail
        ),
        assetName: (
          <a
            href="#"
            onClick={() => {
              handleFileViewer([
                data?.estimateRequestDetail?.attachment?.assetId ?? '',
              ]);
            }}
          >
            {data?.estimateRequestDetail?.attachment?.filename ?? ''}
          </a>
        ),
      };
      viewData.push(tmp);
    });
    return viewData;
  };

  /**
   * システム通知設定ダイアログの確定処理
   */
  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const userReference = await convertUserReference(myEmail);
      const req: mtechnavi.api.worker.ISystemNotificationSetting = {
        surveyReceptionId: item?.estimateResultSummaryId ?? '',
        appendUser:
          result === 'setAsNotificationDestination' ? userReference : null,
        removeUser:
          result !== 'setAsNotificationDestination' ? userReference : null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateEstimateResultSystemNotificationSetting',
        request: req,
      });
      success([toastSuccess]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
  };

  const handleSnedReply = async () => {
    setLoading(true);
    const req: mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest =
      {
        estimateResultId: item?.estimateResultSummaryId,
        comment: replyComment,
        declined: !isSendReply,
      };
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'sharetoEstimateResultWithRelatedItems',
        request: req,
      });
      success([toastSuccess]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    setSendReplyConfirmationOpen(false);
    setSendReplyDialogOpen(false);
    navi('/estimate/est-estimate-result-list');
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstEstimateResultConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/estimate/est-estimate-result-list"
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              iconItems={navigationIconItems()}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: false,
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="info">
              <div className="basic-info-body">
                {/* ////通知枠//// */}
                <div
                  className={`notification-area ${
                    isNotificationShow ? '' : 'close'
                  }`}
                >
                  {/* {履歴通知エリア} */}
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <div className="notification-display-area">
                          {historyMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info-body-header">
                  <EstimateConfirmationHeader
                    estimateResultHeaderInfo={{
                      displayName: `${item?.displayName ?? ''} ${
                        item?.status?.displayNameLang?.ja ?? ''
                      }${
                        estimateSelectionData?.current.feedbackCategory
                          ?.displayNameLang?.ja
                          ? ':' +
                            estimateSelectionData?.current.feedbackCategory
                              ?.displayNameLang?.ja
                          : ''
                      }`,
                      estimateDeadlineDt: getDateFormatWithTimezone(
                        estimateResultData?.current?.estimateRequest
                          ?.estimateDeadlineDt
                      ),
                      requestAutoName: convertLongToString(
                        item?.estimateRequestUnitAutoName
                      ),
                      requestAutoNameLabelId: 'estimateRequestUnitAutoName',
                      desiredReceivedDt: `${
                        getDateFormatWithTimezone(
                          estimateResultData?.current?.estimateRequest
                            ?.desiredReceiveDt
                        ) ?? ''
                      } ${
                        estimateResultData?.current?.estimateRequest
                          ?.desiredReceiveType?.displayNameLang?.ja ?? ''
                      }${
                        estimateResultData?.current?.estimateRequest
                          ?.desiredReceiveType?.displayNameLang?.ja &&
                        estimateResultData?.current?.estimateRequest
                          ?.desiredReceiveRemarks
                          ? ' / '
                          : ''
                      }${
                        estimateResultData?.current?.estimateRequest
                          ?.desiredReceiveRemarks ?? ''
                      }`,
                      requester:
                        estimateResultData?.current?.estimateRequest
                          ?.requestingCompany?.displayNameLang?.ja ?? '',
                      deliveryPoint:
                        estimateResultData?.current?.estimateRequest
                          ?.deliveryPointType?.systemName === 'B01'
                          ? (estimateResultData?.current?.estimateRequest
                              ?.deliveryPoint?.displayNameLang?.ja ?? '') +
                            (estimateResultData?.current?.estimateRequest
                              ?.deliveryAddress?.addressLine ||
                            estimateResultData?.current?.estimateRequest
                              ?.deliveryAddress?.postalCode
                              ? '(' +
                                (estimateResultData?.current?.estimateRequest
                                  ?.deliveryAddress?.postalCode
                                  ? '〒' +
                                    (estimateResultData?.current
                                      ?.estimateRequest?.deliveryAddress
                                      ?.postalCode ?? '') +
                                    ' '
                                  : '') +
                                ((estimateResultData?.current?.estimateRequest
                                  ?.deliveryAddress.addressLine ?? '') +
                                  ')')
                              : '')
                          : (estimateResultData?.current?.estimateRequest
                              ?.deliveryAddress?.postalCode
                              ? `〒${
                                  estimateResultData?.current?.estimateRequest
                                    ?.deliveryAddress?.postalCode ?? ''
                                } `
                              : '') +
                            (estimateResultData?.current?.estimateRequest
                              ?.deliveryAddress?.addressLine ?? ''),
                      contactProperties: `${
                        estimateResultData?.current?.estimateRequest
                          ?.contactOrganizationUnit?.displayNameLang?.ja ?? ''
                      } ${
                        estimateResultData?.current?.estimateRequest
                          ?.contactStaff?.displayNameLang?.ja ?? ''
                      }`,
                    }}
                    listIconViewInfo={{
                      viewData: setHeaderViewList(),
                      keyColumn: 'estimateResultDetailId',
                      assetIdColumn: 'assetId',
                      columnInfo: [
                        {
                          header: { id: 'detailNumber', prefixId: VIEW_ID },
                          propertyName: 'detailNumber',
                          width: '6rem',
                        },
                        {
                          header: { id: 'displayName', prefixId: VIEW_ID },
                          propertyName: 'displayName',
                        },
                        {
                          header: { id: 'amount', prefixId: VIEW_ID },
                          propertyName: 'amount',
                          width: '10rem',
                          align: 'right',
                        },
                        {
                          header: { id: 'requiredItems', prefixId: VIEW_ID },
                          propertyName: 'requiredItems',
                          width: '15rem',
                        },
                        {
                          header: { id: 'assetName', prefixId: VIEW_ID },
                          propertyName: 'assetName',
                          width: '10rem',
                        },
                      ],
                      func: (v: HeaderViewlistData) => {
                        detailConfirmationDialogId.current =
                          v.estimateResultDetailId;
                        setDetailDialogOpen(true);
                      },
                    }}
                  />
                </div>
                <Tabs
                  tabItems={tabItems()}
                  onChangeSelecetedTabIndex={setCurrentTab}
                />
              </div>
            </div>
          </div>
          {/* footerエリア */}
          {renderFooter()}
        </div>
        <Toast />
      </Container>
      {/* システム通知設定ダイアログ */}
      <SystemNotificationSettingDialog
        isOpen={systemNotificationDialogOpen}
        inputData={estimateResultData.current.systemNotificationUsers ?? []}
        onDecision={(result) => {
          handleSystemNotificationSetting(result);
        }}
        onCancel={() => {
          setSystemNotificationDialogOpen(false);
        }}
      />
      {/* 履歴ダイアログ */}
      <HistoryDialog
        isOpen={isHistoryOpen}
        messageOption={{
          headerLabelId: {
            id: 'history',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        inputOption={{
          data: historys ?? [],
          keyColumn: 'history_id',
          columns: [
            {
              propertyName: 'slip_category',
              width: '12rem',
              header: {
                prefixId: 'HistoryDialog',
                id: 'slip_category',
              },
            },
            {
              propertyName: 'occurred_at',
              width: '12rem',
              header: {
                prefixId: 'HistoryDialog',
                id: 'occurred_at',
              },
            },
            {
              propertyName: 'content',
              header: {
                prefixId: 'HistoryDialog',
                id: 'content',
              },
            },
          ],
        }}
        onCancel={() => {
          setHistoryOpen(false);
        }}
      />
      {/* 明細確認ダイアログ */}
      <DetailConfirmationDialog
        isOpen={detailDialogOpen}
        inputOption={{
          detailInfo:
            estimateResultDetailData.current.find(
              (v) =>
                v.estimateResultDetailId === detailConfirmationDialogId.current
            )?.estimateRequestDetail ?? {},
        }}
        onCancel={() => {
          setDetailDialogOpen(false);
        }}
      />
      {/* 回答送信ダイアログ */}
      <SurveyReplyDialog
        isOpen={sendReplyDialogOpen}
        outputOption={{
          mode: 'estimateResult',
          isDisabled: true,
          isCanPartialReply: false,
          initialItem:
            (declineCheckbox?.length ?? 0) > 0 && declineCheckbox![0] === '1'
              ? 'decline'
              : 'sendReply',
        }}
        messageOption={{
          headerLabelId: {
            id: 'survey_reply',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        onCancel={() => {
          setSendReplyDialogOpen(false);
        }}
        onDecision={(result) => {
          result.replyStatus === 'sendReply'
            ? setSendReply(true)
            : setSendReply(false);
          setReplyCommnet(result.comment);
          setSendReplyConfirmationOpen(true);
        }}
      />
      {/* 回答送信確認ダイアログ */}
      <ConfirmationDialog
        isOpen={sendReplyConfirmationOpen}
        viewMessage={confirmationMessage}
        messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
        onDecision={() => {
          handleSnedReply();
        }}
        onCancel={() => setSendReplyConfirmationOpen(false)}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
}
