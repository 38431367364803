import { useState, CSSProperties, useEffect } from 'react';
import './Checkbox.css';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage } from '../Message/Message';

export interface CheckboxProps {
  name: string;
  className?: string;
  items: CheckboxItem[];
  value?: string[] | string;
  validator?: (v: string[]) => string[];
  validateOption?: CheckboxValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string[]) => void;
  workingBlur?: Date;
}

export interface CheckboxItem {
  value: string;
  displayName: string;
}

export interface CheckboxValidateOption {
  isSkippedValidation?: boolean; // このコンポーネントで設定されているバリデーションを行うかどうか
  required?: boolean;
  isSkippedRequireCheck?: boolean;
}

export function Checkbox(props: CheckboxProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  // checkbox要素のクラス名
  const checkboxClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validate =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;
  const isSkippedRequireCheck =
    props.validateOption?.isSkippedRequireCheck ?? false;

  // useState
  const [message, setMessage] = useState<string[]>([]);
  const [checkedValue, setCheckedValue] = useState<string[]>(
    typeof props.value === 'string' ? [props.value] : props.value ?? []
  );
  const changeParentState = props.onChangeState ?? (() => {});
  useEffect(() => {
    setCheckedValue(
      typeof props.value === 'string' ? [props.value] : props.value ?? []
    );
  }, [props.value]);

  const handleChange = (checkbox: HTMLInputElement) => {
    // チェックボックスの状態を管理
    let changedValue = [...checkedValue];
    if (checkbox.checked) {
      changedValue.push(checkbox.value);
    } else {
      changedValue = checkedValue.filter((v) => {
        return v !== checkbox.value;
      });
    }
    setCheckedValue([...changedValue]);
    changeParentState([...changedValue]);

    // 固有バリデーションを行うかどうか
    if (isSkippedValidation) {
      // 固有バリデーションはスキップして、親要素でのバリデーションを行う
      return;
    }

    const errorMessage: string[] = [];

    // 必須入力チェック
    if (!isSkippedRequireCheck && required && changedValue.length < 1) {
      // 入力必須で未入力の場合はエラーメッセージを出して以降の処理はスキップ
      setMessage([REQUIRED_MESSAGE]);
      return;
    }

    // バリデーション
    errorMessage.push(...validate(changedValue));

    setMessage([...errorMessage]);
  };

  /*
 チェックボックスの必須チェックを行うために、強制的にチェック状態の変更と同様の処理を行う。
 用途としては連絡先入力依頼ダイアログで依頼対象を未選択の状態で送信ボタンを押した時のチェックを行うなど。
*/
  useEffect(() => {
    if (!props.workingBlur) {
      return;
    }
    const errorMessage: string[] = [];

    // 必須入力チェック
    if (required && checkedValue.length < 1) {
      // 入力必須で未入力の場合はエラーメッセージを出して以降の処理はスキップ
      setMessage([REQUIRED_MESSAGE]);
      return;
    }

    // バリデーション
    errorMessage.push(...validate(checkedValue));
    setMessage([...errorMessage]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workingBlur]);

  useEffect(() => {
    // 必須制御が変わって任意になる場合にエラー表示をクリアする
    if (!required) {
      // 値が空の場合だけに対応範囲を限定する
      if (checkedValue.length < 1) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なので、props.validateOption?.requiredのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  useEffect(() => {
    // 必須チェック不要になる場合にエラー表示をクリアする
    if (isSkippedRequireCheck) {
      // 値が空の場合だけに対応範囲を限定する
      if (checkedValue.length < 1) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkippedRequireCheck]);

  return (
    <div className="Checkbox">
      {/* チェックボックス */}
      <div className="main-contents">
        {props.items.map((val, i) => {
          // inputとlabelを関連付けするためのランダム値を生成
          const randomId = Math.random().toString(32).substring(2);
          return (
            <div key={i} className="margin">
              <input
                id={randomId}
                name={props.name}
                className={checkboxClassName}
                type="checkbox"
                onChange={(event) => handleChange(event.target)}
                value={val.value}
                checked={checkedValue.includes(val.value)}
                disabled={props.disabled}
                style={style}
              />
              <label htmlFor={randomId}>{val.displayName}</label>
            </div>
          );
        })}
      </div>
      {/* エラー表示 */}
      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
