import { useIntl } from 'react-intl';
import {
  ViewMenu,
  IconMenuName,
  ListViewPageSetting,
  MenuActionItem,
  MenuActionType,
  NavigatorSelectBoxtItemType,
} from './commonType';
import {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Checkbox } from '../../parts/Checkbox/Checkbox';
import { GetMessageComponent, MessageProps } from '../../parts/Message/Message';
import { IconButton } from '../Button';
import { createRandomKey, isValidMenuValue } from './utils';
import { CaptionButton } from '../../parts/Button/CaptionButton';
import { ListNavigator } from './ListNavigator';
import { dataViewInitialState, dataViewReducer } from './dataViewReducer';
export interface DataViewHeaderInfo {
  message: MessageProps;
  width?: string; // カラムの表示幅
  margin?: string; // 現状、明細部分の右寄せを行うためにmarginを設定している
}

export interface DataViewPageSizeOption {
  pageSize?: number;
  isDisplay?: boolean;
  displayItemSize?: NavigatorSelectBoxtItemType[];
}

export interface DataViewExtendCheckboxOption {
  itemWidth: string;
  message?: MessageProps;
  targetId: string;
  func: (v?: string) => void;
  checkedValues?: string[];
}

export interface DataViewPageInfo {
  title?: string;
  data?: Object[];
  header: DataViewHeaderInfo[];
  pageSize?: number;
  pageSizeOption?: DataViewPageSizeOption;
  maxMenuColumn?: string;
  menuTarget?: string;
  menuItem?: MenuActionItem[];
  initialChecks?: string[];
  initialCurrentPage?: number;
}

export interface ListskipValue {
  isVisibleCheckbox?: boolean;
  isHeaderIconItem?: boolean;
  isListIconItem?: boolean;
  isFooterButton?: boolean;
  isActionListOnly?: {
    all?: boolean;
    menu?: boolean;
    check?: boolean;
  };
}

interface DataViewProps {
  pageInfo: DataViewPageInfo;
  extendCheckboxOption?: DataViewExtendCheckboxOption[];
  onChangeState?: (pageState: ListViewPageSetting) => void;
  onReload?: () => void;
  listSkipValue?: ListskipValue;
}

export function DataView(props: DataViewProps) {
  const intl = useIntl();
  const [
    {
      allContents,
      displayContents,
      selectedItemIds,
      maxPage,
      currentPage,
      pageSize,
    },
    dispatchDataViewState,
  ] = useReducer(dataViewReducer, dataViewInitialState);

  const viewLabelItems = useMemo<Array<string>>(() => {
    return props.pageInfo.header.map((items) => items.message.id ?? '');
  }, [props.pageInfo.header]);

  const onParentChangeState = useMemo(
    () => props.onChangeState ?? (() => {}),
    [props.onChangeState]
  );

  const selectMenuId = () => {
    return [...selectedItemIds];
  };

  // header部分のチェックボックス処理
  const handleHeaderSelect = useCallback(
    (headerSelect: string[]) => {
      if (headerSelect[0] !== '1') {
        dispatchDataViewState({ type: 'setSelectedItems', selectedItems: [] });
        return;
      }
      // 型は呼び出し元によるため固定できないため、lintから除外する
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const allIds = (props.pageInfo.data || [])?.map((item: any, index) => {
        if (!props?.pageInfo?.menuTarget) {
          return index;
        }
        return item[props?.pageInfo?.menuTarget as keyof typeof item];
      });
      dispatchDataViewState({
        type: 'setSelectedItems',
        selectedItems: allIds,
      });
    },
    [props.pageInfo.data, props?.pageInfo?.menuTarget]
  );

  // 状態変更時の利用側への通知
  useEffect(() => {
    onParentChangeState({
      checkedValues: selectedItemIds,
      pageSize: `${pageSize}`,
      pageNumber: `${currentPage}`,
    });
  }, [onParentChangeState, selectedItemIds, pageSize, currentPage]);

  // ページデータ変更時
  useEffect(() => {
    dispatchDataViewState({
      type: 'changeContents',
      contents: props.pageInfo.data,
    });
  }, [props.pageInfo.data]);

  // 初期チェック状態変更時
  useEffect(() => {
    if (props.pageInfo.initialChecks) {
      dispatchDataViewState({
        type: 'setSelectedItems',
        selectedItems: props.pageInfo.initialChecks,
      });
    }
  }, [props.pageInfo.initialChecks]);

  const handleRowCheck = (id: string) => {
    dispatchDataViewState({ type: 'changeSelection', selectionItemId: id });
  };

  const handleMovePage = (pageNumber: number) => {
    dispatchDataViewState({
      type: 'changeCurrentPage',
      page: pageNumber,
    });
  };

  // 初期ページ変更時
  useEffect(() => {
    if (props.pageInfo.initialCurrentPage) {
      dispatchDataViewState({
        type: 'changeCurrentPage',
        page: props.pageInfo.initialCurrentPage,
      });
    }
  }, [props.pageInfo.initialCurrentPage]);

  // ページサイズ変更時
  useEffect(() => {
    if (props.pageInfo.pageSizeOption?.pageSize) {
      dispatchDataViewState({
        type: 'changePageSize',
        pageSize: props.pageInfo.pageSizeOption?.pageSize,
      });
    }
  }, [props.pageInfo.pageSizeOption?.pageSize]);

  const listIconWidth = () => {
    let width = '';
    const menuItems = props.pageInfo.menuItem ?? [];
    const menuItem = menuItems.find(
      (item) => item.menuActionType === 'listIconMenu'
    );
    if (menuItem) {
      const icons = menuItem.menu.length * 3;
      width = `${icons}rem `;
    }
    return width;
  };

  const handleChangePageSize = (e: React.SetStateAction<number>) => {
    const pageSize = e.valueOf();
    if (typeof pageSize === 'object') {
      return;
    }
    dispatchDataViewState({ type: 'changePageSize', pageSize });
  };

  const setHeaderWidth = () => {
    let text = '';
    text += props.listSkipValue?.isVisibleCheckbox ? '2.5rem ' : '';
    props.extendCheckboxOption
      ? (text += props.extendCheckboxOption
          .map((op) => {
            return op.itemWidth;
          })
          .join(' '))
      : '';

    text += !props.listSkipValue?.isListIconItem ? listIconWidth() : '';
    text += props.pageInfo.header
      .map((v) => {
        return v.width ?? '5rem';
      })
      .join(' ');
    return text;
  };

  const listViewStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: setHeaderWidth(),
    alignItems: 'center',
    flex: 1,
  };

  const contentsRef = useRef<HTMLDivElement>(null);

  const propertyDataViewNumberColumnStyle = (
    propertyName: string
  ): CSSProperties => {
    const numberColumn = props.pageInfo.header.find(
      (v) => v.message.id === propertyName
    )?.margin;
    if (numberColumn) {
      return {
        margin: numberColumn,
      };
    } else {
      return {};
    }
  };

  const renderIconButton = (
    menuItem: ViewMenu,
    className: string,
    content?: {}
  ): JSX.Element => {
    const iconType = menuItem.name as IconMenuName;
    const disabled =
      !!menuItem.disabledCondFn && menuItem.disabledCondFn(content ?? {});
    return (
      <div className={className} key={createRandomKey()}>
        <IconButton
          name={menuItem.name}
          buttonType="basic"
          iconType={iconType}
          onClick={() => {
            let ids: string[] = [];
            ids = selectMenuId();
            if (
              !content &&
              !isValidMenuValue(intl, 'headerIconMenu', menuItem.name, ids)
            ) {
              return;
            }
            if (content) {
              menuItem.func([
                content[props.pageInfo.menuTarget as keyof typeof content],
              ]);
            } else {
              menuItem.func(ids);
            }
          }}
          caption={menuItem.displayName ?? ''}
          disabled={disabled}
        />
      </div>
    );
  };

  /**
   * 指定された種類に応じたアイコンメニューを表示(共通化)
   *
   * @param targetMenuType 対象のメニュータイプ
   * @param item 一覧の時のみ設定
   * @returns
   */
  const renderIconMenu = (
    targetMenuType: MenuActionType,
    content?: {}
  ): JSX.Element[] | JSX.Element => {
    const menuItem = props.pageInfo.menuItem
      ? props.pageInfo.menuItem.find((v) => v.menuActionType === targetMenuType)
      : '';
    if (menuItem) {
      const className =
        menuItem.menuActionType === 'headerIconMenu'
          ? 'header-icon'
          : 'list-icon';
      return menuItem.menu.map((v) => {
        return renderIconButton(v, className, content);
      });
    } else {
      return <></>;
    }
  };

  /**
   * 一覧下部のボタンを描画
   */
  const renderFooterActionButton = (menuItems: MenuActionItem | undefined) => {
    if (menuItems) {
      return menuItems.menu.map((menuItem) => {
        return (
          <div className="button-area" key={createRandomKey()}>
            <CaptionButton
              name={menuItem.name}
              caption={menuItem.displayName}
              buttonType="basic"
              onClick={() => {
                let ids: string[] = [];
                ids = selectMenuId();
                if (!isValidMenuValue(intl, 'footerMenu', menuItem.name, ids)) {
                  return;
                }
                menuItem.func(ids);
              }}
            />
          </div>
        );
      });
    } else {
      return <></>;
    }
  };

  /**
   * 一覧下部のアクションボタンを生成
   */
  const ListFooterButton = () => {
    const menuItems =
      props.pageInfo.menuItem &&
      props.pageInfo.menuItem.find(
        (item) => item.menuActionType === 'footerMenu'
      );
    if (!menuItems || menuItems.menu.length === 0) {
      return <></>;
    }
    return (
      <div className="list-footer-button">
        {renderFooterActionButton(menuItems)}
      </div>
    );
  };

  return (
    <div className="ListView DataView">
      {props.pageInfo.title && (
        <h3 className="area-title">{props.pageInfo.title}</h3>
      )}
      <div className="list-header">
        <ListNavigator
          navigatorPosition="header"
          renderIconMenu={(e) => renderIconMenu(e)}
          renderFooterButton={ListFooterButton}
          handleMovePage={(e) => handleMovePage(e)}
          currentPage={currentPage}
          maxPage={maxPage}
          onReload={props.onReload}
          items={allContents}
          selectBoxProps={{
            items: props.pageInfo.pageSizeOption?.displayItemSize,
            pageSize: pageSize,
            setPageSize: handleChangePageSize,
          }}
          itemSize={allContents.length}
        />
      </div>
      <div className="contents" ref={contentsRef}>
        {/* ヘッダー */}
        <div className="list-caption">
          <div style={listViewStyle}>
            {props.listSkipValue?.isActionListOnly?.all &&
              props.listSkipValue?.isVisibleCheckbox && (
                <div className="list-header-cell check"></div>
              )}
            {!props.listSkipValue?.isActionListOnly?.all &&
              props.listSkipValue?.isVisibleCheckbox && (
                <div className="list-header-cell check">
                  <Checkbox
                    name="all"
                    items={[{ value: '1', displayName: '' }]}
                    onChangeState={handleHeaderSelect}
                    validateOption={{ isSkippedValidation: true }}
                  />
                </div>
              )}
            {props.extendCheckboxOption &&
              props.extendCheckboxOption.map((op, i) => (
                <div className="list-header-cell" key={'extendCheck' + i}>
                  {op.message && <GetMessageComponent {...op.message} />}
                </div>
              ))}
            {!props.listSkipValue?.isHeaderIconItem && (
              <div
                style={{ width: listIconWidth() }}
                className="list-header-cell"
              >
                &nbsp;
              </div>
            )}
            {props.pageInfo.header.map((header, i) => (
              <div className="list-header-cell" key={i}>
                <GetMessageComponent {...header.message} />
              </div>
            ))}
          </div>
        </div>
        {/* コンテンツ */}
        <div className="list-content">
          <div style={listViewStyle}>
            {displayContents.map((content, i) => {
              const checkId =
                content[props?.pageInfo?.menuTarget as keyof typeof content];
              return (
                <Fragment key={`displayContents${i}`}>
                  {props.listSkipValue?.isVisibleCheckbox && (
                    <div
                      className={`item check${i % 2 === 0 ? ' even' : ' odd'}`}
                    >
                      <Checkbox
                        name={'list_' + i}
                        value={selectedItemIds.includes(checkId) ? '1' : ''}
                        items={[{ value: '1', displayName: '' }]}
                        onChangeState={() => handleRowCheck(checkId)}
                        validateOption={{ isSkippedValidation: true }}
                      />
                    </div>
                  )}
                  {props.extendCheckboxOption &&
                    props.extendCheckboxOption.map((op, opIndex) => (
                      <div
                        key={opIndex}
                        className={`item check${
                          i % 2 === 0 ? ' even' : ' odd'
                        }`}
                      >
                        <Checkbox
                          name={(op?.message?.id ?? '') + opIndex}
                          items={[{ value: '1', displayName: '' }]}
                          value={[
                            op.checkedValues?.includes(
                              content[op?.targetId as keyof typeof content]
                            )
                              ? '1'
                              : '',
                          ]}
                          onChangeState={() => {
                            op.func(
                              content[op?.targetId as keyof typeof content]
                            );
                          }}
                          validateOption={{ isSkippedValidation: true }}
                        />
                      </div>
                    ))}
                  {!props.listSkipValue?.isListIconItem && (
                    <div
                      className={`item ${i % 2 === 0 ? ' even' : ' odd'}`}
                      key={`icon-${i}`}
                    >
                      {renderIconMenu('listIconMenu', content)}
                    </div>
                  )}
                  {Object.entries(content).map(([key, value], number) => {
                    if (!viewLabelItems.includes(key)) return;
                    return (
                      <div
                        key={`info${number}`}
                        className={`item${i % 2 === 0 ? ' even' : ' odd'}`}
                      >
                        <div
                          className="viewText"
                          style={propertyDataViewNumberColumnStyle(key)}
                        >
                          {value as string}
                        </div>
                        <div className="description">{value as string}</div>
                      </div>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="list-footer">
        <ListNavigator
          navigatorPosition="footer"
          renderIconMenu={(e) => renderIconMenu(e)}
          renderFooterButton={ListFooterButton}
          handleMovePage={(e) => handleMovePage(e)}
          currentPage={currentPage}
          maxPage={maxPage}
          onReload={props.onReload}
          items={allContents}
          selectBoxProps={{
            items: props.pageInfo.pageSizeOption?.displayItemSize,
            pageSize: pageSize,
            setPageSize: handleChangePageSize,
          }}
          itemSize={allContents.length}
        />
      </div>
    </div>
  );
}
