import { Textarea, Textbox } from '~/shared/components/ui';
import { LinkTextbox } from '~/shared/components/ui/LinkTextbox';

export const UISampleLayouts = () => {
  return (
    <div className="categories">
      <h3 id="flex-layouts">フレキシブル レイアウト</h3>

      <h4>1/1 ( w-100 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>1/2 ( w-50 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-50">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-50">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>1/3 ( w-33 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-33">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-33">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>1/4 ( w-25 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-25">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-25">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-25">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>1:3 ( w-25 : w-75 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-75">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>1:2 ( w-33 : w-66 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-66">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>2:3 ( w-40 : w-60 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-40">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
          <div className="w-60">
            <Textbox name="normal" type="text" columns={['normal']} />
          </div>
        </div>
      </div>

      <h4>テキストエリア 100% ( w-100 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <Textarea
              className="w-100"
              name="textarea"
              columns={['textarea']}
            />
          </div>
        </div>
      </div>

      <h4>テキストエリア 75% ( w-75 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <Textarea className="w-75" name="textarea" columns={['textarea']} />
          </div>
        </div>
      </div>

      <h4>テキストエリア 50% ( w-50 )</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <Textarea className="w-50" name="textarea" columns={['textarea']} />
          </div>
        </div>
      </div>

      <h4>
        テキストエリア 高さ ( mh-high / mh-middle / mh-normal / mh-short )
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <Textarea
              className="w-100 mh-high"
              name="textarea"
              columns={['textarea']}
            />
          </div>
          <div className="w-25">
            <Textarea
              className="w-100 mh-middle"
              name="textarea"
              columns={['textarea']}
            />
          </div>
          <div className="w-25">
            <Textarea
              className="w-100 mh-normal"
              name="textarea"
              columns={['textarea']}
            />
          </div>
          <div className="w-25">
            <Textarea
              className="w-100 mh-short"
              name="textarea"
              columns={['textarea']}
            />
          </div>
        </div>
      </div>

      <h4>リンクテキストボックス</h4>
      <div className="input-line">
        <div className="item-group-100">
          <LinkTextbox
            className="w-50"
            name="linkbox"
            columns={['linkbox']}
            value="https://www.centsys.jp/"
            labelId="homepageUrl"
            isExternalLink={true}
          />
        </div>
      </div>
    </div>
  );
};
