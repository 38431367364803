import {
  Ref,
  RefObject,
  createRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { GroupTreeItem } from './util';
import { GroupTreeEdge } from './GroupTreeEdge';
import { GroupTreeEditActions, GroupTreeEditPane } from './GroupTreeEditPane';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';

export interface GroupTreeNodeRef {
  open: () => void;
  close: () => void;
}

export interface GroupTreeNodeProps<T> {
  data: GroupTreeItem<T>;
  level: number;
  searchWord?: string;
  isShowDisabled?: boolean;
  isParentDisable?: boolean;
  selectedGroup: string | null;
  onSelectGroup: (selectedGroup: string | null) => void;
  onDrop?: (data: { targetId: string; parentId: string }) => void;
  editActions: GroupTreeEditActions<T>;
}

/**
 * ツリーの枝コンポーネント。
 * 子として葉を持つか、さらに枝を持つ。
 */
export const GroupTreeNode = forwardRef(
  <T,>(
    {
      data,
      level,
      searchWord,
      isShowDisabled,
      isParentDisable,
      selectedGroup,
      onSelectGroup,
      onDrop,
      editActions,
    }: GroupTreeNodeProps<T>,
    ref: Ref<GroupTreeNodeRef> | undefined
  ) => {
    const [isOpen, setOpen] = useState<boolean>(true);
    const isSelected = selectedGroup === data.id;
    const isDisabled = isParentDisable || data.isDisabled;

    const refList = useMemo(() => {
      const entries = (data.children || [])
        .filter((item) => !!item.children)
        .map((item): [string, RefObject<GroupTreeNodeRef>] => [
          item.id || '',
          createRef<GroupTreeNodeRef>(),
        ]);
      return Object.fromEntries(entries);
    }, [data]);

    const isMatch = useMemo<boolean | null>(() => {
      if (!searchWord) {
        return null;
      }
      return !!(data.displayName ?? '').match(searchWord);
    }, [data.displayName, searchWord]);

    const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onSelectGroup && onSelectGroup(data.id || null);
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', data.id || '');
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const targetId = e.dataTransfer.getData('text/plain');
      const parentId = data.id || '';
      if (targetId === parentId) {
        return;
      }
      onDrop && onDrop({ targetId, parentId });
    };
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    useImperativeHandle(
      ref,
      (): GroupTreeNodeRef => ({
        open: () => {
          setOpen(true);
          data.children?.forEach((item) => {
            refList[item.id || '']?.current?.open();
          });
        },
        close: () => {
          setOpen(false);
          data.children?.forEach((item) => {
            refList[item.id || '']?.current?.close();
          });
        },
      })
    );

    return (
      <div
        className={`GroupTreeNode id-${data.id} ${
          isSelected ? 'selected' : ''
        } ${level % 2 === 0 ? 'even' : 'odd'} ${isDisabled ? 'disabled' : ''} ${
          isMatch === null ? '' : isMatch ? 'matched' : 'unmatched'
        }`}
        key={data.id}
        onClick={handleSelect}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="GroupLabel" onClick={() => setOpen(!isOpen)}>
          <span className={`GroupToggle ${isOpen ? 'open' : ''}`}>
            {getGenerallyIconElement('down')}
          </span>
          <span>{data.displayName}</span>
          <GroupTreeEditPane
            data={data}
            editActions={editActions}
            isDisabled={isDisabled}
          />
        </div>
        {isOpen && (
          <div className="ChildTree">
            {data.children?.map((item) => {
              if (!isShowDisabled && item.isDisabled) {
                return;
              }
              const hasChild = item && (item.children || []).length > 0;
              if (!hasChild) {
                return (
                  <GroupTreeEdge
                    key={item.id}
                    data={item}
                    level={level + 1}
                    searchWord={searchWord}
                    isParentDisable={isDisabled}
                    selectedGroup={selectedGroup}
                    onSelectGroup={onSelectGroup}
                    editActions={editActions}
                    onDrop={onDrop}
                  />
                );
              }
              return (
                <GroupTreeNode
                  key={item.id}
                  data={item}
                  level={level + 1}
                  searchWord={searchWord}
                  isShowDisabled={isShowDisabled}
                  isParentDisable={isDisabled}
                  selectedGroup={selectedGroup}
                  onSelectGroup={onSelectGroup}
                  editActions={editActions}
                  onDrop={onDrop}
                  ref={refList[item.id || '']}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
