import Long from 'long';
import { useState, useEffect } from 'react';
import {
  PageNavigation,
  PageInfo,
} from '~/shared/components/ui/PageNavigation/PageNavigation';

export const UISamplePageNavigation = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  useEffect(() => {
    setFirstPage(pageNumber === 1);
    setMaxPage(pageNumber === 5);
  }, [pageNumber]);

  const pageInfomation: PageInfo = {
    isVisibleMoveNavi: true,
    isFirstPage: isFirstPage,
    isMaxPage: isMaxPage,
    pageNumber: pageNumber,
  };
  return (
    <div className="navigation">
      <h3 id="pagination">
        改良版ページネーション
        <br />
        <small>
          サプライヤベース開発期にリメイクされたページナビゲーションUI
        </small>
      </h3>
      <PageNavigation
        backpagePath=""
        iconItems={[
          {
            name: 'files',
            displayName: 'ファイル',
            func: () => {},
          },
          {
            name: 'delete',
            displayName: '削除',
            func: () => {},
          },
          {
            name: 'edit',
            displayName: '編集',
            func: () => {},
          },
          {
            name: 'recipients',
            displayName: '通知設定',
            func: () => {},
          },
          {
            name: 'history',
            displayName: '履歴',
            func: () => {},
          },
        ]}
        handleMovePage={(v: number) => {
          setPageNumber(v);
        }}
        pageInfo={pageInfomation}
        infoOption={{
          lastUpdateInfo: {
            isVisibleUpdateInfo: true,
            attribute: 'taro@test.co.test',
            content: '山田太郎',
            attributeAt: Long.fromNumber(Date.now()),
          },
          issuerInfo: { isVisibleIssuerInfo: false },
        }}
      />
    </div>
  );
};
