import React, { useEffect, useRef, useState } from 'react';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { useCommentPane, useCommentPaneDispatch } from './CommentPaneProvider';
import { ThreadForm } from './ThreadForm';
import { ThreadItem } from './ThreadItem';
import { IconButton } from '~/shared/components/parts/Button/IconButton';
import './ThreadList.css';
import { getCompaniesByEstimateRequestId } from './ApiUtil';

interface ThreadListProps {
  unreadMarker?: sharelib.INameOption;
}
export const ThreadList = (props: ThreadListProps) => {
  const { draft, threadSet, showOption, viewerInfo, assetSet } =
    useCommentPane();
  const commentPaneDispatch = useCommentPaneDispatch();
  const listRef = useRef<HTMLDivElement>(null);
  const [companies, setCompanies] =
    useState<mtechnavi.api.company.ICompany[]>();

  const handleToggleThreadList = (open: boolean) => {
    if (open) {
      commentPaneDispatch({ type: 'showCommentPane' });
    } else {
      commentPaneDispatch({ type: 'hideCommentPane' });
    }
  };

  const handleClickThread = (threadId?: string | null) => {
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId,
    });
  };

  useEffect(() => {
    if (!listRef.current) {
      return;
    }
    if (!threadSet.activeThreadId) {
      listRef.current.scroll({ top: 0 });
      return;
    }
    const target = document.getElementById(threadSet.activeThreadId);
    if (!isNeedScroll(listRef.current, target)) {
      return;
    }
    listRef.current.scroll({
      top: (target?.offsetTop || 0) - 9,
      behavior: 'smooth',
    });
  }, [threadSet.activeThreadId]);

  useEffect(() => {
    (async () => {
      if (!assetSet.activeResourceId) {
        return;
      }
      const companies = await getCompaniesByEstimateRequestId(
        assetSet.activeResourceId
      );
      setCompanies(companies);
    })();
  }, [assetSet.activeResourceId]);

  const isNeedScroll = (
    parent: HTMLDivElement,
    child: HTMLElement | null
  ): boolean => {
    if (!child) {
      return false;
    }
    const scrollThreshold = parent.getBoundingClientRect().height / 3;
    return (
      parent.scrollTop + scrollThreshold >
        child.offsetTop + child.getBoundingClientRect().height ||
      parent.scrollTop +
        parent.getBoundingClientRect().height -
        scrollThreshold <
        child.offsetTop
    );
  };

  return (
    <div className="ThreadList">
      <IconButton
        name="openMenu"
        iconType="forum"
        onClick={() => handleToggleThreadList(!showOption.isShowCommentPane)}
      />
      <div
        className={`list ${showOption.isShowCommentPane ? 'show' : ''}`}
        style={{ maxHeight: viewerInfo.height }}
        ref={listRef}
      >
        {draft && <ThreadForm />}
        {threadSet.threadList.map((thread) => {
          if (thread.deletedAt) {
            return;
          }
          return (
            <React.Fragment key={thread.threadId}>
              <ThreadItem
                thread={thread}
                companies={companies}
                unreadMarker={props.unreadMarker}
                isActive={thread.threadId === threadSet.activeThreadId}
                onClick={() => handleClickThread(thread.threadId)}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
