import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleCaptionButtons = () => {
  return (
    <div className="categories">
      <h3 id="caption-buttons">キャプションボタン</h3>
      <h4>enabled</h4>
      <div className="input-line">
        <CaptionButton
          buttonType="high"
          caption="high"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          properties={[
            {
              name: '',
              propertyName: 'textAlign',
              propertyValue: 'center',
            },
          ]}
        />
        <CaptionButton
          buttonType="basic"
          caption="basic"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          properties={[
            {
              name: '',
              propertyName: 'textAlign',
              propertyValue: 'center',
            },
          ]}
        />
        <CaptionButton
          buttonType="sub"
          caption="sub"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
        />
        <CaptionButton
          buttonType="danger"
          caption="danger"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
        />
        <CaptionButton
          buttonType="cancel"
          caption="cancel"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
        />
        <CaptionButton caption="no style" name="" onClick={() => {}} />
      </div>

      <h4>disabled</h4>
      <div className="input-line">
        <CaptionButton
          buttonType="high"
          caption="high"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          disabled={true}
        />
        <CaptionButton
          buttonType="basic"
          caption="basic"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          properties={[
            {
              name: '',
              propertyName: 'disabled',
              propertyValue: true,
            },
          ]}
        />
        <CaptionButton
          buttonType="sub"
          caption="sub"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          disabled={true}
          properties={[
            {
              name: '',
              propertyName: 'textAlign',
              propertyValue: 'center',
            },
          ]}
        />
        <CaptionButton
          buttonType="danger"
          caption="danger"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          disabled={true}
        />
        <CaptionButton
          buttonType="cancel"
          caption="cancel"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          disabled={true}
        />
        <CaptionButton
          caption="no style"
          name=""
          onClick={() => {
            console.debug('CaptionButton');
          }}
          disabled={true}
        />
      </div>
    </div>
  );
};
