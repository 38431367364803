import { GroupTreeItem } from './GroupTreeEditor/util';

export interface MockOrganization {
  organizationCode?: string; // 組織コード
  parentOrganizationCode?: string; // 親組織コード
  displayName?: string; // 組織名
  abbrName?: string; // 組織名(略名)
  isDisabled?: boolean; // 使用不可
}

const NEST_SIZE = 5;
export const generateMockData = (): MockOrganization[] => {
  return generateMockChild('', 0, 0) || [];
};
const generateMockChild = (
  parentCode: string,
  layer: number,
  parentIndex: number
): MockOrganization[] => {
  if (layer >= NEST_SIZE) {
    return [];
  }
  return new Array(layer <= 1 && parentIndex === 0 ? NEST_SIZE - layer : 1)
    .fill(null)
    .flatMap((noop, index) => {
      const code = `${parentCode ? `${parentCode}-` : ''}${index + 1}`;
      return [
        {
          organizationCode: `${code}`,
          parentOrganizationCode: `${parentCode}`,
          displayName: `グループ ${code}`,
          abbrName: `${code}`,
        },
        ...generateMockChild(code, layer + 1, index),
      ];
    });
};

// 配列を受け取り、GroupTreeItemの配列に変換する関数
export function convertGroupTree(
  orgs: MockOrganization[]
): GroupTreeItem<MockOrganization>[] {
  const itemMap = new Map<string, GroupTreeItem<MockOrganization>>();
  const rootItems: GroupTreeItem<MockOrganization>[] = [];

  // 出来上がりの順序に影響があるため並べ替えておく
  const sortedOrgs = orgs.sort((a, b) =>
    (a.organizationCode || '') > (b.organizationCode || '') ? 1 : -1
  );

  // 全ての組織をGroupTreeItemとしてMapに格納する
  sortedOrgs.forEach((org) => {
    const item: GroupTreeItem<MockOrganization> = {
      id: org.organizationCode,
      displayName: org.displayName,
      isDisabled: org.isDisabled,
      children: [],
      originItem: org,
    };
    itemMap.set(org.organizationCode!, item);
  });

  // 各組織に対して親子関係を構築する
  sortedOrgs.forEach((org) => {
    const currentItem = itemMap.get(org.organizationCode!);
    if (org.parentOrganizationCode) {
      const parentItem = itemMap.get(org.parentOrganizationCode);
      if (parentItem) {
        parentItem.children!.push(currentItem!);
      } else {
        // 親がMapに存在しない場合、ルートアイテムとして扱う
        rootItems.push(currentItem!);
      }
    } else {
      // 親IDが指定されていない場合、ルートアイテムとして追加
      rootItems.push(currentItem!);
    }
  });

  // ルートアイテムに親が存在しない場合のみ追加されるので、重複がない
  return rootItems;
}
