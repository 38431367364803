import Long from 'long';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  PageNavigation,
  Textbox,
  Textarea,
  Checkbox,
  AutoReminderSettingDialog,
  SimpleListView,
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
  LatestUpdateInfo,
  FileUploadDialog,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListSurveyRequestCatalogs,
  PageState,
  ViewId,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  convertApiStringWIthFillBlank,
  convertStringWithTrim,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  getViewIdPreset,
  includeInputValidateError,
  handleCommonFIleUpload,
} from '~/shared/utils';
import './SurveyRequestCatalogInput.css';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import urlList from '~/shared/menu/path.json';
import { Accordion } from '~/shared/components/ui/Accordion';

const VIEW_ID: ViewId = 'SURVEY_REQUEST_CATALOG_INPUT';

export function SurveyRequestCatalogInput() {
  interface AttachmentItems {
    id?: string;
    category?: sharelib.INameOption;
    assetId?: string;
    filename: string;
    url?: string;
    mimeType?: string;
    remarks?: string;
    linkType?: sharelib.INameOption;
  }

  type SurveyRequestCatalog = mtechnavi.api.survey.ISurveyRequestCatalog;
  type ISurveyRequestCatalog = mtechnavi.api.survey.ISurveyRequestCatalog;

  // ログイン者の情報特定用email
  const intl = useIntl();
  const navi = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [backPagePath, setBackPagePath] = useState(
    '/supplier-base/survey-request-catalog-list'
  );

  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
        viewId: VIEW_ID,
      }),
    },
  };

  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };

  const viewMessageDelete: MessageProps = {
    id: 'confirmationDialogMessageDelete',
    prefixId: VIEW_ID,
  };

  const viewMessageDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: VIEW_ID,
  };

  const toastSuccess = GetMessage({ id: 'I0000001' });

  // 遷移元から値を受け取る
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = useRef(sourcePageInfo.actionType);

  // 自動催促設定ダイアログ
  const [autoReminderDialogModalIsOpen, setAutoReminderModalIsOpen] =
    useState(false);
  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // 画面アイテム
  const [surveyRequestCatalog, setSuveyRequestCatalog] =
    useState<SurveyRequestCatalog>();
  // フィルターボックスアイテム
  const [categories, completeConditions] = useMemo<
    [DataFilterboxItem[], DataFilterboxItem[]]
  >(() => {
    const categories = getProgramOptionFilterboxData('A1000001');
    const completeConditions = getProgramOptionFilterboxData('A1000002');
    return [categories, completeConditions];
  }, []);
  // ナヴィゲーションアイテム
  const islatestUpdateVisibleRef = useRef(false);
  // 確認ダイアログProps
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });
  const categoriesNO = window.App.services.ui.getNameOption('A1000001');
  const completeConditionsNO = window.App.services.ui.getNameOption('A1000002');
  const [code, setCode] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [categoryCode, setCategoryCode] = useState<DataFilterboxItem[]>([]);
  const [guidance, setGuidance] = useState('');
  const [approval, setApproval] = useState<string[]>([]);
  const [completeCondition, setCompleteCondition] = useState<
    DataFilterboxItem[]
  >([]);
  const [partialReply, setPartialReply] = useState<string[]>([]);
  const [decline, setDecline] = useState<string[]>([]);
  const [requester1, setRequester1] = useState('');
  const [requester2, setRequester2] = useState('');
  const [autoReminderSetting, setAutoreminderSetting] = useState<
    mtechnavi.api.survey.IAutoReminderSetting[]
  >([]);
  const [fileAttachment, setFileAttachment] = useState<AttachmentItems[]>();
  const [lastUpdateInfo, setLastUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: false,
  });
  const [isRequiredAttachment, setRequiredAttachment] = useState(false);
  const [isRequestFormUsable, setRequestFormUsable] = useState(false);
  const [isResultFormUsable, setResultFormUsable] = useState(false);

  //Disabled
  const [isCodeDisabled, setCodeDisabled] = useState(false);

  // 入力チェック用
  const requiredCodeArea = useRef(null);
  const requiredDisplayNameArea = useRef(null);

  useEffect(() => {
    setLoading(true);
    // 戻るボタンのURLをセット
    setBackPagePath(
      urlList.find((v) => v.viewId === sourcePageInfo.sourceViewId)?.path ??
        '/supplier-base/survey-request-catalog-list'
    );
    if (actionType.current === 'edit') {
      islatestUpdateVisibleRef.current = true;
      (async () => {
        try {
          // カタログ依頼を取得
          const listSurveyRequestCatalogRes =
            (await window.App.services.ui.worker.filter({
              action: 'query',
              fullMethodName: FullMethodName_ListSurveyRequestCatalogs,
              filter: {
                surveyRequestCatalogId: { $eq: sourcePageInfo.ids![0] ?? '' },
              },
              sort: [],
            })) as mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse;

          const surveyCatalog = listSurveyRequestCatalogRes
            ?.items[0] as SurveyRequestCatalog;

          if (surveyCatalog?.surveyRequestCatalogId === '') {
            setLoading(false);
            error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
            return;
          }

          setSuveyRequestCatalog(surveyCatalog);
          const { presetItem } = await window.App.services.ui.getViewIdPreset(
            VIEW_ID
          );
          setPreset(presetItem);
        } catch (err) {
          error(getExceptionMessage(intl, err));
        }
      })();
      setCodeDisabled(true);
    } else {
      // viewIDに紐付くpresetを取得
      (async () => {
        const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

        setPreset(presetItem);
      })();
    }
    setLoading(false);
  }, [intl, sourcePageInfo.ids, sourcePageInfo.sourceViewId]);

  // 初期値セット
  useEffect(() => {
    setCode(surveyRequestCatalog?.code ?? '');
    const cateogry = surveyRequestCatalog?.category?.displayNameLang?.ja
      ? categories?.filter(
          (v) =>
            v.displayName ===
            surveyRequestCatalog?.category?.displayNameLang?.ja
        )
      : [];
    setDisplayName(convertStringWithTrim(surveyRequestCatalog?.displayName));
    setCategoryCode(cateogry);
    setGuidance(convertStringWithTrim(surveyRequestCatalog?.guidance));
    setApproval(surveyRequestCatalog?.approval ? ['1'] : []);
    const completeCondition = surveyRequestCatalog?.completeCondition
      ?.displayNameLang?.ja
      ? completeConditions.filter(
          (v) =>
            v.displayName ===
            surveyRequestCatalog?.completeCondition?.displayNameLang?.ja
        )
      : [];
    setCompleteCondition(completeCondition);
    setPartialReply(surveyRequestCatalog?.partialReply ? ['1'] : []);
    setDecline(surveyRequestCatalog?.decline ? ['1'] : []);
    setRequester1(
      surveyRequestCatalog?.requesters
        ? surveyRequestCatalog?.requesters[0]
        : ''
    );
    setRequester2(
      surveyRequestCatalog?.requesters
        ? surveyRequestCatalog?.requesters[1]
        : ''
    );
    setLastUpdateInfo({
      isVisibleUpdateInfo: surveyRequestCatalog ? true : false,
      attributeAt:
        surveyRequestCatalog?.surveyRequestCatalogUpdatedAt ??
        Long.fromNumber(0),
      content:
        surveyRequestCatalog?.surveyRequestCatalogUpdatedBy?.displayName ?? '',
      attribute:
        surveyRequestCatalog?.surveyRequestCatalogUpdatedBy?.email ?? '',
    });
    putCommonRequestAttachmentItem(
      surveyRequestCatalog?.commonAttachments ?? []
    );
    setAutoreminderSetting(surveyRequestCatalog?.autoReminderSettings ?? []);
    setRequiredAttachment(surveyRequestCatalog?.requiredAttachment || false);
    setRequestFormUsable(surveyRequestCatalog?.requestFormUsable || false);
    setResultFormUsable(surveyRequestCatalog?.resultFormUsable || false);

    // surveyRequestCatalog変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyRequestCatalog]);

  const putCommonRequestAttachmentItem = (
    attachmentItems: sharelib.IAttachment[]
  ) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setFileAttachment([...resultItems]);
  };

  // 登録、変更処理
  const sendAction = async () => {
    const requesters = [];
    requesters.push(requester1 ?? '');
    requesters.push(requester2 ?? '');
    const categoryDisplayName =
      categoryCode.length > 0 ? categoryCode[0].displayName : '';
    const completeConditionDisplayName =
      completeCondition.length > 0 ? completeCondition[0].displayName : '';

    const req: mtechnavi.api.survey.ISurveyRequestCatalog[] = [
      {
        surveyRequestCatalogId:
          actionType.current == 'add'
            ? null
            : surveyRequestCatalog?.surveyRequestCatalogId,
        code: actionType.current === 'add' ? code : null,
        displayName: convertApiStringWIthFillBlank(displayName),
        category: categoriesNO.find(
          (v) => v.displayNameLang?.ja === categoryDisplayName
        ),
        guidance: convertApiStringWIthFillBlank(guidance),
        approval: approval[0] && approval[0] === '1' ? true : false,
        completeCondition: completeConditionsNO.find(
          (v) => v.displayNameLang?.ja === completeConditionDisplayName
        ),
        partialReply: partialReply[0] && partialReply[0] === '1' ? true : false,
        decline: decline[0] && decline[0] === '1' ? true : false,
        requesters: requesters,
        autoReminderSettings: autoReminderSetting,
        commonAttachments: fileAttachment,
        surveyRequestCatalogUpdatedAt: null,
        surveyRequestCatalogUpdatedBy: null,
        createdAt: null,
        updatedAt:
          actionType.current === 'add' ? null : surveyRequestCatalog?.updatedAt,
        deletedAt: null,
        requiredAttachment: isRequiredAttachment,
        requestFormUsable: isRequestFormUsable,
        resultFormUsable: isResultFormUsable,
      },
    ];
    setLoading(true);
    try {
      const result = (await window.App.services.ui.worker.apiCall({
        actionName: 'saveCatalog',
        request: req,
      })) as mtechnavi.api.survey.SurveyRequestCatalog[];

      success([toastSuccess]);

      sourcePageInfo.actionType = 'edit';
      actionType.current = 'edit';

      result.map((updatedCatalog) => {
        let resultCatalog: mtechnavi.api.survey.ISurveyRequestCatalog = {};
        if (Array.isArray(updatedCatalog)) {
          if (updatedCatalog.length > 0) {
            resultCatalog = updatedCatalog[0];
          }
        } else {
          resultCatalog = updatedCatalog;
        }
        setSuveyRequestCatalog(resultCatalog as ISurveyRequestCatalog);
      });
      setCodeDisabled(true);
      // 遷移元に戻る
      backToPreviewPage();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      return;
    } finally {
      setLoading(false);
      setOpenConfirmDialog(false);
    }
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPagePath, { state });
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestCatalogInput">
          <div className="header">
            <PageNavigation
              backpagePath="/supplier-base/survey-request-catalog-list"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: lastUpdateInfo,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setConfirmDialogInfo({
                  viewMessage: viewMessageCancel,
                  onDecision: backToPreviewPage,
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25" ref={requiredCodeArea}>
                  <Textbox
                    name="code"
                    className="field"
                    value={code}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.code"
                    columns={preset.columns}
                    disabled={isCodeDisabled}
                    validateOption={{ required: true }}
                    onChangeState={setCode}
                  />
                </div>
                <div className="w-33" ref={requiredDisplayNameArea}>
                  <Textbox
                    name="displayName"
                    className="field"
                    value={displayName}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.displayName"
                    columns={preset.columns}
                    validateOption={{ required: true }}
                    onChangeState={setDisplayName}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={categories}
                    name="category.code"
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.category.code"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={categoryCode}
                    onChangeState={setCategoryCode}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  <Textarea
                    name="guidance"
                    className="w-100 mh-middle"
                    value={guidance}
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.guidance"
                    columns={preset.columns}
                    onChangeState={setGuidance}
                  />
                </div>
              </div>
            </div>
            <div className="input-line mixed-input-line">
              <div className="item-group-100">
                <div className="w-16">
                  <Checkbox
                    name="checkbox"
                    className="group"
                    items={[
                      {
                        value: '1',
                        displayName: GetMessage({
                          prefixId: 'SURVEY_REQUEST_CATALOG_INPUT',
                          id: 'approval',
                        }),
                      },
                    ]}
                    value={approval}
                    columns={['checkbox']}
                    onChangeState={setApproval}
                  />
                </div>
                <div className="w-16">
                  <DataFilterbox
                    data={completeConditions}
                    name="completeCondition"
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.completeCondition"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={completeCondition}
                    onChangeState={setCompleteCondition}
                  />
                </div>
                <div className="w-16">
                  <Checkbox
                    name="checkbox"
                    className="group"
                    items={[
                      {
                        value: '1',
                        displayName: GetMessage({
                          prefixId: 'SURVEY_REQUEST_CATALOG_INPUT',
                          id: 'partialReply',
                        }),
                      },
                    ]}
                    value={partialReply}
                    columns={['checkbox']}
                    onChangeState={setPartialReply}
                  />
                </div>
                <div className="w-16">
                  <Checkbox
                    name="checkbox"
                    className="group"
                    items={[
                      {
                        value: '1',
                        displayName: GetMessage({
                          prefixId: 'SURVEY_REQUEST_CATALOG_INPUT',
                          id: 'decline',
                        }),
                      },
                    ]}
                    value={decline}
                    columns={['checkbox']}
                    onChangeState={setDecline}
                  />
                </div>
                <div className="w-16">
                  <Checkbox
                    name="requiredAttachment"
                    columns={['requiredAttachment']}
                    items={[
                      {
                        displayName: GetMessageWithIntl(intl, {
                          id: 'requiredAttachment',
                          viewId: VIEW_ID,
                        }),
                        value: '1',
                      },
                    ]}
                    value={isRequiredAttachment ? ['1'] : []}
                    onChangeState={(v) => {
                      setRequiredAttachment(!!v.length);
                    }}
                  />
                </div>
                <div className="w-16">
                  <CaptionButton
                    className="auto-reminder-setting-button"
                    name=""
                    caption="自動催促設定"
                    onClick={() => setAutoReminderModalIsOpen(true)}
                    buttonType="basic"
                  ></CaptionButton>
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  <Textbox
                    name="requester1"
                    className="field"
                    value={requester1}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.requester1"
                    columns={preset.columns}
                    onChangeState={setRequester1}
                  />
                </div>
                <div className="w-40">
                  <Textbox
                    name="requester2"
                    className="field"
                    value={requester2}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_INPUT.requester2"
                    columns={preset.columns}
                    onChangeState={setRequester2}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100 file-area">
                <div className="w-50">
                  <SimpleListView
                    data={fileAttachment ?? []}
                    viewOptions={{
                      previewRowCount: 5,
                      columns: [
                        {
                          header: { id: 'commonAttachedFile' },
                          propertyName: 'filename',
                        },
                      ],
                    }}
                    actionOptions={{
                      onDelete: (item: AttachmentItems) => {
                        setOpenConfirmDialog(true);
                        setConfirmDialogInfo({
                          viewMessage: viewMessageDelete,
                          onDecision: () => {
                            setFileAttachment(
                              fileAttachment?.filter((v) => v.id !== item.id)
                            );
                            setOpenConfirmDialog(false);
                          },
                        });
                      },
                      onDeleteAll: () => {
                        setConfirmDialogInfo({
                          viewMessage: viewMessageDeleteAll,
                          onDecision: () => {
                            setFileAttachment([]);
                            setOpenConfirmDialog(false);
                          },
                        });
                        setOpenConfirmDialog(true);
                      },
                      onRowClick: (item: AttachmentItems) => {
                        autoDownloadFileOnlyName(
                          item.filename ?? '',
                          item.assetId ?? ''
                        );
                      },
                      onFullDownLoad: () => {
                        autoBulkDownload(
                          (fileAttachment ?? []).map(
                            (item) => item.assetId ?? ''
                          ),
                          intl,
                          VIEW_ID
                        );
                      },
                    }}
                  />
                </div>
                <div className="w-20">
                  <CaptionButton
                    buttonType="basic"
                    caption="ファイル追加"
                    name=""
                    onClick={() => {
                      if (fileAttachment && fileAttachment.length >= 10) {
                        error([
                          GetMessageWithIntl(intl, {
                            id: 'E0000077',
                            value: { $1: 10 },
                          }),
                        ]);
                        return;
                      }
                      setOpenFileUploadDialog(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <Accordion
              title={GetMessageWithIntl(intl, {
                id: 'requestDetail',
                viewId: VIEW_ID,
              })}
            >
              <div className="input-line checkbox-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Checkbox
                      name="requestFormUsable"
                      columns={['requestFormUsable']}
                      items={[
                        {
                          displayName: GetMessageWithIntl(intl, {
                            id: 'requestFormUsable',
                            viewId: VIEW_ID,
                          }),
                          value: '1',
                        },
                      ]}
                      value={isRequestFormUsable ? ['1'] : []}
                      onChangeState={(v) => {
                        setRequestFormUsable(!!v.length);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line checkbox-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Checkbox
                      name="resultFormUsable"
                      columns={['resultFormUsable']}
                      items={[
                        {
                          displayName: GetMessageWithIntl(intl, {
                            id: 'resultFormUsable',
                            viewId: VIEW_ID,
                          }),
                          value: '1',
                        },
                      ]}
                      value={isResultFormUsable ? ['1'] : []}
                      onChangeState={(v) => {
                        setResultFormUsable(!!v.length);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                name="resetBtn"
                caption="保存"
                className="btn"
                onClick={() => {
                  // 入力エラーチェック
                  if (
                    includeInputValidateError(document, intl, [
                      { value: code ?? '', ref: requiredCodeArea },
                      {
                        value: displayName ?? '',
                        ref: requiredDisplayNameArea,
                      },
                    ])
                  ) {
                    return;
                  }
                  setConfirmDialogInfo({
                    viewMessage: viewMessageSave,
                    onDecision: sendAction,
                  });
                  setOpenConfirmDialog(true);
                }}
                buttonType="basic"
              />
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmDialogInfo.viewMessage}
          onDecision={confirmDialogInfo.onDecision}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
        ></ConfirmationDialog>
        <AutoReminderSettingDialog
          inputOption={{
            displayModeType: 'masterDecision',
            autoReminderSetting: autoReminderSetting[0],
          }}
          isOpen={autoReminderDialogModalIsOpen}
          onDecision={(v) => {
            autoReminderSetting.shift();
            setAutoreminderSetting((pre) => [...pre, v.autoReminderSetting]);
            setAutoReminderModalIsOpen(false);
          }}
          onCancel={() => setAutoReminderModalIsOpen(false)}
        />
        <FileUploadDialog
          isOpen={isOpenFileUploadDialog}
          messageOption={{
            headerLabelId: {
              id: 'file_add',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          fileUploadOption={{
            multiple: true,
            validateOption: {
              maxFileSizeInMebis: 50,
              maxFileCount: 10,
            },
          }}
          onCancel={() => {
            setOpenFileUploadDialog(false);
          }}
          onDecision={async (result) => {
            if (
              fileAttachment &&
              fileAttachment.length + result.files.length > 10
            ) {
              error([
                GetMessageWithIntl(intl, {
                  id: 'E0000077',
                  value: { $1: 10 },
                }),
              ]);
              return;
            }
            try {
              const attchmentfiels = await handleCommonFIleUpload(
                fileAttachment ?? [],
                result,
                'B05',
                intl
              );
              setFileAttachment(attchmentfiels);
              setOpenFileUploadDialog(false);
            } catch (err) {
              error(getWorkerExceptionMessage(intl, err));
              throw err;
            }
          }}
          onChangeLoadingState={(isLoading) => {
            setLoading(isLoading);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
