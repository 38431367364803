import { useEffect, useState, CSSProperties } from 'react';
import { Select, SelectItem, isUnselectedValidate } from '../Select/Select';
import './Times.css';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage } from '../Message/Message';
import { getErrorBorderClassName } from '~/shared/utils';

export interface TimesProps {
  name: string;
  className?: string;
  value?: string | Date;
  unselectedOption?: TimesUnselectedOption;
  validator?: (v: string[]) => string[];
  validateOption?: TimesValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: Date | null) => void; //親要素へ値を渡すためのプロパティ
}

export interface TimesUnselectedOption {
  isUnselected?: boolean;
  value?: string;
}

export interface TimesValidateOption {
  required?: boolean;
}

// 未選択項目のvalue
const UNSELECTED_VALUE = '-1';
// name属性の定数
const HOURS_NAME = 'H';
const MINUTES_NAME = 'M';
const SECONDS_NAME = 'S';

function getTimeItems(type: 'hours' | 'minutes' | 'seconds'): SelectItem[] {
  // 時分秒それぞれの選択項目を作成
  let count = 0;
  let unit = '';
  switch (type) {
    case 'hours':
      count = 24;
      unit = '時';
      break;

    case 'minutes':
      count = 60;
      unit = '分';
      break;

    case 'seconds':
      count = 60;
      unit = '秒';
      break;
  }
  return [...Array(count)].map((_, i) => {
    return { value: String(i), displayName: i + unit };
  });
}

// Select要素(子要素)で選択された値を親要素へ渡すデータ型(Date | null)を返却
function getSelectedDate(
  type: 'H' | 'HM' | 'HMS',
  hoursValue: string,
  minutesValue = '0',
  secondsValue = '0'
): Date | null {
  switch (type) {
    // 未選択項目が含まれていたらnullを返す
    case 'H':
      if (hoursValue === UNSELECTED_VALUE) {
        return null;
      }
      break;

    case 'HM':
      if (
        hoursValue === UNSELECTED_VALUE ||
        minutesValue === UNSELECTED_VALUE
      ) {
        return null;
      }
      break;

    case 'HMS':
      if (
        hoursValue === UNSELECTED_VALUE ||
        minutesValue === UNSELECTED_VALUE ||
        secondsValue === UNSELECTED_VALUE
      ) {
        return null;
      }
      break;
  }

  // 選択項目をもとにDate型を生成
  const now = new Date();
  now.setHours(Number(hoursValue), Number(minutesValue), Number(secondsValue));

  return now;
}

// 時間（時）を表示
export function TimeH(props: TimesProps) {
  // timeHのクラス名
  const timeHClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validator =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const initialHoursValue =
    props.value instanceof Date
      ? String(props.value.getHours())
      : props.value ?? '';
  const setSelectedTime = props.onChangeState || (() => {});
  const isUnselected = props.unselectedOption?.isUnselected ?? false;
  const unselectedValue = props.unselectedOption?.value ?? '';

  // Selectコンポーネントの値を取得
  const [hoursValue, setHoursValue] = useState<string[]>([initialHoursValue]);

  useEffect(() => {
    const hours = hoursValue[0] ?? UNSELECTED_VALUE;
    setSelectedTime(getSelectedDate('H', hours));

    //hoursValue 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursValue]);

  return (
    <div className="TimeH">
      <Select
        name={props.name + HOURS_NAME}
        className={timeHClassName}
        items={getTimeItems('hours')}
        unselectedOption={{
          isUnselected: isUnselected,
          value: unselectedValue,
        }}
        value={hoursValue}
        validateOption={{ required: required }}
        validator={validator}
        disabled={props.disabled}
        onChangeState={setHoursValue}
        style={style}
      ></Select>
    </div>
  );
}

// 時間（時、分）を表示
export function TimeHM(props: TimesProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  // timeHMのクラス名
  const timeHMClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validator =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const initialHoursValue =
    props.value instanceof Date
      ? String(props.value.getHours())
      : props.value ?? '';
  const initialMinutesValue =
    props.value instanceof Date
      ? String(props.value.getMinutes())
      : props.value ?? '';
  const setSelectedTime = props.onChangeState || (() => {});
  const isUnselected = props.unselectedOption?.isUnselected ?? false;
  const unselectedValue = props.unselectedOption?.value ?? '';

  // Selectコンポーネントからの値取得
  const [hoursValue, setHoursValue] = useState<string[]>([initialHoursValue]);
  const [minutesValue, setMinutesValue] = useState<string[]>([
    initialMinutesValue,
  ]);
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  useEffect(() => {
    // 初期読み込み時はチェックをスキップ
    if (hoursValue[0] === '') {
      return;
    }

    const hours = hoursValue[0] ?? UNSELECTED_VALUE;
    const minutes = minutesValue[0] ?? UNSELECTED_VALUE;

    if (required && isUnselectedValidate([hours, minutes])) {
      setErrorMessage([REQUIRED_MESSAGE]);
      return;
    } else if (!required && isUnselectedValidate([hours, minutes], true)) {
      // 必須でなく、未選択であれば以降の処理をスキップ
      setErrorMessage([]);
      return;
    }

    setErrorMessage(validator([hours, minutes]));

    setSelectedTime(getSelectedDate('HM', hours, minutes));

    // hoursValue,minutesValue 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursValue, minutesValue]);

  return (
    <div className="TimeHM">
      <div className="flex">
        {/* 時表示 */}
        <Select
          name={props.name + HOURS_NAME}
          className={`${timeHMClassName} ${getErrorBorderClassName(
            errorMessage
          )}`}
          items={getTimeItems('hours')}
          unselectedOption={{
            isUnselected: isUnselected,
            value: unselectedValue,
          }}
          validateOption={{ isSkippedValidation: true }}
          value={hoursValue}
          disabled={props.disabled}
          onChangeState={setHoursValue}
          style={style}
        ></Select>

        {/* 分表示 */}
        <Select
          name={props.name + MINUTES_NAME}
          className={`${timeHMClassName} ${getErrorBorderClassName(
            errorMessage
          )}`}
          items={getTimeItems('minutes')}
          unselectedOption={{
            isUnselected: isUnselected,
            value: unselectedValue,
          }}
          validateOption={{ isSkippedValidation: true }}
          value={minutesValue}
          disabled={props.disabled}
          onChangeState={setMinutesValue}
          style={style}
        ></Select>
      </div>

      {/* エラー表示 */}
      {!props.disabled ? (
        <ErrorMessage message={errorMessage}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}

// 時間（時、分、秒）を表示
export function TimeHMS(props: TimesProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  // timeHMSのクラス名
  const timeHMSClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validator =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const initialHoursValue =
    props.value instanceof Date
      ? String(props.value.getHours())
      : props.value ?? '';
  const initialMinutesValue =
    props.value instanceof Date
      ? String(props.value.getMinutes())
      : props.value ?? '';
  const initialSecondsValue =
    props.value instanceof Date
      ? String(props.value.getSeconds())
      : props.value ?? '';
  const setSelectedTime = props.onChangeState || (() => {});
  const isUnselected = props.unselectedOption?.isUnselected ?? false;
  const unselectedValue = props.unselectedOption?.value ?? '';

  // Selectコンポーネントからの値取得
  const [hoursValue, setHoursValue] = useState<string[]>([initialHoursValue]);
  const [minutesValue, setMinutesValue] = useState<string[]>([
    initialMinutesValue,
  ]);
  const [secondsValue, setSecondsValue] = useState<string[]>([
    initialSecondsValue,
  ]);
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  useEffect(() => {
    // 初期読み込み時はチェックをスキップ
    if (hoursValue[0] === '') {
      return;
    }

    const hours = hoursValue[0] ?? UNSELECTED_VALUE;
    const minutes = minutesValue[0] ?? UNSELECTED_VALUE;
    const seconds = secondsValue[0] ?? UNSELECTED_VALUE;

    if (required && isUnselectedValidate([hours, minutes, seconds])) {
      setErrorMessage([REQUIRED_MESSAGE]);
      return;
    } else if (
      !required &&
      isUnselectedValidate([hours, minutes, seconds], true)
    ) {
      // 必須でなく、未選択であれば以降の処理をスキップ
      setErrorMessage([]);
      return;
    }

    setErrorMessage(validator([hours, minutes, seconds]));

    setSelectedTime(getSelectedDate('HMS', hours, minutes, seconds));

    // hoursValue,minutesValue,secondsValue 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursValue, minutesValue, secondsValue]);

  return (
    <div className="TimeHMS">
      <div className="flex">
        {/* 時表示 */}
        <Select
          name={props.name + HOURS_NAME}
          className={`${timeHMSClassName} ${getErrorBorderClassName(
            errorMessage
          )}`}
          items={getTimeItems('hours')}
          unselectedOption={{
            isUnselected: isUnselected,
            value: unselectedValue,
          }}
          validateOption={{ isSkippedValidation: true }}
          value={initialHoursValue}
          disabled={props.disabled}
          onChangeState={setHoursValue}
          style={style}
        ></Select>

        {/* 分表示 */}
        <Select
          name={props.name + MINUTES_NAME}
          className={`${timeHMSClassName} ${getErrorBorderClassName(
            errorMessage
          )}`}
          items={getTimeItems('minutes')}
          unselectedOption={{
            isUnselected: isUnselected,
            value: unselectedValue,
          }}
          validateOption={{ isSkippedValidation: true }}
          value={initialMinutesValue}
          disabled={props.disabled}
          onChangeState={setMinutesValue}
          style={style}
        ></Select>

        {/* 秒表示 */}
        <Select
          name={props.name + SECONDS_NAME}
          className={`${timeHMSClassName} ${getErrorBorderClassName(
            errorMessage
          )}`}
          items={getTimeItems('seconds')}
          unselectedOption={{
            isUnselected: isUnselected,
            value: unselectedValue,
          }}
          validateOption={{ isSkippedValidation: true }}
          value={initialSecondsValue}
          disabled={props.disabled}
          onChangeState={setSecondsValue}
          style={style}
        ></Select>
      </div>

      {/* エラー表示 */}
      {!props.disabled ? (
        <ErrorMessage message={errorMessage}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
