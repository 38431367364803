import {
  ChangeEvent,
  PropsWithoutRef,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { FileUploaderValidateOption } from '~/shared/components/file';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { IconButton } from '~/shared/components/ui/Button';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  CommmonAttachedFileSystemCode,
  autoDownloadFileOnlyName,
  getWorkerExceptionMessage,
  getErrorBorderClassName,
} from '~/shared/utils';
import { ReactComponent as UploadingIcon } from '@material-design-icons/svg/filled/rotate_left.svg';
import { ReactComponent as AttachFileIcon } from '@material-design-icons/svg/filled/attach_file.svg';
import {
  UNIT_MiB,
  uploadAndCreateAsset,
  validateFile,
} from '~/shared/components/file/FileUploader/Utils';
import { error } from '~/shared/components/parts/Toast/Toast';
import './FormInputFile.css';
import './base.css';

interface FormInputFileProps {
  name: string;
  label?: string;
  description?: string;
  required?: boolean;
  disabled?: boolean; // 編集もダウンロードもさせない
  readonly?: boolean; // 編集できないがダウンロードはできる
  assetSystemCode?: CommmonAttachedFileSystemCode;
  value?: mtechnavi.api.form.IFormControlFile;
  validateOption?: FileUploaderValidateOption;
  onChange?: (files: mtechnavi.api.form.IFormControlFile | null) => void;
  workingBlur?: Date;
  isSkippedRequireCheck?: boolean;
}
export const FormInputFile = (props: PropsWithoutRef<FormInputFileProps>) => {
  const intl = useIntl();
  const inputId = useId();
  const [file, setFile] = useState<mtechnavi.api.form.IFormControlFile | null>(
    props.value || null
  );
  const [message, setMessage] = useState<string[]>([]);
  const [isUploading, setUploading] = useState(false);

  const accept = useMemo(
    () =>
      props.validateOption?.allowedFileExtensions
        ?.map((ext) => `.${ext}`)
        .join(','),
    [props.validateOption?.allowedFileExtensions]
  );

  useEffect(() => {
    if (!file && props.value) {
      setFile(props.value);
    }
    // 親要素から連携された値でだけ発火したい
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (!props.workingBlur) {
      return;
    }
    // 必須チェック
    if (!props.isSkippedRequireCheck && props.required && !file) {
      setMessage([GetMessageWithIntl(intl, { id: 'E0000003' })]);
      return;
    }
    // workingBlur変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workingBlur]);

  const isValidFiles = (inputFiles: File[]): boolean => {
    let msg: string[] = [];

    if (!props.isSkippedRequireCheck && props.required && !inputFiles?.length) {
      setMessage([GetMessageWithIntl(intl, { id: 'E0000003' })]);
      return false;
    }

    const [, invalidMessages] = validateFile(
      intl,
      inputFiles,
      props.validateOption?.allowedFileExtensions ?? [],
      (props.validateOption?.maxFileSizeInMebis ?? 0) * UNIT_MiB
    );
    msg = [...msg, ...invalidMessages];

    setMessage(msg);
    return msg.length === 0;
  };

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (!isValidFiles(files) || !props.assetSystemCode) {
      setFile(null);
      return;
    }
    setUploading(true);

    try {
      // 個別でアップロード状況を見せないので、第３引数は空の function を渡す。
      const assets = (
        await uploadAndCreateAsset(files, props.assetSystemCode, () => {}, intl)
      ).flatMap((asset) => asset);

      const asset = assets.at(0);
      const resultFile = asset
        ? {
            assetId: asset?.assetId,
            fileName: asset?.filename,
            mimeType: asset?.mimeType,
          }
        : null;
      setFile(resultFile);
      if (props.onChange) {
        props.onChange(resultFile);
      }
      event.target.value = '';
      setUploading(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  const handleDownload = async () => {
    autoDownloadFileOnlyName(file?.fileName || '', file?.assetId || '');
  };

  const handleClearFiles = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // 親のラベルに連動したファイル選択イベントを起こさせない
    e?.preventDefault();

    setFile(null);
    isValidFiles([]);
    if (props.onChange) {
      props.onChange(null);
    }
  };

  useEffect(() => {
    // 必須制御が変わって任意になる場合にエラー表示をクリアする
    if (!props.required) {
      // 値が空の場合だけに対応範囲を限定する
      if (!file) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なので、props.validateOption?.requiredのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required]);

  useEffect(() => {
    // 必須チェック不要になる場合にエラー表示をクリアする
    if (props.isSkippedRequireCheck) {
      // 値が空の場合だけに対応範囲を限定する
      if (!file) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSkippedRequireCheck]);

  return (
    <div className="FormInput FormInputFile">
      <span className={props.label !== '' ? 'caption' : 'caption no-name'}>
        {props.label && (
          <div className={props.required ? 'required ' : ''}>
            <div
              className={`topping-label topping-label-with-help ${
                props.disabled || props.readonly ? 'disabled-topping-label' : ''
              }`}
            >
              {props.label || ''}
              {!!props.description && (
                <IconButton
                  name="help"
                  className="HelpIcon"
                  iconType="help"
                  buttonType="basic"
                  onClick={() => {}}
                  caption={props.description}
                />
              )}
            </div>
          </div>
        )}
      </span>

      <label
        className={`uploader ${props.disabled ? 'disabled' : ''} ${
          props.readonly ? 'readonly' : ''
        } ${getErrorBorderClassName(message)}`}
        htmlFor={inputId}
      >
        <span className="filename">{isUploading ? '' : file?.fileName}</span>
        {!props.readonly && (
          <>
            <input
              type="file"
              id={inputId}
              name={props.name}
              multiple={false}
              onChange={handleOnChange}
              disabled={props.disabled || isUploading}
              accept={accept}
            />
            {isUploading ? (
              <UploadingIcon className="uploading" />
            ) : (
              <div className="icon-area">
                {file && (
                  <IconButton
                    name="clearFiles"
                    className="clearFiles"
                    iconType="clear"
                    buttonType="basic"
                    disabled={props.disabled}
                    onClick={handleClearFiles}
                  />
                )}
                <AttachFileIcon className="attachIcon" />
              </div>
            )}
          </>
        )}
        {props.readonly && (
          <>
            {file && (
              <IconButton
                name="download"
                iconType="download"
                buttonType="basic"
                disabled={props.disabled}
                onClick={handleDownload}
              />
            )}
            {!file && <AttachFileIcon className="attachIcon" />}
          </>
        )}
      </label>
      <ErrorMessage message={!props.disabled ? message : []}></ErrorMessage>
    </div>
  );
};
