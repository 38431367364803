import { CSSProperties, RefObject } from 'react';
import { Property } from '~/shared/services';

interface dict {
  [key: string]: string;
}

export function getProperty(
  name: string,
  column: String[] | undefined,
  properties: Property[] | undefined
): [CSSProperties, CSSProperties, boolean] {
  // 表示関連スタイル設定
  const viewDict = {
    display: (column || []).findIndex((v) => v === name) < 0 ? 'none' : 'block',
  };
  const viewStyle: CSSProperties = viewDict;

  // セレクトボックス関連スタイル設定
  const selectDict: dict = {};
  let disabled = false;
  (properties || []).map((v) => {
    selectDict[v.propertyName] = v.propertyValue;
    if (v.propertyName === 'disabled') {
      disabled = v.propertyValue;
    }
  });
  const txtStyle: CSSProperties = selectDict;

  return [txtStyle, viewStyle, disabled];
}

/**
 * バーガーメニュー
 */

// バーガーメニューの表示イベントハンドラを要素に追加する
export const setMenuEventHandle = (elem: HTMLLIElement) => {
  let timer: NodeJS.Timeout;

  document.body.classList.remove('noscroll');
  elem.onmouseover = (e: MouseEvent) => {
    document.body.classList.add('noscroll');
    if (e.target == e.currentTarget) {
      timer = setTimeout(() => elem.classList.add('open'), 500);
    }
    return;
  };
  elem.onmouseleave = () => {
    clearTimeout(timer);
    document.body.classList.remove('noscroll');
    elem.classList.remove('open');
    return;
  };
  elem.onclick = (e: MouseEvent) => {
    clearTimeout(timer);
    if (e.target == e.currentTarget) {
      if (elem.classList.contains('open')) {
        elem.classList.remove('open');
      } else {
        elem.classList.add('open');
      }
    }
    return;
  };
};

// バーガーメニューの表示スタイルを親コンテンツ（contentsRef）から計算して設定する
export const setMenuListStyle = (
  contentsRef: RefObject<HTMLDivElement>,
  elem: HTMLLIElement,
  maxMenuColumn?: string
) => {
  const menuColumns = Number(maxMenuColumn ?? '3');
  const menuList = elem.querySelector('.menu-list');
  menuList?.setAttribute(
    'style',
    calcMenuListStyle(contentsRef, elem, menuColumns).join(';')
  );
};

// バーガーメニューがコンテンツ外にはみ出ない行数を計算する
export const calcMenuListStyle = (
  contentsRef: RefObject<HTMLDivElement>,
  menuBoxElem: Element,
  menuColumns: number
): string[] => {
  const contentsElememnt = contentsRef.current as HTMLDivElement;

  const contentsTop = contentsElememnt.getBoundingClientRect().top;
  const menuBoxBottom = menuBoxElem.getBoundingClientRect().bottom;
  const menuBoxHeight = menuBoxElem.clientHeight;
  let topMenuColumns = 1;
  for (let col = menuColumns; col > 0; col--) {
    const burgermenuMaxTop = menuBoxBottom - menuBoxHeight * col;
    if (contentsTop < burgermenuMaxTop) {
      topMenuColumns = col;
      break;
    }
  }
  const contentsBottom = contentsElememnt.getBoundingClientRect().bottom;
  const menuBoxTop = menuBoxElem.getBoundingClientRect().top;
  let bottomMenuColumns = 1;
  for (let col = menuColumns; col > 0; col--) {
    const burgermenuMaxBottom = menuBoxTop + menuBoxHeight * col;
    if (contentsBottom > burgermenuMaxBottom) {
      bottomMenuColumns = col;
      break;
    }
  }
  if (bottomMenuColumns >= topMenuColumns) {
    return [`top:0`, `max-height:${menuBoxHeight * bottomMenuColumns}px`];
  } else {
    return [
      `top: auto`,
      `bottom:0`,
      `max-height:${menuBoxHeight * topMenuColumns}px`,
    ];
  }
};
