import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { OwnStaffDialog } from '~/shared/components/ui/Dialog/OwnStaffDialog';
import { OwnStaffNotificationSettingDialog } from '~/shared/components/ui/Dialog/OwnStaffNotificationSetting';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';

export const UISampleOwnStaffDialogs = () => {
  const [resultConfirm, setResultConfirm] = useState('');
  const [isShowOwnStaffDialog, setShowOwnStaffDialog] = useState(false);
  const [
    isShowOwnStaffNotificationSettingDialog,
    setShowOwnStaffNotificationSettingDialog,
  ] = useState(false);
  const systemNotificationUsers: mtechnavi.api.company.BusinessUnitContact.NotificationUser[] =
    [
      {
        category: {
          categoryName: 'A4000006',
          systemName: 'B01',
          code: '1',
          displayNameLang: { ja: '主担当' },
        } as sharelib.INameOption,
        user: {
          userId: 'testId-1',
          email: 'debug1@mail.xxx',
          displayName: 'ユーザー名1',
        },
      } as mtechnavi.api.company.BusinessUnitContact.NotificationUser,
      {
        category: {
          categoryName: 'A4000006',
          systemName: 'B02',
          code: '2',
          displayNameLang: { ja: 'アシスタント' },
        } as sharelib.INameOption,
        user: {
          userId: 'testId-2',
          email: 'debug2@mail.xxx',
          displayName: 'ユーザー名2',
        },
      } as mtechnavi.api.company.BusinessUnitContact.NotificationUser,
      {
        category: {
          categoryName: 'A4000006',
          systemName: 'B02',
          code: '2',
          displayNameLang: { ja: 'アシスタント' },
        } as sharelib.INameOption,
        user: {
          userId: 'testId-3',
          email: 'debug3@mail.xxx',
          displayName: 'ユーザー名3',
        },
      } as mtechnavi.api.company.BusinessUnitContact.NotificationUser,
      {
        category: {
          categoryName: 'A4000006',
          systemName: 'B01',
          code: '1',
          displayNameLang: { ja: '主担当' },
        } as sharelib.INameOption,
        user: {
          userId: 'testId-4',
          email: 'debug4@mail.xxx',
          displayName: 'ユーザー名4',
        },
      } as mtechnavi.api.company.BusinessUnitContact.NotificationUser,
      {
        category: {
          categoryName: 'A4000006',
          systemName: 'B02',
          code: '2',
          displayNameLang: { ja: 'アシスタント' },
        } as sharelib.INameOption,
        user: {
          userId: 'testId-1',
          email: 'debug1@mail.xxx',
          displayName: 'ユーザー名1',
        },
      } as mtechnavi.api.company.BusinessUnitContact.NotificationUser,
    ];

  return (
    <div className="categories">
      <h3 is="dialog">自社担当ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResultConfirm('');
                setShowOwnStaffDialog(true);
              }}
            />
            <OwnStaffDialog
              isOpen={isShowOwnStaffDialog}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'OwnStaff',
                },
              }}
              inputOption={{
                isDisplayButton: true,
                code: 'codeA',
                displayName: '取引先A（ダイアログ確認用）',
                notificationUsers: systemNotificationUsers,
              }}
              onCancel={() => {
                setResultConfirm('キャンセルしました');
                setShowOwnStaffDialog(false);
              }}
              onNotificationSetting={() => {
                setResultConfirm('通知設定アイコンを押下しました');
                setShowOwnStaffNotificationSettingDialog(true);
              }}
            />
            <OwnStaffNotificationSettingDialog
              isOpen={isShowOwnStaffNotificationSettingDialog}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'OwnStaffNotificationSetting',
                },
              }}
              inputOption={{
                notificationUsers: systemNotificationUsers.map(
                  (v) => v.user ?? {}
                ),
              }}
              onCancel={() => {
                setResultConfirm('キャンセルしました');
                setShowOwnStaffNotificationSettingDialog(false);
              }}
              onDecision={(v) => {
                setResultConfirm(
                  `出力しました。${JSON.stringify(
                    v,
                    function (key, value) {
                      if (this[key] instanceof Date) {
                        return this[key].toString();
                      }
                      return value;
                    },
                    2
                  )}`
                );
                setShowOwnStaffNotificationSettingDialog(false);
                setShowOwnStaffDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{resultConfirm}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
