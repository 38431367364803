import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { FormInputImportDialog } from '~/shared/components/ui/Dialog/FormInputImportDialog';

export const UISampleFileInputImportDialogs = () => {
  const [result, setResult] = useState('');
  const [fileItem, setFileItem] = useState('');
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">フォーム入力インポートダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowConfirm(true);
              }}
            />
            <FormInputImportDialog
              isOpen={isShowConfirm}
              onDecision={() => {
                setResult(`送信しました。コメント：${fileItem}`);
                setFileItem('');
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setResult('キャンセル');
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
