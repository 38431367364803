import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
  useRef,
} from 'react';
import './Tooltip.css';

interface TooltipState {
  isShowTooltip: boolean;
  top: number | string;
  left: number | string;
  maxWidth: number | string;
  content: string | null;
}
export const initialState: TooltipState = {
  isShowTooltip: false,
  top: 0,
  left: 0,
  maxWidth: 0,
  content: null,
};

export type TooltipActions =
  | ActionClearTooltip
  | ActionReadyTooltip
  | ActionShowTooltip;
interface ActionClearTooltip {
  type: 'clearTooltip';
}
interface ActionReadyTooltip {
  type: 'readyTooltip';
  target: HTMLElement;
  content: string;
}
interface ActionShowTooltip {
  type: 'showTooltip';
}

const TOOLTIP_DELAY = 1000;

export const reducer = (
  state: TooltipState,
  action: TooltipActions
): TooltipState => {
  switch (action.type) {
    case 'clearTooltip':
      return {
        ...initialState,
      };
    case 'readyTooltip': {
      const clientRect = action.target.getBoundingClientRect();
      return {
        isShowTooltip: false,
        content: action.content,
        // 縦位置
        //   設定要素の中心を基準に配置する。吹き出し分下にずらす。
        top: clientRect.top + Math.floor(action.target.clientHeight / 2) + 15,
        // 横位置
        //   設定要素がツールチップの min-width (3.25rem) より小さい場合は、設定要素の中心を基準に配置する。
        //   それ以外の場合は設定要素の左端基準で配置する。
        left:
          clientRect.left -
          (action.target.clientWidth < 16 * 3.25
            ? Math.floor(action.target.clientWidth / 2)
            : 0),
        // 最大横幅
        maxWidth: `max(${Math.floor(
          action.target.clientWidth * 0.8
        )}px, 10rem)`,
      };
    }
    case 'showTooltip':
      if (state.content) {
        return {
          ...state,
          isShowTooltip: true,
        };
      } else {
        return { ...initialState };
      }
    default:
      throw new Error(`unknown action: ${action}`);
  }
};

const TooltipContext = createContext<TooltipState>({ ...initialState });
const TooltipDispatchContext = createContext<React.Dispatch<TooltipActions>>(
  () => {}
);
export const TooltipAreaProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const wrappedDispatch = (action: TooltipActions) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (action.type === 'readyTooltip') {
      timeoutRef.current = setTimeout(() => {
        dispatch({ type: 'showTooltip' });
      }, TOOLTIP_DELAY);
    }
    return dispatch(action);
  };

  return (
    <TooltipContext.Provider value={state}>
      <TooltipDispatchContext.Provider value={wrappedDispatch}>
        {children}
        <Tooltip />
      </TooltipDispatchContext.Provider>
    </TooltipContext.Provider>
  );
};

const Tooltip = () => {
  const tooltip = useTooltipContext();
  return (
    <>
      {tooltip.isShowTooltip && (
        <div
          className="Tooltip"
          style={{
            top: tooltip.top,
            left: tooltip.left,
            maxWidth: tooltip.maxWidth,
            width: 'auto',
          }}
        >
          {tooltip.content}
        </div>
      )}
    </>
  );
};

const useTooltipContext = () => {
  return useContext(TooltipContext);
};
const useTooltipDispatch = () => {
  return useContext(TooltipDispatchContext);
};

/**
 * ツールチップを制御するカスタムフック
 *
 * 先祖要素に <TooltipAreaProvider> を配置して使用する。
 */
export const useTooltip = () => {
  const state = useTooltipContext();
  const tooltipDispatch = useTooltipDispatch();
  return {
    /** ツールチップの表示 */
    showTooltip: (content: string, eventTarget: HTMLElement) => {
      if (state.content === content) {
        return;
      }
      tooltipDispatch({
        type: 'readyTooltip',
        target: eventTarget,
        content,
      });
    },
    /** ツールチップの非表示 */
    hideTooltip: () => {
      tooltipDispatch({ type: 'clearTooltip' });
    },
  };
};
