import React from 'react';
import { ConditionCheckbox } from './ConditionCheckbox';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { ViewId } from '~/shared/utils';
import { ConditionDetail } from '../../util';

interface AttributeConditionItemsProps {
  viewId?: ViewId;
  conditions?: ConditionDetail[];
  onEdit?: (index: number, condition: ConditionDetail) => void;
  onDelete?: (index: number) => void;
  onChange?: (index: number, toggleId: string) => void;
}

export const AttributeConditionItems = ({
  viewId,
  conditions,
  onEdit,
  onDelete,
  onChange,
}: AttributeConditionItemsProps) => {
  const intl = useIntl();

  const labelEdit = GetMessageWithIntl(intl, {
    viewId: viewId,
    id: 'edit',
  });
  const labelDelete = GetMessageWithIntl(intl, {
    viewId: viewId,
    id: 'remove_condition',
  });
  const labelEveryMatch = GetMessageWithIntl(intl, {
    viewId: viewId,
    id: 'every_match',
  });
  const labelSomeMatch = GetMessageWithIntl(intl, {
    viewId: viewId,
    id: 'some_match',
  });

  const handleEdit = (index: number, condition: ConditionDetail) => {
    if (onEdit) {
      onEdit(index, condition);
    }
  };

  const handleDelete = (index: number) => {
    if (onDelete) {
      onDelete(index);
    }
  };

  const handleChange = (index: number, toggleId: string) => {
    if (onChange) {
      onChange(index, toggleId);
    }
  };

  return (
    <ul className="condition-list">
      {conditions?.map((condition, index) => (
        <li className="condition-list-item" key={index}>
          <div className="condition-list-item-actions">
            <span className="multiple-type-label">
              {condition.multipleType === 'and'
                ? labelEveryMatch
                : labelSomeMatch}
            </span>
            <a onClick={() => handleEdit(index, condition)}>{labelEdit}</a>
            <a onClick={() => handleDelete(index)}>{labelDelete}</a>
          </div>
          <div className="condition-list-item-detail">
            {condition.conditionItems.map((condition) => (
              <React.Fragment key={condition.item.id}>
                <ConditionCheckbox
                  value={condition.item.id || ''}
                  checked={condition.checked}
                  onChange={() => handleChange(index, condition.item.id || '')}
                  label={
                    (condition.item.displayNameLang &&
                      condition.item.displayNameLang[intl.locale]) ||
                    ''
                  }
                />
              </React.Fragment>
            ))}
          </div>
        </li>
      ))}
    </ul>
  );
};
