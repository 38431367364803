import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessage,
  GetMessageWithIntl,
  error,
  success,
} from '~/shared/components';
import {
  Accordion,
  CommentDialog,
  EstimateBasicInfoRegistrationDialog,
  EstimateBasicInfoRegistrationInfo,
  SimpleIconListView,
  Textarea,
} from '~/shared/components/ui';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import { EstimateResultDetailRegistrationDialog } from '~/shared/components/ui/Dialog/EstimateResultDetailRegistrationDialog';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  ViewId,
  getDateFormat,
  getDateFormatWithTimezone,
  getWorkerExceptionMessage,
  toCommaTypeString,
} from '~/shared/utils';
import {
  convertDatetime,
  convertDate,
  convertLongToString,
  convertLongToNumber,
  convertStringToCommaString,
  toMonetaryAmount,
} from '~/shared/utils/converter';
import { totalAmounts } from '~/shared/utils/calculator';
import { isCanBeEditResult } from '~/tenant/estimate/utils/validator';

interface EstimateResultDetailListViewData {
  estimateResultDetailId: string;
  detailAutoName: string;
  transactionUnit: string;
  quantity: string;
  amount: string;
  materialCost: string;
  processingCost: string;
  administrativeCost: string;
  freightCost: string;
  otherCost: string;
}

interface EstimateResultTabItemProps {
  viewId: ViewId;
  estimateResultDetails?: mtechnavi.api.estimation.IEstimateResultDetail[];
  estimateResult?: mtechnavi.api.estimation.IEstimateResult;
  estimateResultSummary?: mtechnavi.api.estimation.IEstimateResultSummary;
  onChangeLoadingState?: (arg: boolean) => void;
  handleReload?: () => void;
}

type EstimateResultDetail = mtechnavi.api.estimation.IEstimateResultDetail;
export const EstimateResultTabItem = ({
  viewId,
  estimateResult,
  estimateResultDetails,
  onChangeLoadingState,
  handleReload,
}: EstimateResultTabItemProps) => {
  const intl = useIntl();

  // ダイアログ
  // 基本情報登録ダイアログ
  const [estimateBasicInfoDialogOpen, setEstimateBasicInfoDialogOpen] =
    useState(false);
  // 明細内訳登録ダイアログ
  const [estimateResultDialogOpen, setEstimateResultDialogOpen] =
    useState(false);
  // 社内管理メモ登録ダイアログ
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });

  // 社内メモ
  const [comment, setComment] = useState('');

  // アクションID一時管理
  const [
    estimateResultDetailRegistrationDialogId,
    setEstimateResultDetailRegistrationDialogId,
  ] = useState<string>('');

  const setEstimateResultDetailSimpleListViewData = () => {
    const listData: EstimateResultDetailListViewData[] = [];
    estimateResultDetails?.map((v) => {
      const tmp: EstimateResultDetailListViewData = {
        estimateResultDetailId: v.estimateResultDetailId ?? '',
        detailAutoName: convertLongToString(
          v.estimateRequestDetail?.detailAutoName
        ),
        transactionUnit: v.estimateRequestDetail?.displayName ?? '',
        quantity: `${toCommaTypeString(
          v.estimateRequestDetail?.quantity?.amountNum
        )}${
          v.estimateRequestDetail?.quantity?.unit?.displayNameLang?.ja ?? ''
        }`,
        amount: `${toCommaTypeString(v.amount?.amountNum)}${
          v.amount?.amountNum && v.unitPrice?.amountNum
            ? '(@' + toCommaTypeString(v.unitPrice?.amountNum) + ')'
            : ''
        }`,
        materialCost: toCommaTypeString(v.materialCost?.amountNum),
        processingCost: toCommaTypeString(v.processingCost?.amountNum),
        administrativeCost: toCommaTypeString(v.administrativeCost?.amountNum),
        freightCost: toCommaTypeString(v.freightCost?.amountNum),
        otherCost: toCommaTypeString(v.otherCost?.amountNum),
      };
      listData.push(tmp);
    });
    return listData;
  };

  const formatLatestUpdateInfo = () => {
    const date: sharelib.IEmbeddedUpdatedProperties[] = [];
    if (estimateResult?.updatedProperties?.updatedAt) {
      date.push(estimateResult?.updatedProperties);
    }
    estimateResultDetails?.map((v) => {
      if (v.updatedProperties?.updatedAt) {
        date.push(v.updatedProperties);
      }
    });
    date.sort(
      (v1, v2) =>
        (convertLongToNumber(v2.updatedAt) ?? 0) -
        (convertLongToNumber(v1.updatedAt) ?? 0)
    );
    if (!date || date.length === 0) {
      return '';
    }
    return `${date[0].updatedBy?.displayName ?? ''}${
      date[0].updatedBy?.displayName && date[0].updatedBy?.email
        ? '(' + date[0].updatedBy?.email + ')'
        : date[0].updatedBy?.email ?? ''
    } ${getDateFormat(date[0].updatedAt ?? '', 'YYYY/MM/DD HH:mm')}`;
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState && onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  const handleSaveRemarks = async () => {
    onParentLoadingState(true);
    try {
      const req: mtechnavi.api.estimation.IEstimateResult = {
        estimateResultId: estimateResult?.estimateResultId,
        remarks: comment,
        updatedAt: estimateResult?.updatedAt,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateRemarksInEstimateResult',
        request: req,
      });
      success([successMessage]);
      handleReload && handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
      setCommentDialogOpen(false);
    }
  };

  const handleSaveEstimateResultDetail = async (v: EstimateResultDetail) => {
    onParentLoadingState(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateEstimateResultDetail',
        request: v,
      });
      success([successMessage]);
      handleReload && handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
      setEstimateResultDialogOpen(false);
    }
  };

  const handleSaveEstimateBasicInfo = async (
    v: EstimateBasicInfoRegistrationInfo
  ) => {
    onParentLoadingState(true);
    const req: mtechnavi.api.estimation.IEstimateResult = {
      estimateResultId: estimateResult?.estimateResultId,
      estimateRequestId: estimateResult?.estimateRequestId,
      estimateRequest: estimateResult?.estimateRequest,
      systemNotificationUsers: estimateResult?.systemNotificationUsers,
      status: null,
      deliveryTimeDt: convertDatetime(v.deliveryTimeDt ?? null, 'YYYY/MM/DD'),
      deliveryTimeRemarks: v.deliveryTimeRemarks,
      leadTimeNum: v.leadTimeNum,
      leadTimeUnit: v.leadTimeUnit,
      estimateExpireDt: convertDatetime(
        v.estimateExpireDt ?? null,
        'YYYY/MM/DD'
      ),
      estimateRemarks: v.estimateRemarks,
      remarks: estimateResult?.remarks,
      createdAt: estimateResult?.createdAt,
      updatedAt: estimateResult?.updatedAt,
    };
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateEstimateResult',
        request: req,
      });
      success([successMessage]);
      handleReload && handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setEstimateBasicInfoDialogOpen(false);
      onParentLoadingState(false);
    }
  };

  return (
    <>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            {['B00', 'B01'].includes(
              estimateResult?.status?.systemName ?? ''
            ) && (
              <CaptionButton
                name={''}
                caption={GetMessageWithIntl(intl, {
                  id: 'buttonCaption.basicInfoRegistration',
                  prefixId: viewId,
                })}
                buttonType="basic"
                onClick={() => setEstimateBasicInfoDialogOpen(true)}
              />
            )}
          </div>
          <div className="w-50 ta-right">
            {/* 最終更新 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'latestUpdateInfo',
                prefixId: viewId,
              })}
            </span>
            {formatLatestUpdateInfo()}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            {/* 回答納期 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateResult.deliveryTimeDt',
                prefixId: viewId,
              })}
            </span>
            {getDateFormatWithTimezone(estimateResult?.deliveryTimeDt)
              ? getDateFormatWithTimezone(estimateResult?.deliveryTimeDt) +
                (estimateResult?.deliveryTimeRemarks
                  ? ' (' + estimateResult?.deliveryTimeRemarks + ')'
                  : '')
              : estimateResult?.deliveryTimeRemarks ?? ''}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            {/* 参考リードタイム */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateResult.leadTimeNum',
                prefixId: viewId,
              })}
            </span>
            {(estimateResult?.leadTimeNum ?? '') +
              (estimateResult?.leadTimeUnit?.displayNameLang?.ja ?? '')}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            {/* 見積有効期限 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateResult.estimateExpireDt',
                prefixId: viewId,
              })}
            </span>
            {getDateFormatWithTimezone(estimateResult?.estimateExpireDt)}
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">
            {/* 見積摘要 */}
            <Textarea
              name="estimateResult.estimateRemakrs"
              className="w-100 mh-middle"
              value={estimateResult?.estimateRemarks ?? ''}
              columns={['estimateResult.estimateRemakrs']}
              labelId={`${viewId}.estimateResult.estimateRemarks`}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-20">
            {/* 金額 */}
            <Amountbox
              name="estimateResult.estimateResultDetailTotalAmount"
              className="totalAmount"
              value={totalAmounts(
                ...(estimateResultDetails
                  ?.filter(
                    (v) =>
                      v.estimateResultId === estimateResult?.estimateResultId
                  )
                  .map((w) => toMonetaryAmount(w.amount)) ?? [])
              )}
              labelId={`${viewId}.estimateResult.estimateResultDetailTotalAmount`}
              columns={['estimateResult.estimateResultDetailTotalAmount']}
              disabled={true}
              displayOption={{ isCommaFormat: true }}
            />
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <p className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateResult.detailInfo',
                prefixId: viewId,
              })}
            </p>
            {/* 明細内訳 */}
            <SimpleIconListView
              data={setEstimateResultDetailSimpleListViewData()}
              actionOptions={{}}
              viewOptions={{
                columns: [
                  {
                    header: {
                      id: 'detailInfo.requestDetail.detailAutoName',
                      prefixId: viewId,
                    },
                    propertyName: 'detailAutoName',
                    width: '7%',
                  },
                  {
                    header: {
                      id: 'detailInfo.requestDetail.transactionUnit',
                      prefixId: viewId,
                    },
                    propertyName: 'transactionUnit',
                    width: '20%',
                  },
                  {
                    header: {
                      id: 'detailInfo.requestDetail.quantity',
                      prefixId: viewId,
                    },
                    propertyName: 'quantity',
                    align: 'right',
                    width: '10%',
                  },
                  {
                    header: { id: 'detailInfo.amount', prefixId: viewId },
                    propertyName: 'amount',
                    align: 'right',
                    width: '18%',
                  },
                  {
                    header: {
                      id: 'detailInfo.materialCost',
                      prefixId: viewId,
                    },
                    propertyName: 'materialCost',
                    align: 'right',
                    width: isCanBeEditResult(estimateResult?.status)
                      ? '8%'
                      : '9%',
                  },
                  {
                    header: {
                      id: 'detailInfo.processingCost',
                      prefixId: viewId,
                    },
                    propertyName: 'processingCost',
                    align: 'right',
                    width: isCanBeEditResult(estimateResult?.status)
                      ? '8%'
                      : '9%',
                  },
                  {
                    header: {
                      id: 'detailInfo.administrativeCost',
                      prefixId: viewId,
                    },
                    propertyName: 'administrativeCost',
                    align: 'right',
                    width: isCanBeEditResult(estimateResult?.status)
                      ? '8%'
                      : '9%',
                  },
                  {
                    header: {
                      id: 'detailInfo.freightCost',
                      prefixId: viewId,
                    },
                    propertyName: 'freightCost',
                    align: 'right',
                    width: isCanBeEditResult(estimateResult?.status)
                      ? '8%'
                      : '9%',
                  },
                  {
                    header: { id: 'detailInfo.otherCost', prefixId: viewId },
                    propertyName: 'otherCost',
                    align: 'right',
                    width: isCanBeEditResult(estimateResult?.status)
                      ? '8%'
                      : '9%',
                  },
                ],
                omitFooter: true,
                previewRowCount: 3,
                keyColumn: '',
              }}
              iconMenuOptions={{
                iconMenu: isCanBeEditResult(estimateResult?.status)
                  ? [
                      {
                        name: 'edit',
                        displayName: '編集',
                        func: (v: EstimateResultDetailListViewData) => {
                          setEstimateResultDetailRegistrationDialogId(
                            v.estimateResultDetailId
                          );
                          setEstimateResultDialogOpen(true);
                        },
                      },
                    ]
                  : [],
              }}
            />
          </div>
        </div>
      </div>
      <Accordion
        title={GetMessageWithIntl(intl, {
          id: 'estimateResult.internalManagementInfo',
          prefixId: viewId,
        })}
      >
        <div className="input-line mb-6">
          <div className="item-group-100">
            <div className="w-66">
              <IconButton
                name="editRemarks"
                iconType="edit"
                caption="編集"
                className="memoEdit"
                buttonType="basic"
                onClick={() => {
                  setCommentDialogOpen(true);
                }}
              />
              <div className="w-60">
                <Textarea
                  name="estimateResult.remarks"
                  className="w-100 mh-middle"
                  labelId={`${viewId}.estimateResult.remarks`}
                  value={estimateResult?.remarks ?? ''}
                  disabled={true}
                  columns={['estimateResult.remarks']}
                />
              </div>
            </div>
          </div>
        </div>
      </Accordion>
      {/* 見積基本情報入力ダイアログ */}
      <EstimateBasicInfoRegistrationDialog
        isOpen={estimateBasicInfoDialogOpen}
        inputOption={{
          deliveryTimeDt: convertDate(estimateResult?.deliveryTimeDt ?? null),
          deliveryTimeRemarks: estimateResult?.deliveryTimeRemarks ?? '',
          leadTimeNum: estimateResult?.leadTimeNum ?? undefined,
          leadTimeUnit: estimateResult?.leadTimeUnit ?? undefined,
          estimateExpireDt: convertDate(
            estimateResult?.estimateExpireDt ?? null
          ),
          estimateRemarks: estimateResult?.estimateRemarks ?? '',
        }}
        onDecision={(v) => handleSaveEstimateBasicInfo(v)}
        onCancel={() => setEstimateBasicInfoDialogOpen(false)}
      />
      {/* 明細内訳登録ダイアログ */}
      <EstimateResultDetailRegistrationDialog
        isOpen={estimateResultDialogOpen}
        inputOption={{
          data:
            estimateResultDetails?.find(
              (v) =>
                v.estimateResultDetailId ===
                estimateResultDetailRegistrationDialogId
            ) ?? {},
        }}
        onDecision={(v) => handleSaveEstimateResultDetail(v)}
        onCancel={() => setEstimateResultDialogOpen(false)}
      />
      {/* 社内メモ登録ダイアログ */}
      <CommentDialog
        isOpen={commentDialogOpen}
        inputOption={{
          comment: estimateResult?.remarks ?? '',
          modeType: 'save',
        }}
        inputStateOption={{ onChangeComment: setComment }}
        messageOption={{
          headerLabelId: {
            id: 'company_memo_registration',
            prefixId: 'DIALOG_TITLE',
          },
          captionLabelId: {
            id: 'company_memo',
            prefixId: 'DIALOG_CAPTION',
          },
          decisionLabelId: {
            id: 'save',
          },
        }}
        onDecision={() => handleSaveRemarks()}
        onCancel={() => setCommentDialogOpen(false)}
      />
    </>
  );
};
