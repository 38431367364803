import { useEffect, useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { DetailConfirmationDialog } from '~/shared/components/ui/Dialog/DetailConfirmationDialog';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';

import { LoadingIcon } from '~/shared/components';
import { convertDatetime, FullMethodName_ListAssets } from '~/shared/utils';
import Long from 'long';

export const UISampleDetailConfirmationDialogs = () => {
  const [result, setResult] = useState<string>('');
  const [isShowDialog, setIsShowDialog] = useState(false);

  const processs: sharelib.INameOption[] = [
    window.App.services.ui.getNameOptionWithCode('A0000006', '1'),
  ];
  const quantityAmountUnit: sharelib.INameOption =
    window.App.services.ui.getNameOptionWithCode('A0000005', '1');
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = (await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListAssets,
        filter: {},
        sort: [],
      })) as mtechnavi.api.assetinventory.ListAssetsResponse;
      let attach: sharelib.IAttachment = {};
      if (result.items.length > 0) {
        const item = result.items.shift();
        if (item) {
          attach = item;
        }
      }

      setData({
        estimateRequestDetailId: '1', // 見積依頼明細ID
        estimateRequestId: '1', // 見積依頼ID
        detailAutoName: Long.fromNumber(1), // 明細番号
        transactionUnit: {
          transactionUnitId: '1',
          displayNameLang: 'カシメパンチA',
          transactionUnitCode: 'C',
        }, // 品目
        displayName: '品目名AA', // 品目名
        quantity: { unit: quantityAmountUnit, amountNum: '3' }, // 数量/単位
        inspection: true, // 検査証必要
        blueprint: {
          blueprintId: '',
          blueprintNumber: 'XXX-XXX-01',
          branchNumber: '1',
          revision: '1',
        }, // 図面
        attachment: attach, // 添付ファイル
        processs: processs, // 工程
        modelNumber: '型式です', // 型式
        manufacture: 'メーカーです', // メーカー
        contactMatter: '連絡事項の内容', // 連絡事項
        suppliedParts: true, // 支給品有り
        suppliesDt: convertDatetime(new Date(), 'YYYY/MM/DD'), // 支給日
        suppliesRemarks: '支給方法/場所の値', // 支給方法/場所
        requiredMaterialCost: true, // 材料費必須
        requiredProcessingCost: true, // 加工費必須
        requiredAdministrativeCost: true, // 管理費必須
        requiredFreightCost: true, // 運送費必須
      });
      setLoading(false);
    })();
    // 初回起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] =
    useState<mtechnavi.api.estimation.IEstimateRequestDetail>();

  return (
    <div>
      <div className="categories">
        <h3 id="dialog">明細確認ダイアログ</h3>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <CaptionButton
                buttonType="basic"
                caption="確認する"
                name=""
                onClick={() => {
                  setIsShowDialog(true);
                }}
              />
              <DetailConfirmationDialog
                isOpen={isShowDialog}
                inputOption={{ detailInfo: data ?? {} }}
                onCancel={() => {
                  setResult('');
                  setIsShowDialog(false);
                }}
              />
            </div>
            <div className="w-66">
              <pre className="output">
                {result && JSON.stringify(result, null, 2)}
              </pre>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingIcon />}
    </div>
  );
};
