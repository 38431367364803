import React, { createContext, useContext, useReducer } from 'react';
import { SHAPE_TYPE_RECT } from './Shapes/Rect';
import { OffsetInfo, SelectionInfo } from '../Viewer';
import { mtechnavi } from '~/shared/libs/clientsdk';

export interface AssetInfo {
  asset?: mtechnavi.api.assetinventory.IAsset;
  resourceId: string;
}

export interface ShapeInfo {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  attributes: { selectionShape: string };
}

interface AssetSet {
  assetList: AssetInfo[];
  activeResourceId?: string | null;
  activeAssetId?: string | null;
}

interface ThreadSet {
  threadList: mtechnavi.api.forum.IThread[];
  activeThreadId?: string | null;
}

interface ShowOption {
  isShowCommentPane: boolean;
  isShowFileList: boolean;
}

interface ViewerInfo {
  offset: OffsetInfo;
  scale: number;
  width: number;
  height: number;
  isDragging: boolean;
}

interface State {
  viewerInfo: ViewerInfo;
  selection: ShapeInfo;
  draft: ShapeInfo | null;
  assetSet: AssetSet;
  threadSet: ThreadSet;
  showOption: ShowOption;
}

const initialState: State = {
  viewerInfo: {
    offset: { x: 0, y: 0 },
    scale: 1,
    height: 0,
    width: 0,
    isDragging: false,
  },
  selection: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    attributes: {
      selectionShape: SHAPE_TYPE_RECT,
    },
  },
  draft: null,
  assetSet: {
    assetList: [],
  },
  threadSet: {
    threadList: [],
  },
  showOption: {
    isShowCommentPane: false,
    isShowFileList: false,
  },
};

const CommentPaneContext = createContext<State>({
  ...initialState,
});
const CommentPaneDispatchContext = createContext<React.Dispatch<Action>>(
  () => {}
);

interface ActionViewerResize {
  type: 'viewerResize';
  size: { width: number; height: number };
}
interface ActionChangeScale {
  type: 'changeScale';
  scale: number;
}
interface ActionDragging {
  type: 'dragging';
  offset: OffsetInfo;
}
interface ActionChangeOffset {
  type: 'changeOffset';
  offset: OffsetInfo;
}
interface ActionChangeShapeType {
  type: 'changeShapeType';
  shapeType: string;
}
interface ActionChangeSelection {
  type: 'changeSelection';
  selection: SelectionInfo;
}
interface ActionEndSelection {
  type: 'endSelection';
  selection: SelectionInfo;
}
interface ActionAddThread {
  type: 'addThread';
  thread: mtechnavi.api.forum.IThread;
}
interface ActionUpdateThread {
  type: 'updateThread';
  thread: mtechnavi.api.forum.IThread;
}
interface ActionCancelThread {
  type: 'cancelThread';
}
interface ActionShowCommentPane {
  type: 'showCommentPane';
}
interface ActionHideCommentPane {
  type: 'hideCommentPane';
}
interface ActionShowFileList {
  type: 'showFileList';
}
interface ActionHideFileList {
  type: 'hideFileList';
}
interface ActionChangeActiveThread {
  type: 'changeActiveThread';
  threadId?: string | null;
}
interface ActionSetFileList {
  type: 'setFileList';
  fileList: AssetInfo[];
}
interface ActionChangeActiveAsset {
  type: 'changeActiveAsset';
  resourceId: string;
  assetId: string;
  threadList: mtechnavi.api.forum.IThread[];
}

type Action =
  | ActionViewerResize
  | ActionChangeScale
  | ActionDragging
  | ActionChangeOffset
  | ActionChangeShapeType
  | ActionChangeSelection
  | ActionEndSelection
  | ActionAddThread
  | ActionUpdateThread
  | ActionCancelThread
  | ActionShowCommentPane
  | ActionHideCommentPane
  | ActionShowFileList
  | ActionHideFileList
  | ActionChangeActiveThread
  | ActionSetFileList
  | ActionChangeActiveAsset;
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'viewerResize':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          ...action.size,
        },
      };
    case 'changeScale':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          scale: action.scale,
        },
      };
    case 'dragging':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          offset: action.offset,
          isDragging: true,
        },
      };
    case 'changeOffset':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          offset: action.offset,
          isDragging: false,
        },
      };
    case 'changeShapeType':
      return {
        ...state,
        selection: {
          ...state.selection,
          attributes: {
            selectionShape: action.shapeType,
          },
        },
      };
    case 'changeSelection': {
      if (isIgnoreSize(action.selection)) {
        return {
          ...state,
          selection: {
            ...state.selection,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
          },
        };
      }
      return {
        ...state,
        selection: baseScaleShape(state, action),
      };
    }
    case 'endSelection':
      if (isIgnoreSize(action.selection)) {
        return {
          ...state,
          draft: null,
          selection: {
            ...state.selection,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
          },
        };
      }
      return {
        ...state,
        draft: baseScaleShape(state, action),
        showOption: {
          isShowCommentPane: true,
          isShowFileList: false,
        },
        selection: {
          ...state.selection,
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 0,
        },
        threadSet: {
          ...state.threadSet,
          activeThreadId: null,
        },
      };
    case 'addThread':
      if (!state.draft) {
        return state;
      }
      return {
        ...state,
        draft: null,
        threadSet: {
          threadList: [action.thread, ...state.threadSet.threadList],
          activeThreadId: action.thread.threadId,
        },
      };
    case 'updateThread':
      return {
        ...state,
        threadSet: {
          ...state.threadSet,
          threadList: state.threadSet.threadList.map((item) => {
            if (item.threadId === action.thread.threadId) {
              return action.thread;
            }
            return item;
          }),
        },
      };
    case 'cancelThread':
      return {
        ...state,
        draft: null,
        showOption: {
          ...state.showOption,
          isShowCommentPane: state.threadSet.threadList.length > 0,
        },
      };
    case 'showCommentPane':
      return {
        ...state,
        showOption: {
          isShowCommentPane: true,
          isShowFileList: false,
        },
      };
    case 'hideCommentPane':
      return {
        ...state,
        showOption: {
          ...state.showOption,
          isShowCommentPane: false,
        },
        draft: null,
      };
    case 'showFileList':
      return {
        ...state,
        showOption: {
          isShowFileList: true,
          isShowCommentPane: false,
        },
      };
    case 'hideFileList':
      return {
        ...state,
        showOption: {
          ...state.showOption,
          isShowFileList: false,
        },
      };
    case 'changeActiveThread':
      if (state.draft) {
        return state;
      }
      return {
        ...state,
        threadSet: {
          ...state.threadSet,
          activeThreadId: action.threadId,
        },
        showOption: {
          isShowFileList: false,
          isShowCommentPane: true,
        },
      };
    case 'setFileList':
      return {
        ...state,
        assetSet: {
          activeResourceId: null,
          activeAssetId: null,
          assetList: action.fileList,
        },
        showOption: {
          isShowFileList:
            action.fileList.length > 0 ? true : state.showOption.isShowFileList,
          isShowCommentPane:
            action.fileList.length > 0
              ? false
              : state.showOption.isShowCommentPane,
        },
      };
    case 'changeActiveAsset':
      return {
        ...state,
        assetSet: {
          ...state.assetSet,
          activeResourceId: action.resourceId,
          activeAssetId: action.assetId,
        },
        threadSet: {
          threadList: action.threadList,
          activeThreadId: null,
        },
      };
    default:
      throw new Error(`unknown action: ${action}`);
  }
};

const IGNORE_SIZE_THRESHOLD = 20;
const isIgnoreSize = (selection: SelectionInfo) => {
  return (
    Math.abs(selection.x1 - selection.x2) <= IGNORE_SIZE_THRESHOLD &&
    Math.abs(selection.y1 - selection.y2) <= IGNORE_SIZE_THRESHOLD
  );
};

const baseScaleShape = (
  { viewerInfo, selection }: State,
  action: ActionChangeSelection | ActionEndSelection
): ShapeInfo => {
  const x1 = pixelToViewRatio(action.selection.x1, viewerInfo.width);
  const y1 = pixelToViewRatio(action.selection.y1, viewerInfo.height);
  const x2 = pixelToViewRatio(action.selection.x2, viewerInfo.width);
  const y2 = pixelToViewRatio(action.selection.y2, viewerInfo.height);
  return {
    ...selection,
    x1,
    y1,
    x2: x1 + (x2 - x1) / viewerInfo.scale,
    y2: y1 + (y2 - y1) / viewerInfo.scale,
  };
};

const PROT_RATIO_ACCURACY = 10000;
export const pixelToViewRatio = (pixel: number, viewSize: number): number => {
  if (viewSize === 0) {
    return 0;
  }
  return Math.floor((pixel * PROT_RATIO_ACCURACY) / viewSize);
};
export const viewRatioToPixel = (ratio: number, viewSize: number): number => {
  return (ratio * viewSize) / PROT_RATIO_ACCURACY;
};

export interface Props {
  children: React.ReactNode;
}
export const CommentPaneProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  return (
    <CommentPaneContext.Provider value={state}>
      <CommentPaneDispatchContext.Provider value={dispatch}>
        {children}
      </CommentPaneDispatchContext.Provider>
    </CommentPaneContext.Provider>
  );
};

export const useCommentPane = () => {
  return useContext(CommentPaneContext);
};

export const useCommentPaneDispatch = () => {
  return useContext(CommentPaneDispatchContext);
};
