import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
} from '../..';
import { CaptionButton } from '../Button';
import { useIntl } from 'react-intl';
import {
  getDateFormat,
  getProgramOptionFilterboxDataWithSystemName,
  includeInputValidateError,
} from '~/shared/utils';
import { useMemo, useRef, useState, useEffect } from 'react';
import { Textarea } from '../Textarea';
import { Checkbox } from '../Checkbox';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox';
import { convertLongToString } from '~/shared/utils/converter';

const DialogId = 'estimateSelectionDialog';

export interface EstimateSelectionDialogInputOption {
  isReadOnly?: boolean;
  /** パラメータの見積依頼データ */
  request?: mtechnavi.api.estimation.IEstimateRequest;
  /** パラメータの見積依頼送信先データ */
  requestUnit?: mtechnavi.api.estimation.IEstimateRequestUnit;
  /** 入力項目初期値とする見積選考データ */
  data?: mtechnavi.api.estimation.IEstimateSelection;
}
export interface EstimateSelectionDialogProps {
  isOpen: boolean;
  inputOption: EstimateSelectionDialogInputOption;
  onCancel?: () => void;
  onDecision?: (result: EstimateSelectionDialogResult) => void;
}
export interface EstimateSelectionDialogResult {
  estimateSelection?: mtechnavi.api.estimation.IEstimateSelection;
  estimateRequestUnitId?: string;
}
/**
 * 選考ダイアログ
 */
export const EstimateSelectionDialog = (
  props: EstimateSelectionDialogProps
) => {
  const { isOpen, inputOption, onCancel, onDecision } = props;
  const { isReadOnly, request, requestUnit, data } = inputOption;
  const intl = useIntl();
  const formRef = useRef(null);
  const [workingBlur, setWorkingBlur] = useState<Date>();

  // 画面入力値
  const [adoption, setAdoption] = useState(
    !data?.estimateSelectionId ? null : data.adoption
  );
  const [remarks, setRemarks] = useState(data?.remarks || undefined);
  const [notification, setNotification] = useState(!!data?.notification);
  const [feedbackCategory, setFeedbackCategory] = useState(
    data?.feedbackCategory
  );
  const [feedbackComment, setFeedbackComment] = useState(
    data?.feedbackComment || undefined
  );

  const formReset = () => {
    setAdoption(null);
    setRemarks(undefined);
    setNotification(false);
    setFeedbackCategory(undefined);
    setFeedbackComment(undefined);
  };

  // フォームに初期値をセットする
  useEffect(() => {
    if (!isOpen) {
      formReset();
      return;
    }
    const { data } = props.inputOption;

    setAdoption(!data?.estimateSelectionId ? null : data.adoption);
    setRemarks(data?.remarks || undefined);
    setNotification(!!data?.notification);
    setFeedbackCategory(data?.feedbackCategory);
    setFeedbackComment(data?.feedbackComment || undefined);

    // isOpen 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // 選考通知選択データ・選択肢
  const [feedbackCategoryData, feedbackCategoryItems] = useMemo(() => {
    if (adoption === null) {
      return [[], []];
    }
    const systemName = adoption ? 'B01' : 'B00';
    const options = window.App.services.ui.getProgramOptionWithSystemName(
      'A7000007',
      systemName
    );
    const filterboxItems = getProgramOptionFilterboxDataWithSystemName(
      'A7000007',
      systemName
    );
    return [options, filterboxItems];
  }, [adoption]);

  const handleCancel = () => {
    onCancel && onCancel();
    setWorkingBlur(undefined);
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const selection: mtechnavi.api.estimation.IEstimateSelection = {
      ...data,
      estimateRequestId: request?.estimateRequestId,
      adoption,
      remarks,
      notification,
      feedbackCategory,
      feedbackComment,
    };

    const result: EstimateSelectionDialogResult = {
      estimateSelection: selection,
      estimateRequestUnitId: requestUnit?.estimateRequestUnitId ?? '',
    };

    onDecision && onDecision(result);
  };

  const isInputError = (): boolean => {
    setWorkingBlur(new Date());

    const targetElm = document.querySelector('.EstimateSelectionDialog');
    return (
      includeInputValidateError(targetElm, intl, [
        {
          value: adoption !== null ? '1' : '',
          ref: formRef,
        },
      ]) || adoption === null
    );
  };

  const clearFeedback = () => {
    setFeedbackCategory(null);
    setFeedbackComment('');
  };

  const resetFeedbackComment = (
    feedbackCategory?: mtechnavi.api.programoption.IProgramOption
  ) => {
    const options = (
      window.App.services.ui.getProgramOptionWithSystemName(
        'A7000008',
        feedbackCategory?.systemName || ''
      ) as mtechnavi.api.programoption.IProgramOption[]
    ).filter((option) => option.code === feedbackCategory?.code);
    const feedbackTextLang = options.at(0)?.displayNameLang;
    setFeedbackComment(
      feedbackTextLang ? feedbackTextLang[intl.locale] || '' : ''
    );
  };

  const elements = (
    <div className="detail-area" ref={formRef}>
      <div className="description-area">
        <p className="right-side-label">
          {/* 依頼通知番号 */}
          {GetMessageWithIntl(intl, {
            id: 'estimateRequestUnitAutoName',
            prefixId: DialogId,
          })}
          :{convertLongToString(requestUnit?.estimateRequestUnitAutoName)}
        </p>
        <h4>{request?.displayName || ''}</h4>
        <p>{requestUnit?.displayName || ''}</p>
      </div>

      <div className="description-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {/* 採用・不採用 */}
              <Radio
                name="adoption"
                disabled={isReadOnly}
                value={adoption === null ? undefined : adoption ? '1' : '2'}
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'adoption',
                      prefixId: DialogId,
                    }),
                    value: '1',
                  },
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'notAdoption',
                      prefixId: DialogId,
                    }),
                    value: '2',
                  },
                ]}
                validateOption={{ required: true }}
                onChangeState={(v) => {
                  const adoption = v === '1';
                  setAdoption(adoption);
                  clearFeedback();
                }}
                workingBlur={workingBlur}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {/* 社内メモ */}
              <Textarea
                name="remarks"
                labelId={`${DialogId}.remarks`}
                disabled={isReadOnly}
                value={remarks}
                columns={['remarks']}
                className="w-100 mh-middle"
                onChangeState={(v) => setRemarks(v)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-50">
            {/* 取引先に通知する */}
            <Checkbox
              name="notification"
              columns={['notification']}
              value={notification ? ['1'] : []}
              disabled={isReadOnly}
              items={[
                {
                  displayName: GetMessageWithIntl(intl, {
                    id: 'notification',
                    prefixId: DialogId,
                  }),
                  value: '1',
                },
              ]}
              onChangeState={(v) => {
                const notification = !!v.length;
                setNotification(notification);
                if (!notification) {
                  clearFeedback();
                }
              }}
            />
          </div>
          <div className="w-50">
            {data?.notificationAt &&
              `${GetMessageWithIntl(intl, {
                id: 'notificationAt',
                prefixId: DialogId,
              })} : ${getDateFormat(data?.notificationAt, 'YYYY/MM/DD HH:mm')}`}
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-50">
            {/* 選考通知 */}
            <DataFilterbox
              data={feedbackCategoryItems}
              name="feedbackCategory"
              labelId={`${DialogId}.feedbackCategory`}
              columns={['feedbackCategory']}
              searchOption={{ targets: 'displayName' }}
              disabled={isReadOnly || !notification}
              value={feedbackCategoryItems.filter(
                (item) => item.value === feedbackCategory?.code
              )}
              onChangeState={(selectedItems: DataFilterboxItem[]) => {
                const selectedNameOption = feedbackCategoryData.find(
                  (item) => selectedItems.at(0)?.value === item?.code
                );
                setFeedbackCategory(selectedNameOption);
                resetFeedbackComment(selectedNameOption);
              }}
            />
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            {/* 選考通知コメント */}
            <Textarea
              name="feedbackComment"
              labelId={`${DialogId}.feedbackComment`}
              disabled={isReadOnly || !notification}
              value={feedbackComment}
              columns={['feedbackComment']}
              className="w-100 mh-middle"
              onChangeState={(v) => setFeedbackComment(v)}
            />
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        {!isReadOnly && (
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(
              intl,
              notification
                ? {
                    prefixId: DialogId,
                    id: 'approvalRequest',
                  }
                : { id: 'save' }
            )}
            onClick={handleDecision}
          />
        )}
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'estimateSelection',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="EstimateSelectionDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
