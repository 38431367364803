import {
  ModalDialogComponent,
  ModalDialogComponentProps,
  Container,
  success,
  error,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
} from '~/shared/components';
import {
  Textbox,
  Textarea,
  Tel,
  ZipCode,
  ConfirmationDialog,
} from '~/shared/components/ui';
import { useIntl } from 'react-intl';
import type { mtechnavi } from '~/shared/libs/clientsdk/generated';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import {
  validateTel,
  validateZipCode,
} from '~/shared/components/parts/validator';
import './CompanyInput.css';
import { useEffect, useRef, useState } from 'react';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import {
  Amounts,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  includeInputValidateError,
} from '~/shared/utils';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

const VIEW_ID: ViewId = 'MASTER_COMPANY_INPUT';

export function CompanyInput() {
  const intl = useIntl();
  const tradingWindowName = GetMessage({ id: 'tradingWindow' });
  const usableEnabled = GetMessage({ id: 'enabled' });
  const [viewName, setViewName] = useState(tradingWindowName);
  const [companyName, setCompanyName] = useState('');
  const [companyNameKana, setCcompanyNameKana] = useState('');
  const [corporationCode, setCorporationCode] = useState('');
  const [eligibleInvoiceNumber, setEligibleInvoiceNumber] =
    useState<string>('');
  const [representativePhoneNumber, setRepresentativePhoneNumber] =
    useState('');
  const [postCode, setPostCode] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [address, setAddress] = useState('');
  const [representativeDisplayNameLang, setRepresentativeDisplayNameLang] =
    useState('');
  const [representativeKana, setRepresentativeKana] = useState('');
  const [representativePosition, setRepresentativePosition] = useState('');
  const [capital, setCapital] = useState<Amounts>();
  const [industryName, setIndustryName] = useState('');
  const [mainBusiness, setMainBusiness] = useState('');
  const [publicComment, setPublicComment] = useState('');
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const [entrySwitch, setEntrySwitch] = useState(false);
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    area1: true,
  });
  const [companyInfo, setCompanyInfo] =
    useState<mtechnavi.api.company.ICompany>();
  const [comment, setComment] = useState('');
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [fieldItems, setFieldItems] = useState<PresetProperty[]>([]);
  const successMessage = GetMessage({
    id: 'I0000001',
  });

  // ローディング
  const [isLoading, setLoading] = useState(false);

  // 登録確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);

  function getProperties(
    target: string,
    properties?: Array<PresetProperty>
  ): Array<PresetProperty> {
    const returnVal = Array<PresetProperty>();
    if (!properties) return returnVal;
    return properties.filter((v) => v.name === target);
  }

  const openModalProps: ModalDialogComponentProps = {
    modalIsOpen: baseModalIsOpen,
    comment: comment,
    onChangeState: setComment,
    headerLabelId: { prefixId: 'DIALOG_TITLE', viewId: VIEW_ID },
    messageLabelId: { prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID },
    send: () => {},
    cancel: () => setModalIsOpen(false),
    elements: DaialogContent(),
  };

  const openModal = () => {
    setViewName(tradingWindowName);
    setModalIsOpen(true);
  };

  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  useEffect(() => {
    (async () => {
      try {
        const res: mtechnavi.api.company.Company =
          await window.App.services.companyService.getCompany({});
        setCompanyInfo(res);

        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setFieldItems(presetItem.property!);
        const componentList: mtechnavi.api.company.IListComponentUnitsResponse =
          await window.App.services.componentunitService.listComponentUnits({});
        componentList.items?.forEach((item) => {
          if (item.companyId === res.companyId && item.postUnit) {
            setEntrySwitch(true);
          }
        });
        setPreset(presetItem);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  useEffect(() => {
    if (!companyInfo?.displayNameLang) return;
    setCompanyName(companyInfo?.displayNameLang?.ja ?? '');
    setCcompanyNameKana(companyInfo?.displayNameLang!['ja-kana'] ?? '');
    setCorporationCode(
      companyInfo?.companyLegalProperties?.companyNumber ?? ''
    );
    setEligibleInvoiceNumber(
      companyInfo?.companyLegalProperties?.eligibleInvoiceAuthorizationNumber ??
        ''
    );
    setRepresentativePhoneNumber(companyInfo?.profile?.phoneNumber ?? '');
    setPostCode(companyInfo?.profile?.address?.postalCode ?? '');
    setWebsiteUrl(companyInfo?.profile?.websiteUrl ?? '');
    setAddress(companyInfo?.profile?.address?.addressLine ?? '');
    setRepresentativeDisplayNameLang(
      companyInfo?.profile?.representative?.displayNameLang!['ja'] ?? ''
    );
    setRepresentativeKana(
      companyInfo?.profile?.representative?.displayNameLang!['ja-kana'] ?? ''
    );
    setRepresentativePosition(
      companyInfo?.profile?.representative?.position ?? ''
    );

    setCapital(companyInfo?.profile?.capitalStock);

    setIndustryName(companyInfo?.profile?.industry ?? '');
    setMainBusiness(companyInfo?.profile?.mainBusiness ?? '');
    setPublicComment(companyInfo?.profile?.publicComment ?? '');
  }, [companyInfo]);

  const sendAction = () => {
    // input項目にエラーが発生していないかチェック
    if (
      includeInputValidateError(document, intl, [
        { value: companyName ?? '', ref: requireArea },
      ])
    ) {
      setLoading(false);
    } else {
      inputEntryAction();
    }
  };

  const makeTradingWindowAction = () => {
    const componentUnit: mtechnavi.api.company.IComponentUnit = {
      companyId: companyInfo?.companyId,
      componentUnitCode: '1',
      displayNameLang: {
        ja: viewName,
      },
      phoneNumber: representativePhoneNumber,
      address: {
        postalCode: postCode,
        addressLine: address,
      },
    };
    const usables = window.App.services.ui.getNameOptionWithSystemName(
      'A0000001',
      'B01'
    );
    const postUnits = window.App.services.ui.getNameOptionWithSystemName(
      'A0000002',
      'B01'
    );
    if (usables.length) {
      componentUnit.usable = usables[0];
    }
    if (postUnits.length) {
      componentUnit.postUnit = postUnits[0];
    }

    (async () => {
      setLoading(true);
      try {
        const res =
          await window.App.services.componentunitService.createComponentUnit({
            componentUnit: componentUnit,
          });
        if (res.componentUnitId) {
          setEntrySwitch(true);
          success([successMessage]);
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
      setModalIsOpen(false);
    })();
  };
  const inputEntryAction = () => {
    // companyIdが空はあり得ないのでエラー
    if (companyInfo?.companyId === '') {
      setLoading(false);
      error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const sendData: mtechnavi.api.company.IUpdateCompanyRequest = {
          company: {
            displayNameLang: {
              ja: companyName,
              'ja-kana': companyNameKana,
            },
            companyLegalProperties: {
              companyNumber: corporationCode,
              eligibleInvoiceAuthorizationNumber: eligibleInvoiceNumber,
            },
            profile: {
              websiteUrl: websiteUrl,
              phoneNumber: representativePhoneNumber,
              address: {
                countryCode: 'JA',
                postalCode: postCode,
                addressLine: address,
              },
              representative: {
                displayNameLang: {
                  ja: representativeDisplayNameLang,
                  'ja-kana': representativeKana,
                },
                position: representativePosition,
              },
              capitalStock: {
                currencyCode: 'JPY',
                integralAmount: capital?.integralAmount ?? null,
                fractionalAmount: capital?.fractionalAmount ?? null,
              },
              industry: industryName,
              mainBusiness: mainBusiness,
              publicComment: publicComment,
            },
          },
        };

        sendData!.company!.companyId = companyInfo?.companyId;
        sendData!.company!.updatedAt = companyInfo?.updatedAt;
        await window.App.services.companyService.updateCompany(sendData);
        // 更新成功通知ダイアログを出す
        success([
          GetMessageWithIntl(intl, {
            id: 'I0000001',
          }),
        ]);

        // 取引先登録
        if (!entrySwitch) {
          setLoading(false);
          openModal();
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
        console.error('error :公開情報登録部分でエラーが発生しています。', err);
        throw err;
      } finally {
        try {
          const res: mtechnavi.api.company.Company =
            await window.App.services.companyService.getCompany({});
          setCompanyInfo(res);
        } catch (err) {
          error(getExceptionMessage(intl, err));
        }
        setLoading(false);
      }
    })();
  };

  function DaialogContent() {
    return (
      <div>
        <div>
          <p className="text">
            取引窓口がありません。以下の情報で作成しますか？
          </p>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="viewName"
                  type="text"
                  value={viewName}
                  labelId="viewName"
                  onChangeState={setViewName}
                  columns={preset.columns}
                  properties={getProperties('viewName', fieldItems)}
                />
              </div>
              <div className="w-25">
                <Textbox
                  name="tradingWindow"
                  type="text"
                  value={tradingWindowName}
                  labelId="componentUnitCategoryName"
                  columns={preset.columns}
                  disabled={true}
                />
              </div>
              <div className="w-25">
                <Textbox
                  name="usable"
                  type="text"
                  value={usableEnabled}
                  labelId="usable"
                  columns={preset.columns}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="contactPhoneNumber"
                  type="text"
                  value={representativePhoneNumber}
                  labelId="contactPhoneNumber"
                  columns={preset.columns}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="postCode"
                  type="text"
                  value={postCode}
                  labelId="postCode"
                  columns={preset.columns}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-75">
                <Textbox
                  name="address"
                  type="text"
                  value={address}
                  labelId="address"
                  columns={preset.columns}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn-area">
          <CaptionButton
            name="resetBtn"
            caption="取引窓口作成する"
            className="button-margin"
            properties={[
              {
                name: 'resetBtn',
                propertyName: 'resetBtn',
                propertyValue: 'resetBtn',
              },
            ]}
            onClick={() => makeTradingWindowAction()}
            buttonType="basic"
          />
          <CaptionButton
            name="resetBtn"
            caption="あとで登録する"
            className="button-margin"
            properties={[
              {
                name: 'resetBtn',
                propertyName: 'resetBtn',
                propertyValue: 'resetBtn',
              },
            ]}
            onClick={() => {
              setModalIsOpen(false);
            }}
            buttonType="basic"
          />
        </div>
      </div>
    );
  }

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const requireArea = useRef(null);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="CompanyInput">
          <div className="header"></div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div
              className={`input-blocktitle-outer ${
                accordionState.area1 ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('area1')}
              >
                {GetMessage({ id: 'publicInformation' })}
              </h3>
            </div>
            {accordionState.area1 && (
              <div className="field-outer">
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-66" ref={requireArea}>
                      {/* 企業名 */}
                      <Textbox
                        name="companyName"
                        className="field"
                        type="text"
                        value={companyName}
                        labelId="companyName"
                        onChangeState={setCompanyName}
                        columns={preset.columns}
                        validateOption={{ required: true, maxLength: 50 }}
                        properties={getProperties('companyName', fieldItems)}
                      />
                    </div>
                    <div className="w-33">
                      {/* 法人コード */}
                      <Textbox
                        name="corporationCode"
                        type="text"
                        value={corporationCode}
                        labelId="corporationCode"
                        onChangeState={setCorporationCode}
                        columns={preset.columns}
                        validateOption={{ maxLength: 13 }}
                        properties={getProperties(
                          'corporationCode',
                          fieldItems
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-66">
                      {/* 企業名（かな） */}
                      <Textbox
                        name="companyNameKana"
                        type="text"
                        value={companyNameKana}
                        labelId="companyNameKana"
                        onChangeState={setCcompanyNameKana}
                        columns={preset.columns}
                        validateOption={{ maxLength: 50 }}
                        properties={getProperties(
                          'companyNameKana',
                          fieldItems
                        )}
                      />
                    </div>
                    <div className="w-33">
                      {/* 適格請求書認定番号 */}
                      <Textbox
                        name="eligibleInvoiceNumber"
                        type="text"
                        value={eligibleInvoiceNumber}
                        labelId="eligibleInvoiceNumber"
                        onChangeState={setEligibleInvoiceNumber}
                        columns={preset.columns}
                        validateOption={{ maxLength: 14 }}
                        properties={getProperties(
                          'eligibleInvoiceNumber',
                          fieldItems
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      {/* 代表電話番号 */}
                      <Tel
                        name="representativePhoneNumber"
                        labelId="representativePhoneNumber"
                        value={representativePhoneNumber}
                        validator={(v) => validateTel(intl, v)}
                        onChangeState={setRepresentativePhoneNumber}
                        columns={preset.columns}
                        properties={getProperties(
                          'representativePhoneNumber',
                          fieldItems
                        )}
                      />
                    </div>
                    <div className="w-33">
                      {/* 代表者名 */}
                      <Textbox
                        name="representativeDisplayNameLang"
                        type="text"
                        value={representativeDisplayNameLang}
                        labelId="representative"
                        onChangeState={setRepresentativeDisplayNameLang}
                        columns={preset.columns}
                        validateOption={{ maxLength: 32 }}
                        properties={getProperties(
                          'representativeDisplayNameLang',
                          fieldItems
                        )}
                      />
                    </div>
                    <div className="w-33">
                      {/* 会社URL */}
                      <Textbox
                        name="websiteUrl"
                        type="text"
                        value={websiteUrl}
                        labelId="websiteUrl"
                        onChangeState={setWebsiteUrl}
                        columns={preset.columns}
                        validateOption={{ maxLength: 64 }}
                        properties={getProperties('websiteUrl', fieldItems)}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      {/* 郵便番号 */}
                      <ZipCode
                        name="postCode"
                        value={postCode}
                        validator={(v) => validateZipCode(intl, v)}
                        labelId="postCode"
                        onChangeState={setPostCode}
                        columns={preset.columns}
                        properties={getProperties('postCode', fieldItems)}
                      />
                    </div>
                    <div className="w-33">
                      {/* 代表者名（かな） */}
                      <Textbox
                        name="representativeKana"
                        type="text"
                        value={representativeKana}
                        labelId="representativeKana"
                        onChangeState={setRepresentativeKana}
                        columns={preset.columns}
                        validateOption={{ maxLength: 32 }}
                        properties={getProperties(
                          'representativeKana',
                          fieldItems
                        )}
                      />
                    </div>
                    <div className="w-33">
                      {/* 資本金（百万） */}
                      <Amountbox
                        name="capital"
                        value={capital}
                        labelId="capital"
                        onChangeState={setCapital}
                        columns={preset.columns}
                        displayOption={{ digits: 4, isCommaFormat: true }}
                        properties={getProperties('capital', fieldItems)}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      {/* 住所 */}
                      <Textbox
                        name="address"
                        value={address}
                        type="text"
                        labelId="address"
                        onChangeState={setAddress}
                        columns={preset.columns}
                        validateOption={{ maxLength: 128 }}
                        properties={getProperties('address', fieldItems)}
                      />
                    </div>
                    <div className="w-33">
                      {/* 代表者役職 */}
                      <Textbox
                        name="representativePosition"
                        type="text"
                        value={representativePosition}
                        labelId="representativePosition"
                        onChangeState={setRepresentativePosition}
                        columns={preset.columns}
                        validateOption={{ maxLength: 32 }}
                        properties={getProperties(
                          'representativePosition',
                          fieldItems
                        )}
                      />
                    </div>
                    <div className="w-33">
                      {/* 業種名称 */}
                      <Textbox
                        name="industryName"
                        type="text"
                        value={industryName}
                        labelId="industryName"
                        onChangeState={setIndustryName}
                        columns={preset.columns}
                        validateOption={{ maxLength: 32 }}
                        properties={getProperties('industryName', fieldItems)}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-100">
                      <Textarea
                        name="mainBusiness"
                        className="w-100 mh-middle"
                        value={mainBusiness}
                        labelId="mainBusiness"
                        onChangeState={setMainBusiness}
                        columns={preset.columns}
                        validateOption={{ maxLength: 1000 }}
                        properties={getProperties('mainBusiness', fieldItems)}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-100">
                      <Textarea
                        name="publicComment"
                        className="w-100 mh-middle"
                        labelId="publicComment"
                        value={publicComment}
                        onChangeState={setPublicComment}
                        columns={preset.columns}
                        validateOption={{ maxLength: 1000 }}
                        properties={getProperties('publicComment', fieldItems)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="resetBtn"
                  caption="確定"
                  className="btn"
                  properties={[
                    {
                      name: 'resetBtn',
                      propertyName: 'resetBtn',
                      propertyValue: 'resetBtn',
                    },
                  ]}
                  onClick={() => setOpenConfirmDialog(true)}
                  buttonType="basic"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ModalDialogComponent {...openModalProps} />
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={{
          id: 'C0000001',
          value: {
            $1: GetMessageWithIntl(intl, {
              prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
              viewId: VIEW_ID,
            }),
          },
        }}
        onDecision={() => {
          setLoading(true);
          setOpenConfirmDialog(false);
          sendAction();
        }}
        onCancel={() => {
          setOpenConfirmDialog(false);
        }}
      ></ConfirmationDialog>
      {isLoading && <LoadingIcon />}
      <Toast />
    </>
  );
}
