import { useRef, useState } from 'react';
import { success } from '~/shared/components';
import { Checkbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  BuildedInputFormDialog,
  BuildedInputFormDialogRef,
} from '~/shared/components/ui/FormBuilder/BuildedInputFormDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { CommmonAttachedFileSystemCode } from '~/shared/utils';

const SAMPLE_ASSET_SYSTEM_CODE: CommmonAttachedFileSystemCode = 'B10';

export const UISampleBuildedInputFormDialogs = () => {
  const [result, setResult] = useState('');
  const formInputDialogRef =
    useRef<BuildedInputFormDialogRef<mtechnavi.api.form.ISampleWorkForm>>(null);
  const [headerMessageID, setHeaderMessageId] = useState('');
  const [isDecisionCheckShow, setDecisionCheckShow] = useState(false);
  const [isDecisionButtonLabel, setDecisionButtonLabel] = useState(false);
  const [isDownloadable, setDownloadable] = useState(false);
  const [isImportable, setImportable] = useState(false);

  const handleDialogOpen = (readonly: boolean) => {
    formInputDialogRef.current?.open(
      DummyForm,
      DummyFormSetting,
      DummyFormSettingItems,
      DummySampleWorkForm,
      SAMPLE_ASSET_SYSTEM_CODE,
      [],
      readonly,
      isDecisionCheckShow
    );
  };

  const handleDownload = async () => {
    success(['ダウンロード処理が発生しました']);
  };

  const handleImport = async () => {
    success(['インポート処理が発生しました']);
  };

  return (
    <div className="categories">
      <h3 id="dialog">フォーム入力系ダイアログ</h3>
      <h4>フォーム入力ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: '確定チェックの表示', value: '1' }]}
              onChangeState={(v) => {
                setDecisionCheckShow(!!v.length);
              }}
            />
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: '「確定」ボタンにする', value: '1' }]}
              onChangeState={(v) => {
                setDecisionButtonLabel(!!v.length);
              }}
            />
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: 'ダウンロードボタンの表示', value: '1' }]}
              onChangeState={(v) => {
                setDownloadable(!!v.length);
              }}
            />
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: 'アップロードボタンの表示', value: '1' }]}
              onChangeState={(v) => {
                setImportable(!!v.length);
              }}
            />
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setHeaderMessageId('sampleFormInputDialog');
                handleDialogOpen(true);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>フォーム確認ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setHeaderMessageId('sampleFormConfirmationDialog');
                handleDialogOpen(false);
              }}
            />
          </div>
        </div>
      </div>
      <BuildedInputFormDialog
        messageOption={{
          headerLabelId: { prefixId: 'FORM_BUILDER', id: headerMessageID },
          buttonLabelId: isDecisionButtonLabel ? { id: 'decision' } : undefined,
        }}
        onDecision={(v) => setResult(v)}
        onDownload={isDownloadable ? handleDownload : undefined}
        onImport={isImportable ? handleImport : undefined}
        ref={formInputDialogRef}
      />
    </div>
  );
};

const DummyForm: mtechnavi.api.form.IForm = {
  formId: 'dummyformid1',
  baseFormId: 'dummybaseformid2',
  formProperties: {
    displayName: 'サンプルフォーム',
  },
};

const DummyFormSetting: mtechnavi.api.form.IFormSetting = {
  formSettingId: 'dummyformsettingid',
  formId: DummyForm.formId,
  formPages: [
    {
      formPageId: 'dummyformpageid',
      displayName: 'page_1',
    },
  ],
};

const DummyFormSettingItems: mtechnavi.api.form.IFormSettingItem[] = [
  {
    formSettingItemId: 'dummyformsettingitemid1',
    formId: DummyForm.formId,
    formSettingId: DummyFormSetting.formSettingId,
    formPageId: DummyFormSetting.formPages![0].formPageId,
    item: {
      itemAutoName: 'autoname1',
      inputType: 'text',
      displayName: 'text1',
    },
  },
  {
    formSettingItemId: 'dummyformsettingitemid2',
    formId: DummyForm.formId,
    formSettingId: DummyFormSetting.formSettingId,
    formPageId: DummyFormSetting.formPages![0].formPageId,
    item: {
      itemAutoName: 'autoname2',
      inputType: 'text',
      displayName: 'text2',
    },
  },
];

const DummySampleWorkForm: mtechnavi.api.form.ISampleWorkForm = {
  sampleWorkFormId: 'dummysampleworkformid',
  formId: DummyForm.formId,
  baseDisplayName: 'ベースサンプルフォーム',
  displayName: 'サンプルフォーム',
  attribute1: '情報の内容１',
  attribute2: '情報の内容２',
  attribute3: '情報の内容３',
  notice: 'お知らせ内容',
};
