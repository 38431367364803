import { PropsWithoutRef, useId } from 'react';
import './FormInputCheckbox.css';
import { CheckboxValidateOption, GetMessage } from '~/shared/components';
import { IconButton } from '~/shared/components/ui/Button';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface FormInputCheckboxProps {
  name: string;
  caption?: string;
  description?: string;
  value: mtechnavi.api.form.IFormControlToggle;
  disabled?: boolean;
  validateOption?: CheckboxValidateOption;
  onChangeState?: (arg: mtechnavi.api.form.IFormControlToggle) => void;
  workingBlur?: Date;
}
export const FormInputCheckbox = (
  props: PropsWithoutRef<FormInputCheckboxProps>
) => {
  const inputId = useId();
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;
  const isSkippedRequireCheck =
    props.validateOption?.isSkippedRequireCheck ?? false;
  const checked = (props.value && props.value.on) || false;
  const message =
    !isSkippedRequireCheck && !isSkippedValidation && required && !checked
      ? [REQUIRED_MESSAGE]
      : [];
  const changeParentState = props.onChangeState ?? (() => {});

  const handleChange = (checkbox: HTMLInputElement) => {
    const value = checkbox.checked;
    changeParentState({ on: value });
  };

  return (
    <div className="FormInput FormInputCheckbox">
      <div
        className={props.validateOption?.required ? 'checkbox-required' : ''}
      >
        <input
          id={inputId}
          name={props.name}
          type="checkbox"
          onChange={(event) => handleChange(event.target)}
          value="1"
          checked={checked}
          disabled={props.disabled}
        />
        <label htmlFor={inputId}>
          <span className="label-content">
            {props.caption || ''}
            {props.description && (
              <IconButton
                name="help"
                className="HelpIcon"
                iconType="help"
                buttonType="basic"
                onClick={() => {}}
                caption={props.description}
              />
            )}
          </span>
        </label>
      </div>
      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
};
