import { Container } from '~/shared/components';
import { FileViewer as FileViewerUi } from '~/shared/components/ui';

export function FileViewer() {
  return (
    <Container>
      <FileViewerUi></FileViewerUi>
    </Container>
  );
}
