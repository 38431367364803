import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import {
  DataFilterbox,
  DataFilterboxItem,
  Textarea,
  Textbox,
  DateSuggest,
} from '~/shared/components/ui';
import { sharelib } from '~/shared/libs/clientsdk';
import { getProgramOptionFilterboxData } from '~/shared/utils/converter';
import { convertNameOptionToFilterboxItem } from '~/shared/utils';
import { validateFreeDate } from '~/shared/components/parts/validator';
import './EstimateBasicInfoRegistrationDialog.css';
import { Amountbox } from '../Amountbox';
import {
  convertAmountToString,
  convertStringToAmount,
} from '~/shared/utils/converter';

export interface EstimateBasicInfoRegistrationInfo {
  deliveryTimeDt?: Date | null; // 回答納期
  deliveryTimeRemarks?: string; // 納期補足
  leadTimeNum?: string; // 参考リードタイム
  leadTimeUnit?: sharelib.INameOption; // // 参考リードタイム単位
  estimateExpireDt?: Date | null; // 見積有効期限
  estimateRemarks?: string; // 見積摘要
}

export interface EstimateBasicInfoRegistrationDialogProps {
  inputOption: EstimateBasicInfoRegistrationInfo;
  isOpen: boolean;
  onDecision: (v: EstimateBasicInfoRegistrationInfo) => void;
  onCancel: () => void;
}

const LeadTimeUnitCode = 'A7000009';

export const EstimateBasicInfoRegistrationDialog = (
  props: EstimateBasicInfoRegistrationDialogProps
) => {
  const intl = useIntl();
  const [leadTimeUnitCodes] = useMemo<[DataFilterboxItem[]]>(() => {
    const leadTimeUnitCodes = getProgramOptionFilterboxData(LeadTimeUnitCode);
    return [leadTimeUnitCodes];
  }, []);
  const { isOpen } = props;

  const [deliveryTimeDt, setDeliveryTimeDt] = useState<Date | null>(null);
  const [deliveryTimeRemarks, setDeliveryTimeRemarks] = useState<string>('');
  const [leadTimeNum, setLeadTimeNum] = useState<sharelib.IAmount | null>(null);
  const [leadTimeUnit, setLeadTimeUnit] = useState<DataFilterboxItem[]>([]);
  const [estimateExpireDt, setEstimateExpireDt] = useState<Date | null>(null);
  const [estimateRemarks, setEstimateRemarks] = useState<string>('');

  const handleDecision = () => {
    const basicInfo: EstimateBasicInfoRegistrationInfo = {};
    basicInfo.deliveryTimeDt = deliveryTimeDt;
    basicInfo.deliveryTimeRemarks = deliveryTimeRemarks;
    basicInfo.leadTimeNum = convertAmountToString(leadTimeNum);
    basicInfo.leadTimeUnit = leadTimeUnit.length
      ? window.App.services.ui.getNameOptionWithCode(
          LeadTimeUnitCode,
          leadTimeUnit[0].value ?? ''
        )
      : undefined;
    basicInfo.estimateExpireDt = estimateExpireDt;
    basicInfo.estimateRemarks = estimateRemarks;
    props.onDecision(basicInfo);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const formReset = () => {
    setDeliveryTimeDt(null);
    setDeliveryTimeRemarks('');
    setLeadTimeNum(null);
    setLeadTimeUnit([]);
    setEstimateExpireDt(null);
    setEstimateRemarks('');
  };

  // フォームに初期値をセットする
  useEffect(() => {
    if (!isOpen) {
      formReset();
      return;
    }
    const {
      deliveryTimeDt,
      deliveryTimeRemarks,
      leadTimeNum,
      leadTimeUnit,
      estimateExpireDt,
      estimateRemarks,
    } = props.inputOption;
    setDeliveryTimeDt(deliveryTimeDt ?? null);
    setDeliveryTimeRemarks(deliveryTimeRemarks ?? '');
    setLeadTimeNum(leadTimeNum ? convertStringToAmount(leadTimeNum) : null);
    const fbLeadTimeUnit = convertNameOptionToFilterboxItem(leadTimeUnit);
    setLeadTimeUnit(fbLeadTimeUnit ? [fbLeadTimeUnit] : []);
    setEstimateExpireDt(estimateExpireDt ?? null);
    setEstimateRemarks(estimateRemarks ?? '');

    // isOpen 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const elements = (
    <div className="EstimateBasicInfoRegistrationDialog ">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <DateSuggest
                labelId="estimateBasicInfoRegistration.deliveryTimeDt"
                name="deliveryTimeDt"
                validator={validateFreeDate(intl)}
                onChangeState={setDeliveryTimeDt}
                value={deliveryTimeDt}
                columns={['deliveryTimeDt']}
              />
            </div>
            <div className="w-50">
              <Textbox
                name="deliveryTimeRemarks"
                labelId="estimateBasicInfoRegistration.deliveryTimeRemarks"
                columns={['deliveryTimeRemarks']}
                type="text"
                value={deliveryTimeRemarks}
                onChangeState={setDeliveryTimeRemarks}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-60">
              <div className="input-line">
                <div className="item-group-100 no-space">
                  <div className="w-50">
                    <Amountbox
                      name="leadTimeNum"
                      className="leadTimeNum"
                      value={leadTimeNum}
                      labelId="estimateBasicInfoRegistration.leadTimeNum"
                      columns={['leadTimeNum']}
                      onChangeState={setLeadTimeNum}
                    />
                  </div>
                  <div className="w-50 mt-08">
                    <DataFilterbox
                      data={leadTimeUnitCodes}
                      searchOption={{ targets: 'displayName' }}
                      name="leadTimeUnit"
                      columns={['leadTimeUnit']}
                      onChangeState={setLeadTimeUnit}
                      // className="mt-08"
                      value={leadTimeUnit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <DateSuggest
                labelId="estimateBasicInfoRegistration.estimateExpireDt"
                name="estimateExpireDt"
                validator={validateFreeDate(intl)}
                onChangeState={setEstimateExpireDt}
                value={estimateExpireDt}
                columns={['estimateExpireDt']}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="estimateRemarks"
                labelId="estimateBasicInfoRegistration.estimateRemarks"
                value={estimateRemarks}
                columns={['estimateRemarks']}
                className="w-100 mh-middle"
                onChangeState={setEstimateRemarks}
              />
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: 'cancel',
            })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: 'save',
            })}
            onClick={handleDecision}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id: 'estimateBasicInfoRegistration',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
