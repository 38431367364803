import { useEffect, useRef, useState } from 'react';
import { Container, error } from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Textbox,
  Textarea,
  Tel,
  ZipCode,
  SimplePageNavigation,
  MenuDataView,
} from '~/shared/components/ui';
import { GetMessage } from '~/shared/components/parts/Message/Message';
import { PresetItem } from '~/shared/services';
import './CompanyConfirmation.css';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useIntl } from 'react-intl';
import {
  DataViewInfo,
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getPresetAndSchema,
  getPresetFilterdData,
  getSortSettingFromLocalStorage,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

const VIEW_ID: ViewId = 'PUBLIC_COMPANY_CONFIRMATION';
const officePrefixId = 'ListOfficeView';
const postOfficePrefixId = 'ListPostOfficeView';

// officeDataView用ヘッダー情報
const OFFICE_HEADER = [
  {
    message: {
      id: 'office',
      prefixId: officePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'officeProperties',
      prefixId: officePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'officePropertiesKana',
      prefixId: officePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'phoneNumber',
      prefixId: officePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'address',
      prefixId: officePrefixId,
      viewId: VIEW_ID,
    },
  },
];

// postOfficeDataView用ヘッダー情報
const POST_OFFICE_HEADER = [
  {
    message: {
      id: 'postUnit',
      prefixId: postOfficePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'phoneNumber',
      prefixId: postOfficePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'address',
      prefixId: postOfficePrefixId,
      viewId: VIEW_ID,
    },
  },
  {
    message: {
      id: 'placeProperties',
      prefixId: postOfficePrefixId,
      viewId: VIEW_ID,
    },
  },
];

export function CompanyConfirmation() {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navigate = useNavigate();

  const [item, setItem] =
    useState<mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit>();

  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  const _location = useLocation();
  const location = _location.state as PageState;
  const handleError = useErrorHandler();

  const [presetPropertyNames, setPresetPropertyNames] = useState<Array<string>>(
    []
  );
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    confirmation: true,
    area1: true,
    area2: true,
  });

  // DataView用のpreset
  const [childrenPostOfficePresetItem, setChildrenPostOfficePresetItem] =
    useState<PresetItem>({ name: '' });
  const [childrenOfficePresetItem, setChildrenOfficePresetItem] =
    useState<PresetItem>({ name: '' });

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator({
    fullMethodName: 'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      'company.companyId': { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('PUBLIC_COMPANY_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: 'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
      pageNumber: n,
    });
  };

  const handleAccordion = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // 初回画面用データ
  useEffect(() => {
    (async () => {
      try {
        // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
        // 公開管理場所一覧を取得
        // APIから事業所・取引窓口を取り出す

        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, preset, presetItem } =
          await getPresetAndSchema(VIEW_ID, [
            'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
          ]);
        setPreset(presetItem);

        // DataView用のpreset
        const listOfficeView = childrenPresetItem.find(
          (v: PresetItem) => v.name == 'ListOfficeView'
        );
        const listPostOfficeView = childrenPresetItem.find(
          (v: PresetItem) => v.name == 'ListPostOfficeView'
        );
        listOfficeView && setChildrenOfficePresetItem(listOfficeView);
        listPostOfficeView &&
          setChildrenPostOfficePresetItem(listPostOfficeView);

        // 会社情報用のpreset
        setPresetPropertyNames(preset.propertyNames);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();

    // 会社情報用pagenate
    dispatch({
      type: 'query',
      fullMethodName: 'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
      pageNumber: 1,
    });

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // office情報
  const [officeDataViewInfo, setOfficeDataViewInfo] = useState<
    DataViewInfo<mtechnavi.api.company.IComponentUnit>
  >({
    header: [],
    data: [],
  });
  // postOffice情報
  const [postOfficeDataViewInfo, setPostOfficeDataViewInfo] = useState<
    DataViewInfo<mtechnavi.api.company.IComponentUnit>
  >({
    header: [],
    data: [],
  });

  // 事業情報・窓口情報
  const handleComponentUnit = async (
    targetCompanyId: string,
    childrenOfficePresetItem: PresetItem,
    childrenPostOfficePresetItem: PresetItem
  ) => {
    // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
    // 公開管理場所から表示されている企業の事業所・取引窓口を取り出す
    if (!targetCompanyId) {
      return;
    }

    // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
    // office用データ作成
    const officeData = [] as mtechnavi.api.company.IComponentUnit[];

    // officeのpresetを適用したデータをセット
    setOfficeDataViewInfo(
      getPresetFilterdData(childrenOfficePresetItem, OFFICE_HEADER, officeData)
    );

    // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
    // postOffice用データ作成
    const postOfficeData = [] as mtechnavi.api.company.IComponentUnit[];

    // postOfficeのpresetを適用したデータをセット
    setPostOfficeDataViewInfo(
      getPresetFilterdData(
        childrenPostOfficePresetItem,
        POST_OFFICE_HEADER,
        postOfficeData
      )
    );
  };
  useEffect(() => {
    // 企業情報を取得し、セット
    const items =
      page.originalItems as unknown as mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit[];
    setItem(items[0]);
    let targetCompanyId = '';
    if (items.length > 0) {
      targetCompanyId = items[0].company?.companyId ?? '';
    }
    handleComponentUnit(
      targetCompanyId,
      childrenOfficePresetItem,
      childrenPostOfficePresetItem
    );
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [
    childrenOfficePresetItem,
    childrenPostOfficePresetItem,
    page.maxPageNumber,
    page.originalItems,
    page.pageNumber,
  ]);

  // 固定フッター
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <Container viewId={VIEW_ID}>
      <div className="CompanyConfirmation">
        <div className="header"></div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-33">
                {/* 企業ステータス */}
                <Textbox
                  name="statusResult"
                  className="field"
                  disabled={true}
                  type="text"
                  value={
                    item?.company?.companyStatus?.status?.displayNameLang?.ja ??
                    ''
                  }
                  labelId="statusResult"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          <div className="company-info">
            <div
              className={`input-blocktitle-outer ${
                accordionState.confirmation ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('confirmation')}
              >
                {GetMessage({ id: 'publicInformation' })}
              </h3>
            </div>
            {accordionState.confirmation && (
              <div className="company-body">
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-66">
                      {/* 企業名 */}
                      <Textbox
                        name="companyName"
                        className="field"
                        disabled={true}
                        value={item?.company?.displayNameLang!['ja'] ?? ''}
                        type="text"
                        labelId="companyName"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 法人コード */}
                      <Textbox
                        name="corporationCode"
                        disabled={true}
                        value={
                          item?.company?.companyLegalProperties?.companyNumber
                        }
                        type="text"
                        labelId="corporationCode"
                        columns={presetPropertyNames}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-66">
                      {/* 企業名（かな） */}
                      <Textbox
                        name="companyNameKana"
                        disabled={true}
                        value={item?.company?.displayNameLang!['ja-kana'] ?? ''}
                        type="text"
                        labelId="companyNameKana"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 適格請求書認定番号 */}
                      <Textbox
                        name="eligibleInvoiceNumber"
                        disabled={true}
                        value={
                          item?.company?.companyLegalProperties
                            ?.eligibleInvoiceAuthorizationNumber
                        }
                        type="text"
                        labelId="eligibleInvoiceNumber"
                        columns={presetPropertyNames}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      {/* 代表電話番号 */}
                      <Tel
                        name="representativePhoneNumber"
                        disabled={true}
                        value={item?.company?.profile?.phoneNumber ?? ''}
                        labelId="representativePhoneNumber"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 代表者名 */}
                      <Textbox
                        name="representativeDisplayNameLang"
                        disabled={true}
                        value={
                          item?.company?.profile?.representative
                            ?.displayNameLang?.ja
                        }
                        type="text"
                        labelId="representative"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 会社URL */}
                      <Textbox
                        name="websiteUrl"
                        disabled={true}
                        value={item?.company?.profile?.websiteUrl}
                        type="text"
                        labelId="websiteUrl"
                        columns={presetPropertyNames}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      {/* 郵便番号 */}
                      <ZipCode
                        name="postCode"
                        disabled={true}
                        value={
                          item?.company?.profile?.address?.postalCode ?? ''
                        }
                        labelId="postCode"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 代表者名（かな） */}
                      <Textbox
                        name="representativeKana"
                        disabled={true}
                        value={
                          item?.company?.profile?.representative
                            ?.displayNameLang!['ja-kana']
                        }
                        type="text"
                        labelId="representativeKana"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 資本金（百万） */}
                      <Amountbox
                        name="capital"
                        disabled={true}
                        value={item?.company?.profile?.capitalStock}
                        labelId="capital"
                        columns={presetPropertyNames}
                        displayOption={{ digits: 4, isCommaFormat: true }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      {/* 住所 */}
                      <Textbox
                        name="address"
                        disabled={true}
                        value={
                          item?.company?.profile?.address?.addressLine ?? ''
                        }
                        type="text"
                        labelId="address"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 代表者役職 */}
                      <Textbox
                        name="representativePosition"
                        disabled={true}
                        value={item?.company?.profile?.representative?.position}
                        type="text"
                        labelId="representativePosition"
                        columns={presetPropertyNames}
                      />
                    </div>
                    <div className="w-33">
                      {/* 業種名称 */}
                      <Textbox
                        name="industryName"
                        disabled={true}
                        value={item?.company?.profile?.industry}
                        type="text"
                        labelId="industryName"
                        columns={presetPropertyNames}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-100">
                      {/* 主な事業内容 */}
                      <Textarea
                        name="mainBusiness"
                        className="w-100 mh-middle"
                        disabled={true}
                        value={item?.company?.profile!['mainBusiness'] ?? ''}
                        labelId="mainBusiness"
                        columns={presetPropertyNames}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-100">
                      {/* 公開コメント */}
                      <Textarea
                        name="publicComment"
                        className="w-100 mh-middle"
                        disabled={true}
                        value={item?.company?.profile!['publicComment'] ?? ''}
                        labelId="publicComment"
                        columns={presetPropertyNames}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`input-blocktitle-outer ${
              accordionState.area1 ? '' : 'close'
            }`}
          >
            <h3
              className="input-blocktitle"
              onClick={() => handleAccordion('area1')}
            >
              {GetMessage({ id: 'officesList' })}
            </h3>
          </div>
          {accordionState.area1 && (
            <MenuDataView
              pageSizeOption={{ pageSize: 10, isDisplay: true }}
              title={GetMessage({
                prefixId: 'listOfficeView',
                viewId: VIEW_ID,
              })}
              header={officeDataViewInfo.header}
              data={officeDataViewInfo.data}
            ></MenuDataView>
          )}
          <div
            className={`input-blocktitle-outer ${
              accordionState.area2 ? '' : 'close'
            }`}
          >
            <h3
              className="input-blocktitle"
              onClick={() => handleAccordion('area2')}
            >
              {GetMessage({ id: 'transactionWindowInformationList' })}
            </h3>
          </div>
          {accordionState.area2 && (
            <MenuDataView
              pageSizeOption={{ pageSize: 10, isDisplay: true }}
              title={GetMessage({
                prefixId: 'listPostOfficeView',
                viewId: VIEW_ID,
              })}
              header={postOfficeDataViewInfo.header}
              data={postOfficeDataViewInfo.data}
            ></MenuDataView>
          )}
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="blockbtn">
              <CaptionButton
                name="backBtn"
                caption="戻る"
                className="btn limit"
                properties={[
                  {
                    name: 'backBtn',
                    propertyName: 'backBtn',
                    propertyValue: 'backBtn',
                  },
                ]}
                onClick={() => navigate('/businessunit-public-companies-list')}
                buttonType="basic"
              />
            </div>
            <SimplePageNavigation
              pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
              handleMovePage={handleMovePage}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
