import { useState, CSSProperties, useEffect } from 'react';
import './Textarea.css';
import { checkEmptyRequiredValue, countInputWord } from '../validator';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage } from '../Message/Message';
import { getCounterView } from '~/shared/components';
import { getErrorBorderClassName } from '~/shared/utils';
import { useIntl } from 'react-intl';

export interface TextareaProps {
  name: string;
  className?: string;
  value?: string;
  isCounter?: boolean;
  validator?: (v: string) => string[];
  validateOption?: TextareaValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  isParentDeliver?: boolean; // 入力イベントをリアルタイムで親に変更を伝えるかどうかを指定
  onChangeState?: (arg: string) => void;
}

export interface TextareaValidateOption {
  maxLength?: number;
  minLength?: number;
  isSkippedValidation?: boolean; // このコンポーネントで設定されているバリデーションを行うかどうか
  required?: boolean;
  isSkippedRequireCheck?: boolean;
}

export function Textarea(props: TextareaProps) {
  const intl = useIntl();
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  // textarea要素のクラス名
  const textareaClassName = props.className ?? '';

  // オプショナルチェック
  const validate =
    props.validator ||
    (() => {
      return [];
    });
  const maxLength = props.validateOption?.maxLength ?? Number.MAX_SAFE_INTEGER;
  const minLength = props.validateOption?.minLength ?? 0;
  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;
  const isSkippedRequireCheck =
    props.validateOption?.isSkippedRequireCheck ?? false;
  const changeParentState = props.onChangeState ?? (() => {});
  const style = props.style ?? {};

  // 引数チェック
  if (minLength > maxLength) {
    throw new Error(
      'assertion error: minLengthはmaxLengthより小さい値を設定してください'
    );
  }

  // useState
  const [message, setMessage] = useState<string[]>([]);
  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  // フォーカスアウト時にバリデーションを実行
  const handleValidate = (inputValue: string) => {
    // 親要素へ値を連携
    changeParentState(inputValue);

    // 固有バリデーションを行うかどうか
    if (isSkippedValidation) {
      // 固有バリデーションはスキップして、親要素でのバリデーションを行う
      return;
    }

    const errorMessage: string[] = [];

    // 必須入力チェック
    const isEmpty = checkEmptyRequiredValue(inputValue);
    if (!isSkippedRequireCheck && required && isEmpty) {
      setMessage([REQUIRED_MESSAGE]);
      return;
    } else if (!required && isEmpty) {
      // 必須入力でなく、入力値が空の場合は以降の処理をスキップ
      setMessage([]);
      return;
    }

    // 文字数チェック
    errorMessage.push(
      ...countInputWord(intl, inputValue, minLength, maxLength)
    );
    // バリデーション
    errorMessage.push(...validate(inputValue));

    setMessage([...errorMessage]);
  };

  useEffect(() => {
    // 必須制御が変わって任意になる場合にエラー表示をクリアする
    if (!required) {
      // 値が空の場合だけに対応範囲を限定する
      if (!value) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでrequiredのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  useEffect(() => {
    // 必須チェック不要になる場合にエラー表示をクリアする
    if (isSkippedRequireCheck) {
      // 値が空の場合だけに対応範囲を限定する
      if (!value) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkippedRequireCheck]);

  return (
    <div className="Textarea">
      <textarea
        name={props.name}
        className={`${textareaClassName} basic-input-border ${getErrorBorderClassName(
          message
        )}`}
        onBlur={(event) => {
          handleValidate(event.target.value);
        }}
        value={value}
        onChange={(event) => {
          if (props.isParentDeliver) {
            changeParentState(event.target.value);
          }
          setValue(event.target.value);
        }}
        required={required}
        disabled={props.disabled}
        style={style}
        autoComplete="off"
      />
      {props.isCounter && getCounterView(maxLength, value)}
      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
