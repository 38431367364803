import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '../..';
import { CaptionButton } from '../Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DataFilterbox,
  DataFilterboxItem,
  Filterbox,
  FilterboxItem,
} from '../Filterbox';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_SharedListBusinessUnitBranchs,
  FullMethodName_ListBusinessUnitFacilitiess,
  FullMethodName_SharedListBusinessUnitFacilitiess,
  getExceptionMessage,
  getProgramOptionFilterboxData,
  getProgramOptionFilterboxDataWithSystemName,
  includeInputValidateError,
} from '~/shared/utils';
import { Textarea } from '..';

export interface BusinessUnitStrengthFormOption {
  companyId?: string;
  isShared?: boolean;
  category?: sharelib.INameOption;
  content?: string;
  linkProperties?: mtechnavi.api.company.BusinessUnitStrength.ILinkProperties;
}

export interface businessUnitStrengthDialogProps {
  isOpen: boolean;
  inputMode: boolean;
  inputOption?: BusinessUnitStrengthFormOption;
  onCancel: () => void;
  onDecision: (result: BusinessUnitStrengthFormOption) => void;
}

export const BusinessUnitStrengthDialog = (
  props: businessUnitStrengthDialogProps
) => {
  const { inputOption } = props;
  const intl = useIntl();

  const branchItemType = {
    value: 'businessUnitBranchId',
    displayName: 'displayName',
  };

  const facilityItemType = {
    value: 'businessUnitFacilitiesId',
    displayName: 'modelNumber',
  };

  const [category, setCategory] = useState<DataFilterboxItem[]>([]);
  const [categoryDescription, setCategoryDescription] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [facility, setFacility] = useState<FilterboxItem[]>([]);
  const [branch, setBranch] = useState<FilterboxItem[]>([]);
  const [isDisabled, setDisabled] = useState(false);

  const categories = useMemo(() => {
    const categories = getProgramOptionFilterboxData('A4000001');
    return categories;
  }, []);

  // 入力チェック用
  const [workingBlurCategory, setWorkingBlurCategory] = useState<Date>();
  const requiredCategoryArea = useRef(null);

  // 項目セット
  const [isSuspendCategoryChange, setSuspendCategoryChange] = useState(false);

  const getClassNames = (): string => {
    let className = 'button-area';
    if (!props.inputMode) {
      return className;
    }
    return (className = 'button-area top-space-10rem');
  };

  useEffect(() => {
    setDisabled(!props.inputMode);
    // 入力モードではない場合、渡されたデータをそのまま表示する
    // Shareされた側ではcodeがshareされないことも考慮
    if (!props.inputMode ?? false) {
      setSuspendCategoryChange(true);
      setCategory([
        {
          value: inputOption?.category?.code ?? '', //空になる場合があるが、表示のみのため許容
          displayName: inputOption?.category?.displayNameLang?.ja ?? '',
        },
      ]);
    } else {
      setCategory(
        categories.filter((v) => v.value === inputOption?.category?.code ?? '')
      );
    }
    const description = getProgramOptionFilterboxDataWithSystemName(
      'A4000008',
      inputOption?.category?.systemName ?? ''
    );
    setCategoryDescription(
      description.length ? description[0].displayName : ''
    );

    setContent(inputOption?.content ?? '');
    (async () => {
      try {
        const businessUnitbranchId =
          inputOption?.linkProperties?.businessUnitBranchId ?? '';
        const businessUnitBranchs = (await window.App.services.ui.worker.filter(
          {
            action: 'query',
            fullMethodName: inputOption?.isShared
              ? FullMethodName_SharedListBusinessUnitBranchs
              : FullMethodName_ListBusinessUnitBranchs,
            filter: {
              businessUnitBranchId: { $eq: businessUnitbranchId },
            },
            sort: [],
          }
        )) as mtechnavi.api.company.ListBusinessUnitBranchsResponse;
        const businessUnitBranchRes = businessUnitBranchs.items[0];
        if (businessUnitBranchRes) {
          setBranch([
            {
              value: businessUnitBranchRes.businessUnitBranchId ?? '',
              displayName: businessUnitBranchRes.displayName ?? '',
            },
          ]);
        } else {
          setBranch([]);
        }
        const businessUnitFacilityId =
          inputOption?.linkProperties?.businessUnitFacilitiesId ?? '';
        const businessUnitFacilities =
          (await window.App.services.ui.worker.filter({
            action: 'query',
            fullMethodName: inputOption?.isShared
              ? FullMethodName_SharedListBusinessUnitFacilitiess
              : FullMethodName_ListBusinessUnitFacilitiess,
            filter: {
              businessUnitFacilitiesId: { $eq: businessUnitFacilityId },
            },
            sort: [],
          })) as mtechnavi.api.company.ListBusinessUnitFacilitiessResponse;
        const businessUnitFacilityRes = businessUnitFacilities.items[0];
        if (businessUnitFacilityRes) {
          setFacility([
            {
              value: businessUnitFacilityRes.businessUnitFacilitiesId ?? '',
              displayName: businessUnitFacilityRes.modelNumber ?? '',
            },
          ]);
        } else {
          setFacility([]);
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
      }
    })();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption]);

  useEffect(() => {
    if (isSuspendCategoryChange) {
      setSuspendCategoryChange(false);
      return;
    }
    if (!category.length) {
      setCategoryDescription('');
      return;
    }

    const categorySystemName =
      window.App.services.ui.getNameOptionWithCode(
        'A4000001',
        category[0].value
      ).systemName ?? '';
    const description = getProgramOptionFilterboxDataWithSystemName(
      'A4000008',
      categorySystemName
    );
    setCategoryDescription(
      description.length ? description[0].displayName : ''
    );

    // category 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const handleCancel = () => {
    props.onCancel();
    formReset();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const resultCategory = window.App.services.ui.getNameOptionWithCode(
      'A4000001',
      category[0].value ?? ''
    );
    const result: BusinessUnitStrengthFormOption = {
      companyId: inputOption?.companyId ?? '',
      category: resultCategory,
      content,
      linkProperties: {
        businessUnitBranchId: branch.length > 0 ? branch[0].value : '',
        businessUnitFacilitiesId: facility.length > 0 ? facility[0].value : '',
      },
    };
    props.onDecision(result);
    formReset();
  };

  const isInputError = () => {
    const targetElm = document.querySelector('.business-unit-strength-dialog');

    setWorkingBlurCategory(new Date());
    const categoryValue = category.length > 0 ? category[0].value : '';

    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: categoryValue,
          ref: requiredCategoryArea,
        },
      ])
    ) {
      return true;
    }

    return false;
  };

  const formReset = () => {
    setCategory([]);
    setCategoryDescription('');
    setContent('');
    setBranch([]);
    setFacility([]);
    setWorkingBlurCategory(undefined);
  };

  const elements = (
    <div className="business-unit-strength-dialog">
      <div className="detail-area">
        <div className="contents-box">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-40">
                {/* 強み分類 */}
                <DataFilterbox
                  data={categories}
                  labelId="BusinessUnitStrengthDialog.category"
                  searchOption={{ targets: 'displayName' }}
                  name="category"
                  columns={['category']}
                  onChangeState={setCategory}
                  validateOption={{ required: true }}
                  value={category}
                  workingBlur={workingBlurCategory}
                  disabled={isDisabled}
                ></DataFilterbox>
              </div>
              <div className="w-66">
                {/* 強み分類説明 */}
                <p className="word-description-label">{categoryDescription}</p>
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* コンテンツ */}
                <Textarea
                  name="content"
                  className="w-100 mh-middle"
                  labelId="BusinessUnitStrengthDialog.content"
                  value={content}
                  onChangeState={setContent}
                  columns={['content']}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-66">
                {/* 関連拠点 */}
                <Filterbox
                  name={'branch'}
                  fullMethodName={
                    inputOption?.isShared
                      ? FullMethodName_SharedListBusinessUnitBranchs
                      : FullMethodName_ListBusinessUnitBranchs
                  }
                  labelId="BusinessUnitStrengthDialog.branch"
                  itemType={branchItemType}
                  columns={['branch']}
                  value={branch}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: {
                      companyId: { $eq: inputOption?.companyId ?? '' },
                    },
                  }}
                  onChangeState={setBranch}
                  disabled={isDisabled}
                ></Filterbox>
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-66">
                {/* 関連設備 */}
                <Filterbox
                  name={'facility'}
                  labelId="BusinessUnitStrengthDialog.facility"
                  fullMethodName={
                    inputOption?.isShared
                      ? FullMethodName_SharedListBusinessUnitFacilitiess
                      : FullMethodName_ListBusinessUnitFacilitiess
                  }
                  itemType={facilityItemType}
                  columns={['facility']}
                  value={facility}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: {
                      companyId: { $eq: inputOption?.companyId ?? '' },
                    },
                  }}
                  onChangeState={setFacility}
                  disabled={isDisabled}
                ></Filterbox>
              </div>
            </div>
          </div>
        </div>
        <div className={getClassNames()}>
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
          {props.inputMode && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'decision',
              })}
              onClick={handleDecision}
            />
          )}
        </div>
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: props.inputMode
        ? 'BUSINESS_UNIT_STRENGTH_REGISTRATION'
        : 'BUSINESS_UNIT_STRENGTH_CONFIRMATION',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };
  return <ModalDialogComponent {...openModalProps} />;
};
