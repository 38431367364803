import {
  PropsWithoutRef,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CategorizedBranchRef } from './CategorizedBranch';
import './CategorizedCheckbox.css';

interface CategorizedTipCheckboxKeyActions {
  onLeft?: (id: string) => void;
  onRight?: (id: string) => void;
  onUp?: (id: string) => void;
  onDown?: (id: string) => void;
}
export interface CategorizedTipCheckboxProps {
  parentRef?: React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>;
  skillId: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  searchWord?: string;
  onChange?: (id: string, isChecked: boolean) => void;
  keyActions?: CategorizedTipCheckboxKeyActions;
}
export interface CategorizedTipCheckboxRef {
  focus: () => void;
  open: () => void;
  close: () => void;
  isOpen: () => boolean;
}
export const CategorizedTipCheckbox = forwardRef(
  (props: PropsWithoutRef<CategorizedTipCheckboxProps>, ref) => {
    const inputId = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isChecked, setChecked] = useState<boolean>(!!props.checked);
    const isMatch = useMemo<boolean | null>(() => {
      if (!props.searchWord) {
        return null;
      }
      return props.searchWord
        .split(' ')
        .filter((w) => !!w)
        .some((word) => !!props.label.match(word));
    }, [props.label, props.searchWord]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.disabled) {
        event.preventDefault();
        return;
      }
      setChecked(event.target.checked);
      if (props?.onChange) {
        props?.onChange(event.target.value, event.target.checked);
      }
    };
    const setFocus = () => {
      inputRef.current?.focus();
    };
    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'ArrowUp': {
          props.keyActions?.onUp && props.keyActions.onUp(props.skillId);
          break;
        }
        case 'ArrowDown': {
          props.keyActions?.onDown && props.keyActions.onDown(props.skillId);
          break;
        }
        case 'ArrowLeft': {
          props.keyActions?.onLeft && props.keyActions.onLeft(props.skillId);
          break;
        }
        case 'ArrowRight': {
          props.keyActions?.onRight && props.keyActions.onRight(props.skillId);
          break;
        }
      }
    };

    useEffect(() => setChecked(props.checked), [props.checked]);

    // 外から開閉を操作できるようにする
    useImperativeHandle(
      ref,
      (): CategorizedTipCheckboxRef => ({
        focus: () => {
          // 親カテゴリを展開する
          props.parentRef?.current?.open();
          // DOMが表示されてからフォーカスをセットする
          setTimeout(() => setFocus(), 100);
        },
        open: () => {
          props.parentRef?.current?.open();
        },
        close: () => {
          props.parentRef?.current?.close();
        },
        isOpen: () => props.parentRef?.current?.isOpen() || false,
      })
    );

    return (
      <div
        className={`CategorizedCheckbox CategorizedTipCheckbox ${
          isMatch === null ? '' : isMatch ? 'matched' : 'unmatched'
        } ${props.disabled ? 'disabled' : ''}`}
      >
        <input
          type="checkbox"
          id={inputId}
          value={props.skillId}
          checked={isChecked}
          onKeyDown={handleKeydown}
          onChange={(event) => handleChange(event)}
          ref={inputRef}
        />
        <label htmlFor={inputId}>{props.label}</label>
      </div>
    );
  }
);
