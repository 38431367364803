export class ImageRenderer {
  private el?: CanvasImageSource;

  constructor(public src: string) {}

  async load(): Promise<void> {
    return new Promise<void>((resolve) => {
      const el = new Image();
      el.src = this.src;
      el.addEventListener(
        'load',
        () => {
          this.el = el;
          resolve();
        },
        {
          once: true,
        }
      );
    });
  }

  maxPageNumber(): number {
    return 1;
  }

  render(
    ctx: CanvasRenderingContext2D,
    pageNumber: number // eslint-disable-line
  ): [Promise<void>, () => void] {
    const state: {
      timeoutId?: number;
    } = {};
    const cancel = () => {
      window.clearTimeout(state.timeoutId);
    };
    const promise = new Promise<void>((resolve) => {
      const fn = async () => {
        if (!this.el) {
          return (state.timeoutId = window.setTimeout(fn, 0));
        }
        // キャンバスサイズを画像に合わせる
        const ratio = window.devicePixelRatio || 1;
        ctx.canvas.width = Math.floor((this.el.width as number) * ratio);
        ctx.canvas.height = Math.floor((this.el.height as number) * ratio);
        ctx.scale(ratio, ratio);

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(this.el, 0, 0);
        resolve();
      };
      state.timeoutId = window.setTimeout(fn, 0);
    });
    return [promise, cancel];
  }
}
