import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { FilterRequest } from '~/worker';
import { SectionStateProps } from '../ui/TaskListView';

import './base.css';
import { useIntl } from 'react-intl';
import {
  PageState,
  movePage,
  FullMethodName_UncompletedListSurveyRequest,
  FullMethodName_UnchangedListSurveyReplyDueDateChangeRequest,
} from '~/shared/utils';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';

const SURVEY_REQUEST_SENDER_CONFIRMATION_URL =
  '/supplier-base/survey-request-sender-confirmation';
const SURVEY_REQUEST_RECEIVER_CONFIRMATION_URL =
  '/supplier-base/survey-request-receiver-confirmation';

// 未完了の一覧を取得する
async function uncompletedListSurveyRequestResponse(): Promise<mtechnavi.api.survey.UncompletedListSurveyRequestResponse> {
  const option: FilterRequest = {
    action: 'reload',
    fullMethodName: FullMethodName_UncompletedListSurveyRequest,
    filter: {},
    sort: [],
  };
  option.requestBody = {};

  try {
    return (await window.App.services.ui.worker.filter(
      option
    )) as mtechnavi.api.survey.UncompletedListSurveyRequestResponse;
  } catch (err) {
    console.error('Error uncompletedListSurveyRequestResponse', err);
    return (async () =>
      new mtechnavi.api.survey.UncompletedListSurveyRequestResponse())();
  }
}

// 期日変更依頼の一覧を取得する
async function unchangedListSurveyReplyDueDateChangeRequest(): Promise<mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse> {
  const option: FilterRequest = {
    action: 'reload',
    fullMethodName: FullMethodName_UnchangedListSurveyReplyDueDateChangeRequest,
    filter: {},
    sort: [],
  };
  option.requestBody = {};

  try {
    return (await window.App.services.ui.worker.filter(
      option
    )) as mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse;
  } catch (err) {
    console.error('Error unchangedListSurveyReplyDueDateChangeRequest', err);
    return (async () =>
      new mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse())();
  }
}

export interface TaskSurveyRequestProps extends SectionStateProps {}

export const TaskSurveyRequest = (props: TaskSurveyRequestProps) => {
  const intl = useIntl();
  const { onChangeLoadingState } = props;

  const [
    targetUncompletedSurveyReceptionIds,
    setTargetUncompletedSurveyReceptionIds,
  ] = useState<string[]>([]);
  const [targetUnchangedSurveyRequestIds, setTargetUnchangedSurveyRequestIds] =
    useState<string[]>([]);

  const navi = useNavigate();
  // 遷移処理
  const handleMovePage = (e: React.MouseEvent, ids: string[], url: string) => {
    e.preventDefault();

    if (!ids.length) return;
    const state: PageState = {
      ids: ids,
    };
    movePage(state, navi, url);
  };

  // 未完了
  const callUncompletedList = async () => {
    const { items } = await uncompletedListSurveyRequestResponse();
    if (!items.length) {
      setTargetUncompletedSurveyReceptionIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) =>
      resourceIds.push(v.surveyReception?.surveyReceptionId ?? '')
    );

    setTargetUncompletedSurveyReceptionIds(resourceIds);
  };

  // 回答期日変更依頼
  const callChangeRequestList = async () => {
    const { items } = await unchangedListSurveyReplyDueDateChangeRequest();
    if (!items.length) {
      setTargetUnchangedSurveyRequestIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) => resourceIds.push(v.surveyRequest?.surveyRequestId ?? ''));

    setTargetUnchangedSurveyRequestIds(resourceIds);
  };

  useEffect(() => {
    onChangeLoadingState && onChangeLoadingState(true);
    Promise.all([
      callUncompletedList(), // 未完了
      callChangeRequestList(), // 回答期日変更依頼
    ]).finally(() => {
      onChangeLoadingState && onChangeLoadingState(false);
    });
    // 正確にローディング状況を伝えることが困難なため、依存関係に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(targetUncompletedSurveyReceptionIds.length > 0 ||
        targetUnchangedSurveyRequestIds.length > 0) && (
        <div className="TaskList">
          <dl>
            <dt className="title">
              {GetMessageWithIntl(intl, { id: 'TaskList.title.surveyRequest' })}
            </dt>
            {targetUncompletedSurveyReceptionIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetUncompletedSurveyReceptionIds,
                      SURVEY_REQUEST_RECEIVER_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.uncompletedSurveyReception',
                  })}
                  {`（${targetUncompletedSurveyReceptionIds.length}）`}
                </a>
              </dd>
            )}
            {targetUnchangedSurveyRequestIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetUnchangedSurveyRequestIds,
                      SURVEY_REQUEST_SENDER_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unchangedSurveyRequest',
                  })}{' '}
                  {`（${targetUnchangedSurveyRequestIds.length}）`}
                </a>
              </dd>
            )}
          </dl>
        </div>
      )}
    </>
  );
};
