import { useEffect, useState } from 'react';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';

import { Textbox } from '../components/ui/Textbox';
import { GetMessage } from '~/shared/components';
import { validatePassword } from '~/shared/utils';
import { CaptionButton } from '../components/ui/Button/CaptionButton';

export function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordContentState, setPasswordContentState] =
    useState('resetPaaword');
  const [actionCode, setActionCode] = useState<string>('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const from: string = (location.state as any)?.from?.pathname || '/';
  const errorInvalieMessage = GetMessage({ id: 'E0000092' });
  const errorAuthMessage = GetMessage({ id: 'E0000147' });
  const policy1 = GetMessage({ id: 'POLICY_1' });
  const policy2 = GetMessage({ id: 'POLICY_2' });
  const policy3 = GetMessage({ id: 'POLICY_3' });
  const policy4 = GetMessage({ id: 'POLICY_4' });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const oobCode = queryParams.get('oobCode') || '';
    const mode = queryParams.get('mode') || '';

    if (mode !== 'resetPassword' || !oobCode) {
      setErrorMessage('');
      setPasswordContentState('errorResetPasswordMessage');
      return;
    }
    setActionCode(oobCode);
  }, []);

  useEffect(() => {
    if (!!auth.authorized) {
      navigate(from, { replace: true });
    }
  }, [auth, navigate, from]);

  const handleNewPassword = () => {
    if (newPassword === '' || confirmPassword === '') {
      return setErrorMessage(
        'パスワードもしくは確認用パスワードを入力してください。'
      );
    }
    if (newPassword !== confirmPassword) {
      // 仮でテキストを入力しています。
      return setErrorMessage('入力したパスワードが一致しません。');
    }
    if (!validatePassword(newPassword)) {
      return setErrorMessage(errorInvalieMessage);
    }
    (async () => {
      try {
        await auth.newPassword(actionCode, newPassword);
        setPasswordContentState('resetPasswordMessage');
        setErrorMessage('');
      } catch (err) {
        console.error(err);
        setErrorMessage(errorAuthMessage);
      }
    })();
  };

  const loginSectionMove = () => {
    window.location.href =
      'https://' + window.App.config.tenantDomain + '/sign-in';
  };

  return (
    <div className="Login">
      <section className="login-sction">
        <div className="visual-area">
          <p className="fonts">
            <span className="logo">
              <img src="/assets/logo/arrow.svg" alt="arrow" />
            </span>
            <br />
            <span className="font">M-Tech</span>
            <br />
            <span className="font">Navi</span>
          </p>
        </div>
        <div className="input-area">
          {passwordContentState !== 'login' && (
            <h3 className="title">パスワードのリセット</h3>
          )}
          {passwordContentState === 'errorResetPasswordMessage' && (
            <div>
              <div className="input-field message">
                <p>URLがまちがえています。</p>
                <p>ログイン画面より、再度パスワードリセットを行ってください</p>
              </div>
              <div className="input-field remove">
                <a onClick={() => loginSectionMove()}>ログインに戻る</a>
              </div>
            </div>
          )}
          {passwordContentState === 'resetPaaword' && (
            <div>
              <div className="input-field password">
                <Textbox
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  labelId="newPassword"
                  onChangeState={setNewPassword}
                  columns={['newPassword']}
                  properties={[
                    {
                      name: 'newPassword',
                      propertyName: '',
                      propertyValue: true,
                    },
                  ]}
                />
              </div>
              <div className="input-field password">
                <Textbox
                  type="password"
                  name="confirmationPassword"
                  value={confirmPassword}
                  labelId="confirmationPassword"
                  onChangeState={setConfirmPassword}
                  columns={['confirmationPassword']}
                  properties={[
                    {
                      name: 'confirmationPassword',
                      propertyName: '',
                      propertyValue: true,
                    },
                  ]}
                />
              </div>
              <div className="policy-area">
                <p>{policy1}</p>
                <p>{policy2}</p>
                <p>{policy3}</p>
                <p>{policy4}</p>
              </div>
              <div className="input-field submit">
                <CaptionButton
                  name="changePassBtn"
                  caption="パスワード変更"
                  className="btn limit"
                  disabled={auth.authorized}
                  properties={[
                    {
                      name: 'changePassBtn',
                      propertyName: 'changePassBtn',
                      propertyValue: 'changePassBtn',
                    },
                  ]}
                  onClick={handleNewPassword}
                  buttonType="basic"
                />
                <p className="error">{errorMessage}</p>
              </div>
              {/* ログイン検証終了時削除 */}
              {/* <p
                onClick={() => setPasswordContentState('resetPasswordMessage')}
              >
                →
              </p> */}
            </div>
          )}
          {passwordContentState === 'resetPasswordMessage' && (
            <div>
              <div className="input-field message">
                <p>パスワードを変更しました。</p>
                <p>
                  ログイン画面より、新しいパスワードでログインしてください。
                </p>
              </div>
              <div className="input-field remove">
                <a onClick={() => loginSectionMove()}>ログインに戻る</a>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
