import { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { DataFilterbox, DataFilterboxItem } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { Radio, RadioItem } from '~/shared/components/parts/Radio/Radio';
import './base.css';
import {
  includeInputValidateError,
  convertDisplayUserNameEmail,
} from '~/shared/utils';
import { sharelib, mtechnavi } from '~/shared/libs/clientsdk';
import { FullMethodName_ListUserAttributes } from '~/shared/utils';

export type UserAdditionDialogCategoryType = 'main' | 'assistant';

export interface UserAdditionResult {
  category: UserAdditionDialogCategoryType;
  users: sharelib.IUserReference[];
}

export interface UserAdditionDialogProps {
  isOpen: boolean;
  onDecision: (result: UserAdditionResult) => void;
  onCancel: () => void;
}

export const UserAdditionDialog = ({
  isOpen,
  onDecision,
  onCancel,
}: UserAdditionDialogProps) => {
  const intl = useIntl();
  const [category, setCategory] =
    useState<UserAdditionDialogCategoryType | null>(null);
  const [user, setUser] = useState<DataFilterboxItem[]>([]);

  const [workingBlurCategory, setWorkingBlurCategory] = useState<Date>();
  const [workingBlurUser, setWorkingBlurUser] = useState<Date>();
  const requiredCategoryArea = useRef(null);

  const radioCategoryItemValues: UserAdditionDialogCategoryType[] = [
    'main',
    'assistant',
  ];
  const generateItems = () => {
    const items: RadioItem[] = [];
    radioCategoryItemValues.map((item) => {
      items.push({
        value: item,
        displayName: GetMessageWithIntl(intl, {
          id: `radio_${item}`,
          prefixId: 'UserAdditionDialog',
        }),
      });
    });
    return items;
  };
  const radioCategoryItems: RadioItem[] = generateItems();

  // DataFilterbox用(アカウント)
  // ユーザーマスタ
  const [userList, setUserList] = useState<
    mtechnavi.api.tenantadmin.IUserAttribute[]
  >([]);
  const [userItems, setUserItems] = useState<DataFilterboxItem[]>([]);

  useEffect(() => {
    (async () => {
      // ユーザーマスタ
      const listUserRes = (await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListUserAttributes,
        filter: {},
        sort: [],
      })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

      const itemUser: mtechnavi.api.tenantadmin.IUserAttribute =
        listUserRes?.items.length > 0 ? listUserRes?.items[0] : {};
      if (itemUser?.userAttributeId === '') {
        return;
      }

      const items: DataFilterboxItem[] = listUserRes.items
        ? listUserRes.items!.map(
            (v): DataFilterboxItem => ({
              displayName: convertDisplayUserNameEmail(
                v.user?.displayName,
                v.user?.email
              ),
              value: v.user?.userId ?? '',
            })
          ) ?? []
        : [];

      setUserList(listUserRes.items);
      setUserItems(items);
    })();
  }, []);

  const clearDisplay = () => {
    setCategory(null);
    setUser([]);
    setWorkingBlurCategory(undefined);
    setWorkingBlurUser(undefined);
  };

  const handleCancel = () => {
    onCancel();
    clearDisplay();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }

    const selectedUsers: sharelib.IUserReference[] = [];
    user.map((v) => {
      const selectedUser = (userList ?? []).filter(
        (w) => w.user?.userId === v.value
      );
      if (selectedUser.length > 0) {
        selectedUsers.push({
          userId: selectedUser[0].user?.userId,
          email: selectedUser[0].user?.email,
          displayName: selectedUser[0].user?.displayName,
        });
      }
    });

    const result: UserAdditionResult = {
      category: category!,
      users: selectedUsers.length > 0 ? selectedUsers : [],
    };

    onDecision(result);
    clearDisplay();
  };

  useEffect(() => {
    if (isOpen) {
      clearDisplay();
    }
  }, [isOpen]);

  const isInputError = (): boolean => {
    setWorkingBlurCategory(new Date());
    setWorkingBlurUser(new Date());

    const targetElm = document.querySelector('.user-addition-dialog');
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: category ?? '',
          ref: requiredCategoryArea,
        },
      ]) ||
      user.length === 0
    ) {
      return true;
    }
    return false;
  };

  const elements = (
    <div className="user-addition-dialog dialog-with-description">
      <div className="description-area">
        <div className="input-line" ref={requiredCategoryArea}>
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="category"
                value={category ?? ''}
                items={radioCategoryItems}
                validateOption={{ required: true }}
                onChangeState={(v) => {
                  setCategory(v as UserAdditionDialogCategoryType);
                }}
                workingBlur={workingBlurCategory}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input">
                <DataFilterbox
                  data={userItems}
                  name="user"
                  labelId="UserAdditionDialog.user"
                  columns={['user']}
                  validateOption={{ required: true }}
                  searchOption={{ targets: 'displayName' }}
                  onChangeState={setUser}
                  value={user}
                  multiple={true}
                  workingBlur={workingBlurUser}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'guidance',
                prefixId: 'UserAdditionDialog',
              })}
            </div>
          </div>
        </div>
        <div className="button-area top-space-8rem">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="addBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'add' })}
            onClick={handleDecision}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'userAddition',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="UserAdditionDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
