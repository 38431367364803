import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { Textarea } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { ButtonType } from '~/shared/components/parts/Button/CaptionButton';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
export type CommentDialogMode = 'send' | 'save';

/**
 * ページから辞書IDを渡したい時のインターフェイス
 * ダイアログタイトルや説明文、その他ボタンキャプションなど適宜対応できる様にする
 */
export interface MessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
  captionLabelId?: MessageProps;
  decisionLabelId?: MessageProps;
}

/**
 * 入力項目に関する項目用インターフェイス
 * ダイアログによってページから呼び出す項目が異なるが
 * 引数の増減は極力減らしたいため
 */
export interface InputOption {
  comment: string;
  butonType?: ButtonType;
  modeType?: CommentDialogMode;
}

/**
 * 入力項目のステートを管理する項目用インターフェイス
 * ダイアログによってページから呼び出す項目が異なるが
 * 引数の増減は極力減らしたいため
 */
export interface InputStateOption {
  onChangeComment: (arg: string) => void;
}

export interface CommentDialogProps {
  isOpen: boolean;
  messageOption: MessageOption;
  inputOption: InputOption;
  inputStateOption: InputStateOption;
  onDecision: () => void;
  onCancel: () => void;
}

export function CommentDialog(props: CommentDialogProps) {
  const intl = useIntl();

  const { isOpen, messageOption, inputOption, inputStateOption } = props;
  const [isSendConfirmOpen, setSendConfirmOpen] = useState(false);

  const captionLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.captionLabelId) {
      msgId.push(messageOption.captionLabelId.prefixId ?? '');
      msgId.push(messageOption.captionLabelId.viewId ?? '');
      msgId.push(messageOption.captionLabelId.id ?? '');
    } else {
      msgId.push('comment');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.captionLabelId]);

  const decisionLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.decisionLabelId) {
      msgId.push(messageOption.decisionLabelId.prefixId ?? '');
      msgId.push(messageOption.decisionLabelId.viewId ?? '');
      msgId.push(messageOption.decisionLabelId.id ?? '');
    } else {
      msgId.push('send');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.decisionLabelId]);

  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: decisionLabelId }),
    },
  };

  const handleDecision = () => {
    props.onDecision();
    setSendConfirmOpen(false);
  };

  // ダイアログ関係
  const view = (
    <div className="comment-dialog">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {messageOption.messageLabelId &&
              GetMessageWithIntl(intl, messageOption.messageLabelId)}
          </span>
        </p>
      </div>
      <div className="comment-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="comment"
                labelId={captionLabelId}
                value={inputOption.comment}
                columns={['comment']}
                className="w-100 mh-middle"
                properties={[
                  {
                    name: 'declineComment',
                    propertyName: 'declineComment',
                    propertyValue: 'declineComment',
                  },
                ]}
                onChangeState={(v) => inputStateOption.onChangeComment(v)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => props.onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType={
            props.inputOption.butonType ??
            (props.inputOption.modeType === 'send' ? 'high' : 'basic')
          }
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: decisionLabelId,
          })}
          onClick={() => {
            if (props.inputOption.modeType === 'send') {
              setSendConfirmOpen(true);
            } else {
              handleDecision();
            }
          }}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {
      props.onDecision();
    },
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
      {/* 送信 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSendConfirmOpen}
        viewMessage={sendMessage}
        onDecision={() => {
          handleDecision();
        }}
        onCancel={() => {
          setSendConfirmOpen(false);
        }}
      />
    </>
  );
}
