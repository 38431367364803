import {
  Container,
  success,
  error,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
} from '~/shared/components';
import { mtechnavi } from '~/shared/libs/clientsdk/generated';
import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Textbox, ConfirmationDialog } from '~/shared/components/ui';
import { PresetItem, getProgramOptions } from '~/shared/services';
import { validateOnlyNumber } from '~/shared/components/parts/validator';
import {
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  FullMethodName_ListProgramOptions,
} from '~/shared/utils';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
const VIEW_ID: ViewId = 'MASTER_PROGRAMOPTION_INPUT';

interface programOptionInputDisabledSetting {
  categoryName?: boolean;
  categoryDisplayName?: boolean;
  userName: boolean;
  userCode: boolean;
  order?: boolean;
  systemName?: boolean;
  systemDisplayName?: boolean;
  userEditable?: boolean;
}

type createProgramOption =
  mtechnavi.api.programoption.ICreateProgramOptionRequest;
type updateProgramOption =
  mtechnavi.api.programoption.IUpdateProgramOptionRequest;

export function MasterProgramOptionInput() {
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = sourcePageInfo.actionType;
  const intl = useIntl();
  const [programOption, setProgramOption] =
    useState<mtechnavi.api.programoption.IProgramOption>();

  const [categoryName, setCategoryName] = useState('');
  const [categoryDisplayName, setCategoryDisplayName] = useState('');
  const [userCode, setUserCode] = useState('');
  const [userName, setUserName] = useState('');
  const [systemName, setSystemName] = useState('');
  const [systemDisplayName, setSystemDisplayName] = useState('');
  const [order, setOrder] = useState('');
  const [userEditable, setUserEditable] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  // 登録確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [
    programOptionInputDisabledSetting,
    setProgramOptionInputDisabledSetting,
  ] = useState<programOptionInputDisabledSetting>({
    categoryName: true,
    categoryDisplayName: true,
    userName: false,
    userCode: true,
    order: false,
    systemName: true,
    systemDisplayName: true,
    userEditable: true,
  });
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  const navi = useNavigate();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        // 見積依頼情報を取得
        const listProgramOptionRes =
          (await window.App.services.ui.worker.filter({
            action: 'query',
            fullMethodName: FullMethodName_ListProgramOptions,
            filter: {
              programOptionId: { $eq: sourcePageInfo.ids![0] ?? '' },
            },
            sort: [],
          })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

        const programOption = listProgramOptionRes
          ?.items[0] as mtechnavi.api.programoption.IProgramOption;

        if (programOption?.programOptionId === '') {
          setLoading(false);
          error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
          return;
        }

        setProgramOption(programOption);
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      } catch (err) {
        error(getExceptionMessage(intl, err));
      }
    })();

    //複写追加はユーザーコードは入力不可
    if (actionType === 'copy') {
      setProgramOptionInputDisabledSetting({
        ...programOptionInputDisabledSetting,
        userCode: false,
      });
    }

    setLoading(false);

    // intl, sourcePageInfo のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, sourcePageInfo]);

  useEffect(() => {
    setCategoryName(programOption?.categoryName ?? '');
    setCategoryDisplayName(programOption?.category?.displayNameLang?.ja ?? '');
    setUserCode(programOption?.code ?? '');
    setUserName(programOption?.displayNameLang?.ja ?? '');
    setOrder(programOption?.order ? String(programOption?.order) : '');
    setSystemName(programOption?.systemName ?? '');
    setSystemDisplayName(programOption?.system?.displayNameLang?.ja ?? '');
    setUserEditable(programOption?.userEditable ?? false);
    // setUserEditable(programOption?.userEditable ? '◯' : '-');
  }, [programOption]);

  const sendAction = () => {
    inputEntryAction();
  };

  const inputEntryAction = () => {
    (async () => {
      const sendData = {
        programOption: {
          ...programOption,
          order: Number(order),
          displayNameLang: {
            ja: userName,
          },
        },
      };
      try {
        let res;
        if (actionType === 'copy') {
          sendData.programOption.programOptionId = null;
          sendData.programOption.createdAt = null;
          sendData.programOption.updatedAt = null;
          sendData.programOption.deletedAt = null;
          sendData.programOption.code = userCode;

          //種別名、システム名の変更不可
          if (
            categoryDisplayName !==
              programOption?.category?.displayNameLang?.ja ||
            systemDisplayName !== programOption?.system?.displayNameLang?.ja
          ) {
            error([GetMessageWithIntl(intl, { id: 'E0000067' })]);
          }

          //KEY（種別コード＋システムコード）で集計
          const filteredLatestTargetProgramOptions =
            (await window.App.services.ui.worker.filter({
              action: 'reload',
              fullMethodName: FullMethodName_ListProgramOptions,
              filter: {
                $and: [
                  { categoryName: { $eq: programOption?.categoryName ?? '' } },
                  { systemName: { $eq: programOption?.systemName ?? '' } },
                  { userEditable: { $eq: true } },
                ],
              },
              sort: [],
            })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

          //（複写追加モード時）マスタ内に種別コード＋システムコード＋編集可否が「1:可」のレコードが存在する場合のみ可
          if (filteredLatestTargetProgramOptions.items.length === 0) {
            error([GetMessageWithIntl(intl, { id: 'E0000066' })]);
            return;
          }

          //マスタ内に種別コード＋ユーザーコードの重複は不可
          if (
            categoryName ===
              filteredLatestTargetProgramOptions?.items[0].categoryName &&
            userCode === filteredLatestTargetProgramOptions?.items[0].code
          ) {
            error([GetMessageWithIntl(intl, { id: 'E0000065' })]);
            return;
          }

          res =
            await window.App.services.programOptionService.createProgramOption(
              sendData as createProgramOption
            );
        } else {
          //ユーザー名と並び順以外は変更不可
          if (
            categoryName !== programOption?.categoryName ||
            categoryDisplayName !==
              programOption.category?.displayNameLang?.ja ||
            systemName !== programOption?.systemName ||
            userEditable !== programOption?.userEditable ||
            userCode !== programOption?.code ||
            systemDisplayName !== programOption?.system?.displayNameLang?.ja
          ) {
            error([GetMessageWithIntl(intl, { id: 'E0000068' })]);
            return;
          }

          //KEY（種別コード＋システムコード）で集計
          const latestTargetProgramOptions =
            (await window.App.services.ui.worker.filter({
              action: 'reload',
              fullMethodName: FullMethodName_ListProgramOptions,
              filter: {
                $and: [
                  {
                    programOptionId: {
                      $eq: programOption?.programOptionId ?? '',
                    },
                  },
                ],
              },
              sort: [],
            })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

          //明細が存在しない場合エラーを表示して続行不可
          if (latestTargetProgramOptions.items.length === 0) {
            error([GetMessageWithIntl(intl, { id: 'E0000071' })]);
            return;
          }

          //選択された最新のProgramOption
          const editableProgramOptions =
            latestTargetProgramOptions.items.filter((item) => {
              return item.userEditable;
            });

          //明細の編集可否が「0:不可」の場合は不可
          if (editableProgramOptions.length === 0) {
            error([GetMessageWithIntl(intl, { id: 'E0000064' })]);
            return;
          }
          res =
            await window.App.services.programOptionService.updateProgramOption(
              sendData as updateProgramOption
            );
        }
        if (res) {
          // 編集・複写追加後、グローバルの名称マスタも更新
          window.App.programoptions = await getProgramOptions();
          setProgramOption(res);
          // 更新成功通知ダイアログを出す
          success([GetMessageWithIntl(intl, { id: 'I0000001' })]);
        }
      } catch (err) {
        console.error(err);
        error(getExceptionMessage(intl, err));
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);
  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="ProgramOptionInput">
          <div className="header"></div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  {/* 種別コード */}
                  <Textbox
                    name="programOption.categoryName"
                    type="text"
                    value={categoryName}
                    labelId="programOption.categoryName"
                    onChangeState={() => {}}
                    columns={preset.columns}
                    disabled={programOptionInputDisabledSetting.categoryName}
                  />
                </div>
                <div className="w-33">
                  {/* 種別名 */}
                  <Textbox
                    name="programOption.category.displayNameLang"
                    type="text"
                    value={categoryDisplayName}
                    labelId="programOption.category.displayNameLang"
                    onChangeState={() => {}}
                    columns={preset.columns}
                    disabled={
                      programOptionInputDisabledSetting.categoryDisplayName
                    }
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  {/* ユーザーコード */}
                  <Textbox
                    name="programOption.code"
                    type="text"
                    value={userCode}
                    labelId="programOption.code"
                    onChangeState={setUserCode}
                    columns={preset.columns}
                    disabled={programOptionInputDisabledSetting.userCode}
                  />
                </div>
                <div className="w-33">
                  {/* ユーザー名 */}
                  <Textbox
                    name="programOption.displayNameLang"
                    type="text"
                    value={userName}
                    labelId="programOption.displayNameLang"
                    onChangeState={setUserName}
                    columns={preset.columns}
                    disabled={programOptionInputDisabledSetting.userName}
                  />
                </div>
              </div>
            </div>
            {/* 並び順 */}
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <Textbox
                    name="programOption.order"
                    type="text"
                    value={order}
                    labelId="programOption.order"
                    onChangeState={setOrder}
                    columns={preset.columns}
                    disabled={programOptionInputDisabledSetting.order}
                    validator={validateOnlyNumber(intl)}
                    className="right"
                  />
                </div>
              </div>
            </div>

            {/* システムコード */}
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <Textbox
                    name="programOption.systemName"
                    type="text"
                    value={systemName}
                    labelId="programOption.systemName"
                    onChangeState={() => {}}
                    columns={preset.columns}
                    disabled={programOptionInputDisabledSetting.systemName}
                  />
                </div>
                {/* システム名 */}
                <div className="w-33">
                  <Textbox
                    name="programOption.system.displayNameLang"
                    type="text"
                    value={systemDisplayName}
                    labelId="programOption.system.displayNameLang"
                    onChangeState={() => {}}
                    columns={preset.columns}
                    disabled={
                      programOptionInputDisabledSetting.systemDisplayName
                    }
                  />
                </div>
              </div>
            </div>
            {/* 編集可能 */}
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <Textbox
                    name="programOption.userEditable"
                    type="text"
                    value={userEditable ? '◯' : '-'}
                    labelId="programOption.userEditable"
                    onChangeState={() => {}}
                    columns={preset.columns}
                    disabled={programOptionInputDisabledSetting.userEditable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                className="button-margin"
                name=""
                caption="戻る"
                onClick={() => {
                  navi('/master-programoption-list');
                }}
                buttonType="cancel"
              ></CaptionButton>
              <CaptionButton
                name="resetBtn"
                caption="確定"
                className="btn"
                onClick={() => setOpenConfirmDialog(true)}
                buttonType="basic"
              />
            </div>
          </div>
        </div>
      </Container>
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={{
          id: 'C0000001',
          value: {
            $1: GetMessageWithIntl(intl, {
              prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
              viewId: VIEW_ID,
            }),
          },
        }}
        onDecision={() => {
          setLoading(true);
          setOpenConfirmDialog(false);
          sendAction();
        }}
        onCancel={() => {
          setOpenConfirmDialog(false);
        }}
      ></ConfirmationDialog>
      {isLoading && <LoadingIcon />}
      <Toast />
    </>
  );
}
