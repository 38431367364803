/**
 * カテゴリ分けしづらいその他の変換処理
 */

import { convertStringToNumber } from './numeric';

export function convertStringToCommaString(
  value: string | null | undefined
): string {
  const num = convertStringToNumber(value);
  if (!num) {
    return '';
  }
  const commmaValue = num.toLocaleString();
  return commmaValue ? commmaValue : '';
}

export function convertDisplayUserNameEmail(
  userName: string | null | undefined,
  email: string | null | undefined
) {
  return userName && email ? `${userName}(${email})` : '';
}
