import { Textarea } from '~/shared/components/ui';
import { Filterbox, FilterboxItem } from '../Filterbox';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { FilterExpression } from '~/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getExceptionMessage } from '~/shared/utils';
import { error } from '../../parts/Toast/Toast';
import { includeInputValidateError } from '~/shared/utils';

export interface ApprovalRequestInputOption {
  FullMethodName_ListComponentUnits: string;
  FullMethodName_ListStaffs: string;
  selectedApprovalUnit?: FilterboxItem[];
  requestReciptId: string;
  isHighAffect?: boolean;
}

export interface OutputOption {
  requestReciptId: string;
  approvalOrganizationUnit: FilterboxItem[];
  approvalOrganizationStaff: FilterboxItem[];
  comment: string;
}

export interface ApprovalRequestDialogProps {
  isOpen: boolean;
  inputOption: ApprovalRequestInputOption;
  onDecision: (result: OutputOption) => void;
  onCancel: () => void;
}

export const ApprovalRequestDialog = (props: ApprovalRequestDialogProps) => {
  const approvalOrganaizationUnitsItemType = {
    value: 'componentUnitId',
    displayName: 'displayNameLang',
  };

  const approvalOrganaizationStaffItemType = {
    value: 'staffId',
    displayName: 'displayName',
  };
  const intl = useIntl();
  const { isOpen, inputOption } = props;
  const [approvalOrganizationUnit, setApprovalOrganizationUnit] = useState<
    FilterboxItem[]
  >([]);
  const [approvalOrganizationStaff, setApprovalOrganizationStaff] = useState<
    FilterboxItem[]
  >([]);

  // 必須チェック用にBlurするための変数
  const [workingBlurOrganizationUnit, setWorkingBlurOrganizationUnit] =
    useState<Date>();
  const [workingBlurStaff, setWorkingBlurStaff] = useState<Date>();
  const [comment, setComment] = useState<string>('');
  const [organizationStaffCustomQuery, setOrganizationStaffCustomQuery] =
    useState<FilterExpression>({});
  const requiredInputArea = useRef(null);

  const handleDecision = (result: OutputOption) => {
    if (isInputError()) {
      return;
    }

    setWorkingBlurOrganizationUnit(undefined);
    setWorkingBlurStaff(undefined);
    props.onDecision(result);
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.approval-request-dialog');
    // フィルター項目を強制的にBlurする
    setWorkingBlurOrganizationUnit(new Date());
    setWorkingBlurStaff(new Date());
    const organizationUnitValue =
      approvalOrganizationUnit.length > 0
        ? approvalOrganizationUnit[0].value
        : '';
    const staffValue =
      approvalOrganizationStaff.length > 0
        ? approvalOrganizationStaff[0].value
        : '';
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: organizationUnitValue,
          ref: requiredInputArea,
        },
        {
          value: staffValue,
          ref: requiredInputArea,
        },
      ])
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (approvalOrganizationStaff.length > 0) {
      (async () => {
        try {
          const listStaffRes = (await window.App.services.ui.worker.filter({
            action: 'query',
            fullMethodName: inputOption.FullMethodName_ListStaffs,
            filter: {
              staffId: { $eq: approvalOrganizationStaff[0].value ?? '' },
            },
            sort: [],
          })) as mtechnavi.api.company.ListStaffsResponse;

          const staff = listStaffRes.items[0] as mtechnavi.api.company.IStaff;
          const componentUnitId = staff.componentUnitId;

          const listComponetUnitsRes =
            (await window.App.services.ui.worker.filter({
              action: 'query',
              fullMethodName: inputOption.FullMethodName_ListComponentUnits,
              filter: {
                $and: [
                  { 'organizationUnit.systemName': { $eq: 'B02' } },
                  { componentUnitId: { $eq: componentUnitId ?? '' } },
                ],
              },
              sort: [],
            })) as mtechnavi.api.company.ListComponentUnitsResponse;

          const componentUnit = listComponetUnitsRes
            .items[0] as mtechnavi.api.company.IComponentUnit;
          setApprovalOrganizationUnit([
            {
              displayName: componentUnit?.displayNameLang?.ja ?? '',
              value: componentUnit?.componentUnitId ?? '',
            },
          ]);
        } catch (err) {
          error(getExceptionMessage(intl, err));
          throw err;
        }
      })();
    }
    // approvalOrganizationStaff変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalOrganizationStaff]);

  useEffect(() => {
    approvalOrganizationUnit.length
      ? setOrganizationStaffCustomQuery({
          componentUnitId: { $eq: approvalOrganizationUnit[0].value ?? '' },
        })
      : setOrganizationStaffCustomQuery({});
  }, [approvalOrganizationUnit]);

  const view = (
    <div className="approval-request-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'approval_request',
            })}
          </span>
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line" ref={requiredInputArea}>
            <div className="item-group-100">
              <div className="w-50">
                <Filterbox
                  name="approvalOrganizationUnits"
                  fullMethodName={inputOption.FullMethodName_ListComponentUnits}
                  labelId="approvalRequestDialog.approval_organization_unit"
                  columns={['approvalOrganizationUnits']}
                  itemType={approvalOrganaizationUnitsItemType}
                  value={
                    approvalOrganizationUnit ?? [{ value: '', displayName: '' }]
                  }
                  validateOption={{ required: true }}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: {
                      'organizationUnit.systemName': { $eq: 'B02' },
                    },
                  }}
                  formatOption={{ mapKeyValue: 'ja' }}
                  onChangeState={(v) => {
                    setApprovalOrganizationStaff([]);
                    setApprovalOrganizationUnit(v);
                  }}
                  workingBlur={workingBlurOrganizationUnit}
                />
              </div>
              <div className="w-50">
                <Filterbox
                  name="approvalOrganizationStaff"
                  fullMethodName={inputOption.FullMethodName_ListStaffs}
                  labelId="approvalRequestDialog.approval_organization_staff"
                  columns={['approvalOrganizationStaff']}
                  value={
                    approvalOrganizationStaff ?? [
                      { value: '', displayName: '' },
                    ]
                  }
                  itemType={approvalOrganaizationStaffItemType}
                  validateOption={{ required: true }}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: organizationStaffCustomQuery,
                  }}
                  onChangeState={setApprovalOrganizationStaff}
                  workingBlur={workingBlurStaff}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="comment"
                  labelId="approvalRequestDialog.comment"
                  value=""
                  columns={['comment']}
                  className="w-100 mh-middle"
                  onChangeState={setComment}
                />
              </div>
            </div>
          </div>
          <div className="button-area">
            <CaptionButton
              name="cancelBtn"
              buttonType="cancel"
              className="button"
              caption={GetMessageWithIntl(intl, { id: 'cancel' })}
              onClick={() => props.onCancel()}
            />
            <CaptionButton
              name="sendBtn"
              buttonType={inputOption.isHighAffect ? 'high' : 'basic'}
              className="button"
              caption={GetMessage({
                prefixId: 'DIALOG_CAPTION',
                id: 'send_approval_request',
              })}
              onClick={() =>
                handleDecision({
                  requestReciptId: inputOption.requestReciptId,
                  approvalOrganizationUnit,
                  approvalOrganizationStaff,
                  comment,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'approval_request',
    },
    messageLabelId: {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
