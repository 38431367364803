import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import {
  // FIXME:5~8月の改善系に伴う修正 20204/04/23 現在
  // FullMethodName_ListEstimateRequests,
  FullMethodName_ListSurveyRequestContents,
  FullMethodName_ListWorkTasks,
  ViewId,
  getExceptionMessage,
  getLocalStorageCheckboxData,
  getPresetAndSchema,
  saveFilterAndSortData,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import {
  ListView,
  Preset,
  Property,
  getBooleanDataFormetterSchema,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PresetItem } from '~/shared/services';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './WorkTaskConnectingDialog.css';

export type ConnectingType = 'workTask' | 'estimate' | 'survey';

export interface ConnectingDialogProps {
  connectingType: ConnectingType;
  orderNumber: string;
  isOpen: boolean;
  onDecision: (result: ConnectingDialogOutputOption[]) => void;
  onCancel: () => void;
}

export interface ConnectingDialogOutputOption {
  workTaskId: string;
  connectingType: ConnectingType;
  orderNumber: string;
  displayName: string;
  completeCondition: boolean;
}

export const WorkTaskConnectingDialog = (props: ConnectingDialogProps) => {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const connectingType = props.connectingType;
  const fullMethodNameMap = {
    workTask: FullMethodName_ListWorkTasks,
    survey: FullMethodName_ListSurveyRequestContents,
    // FIXME:5~8月の改善系に伴う修正 20204/04/23 現在
    // estimate: FullMethodName_ListEstimateRequests,
    estimate: '',
  };

  const menuTargetMap = {
    workTask: 'workTaskId',
    survey: 'surveyRequest.surveyRequestId',
    estimate: 'estimateRequestId',
  };

  const VIEW_ID: ViewId = useMemo(() => {
    switch (connectingType) {
      case 'workTask':
        return 'WT_LINKED_WORK_TASK';
      case 'estimate':
        return 'WT_LINKED_ESTIMATE';
      case 'survey':
        return 'WT_LINKED_SURVEY';
      default:
        return '';
    }
  }, [connectingType]);

  const listWorkTaskItemRef = useRef<mtechnavi.api.worktask.IWorkTask[]>([]);
  const listSurveyRequestContentsItemRef = useRef<
    mtechnavi.api.survey.ISurveyRequestContent[]
  >([]);
  const listEstimateItemRef = useRef<
    mtechnavi.api.estimation.IEstimateRequest[]
  >([]);

  const [schema, setSchema] = useState<Array<Property>>([]);
  const [presetItem, setPresetItem] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetChildrenItems, setPresetChildrenItems] =
    useState<PresetItem[]>();
  const [isSuspendReload, setSuspendReload] = useState(true);

  useEffect(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
    setSuspendReload(true);
    (async () => {
      try {
        if (!VIEW_ID || !props.isOpen) {
          return;
        }
        if (presetChildrenItems) {
          resetPreset();
        }
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            fullMethodNameMap[connectingType],
          ]);
        const booleanColumn =
          connectingType === 'survey'
            ? ['surveyRequest.completed']
            : ['completed'];
        const booleanSch = getBooleanDataFormetterSchema(
          schemas[0],
          booleanColumn,
          true
        );

        const jaColumn: string[] = [
          'status.status.displayNameLang',
          'status.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(booleanSch, jaColumn, 'ja');
        setPresetChildrenItems(childrenPresetItem);
        setSchema(formatterSch);
        setPresetItem(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
      setSuspendReload(false);
    })();
    // VIEW_ID変更時/起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VIEW_ID, intl, props.isOpen]);

  const handleDecision = () => {
    const checkedIds = getLocalStorageCheckboxData(VIEW_ID, myEmail);
    if (!checkedIds.length) {
      props.onCancel();
      return;
    }
    if (checkedIds.some((v) => v === props.orderNumber)) {
      error([GetMessageWithIntl(intl, { id: 'E0000089' })]);
      return;
    }

    const result = getRecordData(checkedIds);

    // 取得処理
    props.onDecision(result);
  };

  const resetPreset = () => {
    setSchema([]);
    setPresetItem({
      filter: {},
      propertyNames: [],
    });
    setPresetChildrenItems([]);
    saveFilterAndSortData(VIEW_ID, { sort: [] }, myEmail);
  };

  const handleCancel = () => {
    resetPreset();
    props.onCancel();
  };

  const getRecordData = (ids: string[]) => {
    const workTaskResult: ConnectingDialogOutputOption[] = [];
    const surveyResult: ConnectingDialogOutputOption[] = [];
    const estimateResult: ConnectingDialogOutputOption[] = [];
    switch (connectingType) {
      case 'workTask':
        listWorkTaskItemRef.current.map((item) => {
          if (item.workTaskId && ids.includes(item.workTaskId)) {
            workTaskResult.push({
              workTaskId: props.orderNumber,
              connectingType: connectingType,
              orderNumber: item.workTaskId ?? '',
              displayName: item.displayName ?? '',
              completeCondition: item.completed ?? false,
            });
          }
        });
        return workTaskResult as ConnectingDialogOutputOption[];
      case 'survey':
        listSurveyRequestContentsItemRef.current.map((item) => {
          if (
            item.surveyRequest?.surveyRequestId &&
            ids.includes(item.surveyRequest?.surveyRequestId)
          ) {
            surveyResult.push({
              workTaskId: props.orderNumber,
              connectingType: connectingType,
              orderNumber: item.surveyRequest?.surveyRequestId,
              displayName: item.surveyRequest?.displayName ?? '',
              completeCondition: item.surveyRequest.completed ?? false,
            });
          }
        });
        return surveyResult as ConnectingDialogOutputOption[];
      case 'estimate':
        // FIXME:5~8月の改善系に伴う修正 20204/04/23 現在
        // listEstimateItemRef.current.map((item) => {
        //   if (item.estimateRequestId && ids.includes(item.estimateRequestId)) {
        //     estimateResult.push({
        //       workTaskId: props.orderNumber,
        //       connectingType: connectingType,
        //       orderNumber: item.estimateRequestId ?? '',
        //       displayName: item.displayName ?? '',
        //       completeCondition: item.completed ?? false,
        //     });
        //   }
        // });
        return estimateResult as ConnectingDialogOutputOption[];
      default:
        return [];
    }
  };

  const elements = (
    <div className="connecting-dialog">
      <div className="description-area">
        <p>
          {props.connectingType
            ? GetMessageWithIntl(intl, {
                id: props.connectingType,
                prefixId: 'ConnectingDialogType',
              })
            : ''}
        </p>
      </div>
      <div className="connecting-area">
        <ListView
          fullMethodName={fullMethodNameMap[connectingType]}
          pageInfo={{
            preset: presetItem,
            schema,
            menuTarget: menuTargetMap[connectingType],
            presetItems: presetChildrenItems,
            headerTitle: { viewId: VIEW_ID },
            listSkipType: {
              isHeaderIconMenu: true,
              isTotal: true,
              isListActionMenu: true,
              isListIconMenu: true,
              isOutput: true,
            },
          }}
          filterItemOption={{
            isRequestBodyFilter: true,
          }}
          stateOption={{
            onOriginalItemState: (items: unknown[]) => {
              switch (props.connectingType) {
                case 'workTask':
                  listWorkTaskItemRef.current =
                    items as mtechnavi.api.worktask.IWorkTask[];
                  return;
                case 'survey':
                  listSurveyRequestContentsItemRef.current =
                    items as mtechnavi.api.survey.ISurveyRequestContent[];
                  return;
                case 'estimate':
                  listEstimateItemRef.current =
                    items as mtechnavi.api.estimation.IEstimateRequest[];
                  return;
              }
            },
          }}
          isSuspendReload={isSuspendReload}
        />
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={() => handleDecision()}
        />
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'CONNECTING',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
