import { ChangeEvent, useMemo, useState, useEffect } from 'react';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { Container } from '~/shared/components';
import {
  MenuListView,
  Schema,
  Preset,
  MenuItem,
  getSchema,
} from '~/shared/components/ui';
import { Catalog } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';

const availableFullMethodNames = Catalog.services()
  .flatMap((v) => v.methodsArray)
  // ListXXX という命名規則のメソッドが対象
  .filter((v) => v.name.startsWith('List'))
  // 先頭1文字は "." がつくので、削除
  .map((v) => v.fullName.substr(1))
  // サービス名とメソッド名は "/" で区切る
  .map((v) => v.replace(/\.(\w+)$/, '/$1'));

export function List() {
  const auth = useAuth();
  const [fullMethodName, setFullMethodName] = useState<string>('');
  const [presetName, setPresetName] = useState<string>('');
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [selectItems, setSelectItems] = useState<string[]>();

  const [presetItems, setPresetItems] = useState<Array<PresetItem>>();
  const [roleNames, setRoleNames] = useState<Array<string>>();

  useEffect(() => {
    (async () => {
      const presetItems = await window.App.services.ui.getPresetItems();
      const [roleNames] = await window.App.services.ui.getUserRoles(
        auth.user?.userGroupIds
      );
      setPresetItems(presetItems);
      setRoleNames(roleNames);
    })();

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [schema, preset] = useMemo<[Schema, Preset]>(() => {
    console.info('fullMethodName', fullMethodName);
    if (!fullMethodName) {
      return [
        [{ name: 'データ未選択', typeName: 'string' }],
        {
          filter: {},
          propertyNames: ['データ未選択'],
        },
      ];
    }
    const rolePreset: PresetItem[] = (presetItems ?? []).filter((value) => {
      return (roleNames ?? []).indexOf(value.name) >= 0;
    });
    const propertyNames = Array<string>();
    rolePreset.map((preset) => {
      const choosePresetItems = (preset.children ?? []).filter(
        (childPreset) => childPreset.name === presetName
      );
      const choosePresetItem: PresetItem =
        choosePresetItems.length > 0
          ? choosePresetItems[0]
          : { name: presetName };
      if (choosePresetItem?.columns) {
        for (const column of choosePresetItem.columns) {
          propertyNames.push(column);
        }
      }
      if (choosePresetItem && choosePresetItem.children) {
        setChildrenPresetItem(choosePresetItem.children);
      } else {
        setChildrenPresetItem([]);
        // そもそも表示項目以外のプリセットが存在しない場合、初期化
      }
    });
    const sch = getSchema(fullMethodName);
    return [
      sch,
      {
        filter: {},
        propertyNames: propertyNames,
      },
    ];

    //fullMethodName 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullMethodName]);

  const handleChangeFullMethodName = (evt: ChangeEvent<HTMLSelectElement>) => {
    const method = Catalog.lookupMethod(evt.target.value);
    setPresetName(method.name);
    setFullMethodName(evt.target.value);
  };

  // サンプルでバーガーメニューに関数を渡すためのサンプル関数
  const handleMenu = (prop?: string[]) => {
    console.debug('preset menuFunc:');
    console.debug(prop);
  };
  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // 追加
    menuItems.push({ name: 'add', func: (v?: string[]) => handleMenu(v) });
    // 確認
    menuItems.push({ name: 'confirm', func: (v?: string[]) => handleMenu(v) });
    // 削除
    menuItems.push({ name: 'delete', func: (v?: string[]) => handleMenu(v) });
    // タグ付け
    menuItems.push({ name: 'tag', func: (v?: string[]) => handleMenu(v) });
    return menuItems;
  };
  return (
    <Container>
      <div>
        <fieldset>
          <label>
            データ
            <select onChange={handleChangeFullMethodName}>
              <option value=""></option>
              {availableFullMethodNames.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </select>
          </label>
        </fieldset>
      </div>
      <MenuListView
        fullMethodName={fullMethodName}
        schema={schema}
        preset={preset}
        presetItems={childrenPresetItem}
        menuEvent={burgerMenuEvent()}
        checkValue={selectItems}
        onChangeState={setSelectItems}
      />
    </Container>
  );
}
