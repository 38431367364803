import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  BusinessUnitStrengthDialog,
  BusinessUnitStrengthFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitStrengthDialog';

export const UISampleBusinessUnitStrengthDialogs = () => {
  const [inputResult, setInputResult] = useState('');
  const [isInputShow, setInputShow] = useState(false);
  const [editResult, setEditResult] = useState('');
  const [isEditShow, setEditShow] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState('');
  const [isConfirmationShow, setConfirmationShow] = useState(false);

  const inputOption: BusinessUnitStrengthFormOption = {
    companyId: '大阪支店',
    category: {
      categoryName: 'A4000001',
      code: '1',
      systemName: 'B01',
    },
    content: '研磨します',
    linkProperties: {
      businessUnitFacilitiesId: '',
      businessUnitBranchId: '',
    },
  };

  return (
    <div className="categories">
      <h3 id="dialog">得意分野情報登録/確認ダイアログ</h3>
      <h4>新規登録</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setInputResult('');
                setInputShow(true);
              }}
            />
            <BusinessUnitStrengthDialog
              isOpen={isInputShow}
              inputMode={true}
              onCancel={() => {
                setInputResult('キャンセルしました');
                setInputShow(false);
              }}
              onDecision={(v) => {
                setInputResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setInputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{inputResult}</pre>
          </div>
        </div>
      </div>

      <h4>編集</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEditResult('');
                setEditShow(true);
              }}
            />
            <BusinessUnitStrengthDialog
              isOpen={isEditShow}
              inputMode={true}
              inputOption={inputOption}
              onCancel={() => {
                setEditResult('キャンセルしました');
                setEditShow(false);
              }}
              onDecision={(v) => {
                setEditResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setEditShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{editResult}</pre>
          </div>
        </div>
      </div>

      <h4>確認</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setConfirmationResult('');
                setConfirmationShow(true);
              }}
            />
            <BusinessUnitStrengthDialog
              isOpen={isConfirmationShow}
              inputMode={false}
              inputOption={inputOption}
              onCancel={() => {
                setConfirmationResult('キャンセルしました');
                setConfirmationShow(false);
              }}
              onDecision={(v) => {
                setConfirmationResult(
                  `出力しました。${JSON.stringify(v, null, 2)}`
                );
                setConfirmationShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{confirmationResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
