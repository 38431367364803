import { useRef, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import {
  DataFilterbox,
  DataFilterboxItem,
  Textbox,
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui';
import { error } from '../../parts/Toast/Toast';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import './base.css';
import './DocumentRegistrationDialog.css';
import {
  includeInputValidateError,
  autoDownloadFileOnlyName,
  getDateFormat,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { AttachmentFile } from './CommonDialogTypes';
import { getProgramOptionFilterboxData } from '~/shared/utils/converter';
import { FileUploader, FileItem } from '../../file';

export type DocumentRegistrationDisplayModeType = 'save' | 'display';

export interface DocumentRegistrationDialogInputOption {
  displayModeType: DocumentRegistrationDisplayModeType;
  businessUnitDocument?: mtechnavi.api.company.IBusinessUnitDocument;
}

export interface DocumentRegistrationResult {
  businessUnitDocument: mtechnavi.api.company.IBusinessUnitDocument;
  files: AttachmentFile[];
}

export interface DocumentRegistrationDialogProps {
  isOpen: boolean;
  inputOption?: DocumentRegistrationDialogInputOption;
  onEdit: (result: DocumentRegistrationResult) => void;
  onDelete: (result: DocumentRegistrationResult) => void;
  onSave: (result: DocumentRegistrationResult) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export const DocumentRegistrationDialog = ({
  isOpen,
  inputOption,
  onEdit,
  onDelete,
  onSave,
  onCancel,
  onChangeLoadingState,
}: DocumentRegistrationDialogProps) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [isModeTypeDisplay, setModeTypeDisplay] = useState<boolean>(false);
  // 画面項目
  const [category, setCategory] = useState<DataFilterboxItem[]>([]);
  const [attachment, setAttachment] = useState<AttachmentFile>();
  const [filename, setFilename] = useState<string>('');
  const [remarks, setRemarks] = useState<string>('');
  const [attachedAt, setAttachedAt] = useState<string>('');
  // 必須チェック
  const requiredCategoryArea = useRef(null);
  const requiredFilenameArea = useRef(null);
  const [workingBlurCategory, setWorkingBlurCategory] = useState<Date>();
  // 確認ダイアログ（ファイル添付削除）
  const [isDeleteDocumentConfirmOpen, setDeleteDocumentConfirmOpen] =
    useState(false);

  //アップロード処理
  const [isUploadingContain, setUploadingContain] = useState(false);
  const [isErrorContain, setErrorContain] = useState(false);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);
  const [isFileUploaderLoading, setFileUploaderLoading] = useState(false);

  // フィルタボックス定義
  const CategoryTypeCode = 'A4000010';
  const [categoryTypes] = useMemo<[DataFilterboxItem[]]>(() => {
    const categoryTypes = getProgramOptionFilterboxData(CategoryTypeCode);
    return [categoryTypes];
  }, []);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    if (isModeTypeDisplay) {
      // 編集
      iconItems.push({
        name: 'edit',
        displayName: '編集',
        func: () => {
          handleEditDocument();
        },
      });
      // 削除
      iconItems.push({
        name: 'delete',
        displayName: '削除',
        func: () => setDeleteDocumentConfirmOpen(true),
      });
    }

    return iconItems;
  };

  // メッセージ定義
  const deleteMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  // クリア処理
  const clearDisplay = () => {
    setCategory([]);
    setAttachment(undefined);
    setFilename('');
    setRemarks('');
    setAttachedAt('');
    setWorkingBlurCategory(undefined);
    setLoading(false);
  };

  // ファイル添付時処理
  const handleUploaded = (fileList: FileItem[]) => {
    setUploadingContain(fileList.some((item) => item.status === 'uploading'));
    setErrorContain(fileList.some((item) => item.status === 'failure'));
    setTempFileList(fileList);
  };

  // [実行処理]編集
  const handleEditDocument = () => {
    const result: DocumentRegistrationResult = {
      businessUnitDocument: inputOption?.businessUnitDocument ?? {},
      files: [],
    };
    onEdit(result);
  };

  // [実行処理]削除
  const handleDeleteDocument = () => {
    const result: DocumentRegistrationResult = {
      businessUnitDocument: inputOption?.businessUnitDocument ?? {},
      files: [],
    };
    onDelete(result);
    setDeleteDocumentConfirmOpen(false);
  };

  // ファイルダウンロード処理
  const handleDownload = async () => {
    autoDownloadFileOnlyName(
      attachment?.filename || '',
      attachment?.assetId || ''
    );
  };

  // キャンセル時処理
  const handleCancel = () => {
    onCancel();
    clearDisplay();
  };

  // 確定時処理
  const handleDecision = () => {
    if (isInputError()) {
      return;
    }

    // 新規の場合（アップロード機能がある場合）のみチェックする
    if (!inputOption?.businessUnitDocument?.businessUnitDocumentId) {
      if (!tempFileList.length) {
        error([GetMessageWithIntl(intl, { id: 'E0000094' })]);
        return;
      }
      if (isUploadingContain || isErrorContain) {
        error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
        return;
      }
    }

    const attachmentFiles: AttachmentFile[] = [];
    // 新規の場合はアップロードしたファイル情報を返す
    if (!inputOption?.businessUnitDocument?.businessUnitDocumentId) {
      const attachmentCategory =
        window.App.services.ui.getNameOptionWithSystemName('A0000016', 'B03');
      const attachmentLinkType =
        window.App.services.ui.getNameOptionWithSystemName('A0000017', 'B03');

      tempFileList.map((file) =>
        attachmentFiles.push({
          id: `${file.file.name ?? ''}`,
          category: attachmentCategory.length > 0 ? attachmentCategory[0] : {},
          assetId: '',
          filename: filename,
          url: file.url,
          mimeType: '',
          remarks: remarks,
          linkType: attachmentLinkType.length > 0 ? attachmentLinkType[0] : {},
        })
      );
    }
    // 画面項目をセットする
    const businessUnitDocument: mtechnavi.api.company.IBusinessUnitDocument =
      inputOption?.businessUnitDocument ?? {};
    businessUnitDocument.category =
      window.App.services.ui.getNameOptionWithCode(
        CategoryTypeCode,
        category[0].value ?? ''
      );
    if (businessUnitDocument.attachment) {
      businessUnitDocument.attachment.filename = filename;
      businessUnitDocument.attachment.remarks = remarks;
    }

    const result: DocumentRegistrationResult = {
      businessUnitDocument: businessUnitDocument,
      files: attachmentFiles,
    };

    onSave(result);
  };

  // エラーチェック
  const isInputError = (): boolean => {
    setWorkingBlurCategory(new Date());

    const inputValidationCheckList = [
      {
        value: category.length > 0 ? category[0].value : '',
        ref: requiredCategoryArea,
      },
      {
        value: filename,
        ref: requiredFilenameArea,
      },
    ];

    const targetElm = document.querySelector('.document-registration-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  // 起動モード変更時
  useEffect(() => {
    setModeTypeDisplay((inputOption?.displayModeType ?? '') === 'display');
  }, [inputOption?.displayModeType]);

  // ダイアログ表示変更時
  useEffect(() => {
    // ダイアログ起動時
    if (isOpen) {
      clearDisplay();
      const businessUnitDocument: mtechnavi.api.company.IBusinessUnitDocument =
        inputOption?.businessUnitDocument ?? {};
      if (businessUnitDocument) {
        setCategory(
          categoryTypes.filter(
            (type) => type.value === businessUnitDocument.category?.code
          )
        );
        if (businessUnitDocument.attachment) {
          const paramAttachment =
            businessUnitDocument.attachment as AttachmentFile;
          paramAttachment.id = businessUnitDocument.attachment.assetId ?? '';
          setAttachment(paramAttachment);
          setFilename(businessUnitDocument.attachment?.filename ?? '');
          setRemarks(businessUnitDocument.attachment?.remarks ?? '');
        }
        setAttachedAt(
          getDateFormat(
            businessUnitDocument.attachment?.attachedAt ?? '',
            'YYYY/MM/DD HH:mm'
          )
        );
      }
    }
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // 添付データ変更時
  useEffect(() => {
    // 修正モード時はスキップする
    if (inputOption?.businessUnitDocument?.businessUnitDocumentId) return;
    if (tempFileList.length > 0) {
      setFilename(tempFileList[0].file.name);
    } else {
      setFilename('');
    }
    // tempFileList 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileList]);

  //Loading制御
  useEffect(() => {
    onChangeLoadingState &&
      onChangeLoadingState(isLoading || isFileUploaderLoading);
    // Loading関連（isLoading/isFileUploaderLoading）変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFileUploaderLoading]);

  const elements = (
    <div className="document-registration-dialog">
      <div className="header">
        <PageNavigation
          backpagePath=""
          pageInfo={{
            isUnvisibleBackNavi: true,
            isVisibleMoveNavi: false,
          }}
          iconItems={navigationIconItems()}
          infoOption={{
            lastUpdateInfo: {
              isVisibleUpdateInfo: true,
              attribute:
                inputOption?.businessUnitDocument?.updatedProperties?.updatedBy
                  ?.email ?? '',
              attributeAt:
                inputOption?.businessUnitDocument?.updatedProperties
                  ?.updatedAt ?? undefined,
              content:
                inputOption?.businessUnitDocument?.updatedProperties?.updatedBy
                  ?.displayName ?? '',
            },
            issuerInfo: { isVisibleIssuerInfo: false },
          }}
        />
      </div>
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <div className="input" ref={requiredCategoryArea}>
                <DataFilterbox
                  data={categoryTypes}
                  searchOption={{ targets: 'displayName' }}
                  name="category"
                  labelId="DocumentRegistrationDialog.category"
                  columns={['category']}
                  onChangeState={setCategory}
                  validateOption={{ required: true }}
                  value={category}
                  disabled={isModeTypeDisplay}
                  workingBlur={workingBlurCategory}
                ></DataFilterbox>
              </div>
            </div>
            <div className="w-66">
              {!isModeTypeDisplay && (
                <div className="upload-button">
                  <FileUploader
                    name="fileUploader"
                    messageOption={{
                      uploadLabelId: {
                        id: 'attachment.upload',
                        prefixId: 'DocumentRegistrationDialog',
                      },
                      omitAttachedIcon: true,
                    }}
                    dndOption={{
                      enabled: false,
                    }}
                    multiple={false}
                    validateOption={{
                      maxFileSizeInMebis: 50,
                    }}
                    onUpload={handleUploaded}
                    onChangeLoadingState={(v) => setFileUploaderLoading(v)}
                    disabled={
                      inputOption?.businessUnitDocument?.businessUnitDocumentId
                        ? true
                        : false
                    }
                  />
                </div>
              )}
              {isModeTypeDisplay && (
                <div className="download-button">
                  <CaptionButton
                    name="downloadBtn"
                    buttonType="basic"
                    className="button"
                    caption={GetMessageWithIntl(intl, {
                      id: 'attachment.download',
                      prefixId: 'DocumentRegistrationDialog',
                    })}
                    onClick={handleDownload}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <div className="input" ref={requiredFilenameArea}>
                <Textbox
                  name="filename"
                  labelId="DocumentRegistrationDialog.filename"
                  type="text"
                  validateOption={{ required: true }}
                  columns={['filename']}
                  value={filename}
                  onChangeState={setFilename}
                  disabled={isModeTypeDisplay}
                />
              </div>
            </div>
            <div className="w-33">
              <div className="input">
                {isModeTypeDisplay && (
                  <Textbox
                    name="attachedAt"
                    labelId="DocumentRegistrationDialog.attachedAt"
                    type="text"
                    columns={['attachedAt']}
                    value={attachedAt}
                    onChangeState={setAttachedAt}
                    disabled={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <div className="input">
                <Textbox
                  name="remarks"
                  labelId="DocumentRegistrationDialog.remarks"
                  type="text"
                  columns={['remarks']}
                  value={remarks}
                  onChangeState={setRemarks}
                  disabled={isModeTypeDisplay}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          {!isModeTypeDisplay && (
            <CaptionButton
              name="decisionBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, { id: 'save' })}
              onClick={handleDecision}
              disabled={isLoading || isFileUploaderLoading}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: isModeTypeDisplay
      ? {
          prefixId: 'DIALOG_TITLE',
          id: 'documentConfirmation',
        }
      : {
          prefixId: 'DIALOG_TITLE',
          id: 'documentRegistration',
        },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <div className="DocumentRegistrationDialog">
        <ModalDialogComponent {...openModalProps} />
      </div>
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteDocumentConfirmOpen}
        viewMessage={deleteMessage}
        onDecision={handleDeleteDocument}
        onCancel={() => {
          setDeleteDocumentConfirmOpen(false);
        }}
      />
    </>
  );
};
