/**
 * カテゴライズできないユーティリティ関数
 */

// 空オブジェクト判定 APIのデータを表示データに変換する際に利用
export function checkEmptyObject(targetObject: {} | null | undefined) {
  if (targetObject === null || targetObject === undefined) {
    return true;
  }

  const items = Object.keys(targetObject);
  if (items.length === 0) {
    return true;
  } else if (items.length === 1 && items.includes('displayNameLang')) {
    // mongoDBを通すと空オブジェクトでもdisplayNameLangが設定されるためその対応
    const tmp = targetObject['displayNameLang' as keyof typeof targetObject];
    if (typeof tmp === 'object' && Object.keys(tmp).length === 0) {
      return true;
    }
  }

  return false;
}

// 配列から指定されたものを削除した処理
export function getDeletedItemList<T>(
  deleteTargetIds: string[] | undefined,
  list: T[],
  deleteTargetName: keyof T
) {
  if (!deleteTargetIds) {
    return list;
  }

  let tmp = list;
  deleteTargetIds?.map((id) => {
    tmp = tmp.filter(
      // @ts-ignore
      (item) => item[deleteTargetName] !== id
    );
  });
  return tmp;
}

// ランダムな値を生成 現状ではdataviewで各要素にランダム値を設定する場合に使用
export function getRandomStringId() {
  return Math.random().toString(32).substring(2);
}
