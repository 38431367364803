import React, { useCallback, useEffect, useMemo } from 'react';
import {
  AssetInfo,
  useCommentPane,
  useCommentPaneDispatch,
} from './CommentPaneProvider';
import './FileList.css';
import { IconButton } from '~/shared/components/parts/Button/IconButton';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getThreadListByResourceWithAsset } from './ApiUtil';
import { error } from '~/shared/components/parts/Toast/Toast';
import { useIntl } from 'react-intl';
import { GetMessage } from '~/shared/components/parts/Message/Message';
import { getExceptionMessage } from '~/shared/utils';

export interface FileListProps {
  onChangeFile?: (assetId: string, fileName: string) => void;
  resourceType: string;
}
export const FileList = ({ onChangeFile, resourceType }: FileListProps) => {
  const { assetSet, showOption, viewerInfo } = useCommentPane();
  const currentIndex = useMemo<number>(() => {
    return assetSet.assetList.findIndex(
      (item) => item.asset?.assetId === assetSet.activeAssetId
    );
  }, [assetSet.assetList, assetSet.activeAssetId]);
  const commentPaneDispatch = useCommentPaneDispatch();
  const intl = useIntl();

  const handleToggleFileList = (show: boolean) => {
    if (show) {
      commentPaneDispatch({ type: 'showFileList' });
    } else {
      commentPaneDispatch({ type: 'hideFileList' });
    }
  };

  const handlePrevFile = () => {
    handleChangeFile(assetSet.assetList[currentIndex - 1], resourceType);
  };

  const handleNextFile = () => {
    handleChangeFile(assetSet.assetList[currentIndex + 1], resourceType);
  };

  const handleChangeFile = useCallback(
    async (file: AssetInfo, resourceType: string) => {
      if (!file.asset?.assetId || !onChangeFile) {
        return;
      }
      try {
        const response = await getThreadListByResourceWithAsset(
          resourceType,
          file.resourceId,
          file.asset.assetId
        );

        commentPaneDispatch({
          type: 'changeActiveAsset',
          resourceId: file.resourceId,
          assetId: file.asset.assetId,
          threadList: response.items as mtechnavi.api.forum.IThread[],
        });

        onChangeFile(file.asset.assetId, file.asset.displayName ?? '');
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    },
    [intl, commentPaneDispatch, onChangeFile]
  );

  useEffect(() => {
    (async () => {
      if (!assetSet.activeAssetId && assetSet.assetList.length > 0) {
        await handleChangeFile(assetSet.assetList[0], resourceType);
      }
    })();
  }, [
    handleChangeFile,
    resourceType,
    assetSet.assetList,
    assetSet.activeAssetId,
  ]);

  return (
    <div className="FileList">
      <IconButton
        name="openMenu"
        iconType="files"
        onClick={() => handleToggleFileList(!showOption.isShowFileList)}
      />
      <div
        className={`list-container ${showOption.isShowFileList ? 'show' : ''}`}
        style={{ maxHeight: viewerInfo.height }}
      >
        <div className="header">
          <span>{GetMessage({ id: 'filename' })}</span>
          <div className="action">
            <IconButton
              name="prevFile"
              iconType="up"
              onClick={() => handlePrevFile()}
              disabled={currentIndex <= 0}
            />
            <IconButton
              name="nextFile"
              iconType="down"
              onClick={() => handleNextFile()}
              disabled={currentIndex >= assetSet.assetList.length - 1}
            />
          </div>
        </div>
        <ul
          className="list"
          style={{ height: `calc(${viewerInfo.height}px - 2.89rem)` }}
        >
          {assetSet.assetList.map((assetInfo, index) => {
            return (
              <li
                key={index}
                className={`item ${
                  assetInfo.asset?.assetId === assetSet.activeAssetId
                    ? 'selected'
                    : ''
                }`}
                onClick={() => handleChangeFile(assetInfo, resourceType)}
              >
                {assetInfo.asset?.displayName}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
