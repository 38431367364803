import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Toast } from '~/shared/components';
import {
  CommentableViewer as CommentableViewerUi,
  CommentResourceId,
} from '~/shared/components/ui';

export const CommentableViewer = () => {
  const [idList, setIdList] = useState<CommentResourceId[]>([]);
  const { hash } = useLocation();

  const resourceType = 'mtechnavi.api.estimation.EstimateRequest';

  useEffect(() => {
    let params: CommentResourceId[] = [];
    try {
      params = JSON.parse(
        decodeURI(hash).replace('#', '')
      ) as CommentResourceId[];
    } catch {
      console.error(`Invalid URL hash`);
    }
    setIdList(params);
  }, [hash]);

  return (
    <>
      <Container>
        <CommentableViewerUi
          resourceIdList={idList}
          resourceType={resourceType}
        />
      </Container>
      <Toast />
    </>
  );
};
