import { useState } from 'react';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
  error,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import { useIntl } from 'react-intl';
import { saveInitialLocalStorageData } from '~/shared/utils';
import { useAuth } from '~/shared/contexts/AuthProvider';

export interface SelectConnectingDialogProps {
  isOpen: boolean;
  onDecision: (v: string) => void;
  onCancel: () => void;
}

export const ConnectingTypeValues = ['workTask', 'survey', 'estimate'];

export const SelectConnectingDialog = (props: SelectConnectingDialogProps) => {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';

  const radios: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'workTask',
        prefixId: 'SelectConnectingDialog',
      }),
      value: ConnectingTypeValues[0],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'survey',
        prefixId: 'SelectConnectingDialog',
      }),
      value: ConnectingTypeValues[1],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'estimate',
        prefixId: 'SelectConnectingDialog',
      }),
      value: ConnectingTypeValues[2],
    },
  ];

  const [connecting, setConnecting] = useState<string>('');

  const handleCancell = () => {
    setConnecting('');
    props.onCancel();
  };

  const handleDecision = () => {
    if (!connecting) {
      error([GetMessageWithIntl(intl, { id: 'E0000093' })]);
      return;
    }
    saveInitialLocalStorageData('WT_LINKED_WORK_TASK', myEmail);
    saveInitialLocalStorageData('WT_LINKED_ESTIMATE', myEmail);
    saveInitialLocalStorageData('WT_LINKED_SURVEY', myEmail);
    props.onDecision(connecting);
    setConnecting('');
  };

  const elements = (
    <div className="select-connecting-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          {GetMessageWithIntl(intl, {
            id: 'workTask',
            prefixId: 'SelectConnectingDialogDescription',
          })}
        </p>
      </div>
      <div className="comment-area detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="connecting"
                value={connecting}
                items={radios}
                validateOption={{ required: true }}
                isVertical={true}
                onChangeState={setConnecting}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancell}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'search' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'SELECT_CONNECTING',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
