import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as SyncIcon } from '@material-design-icons/svg/outlined/sync.svg';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { IconButton } from '~/shared/components/parts/Button/IconButton';
import { error } from '~/shared/components/parts/Toast/Toast';
import { GetMessage } from '~/shared/components/parts/Message/Message';
import { deleteCommentByCommentId } from './ApiUtil';
import { ConfirmationDialog } from '../Dialog/ConfirmationDialog';
import './ThreadComment.css';
import { getDateFormat, getExceptionMessage } from '~/shared/utils';

export type ThreadComment = mtechnavi.api.forum.IComment & {
  companyName?: string;
};

export interface ThreadCommentProps {
  comment: ThreadComment;
  companies?: mtechnavi.api.company.ICompany[];
  unreadMarker?: sharelib.INameOption;
  refreshFunc?: () => Promise<void>;
}
export const ThreadComment = ({
  comment,
  companies,
  unreadMarker,
  refreshFunc,
}: ThreadCommentProps) => {
  const [isWorking, setWorking] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState<
    string | null | undefined
  >(null);
  const commentDeleteText = `-- ${GetMessage({
    id: 'COMMENT_DELETE_TEXT',
  })} --`;
  const deleteFileMessage = {
    id: 'C0000001',
    value: { $1: 'コメントの削除' },
  };
  const intl = useIntl();

  const handleToggleMarkAsRead = useCallback(
    async (
      comment: mtechnavi.api.forum.IComment,
      unreadMarker?: sharelib.INameOption
    ) => {
      if (!unreadMarker) {
        return;
      }
      setWorking(true);
      const request = {
        typeName: 'mtechnavi.api.forum.Comment',
        recordId: comment?.commentId,
        markers: [unreadMarker],
      };
      const isRead =
        comment?.markers?.findIndex(
          (mark) => mark.code === unreadMarker?.code
        ) === -1;
      try {
        if (isRead) {
          await window.App.services.forumService.setMarker(request);
          comment.markers = (comment?.markers || []).filter(
            (item) => item.code !== unreadMarker.code
          );
        } else {
          await window.App.services.forumService.unsetMarker(request);
          comment.markers = [...(comment?.markers || []), unreadMarker];
        }
        if (refreshFunc) {
          await refreshFunc();
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setWorking(false);
      }
    },
    [intl, refreshFunc]
  );

  const handleDeleteCommentConfirm = useCallback(
    (commentId?: string | null) => {
      setShowConfirm(true);
      setDeleteCommentId(commentId);
    },
    []
  );
  const handleCancelDeleteComment = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const handleDeleteComment = useCallback(
    async (commentId?: string | null) => {
      try {
        setShowConfirm(false);
        setWorking(true);
        await deleteCommentByCommentId(commentId, commentDeleteText);
        if (refreshFunc) {
          await refreshFunc();
        }
        setDeleteCommentId(null);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setWorking(false);
      }
    },
    [intl, commentDeleteText, refreshFunc]
  );

  return (
    <div className="ThreadComment">
      <p>{comment.text}</p>
      <div className="comment-info">
        <div className="action">
          {/* FIXME:5~8月の改善系に伴う修正 20204/04/23 現在 */}
          {/* {companies &&
            comment.contributor?.companyId === companies[0].companyId && (
              <IconButton
                name="delete"
                iconType="delete"
                buttonType="danger"
                disabled={isWorking || comment.text === commentDeleteText}
                onClick={() => handleDeleteCommentConfirm(comment.commentId)}
              />
            )} */}
          {unreadMarker &&
            (comment.markers?.find((mark) => mark.code === '0') ? (
              <IconButton
                name="read"
                buttonType="basic"
                iconType="unchecked"
                disabled={isWorking}
                onClick={() => handleToggleMarkAsRead(comment, unreadMarker)}
              />
            ) : (
              <IconButton
                name="read"
                buttonType="basic"
                iconType="checked"
                disabled={isWorking}
                onClick={() => handleToggleMarkAsRead(comment, unreadMarker)}
              />
            ))}

          {isWorking && <SyncIcon className="working-icon" />}
        </div>
        <div className="metadata">
          <span>
            {comment.companyName && `[${comment.companyName ?? ''}]`}
            {/* FIXME:5~8月の改善系に伴う修正 20204/04/23 現在 */}
            {/* {(comment.contributor?.staff?.displayNameLang &&
              comment.contributor?.staff?.displayNameLang[intl.locale]) ||
              ''} */}
          </span>
          <span>
            {getDateFormat(comment.contributedAt ?? '', 'YYYY/MM/DD HH:mm')}
          </span>
        </div>
      </div>

      <div className="comment-delete-dialog">
        <ConfirmationDialog
          isOpen={isShowConfirm}
          messageLabelId={deleteFileMessage}
          viewMessage={deleteFileMessage}
          onDecision={() => handleDeleteComment(deleteCommentId)}
          onCancel={() => handleCancelDeleteComment()}
        />
      </div>
    </div>
  );
};
