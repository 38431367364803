/**
 * 言語設定と辞書ファイルに関する処理
 */

import { ja } from '~/shared/language/ja/index';
import en from '~/shared/language/en.json';

type DictPath = 'common' | 'tenant';

async function getDictionaryData(
  dictPath: DictPath,
  language: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const src = `/resources/${dictPath}/language/${language}.json`;

  return await fetch(src, {
    method: 'GET',
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    const responseText = await blob.text();
    return JSON.parse(responseText);
  });
}

export const getLangCode = () => {
  return navigator.language?.split('-').at(0) || '';
};

export async function selectMessages(omitTenantDict?: boolean) {
  const langCode = getLangCode();
  let commonDict = {};
  let tenantDict = {};
  try {
    commonDict = await getDictionaryData('common', langCode);
    if (!omitTenantDict) {
      tenantDict = await getDictionaryData('tenant', langCode);
    }
  } catch (err) {
    console.error(err);
  }
  switch (langCode) {
    case 'en':
      return en;
    default:
    case 'ja': {
      const dict = { ...ja, ...commonDict, ...tenantDict };
      // メッセージ中の変数に `$` を使用できないので `_` に置き換える
      const replaceJa = JSON.stringify(dict).replaceAll('{$', '{_');
      return JSON.parse(replaceJa);
    }
  }
}
