import React, {
  PropsWithRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import Long from 'long';
import { GetMessageWithIntl, error } from '~/shared/components';
import { IconButton } from '~/shared/components/ui/Button';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';
import {
  BuildFormInputSize,
  BuildFormInputType,
  numberingItemAutoName,
  numberingTempId,
  TypeTempAutoNames,
} from './utils';
import {
  FormItemDialog,
  FormItemDialogRef,
  sizeOptions,
} from './FormItemDialog';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import { InputSelector } from './InputSelector';
import { Selectbox } from '~/shared/components/ui';
import {
  convertLongToString,
  convertLongToNumber,
} from '~/shared/utils/converter';
import './FormPageBuilder.css';
import { IFormSettingItem } from './';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface NumberingOptionProps {
  typeCheckList?: mtechnavi.api.form.IItemAutoNameManagement[];
  tempAutoNames?: TypeTempAutoNames;
  maxCounter?: number;
  onChangeTempAutoNames?: (formItems: TypeTempAutoNames) => void; //採番管理用
}

interface FormPageBuilderProps {
  formPageId: string;
  formItems: IFormSettingItem[];
  formAllItems?: IFormSettingItem[]; // フォーム内の他のページのitemを参照したい場合に使用
  onChange?: (formItems: IFormSettingItem[]) => void;
  numberingOption?: NumberingOptionProps;
  onChangePageHeaderRef?: (ref: React.RefObject<HTMLDivElement>) => void;
}
export const FormPageBuilder = (props: PropsWithRef<FormPageBuilderProps>) => {
  const intl = useIntl();
  // 削除 確認ダイアログ
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const deleteMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  const [targetFormItem, setTargetFormItem] = useState<IFormSettingItem>();
  const dialogRef = useRef<FormItemDialogRef>(null);
  const [formItems, setFormItems] = useState<IFormSettingItem[]>([]);
  const [isSuspendFormItemsChange, setSuspendFormItemsChange] =
    useState<boolean>(false);
  const addIcon = useMemo(() => getGenerallyIconElement('add'), []);
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    props.onChangePageHeaderRef && props.onChangePageHeaderRef(pageHeaderRef);

    // pageHeaderRef 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHeaderRef.current, props]);

  const updateFormItems = (items: IFormSettingItem[]) => {
    setFormItems(items);
    onChangeHandler(items);
  };

  const handleItemAdd = (size: BuildFormInputSize) => {
    if (formItems && formItems.length >= 50) {
      error([GetMessageWithIntl(intl, { id: 'E0000108' })]);
      return;
    }
    if (props.formAllItems && props.formAllItems.length >= 200) {
      error([GetMessageWithIntl(intl, { id: 'E0000114' })]);
      return;
    }

    // 採番処理
    const [itemAutoName, tempItemAutoNames] = numberingItemAutoName({
      typeCheckList: props.numberingOption?.typeCheckList,
      tempAutoNames: props.numberingOption?.tempAutoNames ?? { values: [] },
      maxCounter: props.numberingOption?.maxCounter,
      inputType: 'text',
    });
    props.numberingOption?.onChangeTempAutoNames &&
      props.numberingOption.onChangeTempAutoNames(tempItemAutoNames);

    updateFormItems([
      ...formItems,
      {
        baseFormId: '', //formID,
        baseFormSettingId: '', //formSettingId
        baseFormSettingItemId: numberingTempId(), // 永続化されるまでの仮ID
        formPageId: props.formPageId, //formPageId
        item: {
          itemAutoName: itemAutoName,
          itemSize: Long.fromNumber(size),
          inputType: 'text',
          displayName: GetMessageWithIntl(intl, {
            prefixId: 'FORM_BUILDER',
            id: 'defaultItemName',
          }),
        },
      },
    ]);
  };
  const handleChangeType = (
    id: string | null | undefined,
    inputType: string
  ) => {
    if (!inputType) {
      return;
    }
    if (['file', 'fileMultiple'].some((t) => t === inputType)) {
      // 同一フォーム内に自分以外にファイル系が１０個あればエラー
      const otherItems =
        props.formAllItems?.filter(
          (v) =>
            v.baseFormSettingItemId !== id &&
            ['file', 'fileMultiple'].some((type) => type === v.item?.inputType)
        ) ?? [];
      if (otherItems?.length >= 10) {
        error([GetMessageWithIntl(intl, { id: 'E0000110' })]);
        return;
      }
    }

    const alterFormItems = formItems.map((formItem) => {
      if (formItem.baseFormSettingItemId === id && formItem.item) {
        // 項目タイプを変更した時に項目タイプに依存する値をクリアする
        const alterItem: mtechnavi.api.form.IFormItem = {
          itemAutoName: formItem.item?.itemAutoName,
          inputType: inputType as BuildFormInputType,
          displayName: ['label', 'labelMultiLine', 'spacer'].includes(inputType)
            ? null
            : formItem.item.displayName,
          itemSize: ['textarea', 'labelMultiLine'].includes(inputType)
            ? Long.fromNumber(3)
            : formItem.item.itemSize,
          required: ['label', 'labelMultiLine', 'spacer'].includes(inputType)
            ? null
            : formItem.item.required,
          description: ['label', 'labelMultiLine', 'spacer'].includes(inputType)
            ? null
            : formItem.item.description,
        };
        formItem.item = alterItem;
      }
      return formItem;
    });
    updateFormItems(alterFormItems);

    // 選択肢未設定なら設定ダイアログを開く、をやってみる
    if (
      ['select', 'selectMultiple'].some((t) => t === inputType) ||
      ['file', 'fileMultiple'].some((t) => t === inputType)
    ) {
      handleEdit(id);
    }
  };

  const handleChangeSize = (id: string | null | undefined, size: string) => {
    if (!size) {
      return;
    }
    const alterFormItems = formItems.map((item) => {
      if (item.baseFormSettingItemId === id && item.item) {
        item.item.itemSize = Long.fromString(size);
      }
      return item;
    });
    updateFormItems(alterFormItems);
  };

  const handleMovePrev = (id: string | null | undefined) => {
    const alterFormItems = [...formItems];
    const targetIndex = alterFormItems.findIndex(
      (item) => item.baseFormSettingItemId === id
    );
    const targetItem = alterFormItems.splice(targetIndex, 1);
    alterFormItems.splice(targetIndex - 1, 0, targetItem[0]);
    updateFormItems(alterFormItems);
  };
  const handleMoveNext = (id: string | null | undefined) => {
    const alterFormItems = [...formItems];
    const targetIndex = alterFormItems.findIndex(
      (item) => item.baseFormSettingItemId === id
    );
    const targetItem = alterFormItems.splice(targetIndex, 1);
    alterFormItems.splice(targetIndex + 1, 0, targetItem[0]);
    updateFormItems(alterFormItems);
  };

  const handleDelete = (id: string | null | undefined) => {
    updateFormItems(
      formItems.filter((item) => item.baseFormSettingItemId !== id)
    );
    setDeleteConfirmOpen(false);
  };

  const handleEdit = (id: string | null | undefined) => {
    const target = formItems.find((item) => item.baseFormSettingItemId === id);
    if (!target) {
      return;
    }
    dialogRef.current?.open(
      target,
      props.formAllItems ?? [],
      props.numberingOption?.typeCheckList ?? []
    );
  };

  const handleChangeItemSetting = (changedItem: IFormSettingItem) => {
    const alterFormItems = formItems.map((item) => {
      if (item.baseFormSettingItemId === changedItem.baseFormSettingItemId) {
        return changedItem;
      }
      return item;
    });
    updateFormItems(alterFormItems);

    // 採番管理
    const tempItems = props.numberingOption?.tempAutoNames ?? { values: [] };
    tempItems.values.push({
      prefix: 'c',
      counter: 0,
      displayName: changedItem.item?.itemAutoName ?? '',
    });
    props.numberingOption?.onChangeTempAutoNames &&
      props.numberingOption.onChangeTempAutoNames(tempItems);
  };

  const onChangeHandler = (formItems: IFormSettingItem[]) => {
    if (props.onChange) {
      props.onChange(
        formItems.map((formItem, index) => ({
          ...formItem,
          item: {
            ...formItem.item,
            sort: Long.fromNumber(index + 1),
          },
        }))
      );
    }
  };

  useEffect(() => {
    if (isSuspendFormItemsChange) {
      return;
    }
    if (!props.formItems.length) {
      return;
    }

    setFormItems(
      props.formItems.sort((a, b) => {
        const s = convertLongToNumber(a.item?.sort) ?? 0;
        const e = convertLongToNumber(b.item?.sort) ?? 0;
        return s > e ? 1 : -1;
      })
    );
    setSuspendFormItemsChange(true);
    // props.formItems変更時（初回）だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formItems]);

  return (
    <div className="FormPageBuilder">
      <div className="form-page-header" ref={pageHeaderRef} />
      <div className="form-area">
        {formItems.map((item, index) => (
          <div
            key={item.baseFormSettingItemId}
            className={`form-item form-item-size-${
              !['textarea', 'labelMultiLine'].includes(
                item.item?.inputType ?? ''
              )
                ? item.item
                  ? convertLongToString(item.item.itemSize)
                  : ''
                : 'textarea'
            }`}
          >
            <div className="form-item-header">
              <span className="form-id">
                id:{item.item?.itemAutoName ?? ''}{' '}
              </span>
              <IconButton
                name="edit"
                iconType="edit"
                onClick={() => handleEdit(item.baseFormSettingItemId)}
              />
              <IconButton
                name="clear"
                iconType="clear"
                onClick={() => {
                  setTargetFormItem(item);
                  setDeleteConfirmOpen(true);
                }}
              />
            </div>
            <InputSelector
              item={item}
              onChangeState={(v) =>
                handleChangeType(
                  item.baseFormSettingItemId,
                  v.length > 0 ? v[0] : ''
                )
              }
            />
            <ul className="form-item-control">
              <li>
                <IconButton
                  name="prev"
                  iconType="prev"
                  onClick={() => handleMovePrev(item.baseFormSettingItemId)}
                  disabled={index === 0}
                />
              </li>
              {!['textarea', 'labelMultiLine'].includes(
                item.item?.inputType ?? ''
              ) && (
                <li>
                  <Selectbox
                    name="size"
                    className="size-select"
                    columns={['size']}
                    labelId="FORM_BUILDER.size"
                    items={sizeOptions}
                    value={`${convertLongToString(item.item?.itemSize)}`}
                    onChangeState={(v) =>
                      handleChangeSize(
                        item.baseFormSettingItemId,
                        v.length > 0 ? v[0] : ''
                      )
                    }
                  />
                </li>
              )}
              <li>
                <IconButton
                  name="next"
                  iconType="next"
                  onClick={() => handleMoveNext(item.baseFormSettingItemId)}
                  disabled={index === formItems.length - 1}
                />
              </li>
            </ul>
          </div>
        ))}
        <button className="form-item-add" onClick={() => handleItemAdd(1)}>
          {addIcon} 項目追加
        </button>
      </div>
      <FormItemDialog
        ref={dialogRef}
        onDecision={handleChangeItemSetting}
      ></FormItemDialog>
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={deleteMessage}
        onDecision={() => {
          handleDelete(targetFormItem?.baseFormSettingItemId);
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
    </div>
  );
};
