import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  Checkbox as CheckboxComponent,
  CheckboxItem,
  CheckboxValidateOption,
} from '~/shared/components/parts/Checkbox/Checkbox';
import { getProperty } from '../common';
import './Checkbox.css';

export interface CheckboxProps {
  name: string;
  className?: string;
  labelId?: string;
  items: CheckboxItem[];
  value?: string | string[];
  disabled?: boolean;
  validator?: (v: string[]) => string[];
  validateOption?: CheckboxValidateOption;
  properties?: Array<Property>;
  columns?: Array<string>;
  onChangeState?: (arg: string[]) => void;
  workingBlur?: Date;
}

export function Checkbox(props: CheckboxProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      {props.labelId && (
        <div className={'CheckboxUI group-header'}>
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label-checkbox ${
                disabled ? 'disabled-topping-label-checkbox' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        </div>
      )}
      <CheckboxComponent
        name={props.name}
        className={props.className}
        items={props.items}
        value={props.value}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
        workingBlur={props.workingBlur}
      ></CheckboxComponent>
    </div>
  );
}
