import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { NotificationSendTargetSettingDialog } from '~/shared/components/ui/Dialog/NotificationSendTargetSettingDialog';

export const UISampleNotificationSendTargetSettingDialogs = () => {
  const [result, setResult] = useState('');
  const [isShow, setIsShow] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">通知メール送信対象設定ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setIsShow(true);
              }}
            />
            <NotificationSendTargetSettingDialog
              isOpen={isShow}
              messageOption={{
                headerLabelId: {
                  id: 'NOTIFICATION_SEND_TARGET_SETTING',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onDecision={() => {
                setResult('確認しました');
                setIsShow(false);
              }}
              onCancel={() => {
                setResult('キャンセル');
                setIsShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
