import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  Select,
  SelectItem,
  SelectValidateOption,
} from '~/shared/components/parts/Select/Select';
import { getProperty } from '../common';

export interface SelectProps {
  name: string;
  className?: string;
  labelId?: string;
  items: SelectItem[];
  value?: string[] | string;
  disabled?: boolean;
  validator?: (v: string[]) => string[];
  validateOption?: SelectValidateOption;
  multiple?: boolean;
  onChangeState?: (arg: string[]) => void;
  properties?: Array<Property>;
  columns?: Array<string>;
}

export function Selectbox(props: SelectProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      <span className={props.labelId === '' ? 'caption no-label' : 'caption'}>
        {props.labelId && props.labelId !== '' && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <Select
        name={props.name}
        className={props.className}
        items={props.items}
        value={props.value}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        multiple={props.multiple}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></Select>
    </div>
  );
}
