/**
 * 他への移動が望ましそうなもの
 */

import Long from 'long';

// FIXME: components/parts に移動すべき
// input等のエラー時の枠線用クラスを返却
export function getErrorBorderClassName(message: string[]) {
  return message.length > 0 ? 'basic-error-input-border' : '';
}

// FIXME: supplierBase/utils に移動すべき
// 依頼通知番号を取得
export function getSurveyRequestAutoName(
  autoName: Long.Long | null | undefined,
  detailAutoName: Long.Long | null | undefined
) {
  const convertAutoName = autoName ? Long.fromValue(autoName).toNumber() : '';
  const convertDetailAutoName = detailAutoName
    ? Long.fromValue(detailAutoName).toNumber()
    : '';

  return `${convertAutoName}-${convertDetailAutoName}`;
}
