import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton } from '../Button';
import { useEffect, useState } from 'react';
import { DateYM } from '../Dates';
import { error } from '~/shared/components/parts/Toast/Toast';

export interface EditPeriodDialogInputOption {
  periodStartDt: Date | null;
  periodEndDt: Date | null;
}
export interface EditPeriodDialogProps {
  period: EditPeriodDialogInputOption;
  isOpen: boolean;
  onCancel?: () => void;
  onDecision?: (v: EditPeriodDialogInputOption) => void;
}

export const EditPeriodDialog = (props: EditPeriodDialogProps) => {
  const intl = useIntl();

  const [periodStartDt, setPeriodStartDt] = useState<Date | null>(new Date());

  useEffect(() => {
    setPeriodStartDt(props.period.periodStartDt);
  }, [props.isOpen, props.period.periodStartDt]);

  const handleCancel = () => {
    formReset();
    props.onCancel && props.onCancel();
  };

  const handleDecision = () => {
    if (!periodStartDt) {
      return;
    }
    if (isValidationError()) {
      return;
    }
    const result: EditPeriodDialogInputOption = {
      periodStartDt,
      periodEndDt: props.period.periodEndDt,
    };
    formReset();
    props.onDecision && props.onDecision(result);
  };

  const formReset = () => {
    setPeriodStartDt(null);
  };

  const isValidationError = () => {
    if (!periodStartDt || !props.period.periodEndDt) {
      error([GetMessageWithIntl(intl, { id: 'E0000003' })]);
      return true;
    }
    if (periodStartDt.getTime() >= props.period.periodEndDt.getTime()) {
      error([GetMessageWithIntl(intl, { id: 'E0000105' })]);
      return true;
    }
  };

  const elements = (
    <div className="financial-registration-dialog">
      <div className="detail-area">
        <div className="contents-box">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <DateYM
                  name="periodStartDt"
                  term={20}
                  onChangeState={setPeriodStartDt}
                  columns={['periodStartDt']}
                  labelId="EditPeriodDialog.periodStartDt"
                  value={periodStartDt ?? undefined}
                ></DateYM>
              </div>
              <div className="w-50">
                <DateYM
                  name="periodEndDt"
                  term={20}
                  columns={['periodEndDt']}
                  labelId="EditPeriodDialog.periodEndDt"
                  disabled={true}
                  value={props.period.periodEndDt ?? undefined}
                ></DateYM>
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: 'cancel',
            })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: 'decision',
            })}
            onClick={handleDecision}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'EDIT_PERIOD',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };
  return <ModalDialogComponent {...openModalProps} />;
};
