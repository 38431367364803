import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { GetMessage } from '../../parts/Message/Message';
import { Textbox } from '../Textbox';
import './base.css';
import './BusinessUnitDetailDialog.css';

export interface BusinessUnitDetailDialogInputOption {
  clientCompanyId?: string;
  clientCompanyName?: string;
  clientStaffName?: string;
  clientStaffEmail?: string;
  clientAssistantName?: string;
  clientAssistantEmail?: string;
  selfCompanyDepartment?: string;
  selfStaffName?: string;
}

export interface BusinessUnitDetailDialogProps {
  isOpen: boolean;
  inputOption: BusinessUnitDetailDialogInputOption;
  onCancel: () => void;
}

export const BusinessUnitDetailDialog = ({
  isOpen,
  inputOption,
  onCancel,
}: BusinessUnitDetailDialogProps) => {
  const elements = (
    <div className="modal-area-container">
      <div className="description-area">
        <div className="client-name">
          {inputOption.clientCompanyId}：{inputOption.clientCompanyName}
        </div>
        <div className="client-staffs">
          <p>
            {GetMessage({ id: 'BusinessUnitDetailDialog.clientStaff' })}：
            {inputOption.clientStaffName} ({inputOption.clientStaffEmail})
          </p>
          <p>
            {GetMessage({ id: 'BusinessUnitDetailDialog.clientAssistant' })}：
            {inputOption.clientAssistantName} (
            {inputOption.clientAssistantEmail})
          </p>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <Textbox
                name="selfCompanyStaff"
                className="selfCompanyStaff"
                labelId="BusinessUnitDetailDialog.selfCompanyStaff"
                type="text"
                columns={['selfCompanyStaff']}
                value={inputOption.selfStaffName}
                disabled={true}
              />
            </div>
            <div className="w-50">
              <Textbox
                name="selfCompanyDepartment"
                className="selfCompanyDepartment"
                labelId="BusinessUnitDetailDialog.selfCompanyDepartment"
                type="text"
                columns={['selfCompanyDepartment']}
                value={inputOption.selfCompanyDepartment}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessage({
            prefixId: 'DIALOG_CAPTION',
            id: 'return',
          })}
          onClick={() => onCancel()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'client_detail',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="BusinessUnitDetailDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
