import { IconButton } from '~/shared/components/parts/Button/IconButton';
import { GroupTreeItem } from './util';

export interface GroupTreeEditActions<T> {
  onDetail?: (data: T | undefined) => void;
  onEdit?: (data: T | undefined) => void;
  onDelete?: (data: T | undefined) => void;
  onEditMember?: (data: T | undefined) => void;
  onAddChild?: (data: T | undefined) => void;
}

export interface GroupTreeEditPaneProps<T> {
  data: GroupTreeItem<T> | undefined;
  editActions: GroupTreeEditActions<T>;
  isCanDelete?: boolean;
  isDisabled?: boolean;
}

/**
 * ツリー階層編集パネル
 */
export const GroupTreeEditPane = <T,>({
  data,
  editActions,
  isCanDelete,
  isDisabled,
}: GroupTreeEditPaneProps<T>) => {
  const { onDetail, onEdit, onDelete, onEditMember, onAddChild } = editActions;

  const handleDetail = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    e && e.stopPropagation();
    onDetail && onDetail(data?.originItem);
  };
  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    e && e.stopPropagation();
    onEdit && onEdit(data?.originItem);
  };
  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    e && e.stopPropagation();
    onDelete && onDelete(data?.originItem);
  };
  const handleEditMember = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    e && e.stopPropagation();
    onEditMember && onEditMember(data?.originItem);
  };
  const handleAddChild = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    e && e.stopPropagation();
    onAddChild && onAddChild(data?.originItem);
  };
  return (
    <div className="GroupTreeEditPane">
      {onDetail && (
        <IconButton
          name="detail"
          buttonType={isDisabled ? 'sub' : 'basic'}
          iconType="description"
          onClick={handleDetail}
        />
      )}
      {onEdit && (
        <IconButton
          name="edit"
          buttonType={isDisabled ? 'sub' : 'basic'}
          iconType="edit"
          onClick={handleEdit}
        />
      )}
      {isCanDelete && onDelete && (
        <IconButton
          name="delete"
          buttonType={isDisabled ? 'sub' : 'basic'}
          iconType="delete"
          onClick={handleDelete}
        />
      )}
      {onEditMember && (
        <IconButton
          name="add_user"
          buttonType={isDisabled ? 'sub' : 'basic'}
          iconType="add_user"
          onClick={handleEditMember}
        />
      )}
      {onAddChild && (
        <IconButton
          name="add"
          buttonType={isDisabled ? 'sub' : 'basic'}
          iconType="add"
          onClick={handleAddChild}
        />
      )}
    </div>
  );
};
