import { useState, useEffect, useRef } from 'react';
import { Container, error, Toast } from '~/shared/components';
import {
  ListView,
  Preset,
  MenuActionItem,
  ViewMenu,
  Property,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
  getJoinedColumnSchema,
} from '~/shared/components/ui';
import { PresetItem } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { AggregateStage } from '~/worker';
import {
  PageState,
  getExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListSurveyReceptionContents,
  FullMethodName_ListUserAttributes,
} from '~/shared/utils';
const VIEW_ID = 'SURVEY_REQUEST_RECEIVER_LIST';

type SurveyReception = mtechnavi.api.survey.SurveyReception;

export function SurveyRequestReceiverList() {
  const intl = useIntl();
  const navi = useNavigate();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  const surveyReceptionList = useRef<SurveyReception[]>([]);

  const FieldName_MergedUserAttributes = 'mergedUserAttributes';
  const aggregateStages: AggregateStage[] = [
    {
      $lookup: {
        from: FullMethodName_ListUserAttributes,
        localField: 'surveyRequest.requestUnit.mainContact.email',
        foreignField: 'user.email',
        as: FieldName_MergedUserAttributes,
        option: {
          useQuery: false, // リロードしない
        },
      },
    },
  ];
  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListSurveyReceptionContents,
            FullMethodName_ListUserAttributes,
          ]);
        const listSurveyReceptionContentsSchemas = schemas[0]!;
        const listUserAttributesSchemas = schemas[1]!;

        const mergedUserAttributesSchemas = listUserAttributesSchemas.map(
          (v) => {
            return {
              name: `${FieldName_MergedUserAttributes}.${v.name}`,
              typeName: v.typeName,
            };
          }
        );

        // ja表示のみする処理
        const jaColumn = ['status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(
          [
            ...listSurveyReceptionContentsSchemas,
            ...mergedUserAttributesSchemas,
          ],
          jaColumn,
          'ja'
        );

        // 日付変換する処理
        const dayCololmns = [
          'surveyReception.receiptedAt',
          'surveyReception.latestSendedAt',
        ];
        const dayFormatterSch = getDayFormetterDisplaySchema(
          formatterSch,
          dayCololmns,
          {
            dayOpts: { formatType: 'YYYY/MM/DD' },
          }
        );
        const accuracyDateColumn = ['replyDueDateDt'];
        const accuracyDateSch = getDayFormetterDisplaySchema(
          dayFormatterSch,
          accuracyDateColumn,
          {
            dayOpts: {
              formatType: 'YYYY/MM/DD',
              isAccuracy: true,
            },
          }
        );
        // 文字列結合したい処理
        const altColumns = [
          'surveyRequest.surveyRequestAutoName',
          'surveyRequest.surveyRequestDetailAutoName',
        ];
        const joinFormatterSch = getJoinedColumnSchema(
          accuracyDateSch,
          altColumns,
          '-'
        );
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(joinFormatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/supplier-base/survey-request-receiver-confirmation', {
      state,
    });
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    surveyReceptionList.current = items as unknown as SurveyReception[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <ListView
          pageInfo={{
            schema: schema,
            preset: preset,
            menuItem: setMenuActionItem(),
            menuTarget: 'surveyRequest.surveyRequestId',
            headerTitle: { viewId: VIEW_ID },
            presetItems: childrenPresetItem,
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isCheckbox: true,
              isListActionMenu: true,
            },
          }}
          fullMethodName={FullMethodName_ListSurveyReceptionContents}
          stateOption={{
            onOriginalItemState: onOriginalItem,
          }}
          filterItemOption={{
            aggregateStages: aggregateStages,
            isRequestBodyFilter: true,
          }}
        />
        <Toast />
      </Container>
    </>
  );
}
