import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import './CommentPaneContainer.css';
import {
  AssetInfo,
  useCommentPane,
  useCommentPaneDispatch,
} from './CommentPaneProvider';
import { FileList } from './FileList';
import { ThreadList } from './ThreadList';
import { sharelib } from '~/shared/libs/clientsdk';

interface CommentPaneContainerProps {
  fileList?: AssetInfo[];
  onChangeFile?: (assetId: string, fileName: string) => void;
  resourceType: string;
}
export const CommentPaneContainer = ({
  fileList,
  onChangeFile,
  resourceType,
  children,
}: PropsWithChildren<CommentPaneContainerProps>) => {
  const unreadMarker: sharelib.INameOption | undefined = useMemo(
    () =>
      window.App.services.ui
        .getNameOption('A0000039')
        .find((item) => item.code === '0'),
    []
  );
  const { showOption } = useCommentPane();
  const commentPaneDispatch = useCommentPaneDispatch();

  useEffect(() => {
    commentPaneDispatch({
      type: 'setFileList',
      fileList: fileList || [],
    });
  }, [commentPaneDispatch, fileList]);

  return (
    <div
      className={`CommentPaneContainer ${
        showOption.isShowCommentPane ? 'open-comment' : ''
      } ${showOption.isShowFileList ? 'open-file' : ''}`}
    >
      <FileList onChangeFile={onChangeFile} resourceType={resourceType} />
      {children}
      <ThreadList unreadMarker={unreadMarker} />
    </div>
  );
};
