import { useEffect, useRef, useState } from 'react';
import { Container, error } from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  MenuListView,
  Textbox,
  Textarea,
  ZipCode,
  SimplePageNavigation,
  Preset,
  Property,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import './BusinessunitConfirmation.css';

import { GetMessage } from '~/shared/components/parts/Message/Message';
import { PresetItem } from '~/shared/services';
import './CompanyConfirmation.css';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';

import { useAuth } from '~/shared/contexts/AuthProvider';
import { useIntl } from 'react-intl';
import {
  ViewId,
  PageState,
  getSortSettingFromLocalStorage,
  getPresetAndSchema,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

export type CompanyAndComponentAndBusiness =
  mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit;
export type CompanyAndComponentAndBusinessList =
  mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit[];
export type ComponentList = mtechnavi.api.company.ComponentUnit[];

const VIEW_ID: ViewId = 'MASTER_BUSINESS_UNIT_CONFIRMATION';

export function BusinessunitConfirmation() {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const [ccaItem, setCcaItem] =
    useState<mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit>();

  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const _location = useLocation();
  const location = _location.state as PageState;
  const [listSchema, setListSchema] = useState<Array<Property>>([]);
  const [listPreset, setListPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [listChildrenPresetItem, setListChildrenPresetItem] =
    useState<PresetItem[]>();

  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    confirmation: true,
    myself: true,
    area2: true,
  });

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator({
    fullMethodName: 'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      'company.companyId': { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('PUBLIC_COMPANY_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: 'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
      pageNumber: n,
    });
  };

  // 初回画面用データ
  useEffect(() => {
    (async () => {
      try {
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);

        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas } = await getPresetAndSchema(
          VIEW_ID,
          [
            'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
          ]
        );
        // ja表示のみする処理
        const jaColumn = ['componentUnit.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        setListChildrenPresetItem(childrenPresetItem);
        childrenPresetItem.forEach((item) => {
          if (item.name === 'ListPostView') {
            setListPreset({ filter: {}, propertyNames: item.columns ?? [] });
          }
        });
        setListSchema(formatterSch);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    dispatch({
      type: 'query',
      fullMethodName: 'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
    });

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit[];
    setCcaItem(items[0]);

    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  const handleAccordion = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <Container viewId={VIEW_ID}>
      <div className="BusinessunitConfirmation">
        <div className="header"></div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="status-box">
            <div className="status-result">
              <Textbox
                name="statusResult"
                className="field"
                disabled={true}
                type="text"
                value={
                  ccaItem?.company?.companyStatus?.status?.displayNameLang
                    ?.ja ?? ''
                }
                labelId="statusResult"
                columns={preset.columns}
              />
            </div>
          </div>
          <div className="business-unit-info">
            <div
              className={`input-blocktitle-outer ${
                accordionState.confirmation ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('confirmation')}
              >
                {GetMessage({ id: 'publicInformation' })}
              </h3>
            </div>
            {accordionState.confirmation && (
              <div className="company-body">
                <div className="inputfields">
                  <div className="field-block middle">
                    <Textbox
                      name="companyName"
                      className="field"
                      disabled={true}
                      value={ccaItem?.company?.displayNameLang?.ja ?? ''}
                      type="text"
                      labelId="companyName"
                      columns={preset.columns}
                    />
                    <Textbox
                      name="representativePhoneNumber"
                      disabled={true}
                      value={ccaItem?.company?.profile?.phoneNumber ?? ''}
                      type="text"
                      labelId="representativePhoneNumber"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="field-block middle">
                    <ZipCode
                      name="postCode"
                      disabled={true}
                      value={
                        ccaItem?.company?.profile?.address?.postalCode ?? ''
                      }
                      labelId="postCode"
                      columns={preset.columns}
                    />
                    <Textbox
                      name="address"
                      disabled={true}
                      value={
                        ccaItem?.company?.profile?.address?.addressLine ?? ''
                      }
                      type="text"
                      labelId="address"
                      columns={preset.columns}
                    />
                  </div>
                </div>
                <div className="inputfields textarea">
                  <Textarea
                    name="publicComment"
                    disabled={true}
                    value={ccaItem?.company?.profile?.publicComment ?? ''}
                    labelId="publicComment"
                    columns={preset.columns}
                  />
                </div>
              </div>
            )}
            <div
              className={`input-blocktitle-outer ${
                accordionState.myself ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('myself')}
              >
                {GetMessage({ id: 'managementInformation' })}
              </h3>
            </div>
            {accordionState.myself && (
              <div className="company-body">
                <div className="inputfields">
                  <div className="field-block middle">
                    <Textbox
                      name="selfManagedCode"
                      className="field"
                      disabled={true}
                      value={ccaItem?.businessUnit?.businessUnitCode ?? ''}
                      type="text"
                      labelId="selfManagedCode"
                      columns={preset.columns}
                    />
                    <Textbox
                      name="selfManagedDisplayName"
                      disabled={true}
                      value={ccaItem?.businessUnit?.displayName ?? ''}
                      type="text"
                      labelId="selfManagedDisplayName"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="field-block middle">
                    <Textbox
                      name="businessUnitCategory"
                      className="field"
                      disabled={true}
                      value={ccaItem?.businessUnit?.businessUnitCategory ?? ''}
                      type="text"
                      labelId="businessUnitCategory"
                      columns={preset.columns}
                    />
                  </div>
                </div>
                <div className="inputfields textarea">
                  <Textarea
                    name="remarksForInternalManagement"
                    disabled={true}
                    value={ccaItem?.businessUnit?.remarks ?? ''}
                    labelId="remarksForInternalManagement"
                    columns={preset.columns}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className={`input-blocktitle-outer ${
              accordionState.area2 ? '' : 'close'
            }`}
          >
            <h3
              className="input-blocktitle"
              onClick={() => handleAccordion('area2')}
            >
              {GetMessage({ id: 'transactionWindowInformationList' })}
            </h3>
          </div>
          {accordionState.area2 && (
            <MenuListView
              headerTitle={{ viewId: VIEW_ID }}
              fullMethodName={
                'FullMethodName' // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
              }
              schema={listSchema}
              preset={listPreset}
              presetItems={listChildrenPresetItem}
              listSkipType={{
                isAllActionMenu: true,
              }}
              initialFilterItems={{
                info: [
                  {
                    targetKey: 'company.companyId',
                    targetValue: ccaItem?.company?.companyId ?? '',
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
      <div className="footer" ref={footerRef}>
        <div className="footer-contents">
          <div className="blockbtn">
            <CaptionButton
              name="backBtn"
              caption="戻る"
              className="btn limit"
              properties={[
                {
                  name: 'backBtn',
                  propertyName: 'backBtn',
                  propertyValue: 'backBtn',
                },
              ]}
              onClick={() => navigate('/businessunit-public-companies-list')}
              buttonType="basic"
            />
          </div>
          <SimplePageNavigation
            pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
            handleMovePage={handleMovePage}
          />
        </div>
      </div>
    </Container>
  );
}
