import React, { useEffect, useRef, useState } from 'react';
import {
  AssetInfo,
  CommentPaneContainer,
  CommentPaneProvider,
  DragModeSelector,
  Selections,
} from '../CommentPane';
import { Viewer, ViewerRef } from './Viewer';
import { error } from '~/shared/components/parts/Toast/Toast';
import { useIntl } from 'react-intl';
import { getAssetInfoListByResourceIds } from '../CommentPane/ApiUtil';
import { getExceptionMessage } from '~/shared/utils';

export type CommentResourceId = [string, string[]];

export interface CommentableViewerProps {
  resourceIdList?: CommentResourceId[];
  resourceType: string;
}
export const CommentableViewer = ({
  resourceType,
  resourceIdList,
}: CommentableViewerProps) => {
  const viewerRef = useRef<ViewerRef>(null);
  const [previewAsset, setPreviewAsset] = useState({
    assetId: '',
    fileName: '',
  });
  const [fileList, setFileList] = useState<AssetInfo[]>([]);
  const intl = useIntl();

  const handleChangeFile = (assetId: string, fileName: string) => {
    setPreviewAsset({
      assetId: assetId,
      fileName: fileName,
    });
    viewerRef.current?.resetOffset();
  };

  useEffect(() => {
    try {
      (async () => {
        const assetInfoList = await getAssetInfoListByResourceIds(
          resourceIdList || []
        );
        setFileList(assetInfoList || []);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  }, [intl, resourceIdList]);

  return (
    <CommentPaneProvider>
      <CommentPaneContainer
        resourceType={resourceType}
        fileList={fileList}
        onChangeFile={handleChangeFile}
      >
        <Viewer
          assetId={previewAsset.assetId}
          fileName={previewAsset.fileName}
          controls={{
            downloading: true,
            pager: true,
            scaling: true,
            extraControls: <DragModeSelector viewerRef={viewerRef} />,
          }}
          ref={viewerRef}
          width="100%"
          height="100%"
        >
          <Selections />
        </Viewer>
      </CommentPaneContainer>
    </CommentPaneProvider>
  );
};
