import { IntlShape } from 'react-intl';
import type { AppWorker, FilterRequest } from '~/worker';
import * as Comlink from 'comlink';
import { Catalog, mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import preset from '../preset/preset.config.json';
import menuPath from '../menu/path.json';
import { GetMessageWithIntl } from '../components';
import {
  FullMethodName_ListComponentUnits,
  FullMethodName_ListPresetMenus,
  FullMethodName_ListPresets,
  FullMethodName_ListStaffAndComponentUnits,
} from '../utils';
import { TenantDebugMenu } from '../config';

export interface MenuItem {
  label?: string;
  icon?: string;
  pathname?: string;
  search?: Record<string, string>;
  permissions?: Array<string>;
  children?: Array<MenuItem>;
  viewId?: string;
}

export interface Property {
  name: string;
  propertyName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyValue: any;
}

export interface PresetItem {
  name: string;
  type?: string;
  columns?: Array<string>;
  property?: Array<Property>;
  children?: Array<PresetItem>;
}
export interface ButtonItem {
  label: string;
  is_visibillity?: boolean;
  is_disabled?: boolean;
}
export interface WorkerRequest {}

export type FileFormatName =
  | 'transactionUnit'
  | 'componentUnit'
  | 'businessUnit'
  | 'businessUnitManagement'
  | 'businessUnitManagementItemValue'
  | 'oldBusinessUnitManagement'
  | 'staff'
  | 'blueprint'
  | 'assetUnit'
  | 'estimateRequestPlanUnit'
  | 'order'
  | 'userAttribute'
  | 'userBelongsUserGroup'
  | 'userGroupAttachedRoles'
  | 'userGroupAllowedMenuItem'
  | 'masterProgramOpiton'
  | 'surveyRequest'
  | 'workTask'
  | 'estimateRequestPlans'
  | 'rankRequest'
  | 'rankReception'
  | 'businessUnitContacts'
  | 'businessUnitFinancials'
  | 'businessUnitFacilitiess'
  | 'businessUnitCertificates'
  | 'businessUnitStrengths'
  | 'businessUnitSkills';

export class UiService {
  worker: Comlink.Remote<AppWorker>;

  constructor(worker: Worker, private adminMode: boolean, lang: string) {
    this.worker = Comlink.wrap<AppWorker>(worker);
    this.worker.setLang(lang);
  }

  async getMenuItems(isDebug: boolean): Promise<Array<MenuItem>> {
    if (!!this.adminMode) {
      return await this.getAdminMenuItems();
    } else {
      return await this.getTenantMenuItems(isDebug);
    }
  }

  /**
   * カテゴリーで名称マスタを取得
   * @param {string} categoryName カテゴリー。
   */
  getProgramOption(
    categoryName: string
  ): mtechnavi.api.programoption.IProgramOption[] {
    return window.App.programoptions
      .filter((v) => {
        return v.categoryName === categoryName;
      })
      .sort((n1, n2) => {
        const n1Order = n1.order ?? 0;
        const n2Order = n2.order ?? 0;
        if (n1Order > n2Order) {
          return 1;
        }
        if (n1Order < n2Order) {
          return -1;
        }
        return 0;
      });
  }

  /**
   * programOptionIdで名称マスタを取得
   * @param {string} id programOption
   */
  getProgramOptionWithId(id: string) {
    const value = window.App.programoptions.find(
      (v) => v.programOptionId === id
    );
    if (!value) {
      return {};
    }

    return {
      categoryName: value.category,
      code: value.code,
      displayNameLang: value.displayNameLang,
      systemName: value.systemName,
    } as sharelib.INameOption;
  }

  /**
   * カテゴリーとコードで名称マスタを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} code ユーザーコード
   */
  getProgramOptionWithCode(
    categoryName: string,
    code: string
  ): mtechnavi.api.programoption.IProgramOption {
    return (
      window.App.programoptions.find((v) => {
        return v.categoryName === categoryName && v.code === code;
      }) ?? {}
    );
  }

  /**
   * カテゴリーでINameOptionを取得
   * @param {string} categoryName カテゴリーコード
   */
  getNameOption(categoryName: string): sharelib.INameOption[] {
    const opts = this.getProgramOption(categoryName);
    return opts.map((v) => this.toNameOption(v));
  }

  /**
   * カテゴリーとcodeでINameOptionを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} code ユーザーコード
   */
  getNameOptionWithCode(
    categoryName: string,
    code: string
  ): sharelib.INameOption {
    const opts = this.getProgramOptionWithCode(categoryName, code);

    return this.toNameOption(opts);
  }

  /**
   * カテゴリーとシステムコードで名称マスタを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} sysytemName システムコード
   */
  getProgramOptionWithSystemName(
    categoryName: string,
    systemName: string
  ): mtechnavi.api.programoption.IProgramOption[] {
    return window.App.programoptions
      .filter((v) => {
        return v.categoryName === categoryName && v.systemName === systemName;
      })
      .sort((n1, n2) => {
        const n1Order = n1.order ?? 0;
        const n2Order = n2.order ?? 0;
        if (n1Order > n2Order) {
          return 1;
        }
        if (n1Order < n2Order) {
          return -1;
        }
        return 0;
      });
  }

  /**
   * カテゴリーとシステムコードでINameOptionを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} sysytemName システムコード
   */
  getNameOptionWithSystemName(
    categoryName: string,
    systemName: string
  ): sharelib.INameOption[] {
    const opts = this.getProgramOptionWithSystemName(categoryName, systemName);
    return opts.map((v) => this.toNameOption(v));
  }

  private toNameOption(
    programOption: mtechnavi.api.programoption.IProgramOption
  ): sharelib.INameOption {
    return <sharelib.INameOption>{
      categoryName: programOption.categoryName,
      code: programOption.code,
      displayNameLang: programOption.displayNameLang,
      systemName: programOption.systemName,
    };
  }

  async getUserRoles(
    userGroupIds: string[] | null | undefined
  ): Promise<[Array<string>, Array<string>]> {
    const userGroups = await window.App.services.identity.listUserGroups({});
    const roles = await window.App.services.identity.listRoles({});
    const userRoleName: Array<string> = [];
    const permissions: string[] = [];
    (userGroupIds ?? []).map((value) => {
      const userGroup = userGroups.items.filter((item) => {
        return item.userGroupId === value;
      });

      roles.items.map((rl) => {
        userGroup.map((ug) => {
          if ((ug.roleNames ?? []).findIndex((nm) => nm === rl.roleName) >= 0) {
            userRoleName.push(rl.roleName ?? '');
            (rl.permissions ?? []).map((permission) => {
              if (permission !== '*/*' && permission) {
                const method = Catalog.lookupMethod(permission);
                if (method) permissions.push(method.name);
                // 管理者は全権限
              } else {
                const availableFullMethodNames = Catalog.services()
                  .flatMap((v) => v.methodsArray)
                  // 先頭1文字は "." がつくので、削除
                  .map((v) => v.fullName.substr(1))
                  // サービス名とメソッド名は "/" で区切る
                  .map((v) => v.replace(/\.(\w+)$/, '/$1'));

                availableFullMethodNames.map((fullMethodName) => {
                  const method = Catalog.lookupMethod(fullMethodName);
                  if (method) permissions.push(method.name);
                });
              }
            });
          }
        });
      });
    });
    return [userRoleName, permissions];
  }

  private async getAdminMenuItems(): Promise<Array<MenuItem>> {
    return [
      {
        label: 'Top',
        pathname: '/',
      },
      {
        label: 'テナント管理',
        pathname: '/tenant',
      },
    ];
  }

  private async getTenantMenuItems(
    debugMode: boolean
  ): Promise<Array<MenuItem>> {
    // debug用メニュー
    const debugMenus = debugMode ? TenantDebugMenu : [];

    // プリセットとメニュープリセットをワーカーから取得
    const listPresetsRes = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListPresets,
      filter: {},
      sort: [],
    })) as mtechnavi.api.uicontroller.ListPresetsResponse;
    const listPresetMenusRes = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListPresetMenus,
      filter: {},
      sort: [],
    })) as mtechnavi.api.uicontroller.ListPresetMenusResponse;
    const presets = listPresetsRes.items;
    const presetMenus = listPresetMenusRes.items;
    // parentPresetMenuIdが空のものがroot、そこからメニュー構成を辿る
    const rootMenu = presetMenus.find((v) => !v.parentPresetMenuId)?.container
      ?.childrenPresetMenuIds;

    const menuList: MenuItem[] =
      rootMenu
        ?.map((menu) => {
          // メニュー情報取得（サブメニューまで）
          return presetMenus.find((v) => v.presetMenuId === menu);
        })
        .map((menu): MenuItem => {
          // メニュー情報を設定
          return {
            label: menu?.container?.displayName ?? '',
            // サブメニュー情報取得及び設定
            children: menu?.container?.childrenPresetMenuIds
              ?.map((subMenu) => {
                // サブメニュー情報取得
                return presetMenus.find((v) => v.presetMenuId === subMenu);
              })
              ?.map((subMenu): MenuItem => {
                // メニューのアイテム情報を取得
                const preset = presets.find(
                  (v) => subMenu?.item?.presetId === v.presetId
                );

                // メニューアイテムを設定
                return {
                  label: preset?.displayName ?? '',
                  pathname: this.getMenuPathname(preset?.viewId ?? ''),
                  viewId: preset?.viewId ?? '',
                };
              }),
          };
        }) ?? [];

    return [
      // debug表示
      ...debugMenus,

      // APIから取得した情報
      ...menuList,
    ];
  }

  async getTargetPreset(target: string): Promise<PresetItem> {
    const allPresetItem = await this.getPresetItems();
    for (const preset of allPresetItem) {
      if (preset.name === target) {
        return preset;
      } else {
        if (preset.children) {
          for (const childrenPreset of preset.children) {
            if (target === childrenPreset.name) {
              return childrenPreset;
            }
          }
        }
      }
    }
    return { name: '' };
  }

  getTargetPresetItem(
    target: string,
    allPreset: Array<PresetItem>
  ): PresetItem | undefined {
    for (const preset of allPreset) {
      if (preset.name === target) {
        return preset;
      } else {
        if (preset.children) {
          for (const childrenPreset of preset.children) {
            if (target === childrenPreset.name) {
              return childrenPreset;
            }
          }
        }
      }
    }
    return;
  }

  async getPresetItems(): Promise<Array<PresetItem>> {
    return await preset;
  }

  // viewIDを指定してプリセットを取得
  /*
     presetの取得経路
     listPresets -> items.<viewId>.internalData
  */
  async getViewIdPreset(viewId: string) {
    // viewIDに属するpresetを取得するオプション作成
    const option: FilterRequest = {
      action: 'query',
      fullMethodName: FullMethodName_ListPresets,
      filter: { viewId: { $eq: viewId } },
      sort: [{ createdAt: 'asc' }],
    };

    // viewIDに属するpresetをMongoDB経由で取得
    const result = await window.App.services.ui.worker.filter(option);
    const viewIdPreset: mtechnavi.api.uicontroller.Preset =
      result.items.pop() as mtechnavi.api.uicontroller.Preset;
    const presetItem = viewIdPreset.internalData as unknown as PresetItem;

    return { presetItem, viewIdPreset };
  }

  // メニューアイテムのパス情報取得
  getMenuPathname(viewId: string) {
    // パス情報の読み込み
    const pathnameList = menuPath;
    const target = pathnameList.find((v) => v.viewId === viewId);
    if (target) {
      return target.path;
    } else {
      return '/not-found'; // pathnameの有無でカテゴリ表示を判断しているため現状は /dummy or /not-found とする
    }
  }

  // 各fileformatの取得
  async getFileFormat(name: FileFormatName, intl: IntlShape) {
    let result;
    switch (name) {
      case 'transactionUnit':
        result =
          await window.App.services.transactionUnitService.listTransactionUnitFormats(
            {}
          );
        break;

      case 'componentUnit':
        result =
          await window.App.services.componentunitService.listComponentUnitFormats(
            {}
          );
        break;

      case 'businessUnit':
        result =
          await window.App.services.businessunitService.listBusinessUnitFormats(
            {}
          );
        break;

      case 'businessUnitManagement':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitManagementFormats(
            {}
          );
        break;

      case 'businessUnitManagementItemValue':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitManagementItemValueFormats(
            {}
          );
        break;

      case 'oldBusinessUnitManagement':
        result =
          await window.App.services.businessUnitManagementService.listOldBusinessUnitManagementFormats(
            {}
          );
        break;

      case 'staff':
        result = await window.App.services.staffService.listStaffFormats({});
        break;

      case 'blueprint':
        result =
          await window.App.services.blueprintService.listBlueprintFormats({});
        break;

      case 'assetUnit':
        result =
          await window.App.services.assetInventory.listBlueprintPropertiesFormats(
            {}
          );
        break;

      case 'estimateRequestPlanUnit':
        result =
          await window.App.services.estimateSenderService.listEstimateRequestPlanFormats(
            {}
          );
        break;

      case 'order':
        result =
          await window.App.services.estimateSenderService.listOrderFormats({});
        break;

      case 'userAttribute':
        result =
          await window.App.services.tenantAdminService.listUserAttributeFormats(
            {}
          );
        break;

      case 'userBelongsUserGroup':
        result =
          await window.App.services.tenantAdminService.listUserBelongsUserGroupFormats(
            {}
          );
        break;

      case 'userGroupAttachedRoles':
        result =
          await window.App.services.tenantAdminService.listUserGroupAttachedRolesFormats(
            {}
          );
        break;

      case 'userGroupAllowedMenuItem':
        result =
          await window.App.services.tenantAdminService.listUserGroupAllowedMenuItemFormats(
            {}
          );
        break;

      case 'workTask':
        result = await window.App.services.workTaskService.listWorkTaskFormats(
          {}
        );
        break;

      case 'masterProgramOpiton':
        result =
          await window.App.services.programOptionService.listProgramOptionFormats(
            {}
          );
        break;

      case 'surveyRequest':
        result =
          await window.App.services.surveySenderService.listSurveyRequestFormats(
            {}
          );
        break;
      case 'estimateRequestPlans':
        result =
          await window.App.services.estimateSenderService.listEstimateRequestPlanFormats(
            {}
          );
        break;
      case 'rankRequest':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitRankRequestFormats(
            {}
          );
        break;
      case 'rankReception':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitRankReceptionFormats(
            {}
          );
        break;
      case 'businessUnitContacts':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitContactFormats(
            {}
          );
        break;
      case 'businessUnitFacilitiess':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitFacilitiesFormats(
            {}
          );
        break;
      case 'businessUnitCertificates':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitCertificateFormats(
            {}
          );
        break;
      case 'businessUnitFinancials':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitFinancialFormats(
            {}
          );
        break;
      case 'businessUnitSkills':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitSkillFormats(
            {}
          );
        break;
      default:
        result = { items: [] };
        break;
    }
    const items = result.items.pop() ?? {};
    (items.headerColumns ?? []).map((v) => {
      if (v.messageName) {
        v.displayName = GetMessageWithIntl(intl, { id: v.messageName });
      }
    });
    return items;
  }

  // ユーザの所属部門を取得
  async getUserOrganizationUnits(email: string) {
    const organizationUnits = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListStaffAndComponentUnits,
      filter: {
        $and: [
          { 'componentUnit.organizationUnit.systemName': { $eq: 'B02' } },
          { 'staff.email': { $eq: email } },
        ],
      },
      sort: [],
    })) as mtechnavi.api.company.ListStaffAndComponentUnitsResponse;
    return organizationUnits.items ?? [];
  }

  // 自社の部門を取得
  async getMyCompanyOrganizationUnits() {
    const organizationUnits = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListComponentUnits,
      filter: { 'organizationUnit.systemName': { $eq: 'B02' } },
      sort: [],
    })) as mtechnavi.api.company.ListComponentUnitsResponse;

    return organizationUnits.items ?? [];
  }

  // 自社の納入先を取得
  async getMyCompanyDeliveryPoints() {
    const deliveryPointUnits = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListComponentUnits,
      filter: { 'deliveryPoint.systemName': { $eq: 'B03' } },
      sort: [],
    })) as mtechnavi.api.company.ListComponentUnitsResponse;

    console.info('自社の納入先一覧:', deliveryPointUnits);

    return deliveryPointUnits.items ?? [];
  }
}
