import { useMemo, CSSProperties, useCallback } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  TextBox as TextboxComponent,
  InputType,
  TextBoxValidateOption,
} from '~/shared/components/parts/Textbox/TextBox';
import { getProperty } from '../common';

export interface TextboxProps {
  name: string;
  className?: string;
  labelId?: string;
  type: InputType;
  value?: string | null;
  placeholder?: string;
  disabled?: boolean;
  isCounter?: boolean;
  validator?: (v: string) => string[];
  formatter?: (v: string) => string;
  validateOption?: TextBoxValidateOption;
  properties?: Array<Property>;
  columns?: Array<string>;
  onChangeState?: (arg: string) => void;
}

export function Textbox(props: TextboxProps) {
  const { onChangeState } = props;
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;

  const onChange = useCallback(
    (v: string) => {
      onChangeState && onChangeState(v);
    },
    [onChangeState]
  );

  return (
    <div style={viewStyle}>
      <span className={props.labelId !== '' ? 'caption' : 'caption no-name'}>
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.labelId !== '' ? (
                <GetMessageComponent id={props.labelId} />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </span>
      <TextboxComponent
        name={props.name}
        className={props.className}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        isCounter={props.isCounter}
        validator={props.validator}
        validateOption={props.validateOption}
        formatter={props.formatter}
        disabled={disabled ? true : false}
        style={componentStyle}
        onChangeState={onChange}
      ></TextboxComponent>
    </div>
  );
}
