import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
} from '~/shared/components';
import {
  FullMethodName_listCompanys,
  ViewId,
  getExceptionMessage,
} from '~/shared/utils';
import {
  ViewMenu,
  ListViewPageSetting,
  MenuActionItem,
} from '~/shared/components/ui';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import {
  DataViewHeaderInfo,
  DataView,
} from '~/shared/components/ui/ListView/DataView';
import './CompanyList.css';
import {
  CompanySearchLocationState,
  getCompanySearchState,
  getConditionText,
  isCompanySearchLocationState,
  makeRequestFilter,
  saveCompanySearchState,
} from '../util';
import { useAuth } from '~/shared/contexts/AuthProvider';

interface ViewListData {
  companyId?: string | null;
  displayName?: string | null;
  region?: string | null;
}

const VIEW_ID: ViewId = 'POC_COMPANY_LIST';

const headerSetting: DataViewHeaderInfo[] = [
  { message: { viewId: VIEW_ID, id: 'displayName' }, width: '30%' },
  { message: { viewId: VIEW_ID, id: 'region' }, width: 'auto' },
];

export const CompanyList = () => {
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isOpenCondition, setOpenCondition] = useState(false);
  const [viewListData, setViewListData] = useState<ViewListData[]>();
  const [initialChecks, setInitialChecks] = useState<string[]>();
  const [initialCurrentPage, setInitialCurrentPage] = useState<number>();
  const [initialPageSize, setInitialPageSize] = useState<number>();
  const footerRef = useRef<HTMLDivElement>(null);

  const labelSearchCondition = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'search_condition',
  });
  const labelChangeCondition = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'change_condition',
  });

  const conditions = useMemo(() => {
    if (!myEmail) {
      return {};
    }
    if (isCompanySearchLocationState(locationState)) {
      return locationState.conditions || {};
    }

    const searchState = getCompanySearchState(VIEW_ID, myEmail);
    if (searchState?.conditions) {
      return searchState?.conditions;
    }
    return {};
  }, [locationState, myEmail]);

  // 検索条件の詳細表記
  const conditionText = useMemo(() => {
    return getConditionText(intl, conditions);
  }, [intl, conditions]);

  const pageRequest = useCallback(
    async (
      dataFilter: mtechnavi.api.pocsearchcompany.ListCompanysRequest.IDataFilter
    ) => {
      setLoading(true);
      try {
        const result = (await window.App.services.ui.worker.filter({
          fullMethodName: FullMethodName_listCompanys,
          action: 'reload',
          filter: {},
          sort: [],
          requestBody: {
            dataFilter,
          },
        })) as mtechnavi.api.pocsearchcompany.IListCompanysResponse;
        setViewListData(
          result.items?.map((item) => ({
            companyId: item.companyId,
            displayName: item.profile?.displayNameLangJa,
            region: item.profile?.address?.region?.displayNameLangJa,
          })) || []
        );
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [intl]
  );

  useEffect(() => {
    if (!myEmail) {
      return;
    }
    const searchState = getCompanySearchState(VIEW_ID, myEmail);
    setInitialChecks(searchState?.pageSetting?.checkedValues);
    if (!Number.isNaN(searchState?.pageSetting?.pageNumber)) {
      setInitialCurrentPage(Number(searchState?.pageSetting?.pageNumber));
    }
    if (!Number.isNaN(searchState?.pageSetting?.pageSize)) {
      setInitialPageSize(Number(searchState?.pageSetting?.pageSize));
    }
  }, [myEmail]);

  useEffect(() => {
    (async () => {
      await pageRequest(makeRequestFilter(conditions, intl));
    })();
  }, [conditions, intl, pageRequest]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 戻る
    menuItems.push({
      name: 'back',
      func: () => {
        handleChangeCondition();
      },
    });

    // 確認
    menuItems.push({
      name: 'description',
      func: (ids?: string[]) => {
        moveConfirmation(ids);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (ids?: string[]) => {
        moveConfirmation(ids);
      },
    });
    return menuItems;
  };

  const moveConfirmation = (ids?: string[]) => {
    if (!ids) {
      return;
    }
    const state: CompanySearchLocationState = {
      conditions,
      pageSetting: {
        ids,
      },
    };
    navigate('/company/confirmation', {
      state,
    });
  };

  // 検索条件表示の切替
  const handleToggleConditionView = () => {
    setOpenCondition(!isOpenCondition);
  };

  // 検索条件を変える
  const handleChangeCondition = () => {
    const state: CompanySearchLocationState = {
      conditions,
    };
    navigate('/company/search', {
      state,
    });
  };

  const handleChangeListState = useCallback(
    (state: ListViewPageSetting) => {
      if (!initialChecks) {
        return;
      }
      saveCompanySearchState(
        VIEW_ID,
        {
          pageSetting: {
            checkedValues: [...(state.checkedValues || [])],
            pageNumber: state.pageNumber,
            pageSize: state.pageSize,
          },
        },
        myEmail
      );
    },
    [myEmail, initialChecks]
  );

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="CompanyList">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <DataView
                  pageInfo={{
                    pageSizeOption: { pageSize: initialPageSize },
                    header: headerSetting,
                    data: viewListData,
                    menuItem: setMenuActionItem(),
                    menuTarget: 'companyId',
                    initialChecks,
                    initialCurrentPage,
                  }}
                  listSkipValue={{
                    isVisibleCheckbox: true,
                  }}
                  onChangeState={handleChangeListState}
                />
              </div>
            </div>
          </div>

          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="condition-container">
                <div className="item-group current-condition">
                  <span>{labelSearchCondition}</span>
                  <div
                    className={`conditions ${
                      isOpenCondition ? 'open' : 'close'
                    }`}
                  >
                    {conditionText}
                  </div>
                  <IconButton
                    className="toggleCondition"
                    name="toggleCondition"
                    iconType="down"
                    onClick={handleToggleConditionView}
                  />
                </div>
                <CaptionButton
                  buttonType="basic"
                  name="change-condition"
                  className="change-condition"
                  caption={labelChangeCondition}
                  onClick={handleChangeCondition}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Toast />
      {isLoading && <LoadingIcon />}
    </>
  );
};
