import React, { useEffect, useMemo, useRef, useState } from 'react';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { ForumThread } from './ForumThread';
import './Forum.css';
import { Textbox } from '../../ui';
import { IconButton } from '../../ui/Button';
import { ForumThreadForm } from './ForumThreadForm';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { useIntl } from 'react-intl';
import { useForum } from './useForum';
import {
  getCommentList,
  getMarkerList,
  getThreadList,
  getUsableBaseThreadId,
} from './utils/api';
import { ForumMaxThreadCount, ForumPrefixId as prefixId } from './utils/util';
import { error } from '../../parts/Toast/Toast';
import { getExceptionMessage, getWorkerExceptionMessage } from '~/shared/utils';

/** スクロールが必要な要素かどうかをチェック */
const isNeedScroll = (
  parent: HTMLDivElement,
  child: HTMLElement | null
): boolean => {
  if (!child) {
    return false;
  }
  const scrollThreshold = parent.getBoundingClientRect().height;
  return (
    parent.scrollTop + scrollThreshold >
      child.offsetTop + child.getBoundingClientRect().height ||
    parent.scrollTop + parent.getBoundingClientRect().height - scrollThreshold <
      child.offsetTop
  );
};

/** 未読のスレッド ID を抽出する処理 */
const extractUnreadThreadIdList = (args: {
  markerList: mtechnavi.api.forum.IMarker[];
  threadList: mtechnavi.api.forum.IThread[];
  unreadMarker?: sharelib.INameOption;
}) => {
  const { markerList, threadList, unreadMarker } = args;
  // 未読のコメントを含むスレッドID
  const unreadThreadIdList = markerList
    .filter((marker) =>
      marker.markers?.some(
        (mark) =>
          mark.categoryName === unreadMarker?.categoryName &&
          mark.systemName === unreadMarker?.systemName
      )
    )
    .map((marker) => marker.threadId)
    .filter((threadId) => !!threadId);
  // スレッドIDを一意にする
  const uniqueUnreadThreadIdList = Array.from(new Set(unreadThreadIdList));
  // スレッドの並び順に合わせるため、スレッドリストを加工して戻り値とする
  return threadList
    .filter((thread) => uniqueUnreadThreadIdList.includes(thread.threadId))
    .map((thread) => thread.threadId);
};

/**
 * フォーラム UI
 */
export const Forum = () => {
  const { typeName, resourceId, closeForum } = useForum();
  const intl = useIntl();
  const [threadList, setThreadList] = useState<mtechnavi.api.forum.IThread[]>(
    []
  );
  const [commentList, setCommentList] = useState<
    mtechnavi.api.forum.IComment[]
  >([]);
  const [markerList, setMarkerList] = useState<mtechnavi.api.forum.IMarker[]>(
    []
  );
  const [filteredThreadIdList, setFilteredThreadIdList] = useState<string[]>(
    []
  );
  const [myCompanyId, setMyCompanyId] = useState<string>();
  const [baseThreadId, setBaseThreadId] = useState<string>();
  const [currentThreadId, setCurrentThreadId] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [isDraft, setDraft] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const isDisabled = isWorking || isLoading;

  const unreadMarker: sharelib.INameOption | undefined = useMemo(
    () =>
      window.App.services.ui
        .getNameOption('A0000039')
        .find((item) => item.systemName === 'B01'),
    []
  );

  // 未読コメントを含むスレッドID
  const unreadThreadIdList = useMemo(() => {
    return extractUnreadThreadIdList({
      markerList,
      threadList,
      unreadMarker,
    });
  }, [markerList, threadList, unreadMarker]);

  // スレッドの開閉
  const handleThreadOpen = (threadid: string) => {
    setCurrentThreadId(threadid);
  };
  const handleThreadClose = () => {
    setCurrentThreadId(null);
  };

  /** 検索ワード変更時 */
  const handleChangeSearchWord = (searchWord: string) => {
    if (!searchWord || threadList.length === 0) {
      setFilteredThreadIdList([]);
      return;
    }
    const matchThreadList = threadList.filter((item) =>
      (item.displayName || '').match(searchWord)
    );
    const matchCommentList = commentList.filter((item) =>
      (item.text || '').match(searchWord)
    );

    const matchThreadIdList = [
      ...matchThreadList.map((item) => item.threadId || ''),
      ...matchCommentList.map((item) => item.threadId || ''),
    ];
    const uniqueThreadIdList = Array.from(new Set(matchThreadIdList));

    setFilteredThreadIdList(uniqueThreadIdList);
  };

  /** 検索に一致するスレッドの表示 */
  const handleSearch = () => {
    if (filteredThreadIdList.length === 0) {
      return;
    }
    const currentIndex = filteredThreadIdList.findIndex(
      (threadId) => threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? filteredThreadIdList.at(0)
        : filteredThreadIdList.at(
            (currentIndex + 1) % filteredThreadIdList.length
          );
    setCurrentThreadId(threadId || null);
  };

  /** スレッドの追加 */
  const handleAddThread = () => {
    if (threadList.length >= ForumMaxThreadCount) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000137',
          value: { $1: ForumMaxThreadCount },
        }),
      ]);
      return;
    }
    setCurrentThreadId(null);
    setDraft(true);
  };
  const handleAddThreadCancel = () => {
    setDraft(false);
  };
  const handleAddThreadDecision = async (
    thread: mtechnavi.api.forum.IThread
  ) => {
    const result = (await window.App.services.ui.worker.apiCall({
      actionName: 'createForumThread',
      request: {
        thread,
        resourceReference: { typeName, resourceId },
      },
    })) as mtechnavi.api.forum.IThread[];

    const newThread = result?.at(0);
    if (!newThread) {
      return;
    }

    setThreadList([newThread, ...(threadList || [])]);
    setDraft(false);
    setCurrentThreadId(newThread.threadId || null);
  };

  /** スレッドの更新 */
  const handleUpdateThread = async (thread: mtechnavi.api.forum.IThread) => {
    const result = (await window.App.services.ui.worker.apiCall({
      actionName: 'updateForumThread',
      request: { thread },
    })) as mtechnavi.api.forum.IThread[];

    // 更新結果で該当スレッドの差し替え
    const newThread = result?.at(0);
    if (!newThread) {
      return;
    }
    const index = (threadList || []).findIndex(
      (thread) => thread.threadId === newThread.threadId
    );
    if (index < 0) {
      return;
    }
    const newList = [...threadList];
    newList.splice(index, 1, newThread);
    setThreadList(newList);
  };

  /** コメントの追加 */
  const handleSubmitComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = (await window.App.services.ui.worker.apiCall({
      actionName: 'createForumComment',
      request: { comment },
    })) as mtechnavi.api.forum.IComment[];

    const newComment = result?.at(0);
    if (!newComment) {
      return;
    }
    setCommentList([...(commentList || []), newComment]);
  };

  /** コメントの未読マーカーセット */
  const handleUnReadComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = (await window.App.services.ui.worker.apiCall({
      actionName: 'setForumMarker',
      request: {
        recordId: comment.commentId,
        markers: [unreadMarker || {}],
      },
    })) as mtechnavi.api.forum.ISetMarkerResponse[];

    // 更新結果でマーカーの差し替え
    replaceMarkerListItem(result?.at(0)?.marker);
  };

  /** コメントの未読マーカー解除 */
  const handleReadComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = (await window.App.services.ui.worker.apiCall({
      actionName: 'unsetForumMarker',
      request: {
        recordId: comment.commentId,
        markers: [unreadMarker || {}],
      },
    })) as mtechnavi.api.forum.IUnsetMarkerResponse[];

    // 更新結果でマーカーの差し替え
    replaceMarkerListItem(result?.at(0)?.marker);
  };

  /** マーカーの差し替え */
  const replaceMarkerListItem = (
    newMarker?: mtechnavi.api.forum.IMarker | null
  ) => {
    if (!newMarker) {
      return;
    }
    const index = (markerList || []).findIndex(
      (marker) => marker.markerId === newMarker.markerId
    );
    if (index < 0) {
      setMarkerList([...markerList, newMarker]);
      return;
    }
    const newList = [...markerList];
    newList.splice(index, 1, newMarker);
    setMarkerList(newList);
  };

  /** 全未読マーカー解除 */
  const handleReadAll = async () => {
    setWorking(true);
    try {
      const result = (await window.App.services.ui.worker.apiCall({
        actionName: 'unsetAllForumMarker',
        request: {
          recordIdList: markerList
            .map((marker) => marker.urn?.split(':')?.at(1) || '')
            .filter((id) => !!id),
          markers: [unreadMarker || {}],
        },
      })) as mtechnavi.api.forum.IUnsetMarkerResponse[][];

      const newMarkerList = result
        .flatMap((item) => item.map((item) => item.marker))
        .filter((item) => !!item) as mtechnavi.api.forum.IMarker[];
      setMarkerList(newMarkerList);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  /** コメントの削除 */
  const handleDeleteComment = async (comment: mtechnavi.api.forum.IComment) => {
    const { commentId, threadId, text, replyCommentId, updatedAt } = comment;
    const result = (await window.App.services.ui.worker.apiCall({
      actionName: 'updateForumComment',
      request: {
        comment: {
          commentId,
          threadId,
          text,
          replyCommentId,
          updatedAt,
        },
      },
    })) as mtechnavi.api.forum.IComment[];

    // 更新結果で該当コメントの差し替え
    const newComment = result?.at(0);
    if (!newComment) {
      return;
    }
    const index = commentList.findIndex(
      (comment) => comment.commentId === newComment.commentId
    );
    if (index < 0) {
      return;
    }
    const newList = [...commentList];
    newList.splice(index, 1, newComment);
    setCommentList(newList);
  };

  /** 一つ前のスレッドを表示 */
  const handlePrevThread = () => {
    if (threadList.length === 0) {
      return;
    }
    if (!currentThreadId) {
      setCurrentThreadId(
        threadList.at(threadList.length - 1)?.threadId || null
      );
      return;
    }
    const currentIndex = threadList.findIndex(
      (thread) => thread.threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? threadList.at(threadList.length - 1)?.threadId
        : threadList.at(currentIndex - 1)?.threadId;
    setCurrentThreadId(threadId || null);
  };

  /** 一つ後のスレッドを表示 */
  const handleNextThread = () => {
    if (threadList.length === 0) {
      return;
    }
    if (!currentThreadId) {
      setCurrentThreadId(threadList.at(0)?.threadId || null);
      return;
    }
    const currentIndex = threadList.findIndex(
      (thread) => thread.threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? threadList.at(0)?.threadId
        : threadList.at((currentIndex + 1) % threadList.length)?.threadId;
    setCurrentThreadId(threadId || null);
  };

  /** 未読のスレッドを表示 */
  const handleNextUnreadThread = () => {
    if (unreadThreadIdList.length === 0) {
      // 未読がなかったら何もしない
      return;
    }
    const currentIndex = unreadThreadIdList.findIndex(
      (threadId) => threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? unreadThreadIdList.at(0)
        : unreadThreadIdList.at((currentIndex + 1) % unreadThreadIdList.length);
    setCurrentThreadId(threadId || null);
  };

  useEffect(() => {
    (async () => {
      if (!typeName || !resourceId) {
        // 親スレッドを特定できる状況にないのでフォーラム UI を閉じる
        error([GetMessageWithIntl(intl, { id: 'E0000145' })]);
        closeForum();
        return;
      }
      try {
        const baseThreadId = await getUsableBaseThreadId({
          typeName,
          resourceId,
        });
        if (!baseThreadId) {
          // 親スレッドを取得できないのでフォーラム UI を閉じる
          error([GetMessageWithIntl(intl, { id: 'E0000145' })]);
          closeForum();
          return;
        }
        setBaseThreadId(baseThreadId);
        await handleRefresh(baseThreadId);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        closeForum();
        throw err;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeName, resourceId]);

  // 表示スレッドの切り替えによるスクロール位置調整
  useEffect(() => {
    if (!listRef.current) {
      return;
    }
    if (!currentThreadId) {
      listRef.current.scroll({ top: 0 });
      return;
    }
    const target = document.getElementById(currentThreadId);
    if (!isNeedScroll(listRef.current, target)) {
      return;
    }
    listRef.current.scroll({
      top: (target?.offsetTop || 0) - 9,
      behavior: 'smooth',
    });
  }, [currentThreadId]);

  /** 再取得 */
  const handleRefresh = async (baseThreadId?: string) => {
    setLoading(true);
    const [myCompany, threadList, commentList, markerList] = await Promise.all([
      window.App.services.companyService.getCompany({}),
      getThreadList(baseThreadId),
      getCommentList(baseThreadId),
      getMarkerList(baseThreadId),
    ]);
    setMyCompanyId(myCompany.companyId);
    setThreadList(threadList?.items || []);
    setCommentList(commentList?.items || []);
    setMarkerList(markerList?.items || []);
    setLoading(false);
  };

  return (
    <>
      {!!baseThreadId && (
        <div className="Forum">
          <div className="ForumHeader">
            <div className="search-box">
              <Textbox
                name="searchWord"
                className="search-word"
                type="text"
                columns={['searchWord']}
                labelId="searchWord"
                disabled={isDisabled}
                onChangeState={handleChangeSearchWord}
              />
              <IconButton
                name="search"
                className="search-button"
                iconType="search"
                buttonType="basic"
                disabled={isDisabled}
                onClick={handleSearch}
              />
            </div>
            <div className="action-box">
              <IconButton
                name="createThread"
                iconType="add"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'createThread',
                })}
                disabled={isDisabled}
                onClick={handleAddThread}
              />
              <hr />
              <IconButton
                name="readAll"
                iconType="read"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'readAll',
                })}
                disabled={isDisabled}
                onClick={handleReadAll}
              />
              <IconButton
                name="refresh"
                buttonType="basic"
                iconType="refresh"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'refresh',
                })}
                disabled={isDisabled}
                onClick={() => handleRefresh(baseThreadId)}
              />
              <hr />
              <IconButton
                name="prevThread"
                iconType="up"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'prevThread',
                })}
                disabled={isDisabled}
                onClick={handlePrevThread}
              />
              <IconButton
                name="nextThread"
                iconType="down"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'nextThread',
                })}
                disabled={isDisabled}
                onClick={handleNextThread}
              />
              <IconButton
                name="openUnread"
                iconType="unread"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'openUnread',
                })}
                disabled={isDisabled}
                onClick={handleNextUnreadThread}
              />
            </div>
          </div>
          {isDraft && (
            <ForumThreadForm
              onCancel={handleAddThreadCancel}
              onDecision={handleAddThreadDecision}
            />
          )}
          <div
            className={`ForumThreadList ${isLoading ? 'loading' : ''}`}
            ref={listRef}
          >
            {!isLoading &&
              threadList?.map((thread) => (
                <ForumThread
                  key={thread.threadId}
                  unreadMarker={unreadMarker}
                  myCompanyId={myCompanyId}
                  inputOption={{
                    thread,
                    commentList:
                      commentList?.filter(
                        (comment) => comment.threadId === thread.threadId
                      ) || [],
                    markerList:
                      markerList?.filter(
                        (marker) => marker.threadId === thread.threadId
                      ) || [],
                  }}
                  onThreadOpen={handleThreadOpen}
                  onThreadClose={handleThreadClose}
                  onEditThread={handleUpdateThread}
                  onSubmitComment={handleSubmitComment}
                  onReadComment={handleReadComment}
                  onUnReadComment={handleUnReadComment}
                  onDeleteComment={handleDeleteComment}
                  isOpen={thread.threadId === currentThreadId}
                  isParentWorking={isWorking}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};
