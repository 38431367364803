import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { EstEstimateRequestPlanList } from './pages/EstEstimateRequestPlanList';
import { EstEstimateRequestPlanConfirmation } from './pages/EstEstimateRequestPlanConfirmation';
import { EstEstimateRequestList } from './pages/EstEstimateRequestList';
import { EstEstimateRequestInput } from './pages/EstEstimateRequestInput';
import { EstEstimateRequestConfirmation } from './pages/EstEstimateRequestConfirmation';
import { EstEstimateRequestDetailList } from './pages/EstEstimateRequestDetailList';
import { EstEstimateSelectionApprovalList } from './pages/EstEstimateSelectionApprovalList';
import { EstEstimateResultList } from './pages/EstEstimateResultList';
import { EstEstimateResultConfirmation } from './pages/EstEstimateResultConfirmation';

export const EstimateRoutes = () => {
  return (
    <Routes>
      <Route
        path="/est-estimate-request-plan-list"
        element={
          <RequireAuth>
            <EstEstimateRequestPlanList />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-request-plan-confirmation/*"
        element={
          <RequireAuth>
            <EstEstimateRequestPlanConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-request-list"
        element={
          <RequireAuth>
            <EstEstimateRequestList />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-request-input"
        element={
          <RequireAuth>
            <EstEstimateRequestInput />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-request-confirmation/*"
        element={
          <RequireAuth>
            <EstEstimateRequestConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-request-detail-list"
        element={
          <RequireAuth>
            <EstEstimateRequestDetailList />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-request-approval-list"
        element={
          <RequireAuth>
            <EstEstimateRequestList />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-selection-approval-list"
        element={
          <RequireAuth>
            <EstEstimateSelectionApprovalList />
          </RequireAuth>
        }
      />

      <Route
        path="/est-estimate-result-list"
        element={
          <RequireAuth>
            <EstEstimateResultList />
          </RequireAuth>
        }
      />
      <Route
        path="/est-estimate-result-confirmation/*"
        element={
          <RequireAuth>
            <EstEstimateResultConfirmation />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
