import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Long from 'long';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import './AutoReminderSettingDialog.css';
import {
  Checkbox,
  DataFilterbox,
  DataFilterboxItem,
  Textarea,
  Textbox,
} from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getProgramOptionFilterboxData } from '~/shared/utils/converter';
import { includeInputValidateError } from '~/shared/utils';
import {
  validateOnlyNumber,
  validateOnlyNumberWithMaxMin,
} from '../../parts/validator';

export type DisplayMode = 'decision' | 'save' | 'display' | 'masterDecision';

export interface AutoReminderSettingDialogInputOption {
  deadline?: Date;
  displayModeType: DisplayMode;
  autoReminderSetting?: mtechnavi.api.survey.IAutoReminderSetting;
}

export interface AutoReminderSettingDialogOutputOption {
  autoReminderSetting: mtechnavi.api.survey.IAutoReminderSetting;
}
export interface AutoReminderSettingDialogProps {
  inputOption: AutoReminderSettingDialogInputOption;
  isOpen: boolean;
  onDecision: (v: AutoReminderSettingDialogOutputOption) => void;
  onCancel: () => void;
}

const MessageTypeCode = 'A1000003';
const InitialMessageCode = 'A1000004';
const BeforeAfterTypeCode = 'A1000006';
export const AutoReminderSettingDialog = (
  props: AutoReminderSettingDialogProps
) => {
  const intl = useIntl();
  const [messageTypes, initialMessage, beforeAfterTypes] = useMemo<
    [DataFilterboxItem[], DataFilterboxItem[], DataFilterboxItem[]]
  >(() => {
    const messageTypes = getProgramOptionFilterboxData(MessageTypeCode);
    const initialMessage = getProgramOptionFilterboxData(InitialMessageCode);
    const beforeAfterTypes = getProgramOptionFilterboxData(BeforeAfterTypeCode);
    return [messageTypes, initialMessage, beforeAfterTypes];
  }, []);
  const [reminderMessageType, setReminderMessageType] = useState<
    DataFilterboxItem[]
  >([]);
  const [reminderMessage, setReminderMessage] = useState<string>(
    initialMessage.length > 0 ? initialMessage[0].displayName : ''
  );
  const [nextReminderDate, setNextReminderDate] = useState<string>('');
  const [firstSite, setFirstSite] = useState<string>('');
  const [autoReminderCheck, setAutoReminderCheck] = useState<string[]>([]);
  const [recurrenceSite, setRecurrenceSite] = useState<string>('');
  const [recurrence, setRecurrence] = useState<string[]>([]);
  const [beforeOrAfter, setBeforeOrAfter] = useState<DataFilterboxItem[]>([]);
  const [sendTargetBusinessUnit, setSendTargetBusinessUnit] = useState<
    string[]
  >([]);
  const [sendTargetResponsibleUser, setSendTargetResponsibleUser] = useState<
    string[]
  >([]);
  const [sendTargetMainContact, setSendTargetMainContact] = useState<string[]>(
    []
  );
  const { isOpen, inputOption } = props;
  const [isDisabled, setDisabled] = useState<boolean>(false);

  // 入力チェック用
  const requiredFirstSiteArea = useRef(null);
  const requiredCommentArea = useRef(null);
  const requiredReccurenceArea = useRef(null);
  const [workingBlurBeforeOrAfter, setWorkingBlurBeforeOrAfter] =
    useState<Date>();

  const getClassNames = (): string => {
    let className = 'button-area';
    if (inputOption.displayModeType === 'display') {
      return className;
    }
    return (className = 'button-area top-space-4rem');
  };

  const handleChangeMessageType = (v: DataFilterboxItem[]) => {
    setReminderMessageType(v);
    if (v.length === 0) {
      setReminderMessage('');
      return;
    }
    const message = initialMessage.find((msg) => msg.value === v[0].value);
    if (!message) {
      setReminderMessage('');
      return;
    }
    setReminderMessage(message.displayName);
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const autoReminderSetting: mtechnavi.api.survey.IAutoReminderSetting = {};
    autoReminderSetting.autoReminder = autoReminderCheck.length > 0;
    if (autoReminderSetting.autoReminder) {
      autoReminderSetting.offset = Long.fromString(firstSite);
      autoReminderSetting.offsetType =
        window.App.services.ui.getNameOptionWithCode(
          BeforeAfterTypeCode,
          beforeOrAfter[0].value ?? ''
        );
      autoReminderSetting.contentType =
        window.App.services.ui.getNameOptionWithCode(
          MessageTypeCode,
          reminderMessageType.length ? reminderMessageType[0].value ?? '' : ''
        );
      autoReminderSetting.content = reminderMessage;
      autoReminderSetting.repeat = recurrence.length > 0;
      if (autoReminderSetting.repeat) {
        autoReminderSetting.repeatInterval = Long.fromString(recurrenceSite);
      }
      autoReminderSetting.sendTarget = {
        activeBusinessUnit:
          sendTargetBusinessUnit.length > 0 && sendTargetBusinessUnit[0] === '1'
            ? true
            : false,
        activeResponsibleUser:
          sendTargetResponsibleUser.length > 0 &&
          sendTargetResponsibleUser[0] === '1'
            ? true
            : false,
        activeMainContact:
          sendTargetMainContact.length > 0 && sendTargetMainContact[0] === '1'
            ? true
            : false,
      };
    }
    props.onDecision({ autoReminderSetting });
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const formReset = () => {
    setAutoReminderCheck([]);
    setReminderMessageType([]);
    setReminderMessage('');
    setNextReminderDate('');
    setFirstSite('');
    setBeforeOrAfter([]);
    setRecurrence([]);
    setRecurrenceSite('');
    setSendTargetBusinessUnit([]);
    setSendTargetResponsibleUser([]);
    setSendTargetMainContact([]);
  };

  const isInputError = (): boolean => {
    if (autoReminderCheck.length === 0) {
      return false;
    }
    setWorkingBlurBeforeOrAfter(new Date());

    const inputValidationCheckList = [
      { value: firstSite ?? '', ref: requiredFirstSiteArea },
      {
        value: reminderMessage ?? '',
        ref: requiredCommentArea,
      },
    ];
    if (recurrence.length) {
      inputValidationCheckList.push({
        value: recurrenceSite ?? '',
        ref: requiredReccurenceArea,
      });
    }

    const targetElm = document.querySelector('.auto-reminder-setting-dialog');
    if (
      includeInputValidateError(targetElm, intl, inputValidationCheckList) ||
      Number.isNaN(Number(firstSite)) ||
      (recurrence.length > 0 && Number.isNaN(Number(recurrenceSite))) ||
      beforeOrAfter.length === 0
    ) {
      return true;
    }
    return false;
  };

  const getNextReminderDate = useCallback(
    (ba: string, site: string): string => {
      if (!inputOption.deadline) {
        return '';
      }

      const deadline = dayjs(inputOption.deadline).startOf('day');
      const today = dayjs().startOf('day');

      let firstReminderDate = deadline;
      if (ba === '1') {
        firstReminderDate = deadline.add(Number(site), 'day');
      } else {
        firstReminderDate = deadline.subtract(Number(site), 'day');
      }
      if (!firstReminderDate.isBefore(today)) {
        return firstReminderDate.toDate().toLocaleDateString();
      }

      const recurrence = Number(recurrenceSite) || 0;
      if (recurrence <= 0) {
        return '';
      }
      const diffDays = today.diff(firstReminderDate, 'day');
      const nextReminderCount = Math.ceil(diffDays / recurrence);
      const nextReminderDate = firstReminderDate.add(
        recurrence * nextReminderCount,
        'day'
      );

      return nextReminderDate.toDate().toLocaleDateString();
    },
    [inputOption.deadline, recurrenceSite]
  );

  useEffect(() => {
    setDisabled(
      autoReminderCheck.length === 0 ||
        props.inputOption.displayModeType === 'display'
    );
  }, [autoReminderCheck, props.inputOption.displayModeType]);

  useEffect(() => {
    if (isDisabled && props.inputOption.displayModeType !== 'display') {
      setReminderMessageType([]);
      setReminderMessage('');
      setNextReminderDate('');
      setFirstSite('');
      setBeforeOrAfter([]);
      setRecurrence([]);
      setRecurrenceSite('');
      setSendTargetBusinessUnit([]);
      setSendTargetResponsibleUser([]);
      setSendTargetMainContact([]);
    }
  }, [initialMessage, isDisabled, props.inputOption.displayModeType]);

  useEffect(() => {
    if (recurrence.length === 0) {
      setRecurrenceSite('');
    }
  }, [recurrence]);

  // 次回の送信日を計算する
  useEffect(() => {
    if (!autoReminderCheck) {
      return;
    }
    if (
      !!firstSite &&
      !Number.isNaN(Number(firstSite)) &&
      beforeOrAfter.length > 0
    ) {
      setNextReminderDate(
        getNextReminderDate(beforeOrAfter[0].value, firstSite)
      );
    } else {
      setNextReminderDate('');
    }
  }, [getNextReminderDate, autoReminderCheck, firstSite, beforeOrAfter]);

  // 自動催促設定の有無によって、送信対象を変更する
  useEffect(() => {
    if (props.inputOption.displayModeType === 'display') {
      return;
    }
    if (autoReminderCheck.length > 0 && autoReminderCheck[0] === '1') {
      setSendTargetBusinessUnit(['1']);
      setSendTargetResponsibleUser(['1']);
    } else {
      setSendTargetBusinessUnit([]);
      setSendTargetResponsibleUser([]);
    }

    // autoReminderCheck 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoReminderCheck]);

  // フォームに初期値をセットする
  useEffect(() => {
    if (!props.isOpen || !props.inputOption.autoReminderSetting) {
      formReset();
      setAutoReminderCheck(
        ['masterDecision', 'decision'].includes(
          props.inputOption.displayModeType
        )
          ? ['1']
          : []
      );
      return;
    }
    const {
      autoReminder,
      offset,
      offsetType,
      contentType,
      content,
      repeat,
      repeatInterval,
      sendTarget,
    } = props.inputOption.autoReminderSetting;
    setAutoReminderCheck(autoReminder ? ['1'] : []);
    setFirstSite(autoReminder ? Long.fromValue(offset || 0).toString() : '');
    setBeforeOrAfter(
      beforeAfterTypes.filter((type) => type.value === offsetType?.code)
    );
    setReminderMessageType(
      messageTypes.filter((type) => type.value === contentType?.code)
    );
    setReminderMessage(content || '');
    setRecurrence(repeat ? ['1'] : []);
    setRecurrenceSite(
      repeatInterval ? Long.fromValue(repeatInterval).toString() : ''
    );
    if (autoReminder) {
      setSendTargetBusinessUnit(['1']);
      setSendTargetResponsibleUser(['1']);
    } else {
      setSendTargetBusinessUnit([]);
      setSendTargetResponsibleUser([]);
    }
    sendTarget?.activeMainContact
      ? setSendTargetMainContact(['1'])
      : setSendTargetMainContact([]);
  }, [
    props.isOpen,
    props.inputOption.autoReminderSetting,
    props.inputOption.displayModeType,
    beforeAfterTypes,
    messageTypes,
  ]);

  const elements = (
    <div className="auto-reminder-setting-dialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <Textbox
                name="nextScheduledRemindDate"
                labelId="autoReminderSettingDialog.next_scheduled_remind_date"
                columns={['nextScheduledRemindDate']}
                type="text"
                value={nextReminderDate}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'autoReminderSettingDialog',
                      id: 'auto_reminder',
                    }),
                  },
                ]}
                value={autoReminderCheck}
                onChangeState={setAutoReminderCheck}
                columns={['checkbox']}
                disabled={props.inputOption.displayModeType === 'display'}
              ></Checkbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25">
              <Textbox
                labelId="autoReminderSettingDialog.first_site"
                name="firstSite"
                columns={['firstSite']}
                type="text"
                value={firstSite}
                validateOption={{ required: true }}
                onChangeState={setFirstSite}
                disabled={isDisabled}
                validator={validateOnlyNumber(intl)}
              />
            </div>
            <div className="w-25 before-and-after-the-first-time-type-filterbox">
              <DataFilterbox
                data={beforeAfterTypes}
                searchOption={{ targets: 'displayName' }}
                name="beforeAndAftertheFirstTimeType"
                columns={['beforeAndAftertheFirstTimeType']}
                onChangeState={setBeforeOrAfter}
                validateOption={{ required: true }}
                value={beforeOrAfter}
                disabled={isDisabled}
                workingBlur={workingBlurBeforeOrAfter}
              ></DataFilterbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <DataFilterbox
                data={messageTypes}
                name="reminderMessageType"
                labelId="autoReminderSettingDialog.reminder_message_type"
                columns={['reminderMessageType']}
                searchOption={{ targets: 'displayName' }}
                onChangeState={handleChangeMessageType}
                value={reminderMessageType}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="comment"
                labelId="autoReminderSettingDialog.reminder_message"
                value={reminderMessage}
                columns={['comment']}
                validateOption={{ required: true }}
                className="w-100 mh-middle"
                onChangeState={setReminderMessage}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33 recurrence-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'autoReminderSettingDialog',
                      id: 'recurrence',
                    }),
                  },
                ]}
                value={recurrence}
                onChangeState={setRecurrence}
                columns={['checkbox']}
                disabled={isDisabled}
              ></Checkbox>
            </div>
            <div className="w-33">
              <Textbox
                name="recurrenceSite"
                labelId="autoReminderSettingDialog.recurrence_site"
                columns={['recurrenceSite']}
                type="text"
                value={recurrenceSite}
                validateOption={{ required: recurrence.length > 0 }}
                onChangeState={setRecurrenceSite}
                validator={validateOnlyNumberWithMaxMin(intl, 1)}
                disabled={isDisabled || recurrence.length === 0}
              />
            </div>
          </div>
        </div>
        <p className="send-target-label">
          {GetMessage({
            prefixId: 'autoReminderSettingDialog',
            id: 'sendTargetLabel',
          })}
        </p>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25 send-target-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'autoReminderSettingDialog',
                      id: 'sendTarget.businessUnit',
                    }),
                  },
                ]}
                value={sendTargetBusinessUnit}
                onChangeState={setSendTargetBusinessUnit}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-33 send-target-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'autoReminderSettingDialog',
                      id: 'sendTarget.responsibleUser',
                    }),
                  },
                ]}
                value={sendTargetResponsibleUser}
                onChangeState={setSendTargetResponsibleUser}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-30 send-target-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'autoReminderSettingDialog',
                      id: 'sendTarget.mainContact',
                    }),
                  },
                ]}
                value={sendTargetMainContact}
                onChangeState={setSendTargetMainContact}
                columns={['checkbox']}
                disabled={isDisabled}
              ></Checkbox>
            </div>
          </div>
        </div>
        <div className={getClassNames()}>
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id:
                inputOption.displayModeType === 'display' ? 'return' : 'cancel',
            })}
            onClick={() => handleCancel()}
          />
          {inputOption.displayModeType !== 'display' && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id:
                  inputOption.displayModeType === 'save' ? 'save' : 'decision',
              })}
              onClick={handleDecision}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id: 'AUTO_REMINDER_SETTING',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
