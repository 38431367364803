import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { BusinessUnitCsvAdditionDialog } from '~/shared/components/ui/';

import { error } from '~/shared/components';

export const UISampleBusinessUnitCsvAdditionDialogs = () => {
  const [result, setResult] = useState('');
  const [isShow, setIsShow] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">取引先CSV追加ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShow(true);
              }}
            />
            <BusinessUnitCsvAdditionDialog
              isOpen={isShow}
              onDecision={(v) => {
                // setResult(`出力しました。${v[0].code} ${v[0].displayName}`);
                if (v.message.length > 0) {
                  error(v.message);
                } else {
                  const codes: string[] = [];
                  const displayNames: string[] = [];
                  v.data.map((data) => {
                    codes.push(data.code);
                    displayNames.push(data.displayName);
                  });
                  setResult(
                    `出力しました。コード：${codes.join(
                      ','
                    )} 名称：${displayNames.join(',')}`
                  );
                  setIsShow(false);
                }
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setIsShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
