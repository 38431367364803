import { useRef, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { DateSuggest, Textarea, Textbox } from '~/shared/components/ui';
import { validateFreeDate } from '~/shared/components/parts/validator';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { ReactComponent as ArrowIcon } from '@material-design-icons/svg//filled/arrow_right_alt.svg';
import './base.css';
import { includeInputValidateError } from '~/shared/utils';
import dayjs from 'dayjs';
import { ButtonType } from '~/shared/components/parts/Button/CaptionButton';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';

export interface DeadlineChangeDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
  currentLabelId?: MessageProps;
  captionLabelId?: MessageProps;
  decisionLabelId?: MessageProps;
}

export interface DeadlineChangeDialogOutputOption {
  currentDeadline: string | Date | null;
}

export interface DeadlineChangeDialogInputOption {
  buttonType?: ButtonType;
}

export interface DeadlineChangeResult {
  deadline: Date | null;
  comment: string;
}

export interface DeadlineChangeDialogProps {
  isOpen: boolean;
  messageOption: DeadlineChangeDialogMessageOption;
  outputOption: DeadlineChangeDialogOutputOption;
  inputOption?: DeadlineChangeDialogInputOption;
  onDecision: (result: DeadlineChangeResult) => void;
  onCancel: () => void;
}

export const DeadlineChangeDialog = ({
  isOpen,
  messageOption,
  outputOption,
  inputOption,
  onDecision,
  onCancel,
}: DeadlineChangeDialogProps) => {
  const intl = useIntl();
  const [deadline, setDeadline] = useState<Date | null>(null);
  const [comment, setComment] = useState<string>('');
  const [isSendConfirmOpen, setSendConfirmOpen] = useState(false);
  const requiredDeadlineArea = useRef(null);

  const handleCancel = () => {
    onCancel();
    setDeadline(null);
    setComment('');
  };

  const handleDecision = (result: DeadlineChangeResult) => {
    onDecision(result);
    setSendConfirmOpen(false);
    /**
     * クリアしてしまうと確認ダイアログが閉じた後に変更日付が必須チェックにかかるのでそのままにする
     * その代わり、キャンセル・オープン時にクリアする
     */
    // setDeadline(null);
    // setComment('');
  };

  useEffect(() => {
    /**
     * 確定時にクリアしなくする代わりにダイアログを閉じる時にもクリアを入れる
     * そうしないと再度ダイアログを開いた時にクリア処理が走り、必須エラーが出るため
     */
    setDeadline(null);
    setComment('');
  }, [isOpen]);

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.deadline-change-dialog');
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: deadline?.toDateString() ?? '',
          ref: requiredDeadlineArea,
        },
      ])
    ) {
      return true;
    }
    return false;
  };

  const convertDisplayValue = () => {
    // nullの場合、空文字
    if (!outputOption.currentDeadline) {
      return '';
    }
    // string型だったらそのまま表示
    if (typeof outputOption.currentDeadline === 'string') {
      return outputOption.currentDeadline;
    } else {
      // ここに来るのは必ずDate型
      return dayjs(outputOption.currentDeadline).format('YYYY/MM/DD');
    }
  };

  const currentLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.currentLabelId) {
      msgId.push(messageOption.currentLabelId.prefixId ?? '');
      msgId.push(messageOption.currentLabelId.viewId ?? '');
      msgId.push(messageOption.currentLabelId.id ?? '');
    } else {
      msgId.push('DeadlineChangeDialog.current_deadline');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.currentLabelId]);

  const captionLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.captionLabelId) {
      msgId.push(messageOption.captionLabelId.prefixId ?? '');
      msgId.push(messageOption.captionLabelId.viewId ?? '');
      msgId.push(messageOption.captionLabelId.id ?? '');
    } else {
      msgId.push('DeadlineChangeDialog.deadline');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.captionLabelId]);

  const decisionLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.decisionLabelId) {
      msgId.push(messageOption.decisionLabelId.prefixId ?? '');
      msgId.push(messageOption.decisionLabelId.viewId ?? '');
      msgId.push(messageOption.decisionLabelId.id ?? '');
    } else {
      msgId.push('send');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.decisionLabelId]);
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: decisionLabelId }),
    },
  };

  const elements = (
    <div className="deadline-change-dialog">
      <div className="description-area">
        <p>
          {messageOption.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100 value-compare-group">
              <div className="input">
                <Textbox
                  labelId={currentLabelId}
                  type="text"
                  name="current_deadline"
                  columns={['current_deadline']}
                  value={convertDisplayValue()}
                  disabled={true}
                />
              </div>
              <ArrowIcon className="action-icon" />
              <div className="input" ref={requiredDeadlineArea}>
                <DateSuggest
                  labelId={captionLabelId}
                  name="deadline"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: true }}
                  onChangeState={setDeadline}
                  value={deadline}
                  columns={['deadline']}
                ></DateSuggest>
              </div>
            </div>
          </div>

          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="comment"
                  labelId="comment"
                  columns={['comment']}
                  className="w-100 mh-middle"
                  onChangeState={setComment}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType={inputOption?.buttonType ?? 'high'}
            className="button"
            caption={GetMessageWithIntl(intl, { id: decisionLabelId })}
            onClick={() => {
              if (isInputError()) {
                return;
              }
              setSendConfirmOpen(true);
            }}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
      {/* 送信 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSendConfirmOpen}
        viewMessage={sendMessage}
        onDecision={() => {
          handleDecision({ deadline, comment });
        }}
        onCancel={() => {
          setSendConfirmOpen(false);
        }}
      />
    </>
  );
};
