import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import './TenantRequestConfirmDialog.css';

/**
 * ページから辞書IDを渡したい時のインターフェイス
 * ダイアログタイトルや説明文、その他ボタンキャプションなど適宜対応できる様にする
 */
export interface TenantRequestConfirmDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
}

/**
 * 入力項目に関する項目用インターフェイス
 * ダイアログによってページから呼び出す項目が異なるが
 * 引数の増減は極力減らしたいため
 */
export interface TenantRequestConfirmDialogInputOption {
  companyName: string;
  tenantAdminName: string;
  tenantAdminEmail: string;
}

export interface TenantRequestConfirmDialogProps {
  isOpen: boolean;
  messageOption: TenantRequestConfirmDialogMessageOption;
  inputOption: TenantRequestConfirmDialogInputOption;
  onDecision: () => void;
  onCancel: () => void;
}

export function TenantRequestConfirmDialog(
  props: TenantRequestConfirmDialogProps
) {
  const intl = useIntl();

  const { isOpen, messageOption, inputOption, onCancel, onDecision } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleDecision = () => {
    onDecision();
  };

  // ダイアログ関係
  const view = (
    <div className="tenant-invite-confirm-dialog">
      <div className="message-area">
        <span className="text">
          {messageOption.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </span>
      </div>
      <div className="tenant-invite-confirm-dialog-area">
        <div className="tenant-info-area">
          <ul>
            <li>
              {GetMessageWithIntl(intl, {
                prefixId: 'TENANT_INVITE_CONFIRM_DIALOG',
                id: 'companyName',
              })}{' '}
              : {inputOption.companyName}
            </li>
            <li>
              {GetMessageWithIntl(intl, {
                prefixId: 'TENANT_INVITE_CONFIRM_DIALOG',
                id: 'tenantAdminName',
              })}{' '}
              : {inputOption.tenantAdminName}
            </li>
            <li>
              {GetMessageWithIntl(intl, {
                prefixId: 'TENANT_INVITE_CONFIRM_DIALOG',
                id: 'tenantAdminEmail',
              })}{' '}
              : {inputOption.tenantAdminEmail}
            </li>
          </ul>
        </div>
      </div>
      <div className="message-area">
        <span className="text">
          {GetMessageWithIntl(intl, {
            prefixId: 'TENANT_INVITE_CONFIRM_DIALOG',
            id: 'description1',
          })}
        </span>
      </div>
      <div className="message-area">
        <span className="text red">
          {GetMessageWithIntl(intl, {
            prefixId: 'TENANT_INVITE_CONFIRM_DIALOG',
            id: 'description2',
          })}
        </span>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => handleCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: 'decision',
          })}
          onClick={() => handleDecision()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
}
