import { useEffect } from 'react';
import { useLoading } from '~/shared/contexts/LoadingProvider';

/**
 * @deprecated 旧ローディング表示コンポーネント
 * こちらは今後利用せず、利用画面側で useLoading を呼び出すように置き換える予定。
 */
export function LoadingIcon() {
  const { showLoading, hideLoading } = useLoading();

  // 新ローディングの操作にフォールバックする
  useEffect(() => {
    showLoading();
    return () => hideLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
