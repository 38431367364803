import './Button.css';
import { useMemo, CSSProperties, ReactNode } from 'react';
import { Property } from '~/shared/services';
import { exportGoogleAnalytics } from '../utils';

type ButtonType = 'icon' | 'button' | 'inlineButton';
type StyleType =
  | 'basic'
  | 'normal'
  | 'normalLine'
  | 'danger'
  | 'thin'
  | 'cancel'
  | 'back';

export interface ButtonProps {
  name: string;
  displayName?: string;
  className?: string;
  styleType?: StyleType;
  buttonType?: ButtonType;
  send: () => void;
  disabled?: boolean;
  properties: Array<Property>;
  icon?: ReactNode;
  iconPath?: string;
  permissions?: string;
  userpermissions?: Array<string>;
}

interface dict {
  [key: string]: string;
}
export function Button(props: ButtonProps) {
  const [buttonStyle, viewStyle] = useMemo<
    [CSSProperties, CSSProperties]
  >(() => {
    const viewStyle: CSSProperties = {};
    if (
      props.permissions &&
      props.userpermissions &&
      !props.userpermissions.includes(props.permissions) &&
      !props.userpermissions.includes('*/*')
    ) {
      viewStyle.display = 'none';
    }
    const textDict: dict = {};
    props.properties.map((v) => {
      textDict[v.propertyName] = v.propertyValue;
    });
    const txtStyle: CSSProperties = textDict;
    return [txtStyle, viewStyle];

    // props.properties 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.properties]);

  // inputのクラス名
  const buttonClassName = props.className ?? '';
  const styleType = props.styleType ?? '';
  const buttonType = props.buttonType ?? 'button';

  const handleClick = () => {
    props.send();

    // GoogleAnalyticsへデータ送信
    exportGoogleAnalytics('button', { targetName: props.name });
  };
  return (
    <div
      className={`Button ${buttonType.replace(' ', '')}Box`}
      style={viewStyle}
    >
      <button
        type="button"
        className={`${buttonType} ${styleType} ${buttonClassName} ${
          props.disabled && !props.icon ? 'disabled' : ''
        }`}
        onClick={handleClick}
        style={buttonStyle}
        disabled={props.disabled}
      >
        {props.icon && <>{props.icon}</>}
        {!props.iconPath && props.displayName}
        {props.iconPath && (
          <span className="aid">
            <img src={props.iconPath} alt="button icon" />
            {props.displayName}
          </span>
        )}
      </button>
    </div>
  );
}
