import { useState } from 'react';
import { FileUploadWithMemoDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleFileUploadWithMemoDialogs = () => {
  const [fileMemoResult, setFileMemoResult] = useState('');
  const [isShowCompleteTaskDialog, setShowCompleteTaskDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">備考付きファイルアップロードダイアログ</h3>

      <h4>ファイル追加ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setFileMemoResult('');
                setShowCompleteTaskDialog(true);
              }}
            />
            <FileUploadWithMemoDialog
              uploaderOption={{
                validateOption: {
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              inputOption={{}}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'file_add',
                },
                memoLabelId: 'remarks',
                buttonType: {
                  id: 'decision',
                },
              }}
              displayOption={{ isMultiple: true, isDnd: true }}
              isOpen={isShowCompleteTaskDialog}
              onDecision={(v) => {
                const fileNames: string[] = (v.files ?? []).map((file) => {
                  return file.filename;
                });
                setFileMemoResult(
                  `出力しました。メモ: ${v.memo} ファイル名: ${fileNames.join(
                    '、'
                  )}`
                );
                setShowCompleteTaskDialog(false);
              }}
              onCancel={() => {
                setFileMemoResult('キャンセルしました');
                setShowCompleteTaskDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{fileMemoResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
