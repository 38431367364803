import { useState } from 'react';
import { CommentDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleMemoDialogs = () => {
  const [result, setResult] = useState('');
  const [comment, setComment] = useState('');
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">メモダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowConfirm(true);
              }}
            />
            <CommentDialog
              isOpen={isShowConfirm}
              inputOption={{ comment: comment }}
              inputStateOption={{ onChangeComment: setComment }}
              messageOption={{
                headerLabelId: {
                  id: 'modification_request',
                  prefixId: 'DIALOG_TITLE',
                },
                captionLabelId: {
                  id: 'memo',
                  prefixId: 'caption',
                },
              }}
              onDecision={() => {
                setResult(`送信しました。コメント：${comment}`);
                setComment('');
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setResult('キャンセル');
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
