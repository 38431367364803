import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  success,
  Toast,
} from '~/shared/components';
import { useIntl } from 'react-intl';
import {
  Preset,
  Property,
  ConfirmationDialog,
  ListView,
  ViewMenu,
  MenuActionItem,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import {
  TenantInvitationDialog,
  TenantInvitationDialogInputOption,
  TenantInvitationDialogResult,
} from '~/shared/components/ui/Dialog';
import {
  ViewId,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListBusinessUnitManagementContents,
  PageState,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/shared/contexts/AuthProvider';

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_LIST';

type BusinessUnitManagement =
  mtechnavi.api.company.IBusinessUnitManagementContent;
export function BusinessUnitManagementList() {
  const intl = useIntl();
  const navi = useNavigate();
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  // APiが利用可能になるまでの仮
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [tenantInvitationInputOption, setTenantInvitaionInputOption] =
    useState<TenantInvitationDialogInputOption>({
      businessUnitManagementIds: [],
      companyName: '',
      staffName: '',
      staffEmail: '',
    });

  const selectedIds = useRef<string[]>([]);
  const myEmail = useAuth().user?.email ?? '';

  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const sendErrorMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };

  const deleteMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };

  const [isSendMailOpen, setSendMailOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isSendConfirmOpen, setSendConfirmOpen] = useState(false);
  const [tenantInvitationDialogResult, setTenantInvitationDialogResult] =
    useState<TenantInvitationDialogResult>({} as TenantInvitationDialogResult);

  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);

  const businessUnitManagementList = useRef<BusinessUnitManagement[]>([]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'businessUnitManagement',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'businessUnitManagement',
    headerColumns: [],
  };

  const [isReload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListBusinessUnitManagementContents,
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'profile.address.region.displayNameLang',
          'businessUnitManagement.status.displayNameLang',
          'tenantStatus.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/businessunitmanagement-confirmation', {
      state,
    });
  };

  const handleDelete = async () => {
    const targetDeleteItems = businessUnitManagementList.current.filter(
      (item) =>
        selectedIds.current.includes(
          item.businessUnitManagement?.businessUnitManagementId || ''
        )
    );
    const deleteItems = targetDeleteItems.map((item) => {
      return item.businessUnitManagement ?? {};
    });
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitManagementList',
        request: deleteItems,
      });
      clearCheckBox();
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
      setDeleteOpen(false);
    }
  };

  const handleSendTenantInviteMail = async () => {
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'sendTenantInvitation',
        request: {
          businessUnitManagementIds: selectedIds.current,
          individualMessage: tenantInvitationDialogResult.message,
          contactInformationMessage: tenantInvitationDialogResult.contact,
        },
      });
      setSendConfirmOpen(false);
      setSendMailOpen(false);
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // アップロード
    menuItems.push({
      name: 'upload',
      func: () => setImportDialogOpen(true),
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        selectedIds.current = v as string[];
        setDeleteOpen(true);
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 招待メール送信
    menuItems.push({
      name: 'input',
      func: (v?: string[]) => {
        const targetIds = v ?? [];
        if (targetIds.length === 0) {
          error([unselectedMessage]);
          return;
        }

        const selectedItems = businessUnitManagementList.current.filter(
          (item) =>
            targetIds.includes(
              item.businessUnitManagement?.businessUnitManagementId || ''
            )
        );

        const linkedItems = selectedItems.filter(
          (item) => item.tenantStatus?.systemName === 'B03'
        );

        // 紐付けありが存在する場合
        if (linkedItems.length > 0) {
          error([sendErrorMessage]);
          return;
        }

        const companyName =
          selectedItems.length > 0
            ? selectedItems[0].businessUnitManagement?.profile?.displayNameLang
                ?.ja ?? ''
            : '';
        const invitees: sharelib.IUserReference[] =
          selectedItems.length > 0
            ? selectedItems[0].businessUnitManagement?.invitees ?? []
            : [];
        const staffName =
          invitees.length > 0 ? invitees[0].displayName ?? '' : '';
        const staffEmail = invitees.length > 0 ? invitees[0].email ?? '' : '';

        selectedIds.current = v as string[];
        const inputOpt: TenantInvitationDialogInputOption = {
          businessUnitManagementIds: targetIds,
          companyName: companyName,
          staffName: staffName,
          staffEmail: staffEmail,
        };
        setTenantInvitaionInputOption(inputOpt);
        setSendMailOpen(true);
      },
    });
    return menuItems;
  };

  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'BusinessUnitManagementList')
        ?.property ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
  }, [childrenPresetItem]);

  const headerLabelId: MessageProps = {
    prefixId: 'DIALOG_TITLE',
    id: 'FILE_IMPORT',
  };

  const onOriginalItem = (items: unknown) => {
    businessUnitManagementList.current =
      items as unknown as BusinessUnitManagement[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <ListView
          pageInfo={{
            schema: schema,
            preset: preset,
            menuItem: setMenuActionItem(),
            menuTarget: 'businessUnitManagement.businessUnitManagementId',
            headerTitle: { viewId: VIEW_ID },
            presetItems: childrenPresetItem,
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isListActionMenu: true,
            },
          }}
          isReload={isReload}
          fullMethodName={FullMethodName_ListBusinessUnitManagementContents}
          stateOption={{
            onOriginalItemState: onOriginalItem,
          }}
          filterItemOption={{
            isRequestBodyFilter: true,
          }}
          importDialogOption={{
            isDisplay: true,
            isDialogOpen: isImportDialogOpen,
            dialogPreset: dialogPreset,
            isFileTypeSelectBox: true,
            userMasterCategoryName: 'A4000009',
            headerLabelId: headerLabelId,
            handleExport: handleExport,
            handleImport: handleImport,
            onChangeState: (v) => {
              setImportDialogOpen(v);
            },
          }}
        />
        <ConfirmationDialog
          isOpen={isDeleteOpen}
          messageLabelId={deleteMessage}
          viewMessage={deleteMessage}
          onDecision={() => {
            handleDelete();
          }}
          onCancel={() => setDeleteOpen(false)}
        />
        <div className="TenantInvitationDialog">
          <TenantInvitationDialog
            isOpen={isSendMailOpen}
            inputOption={tenantInvitationInputOption}
            messageOption={{
              headerLabelId: {
                id: 'tenant_invitation',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'tenant_invitation',
                prefixId: 'DIALOG_DESCRIPTION',
              },
            }}
            onDecision={(result) => {
              setTenantInvitationDialogResult(result);
              setSendConfirmOpen(true);
            }}
            onCancel={() => {
              setSendMailOpen(false);
            }}
          />
        </div>
        {/* 送信確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isSendConfirmOpen}
          viewMessage={sendMessage}
          onDecision={() => {
            handleSendTenantInviteMail();
          }}
          onCancel={() => {
            setSendConfirmOpen(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
