import { useRef, useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  FinancialRegistrationDialog,
  FinancialRegistrationDialogRef,
} from '~/shared/components/ui/Dialog/FinancialRegistrationDialog';

export const UISampleFinancialRegistrationDialogs = () => {
  const [result, setResult] = useState('');
  const dialogRef = useRef<FinancialRegistrationDialogRef>(null);

  return (
    <div className="categories">
      <h3 is="dialog">決算情報登録ダイアログ</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                dialogRef.current?.open({});
              }}
            />
            <FinancialRegistrationDialog
              ref={dialogRef}
              onCancel={() => {
                setResult('キャンセルしました');
              }}
              onDecision={(v) => {
                setResult(
                  `出力しました。${JSON.stringify(
                    v,
                    function (key, value) {
                      if (this[key] instanceof Date) {
                        return this[key].toString();
                      }
                      return value;
                    },
                    2
                  )}`
                );
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
