import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { SystemNotificationSettingDialog } from '~/shared/components/ui/';

export const UISampleSystemNotificationSettingDialogs = () => {
  const [result, setResult] = useState('');
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">システム通知設定ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowDialog(true);
              }}
            />
            <SystemNotificationSettingDialog
              isOpen={isShowDialog}
              onDecision={() => {}}
              onCancel={() => {
                setResult('キャンセルしました');
                setIsShowDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
