import { useState, useEffect, useMemo } from 'react';
import { Container, error, Toast } from '~/shared/components';
import {
  MenuListView,
  Preset,
  MenuItem,
  Property,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { useIntl } from 'react-intl';
import {
  PageState,
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListTransactionUnits,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'MASTER_TRANSACTION_UNIT_LIST';

export function MasterTransactionUnitList() {
  const navi = useNavigate();
  const intl = useIntl();

  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  // 品目取込ダイアログ関係
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'transactionUnitImport'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );

    // prest 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'transactionUnit',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'transactionUnit',
    headerColumns: [],
  };

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListTransactionUnits,
          ]);

        const schema = schemas.pop() ?? [];

        // ja表示のみする処理
        const jaColumn = [
          'displayNameLang',
          'usable.displayNameLang',
          'processs.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schema, jaColumn, 'ja');

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // サンプルでバーガーメニューに関数を渡すためのサンプル関数(ダミー関数)
  const handleMenu = (prop?: string[]) => {
    console.debug('preset menuFunc:');
    console.debug(prop);
  };

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
    };
    navi('/master-transaction-unit', {
      state,
    });
  };

  // メニューの「品目取込」処理
  const handleMenuImport = () => {
    // importダイアログを表示
    setDialogOpen(true);
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    menuItems.push({ name: 'add', func: (v?: string[]) => handleMenu(v) });
    // 確認
    menuItems.push({
      name: 'confirm',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    //menuItems.push({ name: 'delete', func: (v?: string[]) => handleMenu(v) });
    menuItems.push({ name: 'tag', func: (v?: string[]) => handleMenu(v) });
    // 品目取込
    menuItems.push({
      name: 'import',
      func: handleMenuImport,
    });
    menuItems.push({ name: 'edit', func: (v?: string[]) => handleMenu(v) });
    return menuItems;
  };
  const menuViewlist = burgerMenuEvent().map((v) => v.name);
  return (
    <Container viewId={VIEW_ID}>
      <MenuListView
        headerTitle={{ viewId: VIEW_ID }}
        fullMethodName={FullMethodName_ListTransactionUnits}
        schema={schema}
        preset={preset}
        presetItems={childrenPresetItem}
        menuEvent={burgerMenuEvent()}
        listSkipType={{
          isTotal: true,
          isOutput: true,
        }}
        menuTarget={'transactionUnitId'}
        menuViewlist={menuViewlist}
        onHandleDownload={async (ids: string[]) => {
          try {
            const fileFormat = await window.App.services.ui.getFileFormat(
              'transactionUnit',
              intl
            );
            const result =
              await window.App.services.transactionUnitService.exportTransactionUnits(
                { ids, fileFormat }
              );
            return result.assetId;
          } catch (err) {
            error(getExceptionMessage(intl, err));
            throw err;
          }
        }}
        importDialogOption={{
          isDisplay: true,
          isDialogOpen,
          dialogPreset,
          headerLabelId: { prefixId: 'DIALOG', viewId: VIEW_ID },
          handleExport,
          handleImport,
          onChangeState: setDialogOpen,
        }}
      />
      <Toast />
    </Container>
  );
}
