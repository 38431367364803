import './ErrorMessage.css';
import { ReactComponent as WarningIcon } from '@material-design-icons/svg/filled/warning.svg';
import { useEffect, useState } from 'react';

export interface ErrorMessageProps {
  message?: string[];
}

export function ErrorMessage(props: ErrorMessageProps) {
  const [visibility, setVisibility] = useState<'visible' | 'hidden'>('hidden');
  // エラーメッセージ表示の度に表示項目が動かないように、一行分のエリアを常に確保
  const [message, setMessage] = useState<string[]>(['']);

  useEffect(() => {
    const msg = props.message ?? [];
    setMessage(msg);
    if (msg.length > 0) {
      setVisibility('visible');
    } else {
      setVisibility('hidden');
      setMessage(['']); //一行分のエリアを常に確保するため
    }
  }, [props.message]);

  return (
    <div
      className="ErrorMessage"
      style={{
        visibility: visibility,
      }}
    >
      {message.map((msg, index) => {
        return (
          <div key={index} className="error-contents">
            <WarningIcon className="icon" />
            <div className={`message ${!!msg ? 'visible-error-message' : ''}`}>
              {msg}
            </div>
          </div>
        );
      })}
    </div>
  );
}
