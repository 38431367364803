import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { ViewId } from '~/shared/utils';

export interface MessageProps {
  id?: string;
  value?: {};
  viewId?: ViewId;
  prefixId?: string;
}

export const GetMessage = (props: MessageProps) => {
  const intl = useIntl();
  const id = getMessageId(props);
  const value = replaceValueKeys(props.value);
  return id ? intl.formatMessage({ id: id, defaultMessage: '' }, value) : '';
};

export const GetMessageWithIntl = (intl: IntlShape, props: MessageProps) => {
  const id = getMessageId(props);
  const value = replaceValueKeys(props.value);
  return id ? intl.formatMessage({ id: id, defaultMessage: '' }, value) : '';
};

export function GetMessageComponent(props: MessageProps) {
  const id = getMessageId(props);
  const value = replaceValueKeys(props.value);
  return id ? <FormattedMessage id={id} values={value} /> : <></>;
}

// 多言語対応ファイル内のIDを返却
function getMessageId(props: MessageProps) {
  const msgId: string[] = [];
  msgId.push(props.prefixId ?? '');
  msgId.push(props.viewId ?? '');
  msgId.push(props.id ?? '');

  return msgId.filter((v) => !!v).join('.');
}

// メッセージ中の変数に `$` を使用できないので `_` に置き換える
const replaceValueKeys = (value?: {}) => {
  if (!value) {
    return value;
  }
  const replacedEntries = Object.entries(value).map(([key, value]) => [
    key.replaceAll('$', '_'),
    value,
  ]);
  return Object.fromEntries(replacedEntries);
};
