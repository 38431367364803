import { mtechnavi } from '~/shared/libs/clientsdk';
import { GetMessageWithIntl } from '~/shared/components';
import { IntlShape } from 'react-intl';

/**
 * 内訳必須のフォーマット処理
 *  e.g. 材料費,加工費,管理費,運送費
 */
export const formatDisplayRequiredItems = (
  intl: IntlShape,
  data: mtechnavi.api.estimation.IEstimateRequestDetail | null | undefined
): string => {
  if (!data) return '';

  const values: string[] = [];
  // 材料費
  if (data.requiredMaterialCost) {
    values.push(
      GetMessageWithIntl(intl, {
        id: 'requiredItems.requiredMaterialCost',
        prefixId: 'EST_ESTIMATE_RESULT_CONFIRMATION',
      })
    );
  }
  // 加工費
  if (data.requiredProcessingCost) {
    values.push(
      GetMessageWithIntl(intl, {
        id: 'requiredItems.requiredProcessingCost',
        prefixId: 'EST_ESTIMATE_RESULT_CONFIRMATION',
      })
    );
  }
  // 管理費
  if (data.requiredAdministrativeCost) {
    values.push(
      GetMessageWithIntl(intl, {
        id: 'requiredItems.requiredAdministrativeCost',
        prefixId: 'EST_ESTIMATE_RESULT_CONFIRMATION',
      })
    );
  }
  // 運送費
  if (data.requiredFreightCost) {
    values.push(
      GetMessageWithIntl(intl, {
        id: 'requiredItems.requiredFreightCost',
        prefixId: 'EST_ESTIMATE_RESULT_CONFIRMATION',
      })
    );
  }
  return values.join(',');
};
