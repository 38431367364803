import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import './Login.css';
import './TenantApplyRequest.css';
import { GetMessageWithIntl } from '~/shared/components';
import { invokeRequest } from './TenantCreateRequest';

export function TenantApplyRequest() {
  const intl = useIntl();
  const VIEW_ID = 'TENANT_APPLY_REQUEST';

  const [isCompleteRequest, setCompleteRequest] = useState<boolean>(false);
  const [isException, setException] = useState<boolean>(false);

  useEffect(() => {
    const pathName = window.location.pathname;
    const splitPath = pathName.split('/');
    const tenantRequestId = splitPath.length >= 4 ? splitPath[2] : '';
    const verifyTenantRequestId = splitPath.length >= 4 ? splitPath[3] : '';

    (async () => {
      //テナント申請IDからCloudRunを呼び出し、必要な情報を取得する
      try {
        const applyFullMethodName =
          'mtechnavi.api.tenantprovision.TenantProvisionService/ApplyTenantRequest';
        await invokeRequest(applyFullMethodName, {
          tenantRequestId: tenantRequestId,
          verifyTenantRequestId: verifyTenantRequestId,
        });
        setCompleteRequest(true);
      } catch (err) {
        console.error(err);
        setException(true);
      }
    })();
  }, []);

  const viewName = GetMessageWithIntl(intl, {
    id: '',
    viewId: VIEW_ID,
    prefixId: 'HEADER_TITLE',
  });
  const mainRef = useRef<HTMLDivElement>(null);

  const ErrorComponent = () => {
    return (
      <>
        <div>
          <h3>テナント作成依頼に失敗しました。</h3>
          <div>
            下記が考えられます。
            <ul>
              <li>既にテナント作成の手続きが完了している</li>
              <li>有効期限切れ（招待メール受信から１ヶ月以上経過している）</li>
            </ul>
            受信メールの再確認をお願いします。
          </div>
        </div>
      </>
    );
  };

  const ApplyComponent = () => {
    return (
      <>
        <div>
          <h3>テナント作成依頼中です。</h3>
          <div>自動的に画面遷移します。</div>
        </div>
      </>
    );
  };

  const CompleteComponent = () => {
    return (
      <>
        <div>
          <h3>テナント作成依頼が完了しました。</h3>
          <div className="success-area">
            <ul>
              <li className="no-list-style">テナント作成が完了次第、</li>
              <li className="no-list-style">
                申請したメールアドレスに完了メールが送付されます。
              </li>
              <li className="no-list-style">
                この画面を閉じて、完了メールに従って後続の手続きを行ってください。
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div id="back-ground-color-theme"></div>
      <article className="container">
        <header className="Header">
          <div>
            <div className="nav-btn"></div>
          </div>
          <div className="symbol">M-Tech Navi{` ${viewName}`}</div>
          <div className="header-btns"></div>
        </header>

        <div className="content">
          <div className="main" ref={mainRef}>
            <div className="TenantApplyRequestArea">
              {!isException && !isCompleteRequest && ApplyComponent()}
              {!isException && isCompleteRequest && CompleteComponent()}
              {isException && ErrorComponent()}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
