import { PropsWithoutRef, useEffect, useId, useRef, useState } from 'react';
import './ConditionCheckbox.css';

export interface CategorizedTipCheckboxProps {
  value: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: (id: string, isChecked: boolean) => void;
}
export interface CategorizedTipCheckboxRef {
  focus: () => void;
  open: () => void;
  close: () => void;
  isOpen: () => boolean;
}
export const ConditionCheckbox = (
  props: PropsWithoutRef<CategorizedTipCheckboxProps>
) => {
  const inputId = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isChecked, setChecked] = useState<boolean>(!!props.checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      event.preventDefault();
      return;
    }
    setChecked(event.target.checked);
    if (props?.onChange) {
      props?.onChange(event.target.value, event.target.checked);
    }
  };

  useEffect(() => setChecked(props.checked), [props.checked]);

  return (
    <div className="ConditionCheckbox">
      <input
        type="checkbox"
        id={inputId}
        value={props.value}
        checked={isChecked}
        onChange={(event) => handleChange(event)}
        ref={inputRef}
      />
      <label htmlFor={inputId}>{props.label}</label>
    </div>
  );
};
