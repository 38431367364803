import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import {
  DataFilterbox,
  FilterboxItem,
  SimpleListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { convertDisplayUserNameEmail } from '~/shared/utils';
import './OrganizationMemberDialog.css';

interface OrganizationMemberDialogInputOption {
  userList?: mtechnavi.api.tenantadmin.IUserAttribute[];
  belongingUserList?: mtechnavi.api.tenantadmin.IUserAttribute[];
}
interface OrganizationMemberDialogMessageOption {
  headerLabelId: MessageProps;
}
export interface OrganizationMemberDialogProps {
  isOpen: boolean;
  inputOption: OrganizationMemberDialogInputOption;
  messageOption: OrganizationMemberDialogMessageOption;
  onDecision: () => void;
  onCancel: () => void;
}

const prefixId = 'OrganizationMemberDialog';

export const OrganizationMemberDialog = ({
  isOpen,
  inputOption,
  messageOption,
  onDecision,
  onCancel,
}: OrganizationMemberDialogProps) => {
  const { userList } = inputOption;
  const intl = useIntl();

  const [selectedUsers, setSelectedUsers] = useState<FilterboxItem[]>();
  const [belongingUserList, setBelongingUserList] = useState(
    inputOption.belongingUserList ?? []
  );

  const filterItemList = useMemo(() => {
    if (!userList) {
      return [];
    }
    return userList.map(
      (v): FilterboxItem => ({
        displayName: convertDisplayUserNameEmail(
          v.user?.displayName,
          v.user?.email
        ),
        value: v.user?.userId ?? '',
      })
    );
  }, [userList]);
  const belongingUseViewList = useMemo(() => {
    if (!belongingUserList) {
      return [];
    }
    return belongingUserList.map((v) => ({
      userId: v.user?.userId ?? '',
      displayName: v.user?.displayName,
      email: v.user?.email,
    }));
  }, [belongingUserList]);

  const handleDecision = () => {
    onDecision();
  };

  const handleAdd = () => {
    const selectedUserIds = selectedUsers?.map((user) => user.value);
    const users = (userList ?? []).filter(
      (user) =>
        user.user?.userId && selectedUserIds?.includes(user.user.userId ?? '')
    );
    setBelongingUserList([...belongingUserList, ...users]);
    setSelectedUsers([]);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClear = (data: any) => {
    setBelongingUserList(
      belongingUserList.filter((item) => item.user?.userId !== data.userId)
    );
  };
  const handleClearAll = () => {
    setBelongingUserList([]);
  };

  const view = (
    <div className="OrganizationMemberDialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-60">
              <DataFilterbox
                data={filterItemList}
                name="account"
                labelId="OrganizationMemberDialog.account"
                columns={['account']}
                searchOption={{ targets: 'displayName' }}
                onChangeState={setSelectedUsers}
                value={selectedUsers}
              />
            </div>
            <div className="w-40 add-button">
              <CaptionButton
                name="add"
                buttonType="basic"
                className="button"
                caption={GetMessageWithIntl(intl, { id: 'add' })}
                onClick={() => {
                  handleAdd();
                }}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleListView
                data={belongingUseViewList}
                viewOptions={{
                  previewRowCount: 5,
                  keyColumn: 'userId',
                  columns: [
                    {
                      propertyName: 'displayName',
                      header: { prefixId, id: 'displayName' },
                    },
                    {
                      propertyName: 'email',
                      header: { prefixId, id: 'email' },
                    },
                  ],
                }}
                actionOptions={{
                  onDelete: handleClear,
                  onDeleteAll: handleClearAll,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={() => {
            handleDecision();
          }}
        />
      </div>
    </div>
  );

  const modalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...modalProps} />
    </>
  );
};
