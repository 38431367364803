import {
  PropsWithChildren,
  forwardRef,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';
import { CategorizedTipCheckboxRef } from './CategorizedTipCheckbox';
import './CategorizedBranch.css';

interface CategorizedBranchKeyActions {
  onLeft?: (categoryId: string) => void;
  onDown?: (categoryId: string) => void;
  onUp?: (id: string) => void;
  onRight?: (id: string) => void;
}
export interface CategorizedBranchProps {
  parentRef?: React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>;
  categoryId: string;
  label: string;
  level: number;
  disabled?: boolean;
  searchWord?: string;
  autoFocus?: boolean;
  keyActions?: CategorizedBranchKeyActions;
}
export interface CategorizedBranchRef {
  open: () => void;
  close: () => void;
  focus: () => void;
  isOpen: () => boolean;
}
export const CategorizedBranch = forwardRef(
  (props: PropsWithChildren<CategorizedBranchProps>, ref) => {
    const inputId = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState<boolean>(true);
    const isMatch = useMemo<boolean | null>(() => {
      if (!props.searchWord) {
        return null;
      }
      return props.searchWord
        .split(' ')
        .filter((w) => !!w)
        .some((word) => !!props.label.match(word));
    }, [props.label, props.searchWord]);

    const handleToggle = () => {
      setOpen(!isOpen);
    };
    const setFocus = () => {
      inputRef.current?.focus();
    };
    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'ArrowUp': {
          props.keyActions?.onUp && props.keyActions.onUp(props.categoryId);
          break;
        }
        case 'ArrowDown': {
          props.keyActions?.onDown && props.keyActions.onDown(props.categoryId);
          break;
        }
        case 'ArrowRight': {
          props.keyActions?.onRight &&
            props.keyActions.onRight(props.categoryId);
          break;
        }
        case 'ArrowLeft': {
          props.keyActions?.onLeft && props.keyActions.onLeft(props.categoryId);
          break;
        }
      }
    };
    // 外から開閉を操作できるようにする
    useImperativeHandle(
      ref,
      (): CategorizedBranchRef => ({
        open: () => {
          // 親カテゴリがあれば展開する
          props.parentRef?.current?.open();
          setOpen(true);
        },
        close: () => setOpen(false),
        focus: () => setFocus(),
        isOpen: () =>
          isOpen &&
          (props.parentRef
            ? props.parentRef?.current?.isOpen() || false
            : true),
      })
    );
    return (
      <div
        className={`CategorizedBranch level-${props.level} ${
          isOpen ? '' : 'close'
        } ${isMatch === null ? '' : isMatch ? 'matched' : 'unmatched'} ${
          props.disabled ? 'disabled' : ''
        }`}
        ref={containerRef}
      >
        <div className="category-label-area">
          <input
            type="checkbox"
            id={inputId}
            checked={isOpen}
            onKeyDown={handleKeydown}
            onChange={handleToggle}
            ref={inputRef}
            autoFocus={props.autoFocus}
          />
          <label className="category-name" htmlFor={inputId}>
            <span className="icon">{getGenerallyIconElement('down')}</span>
            {props.label}
          </label>
        </div>
        <div className="category-children">{props.children}</div>
      </div>
    );
  }
);
