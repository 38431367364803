import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  ImportDialog,
  ImportDialogHandleFormat,
} from '~/shared/components/ui/Dialog/ImportDialog';
import { MessageProps } from '~/shared/components/parts/Message/Message';

export const UISampleUserAttributeDialogs = () => {
  const [isShow, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false); // eslint-disable-line

  const handleExport: ImportDialogHandleFormat = {
    name: 'userAttribute',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'userAttribute',
    headerColumns: [],
  };

  const headerLabelId: MessageProps = {
    prefixId: 'DIALOG',
    id: 'ADMIN_ACCOUNT_LIST',
  };

  return (
    <div className="categories">
      <h3 id="dialog">アカウント取込ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShow(true);
              }}
            />
            <ImportDialog
              isOpen={isShow}
              headerLabelId={headerLabelId}
              allIds={[]}
              ids={[]}
              preset={[]}
              handleExport={handleExport}
              handleImport={handleImport}
              onChangeState={() => {
                setShow(false);
              }}
              onHandleSuccess={() => {
                setShow(false);
              }}
              onChangeLoadingState={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
