import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { DateSuggest, Textarea, Checkbox } from '~/shared/components/ui';
import { validateFreeDate } from '~/shared/components/parts/validator';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import './SuppliedRegistrationDialog.css';

export interface SuppliedRegistrationInfo {
  suppliedParts?: boolean;
  suppliesDt?: Date | null;
  suppliesRemarks?: string;
}

export interface SuppliedRegistrationDialogProps {
  isOpen: boolean;
  inputOption?: SuppliedRegistrationInfo;
  onDecision: (result: SuppliedRegistrationInfo) => void;
  onCancel: () => void;
}

export const SuppliedRegistrationDialog = ({
  isOpen,
  inputOption,
  onDecision,
  onCancel,
}: SuppliedRegistrationDialogProps) => {
  const intl = useIntl();

  const [suppliedPartsCheck, setsuppliedPartsCheck] = useState<string[]>([]);
  const [suppliesDt, setSuppliesDt] = useState<Date | null>(null);
  const [suppliesRemarks, setSuppliesRemarks] = useState<string>('');

  const handleCancel = () => {
    onCancel();
    setsuppliedPartsCheck([]);
    setSuppliesDt(null);
    setSuppliesRemarks('');
  };

  const handleDecision = (result: SuppliedRegistrationInfo) => {
    onDecision(result);
  };

  useEffect(() => {
    if (isOpen) {
      setsuppliedPartsCheck(inputOption?.suppliedParts ? ['1'] : []);
      setSuppliesDt(inputOption?.suppliesDt ?? null);
      setSuppliesRemarks(inputOption?.suppliesRemarks ?? '');
    }

    // 初回起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const elements = (
    <div className="deadline-change-dialog">
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100 value-compare-group">
              <div className="w-33 supplied-parts-checkbox">
                <Checkbox
                  name="suppliedPartsCheck"
                  className="group"
                  items={[
                    {
                      value: '1',
                      displayName: GetMessage({
                        prefixId: 'suppliedRegistration',
                        id: 'suppliedParts',
                      }),
                    },
                  ]}
                  value={suppliedPartsCheck}
                  onChangeState={setsuppliedPartsCheck}
                  columns={['suppliedPartsCheck']}
                ></Checkbox>
              </div>
              <div className="w-33 after-the-first-time-checkbox">
                <DateSuggest
                  labelId="suppliedRegistration.suppliesDt"
                  name="suppliesDt"
                  validator={validateFreeDate(intl)}
                  onChangeState={setSuppliesDt}
                  value={suppliesDt}
                  columns={['suppliesDt']}
                ></DateSuggest>
              </div>
            </div>
          </div>

          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="suppliesRemarks"
                  labelId="suppliedRegistration.suppliesRemarks"
                  columns={['suppliesRemarks']}
                  className="w-100 mh-middle"
                  value={suppliesRemarks}
                  onChangeState={setSuppliesRemarks}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'decision' })}
            onClick={() => {
              const suppliedParts =
                suppliedPartsCheck.length > 0 ? true : false;
              const info: SuppliedRegistrationInfo = {
                suppliedParts: suppliedParts,
                suppliesDt: suppliesDt ?? null,
                suppliesRemarks: suppliesRemarks,
              };
              handleDecision(info);
            }}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: { prefixId: 'DIALOG_TITLE', id: 'suppliedRegistration' },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
    </>
  );
};
