/**
 * DataView 関連
 * FIXME: modules/shared/src/components/ui/ListView/DataView.tsx に移動するべき？
 */

import { HeaderInfo } from '~/shared/components/ui';
import { ViewId } from './viewId';
import { PresetItem } from '../../services';

// dataViewで使用するインターフェイス
export interface DataViewInfo<T> {
  header: HeaderInfo[];
  data: T[];
}

export function getDataViewHeaderInfo(
  column: string[],
  viewId: ViewId = '',
  prefixId = ''
): HeaderInfo[] {
  return column.map((v) => ({
    message: {
      prefixId: prefixId,
      viewId: viewId,
      id: v,
    },
  }));
}

// DataViewで使用するデータをpresetを元にフィルター
export function getPresetFilterdData<T>(
  preset: PresetItem,
  header: HeaderInfo[],
  data: T[]
): DataViewInfo<T> {
  const headerInfo =
    (header
      .map((v) => {
        const properties = preset.property?.filter(
          (property) => v.message.id === property.name
        );
        const numberColumnPreset = preset.children?.find(
          (v) => v.name === 'numberColumn'
        );
        if (properties?.length ?? 0 > 0) {
          return {
            message: {
              id: v.message.id,
              prefixId: v.message.prefixId,
              viewId: v.message.viewId,
            },
            width: properties?.find((v) => v.propertyName === 'width')
              ?.propertyValue,
            margin: numberColumnPreset?.columns?.find(
              (columns) => columns === v.message.id
            )
              ? '0 0 0 auto'
              : undefined, // 数値項目の右寄せを行うためにmarginを設定
          };
        }
      })
      .filter((v) => !!v) as HeaderInfo[]) ?? [];

  // データはpreset制御する必要はないのでそのまま素通り
  return { header: headerInfo, data };
}
