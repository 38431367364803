import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import { Textbox, ConfirmationDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListUserAttributes,
  PageState,
  ViewId,
  getDateFormat,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import urlList from '~/shared/menu/path.json';
import {
  PageNavigation,
  LatestUpdateInfo,
} from '~/shared/components/ui/PageNavigation/PageNavigation';
import { FilterRequest } from '~/worker';

const VIEW_ID: ViewId = 'ADMIN_ACCOUNT_INPUT';

export function AdminAccountInput() {
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  const requiredCodeArea = useRef(null);
  const requiredNameArea = useRef(null);

  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = sourcePageInfo.actionType;
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [backPageUrl, setBackPageUrl] = useState('');
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);
  const intl = useIntl();
  const navi = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000082' });
  const [userItem, setUserItem] =
    useState<mtechnavi.api.tenantadmin.IUserAttribute>();
  const [email, setEmail] = useState('');
  const [isEmailTextBox, setEmailDisabled] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isOpenSaveDialog, setOpenSaveDialog] = useState(false);
  const [lastUpdateInfo, setLastUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: false,
  });
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });

  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
        viewId: VIEW_ID,
      }),
    },
  };
  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // プリセット取得
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      })();
      (async () => {
        // 新規登録モード
        if (actionType === 'add') {
          setLastUpdateInfo({
            isVisibleUpdateInfo: false,
          });
          setEmailDisabled(false);

          const newUser: mtechnavi.api.idp.IUser = {
            email: '',
            displayName: '',
          };
          const newUserAttribute: mtechnavi.api.tenantadmin.IUserAttribute = {
            userAttributeId: null,
            user: newUser,
            remarks: '',
          };
          setUserItem(newUserAttribute);
        }
        // 編集モード
        if (actionType === 'edit') {
          const userAttribute =
            (await window.App.services.tenantAdminService.getUserAttribute({
              userAttributeId:
                (sourcePageInfo.ids || []).length > 0
                  ? sourcePageInfo.ids![0] ?? ''
                  : '',
            })) as mtechnavi.api.tenantadmin.IUserAttribute;

          setUserItem(userAttribute);
          setScreenData(userAttribute);
        }
      })();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    // 戻るボタンURLセット
    setBackPageUrl(
      urlList.find((v) => v.viewId === sourcePageInfo.sourceViewId)?.path ?? ''
    );
    setLoading(false);

    // 初回時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setScreenData = (
    userItem: mtechnavi.api.tenantadmin.IUserAttribute
  ) => {
    setEmail(userItem?.user?.email || '');
    setAccountName(userItem?.user?.displayName || '');
    setRemarks(userItem?.remarks || '');

    setLastUpdateInfo({
      isVisibleUpdateInfo: true,
      attributeAt: userItem?.userAttributeUpdatedAt ?? undefined,
      content: userItem?.userAttributeUpdatedBy?.displayName ?? '',
      attribute: userItem?.userAttributeUpdatedBy?.email ?? '',
    });
    setEmailDisabled(true);
  };

  // 保存
  const handleSaveAccount = async () => {
    setLoading(true);
    try {
      const saveUserItem = userItem as mtechnavi.api.tenantadmin.IUserAttribute;

      saveUserItem.user!.email = email;
      saveUserItem.user!.displayName = accountName;
      saveUserItem.remarks = remarks;

      const result = (await window.App.services.ui.worker.apiCall({
        actionName: 'saveAdminAccount',
        request: saveUserItem,
      })) as mtechnavi.api.tenantadmin.IUserAttribute[];

      success([toastSuccess]);
      setUserItem(result[0]);
      setScreenData(result[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
      setOpenSaveDialog(false);
    }
    // 遷移元に戻る
    backToPreviewPage();
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPageUrl ?? '/admin-account-list', { state });
  };

  // emailチェック
  const handleEmailCheck = async () => {
    if (actionType === 'add') {
      const option: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListUserAttributes,
        filter: {},
        sort: [],
      };
      const userList = (await window.App.services.ui.worker.filter(
        option
      )) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

      const userEmailList = userList.items.map(
        (v) => v.user?.email
      ) as string[];
      if (userEmailList.includes(email)) {
        error([excessTargetMessage]);
        setOpenSaveDialog(false);
        return;
      }
    }
    handleSaveAccount();
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="header">
          <PageNavigation
            backpagePath={backPageUrl}
            pageInfo={{
              isVisibleMoveNavi: false,
            }}
            infoOption={{
              lastUpdateInfo: lastUpdateInfo,
              issuerInfo: { isVisibleIssuerInfo: false },
            }}
            handleBackPage={() => {
              setConfirmDialogInfo({
                viewMessage: viewMessageCancel,
                onDecision: backToPreviewPage,
              });
              setOpenConfirmDialog(true);
            }}
          />
        </div>
        <div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50" ref={requiredCodeArea}>
                  <Textbox
                    name="user.email"
                    className="field"
                    value={userItem?.user?.email ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_INPUT.user.email"
                    validateOption={{ required: true }}
                    columns={preset.columns}
                    onChangeState={setEmail}
                    disabled={isEmailTextBox}
                  />
                </div>
                <div className="w-20">
                  <Textbox
                    name="status.status.displayNameLang"
                    className="field"
                    value={userItem?.status?.status?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_INPUT.status.status.displayNameLang"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-20">
                  <Textbox
                    name="user.lastLoginAt"
                    className="field"
                    value={getDateFormat(
                      userItem?.user?.lastLoginAt ?? '',
                      'YYYY/MM/DD HH:mm'
                    )}
                    type="text"
                    labelId="ADMIN_ACCOUNT_INPUT.user.lastLoginAt"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50" ref={requiredNameArea}>
                  <Textbox
                    name="user.displayName"
                    className="field"
                    value={userItem?.user?.displayName ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_INPUT.user.displayName"
                    columns={preset.columns}
                    onChangeState={setAccountName}
                    validateOption={{ required: true }}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  <Textbox
                    name="remarks"
                    className="field"
                    value={userItem?.remarks ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_INPUT.remarks"
                    columns={preset.columns}
                    onChangeState={setRemarks}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmDialogInfo.viewMessage}
          onDecision={confirmDialogInfo.onDecision}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
        />
        <ConfirmationDialog
          isOpen={isOpenSaveDialog}
          viewMessage={viewMessageSave}
          onDecision={handleEmailCheck}
          onCancel={() => {
            setOpenSaveDialog(false);
          }}
        />
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                buttonType="basic"
                caption="保存"
                name="savebtn"
                onClick={() => {
                  // 入力エラーチェック
                  if (
                    includeInputValidateError(document, intl, [
                      {
                        value: email,
                        ref: requiredCodeArea,
                      },
                      {
                        value: accountName,
                        ref: requiredNameArea,
                      },
                    ])
                  ) {
                    return;
                  }
                  setOpenSaveDialog(true);
                }}
              />
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
