import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { DetailInputDialog } from '~/shared/components/ui/Dialog/DetailInputDialog';

export const UISampleDetailInputDialogs = () => {
  const [result, setResult] = useState<string>('');
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">明細登録ダイアログ(新規)</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowDialog(true);
              }}
            />
            <DetailInputDialog
              isOpen={isShowDialog}
              inputOption={{ inputMode: 'new', detailItem: {} }}
              onDecision={() => {
                setResult('');
                setIsShowDialog(false);
              }}
              onCancel={() => {
                setResult('');
                setIsShowDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
