import { useState } from 'react';
import { useAuth } from '~/shared/contexts/AuthProvider';
import './SignOutButton.css';
import { IconWithCaptionButton } from '../ui/Button';

export function SignOutButton() {
  const auth = useAuth();
  const [enabled, setEnabled] = useState(true);

  const handleSignOut = () => {
    setEnabled(false);
    (async () => {
      try {
        await auth.signOut();
      } finally {
        setEnabled(false);
      }
    })();
  };

  return (
    <IconWithCaptionButton
      name="passwordChnage"
      caption="ログアウト"
      buttonType="basic"
      className="passwordChnage"
      iconType="logout"
      onClick={handleSignOut}
      properties={[
        {
          name: 'passwordChnage',
          propertyName: 'passwordChnage',
          propertyValue: 'passwordChnage',
        },
      ]}
      disabled={!enabled}
    />
  );
}
