import { useState } from 'react';
import {
  LoadingIcon,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISamplePageOldLoading = () => {
  const [isShowDialog, setShowDialog] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleShowCloseDialog = () => {
    setLoading(true);
    setTimeout(() => setShowDialog(true), 1000);
    setTimeout(() => setShowDialog(false), 2000);
    setTimeout(() => setLoading(false), 3000);
  };

  return (
    <div className="categories">
      <h3 id="old-loading">
        旧ローディング
        <br />
        <small>
          ローディングの仕様変更によるフォールバックが機能していることの確認
        </small>
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <CaptionButton
              buttonType="basic"
              caption="表示"
              name=""
              onClick={handleLoading}
            />
            <CaptionButton
              buttonType="basic"
              caption="ダイアログを表示"
              name=""
              onClick={() => {
                setShowDialog(true);
              }}
            />
            <CaptionButton
              buttonType="basic"
              caption="ローディング中にダイアログを表示して閉じる"
              name=""
              onClick={handleShowCloseDialog}
            />
            <SampleDialog
              isOpen={isShowDialog}
              onCancel={() => {
                setShowDialog(false);
              }}
            />
            {isLoading && <LoadingIcon />}
          </div>
        </div>
      </div>
    </div>
  );
};

interface SampleDialogProps {
  isOpen: boolean;
  onCancel: () => void;
}
const SampleDialog = ({ isOpen, onCancel }: SampleDialogProps) => {
  const [isLoading, setLoading] = useState(false);
  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const openModalProps: ModalDialogComponentProps = {
    cancel: onCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {},
    messageLabelId: {},
    elements: (
      <>
        <div>
          <CaptionButton
            buttonType="basic"
            caption="ローディング表示"
            name=""
            onClick={handleLoading}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption="閉じる"
            onClick={onCancel}
          />
        </div>
        {isLoading && <LoadingIcon />}
      </>
    ),
  };
  return <ModalDialogComponent {...openModalProps} />;
};
