import { useState, useMemo, useEffect } from 'react';
import { FilterPrimitive, FilterTerm } from '~/worker';
import { PresetItem } from '~/shared/services';
import { FilterViewQuery } from '../index';
import {
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui/Filterbox/DataFilterbox';
import {
  isBoolean,
  isFilterEq,
  isFilterNe,
  toEqualFilter,
  toNotEqualFilter,
} from '../filter';
import { checkDisalbedPresetProperty, getPresetPropertyValue } from '../preset';

export const getFilteringFilterboxBooleanValues = (
  terms: FilterTerm[]
): boolean[] => {
  const selectedValues: boolean[] = [];
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      if (isFilterEq(v) && isBoolean(v.$eq)) {
        selectedValues.push(v.$eq);
      }
      if (isFilterNe(v) && isBoolean(v.$ne)) {
        selectedValues.push(!v.$ne);
      }
    }
  }
  return selectedValues;
};

export const toBoolFilters = (name: string, values: FilterPrimitive[]) => {
  return values.reduce((terms, v) => {
    switch (v) {
      case true:
        terms.push(toEqualFilter(name, v));
        break;
      case false:
        // フィールドがomitされている場合は、falseで検索できないため`true以外`で検索する
        terms.push(toNotEqualFilter(name, true));
        break;
    }
    return terms;
  }, [] as FilterTerm[]);
};

const dataFilterboxItem = (
  displayName: string,
  value: string
): DataFilterboxItem => {
  return {
    displayName: displayName,
    value: value,
  };
};

const toDataFilterboxItemsByCodes = (
  items: DataFilterboxItem[],
  ...values: string[]
): DataFilterboxItem[] => {
  return values.map((value) => {
    const v = items.find((item) => item.value === value);
    return dataFilterboxItem(v?.displayName ?? '', v?.value ?? '');
  });
};

interface FilterInputFilterboxMultipleProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}

/**
 * 真偽値一致検索フィルタ
 *
 * 真偽フィールドに、'○'（=true）または'ー'（=false）を指定して検索を行う。
 *
 * - プリセット
 *     - type='filterboxBoolean'
 */
export function FilterInputFilterboxBoolean(
  props: FilterInputFilterboxMultipleProps
) {
  const { labelId, column, filterValue, presetItem, onChangeFilter } = props;

  const [dataFilterboxValue, setDataFilterboxValue] = useState<
    Array<DataFilterboxItem>
  >([]);

  const [dataFilterboxItems, isDisabledColumn] = useMemo<
    [DataFilterboxItem[], boolean]
  >(() => {
    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    const viewType = getPresetPropertyValue(
      presetItem?.property ?? [],
      column,
      'viewType'
    );
    let dataFilterBoxItems: DataFilterboxItem[] = [];
    switch (viewType) {
      case 'completed':
        dataFilterBoxItems = [
          dataFilterboxItem('完了', String(true)),
          dataFilterboxItem('未完了', String(false)),
        ];
        break;
      default:
        dataFilterBoxItems = [
          dataFilterboxItem('○', String(true)),
          dataFilterboxItem('ー', String(false)),
        ];
    }
    return [dataFilterBoxItems, isDisabled];

    // column, presetItem 変更時にのみ起動したいためlint除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, presetItem?.children]);

  useEffect(() => {
    const codes = getFilteringFilterboxBooleanValues(
      filterValue.filterTerms[column] ?? []
    );
    setDataFilterboxValue(
      toDataFilterboxItemsByCodes(dataFilterboxItems, ...codes.map(String))
    );
  }, [column, filterValue.filterTerms, dataFilterboxItems]);

  const onChangeDataFilterboxItem = (selectedItems: DataFilterboxItem[]) => {
    const values = selectedItems.map((v) => v.value);
    const filterTerms = toBoolFilters(column, values.map(convertStrToBool));
    setDataFilterboxValue(selectedItems);
    onChangeFilter && onChangeFilter(filterTerms);
  };

  return (
    <div className="input-area">
      <DataFilterbox
        data={dataFilterboxItems}
        value={dataFilterboxValue}
        labelId={labelId}
        multiple={true}
        searchOption={{ targets: 'displayName' }}
        name={column}
        columns={[column]}
        onChangeState={(v) => {
          onChangeDataFilterboxItem(v);
        }}
        disabled={isDisabledColumn}
      ></DataFilterbox>
    </div>
  );
}

function convertStrToBool(str: string) {
  try {
    const obj = JSON.parse(str.toLowerCase());
    return obj == true;
  } catch (e) {
    return str != '';
  }
}
