import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import '../Textbox/TextBox.css';
import './NumberBox.css';
import { validateRangeNumber } from '../validator';
import { GetMessage } from '../Message/Message';
import { convertNumber, getErrorBorderClassName } from '~/shared/utils';
import { useIntl } from 'react-intl';

export interface NumberBoxProps {
  name: string;
  className?: string;
  value?: number;
  placeholder?: string;
  formatter?: (v: number) => string;
  validator?: (v: number) => string[];
  converter?: (v: number) => number;
  validateOption?: NumberBoxValidateOption;
  externalIcon?: JSX.Element;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg?: number) => void;
}

export interface NumberBoxValidateOption {
  length?: { min?: number; max?: number };
  isSkippedValidation?: boolean;
  required?: boolean;
}

export function NumberBox(props: NumberBoxProps) {
  const intl = useIntl();
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });
  const { onChangeState, formatter, validator, converter } = props;
  // inputのクラス名
  const inputClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const format = useCallback(
    (v: number) => {
      if (!formatter) {
        return v.toString();
      }
      return formatter(v);
    },
    [formatter]
  );

  const validate = useCallback(
    (v: number): string[] => {
      if (!validator) {
        return [];
      }
      return validator(v);
    },
    [validator]
  );

  const convert = useCallback(
    (v: number): number => {
      if (!converter) {
        return v;
      }
      return converter(v);
    },
    [converter]
  );

  const maxLength =
    props.validateOption?.length?.max ?? Number.MAX_SAFE_INTEGER;
  const minLength =
    props.validateOption?.length?.min ?? Number.MIN_SAFE_INTEGER;
  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;

  // 引数チェック
  if (minLength > maxLength) {
    throw new Error(
      'assertion error: minLengthはmaxLengthより小さい値を設定してください'
    );
  }

  // useState
  const [message, setMessage] = useState<string[]>([]);
  const [value, setValue] = useState(props.value); // 内部処理用の値を保持
  const [displayValue, setDisplayValue] = useState(''); // 表示用の値を保持
  const changeParentState = useCallback(
    (v?: number) => {
      onChangeState && onChangeState(v);
    },
    [onChangeState]
  );

  // 親コンポーネントからprops.valueが変更された場合に入力値を変更する
  useEffect(() => {
    setValue(props.value);
    setDisplayValue(props.value ? format(props.value) : '');
  }, [format, props.value]);

  // クリアボタンの表示・非表示を制御
  const [clearButtonVisibility, setClearButtonVisibility] = useState<
    'visible' | 'hidden'
  >('hidden');

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnBlur = (inputValue: string) => {
    // バリデーションエラーのメッセージ
    const msg: string[] = [];

    // 必須入力チェック
    if (required && inputValue === '') {
      setMessage([REQUIRED_MESSAGE]);
      changeParentState();
      return;
    }

    if (inputValue === '') {
      changeParentState();
      return;
    }

    // 入力値（string）をnumberに変換して、以後の処理を行う
    let numberValue = 0;
    try {
      numberValue = convertNumber(inputValue);
    } catch (error) {
      setMessage(['半角の数値形式のみでご入力ください']);
      return;
    }

    msg.push(...validateRangeNumber(intl, numberValue, minLength, maxLength));

    // 固有バリデーションを行うかどうか
    if (isSkippedValidation) {
      changeParentState(numberValue);
      return;
    }

    // バリデーション
    msg.push(...validate(numberValue));

    // フォーマット実施チェック
    if (msg.length > 0) {
      // バリデーションエラーが存在する場合はエラーメッセージをセット
      setMessage([...msg]);
    } else {
      setMessage([]);
      // 四捨五入等の処理を想定した関数を実行
      const convertedValue = convert(numberValue);

      changeParentState(convertedValue); // 親要素に値を連携
      setValue(convertedValue); //内部保持用の値をセット
      setDisplayValue(format(convertedValue)); // フォーマットを行なった表示用文字列をセット
    }
  };

  return (
    <div className="TextBox NumberBox">
      <div
        className={`main-area ${
          props.disabled ? 'basic-disabled-input-border' : 'basic-input-border'
        } ${getErrorBorderClassName(message)}`}
        onMouseEnter={() => {
          // マウスオーバー時に入力値が無ければクリアボタンは非表示
          if (!displayValue) {
            return;
          }
          setClearButtonVisibility('visible');
        }}
        onMouseLeave={() => {
          setClearButtonVisibility('hidden');
        }}
        onClick={() => {
          //クリアボタンがhiddenになっている場合も含めてinputにフォーカス
          inputRef.current?.focus();
        }}
      >
        <input
          name={props.name}
          className={`right ${inputClassName}`}
          onBlur={(event) => {
            handleOnBlur(event.target.value);
          }}
          value={displayValue ?? ''}
          placeholder={props.placeholder}
          onChange={(event) => {
            setDisplayValue(event.target.value);
          }}
          onFocus={() => {
            // フォーカスされたタイミングで表示用の値から内部保持している値に書き換え
            setDisplayValue(value?.toString() ?? '');
          }}
          required={required}
          disabled={props.disabled}
          style={style}
          ref={inputRef}
          autoComplete="off"
        />

        {/* クリアボタン */}
        {!props.disabled && (
          <div
            className={`icon-wrapper ${
              props.disabled ? 'icon-wrapper-disabled' : ''
            }`}
          >
            <CloseIcon
              className="padding"
              onClick={(event) => {
                event.stopPropagation(); // input側のblur処理を抑止
                changeParentState();
                setValue(undefined);
                setMessage([]);
                setDisplayValue('');
              }}
              style={{ visibility: `${clearButtonVisibility}` }}
            ></CloseIcon>
          </div>
        )}

        {/* 外部指定アイコン */}
        {props.externalIcon && (
          <div className="padding">{props.externalIcon}</div>
        )}
      </div>

      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
