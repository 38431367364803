import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import {
  CSSProperties,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import './Dates.css';
import { Select, isUnselectedValidate } from '../Select/Select';
import dayjs from 'dayjs';
import { ReactComponent as CalendarIcon } from '@material-design-icons/svg/filled/calendar_month.svg';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage, GetMessageWithIntl } from '../Message/Message';
import { IntlShape, useIntl } from 'react-intl';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import { getErrorBorderClassName } from '~/shared/utils';

// 何年先まで年リストを作成するかのデフォルト値
const DEFAULT_TERM = 1;

export interface DatesProps {
  name: string;
  className?: string;
  value?: Date | string;
  unselectedOption?: DatesUnselectedOption;
  term?: number;
  validator?: (v: string[]) => string[];
  validateOption?: DatesValidateOption;
  disabled?: boolean;
  onChangeState?: (arg: Date | null) => void;
  style?: CSSProperties;
}

export interface DatesUnselectedOption {
  isUnselected?: boolean;
  value?: string;
}

// サジェスト表示props
export interface DateSuggestProps {
  name: string;
  className?: string;
  value?: Date | null;
  placeholder?: string;
  isCalendar?: boolean; // カレンダー表示するかどうか
  formatter?: (v: string) => string;
  validator?: (v: string) => string[];
  validateOption?: DatesValidateOption;
  disabled?: boolean;
  onChangeState?: (arg: Date | null) => void;
  style?: CSSProperties;
}

// YMD
interface YMD {
  year: string;
  month: string;
  date: string;
}

export interface DatesValidateOption {
  required?: boolean;
  isSkippedRequireCheck?: boolean;
}

const YEAR_NUM = 'YYYY';
const YEAR_DISPLAY = YEAR_NUM + '年';

// name属性区別用
const yearName = 'Y';
const monthName = 'M';

// term引数で指定された前後の期間の年リストを取得
function getYears(term: number) {
  const now = dayjs();

  const items = [];
  for (let index = 0 - term; index <= term; index++) {
    const y = now.add(index, 'y');
    items.push({
      value: y.format(YEAR_NUM),
      displayName: y.format(YEAR_DISPLAY),
    });
  }
  return items;
}

// 月リストを取得
function getMonths() {
  return [...Array(12)].map((_, i) => {
    const added = ++i;
    return { value: String(added), displayName: added + '月' };
  });
}

// YYYY/MM/dd形式に整型された文字列を返す
function getFormatedDate(inputValue: string): string {
  const ymd = getYMD(inputValue);

  const inputDate = dayjs(`${ymd.year}/${ymd.month}/${ymd.date}`);
  return inputDate.format('MM') !== ymd.month
    ? inputValue
    : inputDate.format('YYYY/M/D');
}

// 数字の文字列から年月日のオブジェクトを返す
function getYMD(v: string): YMD {
  // 現在年月日を取得
  const now = dayjs();
  const y = now.format('YYYY');
  const m = now.format('MM');

  const tmpSplit = v.split('/');
  if (tmpSplit.length > 1) {
    const lengthFlg = tmpSplit.length === 2;
    return {
      year: lengthFlg ? y : tmpSplit[0].padStart(4, '0'),
      month: (lengthFlg ? tmpSplit[0] : tmpSplit[1]).padStart(2, '0'),
      date: (lengthFlg ? tmpSplit[1] : tmpSplit[2]).padStart(2, '0'),
    };
  }

  // 「/」を削除
  const num = v.replaceAll('/', '');
  const length = num.length; //入力された値の文字数（桁数）

  // 入力文字を1文字づつ分割し、桁数を8桁に揃える
  const split = num.padStart(8, '0').split('');

  const ymd = {
    year: split.slice(0, 4).join(''),
    month: split.slice(4, 6).join(''),
    date: split.slice(6, 8).join(''),
  };

  // 入力された桁数をもとに年月日オブジェクトを編集
  if (length >= 5 && length < 8) {
    // 年入力の桁数で年表示を補完
    const splitNum = 8 - length;
    const frontY = y.split('').slice(0, splitNum).join('');
    const backY = split.slice(splitNum, 4).join('');
    ymd.year = frontY + backY;
  } else if (length > 2 && length <= 4) {
    // 月日まで指定されている
    ymd.year = y;
  } else if (length <= 2) {
    // 日まで指定されている
    ymd.year = y;
    ymd.month = m;
  }

  return ymd;
}

// 外部関数と月日のバリデーションを行う
function validateDate(
  intl: IntlShape,
  validator: (v: string) => string[],
  isRequire: boolean
): (v: string) => string[] {
  return (v: string) => {
    // 必須チェック
    if (isRequire && v === '') {
      return [GetMessageWithIntl(intl, { id: 'E0000003' })];
    }

    const message: string[] = [];
    // 必須項目でなく、入力値が空の場合はバリデートを行う意味がないのでスキップ
    if (v === '') {
      return message;
    }

    // 外部から渡された関数
    const msg = validator(v);
    if (msg.length > 0) {
      message.push(...msg);
      return message;
    }

    // 月又は日付が異常値の場合
    if (isInvalidDate(v)) {
      message.push(GetMessageWithIntl(intl, { id: 'E0000015' }));
      return message;
    }

    return message;
  };
}

// 年セレクトボックス
export function DateY(props: DatesProps) {
  // DateYのクラス名
  const dateYClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validate =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const changeParentState = props.onChangeState || (() => {});
  const term = props.term ?? DEFAULT_TERM;

  const initialValue =
    props.value instanceof Date
      ? dayjs(props.value).format('YYYY')
      : props.value ?? '';

  // useState
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    setValue([initialValue]);
  }, [initialValue]);

  useEffect(() => {
    const selected = value[0] ?? null;
    // 親要素へ値(セレクトボックス未選択時はnull, 選択時はDate型)を渡す
    changeParentState(
      selected && !isUnselectedValidate(selected) ? new Date(selected) : null
    );

    // 入力値確定時のみ起動させたい処理なので、lintから除外する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="DateY">
      <Select
        name={props.name}
        className={dateYClassName}
        items={getYears(term)}
        unselectedOption={{
          isUnselected: props.unselectedOption?.isUnselected,
          value: props.unselectedOption?.value,
        }}
        validator={validate}
        value={value}
        disabled={props.disabled}
        onChangeState={setValue}
        validateOption={{ required: required }}
        style={style}
      ></Select>
    </div>
  );
}

// 年月セレクトボックス
export function DateYM(props: DatesProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });
  // DateYMのクラス名
  const dateYMClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validate =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const changeParentState = props.onChangeState || (() => {});
  const term = props.term ?? DEFAULT_TERM;

  const initialYear = useMemo(
    () =>
      props.value instanceof Date
        ? dayjs(props.value).format('YYYY')
        : props.value ?? '',
    [props.value]
  );
  const initialMonth = useMemo(
    () =>
      props.value instanceof Date
        ? dayjs(props.value).format('M')
        : props.value ?? '',
    [props.value]
  );

  // useState
  const [selectedY, setSelectedY] = useState<string[]>([initialYear]);
  const [selectedM, setSelectedM] = useState<string[]>([initialMonth]);
  const [message, setMessage] = useState<string[]>([]);

  useEffect(() => {
    setSelectedY([initialYear]);
  }, [initialYear]);

  useEffect(() => {
    setSelectedM([initialMonth]);
  }, [initialMonth]);

  useEffect(() => {
    const y = selectedY[0] ?? null;
    const m = selectedM[0] ?? null;
    // 親要素へ値(セレクトボックスの片方または両方が未選択時はnull, 両方選択時はDate型)を渡す
    changeParentState(
      !y || !m || isUnselectedValidate([y, m]) ? null : new Date(`${y}/${m}`)
    );

    // 必須チェック
    if (required && (isUnselectedValidate(y) || isUnselectedValidate(m))) {
      setMessage([REQUIRED_MESSAGE]);
    } else {
      setMessage([]);
    }

    // 入力値確定時のみ起動させたい処理なので、lintから除外する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedY, selectedM]);

  return (
    <div className="DateYM">
      <div className="flex">
        <Select
          name={props.name + yearName}
          className={`${dateYMClassName} ${getErrorBorderClassName(message)}`}
          items={getYears(term)}
          unselectedOption={{
            isUnselected: props.unselectedOption?.isUnselected,
            value: props.unselectedOption?.value,
          }}
          validateOption={{ isSkippedValidation: true }}
          validator={validate}
          value={selectedY}
          disabled={props.disabled}
          onChangeState={setSelectedY}
          style={style}
        ></Select>

        <Select
          name={props.name + monthName}
          className={`${dateYMClassName} ${getErrorBorderClassName(message)}`}
          items={getMonths()}
          unselectedOption={{
            isUnselected: props.unselectedOption?.isUnselected,
            value: props.unselectedOption?.value,
          }}
          validateOption={{ isSkippedValidation: true }}
          value={selectedM}
          disabled={props.disabled}
          onChangeState={setSelectedM}
          style={style}
        ></Select>
      </div>
      {/* エラー表示 */}
      {!props.disabled ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}

interface ReducerData {
  displayValue: string;
  dateValue: Date | null;
}
type DateSuggestActionType = 'reset' | 'blur' | 'change' | 'set';
interface DateSuggestAction {
  type: DateSuggestActionType;
  value?: string | Date | null;
}

const toReducerData = (v: string | Date | null): ReducerData => {
  if (typeof v === 'string') {
    if (isInvalidDate(v)) {
      return { dateValue: null, displayValue: v };
    } else {
      const formattedValue = getFormatedDate(v);
      return {
        dateValue: new Date(formattedValue),
        displayValue: formattedValue,
      };
    }
  } else {
    return {
      dateValue: v,
      displayValue: dayjs(v).format('YYYY/M/D'),
    };
  }
};

const isInvalidDate = (v: string | undefined) => {
  const dateString = v ?? '';
  if (dateString === '') {
    return true;
  }

  const ymd = getYMD(dateString);

  // 月又は日付が異常値の場合
  if (
    Number(ymd.month) > 12 ||
    dayjs(`${ymd.year}/${ymd.month}/${ymd.date}`).format('MM') !== ymd.month
  ) {
    return true;
  }

  return false;
};
// サジェスト表示
export function DateSuggest(props: DateSuggestProps) {
  const intl = useIntl();

  const dateSuggestClassName = props.className ?? '';
  const style = props.style ?? {};

  // react-datepickerの日本語対応
  registerLocale('ja', ja);

  // オプショナルチェック
  const validate =
    props.validator ||
    (() => {
      return [];
    });
  const changeParentState = props.onChangeState || (() => {});
  const required = props.validateOption?.required ?? false;
  const isSkippedRequireCheck =
    props.validateOption?.isSkippedRequireCheck ?? false;
  const isCalendar = props.isCalendar ?? true;

  // useState
  const [isOpen, setOpen] = useState(false);
  const [message, setMessage] = useState<string[]>([]);
  // クリアボタンの表示・非表示を制御
  const [clearButtonVisibility, setClearButtonVisibility] = useState<
    'visible' | 'hidden'
  >('hidden');

  // 初期データを設定
  useEffect(() => {
    if (props.value?.getTime() === data.dateValue?.getTime()) {
      return;
    }

    dispatch({ type: 'set', value: props.value });
    // 入力中の値・カレンダー or 親要素からの連携された値かの区別するための実装なので、props.valueのみを依存値とする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    // 必須制御が変わって任意になる場合にエラー表示をクリアする
    if (!props.validateOption?.required) {
      // 値が空の場合だけに対応範囲を限定する
      if (!data.displayValue) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なので、props.validateOption?.requiredのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.validateOption?.required]);

  useEffect(() => {
    // 必須チェック不要になる場合にエラー表示をクリアする
    if (props.validateOption?.isSkippedRequireCheck) {
      // 値が空の場合だけに対応範囲を限定する
      if (!data.displayValue) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.validateOption?.isSkippedRequireCheck]);

  const reducer = (
    valueState: ReducerData,
    action: DateSuggestAction
  ): ReducerData => {
    if (
      action.value === null ||
      action.value === undefined ||
      action.value === ''
    ) {
      // reset処理の場合はチェックしない
      if (action.type !== 'reset') {
        // 入力状況に関わらずチェックを行う
        setMessage(
          validateDate(
            intl,
            validate,
            !isSkippedRequireCheck && required
          )(action.value ?? '')
        );
      }

      return { dateValue: null, displayValue: '' };
    }

    // 引数の方に合わせてReducerData型に分解
    const v = toReducerData(action.value);

    switch (action.type) {
      case 'reset':
        return { dateValue: null, displayValue: '' };

      case 'blur':
        setMessage(
          validateDate(
            intl,
            validate,
            !isSkippedRequireCheck && required
          )(v.displayValue)
        );
        return v;

      case 'change':
        return {
          dateValue: null,
          displayValue: typeof action.value === 'string' ? action.value : '',
        };

      case 'set': // 親要素からの連携値をセット
        return v;

      default:
        return valueState;
    }
  };

  const [data, dispatch] = useReducer(reducer, {
    dateValue: props.value ?? null,
    displayValue: props.value ? dayjs(props.value).format('YYYY/M/D') : '',
  });

  // カレンダーを表示している場合はクリアボタン非表示
  useEffect(() => {
    if (isOpen) {
      setClearButtonVisibility('hidden');
    }
  }, [isOpen]);

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className="DateSuggest TextBox">
      <div
        className={`main-area ${
          props.disabled ? 'basic-disabled-input-border' : 'basic-input-border'
        } ${getErrorBorderClassName(message)}`}
        onMouseEnter={() => {
          // マウスオーバー時に入力値が無ければクリアボタンは非表示
          // もしくは、カレンダーが表示されている場合はクリアボタンは非表示
          if (data.displayValue === '' || isOpen) {
            return;
          }
          setClearButtonVisibility('visible');
        }}
        onMouseLeave={() => {
          setClearButtonVisibility('hidden');
        }}
        onClick={() => {
          // カレンダーを閉じる際にコントロールをクリックした場合の制御
          if (data.displayValue === '' || isOpen) {
            setClearButtonVisibility('hidden');
          } else {
            setClearButtonVisibility('visible');
          }
          //クリアボタンがhiddenになっている場合も含めてinputにフォーカス
          inputRef.current?.focus();
        }}
      >
        <input
          name={props.name}
          className={dateSuggestClassName}
          type="text"
          onBlur={(event) => {
            dispatch({ type: 'blur', value: event.target.value });
            changeParentState(toReducerData(event.target.value).dateValue);
          }}
          value={data.displayValue}
          placeholder={props.placeholder ?? ''}
          onChange={(event) => {
            dispatch({ type: 'change', value: event.target.value ?? '' });
          }}
          required={required}
          disabled={props.disabled}
          style={style}
          ref={inputRef}
          autoComplete="off"
        />
        {/* クリアボタン */}
        {!props.disabled && (
          <div
            className={`icon-wrapper ${
              props.disabled ? 'icon-wrapper-disabled' : ''
            }`}
          >
            <CloseIcon
              onClick={(event) => {
                event.stopPropagation();
                dispatch({ type: 'reset' });
                changeParentState(null);
                inputRef.current?.focus();
                setMessage([]);
              }}
              style={{ visibility: `${clearButtonVisibility}` }}
            ></CloseIcon>
          </div>
        )}
        {isCalendar && (
          <div
            className={`icon-wrapper ${
              props.disabled ? 'icon-wrapper-disabled' : ''
            }`}
          >
            <DatePicker
              name={props.name + 'cl'}
              className={'calendar'}
              customInput={
                <CalendarIcon
                  className={props.disabled ? 'disabled-svg-icon' : ''}
                ></CalendarIcon>
              }
              locale="ja"
              dateFormat="yyyy/M/d"
              selected={
                data.dateValue ??
                new Date(dayjs(new Date()).format('YYYY/MM/DD'))
              }
              onChange={(date) => {
                // カレンダーの値変更時
                setOpen(false);
                dispatch({ type: 'blur', value: date });
                changeParentState(date);
              }}
              onSelect={(date: Date) => {
                const nowDt = dayjs(new Date()).format('YYYY/MM/DD');
                const setterDt = dayjs(date).format('YYYY/MM/DD');
                if (nowDt === setterDt) {
                  // カレンダーの値変更時
                  setOpen(false);
                  dispatch({ type: 'blur', value: date });
                  changeParentState(date);
                }
              }}
              open={isOpen}
              onInputClick={() => {
                // customInput部分のクリック時
                if (props.disabled) {
                  // disabledでは制御出来ないので、こちらで対応
                  return;
                }
                setOpen(true);
              }}
              disabled={props.disabled}
              onClickOutside={() => {
                // 領域外をクリックした場合
                setOpen(false);
              }}
            ></DatePicker>
          </div>
        )}
      </div>

      {!props.disabled ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
