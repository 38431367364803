import { mtechnavi } from '~/shared/libs/clientsdk';
import dayjs from 'dayjs';

/**
 * 項目種別
 */
export type BuildFormInputType =
  | 'text' // 1行テキスト
  | 'textarea' // 複数行テキスト
  | 'number' // 数値
  | 'date' // 日付
  | 'toggle' // チェックボックス
  | 'select' // 選択式（単一）
  | 'selectMultiple' // 選択式（複数）
  | 'file' // ファイル（単一）
  | 'label' // ラベル
  | 'labelMultiLine' // ラベル複数行
  | 'spacer'; // 余白

/**
 * 入力のない項目種別
 */
export const NON_VALUE_INPUT_TYPES: string[] = [
  'spacer',
  'label',
  'labelMultiLine',
] as BuildFormInputType[];

/** 項目サイズ */
export type BuildFormInputSize = 1 | 2 | 3 | 4;

export type FormValuesTypeNames =
  | 'mtechnavi.api.survey.SurveyBaseRequest'
  | 'mtechnavi.api.survey.SurveyRequest'
  | 'mtechnavi.api.form.SampleWorkForm'
  | 'mtechnavi.api.company.Company';

export type FormValueItem =
  | FormValueItemText
  | FormValueItemTextArea
  | FormValueItemNumber
  | FormValueItemDate
  | FormValueItemToggle
  | FormValueItemSelect
  | FormValueItemSelectMultiple
  | FormValueItemFile
  | FormValueItemLabel
  | FormValueItemLabelMultiLine
  | FormValueItemSpacer;

interface FormValueItemBase<T extends BuildFormInputType> {
  type: T;
  value?: unknown;
}

// １行テキスト
export interface FormValueItemText extends FormValueItemBase<'text'> {
  value?: mtechnavi.api.form.IFormControlText;
}
// 複数行テキスト
export interface FormValueItemTextArea extends FormValueItemBase<'textarea'> {
  value?: mtechnavi.api.form.IFormControlTextArea;
}
// 数値
export interface FormValueItemNumber extends FormValueItemBase<'number'> {
  value?: mtechnavi.api.form.IFormControlNumber;
}
// 日付
export interface FormValueItemDate extends FormValueItemBase<'date'> {
  value?: mtechnavi.api.form.IFormControlDate;
}
// チェックボックス
export interface FormValueItemToggle extends FormValueItemBase<'toggle'> {
  value?: mtechnavi.api.form.IFormControlToggle;
}
// 選択式（単一）
export interface FormValueItemSelect extends FormValueItemBase<'select'> {
  value?: mtechnavi.api.form.IFormControlSelect;
}
// 選択式（複数）
export interface FormValueItemSelectMultiple
  extends FormValueItemBase<'selectMultiple'> {
  value?: mtechnavi.api.form.IFormControlSelectMultiple;
}
// ファイル（単一）
export interface FormValueItemFile extends FormValueItemBase<'file'> {
  value?: mtechnavi.api.form.IFormControlFile;
}
// ラベル (入力要素ではないが一応定義)
export interface FormValueItemLabel extends FormValueItemBase<'label'> {
  value?: mtechnavi.api.form.IFormControlLabel;
}
// ラベル複数 (入力要素ではないが一応定義)
export interface FormValueItemLabelMultiLine
  extends FormValueItemBase<'labelMultiLine'> {
  value?: mtechnavi.api.form.IFormControlLabelMultiLine;
}
// 余白 (入力要素ではないが一応定義)
export interface FormValueItemSpacer extends FormValueItemBase<'spacer'> {
  value?: mtechnavi.api.form.IFormControlSpacer;
}

/** 一時的に利用する ID を生成 */
export const numberingTempId = (): string => {
  return new Date().getTime().toString();
};

// 仮採番中のデータ
export interface TypeTempAutoNames {
  maxCount?: number;
  values: AutoName[];
}

interface AutoName {
  prefix: string; // c 変わる可能性を考慮して格納
  counter: number; // 手動採番の場合 0
  displayName: string; // countが0以外の場合、prefix+count
}

interface NumberingItemAutoNameProps {
  typeCheckList?: mtechnavi.api.form.IItemAutoNameManagement[];
  tempAutoNames: TypeTempAutoNames;
  maxCounter?: number;
  inputType: BuildFormInputType;
}

/** 項目IDを採番 */
export const numberingItemAutoName = (
  props: NumberingItemAutoNameProps
): [string, TypeTempAutoNames] => {
  // 型チェック用のデータ
  const typeCheckList: mtechnavi.api.form.IItemAutoNameManagement[] =
    props.typeCheckList ?? [];

  // 仮採番中のデータ
  // 保存されるまで、チェック用に格納して取り回す必要がある
  const tempAutoNames: TypeTempAutoNames = props.tempAutoNames;

  // 最大カウンター
  const maxCounter: number = props.maxCounter ?? 0;

  // 項目タイプ
  const inputType: BuildFormInputType = props.inputType;

  // 採番結果の格納用
  const tempAutoName = {
    prefix: 'c',
    counter: 0,
    displayName: '',
  };

  // 仮番号存在チェック
  // フォーム内の仮番号の存在チェックを行う
  if (!tempAutoNames?.values.filter((v) => v.counter !== 0)) {
    // 存在しない場合、型チェックテーブル.自動採番カウントをカウンターへセットする
    tempAutoName.counter = maxCounter;
  } else {
    // 存在する場合、仮採番カウントの最大をカウンターへセットする
    // 念の為、maxCounterが大きい場合はmaxCounterを採用する
    tempAutoName.counter =
      (tempAutoNames?.maxCount ?? 0) > maxCounter
        ? tempAutoNames?.maxCount ?? 0
        : maxCounter;
  }

  let fixedNumber = false;
  while (!fixedNumber) {
    // 仮採番カウントの最大+1
    tempAutoName.counter = tempAutoName.counter + 1;
    // 採番結果を格納
    tempAutoName.displayName =
      tempAutoName.prefix + tempAutoName.counter.toString();
    tempAutoNames.maxCount = tempAutoName.counter;

    // 仮採番中のデータの存在チェック
    if (
      tempAutoNames?.values.some(
        (v) => v.displayName === tempAutoName.displayName
      )
    ) {
      // 存在する場合、再採番
      continue;
    }
    // 既存の項目IDとの型チェック
    if (
      !typeCheckList?.some(
        (v) =>
          v.itemAutoName === tempAutoName.displayName &&
          v.inputType !== inputType
      )
    ) {
      // 存在しない場合、仮番号を確定（新規項目ID）
      fixedNumber = true;
      break;
    } else {
      // 存在する場合、再採番
      continue;
    }
  }

  tempAutoNames.values.push(tempAutoName);

  return [tempAutoName.displayName, tempAutoNames];
};

// valueに登録時のString->Dateの変換
// stringのフォーマットが入力フォームだけの形式のため、converterに配置していません
export function convertStringToDate(value: string | undefined | null) {
  if (!value) {
    return null;
  }
  const date = dayjs(value).toDate();

  return date;
}

/**
 * 拡張子を除いたファイル名を取得
 */
export const getFileName = (name: string) => {
  const extensionIndex = name.lastIndexOf('.');
  const hasExtension = extensionIndex > 0 && extensionIndex < name.length - 1;
  return hasExtension ? name.substring(0, extensionIndex) : name;
};

/**
 * 入力フォーム定義の Union 型。
 * 他の機能で入力フォームを利用する際はここに追加する。
 */
export type WorkFormTypes =
  | mtechnavi.api.form.ISampleWorkForm
  | mtechnavi.api.survey.ISurveyRequestWorkForm
  | mtechnavi.api.survey.ISurveyResultWorkForm;

/**
 * 入力フォーム定義ごとに異なるIDプロパティを、定義を判別して取得する。
 */
export const getWorkFormId = (
  form?: WorkFormTypes
): string | null | undefined => {
  if (!form) {
    return;
  }
  if (isSampleWorkForm(form)) {
    return form.sampleWorkFormId;
  }
  if (isSurveyRequestWorkForm(form)) {
    return form.surveyRequestWorkFormId;
  }
  if (isSurveyResultWorkForm(form)) {
    return form.surveyResultWorkFormId;
  }
};

export const isSampleWorkForm = (
  form: WorkFormTypes
): form is mtechnavi.api.form.ISampleWorkForm => {
  return 'sampleWorkFormId' in form;
};
export const isSurveyRequestWorkForm = (
  form: WorkFormTypes
): form is mtechnavi.api.survey.SurveyRequestWorkForm => {
  return 'surveyRequestWorkFormId' in form;
};
export const isSurveyResultWorkForm = (
  form: WorkFormTypes
): form is mtechnavi.api.survey.SurveyResultWorkForm => {
  return 'surveyResultWorkFormId' in form;
};

export const isBuildFormInputType = (
  type: string | null | undefined
): type is BuildFormInputType => {
  const types: BuildFormInputType[] = [
    'text',
    'textarea',
    'number',
    'date',
    'toggle',
    'select',
    'selectMultiple',
    'file',
    'label',
    'labelMultiLine',
    'spacer',
  ];
  return (types as string[]).includes(type || '');
};
export const convertToFormValue = (
  type: string | null | undefined,
  value: unknown
): FormValueItem | null => {
  if (!isBuildFormInputType(type)) {
    return null;
  }
  switch (type) {
    case 'text':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlText | undefined,
      };
    case 'textarea':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlTextArea | undefined,
      };
    case 'number':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlNumber | undefined,
      };
    case 'date':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlDate | undefined,
      };
    case 'toggle':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlToggle | undefined,
      };
    case 'select':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlSelect | undefined,
      };
    case 'selectMultiple':
      return {
        type,
        value: value as
          | mtechnavi.api.form.IFormControlSelectMultiple
          | undefined,
      };
    case 'file':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlFile | undefined,
      };
    case 'label':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlLabel | undefined,
      };
    case 'labelMultiLine':
      return {
        type,
        value: value as
          | mtechnavi.api.form.IFormControlLabelMultiLine
          | undefined,
      };
    case 'spacer':
      return {
        type,
        value: value as mtechnavi.api.form.IFormControlSpacer | undefined,
      };
  }
};
