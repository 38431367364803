import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { MockOrganization } from './mock';
import { Checkbox, Textbox } from '~/shared/components/ui';
import { useState } from 'react';
import './OrganizationDetailDialog.css';

export type OrganizationDetailDialogMode = 'add' | 'edit' | 'show';
interface OrganizationDetailDialogInputOption {
  mode: OrganizationDetailDialogMode;
  parents?: MockOrganization[];
  data?: MockOrganization;
}
interface OrganizationDetailDialogMessageOption {
  headerLabelId: MessageProps;
}
export interface OrganizationDetailDialogProps {
  isOpen: boolean;
  inputOption: OrganizationDetailDialogInputOption;
  messageOption: OrganizationDetailDialogMessageOption;
  onDecision: (result: MockOrganization) => void;
  onCancel: () => void;
}
export const OrganizationDetailDialog = ({
  isOpen,
  inputOption,
  messageOption,
  onDecision,
  onCancel,
}: OrganizationDetailDialogProps) => {
  const { mode, parents, data } = inputOption;
  const intl = useIntl();

  const [isDisabled, setDisabled] = useState(data?.isDisabled);
  const [organizationCode, setOrganizationCode] = useState(
    mode !== 'add' ? data?.organizationCode : ''
  );
  const [displayName, setOrganizationName] = useState(
    mode !== 'add' ? data?.displayName : ''
  );
  const [abbrName, setOrganizationAbbr] = useState(
    mode !== 'add' ? data?.abbrName : ''
  );

  const handleDecision = () => {
    if (mode === 'edit') {
      onDecision({
        ...data,
        organizationCode,
        displayName,
        abbrName,
        isDisabled,
      });
    } else {
      onDecision({
        parentOrganizationCode: data?.organizationCode,
        organizationCode,
        displayName,
        abbrName,
        isDisabled,
      });
    }
  };

  const view = (
    <div className="OrganizationDetailDialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="breadcrumb-label">
                {GetMessageWithIntl(intl, {
                  prefixId: 'OrganizationDetailDialog',
                  id: 'orgBreadcrumb',
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <div className="parents-breadcrumb">
                {parents?.map((parent) => (
                  <span key={parent.organizationCode}>
                    {parent.displayName}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-25">
              <Checkbox
                name="disabled"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessageWithIntl(intl, {
                      prefixId: 'OrganizationDetailDialog',
                      id: 'disabled',
                    }),
                  },
                ]}
                value={isDisabled ? '1' : ''}
                columns={['disabled']}
                disabled={mode === 'show'}
                onChangeState={(checkedValues) =>
                  setDisabled(checkedValues.includes('1'))
                }
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <Textbox
                name="code"
                type="text"
                labelId="OrganizationDetailDialog.organizationCode"
                disabled={mode !== 'add'}
                value={organizationCode}
                onChangeState={setOrganizationCode}
                columns={['code']}
              ></Textbox>
            </div>
            <div className="w-33">
              <Textbox
                name="parentCode"
                type="text"
                labelId="OrganizationDetailDialog.parentCode"
                disabled={true}
                value={
                  mode === 'add'
                    ? data?.organizationCode
                    : data?.parentOrganizationCode
                }
                columns={['parentCode']}
              ></Textbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <Textbox
                name="name"
                type="text"
                labelId="OrganizationDetailDialog.organizationName"
                disabled={mode === 'show'}
                value={displayName}
                onChangeState={setOrganizationName}
                columns={['name']}
              ></Textbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <Textbox
                name="code"
                type="text"
                labelId="OrganizationDetailDialog.organizationAbbr"
                disabled={mode === 'show'}
                value={abbrName}
                onChangeState={setOrganizationAbbr}
                columns={['code']}
              ></Textbox>
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={onCancel}
        />
        {mode !== 'show' && (
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'save' })}
            onClick={handleDecision}
          />
        )}
      </div>
    </div>
  );

  const modalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...modalProps} />
    </>
  );
};
