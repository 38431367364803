import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  BusinessUnitBranchDialog,
  BusinessUnitBranchFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitBranchDialog';

export const UISampleBusinessUnitBranchDialogs = () => {
  const [inputResult, setInputResult] = useState('');
  const [isInputShow, setInputShow] = useState(false);
  const [editResult, setEditResult] = useState('');
  const [isEditShow, setEditShow] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState('');
  const [isConfirmationShow, setConfirmationShow] = useState(false);

  const inputOption: BusinessUnitBranchFormOption = {
    displayName: '大阪支店',
    address: {
      country: { code: '1' },
      postalCode: '0050006',
      region: { code: '27' },
      addressLines: ['大阪市浪速区恵美須町３丁目', 'テストサンライズ４０２'],
    },
    phoneNumber: '061-2345-678',
    faxNumber: '061-2345-679',
    overview: '海外陶芸品の輸入',
  };

  return (
    <div className="categories">
      <h3 id="dialog">拠点情報登録/確認ダイアログ</h3>
      <h4>新規登録</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setInputResult('');
                setInputShow(true);
              }}
            />
            <BusinessUnitBranchDialog
              isOpen={isInputShow}
              inputMode={true}
              onCancel={() => {
                setInputResult('キャンセルしました');
                setInputShow(false);
              }}
              onDesicion={(v) => {
                setInputResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setInputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{inputResult}</pre>
          </div>
        </div>
      </div>

      <h4>編集</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEditResult('');
                setEditShow(true);
              }}
            />
            <BusinessUnitBranchDialog
              isOpen={isEditShow}
              inputMode={true}
              inputOption={inputOption}
              onCancel={() => {
                setEditResult('キャンセルしました');
                setEditShow(false);
              }}
              onDesicion={(v) => {
                setEditResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setEditShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{editResult}</pre>
          </div>
        </div>
      </div>

      <h4>確認</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setConfirmationResult('');
                setConfirmationShow(true);
              }}
            />
            <BusinessUnitBranchDialog
              isOpen={isConfirmationShow}
              inputMode={false}
              inputOption={inputOption}
              onCancel={() => {
                setConfirmationResult('キャンセルしました');
                setConfirmationShow(false);
              }}
              onDesicion={(v) => {
                setConfirmationResult(
                  `出力しました。${JSON.stringify(v, null, 2)}`
                );
                setConfirmationShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{confirmationResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
