import { useEffect, useState } from 'react';
import { Container, Toast, LoadingIcon } from '~/shared/components';
import { useIntl } from 'react-intl';
import { getExceptionMessage } from '~/shared/utils';
import './Home.css';

export function Home() {
  const intl = useIntl();
  const [companyName, setCompanyName] = useState<string>('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await window.App.services.companyService.getCompany({});
        setCompanyName(res.displayNameLang.ja);
      } catch (err) {
        getExceptionMessage(intl, err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [intl]);

  return (
    <>
      <Container forceNavSwitch={true}>
        <div className="HomeMainArea">
          <div>
            <div className="visual-area">
              <p className="fonts">
                <span className="logo">
                  <img src="/assets/logo/arrow.svg" alt="arrow" />
                </span>
                <br />
                <span className="font">M-Tech</span>
                <br />
                <span className="font">Navi</span>
              </p>
            </div>
            <div className="company-name-area">{companyName ?? ''}</div>
          </div>
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
