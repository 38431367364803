import { useState } from 'react';
import { Tel, Textbox, ZipCode } from '~/shared/components/ui';
import { Numberbox } from '~/shared/components/ui/Numberbox';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { Amount } from '~/shared/components/parts/Amountbox/AmountBox';
import {
  validateEmail,
  validateIncludeProhibit,
  validateTel,
  validateZipCode,
} from '~/shared/components/parts/validator';
import { useIntl } from 'react-intl';

const EMAIL = 'メール';
const PASSWORD = 'パスワード';
const NUMBER = '数値';
const ZIPCODE = '郵便番号';
const TEL = '電話番号';

const EMAIL_REG_EXP = new RegExp('[^@]+@[^@]+');

export const UISampleFormattedInputs = () => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [number, setNumber] = useState<number>();
  const [amount, setAmount] = useState<Amount | null>(null);

  // 郵便番号
  const [zipCode, setZipCode] = useState('');
  // 電話番号
  const [tel, setTel] = useState('');

  return (
    <div className="categories">
      <h3 id="formatted-inputs">特定フォーマット入力</h3>
      <h4>{EMAIL}</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="mail"
              type="email"
              validator={validateEmail(intl, EMAIL_REG_EXP)}
              validateOption={{ required: true }}
              onChangeState={setEmail}
              columns={['mail']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{email}</pre>
          </div>
        </div>
      </div>

      <h4>{PASSWORD} 文字数8〜12文字 禁止文字: @?</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="pass"
              type="password"
              isCounter={true}
              validateOption={{ minLength: 8, maxLength: 12 }}
              validator={validateIncludeProhibit(intl, '@?')}
              onChangeState={setPassword}
              columns={['pass']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{password}</pre>
          </div>
        </div>
      </div>

      <h4>{NUMBER}</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Numberbox
              name="number"
              value={number}
              onChangeState={setNumber}
              converter={(v) => {
                const pow = Math.pow(10, 2);
                return Math.round(v * pow) / pow;
              }}
              formatter={(v) => v.toLocaleString()}
              columns={['number']}
              validateOption={{ length: { min: 1, max: 100 } }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{number}</pre>
          </div>
        </div>
      </div>

      <h4>{NUMBER}(整数)</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Amountbox
              name="amount"
              columns={['amount']}
              value={amount}
              onChangeState={setAmount}
              validateOption={{ length: { min: 1, max: 100 } }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(amount, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>{ZIPCODE}(必須入力)</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <ZipCode
              name="zipCode"
              validator={(v) => validateZipCode(intl, v)}
              validateOption={{ required: true }}
              onChangeState={setZipCode}
              columns={['zipCode']}
            ></ZipCode>
          </div>
          <div className="w-66">
            <pre className="output">{zipCode}</pre>
          </div>
        </div>
      </div>

      <h4>{TEL}(必須入力)</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Tel
              name="tel"
              validator={(v) => validateTel(intl, v)}
              validateOption={{ required: true }}
              onChangeState={setTel}
              columns={['tel']}
            ></Tel>
          </div>
          <div className="w-66">
            <pre className="output">{tel}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
