import { useState, useEffect, useMemo } from 'react';
import { Container, error, Toast } from '~/shared/components';
import {
  MenuListView,
  Preset,
  MenuItem,
  Property,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { useIntl } from 'react-intl';
import {
  FullMethodName_ListStaffAndComponentUnits,
  getExceptionMessage,
  getPresetAndSchema,
} from '~/shared/utils';

const VIEW_ID = 'MASTER_STAFF_LIST';

export function MasterStaffList() {
  const intl = useIntl();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  const handleExport: ImportDialogHandleFormat = {
    name: 'staff',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'staff',
    headerColumns: [],
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'masterStaffImport'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );
    // prest 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListStaffAndComponentUnits,
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'componentUnit.displayNameLang',
          'componentUnit.organizationUnit.displayNameLang',
          'staff.usable.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // バーガーメニューの関数
  const handleMenu = (prop?: string[], type?: string) => {
    switch (type) {
      case 'staffImport':
        // 取引ダイアログを表示
        setIsDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    menuItems.push({
      name: 'staffImport',
      func: (v?: string[]) => handleMenu(v, 'staffImport'),
    });
    menuItems.push({
      name: 'confirm',
      func: (v?: string[]) => handleMenu(v),
    });
    menuItems.push({ name: 'delete', func: (v?: string[]) => handleMenu(v) });
    menuItems.push({ name: 'tag', func: (v?: string[]) => handleMenu(v) });
    menuItems.push({ name: 'edit', func: (v?: string[]) => handleMenu(v) });
    return menuItems;
  };
  const menuViewlist = burgerMenuEvent().map((v) => v.name);
  return (
    <Container viewId={VIEW_ID}>
      <MenuListView
        headerTitle={{ viewId: VIEW_ID }}
        fullMethodName={FullMethodName_ListStaffAndComponentUnits}
        schema={schema}
        preset={preset}
        presetItems={childrenPresetItem}
        menuEvent={burgerMenuEvent()}
        listSkipType={{
          isTotal: true,
          isOutput: true,
        }}
        menuTarget={'staff.staffId'}
        menuViewlist={menuViewlist}
        importDialogOption={{
          isDisplay: true,
          isDialogOpen,
          dialogPreset,
          headerLabelId: {
            viewId: 'MASTER_STAFF_LIST',
            id: 'staffImport',
          },
          handleExport,
          handleImport,
          onChangeState: setIsDialogOpen,
        }}
        onHandleDownload={async (ids: string[]) => {
          try {
            const fileFormat = await window.App.services.ui.getFileFormat(
              'staff',
              intl
            );

            const result = await window.App.services.staffService.exportStaffs({
              ids,
              fileFormat,
            });
            return result.assetId;
          } catch (err) {
            error(getExceptionMessage(intl, err));
            throw err;
          }
        }}
      />
      <Toast />
    </Container>
  );
}
