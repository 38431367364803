import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  Property,
  Preset,
  getBooleanDataFormetterSchema,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
  getJoinedUserNameEmailSchema,
  MenuActionItem,
  ViewMenu,
  ListView,
  ConfirmationDialog,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListWorkTaskCatalogs,
  PageState,
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
  saveLocalStorageCheckboxData,
  getWorkerExceptionMessage,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'WT_REQUEST_CATALOG_LIST';
type WorktaskCatalog = mtechnavi.api.worktask.WorkTaskCatalog;

export function WtRequestCatalogList() {
  const [isLoading, setLoading] = useState(false);
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const [isReload, setReload] = useState(false);
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };

  const workTaskCatalogList = useRef<WorktaskCatalog[]>([]);
  const deleteIds = useRef<string[]>([]);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListWorkTaskCatalogs,
          ]);

        const booleanColumn = ['approval'];
        const booleanSch = getBooleanDataFormetterSchema(
          schemas[0],
          booleanColumn
        );
        const jaColumn: string[] = [
          'category.displayNameLang',
          'managementOrganization.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(booleanSch, jaColumn, 'ja');
        const dateColumn = ['workTaskCatalogCatalogUpdatedAt'];
        const dateSch = getDayFormetterDisplaySchema(formatterSch, dateColumn, {
          dayOpts: {
            formatType: 'YYYY/MM/DD HH:mm',
          },
        });

        // ユーザー名とアドレスを結合する処理
        const joinFormatterSch = getJoinedUserNameEmailSchema(
          dateSch,
          'workTaskCatalogCatalogUpdatedBy.displayName',
          'workTaskCatalogCatalogUpdatedBy.email'
        );

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(joinFormatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // メニューの「登録」処理
  const handleMenuInput = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      actionType: 'add',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/work-task/wt-request-catalog-input', { state });
  };

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/work-task/wt-request-catalog-confirmation', {
      state,
    });
  };

  // メニューの「指図案件登録」処理
  const handleMenuToWorkTaskInput = (prop?: string[]) => {
    if (!prop || prop.length === 0) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000023',
        }),
      ]);
      return;
    }
    if (prop.length > 1) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000073',
        }),
      ]);
      return;
    }

    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      actionType: 'add',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/work-task/wt-worktask-input', {
      state,
    });
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const handleMenuDelete = async () => {
    const deleteItems = workTaskCatalogList.current.filter((item) =>
      deleteIds.current.includes(item.workTaskCatalogId)
    );
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteWorkTaskCatalog',
        request: deleteItems,
      });
      success([toastSuccess]);
      clearCheckBox();
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setMenuModalIsOpen(false);
    setLoading(false);
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: () => handleMenuInput(),
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        deleteIds.current = v as string[];
        setMenuModalIsOpen(true);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 依頼登録
    menuItems.push({
      name: 'input',
      func: (v?: string[]) => handleMenuToWorkTaskInput(v),
    });
    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    workTaskCatalogList.current = items as unknown as WorktaskCatalog[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="WorkTaskCatalogList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'workTaskCatalogId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListWorkTaskCatalogs}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
            }}
          />
          <ConfirmationDialog
            isOpen={menuModalIsOpen}
            viewMessage={deleteMessage}
            messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
            onDecision={() => {
              handleMenuDelete();
            }}
            onCancel={() => setMenuModalIsOpen(false)}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
