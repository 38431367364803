import { useIntl } from 'react-intl';
import { Property } from 'csstype';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import './base.css';
import './SimpleListDialog.css';

import { SimpleListView } from '~/shared/components/ui';

export interface SimpleListDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface SimpleListDialogActionOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (data: any) => void;
  onFullDownLoad?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (data: any) => void;
  onDeleteAll?: () => void;
}

export interface SimpleListDialogColumnSetting {
  header?: MessageProps;
  propertyName: string;
  width?: string;
  align?: Property.TextAlign;
  readonly?: boolean;
}

export interface SimpleListDialogViewOptions {
  readonly?: boolean;
  omitHeader?: boolean;
  omitFooter?: boolean;
  previewRowCount?: number;
  keyColumn?: string;
  columns: SimpleListDialogColumnSetting[];
}

export interface SimpleListDialogProps {
  isOpen: boolean;
  messageOption: SimpleListDialogMessageOption;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  actionOption: SimpleListDialogActionOptions;
  viewOption: SimpleListDialogViewOptions;
  onCancel: () => void;
}

export const SimpleListDialog = ({
  isOpen,
  messageOption,
  data,
  actionOption,
  viewOption,
  onCancel,
}: SimpleListDialogProps) => {
  const intl = useIntl();

  const elements = (
    <div className="simple-list-dialog">
      <SimpleListView
        data={data}
        viewOptions={viewOption}
        actionOptions={actionOption}
      />
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, {
            prefixId: 'DIALOG_CAPTION',
            id: 'return',
          })}
          onClick={() => onCancel()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="SimpleListDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
