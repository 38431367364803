/* localStorageに関する処理 */

import dayjs from 'dayjs';
import { ViewId } from '.';
import { ListViewPageSetting } from '../components/ui';

// 遷移時（別ウインド、別タブ）にローカルストレージに値を保存する際の定形処理
export function setLocalStorage(keyPrefix: string, value: string[]) {
  // localStorageに情報が溜まり続けないように現在時刻を付加
  const key = `${keyPrefix}/` + dayjs().unix();
  if (value) {
    localStorage.setItem(key, value.join('_'));
  }
  return key;
}
// 遷移先でハッシュフラグメント形式からlocalStorageの値を取得
export function getLocalStorageFromHashFragment(hashFragment: string) {
  return localStorage.getItem(hashFragment.replace('#', ''))?.split('_') ?? [];
}

export const getLocalStorageKey = (viewId: ViewId, myEmail: string) => {
  const viewIdWithEmails: string[] = [viewId];
  viewIdWithEmails.push(myEmail);
  return viewIdWithEmails.join('.');
};

export function saveInitialLocalStorageData(viewId: ViewId, myEmail: string) {
  const viewIdWithEmail = getLocalStorageKey(viewId, myEmail);
  const v: ListViewPageSetting = {
    checkedValues: [],
    pageSize: '50',
    pageNumber: '1',
  };
  localStorage.setItem(viewIdWithEmail, JSON.stringify(v));
}

// ListViewの情報（フィルター、ソート、絞り込み用id）をlocalstorageに格納
export function saveFilterAndSortData(
  viewId: ViewId,
  data: ListViewPageSetting,
  myEmail: string
) {
  const viewIdWithEmail = getLocalStorageKey(viewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (item) {
    const prevItem: ListViewPageSetting = JSON.parse(item);
    const v: ListViewPageSetting = {
      filter: data.filter ?? prevItem.filter,
      sort: data.sort ?? prevItem.sort,
      ids: data.ids ?? prevItem.ids,
      checkedValues: data.checkedValues ?? prevItem.checkedValues,
      pageSize: data.pageSize ?? prevItem.pageSize,
      pageNumber: data.pageNumber ?? prevItem.pageNumber,
    };
    localStorage.setItem(viewIdWithEmail, JSON.stringify(v));
  } else {
    localStorage.setItem(viewIdWithEmail, JSON.stringify(data));
  }
}

// localStorageに設定された絞り込み条件を取得する際に使用する（別タブで開いた画面を想定）
export function getFilterAndSortData(
  sourceViewId: ViewId,
  myEmail: string
): ListViewPageSetting | null {
  if (!sourceViewId) {
    return null;
  }
  const viewIdWithEmail = getLocalStorageKey(sourceViewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (!item) {
    return null;
  }
  return JSON.parse(item) ?? null;
}

// localStorageに設定されたlistViewのソート条件を遷移先の画面で利用する際に使用する
export function getSortSettingFromLocalStorage(
  sourceViewId: ViewId,
  myEmail: string
) {
  const viewIdWithEmail = getLocalStorageKey(sourceViewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (item) {
    const tmp = JSON.parse(item);
    if (tmp.sort) {
      return tmp.sort;
    }
  }

  return [];
}

// localStorageに設定された絞り込み条件を利用する際に使用する（別タブで開いた画面を想定）
export function getFilteridsFromLocalStorage(
  sourceViewId: ViewId,
  myEmail: string
) {
  const viewIdWithEmail = getLocalStorageKey(sourceViewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (item) {
    const tmp = JSON.parse(item);
    if (tmp.ids) {
      return tmp.ids;
    }
  }

  return [];
}
const CEASAR_KEY = 4;

// ローカルストレージに保持するデータの暗号化（簡易）
function ceasarEncode(value: string): string {
  const encodeValue = encodeURIComponent(value);
  let encodeResult = '';
  for (let i = 0; i < encodeValue.length; i++) {
    encodeResult += String.fromCharCode(encodeValue.charCodeAt(i) + CEASAR_KEY);
  }
  return encodeResult;
}

// ローカルストレージに保持するデータの複合化（簡易）
function ceasarDecode(encodeValue: string): string {
  let decodeResult = '';
  for (let i = 0; i < encodeValue.length; i++) {
    decodeResult += String.fromCharCode(encodeValue.charCodeAt(i) - CEASAR_KEY);
  }
  return decodeURIComponent(decodeResult);
}

// ローカルストレージのチェックボックスの値を取得（複合含む）
export function getLocalStorageCheckboxData(viewId: ViewId, myEmail: string) {
  const checkedValue =
    getFilterAndSortData(viewId, myEmail)?.checkedValues ?? [];
  return checkedValue.map((v) => ceasarDecode(v));
}
// ローカルストーレージにチェックボックスの値を保存（暗号化含む）
export function saveLocalStorageCheckboxData(
  viewId: ViewId,
  values: string[],
  myEmail: string
) {
  const saveValues: string[] = [];
  if (values.length > 0) {
    saveValues.push(...values.map((v) => ceasarEncode(v)));
  }
  saveFilterAndSortData(
    viewId,
    {
      checkedValues: saveValues,
    },
    myEmail
  );
}
