import React, { RefObject, useState } from 'react';
import { DragMode, ViewerRef } from '../Viewer';
import { ReactComponent as CommentIcon } from '@material-design-icons/svg/filled/add_comment.svg';
import { ReactComponent as MoveIcon } from '@material-design-icons/svg/filled/control_camera.svg';
import { ReactComponent as CropIcon } from '@material-design-icons/svg/filled/crop.svg';
import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/filled/north_west.svg';
import './DragModeSelector.css';
import { IconButton } from '~/shared/components/parts/Button/IconButton';
import { useCommentPane, useCommentPaneDispatch } from './CommentPaneProvider';
import { SHAPE_TYPE_ARROW, SHAPE_TYPE_RECT } from './Shapes';

interface DragModeSelectorProps {
  viewerRef?: RefObject<ViewerRef>;
}
export const DragModeSelector = ({ viewerRef }: DragModeSelectorProps) => {
  const [isShowSelector, setShowSelector] = useState(false);
  const [localDragMode, setLocalDragMode] = useState<DragMode>(
    viewerRef?.current?.dragMode || 'move'
  );
  const { selection } = useCommentPane();
  const commentPaneDispatch = useCommentPaneDispatch();
  const handleChangeMode = (mode: DragMode) => {
    if (!viewerRef?.current?.changeDragMode) {
      return;
    }
    viewerRef?.current?.changeDragMode(mode);
    setLocalDragMode(mode);
    setShowSelector(false);
  };

  const handleChangeShape = (shapeType: string) => {
    commentPaneDispatch({ type: 'changeShapeType', shapeType });
    handleChangeMode('selection');
    setShowSelector(false);
  };

  const getCommentModeIcon = () => {
    if (localDragMode === 'move') {
      return <CommentIcon />;
    }
    switch (selection.attributes.selectionShape) {
      case SHAPE_TYPE_RECT:
        return <CropIcon />;
      case SHAPE_TYPE_ARROW:
        return <ArrowIcon />;
    }
    return <></>;
  };

  return (
    <ul className="DragModeSelector">
      <li className={`${localDragMode === 'move' ? 'active' : ''}`}>
        <IconButton
          name=""
          icon={<MoveIcon />}
          onClick={() => handleChangeMode('move')}
        />
      </li>
      <li className={`${localDragMode === 'selection' ? 'active' : ''}`}>
        <IconButton
          name=""
          icon={getCommentModeIcon()}
          onClick={() => setShowSelector(!isShowSelector)}
        />
        {isShowSelector && (
          <div
            className="selector-bg"
            onClick={() => setShowSelector(false)}
          ></div>
        )}
        <ul className={`selector ${isShowSelector ? 'show' : ''}`}>
          <li
            className={
              localDragMode === 'selection' &&
              selection.attributes.selectionShape === SHAPE_TYPE_RECT
                ? 'active'
                : ''
            }
          >
            <IconButton
              name=""
              icon={<CropIcon />}
              onClick={() => handleChangeShape(SHAPE_TYPE_RECT)}
            />
          </li>
          <li
            className={
              localDragMode === 'selection' &&
              selection.attributes.selectionShape === SHAPE_TYPE_ARROW
                ? 'active'
                : ''
            }
          >
            <IconButton
              name=""
              icon={<ArrowIcon />}
              onClick={() => handleChangeShape(SHAPE_TYPE_ARROW)}
            />
          </li>
        </ul>
      </li>
    </ul>
  );
};
