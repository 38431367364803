import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import {
  ConfirmationDialog,
  NavigationIconMenu,
  PageNavigation,
} from '~/shared/components/ui';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListEstimateRequestPlans,
  PageState,
  ViewId,
  getDateFormatWithTimezone,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import './EstEstimateRequestPlanConfirmation.css';

type EstimateRequestPlan = mtechnavi.api.estimation.IEstimateRequestPlan;
const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION';
export function EstEstimateRequestPlanConfirmation() {
  // ログイン者の情報特定用email
  const intl = useIntl();
  const handleError = useErrorHandler();
  const [isLoading, setLoading] = useState(false);
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});
  const navi = useNavigate();

  const [item, setItem] = useState<EstimateRequestPlan>();
  // PageInfo
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };

  // ダイアログ
  const [isShowDelete, setShowDelete] = useState(false);

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListEstimateRequestPlans,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      estimateRequestPlanId: { $in: location.ids ?? [] },
    },
    sort: [],
    requestBody: {
      estimateRequestPlanIds: location.ids ?? [],
    },
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  // pageデータ変更時の処理
  useEffect(() => {
    const items = page.originalItems as unknown as EstimateRequestPlan[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // 初回画面用データ
  useEffect(() => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListEstimateRequestPlans,
      pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    });

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListEstimateRequestPlans,
      pageNumber: n,
    });
  };

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 削除
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: () => setShowDelete(true),
    });

    return iconItems;
  };

  const handleMenuDelete = async () => {
    if (!item) {
      return;
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteEstimateRequestPlan',
        request: item,
      });

      setShowDelete(false);
      success([successMessage]);
      navi('/estimate/est-estimate-request-plan-list');
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstimateRequestPlanConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/estimate/est-estimate-request-plan-list"
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              iconItems={navigationIconItems()}
              handleMovePage={(n) => handleMovePage(n)}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: item?.updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: item?.updatedAt ?? undefined,
                  content:
                    item?.updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100 font-b">
                  {item?.displayNameLang?.ja ?? ''}
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  {item?.status?.displayNameLang?.ja ?? ''}
                </div>
              </div>
            </div>
            <div className="input-line space-bottom">
              <div className="item-group-100">
                <div className="w-100">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'desiredDt',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {getDateFormatWithTimezone(
                    item?.sapWorkOrder?.desiredReceiveDt
                  ) ?? ''}
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'trackingCode',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.trackingCode ?? ''}
                </div>
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'attachment',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.attachment ? '○' : '-'}
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'freeItem',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.freeItem ?? ''}
                </div>
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'quantity',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.quantity?.amount ?? ''}
                  {item?.sapWorkOrder?.quantity?.unit ?? ''}
                </div>
              </div>
            </div>
            <div className="input-line space-bottom">
              <div className="item-group-100">
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'workCategory',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.workCategory?.itemCode ?? ''}&nbsp;
                  {item?.sapWorkOrder?.workCategory?.displayName
                    ? `(${item?.sapWorkOrder?.workCategory?.displayName})`
                    : ''}
                </div>
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'workUsage',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.workUsage?.itemCode ?? ''}&nbsp;
                  {item?.sapWorkOrder?.workUsage?.displayName
                    ? `(${item?.sapWorkOrder?.workUsage?.displayName})`
                    : ''}
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'receivedProperties',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {getDateFormatWithTimezone(
                    item?.sapWorkOrder?.receiverProperties?.receivedDt
                  ) ?? ''}
                  &nbsp;
                  {item?.sapWorkOrder?.receiverProperties?.organizationUnit
                    ?.displayName ?? ''}
                  &nbsp;
                  {item?.sapWorkOrder?.receiverProperties?.staff?.displayName ??
                    ''}
                </div>
                <div className="w-33">
                  <span className="label">
                    {GetMessageWithIntl(intl, {
                      id: 'requesterProperties',
                      prefixId: 'EST_ESTIMATE_REQUEST_PLAN_CONFIRMATION',
                    })}
                  </span>
                  {item?.sapWorkOrder?.requesterProperties?.organizationUnit
                    ?.displayNameLang?.ja ?? ''}
                  &nbsp;
                  {item?.sapWorkOrder?.requesterProperties?.staff
                    ?.displayNameLang?.ja ?? ''}
                  &nbsp;
                  {item?.sapWorkOrder?.requesterProperties?.phoneNumber
                    ? `(${item?.sapWorkOrder?.requesterProperties?.phoneNumber})`
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isShowDelete}
          viewMessage={deleteMessage}
          onDecision={() => {
            handleMenuDelete();
          }}
          onCancel={() => setShowDelete(false)}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
