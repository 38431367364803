import { useState } from 'react';
import { ExtendFileUploader, FileItem } from '~/shared/components/file';

export const UISampleExtendFileUploads = () => {
  // ファイルアップロード
  const [resultSingleUpload, setResultSingleUpload] = useState<FileItem[]>([]);
  const [resultMultipleUpload, setResultMultipleUpload] = useState<FileItem[]>(
    []
  );
  const [resultSingleDnDUpload, setResultSingleDnDUpload] = useState<
    FileItem[]
  >([]);
  const [resultMultipleDnDUpload, setResultMultipleDnDUpload] = useState<
    FileItem[]
  >([]);

  return (
    <div className="categories">
      <h3 id="extends-file-uploads">拡張版ファイルアップロード</h3>

      <h4>
        単一選択 <small>(1MB以下、.text, .txt, .csv)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-40">
            <ExtendFileUploader
              name="upload"
              onUpload={setResultSingleUpload}
              validateOption={{
                allowedFileExtensions: ['text', 'txt', 'csv'],
                maxFileSizeInMebis: 1,
              }}
            />
          </div>
          <div className="w-60">
            <pre className="output">
              {JSON.stringify(resultSingleUpload, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        複数選択可 <small>(2MB以下、3つまで、.png)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-40">
            <ExtendFileUploader
              name="upload"
              multiple={true}
              validateOption={{
                allowedFileExtensions: ['png'],
                maxFileSizeInMebis: 2,
                maxFileCount: 3,
              }}
              onUpload={setResultMultipleUpload}
            />
          </div>
          <div className="w-60">
            <pre className="output">
              {JSON.stringify(resultMultipleUpload, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        単一選択 ドラッグ&ドロップ <small>(2MB以下、.png)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-40">
            <ExtendFileUploader
              name="sdnd"
              dndOption={{
                enabled: true,
              }}
              validateOption={{
                allowedFileExtensions: ['png'],
                maxFileSizeInMebis: 2,
              }}
              onUpload={setResultSingleDnDUpload}
            />
          </div>
          <div className="w-60">
            <pre className="output">
              {JSON.stringify(resultSingleDnDUpload, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        複数選択可 ドラッグ&ドロップ
        <small>(1MB以下、ファイル数制限なし、.text, .txt, .csv)</small>
        <br />
        <small>
          ドラッグ&ドロップエリアに追加の class 属性を設定、一覧表示 5 件まで
        </small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-40">
            <ExtendFileUploader
              name="mdnd"
              multiple={true}
              dndOption={{
                enabled: true,
                dndAreaClassName: 'custom-dnd-area',
              }}
              validateOption={{
                allowedFileExtensions: ['text', 'txt', 'csv'],
                maxFileSizeInMebis: 1,
              }}
              resultOption={{
                previewRowCount: 5,
              }}
              onUpload={setResultMultipleDnDUpload}
            />
          </div>
          <div className="w-60">
            <pre className="output">
              {JSON.stringify(resultMultipleDnDUpload, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
