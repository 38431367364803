/**
 * DataView の状態を管理する Reducer
 *
 * 一覧の内部的なstate(表示ページ・チェック状態)を一元管理する。
 * 複合的な制御(ページサイズが変われば表示データが変わる等)が必要な項目に限定し、
 * 見た目の変化だけのプロパティ(そのstate以外の別のstateに影響しないもの)はここでは管理しない。
 */

// 状態
interface DataViewState {
  allContents: {}[]; // 全データ
  displayContents: {}[]; // 表示ページのデータ
  selectedItemIds: string[]; // 選択状態
  maxPage: number; // 最大ページ
  currentPage: number; // 現在ページ
  pageSize: number; // ページサイズ
}

// 状態初期値
export const dataViewInitialState: DataViewState = {
  allContents: [],
  displayContents: [],
  selectedItemIds: [],
  maxPage: 1,
  currentPage: 1,
  pageSize: 20,
};

/**
 * 選択状態セット処理(まとめて全部セット)
 */
interface ActionSetSelectedItems {
  type: 'setSelectedItems';
  selectedItems: string[]; // 選択状態のID全件
}
const actionSetSelectedItems = (
  state: DataViewState,
  itemIds: string[]
): DataViewState => {
  return {
    ...state,
    selectedItemIds: [...itemIds],
  };
};

/**
 * 一覧データ変更時時処理
 */
interface ActionChangeContents {
  type: 'changeContents';
  contents?: Object[]; // リストデータ全件
}
const actionChangeContents = (
  state: DataViewState,
  contents?: Object[]
): DataViewState => {
  if (!contents) {
    return state;
  }
  const displayContents = contents.slice(0, state.pageSize) ?? [];
  const maxPage = Math.max(Math.ceil(contents.length / state.pageSize), 1);
  return {
    ...state,
    currentPage: 1,
    maxPage,
    allContents: contents,
    displayContents,
  };
};

/**
 * 現在ページ変更時時処理
 */
interface ActionChangeCurrentPage {
  type: 'changeCurrentPage';
  page: number;
}
const actionChangeCurrentPage = (
  state: DataViewState,
  page: number
): DataViewState => {
  if (state.currentPage === page) {
    // 変化がなければ何も変えずに返す
    return state;
  }
  const currentPage = Math.min(Math.max(1, page), state.maxPage);
  const offset = (page - 1) * state.pageSize;
  const displayContents = state.allContents.slice(
    offset,
    offset + state.pageSize
  );
  return {
    ...state,
    currentPage,
    displayContents,
  };
};

/**
 * ページサイズ変更時処理
 */
interface ActionChangePageSize {
  type: 'changePageSize';
  pageSize: number;
}
const actionChangePageSize = (
  state: DataViewState,
  pageSize: number
): DataViewState => {
  if (state.pageSize === pageSize) {
    // 変化がなければ何も変えずに返す
    return state;
  }
  const displayContents = state.allContents.slice(0, pageSize) ?? [];
  const maxPage = Math.max(Math.ceil(state.allContents.length / pageSize), 1);
  return {
    ...state,
    pageSize,
    currentPage: 1, //
    maxPage,
    displayContents,
  };
};

/**
 * 選択状態変更処理(単一)
 */
interface ActionChangeSelection {
  type: 'changeSelection';
  selectionItemId: string; // 選択状態を変更したい対象のID
}
const actionChangeSelection = (
  state: DataViewState,
  itemId: string
): DataViewState => {
  let checkedItems: string[] = [];
  if (state.selectedItemIds.includes(itemId)) {
    checkedItems = state.selectedItemIds.filter(
      (selectedItemId) => selectedItemId !== itemId
    );
  } else {
    checkedItems = [...state.selectedItemIds, itemId];
  }
  return {
    ...state,
    selectedItemIds: checkedItems,
  };
};

type DataViewActions =
  | ActionSetSelectedItems
  | ActionChangeContents
  | ActionChangeCurrentPage
  | ActionChangePageSize
  | ActionChangeSelection;

export const dataViewReducer = (
  state: DataViewState,
  action: DataViewActions
) => {
  switch (action.type) {
    case 'setSelectedItems':
      return actionSetSelectedItems(state, action.selectedItems);
    case 'changeContents':
      return actionChangeContents(state, action.contents);
    case 'changeCurrentPage':
      return actionChangeCurrentPage(state, action.page);
    case 'changePageSize':
      return actionChangePageSize(state, action.pageSize);
    case 'changeSelection':
      return actionChangeSelection(state, action.selectionItemId);
    default:
      throw new Error(`unknown action: ${action}`);
  }
};
