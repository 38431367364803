import { Dispatch, useReducer, useCallback } from 'react';
import { IntlShape } from 'react-intl';
import type {
  DummyUser,
  SortTerm,
  FilterExpression,
  AggregateStage,
  UnionGroup,
} from '~/worker';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import { error } from '~/shared/components/parts/Toast/Toast';

interface State {
  fullMethodName: string;
  pageNumber: number;
  pageSize: number;
  maxPageNumber: number;
  filter: FilterExpression;
  // 型は呼び出し元によるため固定できないため、lintから除外する
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  requestBody?: { [k: string]: any };
  aggregate?: AggregateStage[];
  sort: Array<SortTerm>;
  items: Array<DummyUser>;
  allItems?: Array<DummyUser>;
  originalItems?: Array<DummyUser>;
  uniongroupItems?: UnionGroup[];
  loadingState?: (arg: boolean) => void;
  onError?: (err: unknown) => void;
}

interface ActionQuery {
  type: 'query';
  fullMethodName: string;
  pageNumber?: number;
  pageSize?: number;
  maxPageNumber?: number;
  filter?: FilterExpression;
  // 型は呼び出し元によるため固定できないため、lintから除外する
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  requestBody?: { [k: string]: any };
  sort?: Array<SortTerm>;
  aggregate?: AggregateStage[];
  uniongroupItems?: UnionGroup[];
  onChangeItems?: (items: Array<DummyUser>) => void;
}

interface ActionReload {
  type: 'reload';
  pageNumber?: number;
  pageSize?: number;
  fullMethodName: string;
  sort?: Array<SortTerm>;
  filter?: FilterExpression;
  // 型は呼び出し元によるため固定できないため、lintから除外する
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  requestBody?: { [k: string]: any };
  aggregate?: AggregateStage[];
  uniongroupItems?: UnionGroup[];
  delayTime?: number;
  onChangeItems?: (items: Array<DummyUser>) => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

interface ActionRefresh {
  type: 'refresh';
  fullMethodName: string;
  pageNumber: number;
  pageSize: number;
  maxPageNumber: number;
  filter: FilterExpression;
  // 型は呼び出し元によるため固定できないため、lintから除外する
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  requestBody?: { [k: string]: any };
  aggregate?: AggregateStage[];
  sort: Array<SortTerm>;
  items: Array<DummyUser>;
  allItems?: Array<DummyUser>;
  originalItems?: Array<DummyUser>;
}

type Action = ActionQuery | ActionReload | ActionRefresh;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'refresh':
      return {
        fullMethodName: action.fullMethodName,
        pageNumber: action.pageNumber ?? state.pageNumber,
        pageSize: action.pageSize ?? state.pageSize,
        maxPageNumber: action.maxPageNumber ?? state.maxPageNumber,
        filter: action.filter ?? state.filter,
        aggregate: action.aggregate ?? state.aggregate,
        requestBody: action.requestBody ?? state.requestBody,
        sort: action.sort ?? state.sort,
        items: action.items ?? state.items,
        allItems: action.allItems ?? [],
        originalItems: action.originalItems ?? state.originalItems,
      };
    default:
      throw new Error(`unhandled action.type=${action.type}`);
  }
}

export function usePagenator(
  initialState: State,
  intl?: IntlShape
): [State, Dispatch<Action>] {
  const [page, dispatch_] = useReducer(reducer, initialState);
  const dispatch = useCallback(
    (action: Action) => {
      (async () => {
        switch (action.type) {
          case 'query': {
            const res = await window.App.services.ui.worker.pagenate({
              action: 'query',
              fullMethodName: action.fullMethodName,
              pageNumber: action.pageNumber ?? page.pageNumber,
              pageSize: action.pageSize ?? page.pageSize,
              filter: action.filter ?? page.filter,
              sort: action.sort ?? page.sort,
              aggregate: action.aggregate ?? page.aggregate,
              requestBody: action.requestBody ?? page.requestBody,
              uniongroupItems: action.uniongroupItems ?? page.uniongroupItems,
            });
            // @ts-ignore
            action.onChangeItems && action.onChangeItems(res.items);
            // @ts-ignore
            return dispatch_({ type: 'refresh', ...res });
          }
          case 'reload': {
            // ローディング画面を表示
            action.onChangeLoadingState && action.onChangeLoadingState(true);
            if (action.delayTime) {
              await new Promise((resolve) =>
                setTimeout(resolve, action.delayTime)
              );
            }
            try {
              const res = await window.App.services.ui.worker.pagenate({
                action: 'reload',
                fullMethodName: action.fullMethodName,
                pageNumber: action.pageNumber ?? page.pageNumber,
                pageSize: action.pageSize ?? page.pageSize,
                filter: action.filter ?? page.filter,
                sort: action.sort ?? page.sort,
                aggregate: action.aggregate ?? page.aggregate,
                requestBody: action.requestBody ?? page.requestBody,
                uniongroupItems: action.uniongroupItems ?? page.uniongroupItems,
              });
              // @ts-ignore
              action.onChangeItems && action.onChangeItems(res.items);
              // @ts-ignore
              return dispatch_({ type: 'refresh', ...res });
            } catch (err) {
              // appworkerのcomlinkでRpcErrorが変換されてしまうので変換する
              if (
                intl &&
                (err as Error).message.includes('PERMISSION_DENIED')
              ) {
                // トーストでエラーメッセージを出す
                error([GetMessageWithIntl(intl, { id: 'E0000019' })]);
              }
              page.onError && page.onError(err);
              throw err;
            } finally {
              action.onChangeLoadingState && action.onChangeLoadingState(false);
            }
          }
          default:
            return dispatch_(action);
        }
      })();
    },
    // 下記の特定の項目変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page.pageNumber, page.pageSize, page.filter, page.sort, intl]
  );
  return [page, dispatch];
}
