import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { ApprovalRequestDialog } from '~/shared/components/ui/Dialog';
import {
  FullMethodName_ListComponentUnits,
  FullMethodName_ListStaffs,
} from '~/shared/utils';

export const UISampleApprovalRequestDialogs = () => {
  const [result, setResult] = useState('');
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">承認依頼ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowConfirm(true);
              }}
            />
            <ApprovalRequestDialog
              isOpen={isShowConfirm}
              inputOption={{
                FullMethodName_ListComponentUnits,
                FullMethodName_ListStaffs,
                requestReciptId: '12345',
              }}
              onDecision={() => {
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
