import { useEffect, useRef, PropsWithChildren } from 'react';
import './FilterDrawer.css';

interface FilterDrawerProps {
  modalIsOpen: boolean;
  onClickOuter: () => void;
}

export function FilterDrawer(props: PropsWithChildren<FilterDrawerProps>) {
  const { children, modalIsOpen, onClickOuter } = props;

  const filterBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = filterBodyRef.current;
    if (!el) return;
    const hundleClickOutside = (e: MouseEvent) => {
      if (!el.contains(e.target as Node)) {
        // マーカーフィルタ外をクリックした場合
        if (modalIsOpen) {
          onClickOuter();
        }
      }
    };
    document.addEventListener('click', hundleClickOutside);
    return () => {
      document.removeEventListener('click', hundleClickOutside);
    };
  }, [filterBodyRef, onClickOuter, modalIsOpen]);

  return (
    <div
      className={`FilterDrawer ${modalIsOpen ? `on` : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div ref={filterBodyRef}>{children}</div>
    </div>
  );
}
