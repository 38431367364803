import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import {
  Container,
  GetMessage,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import {
  SimpleIconListView,
  IconMenuItem,
  SimpleListView,
} from '~/shared/components/ui/SimpleListView';
import { Textbox } from '~/shared/components/ui/Textbox';
import { Textarea } from '~/shared/components/ui/Textarea';
import { CaptionButton } from '~/shared/components/ui/Button';
import { LinkTextbox } from '~/shared/components/ui/LinkTextbox';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { useLocation } from 'react-router-dom';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  HistoryDialog,
  ConfirmationDialog,
  BusinessUnitStrengthFormOption,
  BusinessUnitStrengthDialog,
  CommentDialog,
} from '~/shared/components/ui/Dialog';
import { ChangeNotificationDialog } from '~/shared/components/ui/Dialog/ChangeNotificationDialog';
import {
  ContactRequestDialog,
  ContactRequestDialogInputOption,
  ContactRequestResult,
} from '~/shared/components/ui/Dialog/ContactRequestDialog';
import {
  ContactRegistrationDialog,
  ContactRegistrationDialogInputOption,
  ContactRegistrationResult,
} from '~/shared/components/ui/Dialog/ContactRegistrationDialog';
import {
  ContactAttributeSettingDialog,
  ContactAttributeSettingDialogInputOption,
  ContactAttributeSettingResult,
} from '~/shared/components/ui/Dialog/ContactAttributeSettingDialog';
import {
  BusinessUnitBranchDialog,
  BusinessUnitBranchFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitBranchDialog';
import {
  DocumentRegistrationDialog,
  DocumentRegistrationDialogInputOption,
  DocumentRegistrationResult,
} from '~/shared/components/ui/Dialog/DocumentRegistrationDialog';

import './BusinessUnitManagementConfirmation.css';
import {
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui/PageNavigation/PageNavigation';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  getViewIdPreset,
  getWorkerExceptionMessage,
  ViewId,
  FullMethodName_ListBusinessUnitManagementContents,
  FullMethodName_ListBusinessUnitContactContents,
  FullMethodName_SharedListBusinessUnitBranchs,
  FullMethodName_SharedListBusinessUnitFinancials,
  FullMethodName_SharedListBusinessUnitFacilitiess,
  FullMethodName_SharedListBusinessUnitCertificates,
  FullMethodName_SharedListBusinessUnitStrengths,
  FullMethodName_ListBusinessUnitDocuments,
  FullMethodName_SharedListBusinessUnitSkills,
  FullMethodName_ListBusinessUnitChangeNotifications,
  getSortSettingFromLocalStorage,
  getExceptionMessage,
  HistoryItems,
  getHistoryItems,
  isShowNotificationArea,
  typeName,
  getDateFormatWithTimezone,
  mergeAmountNumberWithRounding,
  convertBaseDigitOnceTo,
  BASE_DIGIT_THOUSAND,
  toCommaTypeString,
  convertLongToString,
  convertIDateToString,
  convertDisplayUserNameEmail,
  getDateFormat,
  createAttachmentFiles,
  autoDownloadFileOnlyName,
  autoBulkDownloadWithName,
  convertSkillTreeToCategorizedList,
  StringDateToNumber,
  FullMethodName_ListBusinessUnitRankRequestContents,
  FullMethodName_ListBusinessUnitRankReceptionContents,
  FullMethodName_ListLocalBusinessUnitContactContents,
  FullMethodName_ListLocalBusinessUnitFinancials,
  FullMethodName_ListLocalBusinessUnitFacilitiess,
  FullMethodName_ListLocalBusinessUnitCertificates,
  FullMethodName_ListLocalBusinessUnitStrengths,
  FullMethodName_ListLocalBusinessUnitSkills,
  FullMethodName_ListLocalBusinessUnitRankRequestContents,
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_ListLicenses,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { FilterRequest } from '~/worker';
import { ReactComponent as PriorityHighIcon } from '@material-design-icons/svg//filled/priority_high.svg';
import {
  BusinessUnitFacilityDialog,
  BusinessUnitFacilityFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitFacilityDialog';
import {
  CertificateRegistrationDialog,
  CertificateRegistrationDialogInputOption,
} from '~/shared/components/ui/Dialog/CertificateRegistrationDialog';
import {
  CategorizedCheckList,
  CategorizedList,
} from '~/shared/components/ui/CategorizedCheckList';
import {
  DISPLAY_TYPE,
  ResponsiblePersonRegistrationDialog,
  ResponsiblePersonRegistrationDialogInputOption,
} from '~/shared/components/ui/Dialog/ResponsiblePersonRegistrationDialog';

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const LICENSE_NITERRA_BUSINESS_BETA = '_NITERRA_BUSINESSUNIT_BETA';
const LICENSE_NITERRA_BUSINESS = '_NITERRA_BUSINESSUNIT';

export function BusinessUnitManagementConfirmation() {
  // ログイン者の情報特定用email
  const auth = useAuth();
  const myEmail = useAuth().user?.email ?? '';
  // 自社企業情報
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();
  const handleError = useErrorHandler();
  const intl = useIntl();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // ユーザー権限の有無

  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 2) {
        const tabPath = splitPath[splitPath.length - 1];
        const idPath = splitPath[splitPath.length - 2];
        const endpointPath = splitPath[splitPath.length - 3];
        if (
          tabPath !== 'businessunitmanagement-confirmation' &&
          idPath !== 'businessunitmanagement-confirmation' &&
          endpointPath === 'businessunitmanagement-confirmation'
        ) {
          return [idPath];
        }
      }
    }
    return [];
  };

  /**
   * @param:TabName 初回の起動のみ、初回タブ表示をプロフィールにするために設定
   */
  const getTargetTabItem = (initialTab?: TabName): TabName => {
    // タブ内で更新がかかりリロードした後に元のタブに戻す（基本情報には更新系なし）
    if (tabPanel !== 'profile' && !initialTab) {
      return tabPanel;
    }
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (splitPath.length > 2) {
      return splitPath[splitPath.length - 1] as TabName;
    }
    return 'profile';
  };

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    requestBody: {
      dataFilter: {
        businessUnitManagementIds: getTargetId(),
      } as mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.IDataFilter,
    },
    filter: {
      'businessUnitManagement.businessUnitManagementId': {
        $in: getTargetId() ?? [],
      },
    },
    sort: getSortSettingFromLocalStorage(
      'BUM_BUSINESS_UNIT_MANAGEMENT_LIST',
      myEmail
    ),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // Loading制御用
  const [isLoading, setLoading] = useState(false);
  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const [isLoadingContact, setLoadingContact] = useState(false);
  const [isLoadingBranch, setLoadingBranch] = useState(false);
  const [isLoadingFinancial, setLoadingFinancial] = useState(false);
  const [isLoadingFacilities, setLoadingFacilities] = useState(false);
  const [isLoadingCertificate, setLoadingCertificate] = useState(false);
  const [isLoadingStrength, setLoadingStrength] = useState(false);
  const [isLoadingDocument, setLoadingDocument] = useState(false);
  const [isLoadingManagement, setLoadingManagement] = useState(false);
  const [isLoadingSkill, setLoadingSkill] = useState(false);
  const [isLoadingChangeNotification, setLoadingChangeNotification] =
    useState(false);
  const [isLoadingRankRequest, setLoadingRankRequest] = useState(false);
  const [isLoadingRankReception, setLoadingRankReception] = useState(false);

  // メインデータ（取引先管理/基本情報）
  const [item, setItem] =
    useState<mtechnavi.api.company.BusinessUnitManagementContent>();
  // 取得データ格納用 取引先管理（連絡先）コンテンツ
  const businessUnitContactContentsData = useRef<
    mtechnavi.api.company.IBusinessUnitContactContent[]
  >([]);
  // 取得データ格納用 取引先管理（拠点）マスタ
  const businessUnitBranchsData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);
  // 取得データ格納用 取引先管理（決算）マスタ
  const businessUnitFinancialsData = useRef<
    mtechnavi.api.company.IBusinessUnitFinancial[]
  >([]);
  // 取得データ格納用 取引先管理（設備）マスタ
  const businessUnitFacilitiessData = useRef<
    mtechnavi.api.company.IBusinessUnitFacilities[]
  >([]);
  // 取得データ格納用 取引先管理（認証）マスタ
  const businessUnitCertificatesData = useRef<
    mtechnavi.api.company.IBusinessUnitCertificate[]
  >([]);
  // 取得データ格納用 取引先管理（得意分野）マスタ
  const businessUnitStrengthsData = useRef<
    mtechnavi.api.company.IBusinessUnitStrength[]
  >([]);
  // 取得データ格納用 取引先管理（文書）マスタ
  const businessUnitDocumentsData = useRef<
    mtechnavi.api.company.IBusinessUnitDocument[]
  >([]);
  // 取得データ格納用 取引先管理（技術情報）マスタ
  const businessUnitSkillsData = useRef<
    mtechnavi.api.company.IBusinessUnitSkill[]
  >([]);
  // 取得データ格納用 更新通知データ
  const businessUnitChangeNotificationsData = useRef<
    mtechnavi.api.company.IBusinessUnitChangeNotification[]
  >([]);
  // 取得データ格納用 品質要求
  const businessUnitRankRequestData = useRef<
    mtechnavi.api.company.IBusinessUnitRankContent[]
  >([]);
  // 取得データ格納用 品質管理
  const businessUnitRankReceptionData = useRef<
    mtechnavi.api.company.IBusinessUnitRankContent[]
  >([]);
  // 取得データ格納用 品質管理で使用する自社拠点情報
  const ownCompanyBranchData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);

  // 更新通知ダイアログ用
  interface ViewChangeNotificationType {
    typeName: string; // 抽出用
    recordId: string; // 抽出用
    targetKey: string; // 名称を格納
    targetFieldPath: string; // 辞書引きが必要
    oldValue: string; // フォーマット済の値
    newValue: string; // フォーマット済の値
    remarks: string; // string[] からの変換、辞書引きが必要
    notifiedAt: Long | undefined; // ダイアログ側でフォーマット
  }

  // SimpleListView用
  interface ViewContactType {
    contactId: string; // Key businessUnitContactHeaderId/businessUnitContactId
    displayName: string; // businessUnitContactHeader.displayName / businessUnitContact.displayName
    notificationUsers: string; // businessUnitContact.notificationUsers[].displayName {1},{2}...
    status: string; // status.displayName
    remarks: string; // businessUnitContactAttribute.remarks
  }

  interface ViewFinancialType {
    businessUnitFinancialId: string; // Key
    period: string; // periodStartDt-periodEndDt YYYY/MM-YYYY/MM
    netSales: string; // netSales / 1000 切り捨て #,###
    operatingProfit: string; // operatingProfit / 1000 切り捨て #,###
    ordinaryIncome: string; // ordinaryIncome / 1000 切り捨て #,###
  }

  interface ViewBranchType {
    businessUnitBranchId: string; // Key
    displayName: string; // displayName
    address: string; // address joinAddress
    phoneNumber: string; // phoneNumber
    overview: string; // overview
  }

  interface ViewDocumentType {
    businessUnitDocumentId: string; // Key
    category: string; // category.displayNameLang.ja
    filename: string; // attachment.filename
    attachedAt: string; // attachment.attachedAt yyyy/MM/dd
    updatedBy: string; // updatedProperties.updatedBy displayName(email)
    remarks: string; // attachment.remarks
    assetId: string; // 非表示項目 ダウンロード処理用
  }

  interface ViewFacilityType {
    businessUnitFacilitiesId: string; // Key
    branchName: string; // 拠点名
    manufacture: string; //メーカー
    modelNumber: string; // 型式
    assetsAmount: string; // 台数
  }

  interface ViewStrengthType {
    businessUnitStrengthId: string; // Key
    category: string; // 分類
    content: string; //メーカー
    linkProperties: string; // 関連拠点/関連設備
  }

  interface ViewCertificateType {
    businessUnitCertificateId: string; // Key
    displayName: string; // 認証名
    obtained: string; // 取得状況
    obtainedDt: string; //取得年月日
    expirationDt: string; // 有効期限
    scope: string; // 適用範囲
  }
  interface ViewManagementType {
    id: number; // key (map index)
    itemKey: string; // 項目名
    itemValue: string; // 項目値
    code: string; // 識別コード
  }

  interface RankType {
    id: number; //Key  (map index)
    requestId: string; // 品質要求ID
    receptionId: string; // 品質管理（責任者）ID
    displayName: string; //適用品種
    rank: string; // ランク
    companyDisplayName: string; // 品質管理企業名
    qualityManagerDisplayName: string; // 品質管理者名
    managerCategory: string; // 正/副(担当分類)
    businessUnitName: string; // 工場名
  }

  type rankActionType = 'delete' | 'save';
  interface ConfirmationDialogInfo {
    actionType: rankActionType;
    item: RankType | mtechnavi.api.company.IBusinessUnitRankReception;
  }

  type sortCategory = 'obtainedDt' | 'expirationDt' | 'certificationName';

  type sortDocumentType = 'category-filename' | 'attachedAt';

  // IAdressが共通化（sharelib.IAddress）されたタイミングで共通処理に移動することを検討
  const joinAddress = (address: mtechnavi.api.company.IAddress) => {
    if (!address) return '';
    const ary: string[] = [];
    ary.push(address.region?.displayNameLang?.ja ?? '');
    if (address.addressLines && address.addressLines?.length > 0) {
      address.addressLines.map((v) => ary.push(v));
    }
    return ary.join('');
  };

  // 表示項目制御
  // 連絡先タブ
  // 相手先の連絡先
  const [contactReceiveItems, setContactReceiveItems] = useState<
    ViewContactType[]
  >([]);
  const [contactRequestFormItems, setContactRequestFormItems] =
    useState<ContactRequestDialogInputOption>();
  const [contactConfirmationFormItems, setContactConfirmationFormItems] =
    useState<ContactRegistrationDialogInputOption>();
  const [
    contactAttributeReceiveFormItems,
    setContactAttributeReceiveFormItems,
  ] = useState<ContactAttributeSettingDialogInputOption>();
  // 自社の連絡先
  const [contactSendItems, setContactSendItems] = useState<ViewContactType[]>(
    []
  );
  const [contactRegistrationFormItems, setContactRegistrationFormItems] =
    useState<ContactRegistrationDialogInputOption>();
  const [contactAttributeSendFormItems, setContactAttributeSendFormItems] =
    useState<ContactAttributeSettingDialogInputOption>();

  // 拠点タブ
  const [branchItems, setBranchItems] = useState<ViewBranchType[]>([]);
  const [branchFormItems, setBranchFormItems] =
    useState<BusinessUnitBranchFormOption>();

  // 設備タブ
  const [facilityItems, setFacilityItems] = useState<ViewFacilityType[]>([]);
  const [facilityFormItems, setFacilityFormItems] =
    useState<BusinessUnitFacilityFormOption>();

  // 得意分野タブ
  const [strengthItems, setStrengthItems] = useState<ViewStrengthType[]>([]);
  const [strengthFormItems, setStrengthFormItems] =
    useState<BusinessUnitStrengthFormOption>();

  // 認証タブ
  const [certificateItems, setCertificateItems] = useState<
    ViewCertificateType[]
  >([]);
  const [certificateFormItems, setCertificateFormItems] =
    useState<CertificateRegistrationDialogInputOption>();

  // 決算タブ
  const [financialItems, setFinancialItems] = useState<ViewFinancialType[]>([]);

  // 技術情報タブ
  const [skillTreeData, setSkillTreeData] = useState<CategorizedList>([]);
  const [businessUnitSkillData, setBusinessUnitSkillData] =
    useState<mtechnavi.api.company.IBusinessUnitSkill>();

  // 文書タブ
  const [documentItems, setDocumentItems] = useState<ViewDocumentType[]>([]);
  const [documentConfirmationFormItems, setDocumentConfirmationFormItems] =
    useState<DocumentRegistrationDialogInputOption>();
  const [documentRegistrationFormItems, setDocumentRegistrationFormItems] =
    useState<DocumentRegistrationDialogInputOption>();
  const [managementItems, setManagementItems] = useState<ViewManagementType[]>(
    []
  );

  // 管理タブ
  const [statusButtonCaption, setStatusButtonCaption] = useState('');
  const [notificationButtonCaption, setNotificationButtonCaption] =
    useState('');
  const [isDisabledNotificationButton, setDisabledNotificationButton] =
    useState(false);

  // 品質要求タブ
  const [rankRequestItem, setRankRequestItem] = useState<RankType[]>([]);
  const [requestCompanyRank, setRequestCompanyRank] = useState<string>('');

  // 品質管理タブ
  const [rankReceptionItem, setRankReceptionItem] = useState<RankType[]>([]);
  const [receptionCompanyRank, setReceptionCompanyRank] = useState<string>('');

  // 責任者登録ダイアログ
  const [rankFormItem, setRankFormItem] =
    useState<ResponsiblePersonRegistrationDialogInputOption>({
      displayType: 'display',
      rankContent: {},
      sharedCompanyId: '',
      isLinkedTenant: false,
    });

  // 履歴ダイアログ
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [historys, setHistorys] = useState<HistoryItems[]>([]);
  const historyMessage = GetMessage({
    id: 'I0000005',
    value: { $1: historys?.length > 0 ? historys[0].slip_category : '' },
  });

  // 更新通知ダイアログ
  const [
    isBusinessUnitChangeNotificationDialogOpen,
    setBusinessUnitChangeNotificationDialogOpen,
  ] = useState(false);
  const [changeNotifications, setChangeNotifications] = useState<
    ViewChangeNotificationType[]
  >([]);

  // 確認ダイアログ
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  const [confirmationDialogInfo, setConfirmationDialogInfo] =
    useState<ConfirmationDialogInfo>({
      actionType: 'delete',
      item: {},
    });
  const [isSaveConfirmationDialogOpen, setSaveConfirmaionDialogOpen] =
    useState(false);

  // 連絡先入力依頼ダイアログ
  const [isContactRequestDialogOpen, setContactRequestDialogOpen] =
    useState(false);
  // 連絡先確認ダイアログ
  const [isContactConfirmationDialogOpen, setContactConfirmationDialogOpen] =
    useState(false);
  // 自社情報設定（相手連絡先）ダイアログ
  const [
    isContactAttributeSettingReceiveDialogOpen,
    setContactAttributeSettingReceiveDialogOpen,
  ] = useState(false);
  // 自社連絡先登録ダイアログ
  const [isContactRegistrationDialogOpen, setContactRegistrationDialogOpen] =
    useState(false);
  // 自社情報設定（自社連絡先）ダイアログ
  const [
    isContactAttributeSettingSendDialogOpen,
    setContactAttributeSettingSendDialogOpen,
  ] = useState(false);
  // 拠点情報確認ダイアログ
  const [isBranchDialogOpen, setBranchDialogOpen] = useState(false);
  // 設備情報確認ダイアログ
  const [isFacilityDialogOpen, setFacilityDialogOpen] = useState(false);
  // 得意分野情報確認ダイアログ
  const [isStrengthDialogOpen, setStrengthDialogOpen] = useState(false);
  // 認証情報確認ダイアログ
  const [isCertificateDialogOpen, setCertificateDialogOpen] = useState(false);
  // ファイル管理ダイアログ
  // 文書確認ダイアログ
  const [isDocumentConfirmationDialogOpen, setDocumentConfirmationDialogOpen] =
    useState(false);
  // 文書登録ダイアログ
  const [isDocumentRegistrationDialogOpen, setDocumentRegistrationDialogOpen] =
    useState(false);
  // 取引停止 確認ダイアログ
  const [isStopBusinessConfirmOpen, setStopBusinessConfirmOpen] =
    useState(false);
  // 取引再開 確認ダイアログ
  const [isStartBusinessConfirmOpen, setStartBusinessConfirmOpen] =
    useState(false);
  // 通知/更新停止ダイアログ
  const [isNotificationStopDialogOpen, setNotificationStopDialogOpen] =
    useState(false);
  // 通知/更新再開ダイアログ
  const [isNotificationStartDialogOpen, setNotificationStartDialogOpen] =
    useState(false);
  // 通知更新ダイアログコメント
  const [
    notificationStartStopDialogComment,
    setNotificationStartStopDialogComment,
  ] = useState('');
  // 責任者登録ダイアログ
  const [
    isResponsiblePersonRegistrationDialogOpen,
    setResponsiblePersonRegistrationDialogOpen,
  ] = useState(false);

  // 項目表示制御
  const [isVisibleHistory, setVisibleHistory] = useState(false);
  const [isVisibleChangeNotification, setVisibleChangeNotification] =
    useState(false);
  const [isVisibleBranchTab, setVisibleBranchTab] = useState(false);
  const [isVisibleRankRequestTab, setVisibleRankRequestTab] = useState(false);
  const [isVisibleRankReceptionTab, setVisibleRankReceptionTab] =
    useState(false);
  const [isVisibleEditContactHeader, setVisibleEditContactHeader] =
    useState(false);
  const [isVisibleOnlyContact, setVisibleOnlyContact] = useState(true);
  const [isNiterraBusiness, setNiterraBusiness] = useState(false);

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });
  const stopBusinessMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'stopBusiness' }),
    },
  };
  const startBusinessMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'restartBusiness' }),
    },
  };

  // タブ制御
  type TabName =
    | 'profile'
    | 'contact'
    | 'branch'
    | 'financial'
    | 'facilities'
    | 'certificate'
    | 'strength'
    | 'document'
    | 'management'
    | 'skill'
    | 'rankRequest'
    | 'rankReception';
  const [tabs, setTabs] = useState<TabName[]>([]);
  const [tabPanel, setTabPanel] = useState<TabName>('contact');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  const sortCertificateItems = (sortCategory: sortCategory, desc: boolean) => {
    if (sortCategory === 'expirationDt') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val2.expirationDt) -
            StringDateToNumber(val1.expirationDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val1.expirationDt) -
            StringDateToNumber(val2.expirationDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
      return;
    }
    if (sortCategory === 'obtainedDt') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val2.obtainedDt) -
            StringDateToNumber(val1.obtainedDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val1.obtainedDt) -
            StringDateToNumber(val2.obtainedDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
      return;
    }
    if (sortCategory === 'certificationName') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort((val1, val2) =>
          val2.displayName.localeCompare(val1.displayName, 'ja')
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort((val1, val2) =>
          val1.displayName.localeCompare(val2.displayName, 'ja')
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
    }
  };

  // ヘッダアイコン設定
  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];

    if (isVisibleHistory) {
      // 履歴
      iconItems.push({
        name: 'history',
        displayName: '履歴',
        func: () => {
          setHistoryOpen(true);
        },
      });
    }

    return iconItems;
  };

  // 連絡先（相手先の連絡先アイコンメニュー）
  const getContactReceiveIconMenuItem = (): IconMenuItem[] => {
    const iconItems: IconMenuItem[] = [];
    if (!isNiterraBusiness && isVisibleEditContactHeader) {
      iconItems.push({
        name: 'mail',
        displayName: '依頼',
        func: (v: ViewContactType) => {
          handleContactRequestDialog(v);
        },
      });
    }
    iconItems.push({
      name: 'description',
      displayName: '確認',
      func: (v: ViewContactType) => {
        handleContactConfirmationDialog(v);
      },
    });
    iconItems.push({
      name: 'settings',
      displayName: '設定',
      func: (v: ViewContactType) => {
        handleContactAttributeSettingReceiveDialog(v);
      },
    });

    return iconItems;
  };

  // 連絡先（自社の連絡先アイコンメニュー）
  const getContactSendIconMenuItem = (): IconMenuItem[] => {
    const iconItems: IconMenuItem[] = [];
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: (v: ViewContactType) => {
        handleContactRegistrationDialog(v);
      },
    });
    iconItems.push({
      name: 'settings',
      displayName: '設定',
      func: (v: ViewContactType) => {
        handleContactAttributeSettingSendDialog(v);
      },
    });

    return iconItems;
  };

  // 履歴関連処理
  // 通知エリア制御
  const [isNotificationShow, setNotificationShow] = useState<boolean>(false);
  // 履歴データ取得
  const listHistoryData = async () => {
    try {
      let typeName: typeName = '';
      let recordId = '';
      if (isCurrentTab('contact')) {
        typeName = 'mtechnavi.api.company.BusinessUnitContact';
        recordId = item?.businessUnitManagement?.businessUnitManagementId ?? '';
      } else {
        typeName = 'mtechnavi.api.company.BusinessUnitManagement';
        recordId = item?.businessUnitManagement?.businessUnitManagementId ?? '';
      }

      // 履歴一覧を取得
      const datas = await getHistoryItems(intl, typeName, [recordId]);
      setHistorys(datas);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  const getChangeNotificationItems = () => {
    // 更新通知データを取得
    const changeNotificationItems: ViewChangeNotificationType[] = [];
    listBusinessUnitChangeNotificationsFilterTabName(tabPanel).map((v) =>
      changeNotificationItems.push({
        typeName: v.typeName ?? '',
        recordId: v.recordId ?? '',
        targetKey: v.targetKey ?? '',
        targetFieldPath: GetMessageWithIntl(intl, {
          id: v.targetFieldPath ?? '',
        }),
        oldValue: v.oldValue ?? '',
        newValue: v.newValue ?? '',
        remarks:
          v.remarksNames
            ?.map((w) => {
              return GetMessageWithIntl(intl, { id: w });
            })
            .join('') ?? '',
        notifiedAt: v.notifiedAt ?? undefined,
      })
    );
    return changeNotificationItems;
  };
  // 更新通知ダイアログ起動時
  useEffect(() => {
    // 基本情報はタブ遷移前にダイアログ表示するケースが存在するため、直前にもセットする
    if (isBusinessUnitChangeNotificationDialogOpen) {
      // 更新通知データをセット
      setChangeNotifications(getChangeNotificationItems());
    }

    // isBusinessUnitChangeNotificationDialogOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBusinessUnitChangeNotificationDialogOpen]);

  // 履歴ダイアログ起動時
  useEffect(() => {
    if (isHistoryOpen) {
      //履歴データを取得
      (async () => {
        await listHistoryData();
      })();
    }

    // isHistoryOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryOpen]);

  // 履歴データ変更時
  useEffect(() => {
    // 最新の履歴通知レベルによる制御
    setNotificationShow(isShowNotificationArea(historys));
  }, [historys]);

  // [実行処理]ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
      pageNumber: n,
    });
  };

  // [実行処理]リロード
  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  // [実行処理]確認済み 更新通知ダイアログ
  const handleConfirmedChangeNotification = async () => {
    setLoading(true);
    try {
      const targets =
        listBusinessUnitChangeNotificationsFilterTabName(tabPanel);
      const targetIds: string[] = [];
      targets.map((v) => targetIds.push(v.recordId ?? ''));

      if (targetIds.length > 0) {
        const targetTypeName = targets.length > 0 ? targets[0].typeName : '';
        const request: mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest =
          {
            typeName: targetTypeName,
            recordIds: targetIds,
          };

        await window.App.services.ui.worker.apiCall({
          actionName: 'execConfirmBusinessUnitChangeNotification',
          request: request,
        });
      }

      setBusinessUnitChangeNotificationDialogOpen(false);
      success([successMessage]);

      //リロード(更新通知データのみ)
      setLoadingChangeNotification(true);
      await listBusinessUnitChangeNotificationsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]連絡先入力依頼ダイアログ
  const handleContactRequestDialog = (v?: ViewContactType) => {
    setContactRequestFormItems(undefined);
    if (v) {
      const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
        businessUnitContactContentsData.current.find(
          (w) =>
            w.businessUnitContactHeader?.businessUnitContactHeaderId ===
              v?.contactId ||
            w.businessUnitContact?.businessUnitContactId === v?.contactId
        ) ?? {};
      if (formItem) {
        setContactRequestFormItems({
          id: v.contactId ?? '',
          displayName:
            formItem.businessUnitContactHeader?.displayName ??
            formItem.businessUnitContact?.displayName ??
            '',
        });
      }
    }
    setContactRequestDialogOpen(true);
  };

  // [実行処理]保存/送信 連絡先入力依頼
  const handleSaveContactRequest = async (v: ContactRequestResult) => {
    setLoading(true);
    try {
      let target: mtechnavi.api.company.IBusinessUnitContactContent = {};
      if (v?.id) {
        target =
          businessUnitContactContentsData.current.find(
            (w) =>
              w.businessUnitContactHeader?.businessUnitContactHeaderId ===
                v?.id || w.businessUnitContact?.businessUnitContactId === v?.id
          ) ?? {};
      }

      const request: mtechnavi.api.company.IBusinessUnitContactHeader = {
        businessUnitContactHeaderId:
          target.businessUnitContactHeader?.businessUnitContactHeaderId,
        businessUnitManagementId:
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
        companyId: myCompanyInfo.current?.companyId ?? '',
        displayName: v.displayName ?? '',
        documentRecipientAddress: v.requestTargetCheck.some(
          (w) => w === 'documentRecipientAddress'
        ),
        notificationUser: v.requestTargetCheck.some(
          (w) => w === 'notificationUser'
        ),
        requestProcess: { processedComment: v.comment },
        updatedAt: target.businessUnitContactHeader?.updatedAt,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execBusinessUnitContactHeader',
        request: {
          businessUnitContactHeader: request,
          businessUnitContactId: v?.id ?? '',
        },
      });

      setContactRequestDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      setLoadingContact(true);
      await listBusinessUnitContactContentsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]連絡先確認ダイアログ
  const handleContactConfirmationDialog = (v: ViewContactType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
      businessUnitContactContentsData.current.find(
        (w) =>
          w.businessUnitContactHeader?.businessUnitContactHeaderId ===
            v?.contactId ||
          w.businessUnitContact?.businessUnitContactId === v?.contactId
      ) ?? {};
    if (formItem) {
      setContactConfirmationFormItems({
        displayModeType: 'display',
        companyId: myCompanyInfo.current?.companyId ?? '',
        requestDocumentRecipientAddress:
          formItem.businessUnitContactHeader?.documentRecipientAddress ?? false,
        requestNotificationUser:
          formItem.businessUnitContactHeader?.notificationUser ?? false,
        id: formItem.businessUnitContact?.businessUnitContactId ?? '',
        displayName:
          formItem.businessUnitContactHeader?.displayName ??
          formItem.businessUnitContact?.displayName ??
          '',
        businessUnitBranchProperties:
          formItem.businessUnitContact?.businessUnitBranchProperties ?? {},
        alias: formItem.businessUnitContact?.alias ?? '',
        notificationUsers:
          formItem.businessUnitContact?.notificationUsers ?? [],
        comment: '',
      });
    } else {
      setContactConfirmationFormItems({
        displayModeType: 'display',
        companyId: myCompanyInfo.current?.companyId ?? '',
      });
    }
    setContactConfirmationDialogOpen(true);
  };

  // [実行処理]自社情報設定（相手連絡先）ダイアログ
  const handleContactAttributeSettingReceiveDialog = (v: ViewContactType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
      businessUnitContactContentsData.current.find(
        (w) =>
          w.businessUnitContactHeader?.businessUnitContactHeaderId ===
            v?.contactId ||
          w.businessUnitContact?.businessUnitContactId === v?.contactId
      ) ?? {};
    if (formItem) {
      setContactAttributeReceiveFormItems({
        id: v?.contactId ?? '',
        code: formItem.businessUnitContactAttribute?.code ?? '',
        displayName: formItem.businessUnitContactAttribute?.displayName ?? '',
        remarks: formItem.businessUnitContactAttribute?.remarks ?? '',
      });
    } else {
      setContactAttributeReceiveFormItems(undefined);
    }
    setContactAttributeSettingReceiveDialogOpen(true);
  };

  // [共通処理]保存 自社情報設定
  const saveContactAttribute = async (v: ContactAttributeSettingResult) => {
    let target: mtechnavi.api.company.IBusinessUnitContactContent = {};
    if (v?.id) {
      target =
        businessUnitContactContentsData.current.find(
          (w) =>
            w.businessUnitContactHeader?.businessUnitContactHeaderId ===
              v?.id || w.businessUnitContact?.businessUnitContactId === v?.id
        ) ?? {};
    }

    let typeName = '';
    let recordId = '';
    if (target.businessUnitContactHeader?.businessUnitContactHeaderId) {
      typeName = 'mtechnavi.api.company.BusinessUnitContactHeader';
      recordId = target.businessUnitContactHeader?.businessUnitContactHeaderId;
    } else {
      if (target.businessUnitContact?.businessUnitContactId) {
        typeName = 'mtechnavi.api.company.BusinessUnitContact';
        recordId = target.businessUnitContact?.businessUnitContactId;
      }
    }

    const request: mtechnavi.api.company.IBusinessUnitContactAttribute = {
      businessUnitContactAttributeId:
        target.businessUnitContactAttribute?.businessUnitContactAttributeId,
      businessUnitManagementId:
        item?.businessUnitManagement?.businessUnitManagementId,
      typeName: typeName,
      recordId: recordId,
      code: v.code,
      displayName: v.displayName,
      remarks: v.remarks,
      updatedAt: target.businessUnitContactAttribute?.updatedAt,
    };

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveBusinessUnitContactAttribute',
      request: request,
    });
  };

  // [実行処理]保存 自社情報設定（相手連絡先）
  const handleSaveContactAttributeReceive = async (
    v: ContactAttributeSettingResult
  ) => {
    setLoading(true);
    try {
      // [共通処理]保存 自社情報設定
      await saveContactAttribute(v);
      setContactAttributeSettingReceiveDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      setLoadingContact(true);
      await listBusinessUnitContactContentsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]連絡先登録ダイアログ
  const handleContactRegistrationDialog = (v?: ViewContactType) => {
    setContactRegistrationFormItems({
      displayModeType:
        item?.tenantStatus?.systemName === 'B03' &&
        item?.activeStatus?.systemName === 'B00'
          ? 'send'
          : 'save',
      companyId: myCompanyInfo.current?.companyId ?? '',
    });
    if (v) {
      const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
        businessUnitContactContentsData.current.find(
          (w) =>
            w.businessUnitContactHeader?.businessUnitContactHeaderId ===
              v?.contactId ||
            w.businessUnitContact?.businessUnitContactId === v?.contactId
        ) ?? {};
      if (formItem) {
        setContactRegistrationFormItems({
          displayModeType:
            item?.tenantStatus?.systemName === 'B03' &&
            item?.activeStatus?.systemName === 'B00'
              ? 'send'
              : 'save',
          companyId: myCompanyInfo.current?.companyId ?? '',
          requestDocumentRecipientAddress:
            formItem.businessUnitContactHeader?.documentRecipientAddress ??
            false,
          requestNotificationUser:
            formItem.businessUnitContactHeader?.notificationUser ?? false,
          id: v?.contactId ?? '',
          displayName:
            formItem.businessUnitContactHeader?.displayName ??
            formItem.businessUnitContact?.displayName ??
            '',
          businessUnitBranchProperties:
            formItem.businessUnitContact?.businessUnitBranchProperties ?? {},
          alias: formItem.businessUnitContact?.alias ?? '',
          notificationUsers:
            formItem.businessUnitContact?.notificationUsers ?? [],
          comment: '',
        });
      }
    }
    setContactRegistrationDialogOpen(true);
  };

  // [実行処理]保存/送信 連絡先
  const handleSaveContact = async (v: ContactRegistrationResult) => {
    setLoading(true);
    try {
      let target: mtechnavi.api.company.IBusinessUnitContactContent = {};
      if (v?.id) {
        target =
          businessUnitContactContentsData.current.find(
            (w) =>
              w.businessUnitContactHeader?.businessUnitContactHeaderId ===
                v?.id || w.businessUnitContact?.businessUnitContactId === v?.id
          ) ?? {};
      }
      const request: mtechnavi.api.company.IBusinessUnitContact = {
        businessUnitContactId:
          target.businessUnitContact?.businessUnitContactId,
        businessUnitManagementId:
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
        companyId: myCompanyInfo.current?.companyId ?? '',
        displayName: v.displayName ?? '',
        businessUnitBranchProperties: v.businessUnitBranchProperties,
        alias: v.alias ?? '',
        notificationUsers: v.notificationUsers,
        sendProcess: { processedComment: v.comment },
        updatedAt: target.businessUnitContact?.updatedAt,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execBusinessUnitContact',
        request: {
          tenantStatus: item?.tenantStatus ?? {},
          activeStatus: item?.activeStatus ?? {},
          businessUnitContact: request,
          businessUnitContactHeaderId: v?.id ?? '',
        },
      });

      setContactRegistrationDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      setLoadingContact(true);
      await listBusinessUnitContactContentsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]自社情報設定（自社連絡先）ダイアログ
  const handleContactAttributeSettingSendDialog = (v: ViewContactType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
      businessUnitContactContentsData.current.find(
        (w) =>
          w.businessUnitContactHeader?.businessUnitContactHeaderId ===
            v?.contactId ||
          w.businessUnitContact?.businessUnitContactId === v?.contactId
      ) ?? {};
    if (formItem) {
      setContactAttributeSendFormItems({
        id: v?.contactId ?? '',
        code: formItem.businessUnitContactAttribute?.code ?? '',
        displayName: formItem.businessUnitContactAttribute?.displayName ?? '',
        remarks: formItem.businessUnitContactAttribute?.remarks ?? '',
      });
    } else {
      setContactAttributeSendFormItems(undefined);
    }
    setContactAttributeSettingSendDialogOpen(true);
  };

  // [実行処理]保存 自社情報設定（自社連絡先）
  const handleSaveContactAttributeSend = async (
    v: ContactAttributeSettingResult
  ) => {
    setLoading(true);
    try {
      // [共通処理]保存 自社情報設定
      await saveContactAttribute(v);
      setContactAttributeSettingSendDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      setLoadingContact(true);
      await listBusinessUnitContactContentsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]拠点情報確認ダイアログ
  const handleBranchDialog = (v: ViewBranchType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitBranch =
      businessUnitBranchsData.current.find(
        (w) => w.businessUnitBranchId === v.businessUnitBranchId
      ) ?? {};
    if (formItem) {
      setBranchFormItems({
        displayName: formItem.displayName ?? '',
        address: formItem.address ?? {},
        phoneNumber: formItem.phoneNumber ?? '',
        faxNumber: formItem.faxNumber ?? '',
        overview: formItem.overview ?? '',
      });
    }
    setBranchDialogOpen(true);
  };

  // [実行処理]設備情報確認ダイアログ
  const handleFacilitiesDialog = (v: ViewFacilityType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitFacilities =
      businessUnitFacilitiessData.current.find(
        (w) => w.businessUnitFacilitiesId === v.businessUnitFacilitiesId
      ) ?? {};
    if (formItem) {
      setFacilityFormItems({
        buFacilities: formItem as mtechnavi.api.company.IBusinessUnitFacilities,
        companyId: formItem.companyId ?? '',
        isShared: true,
      });
    }
    setFacilityDialogOpen(true);
  };

  // [実行処理]認証情報確認ダイアログ
  const handleCertificatesDialog = (v: ViewCertificateType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitCertificate =
      businessUnitCertificatesData.current.find(
        (w) => w.businessUnitCertificateId === v.businessUnitCertificateId
      ) ?? {};
    if (formItem) {
      setCertificateFormItems({
        displayModeType: 'display',
        businessUnitCertificate: formItem,
      });
    }
    setCertificateDialogOpen(true);
  };

  // [実行処理]得意分野情報確認ダイアログ
  const handleStrengthDialog = (v: ViewStrengthType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitStrength =
      businessUnitStrengthsData.current.find(
        (w) => w.businessUnitStrengthId === v.businessUnitStrengthId
      ) ?? {};
    if (formItem) {
      setStrengthFormItems({
        companyId: formItem.companyId ?? '',
        isShared: true,
        category: formItem.category ?? {},
        content: formItem.content ?? '',
        linkProperties: formItem.linkProperties ?? {},
      });
    }
    setStrengthDialogOpen(true);
  };

  // [実行処理]文書確認ダイアログ
  const handleDocumentConfirmationDialog = (v: ViewDocumentType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitDocument =
      businessUnitDocumentsData.current.find(
        (w) => w.businessUnitDocumentId === v.businessUnitDocumentId
      ) ?? {};

    setDocumentConfirmationFormItems({
      displayModeType: 'display',
      businessUnitDocument: formItem ?? {},
    });

    setDocumentConfirmationDialogOpen(true);
  };

  // [実行処理]削除 文書
  const handleDeleteDocument = async (v: DocumentRegistrationResult) => {
    setLoading(true);
    try {
      const request: mtechnavi.api.company.IBusinessUnitContact =
        v.businessUnitDocument;

      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitDocument',
        request: request,
      });

      setDocumentConfirmationDialogOpen(false);
      success([successMessage]);

      //リロード(文書情報のみ)
      setLoadingDocument(true);
      await listBusinessUnitDocumentsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]文書登録ダイアログ
  const handleDocumentRegistrationDialog = (v?: DocumentRegistrationResult) => {
    setDocumentRegistrationFormItems({
      displayModeType: 'save',
      businessUnitDocument: v?.businessUnitDocument ?? {},
    });
    setDocumentConfirmationDialogOpen(false);
    setDocumentRegistrationDialogOpen(true);
  };

  // [実行処理]保存 文書
  const handleSaveDocument = async (v: DocumentRegistrationResult) => {
    setLoading(true);
    try {
      const request: mtechnavi.api.company.IBusinessUnitDocument =
        v.businessUnitDocument;

      if (v.files.length > 0) {
        const tmpData = await createAttachmentFiles(v.files ?? [], 'B09');
        request.businessUnitManagementId =
          item?.businessUnitManagement?.businessUnitManagementId;
        request.attachment = tmpData.length > 0 ? tmpData[0] : {};
        request.attachment.remarks = v.files[0].remarks;
      }

      await window.App.services.ui.worker.apiCall({
        actionName: 'execBusinessUnitDocument',
        request: request,
      });

      setDocumentRegistrationDialogOpen(false);
      success([successMessage]);

      //リロード(文書情報のみ)
      setLoadingDocument(true);
      await listBusinessUnitDocumentsData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]添付ファイルダウンロード 文書
  const handleDownloadDocument = (v: ViewDocumentType) => {
    autoDownloadFileOnlyName(v.filename ?? '', v.assetId ?? '');
  };
  // [実行処理]添付ファイル全ダウンロード 文書
  const handleFullDownloadDocument = () => {
    autoBulkDownloadWithName(
      businessUnitDocumentsData.current.map(
        (item) => item.attachment?.assetId ?? ''
      ),
      intl,
      GetMessageWithIntl(intl, {
        id: 'document.download.zipFilename',
        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
      })
    );
  };

  // [実行処理]ソート 文書タブ
  const handleSortDocumentItems = (
    sortItem: sortDocumentType,
    desc: boolean
  ) => {
    const sortedData: ViewDocumentType[] = documentItems;

    switch (sortItem) {
      case 'category-filename':
        sortedData.sort((val1, val2) =>
          val1.category.localeCompare(val2.category, 'ja')
        );
        sortedData.sort((val1, val2) => {
          if (val1.category === val2.category) {
            return val1.filename.localeCompare(val2.filename, 'ja');
          }
          return 1;
        });

        break;
      case 'attachedAt':
        sortedData.sort(
          (val1, val2) =>
            val1.attachedAt.localeCompare(val2.attachedAt) * (desc ? -1 : 1)
        );
        break;
    }

    setDocumentItems(
      sortedData.map(
        (v): ViewDocumentType => ({
          businessUnitDocumentId: v.businessUnitDocumentId,
          category: v.category,
          filename: v.filename,
          attachedAt: v.attachedAt,
          updatedBy: v.updatedBy,
          remarks: v.remarks,
          assetId: v.assetId,
        })
      )
    );
  };

  // [実行処理]取引停止
  const handleStopBusiness = async () => {
    setLoading(true);
    try {
      const request: mtechnavi.api.company.IStopBusinessRequest = {
        businessUnitManagementId:
          item?.businessUnitManagement?.businessUnitManagementId,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execStopBusiness',
        request: request,
      });

      setStopBusinessConfirmOpen(false);
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]取引再開
  const handleStartBusiness = async () => {
    setLoading(true);
    try {
      const request: mtechnavi.api.company.IStartBusinessRequest = {
        businessUnitManagementId:
          item?.businessUnitManagement?.businessUnitManagementId,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execStartBusiness',
        request: request,
      });

      setStartBusinessConfirmOpen(false);
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]開始 通知/更新区分
  const handleActivateBusinessUnit = async () => {
    setLoading(true);
    try {
      const request: mtechnavi.api.company.IActivateBusinessUnitRequest = {
        companyId: item?.businessUnitManagement?.companyId,
        comment: notificationStartStopDialogComment,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execActivateBusinessUnit',
        request: request,
      });

      setNotificationStartDialogOpen(false);
      setNotificationStartStopDialogComment('');
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]停止 通知/更新区分
  const handleInactivateBusinessUnit = async () => {
    setLoading(true);
    try {
      const request: mtechnavi.api.company.IInactivateBusinessUnitRequest = {
        companyId: item?.businessUnitManagement?.companyId,
        comment: notificationStartStopDialogComment,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execInactivateBusinessUnit',
        request: request,
      });

      setNotificationStopDialogOpen(false);
      setNotificationStartStopDialogComment('');
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 責任者登録/確認ダイアログ
  const handleResponsiblePersonRegistrationDialog = (
    listViewItem: RankType,
    displayType: DISPLAY_TYPE
  ) => {
    let targetItem:
      | mtechnavi.api.company.IBusinessUnitRankContent
      | null
      | undefined;
    if (displayType === 'display') {
      // rankRequestIdで絞る
      const items = businessUnitRankRequestData.current.filter((v) => {
        return (
          listViewItem.requestId ===
          v.businessUnitRankRequest?.businessUnitRankRequestId
        );
      });
      // 二つ以上アイテムをある場合は、rankReceptionIDで絞る
      if (items.length > 1) {
        targetItem = items.find(
          (v) =>
            v.businessUnitRankReception?.businessUnitRankReceptionId ===
            listViewItem.receptionId
        );
      } else {
        targetItem = items.length === 1 ? items[0] : null;
      }
    } else {
      if (listViewItem.receptionId) {
        targetItem = businessUnitRankReceptionData.current.find(
          (v) =>
            v.businessUnitRankReception?.businessUnitRankReceptionId ===
            listViewItem?.receptionId
        );
      } else {
        targetItem = businessUnitRankReceptionData.current.find(
          (v) =>
            v.businessUnitRankRequest?.businessUnitRankRequestId ===
            listViewItem.requestId
        );
      }
    }
    if (!targetItem) {
      return;
    }
    setRankFormItem({
      displayType: displayType,
      rankContent: targetItem,
      sharedCompanyId: item?.businessUnitManagement?.companyId ?? '',
      isLinkedTenant: isLinkedTenant(item?.tenantStatus?.systemName),
    });
    setResponsiblePersonRegistrationDialogOpen(true);
  };

  // [実行処理]責任者情報登録
  const handleSaveRankReception = async (
    item: mtechnavi.api.company.IBusinessUnitRankReception
  ) => {
    if (!item) {
      return;
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveBusinessUnitRankReception',
        request: item,
      });

      success([successMessage]);

      //リロード
      setSaveConfirmaionDialogOpen(false);
      setResponsiblePersonRegistrationDialogOpen(false);
      setLoadingRankReception(true);
      await listBusinessUnitRankReceptionData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]品質管理削除
  const handleDeleteReceptionItem = async (
    item: mtechnavi.api.company.IBusinessUnitRankReception
  ) => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitRankReception',
        request: item,
      });

      success([successMessage]);
      setSaveConfirmaionDialogOpen(false);
      //リロード
      setLoadingRankReception(true);
      await listBusinessUnitRankReceptionData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 制御対象のコントロールの非活性(非表示)化
  const disableAllItems = () => {
    setVisibleHistory(false);
    setVisibleChangeNotification(false);
    setVisibleBranchTab(false);

    setVisibleEditContactHeader(false);
    setDisabledNotificationButton(true);
  };

  // [データ取得] 全データ取得
  const listAllData = async () => {
    // 以下のデータ取得は取引先管理コンテンツの取得後の前提

    // 連絡先情報取得
    (async () => {
      setLoadingContact(true);
      await listBusinessUnitContactContentsData();
    })();

    // 拠点情報取得
    (async () => {
      setLoadingBranch(true);
      await listBusinessUnitBranchsData();
    })();

    // 決算情報取得
    (async () => {
      setLoadingFinancial(true);
      await listBusinessUnitFinancialsData();
    })();

    // 設備情報取得
    (async () => {
      setLoadingFacilities(true);
      await listBusinessUnitFacilitiessData();
    })();

    // 認証情報取得
    (async () => {
      setLoadingCertificate(true);
      await listBusinessUnitCertificatesData();
    })();

    // 得意分野情報取得
    (async () => {
      setLoadingStrength(true);
      await listBusinessUnitStrengthsData();
    })();

    // 文書情報情報取得
    (async () => {
      setLoadingDocument(true);
      await listBusinessUnitDocumentsData();
    })();

    // 技術情報情報取得
    (async () => {
      setLoadingSkill(true);
      await listBusinessUnitSkillsData();
    })();

    // 品質要求情報取得
    (async () => {
      setLoadingRankRequest(true);
      await listBusinessUnitRankRequestData();
    })();

    // 品質管理情報取得
    (async () => {
      setLoadingRankReception(true);
      await listBusinessUnitRankReceptionData();
    })();

    // 更新通知データ取得（全タブ分）
    // 更新通知データはアイコン表示制御のため、まとめて取得するリストの対象
    (async () => {
      setLoadingChangeNotification(true);
      await listBusinessUnitChangeNotificationsData();
    })();
  };

  /**
   * テナントステータスが紐付けあり(B03)かどうか判定する
   * @param systemName
   * @returns 紐付けありの場合、True
   */
  const isLinkedTenant = (systemName: string | null | undefined) => {
    return systemName === 'B03';
  };

  // [データ取得] 取引先管理（連絡先）コンテンツ
  const listBusinessUnitContactContentsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_ListBusinessUnitContactContents
          : FullMethodName_ListLocalBusinessUnitContactContents,
        filter: {},
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          businessUnitManagementIds: [
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
          ],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitContactContentsResponse
        | mtechnavi.api.company.IListLocalBusinessUnitContactContentsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitContactContent[] =
        resList.items ?? [];
      businessUnitContactContentsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingContact(false);
    }
  };

  // [データ取得] 自社企業（拠点）マスタ
  const listBusinessUnitBranchsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_SharedListBusinessUnitBranchs,
        filter: {},
        requestBody: {
          companyIds: [item?.businessUnitManagement?.companyId ?? ''],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitBranchsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitBranch[] =
        resList.items ?? [];
      businessUnitBranchsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingBranch(false);
    }
  };

  // [データ取得]自社企業（決算）マスタ
  const listBusinessUnitFinancialsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_SharedListBusinessUnitFinancials
          : FullMethodName_ListLocalBusinessUnitFinancials,
        filter: {},
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          companyIds: [item?.businessUnitManagement?.companyId ?? ''],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitFinancialsResponse
        | mtechnavi.api.company.IListLocalBusinessUnitFinancialsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitFinancial[] =
        resList.items ?? [];
      businessUnitFinancialsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingFinancial(false);
    }
  };

  // [データ取得] 自社企業（設備）マスタ
  const listBusinessUnitFacilitiessData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_SharedListBusinessUnitFacilitiess
          : FullMethodName_ListLocalBusinessUnitFacilitiess,
        filter: {},
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          companyIds: [item?.businessUnitManagement?.companyId ?? ''],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitFacilitiessResponse
        | mtechnavi.api.company.IListLocalBusinessUnitFacilitiessResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitFacilities[] =
        resList.items ?? [];
      businessUnitFacilitiessData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingFacilities(false);
    }
  };

  // [データ取得] 自社企業（認証）マスタ
  const listBusinessUnitCertificatesData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_SharedListBusinessUnitCertificates
          : FullMethodName_ListLocalBusinessUnitCertificates,
        filter: {},
        sort: [], // ソートは画面項目セット時に行うため不要
      };

      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          companyIds: [item?.businessUnitManagement?.companyId ?? ''],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitCertificatesResponse
        | mtechnavi.api.company.IListLocalBusinessUnitCertificatesResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitCertificate[] =
        resList.items ?? [];
      businessUnitCertificatesData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingCertificate(false);
    }
  };

  // [データ取得] 自社企業（得意分野）マスタ
  const listBusinessUnitStrengthsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_SharedListBusinessUnitStrengths
          : FullMethodName_ListLocalBusinessUnitStrengths,
        filter: {},
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          companyIds: [item?.businessUnitManagement?.companyId ?? ''],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitStrengthsResponse
        | mtechnavi.api.company.IListLocalBusinessUnitStrengthsResponse;
      const listItem: mtechnavi.api.company.IBusinessUnitStrength[] =
        resList.items ?? [];
      businessUnitStrengthsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingStrength(false);
    }
  };

  // [データ取得] 取引先管理（文書）マスタ
  const listBusinessUnitDocumentsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitDocuments,
        filter: {},
        requestBody: {
          businessUnitManagementIds: [
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
          ],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitDocumentsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitDocument[] =
        resList.items ?? [];
      businessUnitDocumentsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingDocument(false);
    }
  };

  // [データ取得] 自社企業（技術情報）マスタ
  const listBusinessUnitSkillsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_SharedListBusinessUnitSkills
          : FullMethodName_ListLocalBusinessUnitSkills,
        filter: {},
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          companyIds: [item?.businessUnitManagement?.companyId ?? ''],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitSkillsResponse
        | mtechnavi.api.company.IListLocalBusinessUnitSkillsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitSkill[] =
        resList.items ?? [];
      businessUnitSkillsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingSkill(false);
    }
  };

  // [データ取得] 取引先管理（品質要求）マスタ
  const listBusinessUnitRankRequestData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: isLinkedTenant(item?.tenantStatus?.systemName)
          ? FullMethodName_ListBusinessUnitRankRequestContents
          : FullMethodName_ListLocalBusinessUnitRankRequestContents,
        filter: {},
        sort: [],
      };
      if (isLinkedTenant(item?.tenantStatus?.systemName)) {
        listOption.requestBody = {
          businessUnitManagementIds: [
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
          ],
        };
      } else {
        listOption.requestBody = {
          businessUnitManagementId:
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
        };
      }
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as
        | mtechnavi.api.company.IListBusinessUnitRankRequestContentsResponse
        | mtechnavi.api.company.IListLocalBusinessUnitRankRequestContentsResponse;
      const listItem: mtechnavi.api.company.IBusinessUnitRankContent[] =
        resList.items ?? [];
      businessUnitRankRequestData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingRankRequest(false);
    }
  };

  // [データ取得] 取引先管理（品質管理）マスタ
  const listBusinessUnitRankReceptionData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitRankReceptionContents,
        filter: {},
        requestBody: {
          businessUnitManagementIds: [
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
          ],
        },
        sort: [],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitRankReceptionContentsResponse;
      const listItem: mtechnavi.api.company.IBusinessUnitRankContent[] =
        resList.items ?? [];
      businessUnitRankReceptionData.current = listItem;

      // 自社拠点管理の工場名を取得するため
      const listBranchOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitBranchs,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId ?? ''],
        },
        sort: [],
      };
      const resBranchList = (await window.App.services.ui.worker.filter(
        listBranchOption
      )) as mtechnavi.api.company.IListBusinessUnitBranchsResponse;

      const listBranchItem: mtechnavi.api.company.IBusinessUnitBranch[] =
        resBranchList.items ?? [];
      ownCompanyBranchData.current = listBranchItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoadingRankReception(false);
    }
  };

  // [データ取得] 更新通知データ(全タブ分)
  const listBusinessUnitChangeNotificationsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitChangeNotifications,
        filter: {},
        requestBody: {
          baseTypeName: 'mtechnavi.api.company.BusinessUnitManagement',
          baseRecordIds: [
            item?.businessUnitManagement?.businessUnitManagementId ?? '',
          ],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitChangeNotificationsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitChangeNotification[] =
        resList.items ?? [];
      businessUnitChangeNotificationsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoadingChangeNotification(false);
    }
  };

  // タブ別更新通知データ取得
  const listBusinessUnitChangeNotificationsFilterTabName = (
    tabname: TabName
  ): mtechnavi.api.company.IBusinessUnitChangeNotification[] => {
    let typeName = '';
    switch (tabname) {
      case 'profile':
        typeName = 'mtechnavi.api.company.BusinessUnitProfile';
        break;
      case 'branch':
        typeName = 'mtechnavi.api.company.BusinessUnitBranch';
        break;
      case 'certificate':
        typeName = 'mtechnavi.api.company.BusinessUnitCertificate';
        break;
      case 'contact':
        typeName = 'mtechnavi.api.company.BusinessUnitContact';
        break;
      case 'rankRequest':
        typeName = 'mtechnavi.api.company.BusinessUnitRankReception';
        break;
      default:
        return [];
    }
    return businessUnitChangeNotificationsData.current.filter(
      (v) => v.typeName === typeName
    );
  };

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      setLoading(true);
      try {
        const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
        setPreset(presetItem);
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});
        const skillTree =
          await window.App.services.businessUnitManagementService.getBusinessUnitSkillTree(
            {}
          );
        setSkillTreeData(convertSkillTreeToCategorizedList(skillTree));
        const [roleNames] = await window.App.services.ui.getUserRoles(
          auth.user?.userGroupIds
        );

        const fetchLicenses = [
          LICENSE_NITERRA_BUSINESS,
          LICENSE_NITERRA_BUSINESS_BETA,
        ];

        const licenses = (
          (await window.App.services.ui.worker.filter({
            action: 'reload',
            fullMethodName: FullMethodName_ListLicenses,
            filter: {
              licenseCode: { $in: fetchLicenses },
            },
            sort: [],
          })) as mtechnavi.api.license.ListLicensesResponse
        ).items;

        if (
          licenses.length !== 0 &&
          licenses.some(
            (license) => license.licenseCode === LICENSE_NITERRA_BUSINESS
          )
        ) {
          setNiterraBusiness(true);
        }

        if (
          licenses.length !== 0 &&
          licenses.some(
            (license) => license.licenseCode === LICENSE_NITERRA_BUSINESS_BETA
          )
        ) {
          setVisibleOnlyContact(true);
          setTabPanel('contact');
        } else {
          setVisibleOnlyContact(false);
          roleNames.some((name) => name === 'Role-QualityManagement-Request')
            ? setVisibleRankRequestTab(true)
            : setVisibleRankRequestTab(false);
          roleNames.some((name) => name === 'Role-QualityManagement-Result')
            ? setVisibleRankReceptionTab(true)
            : setVisibleRankReceptionTab(false);
          setTabPanel(getTargetTabItem('profile'));
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
    handleReload();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.company.BusinessUnitManagementContent[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setNotificationShow(false);
    setTabPanel(getTargetTabItem());

    // page変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // メインデータ変更時
  useEffect(() => {
    if (!item) {
      return;
    }

    // 対象データ取得
    (async () => {
      await listAllData();
    })();

    // 管理タブのボタン制御
    switch (item.businessUnitManagement?.status?.systemName) {
      case 'B00':
        setStatusButtonCaption(
          GetMessageWithIntl(intl, { id: 'stopBusiness' })
        );
        break;
      case 'B01':
        setStatusButtonCaption(
          GetMessageWithIntl(intl, { id: 'restartBusiness' })
        );
        break;
      default:
        setStatusButtonCaption(
          GetMessageWithIntl(intl, { id: 'restartBusiness' })
        );
    }

    switch (item.activeStatus?.systemName) {
      case 'B00':
        setNotificationButtonCaption(GetMessageWithIntl(intl, { id: 'stop' }));
        break;
      case 'B01':
        setNotificationButtonCaption(
          GetMessageWithIntl(intl, { id: 'restart' })
        );
        break;
      default:
        setNotificationButtonCaption(
          GetMessageWithIntl(intl, { id: 'restart' })
        );
    }

    // 管理タブの情報セット
    if (!item?.businessUnitManagement?.itemValues?.length) {
      setManagementItems([]);
    } else {
      const vals: ViewManagementType[] = [];
      item?.businessUnitManagement?.itemValues.map((v, index) => {
        vals.push({
          id: index,
          itemKey: v.itemKey ?? '',
          itemValue: v.itemValue ?? '',
          code: v.code ?? '',
        });
      });
      vals.sort((val1, val2) => val1.code.localeCompare(val2.code, 'ja'));
      setManagementItems(vals);
    }

    //基本情報/管理タブの情報取得が完了するため、Loading終了とする
    setLoadingProfile(false);
    setLoadingManagement(false);

    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  // タブ切り替え時の処理
  useEffect(() => {
    // 履歴データはタブごとに内容が変化するため、各タブに遷移したときに取得する
    switch (tabPanel) {
      case 'profile':
      case 'contact':
      case 'management':
        //履歴データを取得
        (async () => {
          await listHistoryData();
        })();
        break;
      default:
        setHistorys([]);
    }

    // 更新通知ダイアログのデータは各タブに遷移したときにセットする
    setChangeNotifications(getChangeNotificationItems());

    // tabPanel変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabPanel,
    // 連続確認モードで別ページへ遷移した場合、直接URLから遷移した場合も同様の挙動とする
    item?.businessUnitManagement?.businessUnitManagementId,
  ]);

  // Loading制御
  useEffect(() => {
    // ひとつでもLoadingならアイコン表示
    setLoading(
      isLoadingProfile ||
        isLoadingContact ||
        isLoadingBranch ||
        isLoadingFinancial ||
        isLoadingFacilities ||
        isLoadingCertificate ||
        isLoadingStrength ||
        isLoadingDocument ||
        isLoadingManagement ||
        isLoadingSkill ||
        isLoadingChangeNotification ||
        isLoadingRankRequest ||
        isLoadingRankReception
    );

    // isLodingXX 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingProfile,
    isLoadingContact,
    isLoadingBranch,
    isLoadingFinancial,
    isLoadingFacilities,
    isLoadingCertificate,
    isLoadingStrength,
    isLoadingDocument,
    isLoadingManagement,
    isLoadingSkill,
    isLoadingChangeNotification,
    isLoadingRankRequest,
    isLoadingRankReception,
  ]);

  // ステータス管理関連データ変更時
  useEffect(() => {
    // 制御対象を非活性化
    disableAllItems();

    const isLinkedCompany: boolean = item?.tenantStatus?.systemName === 'B03';
    // テナントステータス=紐付けありの場合のみ対象になるもの
    if (isLinkedCompany) {
      setVisibleBranchTab(true);
      setDisabledNotificationButton(false);

      const isActiveCompany: boolean = item?.activeStatus?.systemName === 'B00';
      // 通知更新区分=通知/更新中の場合のみ対象になるもの
      if (isActiveCompany) {
        setVisibleEditContactHeader(true);
      }

      // 更新通知確認するボタン
      if (
        listBusinessUnitChangeNotificationsFilterTabName(tabPanel).length > 0
      ) {
        setVisibleChangeNotification(true);
      }
    }
    // 履歴
    switch (tabPanel) {
      case 'profile':
      case 'contact':
      case 'management':
        setVisibleHistory(true);
        break;
      default:
      // それ以外は表示しない
    }
    // ステータス管理関連データ 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    item?.tenantStatus,
    tabPanel,
    businessUnitChangeNotificationsData.current.length,
  ]);

  // タブの表示制御
  useEffect(() => {
    const tb: TabName[] = [];
    tb.push('profile');
    tb.push('contact');
    if (isVisibleBranchTab) tb.push('branch');
    tb.push('financial');
    tb.push('facilities');
    tb.push('certificate');
    tb.push('strength');
    tb.push('document');
    tb.push('management');
    tb.push('skill');
    if (isVisibleRankRequestTab) tb.push('rankRequest');
    if (isVisibleRankReceptionTab) tb.push('rankReception');
    if (isVisibleOnlyContact) {
      setTabs(['contact']);
    } else {
      setTabs(tb);
    }
  }, [
    isVisibleBranchTab,
    isVisibleRankRequestTab,
    isVisibleRankReceptionTab,
    isVisibleOnlyContact,
  ]);

  // 連絡先情報変更時
  useEffect(() => {
    if (!businessUnitContactContentsData.current) {
      setContactReceiveItems([]);
      setContactSendItems([]);
      return;
    }
    const receiveMainContactVals: ViewContactType[] = [];
    const sendMainContactVals: ViewContactType[] = [];
    const receiveVals: ViewContactType[] = [];
    const sendVals: ViewContactType[] = [];
    businessUnitContactContentsData.current.map((v) => {
      const notificationUserDisplayNames: string[] = [];
      if ((v.businessUnitContact?.notificationUsers ?? []).length > 0) {
        v.businessUnitContact?.notificationUsers?.map((w) =>
          notificationUserDisplayNames.push(w.user?.displayName ?? '')
        );
      }
      const val: ViewContactType = {
        contactId:
          v.businessUnitContactHeader?.businessUnitContactHeaderId ??
          v.businessUnitContact?.businessUnitContactId ??
          '',
        displayName:
          v.businessUnitContactHeader?.displayName ??
          v.businessUnitContact?.displayName ??
          '',
        notificationUsers: notificationUserDisplayNames.join(','),
        status: v.status?.displayNameLang?.ja ?? '',
        remarks: v.businessUnitContactAttribute?.remarks ?? '',
      };

      if (
        v.businessUnitContactHeader?.businessUnitManagementId ||
        (v.businessUnitContact &&
          !v.businessUnitContact?.businessUnitManagementId)
      ) {
        if (v.businessUnitContact?.mainContact === true) {
          receiveMainContactVals.push(val);
        } else {
          receiveVals.push(val);
        }
      } else {
        if (v.businessUnitContact?.mainContact === true) {
          sendMainContactVals.push(val);
        } else {
          sendVals.push(val);
        }
      }
    });

    // 代表連絡先は常に先頭に表示する
    receiveMainContactVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    receiveVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setContactReceiveItems([...receiveMainContactVals, ...receiveVals]);
    sendMainContactVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    sendVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setContactSendItems([...sendMainContactVals, ...sendVals]);

    // businessUnitContactContentsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitContactContentsData.current]);

  // 拠点情報変更時
  useEffect(() => {
    if (!businessUnitBranchsData.current) {
      setBranchItems([]);
      return;
    }
    const vals: ViewBranchType[] = [];
    businessUnitBranchsData.current.map((v) =>
      vals.push({
        businessUnitBranchId: v.businessUnitBranchId ?? '',
        displayName: v.displayName ?? '',
        address: joinAddress(v.address ?? {}),
        phoneNumber: v.phoneNumber ?? '',
        overview: v.overview ?? '',
      })
    );
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setBranchItems(vals);

    // businessUnitBranchsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitBranchsData.current]);

  // 設備情報変更時
  useEffect(() => {
    if (!businessUnitFacilitiessData.current) {
      setFacilityItems([]);
      return;
    }
    const vals: ViewFacilityType[] = [];
    businessUnitFacilitiessData.current.map((v) => {
      vals.push({
        businessUnitFacilitiesId: v.businessUnitFacilitiesId ?? '',
        branchName:
          businessUnitBranchsData.current.find(
            (branch) => branch.businessUnitBranchId === v.businessUnitBranchId
          )?.displayName ?? '',
        manufacture: v.manufacture ?? '',
        modelNumber: v.modelNumber ?? '',
        assetsAmount: v.assetsAmount ? v.assetsAmount.low.toString() : '',
      });
    });
    vals.sort((val1, val2) =>
      val1.branchName.localeCompare(val2.branchName, 'ja')
    );
    setFacilityItems(vals);

    // businessUnitFacilitiessDataと関連データ変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitFacilitiessData.current, businessUnitBranchsData.current]);

  // 得意分野情報変更時
  useEffect(() => {
    if (!businessUnitStrengthsData.current) {
      setStrengthItems([]);
      return;
    }
    const vals: ViewStrengthType[] = [];
    businessUnitStrengthsData.current.map((v) => {
      const branchName =
        businessUnitBranchsData.current.find(
          (branch) =>
            branch.businessUnitBranchId ===
            v.linkProperties?.businessUnitBranchId
        )?.displayName ?? '';
      const modelNumber =
        businessUnitFacilitiessData.current.find(
          (facility) =>
            facility.businessUnitFacilitiesId ===
            v.linkProperties?.businessUnitFacilitiesId
        )?.modelNumber ?? '';
      vals.push({
        businessUnitStrengthId: v.businessUnitStrengthId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        content: v.content ?? '',
        linkProperties:
          branchName || modelNumber ? [branchName, modelNumber].join('/') : '',
      });
    });
    vals.sort((val1, val2) => val1.category.localeCompare(val2.category, 'ja'));
    setStrengthItems(vals);

    // businessUnitStrengthsDataと関連データ変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessUnitStrengthsData.current,
    businessUnitBranchsData.current,
    businessUnitFacilitiessData.current,
  ]);

  // 認証情報変更時
  useEffect(() => {
    if (!businessUnitCertificatesData.current) {
      setCertificateItems([]);
      return;
    }
    const vals: ViewCertificateType[] = [];
    businessUnitCertificatesData.current.map((v) => {
      vals.push({
        businessUnitCertificateId: v.businessUnitCertificateId ?? '',
        displayName: v.displayName
          ? v.displayName
          : v.certificate!.displayNameLang!.ja ?? '',
        obtained: v.obtained ? '○' : '-',
        obtainedDt: v.obtainedDt ? getDateFormatWithTimezone(v.obtainedDt) : '',
        expirationDt: v.expirationDt
          ? getDateFormatWithTimezone(v.expirationDt)
          : '',
        scope:
          (v.scopeProduct ? v.scopeProduct + ',' : '') +
          (v.scopeBranch ? v.scopeBranch : ''),
      });
    });
    // 初期ソート（有効期限、降順）
    vals.sort(
      (val1, val2) =>
        StringDateToNumber(val2.expirationDt) -
        StringDateToNumber(val1.expirationDt)
    );
    setCertificateItems(vals);

    // businessUnitCertificatesData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitCertificatesData.current]);

  // 決算情報変更時
  useEffect(() => {
    if (!businessUnitFinancialsData.current) {
      setFinancialItems([]);
      return;
    }
    const vals: ViewFinancialType[] = [];
    const sortData = businessUnitFinancialsData.current;
    // 開始年月日で降順
    sortData.sort(
      (val1, val2) =>
        StringDateToNumber(getDateFormatWithTimezone(val2.periodStartDt)) -
        StringDateToNumber(getDateFormatWithTimezone(val1.periodStartDt))
    );
    sortData.map((v) =>
      vals.push({
        businessUnitFinancialId: v.businessUnitFinancialId ?? '',
        period: [
          getDateFormatWithTimezone(v.periodStartDt),
          getDateFormatWithTimezone(v.periodEndDt),
        ].join('-'),
        netSales: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.netSales, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
        operatingProfit: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.operatingProfit, BASE_DIGIT_THOUSAND),
          0,
          { rounding: 'RoundDown', digits: 0 }
        ),
        ordinaryIncome: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.ordinaryIncome, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
      })
    );
    setFinancialItems(vals);

    // businessUnitFinancialsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitFinancialsData.current]);

  // 文書情報変更時
  useEffect(() => {
    if (!businessUnitDocumentsData.current) {
      setDocumentItems([]);
      return;
    }
    const vals: ViewDocumentType[] = [];
    businessUnitDocumentsData.current.map((v) =>
      vals.push({
        businessUnitDocumentId: v.businessUnitDocumentId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        filename: v.attachment?.filename ?? '',
        attachedAt: getDateFormat(
          v.attachment?.attachedAt ?? '',
          'YYYY/MM/DD HH:mm'
        ),
        updatedBy: convertDisplayUserNameEmail(
          v.updatedProperties?.updatedBy?.displayName,
          v.updatedProperties?.updatedBy?.email
        ),
        remarks: v.attachment?.remarks ?? '',
        assetId: v.attachment?.assetId ?? '',
      })
    );

    vals.sort((val1, val2) => val1.category.localeCompare(val2.category, 'ja'));
    vals.sort((val1, val2) => {
      if (val1.category === val2.category) {
        return val1.filename.localeCompare(val2.filename, 'ja');
      }
      return 1;
    });

    const sortedData = vals.sort((val1, val2) =>
      val1.category.localeCompare(val2.category, 'ja')
    );
    sortedData.sort((val1, val2) => {
      if (val1.category === val2.category) {
        return val1.filename.localeCompare(val2.filename, 'ja');
      }
      return 1;
    });

    setDocumentItems(sortedData);

    // businessUnitDocumentsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitDocumentsData.current]);

  // 技術情報変更時
  useEffect(() => {
    if (!businessUnitSkillsData.current) {
      setBusinessUnitSkillData({});
      return;
    }
    setBusinessUnitSkillData(businessUnitSkillsData.current.at(0));

    // businessUnitSkillsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitSkillsData.current]);

  // 品質要求変更時
  useEffect(() => {
    if (!businessUnitRankRequestData.current) {
      setRankRequestItem([]);
    }
    const ranks = window.App.services.ui.getNameOption('A4000011');
    const tmpData: string[] = [];
    ranks.map((rank) => {
      businessUnitRankRequestData.current.map((item) => {
        if (
          rank.code &&
          item.businessUnitRankRequest?.rank?.code === rank.code
        ) {
          tmpData.push(rank.code);
        }
      });
    });
    setRequestCompanyRank(tmpData[0]);

    const vals: RankType[] = [];
    businessUnitRankRequestData.current.map((v, index) => {
      let businessUnitName = '';
      // テナント紐付けなし、マスタ.拠点情報.工場名を参照
      if (!isLinkedTenant(item?.tenantStatus?.systemName)) {
        businessUnitName =
          v.businessUnitRankReception?.businessUnitBranchProperties
            ?.displayName ?? '';
        // 紐付けありの場合
      } else {
        // 自社、かつ拠点IDがからでない場合、拠点マスタ.拠点名を参照
        if (
          v.businessUnitRankReception?.ownCompany &&
          v.businessUnitRankReception?.businessUnitBranchProperties
            ?.businessUnitBranchId
        ) {
          businessUnitName =
            businessUnitBranchsData.current.find(
              (branch) =>
                branch.businessUnitBranchId ===
                v.businessUnitRankReception?.businessUnitBranchProperties
                  ?.businessUnitBranchId
            )?.displayName ?? '';
          // 他社の場合、マスタ.拠点情報.工場名を参照
        } else {
          businessUnitName =
            v.businessUnitRankReception?.businessUnitBranchProperties
              ?.displayName ?? '';
        }
      }
      vals.push({
        id: index,
        requestId: v.businessUnitRankRequest?.businessUnitRankRequestId ?? '',
        receptionId:
          v.businessUnitRankReception?.businessUnitRankReceptionId ?? '',
        displayName: v.businessUnitRankRequest?.displayName ?? '',
        rank: v.businessUnitRankRequest?.rank?.code ?? '',
        companyDisplayName:
          v.businessUnitRankReception?.companyDisplayName ?? '',
        qualityManagerDisplayName:
          v.businessUnitRankReception?.qualityManager?.displayName ?? '',
        managerCategory:
          v.businessUnitRankReception?.category?.displayNameLang?.ja ?? '',
        businessUnitName: businessUnitName,
      });
    });
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setRankRequestItem(vals);
  }, [businessUnitRankRequestData.current]);

  // 品質管理変更時
  useEffect(() => {
    if (!businessUnitRankReceptionData.current) {
      setRankReceptionItem([]);
    }
    const ranks = window.App.services.ui.getNameOption('A4000011');
    const tmpData: string[] = [];
    ranks.map((rank) => {
      businessUnitRankReceptionData.current.map((item) => {
        if (
          rank.code &&
          item.businessUnitRankRequest?.rank?.code === rank.code
        ) {
          tmpData.push(rank.code);
        }
      });
    });
    setReceptionCompanyRank(tmpData[0]);

    const vals: RankType[] = [];
    businessUnitRankReceptionData.current.map((v, index) =>
      vals.push({
        id: index,
        requestId: v.businessUnitRankRequest?.businessUnitRankRequestId ?? '',
        receptionId:
          v.businessUnitRankReception?.businessUnitRankReceptionId ?? '',
        displayName: v.businessUnitRankRequest?.displayName ?? '',
        rank: v.businessUnitRankRequest?.rank?.code ?? '',
        companyDisplayName:
          v.businessUnitRankReception?.companyDisplayName ?? '',
        qualityManagerDisplayName:
          v.businessUnitRankReception?.qualityManager?.displayName ?? '',
        managerCategory:
          v.businessUnitRankReception?.category?.displayNameLang?.ja ?? '',
        businessUnitName: v.businessUnitRankReception?.ownCompany
          ? ownCompanyBranchData.current.find(
              (branch) =>
                branch.businessUnitBranchId ===
                v.businessUnitRankReception?.businessUnitBranchProperties
                  ?.businessUnitBranchId
            )?.displayName ?? ''
          : v.businessUnitRankReception?.businessUnitBranchProperties
              ?.displayName ?? '',
      })
    );
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setRankReceptionItem(vals);
  }, [businessUnitRankReceptionData.current, ownCompanyBranchData.current]);

  // 基本情報タブ
  const renderProfileComponent = () => {
    return (
      <div className="tab-info-body">
        {/* 1段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-40">
              {/* 企業名 */}
              <Textbox
                name="profile.displayNameLang.ja"
                className="companyName"
                type="text"
                disabled={true}
                value={item?.profile?.displayNameLang?.ja}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.displayNameLang.ja"
                columns={preset.columns}
              />
            </div>
            <div className="w-40">
              {/* 英語名 */}
              <Textbox
                name="profile.displayNameLang.en"
                className="companyNameEn"
                type="text"
                disabled={true}
                value={item?.profile?.displayNameLang?.en}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.displayNameLang.en"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 法人番号 */}
              <Textbox
                name="profile.companyNumber"
                className="companyNumber"
                type="text"
                disabled={true}
                value={item?.profile?.companyLegalProperties?.companyNumber}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.companyNumber"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 2段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-40">
              {/* ふりがな */}
              <Textbox
                name="profile.displayNameLang.ja.kana"
                className="companyNameKana"
                type="text"
                disabled={true}
                value={item?.profile?.displayNameLang?.['ja-kana']}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.displayNameLang.ja.kana"
                columns={preset.columns}
              />
            </div>
            <div className="w-40">
              {/* ホームページURL */}
              <LinkTextbox
                name="profile.websiteUrl"
                className="websiteUrl"
                value={item?.profile?.websiteUrl}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.websiteUrl"
                columns={preset.columns}
                isExternalLink={true}
              />
            </div>
            <div className="w-20">
              {/* 適格請求書番号 */}
              <Textbox
                name="profile.eligibleInvoiceAuthorizationNumber"
                className="eligibleInvoiceAuthorizationNumber"
                type="text"
                disabled={true}
                value={
                  item?.profile?.companyLegalProperties
                    ?.eligibleInvoiceAuthorizationNumber
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.eligibleInvoiceAuthorizationNumber"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 3段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 代表者名 */}
              <Textbox
                name="profile.representative.ja"
                className="representative"
                type="text"
                disabled={true}
                value={item?.profile?.representative?.displayNameLang?.ja}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.representative.ja"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 代表者名（ふりがな） */}
              <Textbox
                name="profile.representative.ja.kana"
                className="representativeKana"
                type="text"
                disabled={true}
                value={
                  item?.profile?.representative?.displayNameLang?.['ja-kana']
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.representative.ja.kana"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 代表者（役職） */}
              <Textbox
                name="profile.position"
                className="position"
                type="text"
                disabled={true}
                value={item?.profile?.representative?.position}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.position"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 代表者（生年月日） */}
              <Textbox
                name="profile.birthDate"
                type="text"
                value={convertIDateToString(
                  item?.profile?.representative?.birthDate,
                  'YYYY/MM/DD'
                )}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.birthDate"
                columns={preset.columns}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {/* 4段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 本社（郵便番号） */}
              <Textbox
                name="profile.postalCode"
                className="postalCode"
                type="text"
                disabled={true}
                value={item?.profile?.address?.postalCode}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.postalCode"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 本社（都道府県） */}
              <Textbox
                name="profile.region"
                type="text"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.region"
                columns={preset.columns}
                disabled={true}
                value={item?.profile?.address?.region?.displayNameLang?.ja}
              />
            </div>
            <div className="w-40">
              {/* 本社（住所） */}
              <Textbox
                name="profile.addressLine"
                className="addressLine"
                type="text"
                disabled={true}
                value={item?.profile?.address?.addressLines?.join()}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.addressLine"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 本社（電話番号） */}
              <Textbox
                name="profile.phoneNumber"
                className="phoneNumber"
                type="text"
                disabled={true}
                value={item?.profile?.phoneNumber}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.phoneNumber"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 5段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 従業員（人数） */}
              <Textbox
                name="profile.employees.size"
                className="employees right"
                type="text"
                disabled={true}
                value={convertLongToString(item?.profile?.employees?.size)}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.employees.size"
                columns={preset.columns}
                formatter={toCommaTypeString}
              />
            </div>
            <div className="w-20">
              {/* 従業員（平均年齢） */}
              <Amountbox
                name="profile.employees.averageAge"
                className="averageAge"
                value={item?.profile?.employees?.averageAge}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.employees.averageAge"
                columns={preset.columns}
                displayOption={{ isCommaFormat: true }}
                disabled={true}
              />
            </div>
            <div className="w-20">
              {/* 資本金（千円） */}
              <Amountbox
                name="profile.capitalStock"
                className="capitalStock"
                value={convertBaseDigitOnceTo(
                  item?.profile?.capitalStock,
                  BASE_DIGIT_THOUSAND
                )}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.capitalStock"
                columns={preset.columns}
                displayOption={{ isCommaFormat: true }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {/* 6段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 主要株主 */}
              <Textbox
                name="profile.mainShareholder"
                className="mainShareholder"
                type="text"
                disabled={true}
                value={item?.profile?.mainShareholder}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainShareholder"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 主要取引銀行 */}
              <Textbox
                name="profile.mainBank"
                className="mainBank"
                type="text"
                disabled={true}
                value={item?.profile?.mainBank}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainBank"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 主要仕入先 */}
              <Textbox
                name="profile.mainSupplier"
                className="mainSupplier"
                type="text"
                disabled={true}
                value={item?.profile?.mainSupplier}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainSupplier"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 主要納入先 */}
              <Textbox
                name="profile.mainCustomer"
                className="mainCustomer"
                type="text"
                disabled={true}
                value={item?.profile?.mainCustomer}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainCustomer"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 7段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              {/* 事業内容 */}
              <Textarea
                name="profile.mainBusiness"
                className="w-100 mh-middle"
                disabled={true}
                value={item?.profile?.mainBusiness ?? ''}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainBusiness"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 連絡先タブ
  const renderContactComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'contact.receive',
                prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
              })}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <div className="contact-list-area">
                <SimpleIconListView
                  data={contactReceiveItems}
                  viewOptions={{
                    previewRowCount: 4,
                    keyColumn: 'contactId',
                    columns: [
                      {
                        header: {
                          id: 'contact.receive.displayName',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'displayName',
                        width: '12rem',
                      },
                      {
                        header: {
                          id: 'contact.receive.notificationUsers',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'notificationUsers',
                        width: '15rem',
                      },
                      {
                        header: {
                          id: 'contact.receive.status',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'status',
                        width: '10rem',
                      },
                      {
                        header: {
                          id: 'contact.receive.remarks',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'remarks',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: getContactReceiveIconMenuItem(),
                  }}
                />
              </div>
            </div>
            <div className="w-20">
              {!isNiterraBusiness && isVisibleEditContactHeader && (
                <CaptionButton
                  buttonType="basic"
                  caption="新規連絡先"
                  name="addContactBtn"
                  onClick={() => {
                    handleContactRequestDialog(undefined);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'contact.send',
                prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
              })}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <div className="contact-list-area">
                <SimpleIconListView
                  data={contactSendItems}
                  viewOptions={{
                    previewRowCount: 4,
                    keyColumn: 'contactId',
                    columns: [
                      {
                        header: {
                          id: 'contact.send.displayName',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'displayName',
                        width: '12rem',
                      },
                      {
                        header: {
                          id: 'contact.send.notificationUsers',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'notificationUsers',
                        width: '15rem',
                      },
                      {
                        header: {
                          id: 'contact.send.status',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'status',
                        width: '10rem',
                      },
                      {
                        header: {
                          id: 'contact.send.remarks',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'remarks',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{ iconMenu: getContactSendIconMenuItem() }}
                />
              </div>
            </div>
            <div className="w-20">
              {!isNiterraBusiness && (
                <CaptionButton
                  buttonType="basic"
                  caption="新規連絡先"
                  name="addContactBtn"
                  onClick={() => {
                    handleContactRegistrationDialog(undefined);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 拠点タブ
  const renderBranchComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={branchItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitBranchId',
                  columns: [
                    {
                      header: {
                        id: 'branch.displayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'displayName',
                      width: '16%',
                    },
                    {
                      header: {
                        id: 'branch.address',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'address',
                    },
                    {
                      header: {
                        id: 'branch.phoneNumber',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'phoneNumber',
                      width: '11rem',
                    },
                    {
                      header: {
                        id: 'branch.overview',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'overview',
                      width: '22%',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewBranchType) => {
                        handleBranchDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 決算タブ
  const renderFinancialComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={financialItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitFinancialId',
                  columns: [
                    {
                      header: {
                        id: 'financial.fiscalYear',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'period',
                      width: '10rem',
                    },
                    {
                      header: {
                        id: 'financial.netSales',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'netSales',
                      align: 'right',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'financial.operatingProfit',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'operatingProfit',
                      align: 'right',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'financial.ordinaryIncome',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'ordinaryIncome',
                      align: 'right',
                      width: '12rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{ iconMenu: [] }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 設備タブ
  const renderFacilitiesComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={facilityItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitFacilitiesId',
                  columns: [
                    {
                      header: {
                        id: 'facility.branchName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'branchName',
                    },
                    {
                      header: {
                        id: 'facility.manufacture',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'manufacture',
                    },
                    {
                      header: {
                        id: 'facility.modelNumber',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'modelNumber',
                    },
                    {
                      header: {
                        id: 'facility.assetsAmount',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'assetsAmount',
                      align: 'right',
                      width: '6rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewFacilityType) => {
                        handleFacilitiesDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 認証タブ
  const renderCertificateComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="sort-link-area">
                <span className="sort-label">
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.label',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </span>
                <div
                  className="sort-link expiredDt"
                  onClick={() => sortCertificateItems('expirationDt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.expiredDtDesc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link obtainedDt"
                  onClick={() => sortCertificateItems('obtainedDt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.obtainedDtDesc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link certificationName"
                  onClick={() =>
                    sortCertificateItems('certificationName', false)
                  }
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.certificationNameAsc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={certificateItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitCertificateId',
                  columns: [
                    {
                      header: {
                        id: 'certificate.displayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'displayName',
                      width: '20%',
                    },
                    {
                      header: {
                        id: 'certificate.obtained',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'obtained',
                      width: '6rem',
                    },
                    {
                      header: {
                        id: 'certificate.obtainedDt',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'obtainedDt',
                      width: '8rem',
                    },
                    {
                      header: {
                        id: 'certificate.expirationDt',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'expirationDt',
                      width: '8rem',
                    },
                    {
                      header: {
                        id: 'certificate.scope',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'scope',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewCertificateType) => {
                        handleCertificatesDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 得意分野タブ
  const renderStrengthComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={strengthItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitStrengthId',
                  columns: [
                    {
                      header: {
                        id: 'strength.category',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'category',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'strength.content',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'content',
                    },
                    {
                      header: {
                        id: 'strength.linkProperties',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'linkProperties',
                      width: '30%',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewStrengthType) => {
                        handleStrengthDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 文書タブ
  const renderDocumentComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="sort-link-area">
                <span className="sort-label">
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.label',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </span>
                <div
                  className="sort-link category-filename"
                  onClick={() =>
                    handleSortDocumentItems('category-filename', false)
                  }
                >
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.category-filename',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link attachedAt-ask"
                  onClick={() => handleSortDocumentItems('attachedAt', false)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.attachedAtAsc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link attachedAt-desk"
                  onClick={() => handleSortDocumentItems('attachedAt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.attachedAtDesc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <div className="document-list-area">
                <SimpleIconListView
                  data={documentItems}
                  viewOptions={{
                    previewRowCount: 9,
                    keyColumn: 'businessUnitDocumentId',
                    columns: [
                      {
                        header: {
                          id: 'document.category',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'category',
                        width: '10rem',
                        readonly: true,
                      },
                      {
                        header: {
                          id: 'document.filename',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'filename',
                        width: '12rem',
                      },
                      {
                        header: {
                          id: 'document.attachedAt',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'attachedAt',
                        width: '13rem',
                        readonly: true,
                      },
                      {
                        header: {
                          id: 'document.updatedBy',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'updatedBy',
                        width: '15rem',
                        readonly: true,
                      },
                      {
                        header: {
                          id: 'document.remarks',
                          prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        },
                        propertyName: 'remarks',
                        readonly: true,
                      },
                    ],
                    omitFooter: false,
                  }}
                  actionOptions={{
                    onRowClick: handleDownloadDocument,
                    onFullDownLoad: handleFullDownloadDocument,
                  }}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'description',
                        displayName: '確認',
                        func: (v: ViewDocumentType) => {
                          handleDocumentConfirmationDialog(v);
                        },
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="w-20">
              <CaptionButton
                buttonType="basic"
                caption="文書登録"
                name="addDocumentBtn"
                onClick={() => {
                  handleDocumentRegistrationDialog();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 管理タブ
  const renderManagementComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            {/* 取引区分 */}
            <div className="w-20">
              <Textbox
                name="status"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.status"
                type="text"
                className=""
                columns={['status']}
                value={
                  item?.businessUnitManagement?.status?.displayNameLang?.ja ??
                  ''
                }
                disabled={true}
              />
            </div>
            <div className="w-20 line-up">
              <CaptionButton
                name="statusChangeButton"
                buttonType="basic"
                caption={statusButtonCaption}
                onClick={() => {
                  if (
                    item?.businessUnitManagement?.status?.systemName === 'B00'
                  ) {
                    setStopBusinessConfirmOpen(true);
                  } else {
                    setStartBusinessConfirmOpen(true);
                  }
                }}
              />
            </div>
            {/* 通知更新区分 */}
            <div className="w-20">
              <Textbox
                name="activeStatus"
                type="text"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.notificationUpdate"
                className=""
                columns={['activeStatus']}
                value={item?.activeStatus?.displayNameLang?.ja ?? ''}
                disabled={true}
              />
            </div>
            <div className="w-20 line-up">
              <CaptionButton
                name="saveButton"
                buttonType="basic"
                caption={notificationButtonCaption}
                onClick={() => {
                  if (item?.activeStatus?.systemName === 'B00') {
                    setNotificationStopDialogOpen(true);
                  } else {
                    setNotificationStartDialogOpen(true);
                  }
                }}
                disabled={isDisabledNotificationButton}
              />
            </div>
            {/* テナント */}
            <div className="w-20">
              <Textbox
                name="tenantStatus"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.tenant"
                type="text"
                className=""
                columns={['tenantStatus']}
                value={item?.tenantStatus?.displayNameLang?.ja ?? ''}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line management-item-list-area">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleListView
                data={managementItems}
                viewOptions={{
                  previewRowCount: 6,
                  keyColumn: 'mapkey',
                  columns: [
                    {
                      header: {
                        id: 'management.itemKey',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'itemKey',
                      width: '10rem',
                    },
                    {
                      header: {
                        id: 'management.itemValue',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'itemValue',
                      width: '10rem',
                    },
                    {
                      header: {
                        id: 'management.code',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'code',
                      width: '10rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
              />
            </div>
          </div>
        </div>
        {/* テナント招待用エリア */}
        <div className="input-line information-area">
          <div className="item-group-100">
            <div className="w-50">
              <Textbox
                name="businessUnitStaffName"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.businessUnitStaffName"
                type="text"
                className=""
                columns={['businessUnitStaffName']}
                value={
                  item?.businessUnitManagement?.invitees &&
                  item?.businessUnitManagement.invitees.length > 0
                    ? item?.businessUnitManagement.invitees[0].displayName ?? ''
                    : ''
                }
                disabled={true}
              />
            </div>
            <div className="w-50">
              <Textbox
                name="businessUnitStaffEmail"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.businessUnitStaffEmail"
                type="text"
                className=""
                columns={['businessUnitStaffEmail']}
                value={
                  item?.businessUnitManagement?.invitees &&
                  item?.businessUnitManagement.invitees.length > 0
                    ? item?.businessUnitManagement?.invitees[0].email ?? ''
                    : ''
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 技術情報タブ
  const renderSkillComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <CategorizedCheckList
                listData={skillTreeData}
                checkedIds={
                  businessUnitSkillData?.businessUnitSkillItemIds || []
                }
                disabled={true}
                checkedOnly={true}
                inputOption={{
                  isVisibleOpenAll: true,
                  isVisibleCloseAll: true,
                }}
              ></CategorizedCheckList>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="otherSkill"
                className="w-75 mh-middle"
                value={businessUnitSkillData?.otherSkill || ''}
                labelId="OCP_BUSINESS_UNIT_SKILL_INPUT.otherSkill"
                columns={['otherSkill']}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 品質要求タブ
  const renderRankRequestComponent = () => {
    return (
      <div className="tab-info-body pt-0">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <p className="company-rank">
                {GetMessageWithIntl(intl, {
                  prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  id: 'rank.companyRank',
                })}
                {requestCompanyRank}
              </p>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={rankRequestItem}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'id',
                  columns: [
                    {
                      header: {
                        id: 'rank.displayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'displayName',
                      width: '16%',
                    },
                    {
                      header: {
                        id: 'rank.rank',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'rank',
                      width: '8rem',
                    },
                    {
                      header: {
                        id: 'rank.companyDisplayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'companyDisplayName',
                      width: '17rem',
                    },
                    {
                      header: {
                        id: 'rank.qualityManagerDisplayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'qualityManagerDisplayName',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'rank.managerCategory',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'managerCategory',
                      width: '10rem',
                    },
                    {
                      header: {
                        id: 'rank.businessUnitName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'businessUnitName',
                      width: '14rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: RankType) => {
                        handleResponsiblePersonRegistrationDialog(v, 'display');
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 品質管理タブ
  const renderRankReceptionComponent = () => {
    return (
      <div className="tab-info-body pt-0">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <p className="company-rank">
                {GetMessageWithIntl(intl, {
                  prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  id: 'rank.companyRank',
                })}
                {receptionCompanyRank}
              </p>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={rankReceptionItem}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'id',
                  columns: [
                    {
                      header: {
                        id: 'rank.displayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'displayName',
                      width: '16%',
                    },
                    {
                      header: {
                        id: 'rank.rank',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'rank',
                      width: '8rem',
                    },
                    {
                      header: {
                        id: 'rank.companyDisplayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'companyDisplayName',
                      width: '17rem',
                    },
                    {
                      header: {
                        id: 'rank.qualityManagerDisplayName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'qualityManagerDisplayName',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'rank.managerCategory',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'managerCategory',
                      width: '10rem',
                    },
                    {
                      header: {
                        id: 'rank.businessUnitName',
                        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                      },
                      propertyName: 'businessUnitName',
                      width: '14rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'edit',
                      displayName: '編集',
                      func: (v: RankType) => {
                        handleResponsiblePersonRegistrationDialog(v, 'edit');
                      },
                    },
                    {
                      name: 'copy',
                      displayName: '複写',
                      func: (v: RankType) => {
                        handleResponsiblePersonRegistrationDialog(v, 'copy');
                      },
                    },
                    {
                      name: 'delete',
                      displayName: '削除',
                      func: async (v: RankType) => {
                        const item = businessUnitRankReceptionData.current.find(
                          (reception) =>
                            reception.businessUnitRankReception
                              ?.businessUnitRankReceptionId === v.receptionId
                        );
                        if (!item?.businessUnitRankReception) {
                          // 未登録状態で削除した場合もエラー
                          error([GetMessageWithIntl(intl, { id: 'E0000129' })]);
                          return;
                        }
                        const deletable =
                          businessUnitRankReceptionData.current.filter(
                            (data) => {
                              if (
                                data.businessUnitRankRequest?.displayName ===
                                  item.businessUnitRankRequest?.displayName &&
                                data.businessUnitRankRequest?.rank?.code ===
                                  item?.businessUnitRankRequest?.rank?.code
                              ) {
                                return data;
                              }
                            }
                          );
                        if (deletable.length === 1) {
                          error([GetMessageWithIntl(intl, { id: 'E0000129' })]);
                          return;
                        }
                        setConfirmationDialogInfo({
                          actionType: 'delete',
                          item: item.businessUnitRankReception,
                        });
                        setSaveConfirmaionDialogOpen(true);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="BusinessUnitManagementConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/businessunitmanagement-list"
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div className="info">
            <div className="basic-info-body">
              {/* ////通知枠//// */}
              <div
                className={`notification-area ${
                  isNotificationShow ? '' : 'close'
                }`}
              >
                {/* {履歴通知エリア} */}
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <div className="notification-display-area">
                        {historyMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="code"
                      className="field"
                      type="text"
                      disabled={true}
                      value={item?.businessUnitManagement?.code ?? ''}
                      labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.code"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="displayName"
                      className="field"
                      type="text"
                      disabled={true}
                      value={item?.businessUnitManagement?.displayName ?? ''}
                      labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.displayName"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="status"
                      className="field"
                      type="text"
                      disabled={true}
                      value={
                        item?.businessUnitManagement?.status?.displayNameLang
                          ?.ja ?? ''
                      }
                      labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.status"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    {isVisibleChangeNotification && (
                      <CaptionButton
                        buttonType="basic"
                        caption="更新通知を確認"
                        className="confirmBtn"
                        name="confirmBtn"
                        onClick={() => {
                          setBusinessUnitChangeNotificationDialogOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="tabPanelContents">
                {tabs.map((v, index) => {
                  const selectPane = v === tabPanel ? 'current' : '';
                  return (
                    <div
                      key={`tab-${index}`}
                      className={`tabPanel ${selectPane}`}
                      onClick={() => setTabPanel(v)}
                    >
                      {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      {listBusinessUnitChangeNotificationsFilterTabName(v)
                        .length > 0 && (
                        <PriorityHighIcon className="notification-icon" />
                      )}
                    </div>
                  );
                })}
              </div>
              {isCurrentTab('profile') && renderProfileComponent()}
              {isCurrentTab('contact') && renderContactComponent()}
              {isCurrentTab('branch') && renderBranchComponent()}
              {isCurrentTab('financial') && renderFinancialComponent()}
              {isCurrentTab('facilities') && renderFacilitiesComponent()}
              {isCurrentTab('certificate') && renderCertificateComponent()}
              {isCurrentTab('strength') && renderStrengthComponent()}
              {isCurrentTab('document') && renderDocumentComponent()}
              {isCurrentTab('management') && renderManagementComponent()}
              {isCurrentTab('skill') && renderSkillComponent()}
              {isCurrentTab('rankRequest') && renderRankRequestComponent()}
              {isCurrentTab('rankReception') && renderRankReceptionComponent()}
            </div>
          </div>
        </div>

        {/* 履歴ダイアログ */}
        <HistoryDialog
          isOpen={isHistoryOpen}
          messageOption={{
            headerLabelId: {
              id: 'history',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          inputOption={{
            data: historys ?? [],
            keyColumn: 'history_id',
            columns: [
              {
                propertyName: 'slip_category',
                width: '12rem',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'slip_category',
                },
              },
              {
                propertyName: 'occurred_at',
                width: '12rem',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'occurred_at',
                },
              },
              {
                propertyName: 'content',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'content',
                },
              },
            ],
          }}
          onCancel={() => {
            setHistoryOpen(false);
          }}
        />
        {/* 更新通知ダイアログ */}
        <ChangeNotificationDialog
          isOpen={isBusinessUnitChangeNotificationDialogOpen}
          inputOption={{
            data: changeNotifications,
            keyColumn: 'changeNotificationId',
            columns: [
              {
                propertyName: 'targetKey',
                width: '18%',
                header: {
                  prefixId: 'ChangeNotificationDialog',
                  id: 'targetKey',
                },
              },
              {
                propertyName: 'targetFieldPath',
                width: '18%',
                header: {
                  prefixId: 'ChangeNotificationDialog',
                  id: 'targetFieldPath',
                },
              },
              {
                propertyName: 'oldValue',
                width: '18%',
                header: {
                  prefixId: 'ChangeNotificationDialog',
                  id: 'oldValue',
                },
              },
              {
                propertyName: 'newValue',
                width: '18%',
                header: {
                  prefixId: 'ChangeNotificationDialog',
                  id: 'newValue',
                },
              },
              {
                propertyName: 'remarks',
                width: '28%',
                header: {
                  prefixId: 'ChangeNotificationDialog',
                  id: 'remarks',
                },
              },
            ],
          }}
          onCancel={() => {
            setBusinessUnitChangeNotificationDialogOpen(false);
          }}
          onDecision={() => {
            handleConfirmedChangeNotification();
          }}
        />
        {/* 連絡先入力依頼ダイアログ */}
        <ContactRequestDialog
          isOpen={isContactRequestDialogOpen}
          inputOption={contactRequestFormItems}
          messageOption={{}}
          onCancel={() => {
            setContactRequestDialogOpen(false);
          }}
          onDecision={handleSaveContactRequest}
        />
        {/* 連絡先確認ダイアログ */}
        <ContactRegistrationDialog
          isOpen={isContactConfirmationDialogOpen}
          inputOption={contactConfirmationFormItems}
          onCancel={() => {
            setContactConfirmationDialogOpen(false);
          }}
          onDecision={() => {}}
        />
        {/* 自社情報設定（相手連絡先）ダイアログ */}
        <ContactAttributeSettingDialog
          isOpen={isContactAttributeSettingReceiveDialogOpen}
          messageOption={{
            headerLabelId: {
              prefixId: 'DIALOG_TITLE',
              id: 'contactAttributeSetting.other',
            },
            codeLabelId: {
              prefixId: 'ContactAttributeSettingDialog',
              id: 'code',
            },
          }}
          inputOption={contactAttributeReceiveFormItems}
          onCancel={() => {
            setContactAttributeSettingReceiveDialogOpen(false);
          }}
          onDecision={handleSaveContactAttributeReceive}
        />
        {/* 連絡先登録ダイアログ */}
        <ContactRegistrationDialog
          isOpen={isContactRegistrationDialogOpen}
          inputOption={contactRegistrationFormItems}
          onCancel={() => {
            setContactRegistrationDialogOpen(false);
          }}
          onDecision={handleSaveContact}
        />
        {/* 自社情報設定（自社連絡先）ダイアログ */}
        <ContactAttributeSettingDialog
          isOpen={isContactAttributeSettingSendDialogOpen}
          messageOption={{
            headerLabelId: {
              prefixId: 'DIALOG_TITLE',
              id: 'contactAttributeSetting.self',
            },
            codeLabelId: {
              prefixId: 'ContactAttributeSettingDialog',
              id: 'organization',
            },
          }}
          inputOption={contactAttributeSendFormItems}
          onCancel={() => {
            setContactAttributeSettingSendDialogOpen(false);
          }}
          onDecision={handleSaveContactAttributeSend}
        />
        {/* 拠点情報確認ダイアログ */}
        <BusinessUnitBranchDialog
          isOpen={isBranchDialogOpen}
          inputMode={false}
          inputOption={branchFormItems}
          onCancel={() => {
            setBranchDialogOpen(false);
          }}
          onDesicion={() => {}}
        />
        {/* 設備情報確認ダイアログ */}
        <BusinessUnitFacilityDialog
          isOpen={isFacilityDialogOpen}
          inputMode={false}
          inputOption={facilityFormItems ?? { companyId: '' }}
          onCancel={() => {
            setFacilityDialogOpen(false);
          }}
          onDecision={() => {}}
        />
        {/* 得意分野情報確認ダイアログ */}
        <BusinessUnitStrengthDialog
          isOpen={isStrengthDialogOpen}
          inputMode={false}
          inputOption={strengthFormItems}
          onCancel={() => {
            setStrengthDialogOpen(false);
          }}
          onDecision={() => {}}
        />
        {/* 認証情報確認ダイアログ */}
        <CertificateRegistrationDialog
          isOpen={isCertificateDialogOpen}
          inputOption={certificateFormItems}
          onCancel={() => {
            setCertificateDialogOpen(false);
          }}
          onDecision={() => {}}
          messageOption={{}}
        />
        {/* 文書確認ダイアログ */}
        <DocumentRegistrationDialog
          isOpen={isDocumentConfirmationDialogOpen}
          inputOption={documentConfirmationFormItems}
          onEdit={handleDocumentRegistrationDialog}
          onDelete={handleDeleteDocument}
          onCancel={() => {
            setDocumentConfirmationDialogOpen(false);
          }}
          onSave={() => {}}
        />
        {/* 文書登録ダイアログ */}
        <DocumentRegistrationDialog
          isOpen={isDocumentRegistrationDialogOpen}
          inputOption={documentRegistrationFormItems}
          onEdit={() => {}}
          onDelete={() => {}}
          onCancel={() => {
            setDocumentRegistrationDialogOpen(false);
          }}
          onSave={(v) => {
            handleSaveDocument(v);
          }}
        />
        {/* 取引停止 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isStopBusinessConfirmOpen}
          viewMessage={stopBusinessMessage}
          onDecision={() => {
            handleStopBusiness();
          }}
          onCancel={() => {
            setStopBusinessConfirmOpen(false);
          }}
        />
        {/* 取引再開 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isStartBusinessConfirmOpen}
          viewMessage={startBusinessMessage}
          onDecision={() => {
            handleStartBusiness();
          }}
          onCancel={() => {
            setStartBusinessConfirmOpen(false);
          }}
        />
        {/* 通知/更新再開ダイアログ */}
        <CommentDialog
          isOpen={isNotificationStartDialogOpen}
          inputOption={{
            comment: notificationStartStopDialogComment,
            modeType: 'send',
          }}
          inputStateOption={{
            onChangeComment: setNotificationStartStopDialogComment,
          }}
          messageOption={{
            headerLabelId: {
              id: 'notificationUpdateStart',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'notificationUpdateStart',
              prefixId: 'DIALOG_DESCRIPTION',
            },
          }}
          onDecision={() => {
            handleActivateBusinessUnit();
          }}
          onCancel={() => {
            setNotificationStartDialogOpen(false);
          }}
        />
        {/* 通知/更新停止ダイアログ */}
        <CommentDialog
          isOpen={isNotificationStopDialogOpen}
          inputOption={{
            comment: notificationStartStopDialogComment,
            modeType: 'send',
          }}
          inputStateOption={{
            onChangeComment: setNotificationStartStopDialogComment,
          }}
          messageOption={{
            headerLabelId: {
              id: 'notificationUpdateStop',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'notificationUpdateStop',
              prefixId: 'DIALOG_DESCRIPTION',
            },
          }}
          onDecision={() => {
            handleInactivateBusinessUnit();
          }}
          onCancel={() => {
            setNotificationStopDialogOpen(false);
          }}
        />
        {/* 責任者登録/確認ダイアログ */}
        <ResponsiblePersonRegistrationDialog
          isOpen={isResponsiblePersonRegistrationDialogOpen}
          inputOption={rankFormItem}
          onDecision={(v) => {
            setConfirmationDialogInfo({
              actionType: 'save',
              item: v,
            });
            setSaveConfirmaionDialogOpen(true);
          }}
          onCancel={() => {
            setResponsiblePersonRegistrationDialogOpen(false);
          }}
          onChangeLoadingState={(v: boolean) => {
            setLoadingRankReception(v);
          }}
        />
        {/* 自社企業情報の保存 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isSaveConfirmationDialogOpen}
          viewMessage={
            confirmationDialogInfo.actionType === 'save'
              ? viewMessageSave
              : viewMessageDelete
          }
          onDecision={() => {
            confirmationDialogInfo.actionType === 'delete'
              ? handleDeleteReceptionItem(
                  confirmationDialogInfo.item as RankType
                )
              : handleSaveRankReception(
                  confirmationDialogInfo.item as mtechnavi.api.company.IBusinessUnitRankReception
                );
          }}
          onCancel={() => {
            setSaveConfirmaionDialogOpen(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
