import { useMemo } from 'react';
import { GroupTreeItem } from './util';
import { GroupTreeEditActions, GroupTreeEditPane } from './GroupTreeEditPane';

export interface GroupTreeEdgeProps<T> {
  data: GroupTreeItem<T>;
  level: number;
  searchWord?: string;
  isParentDisable?: boolean;
  selectedGroup: string | null;
  onSelectGroup: (selectedGroup: string | null) => void;
  onDrop?: (data: { targetId: string; parentId: string }) => void;
  editActions: GroupTreeEditActions<T>;
}

/**
 * ツリーの末端コンポーネント。
 */
export const GroupTreeEdge = <T,>({
  data,
  level,
  searchWord,
  isParentDisable,
  selectedGroup,
  onSelectGroup,
  onDrop,
  editActions,
}: GroupTreeEdgeProps<T>) => {
  const isSelected = selectedGroup === data.id;
  const isDisabled = isParentDisable || data.isDisabled;
  const isMatch = useMemo<boolean | null>(() => {
    if (!searchWord) {
      return null;
    }
    return !!(data.displayName ?? '').match(searchWord);
  }, [data.displayName, searchWord]);

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onSelectGroup && onSelectGroup(data.id || null);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', data.id || '');
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const targetId = e.dataTransfer.getData('text/plain');
    const parentId = data.id || '';
    if (targetId === parentId) {
      return;
    }
    onDrop && onDrop({ targetId, parentId });
  };
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div
      className={`GroupTreeEdge id-${data.id} ${isSelected ? 'selected' : ''} ${
        level % 2 === 0 ? 'even' : 'odd'
      } ${isDisabled ? 'disabled' : ''} ${
        isMatch === null ? '' : isMatch ? 'matched' : 'unmatched'
      }`}
      key={data.id}
      onClick={handleSelect}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <span>{data.displayName}</span>
      <GroupTreeEditPane
        editActions={editActions}
        data={data}
        isCanDelete={true}
        isDisabled={isDisabled}
      />
    </div>
  );
};
