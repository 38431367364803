import { useState } from 'react';
import { FileUploadAppendListDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { AttachmentItems } from '~/shared/utils';

export const UISampleFileUploadAppendListDialogs = () => {
  const [result, setResult] = useState<AttachmentItems[]>([]);
  const [isShowEditAnswerAttachmentDialog, setShowEditAnswerAttachmentDialog] =
    useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">リスト付きファイルアップロードダイアログ</h3>

      <h4>回答添付編集ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowEditAnswerAttachmentDialog(true);
              }}
            />
            <FileUploadAppendListDialog
              uploaderOption={{
                validateOption: {
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              inputOption={{
                files: result,
              }}
              displayOption={{
                isDnd: true,
              }}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'answer-attach-file-edit',
                },
                buttonType: {
                  id: 'save',
                },
                simpleListViewHeader: {
                  prefixId: 'FileManagementDialog',
                  id: 'simpleListViewHeader',
                },
              }}
              isOpen={isShowEditAnswerAttachmentDialog}
              onDecision={(v) => {
                setResult(
                  // 永続化されたことを再現するため assetId に適当な値をセットする
                  (v.files ?? []).map((item) => ({ ...item, assetId: '1' }))
                );
                setShowEditAnswerAttachmentDialog(false);
              }}
              onCancel={() => {
                setShowEditAnswerAttachmentDialog(false);
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
