import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import '../Textbox/TextBox'; // TextBoxの派生型なので共通のcssを読み込む
import './AmountBox.css';
import { validateOverDigits, validateRangeNumber } from '../validator';
import Long from 'long';
import { GetMessage } from '../Message/Message';
import {
  Amounts,
  convertNumber,
  getErrorBorderClassName,
} from '~/shared/utils';
import { useIntl } from 'react-intl';

export interface AmountBoxProps {
  name: string;
  className?: string;
  value?: Amounts;
  placeholder?: string;
  validator?: (v: number) => string[];
  validateOption?: AmountBoxValidateOption;
  displayOption?: AmountBoxDisplayOption;
  externalIcon?: JSX.Element;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: Amount | null) => void;
}

export interface AmountBoxValidateOption {
  length?: { min?: number; max?: number };
  isSkippedValidation?: boolean;
  required?: boolean;
  isSkippedRequireCheck?: boolean;
}
export interface AmountBoxDisplayOption {
  digits?: number; // 少数第何位までの表示とするか（切り捨て）
  isCommaFormat?: boolean; // 3桁区切りを表示するかどうか
}
export interface Amount {
  integralAmount?: Long;
  fractionalAmount?: number;
}

/** 小数点以下の誤差を避ける為、整数部と少数部をそれぞれ整数として表現する型 */
interface ParsedNumber {
  integerPart: number;
  decimalPart: number;
}

/**
 * 小数点以下の基準桁数
 *
 * 将来的に4桁限定としなくなるかも。
 * その場合には displayOption.digits の値を参照することになると思われる。
 */
const DECIMAL_BASE_DIGIT = 4;

// 3桁区切り表記に変換
function toCommaFormatString(value: number) {
  //切り捨て処理は既に実施済みなのでmaximumFractionDigitsを最大値の20に設定
  return value.toLocaleString(undefined, { maximumFractionDigits: 20 });
}

// Amount型に変換
function toAmount(value: string | undefined): Amount {
  if (value === undefined) {
    return {};
  }
  const removedCommaValue = value.replaceAll(',', '');
  const split = removedCommaValue.split('.');

  return {
    integralAmount: Long.fromString(split.shift() ?? '0'),
    fractionalAmount: Number(
      split
        .shift()
        ?.slice(0, DECIMAL_BASE_DIGIT)
        .padEnd(DECIMAL_BASE_DIGIT, '0') ?? 0
    ),
  };
}

// 切り捨て処理
function floorParsedNumber(value: ParsedNumber, digits: number): ParsedNumber {
  const pow = Math.pow(10, DECIMAL_BASE_DIGIT - digits);
  const conditionNum = value.integerPart < 0 ? -1 : 1;
  return {
    ...value,
    decimalPart:
      // マイナス数値の場合は、 -5.6 => -5 に切り捨てられるように調整
      (Math.floor(Math.abs(value.decimalPart) / pow) * pow) / conditionNum,
  };
}

function stringToParsedNumber(value?: string | null): ParsedNumber | null {
  if (!value) {
    return null;
  }
  const removedCommaValue = value.replaceAll(',', '');
  if (Number.isNaN(Number(removedCommaValue))) {
    return null;
  }
  const [integerPartString, decimalPartString] = removedCommaValue.split('.');
  const integerPart = Number(integerPartString);
  const decimalPart = Number(
    decimalPartString ? decimalPartString.padEnd(DECIMAL_BASE_DIGIT, '0') : ''
  );
  return {
    integerPart: Number.isNaN(integerPart) ? 0 : integerPart,
    decimalPart: Number.isNaN(decimalPart) ? 0 : decimalPart,
  };
}

function parsedNumberToString(
  value: ParsedNumber | null | undefined,
  isComma: boolean
): string {
  if (
    !value ||
    Number.isNaN(value.integerPart) ||
    Number.isNaN(value.decimalPart)
  ) {
    return '';
  }
  const integerPartString = isComma
    ? toCommaFormatString(value.integerPart)
    : value.integerPart;
  let decimalPartString = '';
  if (value.decimalPart !== 0) {
    decimalPartString = `.${value.decimalPart
      .toString()
      .padStart(DECIMAL_BASE_DIGIT, '0')}`;
    while (decimalPartString.length > 1 && decimalPartString.endsWith('0')) {
      decimalPartString = decimalPartString.substring(
        0,
        decimalPartString.length - 1
      );
    }
  }
  return `${integerPartString}${decimalPartString}`;
}

function amountToParsedNumber(
  amount: Amounts | null | undefined
): ParsedNumber {
  if (!amount) {
    return { integerPart: 0, decimalPart: 0 };
  }
  const integralAmount = amount?.integralAmount
    ? Long.fromValue(amount.integralAmount).toString()
    : '0';
  return {
    integerPart: Number(integralAmount),
    decimalPart: amount?.fractionalAmount || 0,
  };
}

export function AmountBox(props: AmountBoxProps) {
  const intl = useIntl();
  const requireMessage = GetMessage({ id: 'E0000003' });
  const onlyNumMessage = GetMessage({ id: 'E0000004' });
  const { onChangeState, validator } = props;
  // inputのクラス名
  const inputClassName = props.className ?? '';
  const style = props.style ?? {};
  const isComma = props.displayOption?.isCommaFormat ?? false;

  // オプショナルチェック
  const validate = useCallback(
    (v: number): string[] => {
      if (!validator) {
        return [];
      }
      return validator(v);
    },
    [validator]
  );

  const changeParentState = useCallback(
    (v: Amount | null) => {
      onChangeState && onChangeState(v);
    },
    [onChangeState]
  ); // Amount型(Amounts型ではない)を親要素へ連携

  const maxLength =
    props.validateOption?.length?.max ?? Number.MAX_SAFE_INTEGER;
  const minLength =
    props.validateOption?.length?.min ?? Number.MIN_SAFE_INTEGER;
  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;
  const isSkippedRequireCheck =
    props.validateOption?.isSkippedRequireCheck ?? false;

  // 引数チェック
  if (minLength > maxLength) {
    throw new Error(
      'assertion error: minLengthはmaxLengthより小さい値を設定してください'
    );
  }

  // useState
  const [message, setMessage] = useState<string[]>([]);
  const [displayValue, setDisplayValue] = useState(''); // 表示用の値を保持
  // クリアボタンの表示・非表示を制御
  const [clearButtonVisibility, setClearButtonVisibility] = useState<
    'visible' | 'hidden'
  >('hidden');

  // useMemo
  const digits = useMemo(() => {
    const num = props.displayOption?.digits ?? 0;
    if (num < 0) {
      throw new Error('assertion error: digitsには正の数を入れてください');
    }
    return num;
  }, [props.displayOption?.digits]);

  // 親コンポーネントからprops.valueが変更された場合に入力値を変更する
  useEffect(() => {
    // nullが入る場合はエラーの場合のみなので処理をスキップする
    if (props.value === null) {
      return;
    }
    if (!props.value?.integralAmount && !props.value?.fractionalAmount) {
      setDisplayValue('');
      return;
    }
    const parsedValue = amountToParsedNumber(props.value);
    const valueString = parsedNumberToString(parsedValue, isComma);
    setDisplayValue(valueString);
  }, [isComma, props.value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnBlur = (inputValue: string) => {
    // バリデーションエラーのメッセージ
    const msg: string[] = [];

    // 必須入力チェック
    if (!isSkippedRequireCheck && required && inputValue === '') {
      setMessage([requireMessage]);
      changeParentState(null);
      return;
    }

    if (inputValue === '') {
      changeParentState(null);
      return;
    }

    // 数値に変換できるかのチェック
    let numberValue = 0;
    try {
      numberValue = convertNumber(inputValue);
    } catch (error) {
      changeParentState(null);
      setDisplayValue(inputValue);
      setMessage([onlyNumMessage]);
      return;
    }

    // 固有の範囲チェック
    msg.push(...validateRangeNumber(intl, numberValue, minLength, maxLength));

    // 固有の小数点以下桁チェック
    msg.push(
      ...validateOverDigits(
        intl,
        props.displayOption?.digits ?? null
      )(inputValue)
    );

    // 固有バリデーションを行うかどうか
    if (isSkippedValidation) {
      changeParentState(toAmount(inputValue));
      return;
    }

    // バリデーション
    msg.push(...validate(numberValue));

    // フォーマット実施チェック
    if (msg.length > 0) {
      // バリデーションエラーが存在する場合はエラーメッセージをセット
      changeParentState(null);
      setDisplayValue(inputValue);
      setMessage([...msg]);
    } else {
      setMessage([]);

      const parsedNumber = stringToParsedNumber(inputValue);
      if (!parsedNumber) {
        return;
      }

      // 切り捨て処理
      const convertedValue = parsedNumberToString(
        floorParsedNumber(parsedNumber, digits),
        isComma
      );

      changeParentState(toAmount(convertedValue)); // 親要素に値を連携
      setDisplayValue(convertedValue); // フォーマットを行なった表示用文字列をセット
    }
  };

  useEffect(() => {
    // 必須制御が変わって任意になる場合にエラー表示をクリアする
    if (!required) {
      // 値が空の場合だけに対応範囲を限定する
      if (!props.value?.integralAmount) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なので、props.validateOption?.requiredのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  useEffect(() => {
    // 必須チェック不要になる場合にエラー表示をクリアする
    if (isSkippedRequireCheck) {
      // 値が空の場合だけに対応範囲を限定する
      if (!props.value?.integralAmount) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkippedRequireCheck]);

  return (
    <div className="TextBox AmountBox">
      <div
        className={`main-area ${
          props.disabled ? 'basic-disabled-input-border' : 'basic-input-border'
        } ${getErrorBorderClassName(message)}`}
        onMouseEnter={() => {
          // マウスオーバー時に入力値が無ければクリアボタンは非表示
          if (!displayValue) {
            return;
          }
          setClearButtonVisibility('visible');
        }}
        onMouseLeave={() => {
          setClearButtonVisibility('hidden');
        }}
        onClick={() => {
          //クリアボタンがhiddenになっている場合も含めてinputにフォーカス
          inputRef.current?.focus();
        }}
      >
        <input
          name={props.name}
          className={`right ${inputClassName}`}
          onBlur={(event) => {
            handleOnBlur(event.target.value);
          }}
          value={displayValue ?? ''}
          placeholder={props.placeholder}
          onChange={(event) => {
            setDisplayValue(event.target.value);
          }}
          onFocus={() => {
            if (isComma) {
              // フォーカスされたタイミングで表示用の値から3桁区切りを除去
              setDisplayValue(displayValue.replaceAll(',', ''));
            }
          }}
          required={required}
          disabled={props.disabled}
          style={style}
          ref={inputRef}
          autoComplete="off"
        />

        {/* クリアボタン */}
        {!props.disabled && (
          <div className="icon-wrapper">
            <CloseIcon
              className="padding"
              onClick={(event) => {
                event.stopPropagation(); // input側のblur処理を抑止
                setDisplayValue('');
                changeParentState(null);
                inputRef.current?.focus();
                setMessage([]);
              }}
              style={{ visibility: `${clearButtonVisibility}` }}
            ></CloseIcon>
          </div>
        )}

        {/* 外部指定アイコン */}
        {props.externalIcon && (
          <div className="icon-wrapper">{props.externalIcon}</div>
        )}
      </div>

      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
