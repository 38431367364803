import { PropsWithoutRef } from 'react';
import {
  DataFilterbox,
  DataFilterboxItem,
  DataFilterboxValidateOption,
} from '../../Filterbox';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface FormInputSelectProps {
  name: string;
  className?: string;
  label?: string;
  description?: string;
  workingBlur?: Date;
  validateOption?: DataFilterboxValidateOption;
  multiple?: boolean;
  disabled?: boolean;
  data: DataFilterboxItem[];
  value?:
    | mtechnavi.api.form.IFormControlSelect
    | mtechnavi.api.form.IFormControlSelectMultiple;
  onChange?: (
    selected:
      | mtechnavi.api.form.IFormControlSelect
      | mtechnavi.api.form.IFormControlSelectMultiple
  ) => void;
}

const isMultipleValue = (
  val:
    | mtechnavi.api.form.IFormControlSelect
    | mtechnavi.api.form.IFormControlSelectMultiple
    | undefined
): val is mtechnavi.api.form.IFormControlSelectMultiple => {
  return !!val && 'items' in val;
};
const isSingleValue = (
  val:
    | mtechnavi.api.form.IFormControlSelect
    | mtechnavi.api.form.IFormControlSelectMultiple
    | undefined
): val is mtechnavi.api.form.IFormControlSelect => {
  return !!val && 'item' in val;
};

export const FormInputSelect = (
  props: PropsWithoutRef<FormInputSelectProps>
) => {
  const onChange = props?.onChange ?? (() => {});
  const value = (
    (isMultipleValue(props?.value) && props?.value?.items) ||
    (isSingleValue(props?.value) && [props?.value?.item]) ||
    []
  )?.map((item) => ({
    displayName: item?.displayName || '',
    value: item?.value || '',
  }));

  const handleChangeValue = (selected: DataFilterboxItem[]) => {
    if (props.multiple) {
      onChange({ items: selected });
    } else {
      onChange({ item: selected.at(0) });
    }
  };

  return (
    <DataFilterbox
      name={props.name || ''}
      columns={[props.name || '']}
      formBuilderOption={{
        label: props?.label || '',
        description: props?.description || '',
      }}
      workingBlur={props?.workingBlur}
      validateOption={props.validateOption}
      multiple={props?.multiple}
      disabled={props.disabled}
      data={props.data || []}
      onChangeState={handleChangeValue}
      value={value}
    />
  );
};
