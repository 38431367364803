import React, { CSSProperties } from 'react';
import './CaptionButton.css';
import { exportGoogleAnalytics } from '~/shared/utils';

export type ButtonType = 'high' | 'basic' | 'sub' | 'cancel' | 'danger';

export interface CaptionButtonProps {
  name: string;
  caption?: string;
  buttonType?: ButtonType;
  className?: string;
  style?: CSSProperties;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  isNoAnalytics?: boolean;
}

export const CaptionButton = (props: CaptionButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onClick(e);
    // GoogleAnalyticsへデータ送信
    if (props.isNoAnalytics) {
      exportGoogleAnalytics('button', { targetName: props.name });
    }
  };

  return (
    <button
      type="button"
      className={`CaptionButton ${props.buttonType || ''} ${
        props.className || ''
      }`}
      onClick={handleClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.caption}
    </button>
  );
};
