import { useState } from 'react';
import {
  Container,
  Toast,
  success,
  error,
  ModalDialogComponent,
  ModalDialogComponentProps,
  LoadingIcon,
  GetMessage,
} from '~/shared/components';

export function Dialog() {
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [comment, setComment] = useState('');

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModalDialog = () => {
    setModalIsOpen(false);
  };

  const sendModalDialog = () => {
    setModalIsOpen(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  };

  const failMessage = GetMessage({
    id: 'E0000144',
    value: { $2: 'なにか', $1: 'テスト' },
  });

  const openModalProps: ModalDialogComponentProps = {
    cancel: closeModalDialog,
    send: sendModalDialog,
    modalIsOpen: baseModalIsOpen,
    comment: comment,
    onChangeState: setComment,
    headerLabelId: { id: 'approvalRequestHeader' }, // debugページなのでidで指定
    titleLabelId: { id: 'approvalRequestTitle' }, // debugページなのでidで指定
    messageLabelId: { id: 'approvalRequestMessage' }, // debugページなのでidで指定
  };

  const openToast = () => {
    const mes = ['書き込みに成功しました。'];
    success(mes);
  };

  const openErrorToast = () => {
    const mes = [failMessage];
    error(mes);
  };

  return (
    <Container>
      <div>
        <h3>ボタン群</h3>
        <br />
        <button type="submit" onClick={openModal}>
          Open Modal
        </button>
        <button type="submit" onClick={openToast}>
          Open Success Toast
        </button>
        <button type="submit" onClick={openErrorToast}>
          Open Error Toast
        </button>
        <Toast />
        <ModalDialogComponent {...openModalProps} />
        {isLoading && <LoadingIcon />}
      </div>
    </Container>
  );
}
