import { Navigate, Route, Routes } from 'react-router-dom';
import { BcpDisasterList } from './pages/BcpDisasterList';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { BcpDisasterSurveyList } from './pages/BcpDisasterSurveyList';
import { BcpDisasterSurveyInput } from './pages/BcpDisasterSurveyInput';
import { BcpDisasterSurveyConfirmation } from './pages/BcpDisasterSurveyConfirmation';

export const BcpRoutes = () => {
  return (
    <Routes>
      <Route
        path="/bcp-disaster-list"
        element={
          <RequireAuth>
            <BcpDisasterList />
          </RequireAuth>
        }
      />
      <Route
        path="/bcp-disaster-survey-list"
        element={
          <RequireAuth>
            <BcpDisasterSurveyList />
          </RequireAuth>
        }
      />
      <Route
        path="/bcp-disaster-survey-input"
        element={
          <RequireAuth>
            <BcpDisasterSurveyInput />
          </RequireAuth>
        }
      />
      <Route
        path="/bcp-disaster-survey-confirmation"
        element={
          <RequireAuth>
            <BcpDisasterSurveyConfirmation />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
