import { useEffect, useState, useMemo } from 'react';
import type { FilterOperator, FilterPrimitive, FilterTerm } from '~/worker';
import { Textbox } from '~/shared/components/ui/Textbox';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { FilterViewQuery } from '../index';
import {
  getPresetPropertysByColumnName,
  checkDisalbedPresetProperty,
} from '../preset';
import {
  isFilterEq,
  isFilterLike,
  isString,
  toFilterElemMatchFilter,
  toLikeFilter,
} from '../filter';

export const toTextboxFilterTerms = (
  column: string,
  text: string,
  optFn?: (v: FilterPrimitive) => FilterOperator
): FilterTerm[] => {
  const values = splitStringByEmpty(text);
  return values.length > 0
    ? [
        toFilterElemMatchFilter(
          column,
          values,
          optFn ??
            ((v) => {
              return { $like: v };
            })
        ),
      ]
    : [];
};

export const getFilteringTextboxValues = (terms: FilterTerm[]): string[] => {
  const values: string[] = [];
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      if (isFilterLike(v) && isString(v.$like)) {
        values.push(v.$like);
        continue;
      }
      if (isFilterEq(v) && isString(v.$eq)) {
        values.push(v.$eq);
        continue;
      }
    }
  }
  return values;
};

// 指定された文字列の長さよりも長い文字列の場合は、指定した長さでカットし、省略用の文字列を追加する
const splitStringByEmpty = (v: string): string[] => {
  return v
    .replace(/\u{3000}/gu, ' ')
    .split(' ')
    .filter(String);
};

interface FilterInputTextboxProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}

/**
 * テキスト検索フィルタ
 *
 * 文字列フィールド（文字列配列フィールドも含む）に部分一致で検索を行う。
 * 複数指定で検索を行う場合は、スペース区切りで検索が可能。「e.g. "a b c"」
 *
 * - プリセット
 *     - type='text'
 */
export function FilterInputTextbox(props: FilterInputTextboxProps) {
  const { labelId, column, presetItem, filterValue, onChangeFilter } = props;
  const [textItem, setTextItem] = useState<string>('');

  const [columnPropertys, isDisabledColumn] = useMemo<
    [PresetProperty[], boolean]
  >(() => {
    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    const columnProoerty = getPresetPropertysByColumnName(
      presetItem?.property,
      column
    );
    return [columnProoerty, isDisabled];
  }, [presetItem?.property, presetItem?.children, column]);

  const onChangeTextboxState = (text: string) => {
    const filterTerms: FilterTerm[] = [];
    text && filterTerms.push(toLikeFilter(column, text));
    setTextItem(text);
    onChangeFilter && onChangeFilter(filterTerms);
  };

  useEffect(() => {
    const textboxValues = getFilteringTextboxValues(
      filterValue.filterTerms[column] ?? []
    );
    setTextItem(textboxValues.join(' '));
  }, [column, filterValue.filterTerms]);

  // タイプに応じて内容を表示
  return (
    <div className="input-area">
      <Textbox
        name={column}
        type="text"
        value={textItem}
        columns={[column]}
        labelId={labelId}
        onChangeState={(v) => {
          onChangeTextboxState(v);
        }}
        properties={columnPropertys}
        disabled={isDisabledColumn}
      />
    </div>
  );
}
