import { useState } from 'react';
import { FileItem, FileUploader } from '~/shared/components/file';

const FILE_UPLOAD = 'ファイルアップロード';
const FILE_UPLOAD_DND = 'ファイルアップロード(D&D)';

export const UISampleFileUploads = () => {
  // ファイルアップロード
  const [fileUpload, setFileUpload] = useState<FileItem[]>([]);
  const [dnd, setDnd] = useState<FileItem[]>([]);

  return (
    <div className="categories">
      <h3 id="file-uploads">ファイルアップロード</h3>

      <h4>{FILE_UPLOAD} （単一選択）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <FileUploader name="upload" onUpload={setFileUpload}></FileUploader>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(fileUpload, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>
        {FILE_UPLOAD_DND}
        (複数選択可、ファイルサイズ2MBまで、テキストファイルとCSVのみ許可)
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <FileUploader
              name="dnd"
              dndOption={{
                enabled: true,
                style: {
                  backgroundColor: 'rgba(200, 100, 100, .5)',
                  border: '2px dashed black',
                  height: '200px',
                  width: '200px',
                },
              }}
              multiple={true}
              validateOption={{
                allowedFileExtensions: ['text', 'txt', 'csv'],
                maxFileSizeInMebis: 2,
              }}
              onUpload={setDnd}
              //disabled={true}
            ></FileUploader>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(dnd, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
