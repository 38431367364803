import { useEffect, useState, CSSProperties, useCallback } from 'react';
import './Tel.css';
import { TextBox } from '../Textbox/TextBox';

export interface TelProps {
  name: string;
  className?: string;
  value?: string;
  validator?: (v: string) => string[];
  validateOption?: TelValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string) => void;
}

export interface TelValidateOption {
  required?: boolean;
}

// 郵便番号入力を表示
export function Tel(props: TelProps) {
  // Telのクラス名
  const telClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validator =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const onChangeState = props.onChangeState;
  const changeParentState = useCallback(
    (v: string) => {
      onChangeState && onChangeState(v);
    },
    [onChangeState]
  );

  // state
  const [tel, setTel] = useState(props.value ?? '');

  useEffect(() => {
    setTel(props.value ?? '');
  }, [props.value]);

  return (
    <div className="Tel">
      <TextBox
        name={props.name}
        type="text"
        className={telClassName}
        value={tel}
        validator={validator}
        validateOption={{ required: required }}
        disabled={props.disabled}
        onChangeState={(v) => {
          onChangeState && changeParentState(v);
          setTel(v);
        }}
        style={style}
      ></TextBox>
    </div>
  );
}
