import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import './ThreadItem.css';
import { Textarea } from '../Textarea';
import { IconButton } from '~/shared/components/parts/Button/IconButton';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import { GetMessage, error } from '~/shared/components';
import { getListComments } from './ApiUtil';
import { ThreadComment } from './ThreadComment';
import { getExceptionMessage } from '~/shared/utils';
import { Textbox } from '../Textbox';
import { useCommentPaneDispatch } from './CommentPaneProvider';

interface ThreadItemProps {
  thread: mtechnavi.api.forum.IThread;
  companies?: mtechnavi.api.company.ICompany[];
  unreadMarker?: sharelib.INameOption;
  isActive?: boolean;
  onClick?: () => void;
}

export const ThreadItem = ({
  thread,
  companies,
  unreadMarker,
  isActive,
  onClick,
}: ThreadItemProps) => {
  const [commentList, setCommentList] = useState<
    mtechnavi.api.forum.IComment[]
  >([]);
  // 下方にあるコメントアウト箇所で使用。一時的にlint除外2024/04/16
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewCommentList, setViewCommentList] = useState<ThreadComment[]>([]);
  const [draftComment, setDraftComment] = useState('');
  const [isEdit, setEdit] = useState(false);
  const [editThreadTitle, setEditThreadTitle] = useState('');
  const [isWorking, setWorking] = useState(false);
  const intl = useIntl();
  const commentPaneDispatch = useCommentPaneDispatch();

  const loadComment = useCallback(async () => {
    try {
      const response = await getListComments(thread.threadId);
      setCommentList(response.items as mtechnavi.api.forum.IComment[]);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  }, [intl, thread.threadId]);

  const lookupCompany = useCallback(
    (
      id: string | null | undefined,
      companies: mtechnavi.api.company.ICompany[]
    ): mtechnavi.api.company.ICompany | null => {
      if (!id) {
        return null;
      }
      return (companies ?? []).find((item) => item.companyId == id) || null;
    },
    []
  );

  const handleRefresh = useCallback(async () => {
    await loadComment();
  }, [loadComment]);

  const handleEditTitle = () => {
    setEditThreadTitle(thread?.displayName || '');
    setEdit(true);
  };

  const handleCancelThreadTitle = () => {
    setEdit(false);
    setEditThreadTitle('');
  };

  const handleSaveThreadTitle = async () => {
    try {
      setWorking(true);
      const updatedThread = await window.App.services.forumService.updateThread(
        {
          thread: { ...thread, displayName: editThreadTitle },
        }
      );
      commentPaneDispatch({
        type: 'updateThread',
        thread: updatedThread,
      });
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setEdit(false);
      setEditThreadTitle('');
      setWorking(false);
    }
  };

  // 期日対応される予定のため一時的にlint除外2024/04/16
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const mergeCommentWithCompany = useCallback(
    (
      comments: mtechnavi.api.forum.IComment[],
      companies: mtechnavi.api.company.ICompany[]
    ) => {
      // FIXME:5~8月の改善系に伴う修正 20204/04/23 現在
      // return comments.map((comment) => {
      //   const company = lookupCompany(
      //     comment.contributor?.companyId,
      //     companies
      //   );
      //   return {
      //     ...comment,
      //     companyName:
      //       (company?.displayNameLang &&
      //         company?.displayNameLang[intl.locale]) ??
      //       '',
      //   };
      // });
    },

    // lookupCompany 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, lookupCompany]
  );

  const handleSubmitComment = async () => {
    try {
      setWorking(true);

      await window.App.services.forumService.createComment({
        comment: {
          threadId: thread.threadId,
          text: draftComment,
        },
      });

      setDraftComment('');
      handleRefresh();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  useEffect(() => {
    loadComment();
  }, [loadComment]);

  useEffect(() => {
    // FIXME:5~8月の改善系に伴う修正 20204/04/23 現在
    // setViewCommentList(mergeCommentWithCompany(commentList, companies || []));
  }, [mergeCommentWithCompany, commentList, companies]);

  return (
    <div
      className={`Thread ${isActive ? 'active' : ''}`}
      id={`${thread.threadId}`}
      onClick={onClick}
    >
      <div className="header">
        <div className="thread-info">
          <span className="label">
            {thread.shareScope?.displayNameLang
              ? thread.shareScope?.displayNameLang[intl.locale]
              : ''}
          </span>
          <IconButton
            name="refresh"
            iconType="refresh"
            onClick={handleRefresh}
          />
        </div>
        {!isEdit && (
          <div className="title">
            {thread.displayName}
            {(!thread.sharedProperties?.sharedBy ||
              (companies &&
                thread.sharedProperties?.sharedBy ===
                  companies[0].companyId)) && (
              <IconButton
                name="edit"
                iconType="edit"
                onClick={handleEditTitle}
                disabled={isWorking}
              />
            )}
          </div>
        )}
        {isEdit && (
          <div className="title-edit">
            <Textbox
              type="text"
              name="displayName"
              labelId="thread.displayName"
              onChangeState={(v) => {
                setEditThreadTitle(v);
              }}
              value={editThreadTitle}
              validateOption={{ required: true }}
              columns={['displayName']}
              properties={[]}
              disabled={isWorking}
            />
            <div className="actions">
              <CaptionButton
                buttonType="cancel"
                name="cancel"
                caption={GetMessage({ id: 'cancel' })}
                onClick={handleCancelThreadTitle}
                disabled={isWorking}
              />
              <CaptionButton
                buttonType="basic"
                name="send"
                caption={GetMessage({ id: 'decision' })}
                onClick={handleSaveThreadTitle}
                disabled={isWorking || !editThreadTitle}
              />
            </div>
          </div>
        )}
      </div>
      <ul className="comment-list">
        {viewCommentList.map((comment) => (
          <li key={comment.commentId}>
            <ThreadComment
              comment={comment}
              companies={companies}
              unreadMarker={unreadMarker}
              refreshFunc={handleRefresh}
            />
          </li>
        ))}
      </ul>
      <div className="comment-form">
        <Textarea
          name="comment"
          className="comment-input"
          labelId="comment"
          value={draftComment ?? ''}
          columns={['comment']}
          isParentDeliver={true}
          onChangeState={(v) => {
            setDraftComment(v);
          }}
          validateOption={{ isSkippedValidation: true }}
          disabled={isWorking}
        />
        <div className="action">
          <CaptionButton
            buttonType="basic"
            name="submitComment"
            caption={GetMessage({ id: 'register' })}
            onClick={() => {
              handleSubmitComment();
            }}
            disabled={isWorking || !draftComment}
          />
        </div>
      </div>
    </div>
  );
};
