import React, { PropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { CategorizedBranch, CategorizedBranchRef } from './CategorizedBranch';
import {
  CategorizedTipCheckbox,
  CategorizedTipCheckboxRef,
} from './CategorizedTipCheckbox';
import {
  CategorizedBranchData,
  CategorizedItem,
  CheckItem,
  RelationMap,
  allCheckIdFormat,
  createRelationList,
  extractTargetIdItems,
  filterCategorizedList,
  isCategorizedBranchList,
  isCategorizedTipList,
} from './util';
import { Textbox } from '~/shared/components/ui';
import { CategorizedBranchCheckbox } from './CategorizedBranchCheckbox';
import { GetMessageWithIntl } from '~/shared/components';
import './CategorizedCheckList.css';
import { IconButton } from '~/shared/components/ui/Button';
import { DisplayNameLang } from '~/shared/utils/commonType';

const findOpenedAncestorLayer = (
  relationMap: RelationMap,
  targetId: string
):
  | React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>
  | undefined => {
  const parentId = relationMap[targetId]?.parentId || '';
  if (!parentId) {
    return relationMap[targetId]?.ref;
  }
  const parentRef = relationMap[parentId]?.ref;
  if (parentRef?.current?.isOpen()) {
    return relationMap[targetId]?.ref;
  } else {
    return findOpenedAncestorLayer(relationMap, parentId);
  }
};

const findNextAncestorSibling = (
  relationMap: RelationMap,
  targetId: string
):
  | React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>
  | undefined => {
  const parentId = relationMap[targetId].parentId || '';
  if (!parentId) {
    return;
  }
  const parentNextSiblingId = relationMap[parentId].nextSiblingId;
  if (parentNextSiblingId) {
    return relationMap[parentNextSiblingId]?.ref;
  } else {
    return findNextAncestorSibling(relationMap, parentId);
  }
};

const findMatchItem = (
  list: CategorizedItem[],
  searchWords: string[],
  intl: IntlShape
): CategorizedItem | undefined => {
  if (isCategorizedBranchList(list)) {
    return list
      .map((item) => {
        // カテゴリ自身が検索ワードに一致するならそちらを優先して返す
        if (
          searchWords.some((word) =>
            item.displayNameLang
              ? !!item.displayNameLang[intl.locale].match(word)
              : false
          )
        ) {
          return item;
        }
        // カテゴリ自身が一致しないなら子から探す
        const matches = findMatchItem(item.children, searchWords, intl);
        return matches;
      })
      .find((item) => !!item);
  }
  return list.find((item) =>
    searchWords.some((word) =>
      item.displayNameLang
        ? !!item.displayNameLang[intl.locale].match(word)
        : false
    )
      ? item
      : undefined
  );
};

export interface CategorizedCheckListInputOption {
  isUnVisibleSearchArea?: boolean;
  isVisibleOpenAll?: boolean;
  isVisibleCloseAll?: boolean;
  isVisibleOpenChecked?: boolean;
}

export interface CategorizedCheckListProps {
  listData: CategorizedBranchData[];
  checkedIds?: string[];
  disabled?: boolean;
  checkedOnly?: boolean;
  focusId?: string;
  onChange?: (checkedIdList: CheckItem[]) => void;
  inputOption?: CategorizedCheckListInputOption;
}

export const CategorizedCheckList = ({
  listData,
  checkedIds,
  disabled,
  checkedOnly,
  focusId,
  onChange,
  inputOption,
}: PropsWithoutRef<CategorizedCheckListProps>) => {
  const intl = useIntl();
  const [searchWord, setSearchWord] = useState('');
  const [checkedItemList, setCheckedIdList] = useState<CheckItem[]>([]);

  const viewListData = useMemo(
    () =>
      checkedOnly
        ? filterCategorizedList(
            listData,
            checkedItemList.map((item) => item.id || '')
          )
        : listData,
    [listData, checkedOnly, checkedItemList]
  );
  const relationMap = useMemo(
    () => createRelationList(viewListData),
    [viewListData]
  );

  const allLabel = useMemo(
    () => GetMessageWithIntl(intl, { id: 'all' }),
    [intl]
  );

  const handleChangeSearchWord = (v: string) => {
    setSearchWord(v);
  };
  const onSearch = () => {
    const matchSkill = findMatchItem(
      viewListData,
      searchWord.split(' ').filter((w) => !!w),
      intl
    );
    relationMap[matchSkill?.CategorizedItemId || '']?.ref?.current?.focus();
  };

  const handleCheck = (
    id: string,
    displayNameLang: DisplayNameLang,
    isChecked: boolean
  ) => {
    setCheckedIdList(
      isChecked
        ? [...checkedItemList, { id, displayNameLang }]
        : checkedItemList.filter((checkedKeyValue) => checkedKeyValue.id !== id)
    );
  };
  const handleCheckAll = (items: CheckItem[], isChecked: boolean) => {
    setCheckedIdList([
      ...checkedItemList.filter((checkedId) =>
        items.every((id) => id.id !== checkedId.id)
      ),
      ...(isChecked ? items : []),
    ]);
  };

  const handleBranchDown = (id: string) => {
    if (relationMap[id]?.ref?.current?.isOpen()) {
      // 次の階層が参照できない("全て"チェック非表示)場合はその次の要素にフォーカスする
      if (!relationMap[relationMap[id]?.nextLayerId || '']?.ref?.current) {
        relationMap[
          relationMap[relationMap[id]?.nextLayerId || '']?.nextId || ''
        ]?.ref?.current?.focus();
        return;
      }
      relationMap[relationMap[id]?.nextLayerId || '']?.ref?.current?.focus();
      return;
    }
    if (relationMap[id]?.nextSiblingId) {
      relationMap[relationMap[id]?.nextSiblingId || '']?.ref?.current?.focus();
      return;
    }
    // 次の兄弟要素がいないので、祖先の兄弟要素を探す
    const nextRef = findNextAncestorSibling(relationMap, id);
    nextRef?.current?.focus();
  };
  const handleBranchUp = (id: string) => {
    if (
      relationMap[relationMap[id]?.prevSiblingId || '']?.ref &&
      !relationMap[relationMap[id]?.prevSiblingId || '']?.ref?.current?.isOpen()
    ) {
      // 直前の兄弟が子を展開していなければ、その子ではなく直前の兄弟自体にフォーカスする
      relationMap[relationMap[id]?.prevSiblingId || '']?.ref?.current?.focus();
      return;
    }
    if (
      relationMap[relationMap[id]?.prevLayerId || '']?.ref?.current?.isOpen()
    ) {
      // 直前の階層が開いていればそれにフォーカス
      relationMap[relationMap[id]?.prevLayerId || '']?.ref?.current?.focus();
      return;
    }
    if (
      !relationMap[relationMap[id]?.prevLayerId || '']?.ref?.current &&
      relationMap[
        relationMap[relationMap[id]?.prevLayerId || '']?.nextId || ''
      ]?.ref?.current?.isOpen()
    ) {
      // 直前の階層の要素が参照できない("全て"チェック非表示)場合はその次の要素にフォーカス(その階層が開いていれば)する
      relationMap[
        relationMap[relationMap[id]?.prevLayerId || '']?.nextId || ''
      ]?.ref?.current?.focus();
      return;
    }
    // 直前の階層の、開いている祖先を探して遷移する
    const prevRef = findOpenedAncestorLayer(
      relationMap,
      relationMap[id]?.prevLayerId || ''
    );
    prevRef?.current?.focus();
  };
  const handleBranchRight = (id: string) => {
    if (!relationMap[id]?.ref?.current?.isOpen()) {
      relationMap[id]?.ref?.current?.open();
      return;
    }
    // 次の階層が参照できない("全て"チェック非表示)場合はその次の要素にフォーカスする
    if (!relationMap[relationMap[id]?.nextLayerId || '']?.ref?.current) {
      relationMap[
        relationMap[relationMap[id]?.nextLayerId || '']?.nextId || ''
      ]?.ref?.current?.focus();
      return;
    }
    relationMap[relationMap[id]?.nextLayerId || '']?.ref?.current?.focus();
  };
  const handleBranchLeft = (id: string) => {
    if (relationMap[id]?.ref?.current?.isOpen()) {
      relationMap[id]?.ref?.current?.close();
      return;
    }
    // 直前の要素の、開いている祖先を探して遷移する
    const prevRef = findOpenedAncestorLayer(
      relationMap,
      relationMap[id]?.prevId || ''
    );
    prevRef?.current?.focus();
  };

  const handleCheckboxLeft = (id: string) => {
    // 前の要素が参照できない場合は前のレイヤーに遷移
    if (!relationMap[relationMap[id]?.prevId || '']?.ref?.current) {
      relationMap[relationMap[id]?.prevLayerId || '']?.ref?.current?.focus();
      return;
    }
    relationMap[relationMap[id]?.prevId || '']?.ref?.current?.focus();
  };
  const handleCheckboxRight = (id: string) => {
    relationMap[relationMap[id]?.nextId || '']?.ref?.current?.focus();
  };
  const handleCheckboxUp = (id: string) => {
    relationMap[relationMap[id]?.prevLayerId || '']?.ref?.current?.focus();
  };
  const handleCheckboxDown = (id: string) => {
    relationMap[relationMap[id]?.nextLayerId || '']?.ref?.current?.focus();
  };

  const handleOpenAll = () => {
    for (const key in relationMap) {
      relationMap[key].ref?.current?.open();
    }
  };
  const handleCloseAll = () => {
    for (const key in relationMap) {
      if (relationMap[key].id.match('allCheck')) continue;
      relationMap[key].ref?.current?.close();
    }
  };
  const handleOpenChecked = () => {
    // 一度全て閉じる
    handleCloseAll();
    // 選択中のものだけを開く
    if (listData.length && checkedItemList.length) {
      checkedItemList.map((item) => {
        const parentId = relationMap[item.id || ''].parentId;
        if (parentId) {
          relationMap[parentId]?.ref?.current?.open();
        }
      });
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(checkedItemList);
    }
  }, [onChange, checkedItemList]);

  useEffect(() => {
    if (checkedIds) {
      const checkIdItems = extractTargetIdItems(listData, checkedIds);
      setCheckedIdList(checkIdItems);
    }
  }, [listData, checkedIds]);

  useEffect(() => {
    if (focusId) {
      relationMap[focusId].ref?.current?.focus();
    }
  }, [relationMap, focusId]);

  const renderCategory = (
    list: CategorizedItem[],
    level = 0,
    parentCategoryId = '',
    parentRef?: React.RefObject<
      CategorizedBranchRef | CategorizedTipCheckboxRef
    >
  ) => {
    if (isCategorizedBranchList(list)) {
      return list.map((item, index) => (
        <React.Fragment key={item.CategorizedItemId}>
          <CategorizedBranch
            categoryId={item.CategorizedItemId}
            label={
              item.displayNameLang ? item.displayNameLang[intl.locale] : ''
            }
            keyActions={{
              onDown: handleBranchDown,
              onUp: handleBranchUp,
              onLeft: handleBranchLeft,
              onRight: handleBranchRight,
            }}
            ref={relationMap[item.CategorizedItemId]?.ref}
            parentRef={parentRef}
            level={level}
            autoFocus={!disabled ? level === 0 && index === 0 : false}
            searchWord={searchWord}
            disabled={disabled}
          >
            {renderCategory(
              item.children,
              level + 1,
              item.CategorizedItemId,
              relationMap[item.CategorizedItemId]?.ref
            )}
          </CategorizedBranch>
        </React.Fragment>
      ));
    } else if (isCategorizedTipList(list)) {
      const containIds: CheckItem[] = list.map((item) => ({
        id: item.CategorizedItemId,
        displayNameLang: item.displayNameLang,
      }));
      return (
        <div className="tip-checkbox-list-container">
          {!disabled && (
            <div className="all-check-container">
              <CategorizedBranchCheckbox
                categoryId={parentCategoryId}
                label={allLabel}
                checked={containIds.every((item) =>
                  checkedItemList.some(
                    (checkedItem) => checkedItem.id === item.id
                  )
                )}
                discreet={!!searchWord}
                containIds={containIds}
                keyActions={{
                  onLeft: handleCheckboxLeft,
                  onRight: handleCheckboxRight,
                  onUp: handleCheckboxUp,
                  onDown: handleCheckboxDown,
                }}
                onChange={handleCheckAll}
                ref={relationMap[allCheckIdFormat(parentCategoryId)]?.ref}
                parentRef={parentRef}
              />
            </div>
          )}
          <div className="tip-checkbox-list">
            {list.map((item) => (
              <React.Fragment key={item.CategorizedItemId}>
                <CategorizedTipCheckbox
                  skillId={item.CategorizedItemId}
                  disabled={disabled}
                  checked={checkedItemList.some(
                    (checkedItem) => checkedItem.id === item.CategorizedItemId
                  )}
                  label={
                    item.displayNameLang
                      ? item.displayNameLang[intl.locale]
                      : ''
                  }
                  searchWord={searchWord}
                  keyActions={{
                    onUp: handleCheckboxUp,
                    onDown: handleCheckboxDown,
                    onLeft: handleCheckboxLeft,
                    onRight: handleCheckboxRight,
                  }}
                  onChange={(id, isChecked) => {
                    handleCheck(id, item.displayNameLang || {}, isChecked);
                  }}
                  ref={relationMap[item.CategorizedItemId]?.ref}
                  parentRef={parentRef}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
  };
  return (
    <div className={`CategorizedCheckList ${disabled ? 'disabled' : ''}`}>
      {!inputOption?.isUnVisibleSearchArea && (
        <div className="search-area">
          <Textbox
            name="search"
            className="search-text"
            type="text"
            columns={['search']}
            labelId="searchWord"
            onChangeState={handleChangeSearchWord}
          />
          <IconButton
            name="search"
            className="search-button"
            iconType="search"
            buttonType="basic"
            onClick={onSearch}
          />
        </div>
      )}
      <div className="link-area">
        {inputOption?.isVisibleOpenAll && (
          <div className="link-button" onClick={handleOpenAll}>
            {GetMessageWithIntl(intl, {
              id: 'openAll',
            })}
          </div>
        )}
        {inputOption?.isVisibleCloseAll && (
          <div className="link-button" onClick={handleCloseAll}>
            {GetMessageWithIntl(intl, {
              id: 'closeAll',
            })}
          </div>
        )}
        {inputOption?.isVisibleOpenChecked && (
          <div className="link-button" onClick={handleOpenChecked}>
            {GetMessageWithIntl(intl, {
              id: 'openChecked',
            })}
          </div>
        )}
      </div>

      <div className="checklist-area">{renderCategory(viewListData)}</div>
    </div>
  );
};
