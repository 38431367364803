import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { OutputDialog } from '~/shared/components/ui/';

export const UISampleOutputDialogs = () => {
  const [supplierbaseResult, setSupplierBaseResult] = useState('');
  const [isSupplierBaseOutputShow, setSupplierBaseOutputShow] = useState(false);
  const [workTaskResult, setWorkTaskResult] = useState('');
  const [isWorkTaskOutputShow, setWorkTaskOutputShow] = useState(false);
  const [formResult, setFormResult] = useState('');
  const [isFormOutputShow, setFormOutputShow] = useState(false);
  const [estimateResult, setEstimateResult] = useState('');
  const [isEstimateOutputShow, setEstimateOutputShow] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">出力ダイアログ</h3>
      <h4>出力ダイアログ：サプライヤーベース</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setSupplierBaseResult('');
                setSupplierBaseOutputShow(true);
              }}
            />
            <OutputDialog
              isOpen={isSupplierBaseOutputShow}
              onDecision={(v) => {
                setSupplierBaseResult(
                  `出力しました。${v.outputInfoType}${v.outputVolume}`
                );
                setSupplierBaseOutputShow(false);
              }}
              onCancel={() => {
                setSupplierBaseResult('キャンセルしました');
                setSupplierBaseOutputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{supplierbaseResult}</pre>
          </div>
        </div>
      </div>

      <h4>出力ダイアログ：指図案件</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setWorkTaskResult('');
                setWorkTaskOutputShow(true);
              }}
            />
            <OutputDialog
              isOpen={isWorkTaskOutputShow}
              type="workTask"
              onDecision={(v) => {
                setWorkTaskResult(
                  `出力しました。${v.outputInfoType}${v.outputVolume}`
                );
                setWorkTaskOutputShow(false);
              }}
              onCancel={() => {
                setWorkTaskResult('キャンセルしました');
                setWorkTaskOutputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{workTaskResult}</pre>
          </div>
        </div>
      </div>

      <h4>出力ダイアログ：入力フォーム</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setFormResult('');
                setFormOutputShow(true);
              }}
            />
            <OutputDialog
              isOpen={isFormOutputShow}
              type="form"
              onDecision={(v) => {
                setWorkTaskResult(
                  `出力しました。${v.outputInfoType}${v.outputVolume}`
                );
                setFormOutputShow(false);
              }}
              onCancel={() => {
                setFormResult('キャンセルしました');
                setFormOutputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{formResult}</pre>
          </div>
        </div>
      </div>

      <h4>出力ダイアログ：見積</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEstimateResult('');
                setEstimateOutputShow(true);
              }}
            />
            <OutputDialog
              isOpen={isEstimateOutputShow}
              type="estimate"
              onDecision={(v) => {
                setEstimateResult(
                  `出力しました。${v.outputInfoType}/${v.outputVolume}`
                );
                setEstimateOutputShow(false);
              }}
              onCancel={() => {
                setEstimateResult('キャンセルしました');
                setEstimateOutputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{estimateResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
