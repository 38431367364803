/*
 GoogleAnalytics関連
 送信項目のデータが空文字や空配列だと項目が削除されて送信されるので注意
 */

import { logEvent } from 'firebase/analytics';

// TODO 送信データの仮インターフェース
interface exportData {
  targetName: string; // 対象コンポーネントや要素を想定
  documentIds?: string[]; // 対象データのIDを想定 リスト表示での複数指定に対応するため配列
}

// イベント名は既存のものに合わせてスネークケース
export type clickEvent = 'button' | 'burger_menu_parent' | 'burger_menu_item';

// GoogleAnalyticsへデータ送信
export function exportGoogleAnalytics(
  eventName: clickEvent, // GoogleAnalyticsで利用されるイベント名
  exportData: exportData
) {
  //GAへ情報送信前処理
  const analytics = window.App.firebaseApps.analytics;

  // GoogleAnalyticsへ送信
  logEvent(analytics, eventName, exportData);
}
