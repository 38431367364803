import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { useRef, useState, useEffect } from 'react';
import { CaptionButton } from '../Button';
import { useIntl } from 'react-intl';
import { Amountbox } from '../Amountbox';
import {
  convertAmountToString,
  convertMonetaryAmount,
  toQuantityString,
  includeInputValidateError,
  toNullableMonetaryAmount,
  toMonetaryAmount,
  convertLongToString,
  toQuantityAmount,
} from '~/shared/utils';
import { divideAmount, totalAmounts } from '~/shared/utils/calculator';
import './EstimateResultDetailRegistrationDialog.css';

const DialogId = 'estimateResultDetailRegistrationDialog';

/**
 * 明細内訳登録ダイアログプロパティ
 */
export interface EstimateResultDetailRegistrationDialogProps {
  isOpen: boolean;
  inputOption: EstimateResultDetailRegistrationDialogPropsInputOption;
  onCancel?: () => void;
  onDecision?: (result: mtechnavi.api.estimation.IEstimateResultDetail) => void;
}
/**
 * 明細内訳登録ダイアログ入力オプション
 */
export interface EstimateResultDetailRegistrationDialogPropsInputOption {
  /** 入力データ */
  data?: mtechnavi.api.estimation.IEstimateResultDetail;
}
/**
 * 明細内訳登録ダイアログ
 */
export const EstimateResultDetailRegistrationDialog = (
  props: EstimateResultDetailRegistrationDialogProps
) => {
  const { isOpen, inputOption, onCancel, onDecision } = props;
  const { data } = inputOption;
  const intl = useIntl();
  const formRef = useRef(null);
  // 見積金額
  const [amount, setAmount] = useState<sharelib.IMonetaryAmount | null>(null);
  // 数量
  const quantity: sharelib.IQuantityAmount | null = toQuantityAmount(
    data?.estimateRequestDetail?.quantity || null
  );
  // 見積単価
  const [unitPrice, setUnitPrice] = useState<sharelib.IMonetaryAmount | null>(
    null
  );
  // 材料費
  const [materialCost, setMaterialCost] =
    useState<sharelib.IMonetaryAmount | null>(null);
  // 加工費
  const [processingCost, setProcessingCost] =
    useState<sharelib.IMonetaryAmount | null>(null);
  // 管理費
  const [administrativeCost, setAdministrativeCost] =
    useState<sharelib.IMonetaryAmount | null>(null);
  // 運送費
  const [freightCost, setFreightCost] =
    useState<sharelib.IMonetaryAmount | null>(null);
  // その他費目金額
  const [otherCost, setOtherCost] = useState<sharelib.IMonetaryAmount | null>(
    null
  );

  const isSomeInputDetailCost =
    !!materialCost ||
    !!processingCost ||
    !!administrativeCost ||
    !!freightCost ||
    !!otherCost;

  const isDisableAmount =
    isSomeInputDetailCost ||
    !!data?.estimateRequestDetail?.requiredMaterialCost ||
    !!data?.estimateRequestDetail?.requiredProcessingCost ||
    !!data?.estimateRequestDetail?.requiredAdministrativeCost ||
    !!data?.estimateRequestDetail?.requiredFreightCost;

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const result: mtechnavi.api.estimation.IEstimateResultDetail = {
      ...data,
      amount: toNullableMonetaryAmount(amount),
      unitPrice: toNullableMonetaryAmount(unitPrice),
      materialCost: toNullableMonetaryAmount(materialCost),
      processingCost: toNullableMonetaryAmount(processingCost),
      administrativeCost: toNullableMonetaryAmount(administrativeCost),
      freightCost: toNullableMonetaryAmount(freightCost),
      otherCost: toNullableMonetaryAmount(otherCost),
    };
    onDecision && onDecision(result);
  };

  const isInputError = () => {
    const inputValidationCheckList = [];
    if (data?.estimateRequestDetail?.requiredMaterialCost) {
      const value = convertAmountToString(materialCost);
      inputValidationCheckList.push({ value, ref: formRef });
    }
    if (data?.estimateRequestDetail?.requiredProcessingCost) {
      const value = convertAmountToString(processingCost);
      inputValidationCheckList.push({ value, ref: formRef });
    }
    if (data?.estimateRequestDetail?.requiredAdministrativeCost) {
      const value = convertAmountToString(administrativeCost);
      inputValidationCheckList.push({ value, ref: formRef });
    }
    if (data?.estimateRequestDetail?.requiredFreightCost) {
      const value = convertAmountToString(freightCost);
      inputValidationCheckList.push({ value, ref: formRef });
    }
    const targetElm = document.querySelector(
      '.EstimateResultDetailRegistrationDialog'
    );
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
  };

  const changeDetailCost = (costs: {
    materialCost: sharelib.IMonetaryAmount | null;
    processingCost: sharelib.IMonetaryAmount | null;
    administrativeCost: sharelib.IMonetaryAmount | null;
    freightCost: sharelib.IMonetaryAmount | null;
    otherCost: sharelib.IMonetaryAmount | null;
  }) => {
    const {
      materialCost,
      processingCost,
      administrativeCost,
      freightCost,
      otherCost,
    } = costs;
    if (
      !materialCost &&
      !processingCost &&
      !administrativeCost &&
      !freightCost &&
      !otherCost
    ) {
      changeAmount(amount);
    } else {
      changeAmount(
        totalAmounts(
          materialCost,
          processingCost,
          administrativeCost,
          freightCost,
          otherCost
        )
      );
    }
  };

  const changeAmount = (newAmount: sharelib.IMonetaryAmount | null) => {
    const unitPrice = divideAmount(newAmount, quantity, 'roundDown');
    setAmount(convertMonetaryAmount(newAmount));
    setUnitPrice(convertMonetaryAmount(unitPrice));
  };

  const formReset = () => {
    setAmount(null);
    setUnitPrice(null);
    setMaterialCost(null);
    setProcessingCost(null);
    setAdministrativeCost(null);
    setFreightCost(null);
    setOtherCost(null);
  };

  useEffect(() => {
    if (!isOpen) {
      formReset();
      return;
    }
    const { data } = props.inputOption;

    setAmount(data?.amount?.amountNum ? toMonetaryAmount(data.amount) : null);
    setUnitPrice(
      data?.unitPrice?.amountNum ? toMonetaryAmount(data?.unitPrice) : null
    );
    setMaterialCost(
      data?.materialCost?.amountNum
        ? toMonetaryAmount(data?.materialCost)
        : null
    );
    setProcessingCost(
      data?.processingCost?.amountNum
        ? toMonetaryAmount(data?.processingCost)
        : null
    );
    setAdministrativeCost(
      data?.administrativeCost?.amountNum
        ? toMonetaryAmount(data?.administrativeCost)
        : null
    );
    setFreightCost(
      data?.freightCost?.amountNum ? toMonetaryAmount(data?.freightCost) : null
    );
    setOtherCost(
      data?.otherCost?.amountNum ? toMonetaryAmount(data?.otherCost) : null
    );

    // isOpen 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const elements = (
    <div className="detail-area" ref={formRef}>
      <h4>{`${GetMessageWithIntl(intl, {
        prefixId: DialogId,
        id: 'detailAutoName',
      })}${convertLongToString(
        data?.estimateRequestDetail?.detailAutoName
      )} : ${data?.estimateRequestDetail?.displayName || ''}`}</h4>
      <p>{`${GetMessageWithIntl(intl, {
        prefixId: DialogId,
        id: 'quantity',
      })} : ${toQuantityString(intl, quantity, true)}`}</p>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            {/* 見積金額 */}
            <Amountbox
              name="amount"
              className="amount"
              value={amount}
              labelId={`${DialogId}.amount`}
              columns={['amount']}
              disabled={isDisableAmount}
              onChangeState={(v) => {
                changeAmount(v);
              }}
              displayOption={{ isCommaFormat: true }}
            />
          </div>
          <div className="w-25">
            {/* 見積単価 */}
            <Amountbox
              name="unitPrice"
              className="unitPrice"
              value={unitPrice}
              labelId={`${DialogId}.unitPrice`}
              disabled={true}
              columns={['unitPrice']}
              displayOption={{ isCommaFormat: true }}
            />
          </div>
        </div>
      </div>
      <div className="bordered-container">
        <h5>
          {GetMessageWithIntl(intl, {
            prefixId: DialogId,
            id: 'detail',
          })}
        </h5>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* 材料費 */}
              <Amountbox
                name="materialCost"
                className="materialCost"
                value={materialCost}
                labelId={`${DialogId}.materialCost`}
                validateOption={{
                  required: !!data?.estimateRequestDetail?.requiredMaterialCost,
                }}
                columns={['materialCost']}
                onChangeState={(v) => {
                  setMaterialCost(convertMonetaryAmount(v));
                  changeDetailCost({
                    materialCost: v,
                    processingCost,
                    administrativeCost,
                    freightCost,
                    otherCost,
                  });
                }}
                displayOption={{ isCommaFormat: true }}
              />
            </div>
            <div className="w-25">
              {/* 加工費 */}
              <Amountbox
                name="processingCost"
                className="processingCost"
                value={processingCost}
                labelId={`${DialogId}.processingCost`}
                validateOption={{
                  required:
                    !!data?.estimateRequestDetail?.requiredProcessingCost,
                }}
                columns={['processingCost']}
                onChangeState={(v) => {
                  setProcessingCost(convertMonetaryAmount(v));
                  changeDetailCost({
                    materialCost,
                    processingCost: v,
                    administrativeCost,
                    freightCost,
                    otherCost,
                  });
                }}
                displayOption={{ isCommaFormat: true }}
              />
            </div>
            <div className="w-25">
              {/* 管理費 */}
              <Amountbox
                name="administrativeCost"
                className="administrativeCost"
                value={administrativeCost}
                labelId={`${DialogId}.administrativeCost`}
                validateOption={{
                  required:
                    !!data?.estimateRequestDetail?.requiredAdministrativeCost,
                }}
                columns={['administrativeCost']}
                onChangeState={(v) => {
                  setAdministrativeCost(convertMonetaryAmount(v));
                  changeDetailCost({
                    materialCost,
                    processingCost,
                    administrativeCost: v,
                    freightCost,
                    otherCost,
                  });
                }}
                displayOption={{ isCommaFormat: true }}
              />
            </div>
            <div className="w-25">
              {/* 運送費 */}
              <Amountbox
                name="freightCost"
                className="freightCost"
                value={freightCost}
                labelId={`${DialogId}.freightCost`}
                validateOption={{
                  required: !!data?.estimateRequestDetail?.requiredFreightCost,
                }}
                columns={['freightCost']}
                onChangeState={(v) => {
                  setFreightCost(convertMonetaryAmount(v));
                  changeDetailCost({
                    materialCost,
                    processingCost,
                    administrativeCost,
                    freightCost: v,
                    otherCost,
                  });
                }}
                displayOption={{ isCommaFormat: true }}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* その他費目金額 */}
              <Amountbox
                name="otherCost"
                className="otherCost"
                value={otherCost}
                labelId={`${DialogId}.otherCost`}
                columns={['otherCost']}
                onChangeState={(v) => {
                  setOtherCost(convertMonetaryAmount(v));
                  changeDetailCost({
                    materialCost,
                    processingCost,
                    administrativeCost,
                    freightCost,
                    otherCost: v,
                  });
                }}
                displayOption={{ isCommaFormat: true }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'estimateResultDetailRegistration',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="EstimateResultDetailRegistrationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
