import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ApprovalRequestDialog,
  Checkbox,
  ConfirmationDialog,
  DateSuggest,
  FileUploadDialog,
  FileUploadDialogResult,
  Filterbox,
  FilterboxItem,
  FilterboxItemOption,
  OutputOption,
  SimpleListView,
  PageNavigation,
  Textarea,
  Textbox,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  AttachmentItems,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListStaffs,
  FullMethodName_ListUserAttributes,
  FullMethodName_ListWorkTaskCatalogs,
  PageState,
  ViewId,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  convertComponentFilterboxItems,
  convertComponentUnitReference,
  convertComponentUnitReferenceToFilterboxItem,
  convertDate,
  convertDatetime,
  convertDisplayUserNameEmail,
  convertStaffReference,
  convertStaffReferenceToFilterboxItem,
  convertUserReference,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
  handleCommonFIleUpload,
  includeInputValidateError,
} from '~/shared/utils';
import urlList from '~/shared/menu/path.json';
import { FilterExpression } from '~/worker';
import { validateFreeDate } from '~/shared/components/parts/validator';
import './WtWorkTaskInput.css';

const VIEW_ID: ViewId = 'WT_WORKTASK_INPUT';
const AttachFileCountLimit = 10;
export const WtWorkTaskInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);
  const [actionType, setActionType] = useState(
    sourcePageInfo.current.actionType || 'add'
  );
  const intl = useIntl();
  const navi = useNavigate();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const backPageUrl = useMemo(
    () =>
      urlList.find((v) => v.viewId === sourcePageInfo.current.sourceViewId)
        ?.path ?? '/',
    [sourcePageInfo]
  );

  // ラベル
  const labelTemporarySave = GetMessageWithIntl(intl, {
    id: 'temporarySave',
  });
  const labelTaskActivate = GetMessageWithIntl(intl, {
    id: 'activate',
    prefixId: VIEW_ID,
  });
  const labelApprovalRequest = GetMessageWithIntl(intl, {
    id: 'approvalRequest',
    prefixId: VIEW_ID,
  });
  const labelAttachFile = GetMessageWithIntl(intl, {
    id: 'attachFile',
    prefixId: VIEW_ID,
  });

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  const viewMessageTemporarySave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'temporarySave' }),
    },
  };
  const viewMessageTaskActivate: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        id: 'activate',
        prefixId: VIEW_ID,
      }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'confirmationDialogMessageDelete',
    prefixId: VIEW_ID,
  };
  const viewMessageDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: VIEW_ID,
  };
  const viewMessageAttachFileLimit = GetMessageWithIntl(intl, {
    id: 'E0000077',
    value: { $1: AttachFileCountLimit },
  });

  //フィルターボックスType
  const catalogItemType = {
    value: 'workTaskCatalogId',
    displayName: 'displayName',
  };
  const organizationUnitsItemType = {
    value: 'componentUnitId',
    displayName: 'displayNameLang',
  };
  const staffItemType = {
    value: 'staffId',
    displayName: 'displayName',
  };
  const userItemType = {
    value: 'user.email',
    displayName: 'user.displayName',
  };

  const [isLoading, setLoading] = useState(false);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // アコーディオン
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    request: true,
    businessUnit: true,
    reference: true,
  });
  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // マスタデータ
  const [catalogList, setCatalogList] =
    useState<mtechnavi.api.worktask.ListWorkTaskCatalogResponse>();
  const [staffList, setStaffList] =
    useState<mtechnavi.api.company.ListStaffsResponse>();
  const [componentUnitList, setComponentUnitList] =
    useState<mtechnavi.api.company.ListComponentUnitsResponse>();
  const [userAttributeList, setUserAttributeList] =
    useState<mtechnavi.api.tenantadmin.ListUserAttributesResponse>();

  // 編集元データ格納用
  const workTaskData = useRef<mtechnavi.api.worktask.IWorkTask>();
  // 項目セット用
  const [workTask, setWorkTask] = useState<mtechnavi.api.worktask.IWorkTask>(
    {}
  );
  const [isSuspendCatalogChange, setSuspendCatalogChange] = useState(false);
  // 画面項目
  const [workTaskCatalogItem, setWorkTaskCatalogItem] = useState<
    FilterboxItem[]
  >([]);
  const [workTaskCatalog, setWorkTaskCatalog] =
    useState<mtechnavi.api.worktask.IWorkTaskCatalog>();
  const [managementOrganizationItem, setManagementOrganizationItem] = useState<
    FilterboxItem[]
  >([]);
  const [managementStaffItem, setManagementStaffItem] = useState<
    FilterboxItem[]
  >([]);
  const [managementStaffUser, setManagementStaffUser] =
    useState<sharelib.IUserReference>();
  const [displayName, setDisplayName] = useState('');
  const [worktaskDueDateDt, setWorktaskDueDateDt] = useState<Date | null>();
  const [workOrganizationItem, setWorkOrganizationItem] = useState<
    FilterboxItem[]
  >([]);
  const [workStaffItem, setWorkStaffItem] = useState<FilterboxItem[]>([]);
  const [workStaffUser, setWorkStaffUser] = useState<sharelib.IUserReference>();
  const [worktaskTicketDueDateDt, setWorktaskTicketDueDateDt] =
    useState<Date | null>();
  const [asapCheck, setAsapCheck] = useState<string[]>([]);
  const [asapComment, setAsapComment] = useState('');
  const [contents, setContents] = useState('');
  const [approval, setApproval] = useState(false);
  const [systemNotificationUsersItem, setSystemNotificationUsersItem] =
    useState<FilterboxItem[]>([]);
  const [requestRemarks, setRequestRemarks] = useState('');
  const [requestAttachments, setRequestAttachments] = useState<
    AttachmentItems[]
  >([]);

  // フィルタボックス
  const orgSearchOption = useMemo(
    () => ({
      targets: 'displayName' as FilterboxItemOption,
      customQuery: { 'organizationUnit.systemName': { $eq: 'B02' } },
    }),
    []
  );
  const [managementStaffCustomQuery, setManagementStaffCustomQuery] =
    useState<FilterExpression>({});
  const [workStaffCustomQuery, setWorkStaffCustomQuery] =
    useState<FilterExpression>({});

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログInfo
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });
  // 承認依頼ダイアログ
  const [isOpenApprovalRequestDialog, setOpenApprovalRequestDialog] =
    useState(false);
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);

  // 入力チェック
  const requireRequest = useRef(null);
  const [workingBlurWorktaskCatalog, setWorkingBlurWorktaskCatalog] =
    useState<Date>();
  const [
    workingBlurManagementOrganizationUnit,
    setWorkingBlurManagementOrganizationUnit,
  ] = useState<Date>();
  const [workingBlurWorkOrganizationUnit, setWorkingBlurWorkOrganizationUnit] =
    useState<Date>();
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    setWorkingBlurWorktaskCatalog(new Date());
    setWorkingBlurManagementOrganizationUnit(new Date());
    setWorkingBlurWorkOrganizationUnit(new Date());
    return includeInputValidateError(document, intl, [
      {
        value:
          workTaskCatalogItem.length > 0 ? workTaskCatalogItem[0].value : '',
        ref: formAreaRef,
      },
      {
        value:
          managementOrganizationItem.length > 0
            ? managementOrganizationItem[0].value
            : '',
        ref: formAreaRef,
      },
      {
        value:
          workOrganizationItem.length > 0 ? workOrganizationItem[0].value : '',
        ref: formAreaRef,
      },
      {
        value: displayName ?? '',
        ref: formAreaRef,
      },
    ]);
  };

  const handleConfirmTempSave = () => {
    if (isInputError()) {
      return;
    }
    setConfirmDialogInfo({
      viewMessage: viewMessageTemporarySave,
      onDecision: handleTempSave,
    });
    setOpenConfirmDialog(true);
  };

  const handleTempSave = async () => {
    setLoading(true);
    try {
      const workTask = await saveWorkTask();
      success([successMessage]);
      setOpenConfirmDialog(false);
      // 編集モードへ移行
      changeEditMode(workTask[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      setLoading(false);
    }
    setLoading(false);
  };

  const saveWorkTask = async () => {
    const managementOrganization = await filterboxItemToOrg(
      managementOrganizationItem
    );
    const workOrganization = await filterboxItemToOrg(workOrganizationItem);
    const managementStaff = await filterboxItemToStaff(managementStaffItem);
    const workStaff = await filterboxItemToStaff(workStaffItem);

    const systemNotificationUsers: sharelib.IUserReference[] = [];
    managementStaffUser && systemNotificationUsers.push(managementStaffUser);
    if (workStaffUser && workStaffUser.email !== managementStaffUser?.email) {
      systemNotificationUsers.push(workStaffUser);
    }
    const otherNoticeUsers = await filterboxItemToUser(
      systemNotificationUsersItem
    );
    otherNoticeUsers.forEach((user) => {
      if (
        user &&
        user.email !== managementStaffUser?.email &&
        user.email !== workStaffUser?.email
      ) {
        systemNotificationUsers.push(user);
      }
    });

    const request: mtechnavi.api.worktask.IWorkTask = {
      systemNotificationUsers,
      displayName,
      asap: asapCheck.length > 0,
      workTaskCatalog,
      contents,
      approval,
      managementOrganization,
      managementStaff,
      workOrganization,
      workStaff,
      requestRemarks,
      requestAttachments,
    };
    if (request.asap) {
      request.asapComment = asapComment;
    }
    if (worktaskDueDateDt) {
      request.worktaskDueDateDt = convertDatetime(
        worktaskDueDateDt,
        'YYYY/MM/DD'
      );
    }
    if (worktaskTicketDueDateDt) {
      request.worktaskTicketDueDateDt = convertDatetime(
        worktaskTicketDueDateDt,
        'YYYY/MM/DD'
      );
    }

    if (workTask.workTaskId) {
      request.workTaskId = workTask.workTaskId;
    }
    if (workTask.updatedAt) {
      request.updatedAt = workTask.updatedAt;
    }

    return window.App.services.ui.worker.apiCall({
      actionName: 'saveWorkTask',
      request,
    }) as Promise<mtechnavi.api.worktask.IWorkTask[]>;
  };

  const filterboxItemToOrg = async (items: FilterboxItem[]) => {
    return (
      (await convertComponentUnitReference(
        items[0]?.value || '',
        items[0]?.displayName || ''
      )) || {}
    );
  };

  const filterboxItemToStaff = async (items: FilterboxItem[]) => {
    return (
      (await convertStaffReference(
        items[0]?.value || '',
        items[0]?.displayName || ''
      )) || {}
    );
  };

  const filterboxItemToUser = async (items: FilterboxItem[]) => {
    return await Promise.all(
      items.map((item) => convertUserReference(item?.value || ''))
    );
  };

  const handleConfirmApprovalRequest = () => {
    if (isInputError()) {
      return;
    }
    setOpenApprovalRequestDialog(true);
  };

  const handleApprovalRequest = async (requestInfo: OutputOption) => {
    setLoading(true);
    setOpenApprovalRequestDialog(false);
    try {
      const workTask = await saveWorkTask();
      await approvalRequestWorkTask(workTask[0], requestInfo);
      success([successMessage]);
      setOpenConfirmDialog(false);
      // 編集モードへ移行
      changeEditMode(workTask[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      setLoading(false);
    }
    setLoading(false);
    backToBaseViewPage();
  };

  const approvalRequestWorkTask = async (
    wt: mtechnavi.api.worktask.IWorkTask,
    requestInfo: OutputOption
  ) => {
    // 選択値を担当者情報に変換
    const selectedStaff =
      (await convertStaffReference(
        requestInfo?.approvalOrganizationStaff[0]?.value || '',
        requestInfo?.approvalOrganizationStaff[0]?.displayName || ''
      )) || {};

    // 選択値を管理場所情報に変換
    const selectedComponentUnit =
      (await convertComponentUnitReference(
        requestInfo?.approvalOrganizationUnit[0]?.value || '',
        requestInfo?.approvalOrganizationUnit[0]?.displayName || ''
      )) || {};

    return window.App.services.ui.worker.apiCall({
      actionName: 'approvalRequestWorkTask',
      request: {
        workTaskId: wt.workTaskId,
        approvalPlanOrganization: selectedComponentUnit,
        approvalPlanStaff: selectedStaff,
        comment: requestInfo.comment,
      },
    });
  };

  const handleConfirmActivateWorkTask = () => {
    if (isInputError()) {
      return;
    }
    setConfirmDialogInfo({
      viewMessage: viewMessageTaskActivate,
      onDecision: handleActivateWorkTask,
    });
    setOpenConfirmDialog(true);
  };

  const handleActivateWorkTask = async () => {
    setLoading(true);
    try {
      const workTask = await saveWorkTask();
      await activateWorkTask(workTask[0]);
      success([successMessage]);
      setOpenConfirmDialog(false);
      // 編集モードへ移行
      changeEditMode(workTask[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      setLoading(false);
    }
    setLoading(false);
    backToBaseViewPage();
  };

  const activateWorkTask = async (wt: mtechnavi.api.worktask.IWorkTask) => {
    return window.App.services.ui.worker.apiCall({
      actionName: 'activateWorkTask',
      request: {
        workTaskId: wt.workTaskId,
      },
    });
  };

  const changeEditMode = (wt: mtechnavi.api.worktask.IWorkTask) => {
    workTaskData.current = wt;
    setSuspendCatalogChange(true);
    setWorkTask(wt);
    setActionType('edit');
  };

  // 添付ファイル追加
  const handleAttachFile = () => {
    if (
      requestAttachments &&
      requestAttachments.length >= AttachFileCountLimit
    ) {
      error([viewMessageAttachFileLimit]);
      return;
    }
    setOpenFileUploadDialog(true);
  };

  const uploadFiles = async (result: FileUploadDialogResult) => {
    const files = await handleCommonFIleUpload([], result, 'B06', intl);
    if (requestAttachments.length + files.length > AttachFileCountLimit) {
      error([viewMessageAttachFileLimit]);
      return;
    }
    setRequestAttachments([...requestAttachments, ...files]);
    setOpenFileUploadDialog(false);
  };

  // 添付ファイル削除
  const handleDeleteAttachFile = (item: AttachmentItems) => {
    setOpenConfirmDialog(true);
    setConfirmDialogInfo({
      viewMessage: viewMessageDelete,
      onDecision: () => {
        setRequestAttachments(
          requestAttachments?.filter((v) => v.assetId !== item.assetId)
        );
        setOpenConfirmDialog(false);
      },
    });
  };
  // 添付ファイル全削除
  const handleDeleteAllAttachFile = () => {
    setConfirmDialogInfo({
      viewMessage: viewMessageDeleteAll,
      onDecision: () => {
        setRequestAttachments([]);
        setOpenConfirmDialog(false);
      },
    });
    setOpenConfirmDialog(true);
  };
  // 添付ファイルダウンロード
  const handleDownload = (item: AttachmentItems) => {
    autoDownloadFileOnlyName(item.filename ?? '', item.assetId ?? '');
  };
  // 添付ファイル全ダウンロード
  const handleFullDownload = () => {
    autoBulkDownload(
      (requestAttachments ?? []).map((item) => item.assetId ?? ''),
      intl,
      VIEW_ID
    );
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 一覧に戻る
  const backToBaseViewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
    };

    // 戻り先に遷移元(一覧)情報をセット
    //  遷移先不明の場合は依頼管理一覧に戻る
    const backPath =
      urlList.find(
        (v) => v.viewId === sourcePageInfo.current.baseViewOption?.sourceViewId
      )?.path ?? '/work-task/wk-worktask-list';

    navi(backPath, { state });
  };

  // 保持したデータから Staff を取得
  const getStaffById = useCallback(
    async (
      staffId: string | undefined
    ): Promise<mtechnavi.api.company.IStaff | null> => {
      return staffList?.items.find((item) => item.staffId === staffId) || null;
    },
    [staffList]
  );

  // 保持したデータから ComponentUnit を取得
  const getComponentUnitById = useCallback(
    async (
      componentUnitId: string | undefined
    ): Promise<mtechnavi.api.company.IStaff | null> => {
      return (
        componentUnitList?.items.find(
          (item) => item.componentUnitId === componentUnitId
        ) || null
      );
    },
    [componentUnitList]
  );

  // 保持したデータから UserAttribute を取得
  const getUserAttributeByEmail = useCallback(
    async (
      email: string | undefined
    ): Promise<mtechnavi.api.tenantadmin.IUserAttribute | null> => {
      return (
        userAttributeList?.items.find((item) => item?.user?.email === email) ||
        null
      );
    },
    [userAttributeList]
  );

  // 保持したデータから UserReference を取得
  const getUserReferenceByEmail = useCallback(
    async (
      email: string | undefined
    ): Promise<sharelib.IUserReference | null> => {
      const attr = await getUserAttributeByEmail(email);
      return attr
        ? {
            userId: attr!.userAttributeId,
            email: attr!.user!.email,
            displayName: attr!.user!.displayName,
          }
        : null;
    },
    [getUserAttributeByEmail]
  );

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // プリセット取得
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      })();

      (async () => {
        const catalogList = (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListWorkTaskCatalogs,
          filter: {},
          sort: [],
        })) as mtechnavi.api.worktask.ListWorkTaskCatalogResponse;
        setCatalogList(catalogList);

        const staffList = (await window.App.services.ui.worker.filter({
          action: 'query',
          fullMethodName: FullMethodName_ListStaffs,
          filter: {},
          sort: [],
        })) as mtechnavi.api.company.ListStaffsResponse;
        setStaffList(staffList);

        const componentUnitList = (await window.App.services.ui.worker.filter({
          action: 'query',
          fullMethodName: FullMethodName_ListComponentUnits,
          filter: {},
          sort: [],
        })) as mtechnavi.api.company.ListComponentUnitsResponse;
        setComponentUnitList(componentUnitList);

        const userAttributeList = (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListUserAttributes,
          filter: {},
          sort: [],
        })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;
        setUserAttributeList(userAttributeList);

        // カタログ指定での登録モード
        if (
          actionType === 'add' &&
          sourcePageInfo.current.sourceViewId === 'WT_REQUEST_CATALOG_LIST'
        ) {
          const catalog: mtechnavi.api.worktask.IWorkTaskCatalog[] = (
            catalogList.items ?? []
          ).filter((v) =>
            (sourcePageInfo.current.ids || []).length > 0
              ? v.workTaskCatalogId === sourcePageInfo.current.ids![0] ?? ''
              : ''
          );
          setWorkTaskCatalogItem(
            catalog.length > 0
              ? [
                  {
                    value: catalog[0].workTaskCatalogId ?? '',
                    displayName: catalog[0].displayName ?? '',
                  },
                ]
              : []
          );
        }

        // 編集モード
        if (actionType === 'edit') {
          setSuspendCatalogChange(true);
          if (!workTaskData.current) {
            const workTask =
              await window.App.services.workTaskService.getWorkTask({
                workTaskId:
                  (sourcePageInfo.current.ids || []).length > 0
                    ? sourcePageInfo.current.ids![0] ?? ''
                    : '',
              });
            workTaskData.current = workTask;
            setWorkTask(workTaskData.current);
          }
        }

        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }
  }, [intl, actionType]);

  // 画面データ変更時
  useEffect(() => {
    if (!workTask) {
      return;
    }
    (async () => {
      setWorkTaskCatalogItem(
        workTask.workTaskCatalog
          ? [
              {
                value: workTask.workTaskCatalog.workTaskCatalogId || '',
                displayName: workTask.workTaskCatalog.displayName || '',
              },
            ]
          : []
      );
      setWorkTaskCatalog(workTask.workTaskCatalog || undefined);
      setManagementOrganizationItem([
        convertComponentUnitReferenceToFilterboxItem(
          workTask.managementOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setManagementStaffItem([
        convertStaffReferenceToFilterboxItem(workTask.managementStaff) ?? {
          value: '',
          displayName: '',
        },
      ]);
      setDisplayName(workTask.displayName || '');
      setWorktaskDueDateDt(convertDate(workTask.worktaskDueDateDt || null));
      setWorkOrganizationItem([
        convertComponentUnitReferenceToFilterboxItem(
          workTask.workOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setWorkStaffItem([
        convertStaffReferenceToFilterboxItem(workTask.workStaff) ?? {
          value: '',
          displayName: '',
        },
      ]);
      setWorktaskTicketDueDateDt(
        convertDate(workTask.worktaskTicketDueDateDt || null)
      );
      setAsapCheck(workTask.asap ? ['1'] : []);
      setAsapComment(workTask.asapComment || '');
      setContents(workTask.contents ?? '');
      setApproval(!!workTask.approval);

      const managementStaff = await getStaffById(
        workTask.managementStaff?.staffId || ''
      );
      const workStaff = await getStaffById(workTask.workStaff?.staffId || '');
      const otherUsers =
        workTask.systemNotificationUsers?.filter(
          (user) =>
            user.email !== managementStaff?.email &&
            user.email !== workStaff?.email
        ) || [];
      setManagementStaffUser(
        workTask.systemNotificationUsers?.find(
          (user) => user.email === managementStaff?.email
        )
      );
      setWorkStaffUser(
        workTask.systemNotificationUsers?.find(
          (user) => user.email === workStaff?.email
        )
      );
      setSystemNotificationUsersItem(
        otherUsers.map((user) => ({
          displayName: user.displayName || '',
          value: user.email || '',
        }))
      );
      setRequestRemarks(workTask.requestRemarks || '');
      setRequestAttachments(
        (workTask.requestAttachments ?? []) as AttachmentItems[]
      );
    })();
  }, [workTask, getStaffById]);

  // カタログ変更時
  useEffect(() => {
    if (workTaskCatalogItem.length <= 0 || isSuspendCatalogChange) {
      return;
    }
    (async () => {
      const catalog = catalogList?.items.find(
        (item) => item.workTaskCatalogId === workTaskCatalogItem[0].value
      );
      if (!catalog) {
        setWorkTaskCatalog(undefined);
        return;
      }
      setWorkTaskCatalog(catalog);

      setManagementOrganizationItem([
        convertComponentUnitReferenceToFilterboxItem(
          catalog.managementOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setWorkOrganizationItem([
        convertComponentUnitReferenceToFilterboxItem(
          catalog.workOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setContents(catalog.contents ?? '');
      setApproval(!!catalog.approval);
      setRequestAttachments(
        (catalog.commonAttachments ?? []) as AttachmentItems[]
      );

      // 値のセット直後にblurしても値が評価されないため、後からblurをかける
      setTimeout(() => {
        setWorkingBlurManagementOrganizationUnit(new Date());
        setWorkingBlurWorkOrganizationUnit(new Date());
      }, 100);
    })();
  }, [workTaskCatalogItem, isSuspendCatalogChange, catalogList?.items]);

  // 管理部門の選択時
  useEffect(() => {
    const query: FilterExpression = managementOrganizationItem.length
      ? { componentUnitId: { $eq: managementOrganizationItem[0].value ?? '' } }
      : {};
    setManagementStaffCustomQuery(query);
  }, [managementOrganizationItem]);

  // 作業部門の選択時
  useEffect(() => {
    const query: FilterExpression = workOrganizationItem.length
      ? { componentUnitId: { $eq: workOrganizationItem[0].value ?? '' } }
      : {};
    setWorkStaffCustomQuery(query);
  }, [workOrganizationItem]);

  useEffect(() => {
    // 管理部門に所属していない担当をクリア
    if (
      managementStaffItem.length === 0 ||
      managementOrganizationItem.length === 0
    ) {
      return;
    }
    (async () => {
      const staff = await getStaffById(managementStaffItem[0]?.value);
      if (
        staff?.componentUnitId !== (managementOrganizationItem[0].value ?? '')
      ) {
        setManagementStaffItem([]);
      }
    })();
  }, [
    managementStaffCustomQuery,
    managementOrganizationItem,
    managementStaffItem,
    getStaffById,
  ]);

  useEffect(() => {
    // 作業部門に所属していない担当をクリア
    if (workStaffItem.length === 0 || workOrganizationItem.length === 0) {
      return;
    }
    (async () => {
      const staff = await getStaffById(workStaffItem[0]?.value);
      if (staff?.componentUnitId !== (workOrganizationItem[0].value ?? '')) {
        setWorkStaffItem([]);
      }
    })();
  }, [workStaffCustomQuery, workOrganizationItem, workStaffItem, getStaffById]);

  // 管理担当の選択時
  useEffect(() => {
    (async () => {
      if (managementStaffItem.length === 0) {
        setManagementStaffUser(undefined);
        return;
      }
      const staff = await getStaffById(managementStaffItem[0]?.value);
      if (!staff) {
        setManagementStaffUser(undefined);
        return;
      }
      const user = await getUserReferenceByEmail(staff?.email || '');
      setManagementStaffUser(user || undefined);
      const org = await getComponentUnitById(staff?.componentUnitId || '');
      if (org) {
        setManagementOrganizationItem(convertComponentFilterboxItems([org]));
      }
      // 値のセット直後にblurしても値が評価されないため、後からblurをかける
      setTimeout(() => {
        setWorkingBlurManagementOrganizationUnit(new Date());
      }, 100);
    })();
  }, [
    managementStaffItem,
    getComponentUnitById,
    getStaffById,
    getUserReferenceByEmail,
  ]);

  // 作業担当の選択時
  useEffect(() => {
    (async () => {
      if (workStaffItem.length === 0) {
        setWorkStaffUser(undefined);
        return;
      }
      const staff = await getStaffById(workStaffItem[0]?.value);
      if (!staff) {
        setWorkStaffUser(undefined);
        return;
      }
      const user = await getUserReferenceByEmail(staff?.email || '');
      setWorkStaffUser(user || undefined);
      const org = await getComponentUnitById(staff?.componentUnitId || '');
      if (org) {
        setWorkOrganizationItem(convertComponentFilterboxItems([org]));
      }
      // 値のセット直後にblurしても値が評価されないため、後からblurをかける
      setTimeout(() => {
        setWorkingBlurWorkOrganizationUnit(new Date());
      }, 100);
    })();
  }, [
    workStaffItem,
    getComponentUnitById,
    getStaffById,
    getUserReferenceByEmail,
  ]);

  // ASAP
  useEffect(() => {
    if (asapCheck.length > 0) {
      setWorktaskTicketDueDateDt(null);
    } else {
      setAsapComment('');
    }
  }, [asapCheck]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="WtWorkTaskInput">
          <div className="header">
            <PageNavigation
              backpagePath="/work-task/wk-worktask-list"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setConfirmDialogInfo({
                  viewMessage: viewMessageCancel,
                  onDecision: backToPreviewPage,
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div
              className={`input-blocktitle-outer ${
                accordionState.request ? '' : 'close'
              }`}
              ref={requireRequest}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('request')}
              >
                {GetMessage({ id: 'request', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.request ? '' : 'close'
              }`}
            >
              <div className="input-line worktask-form">
                <div className="item-group-100">
                  <div className="w-25">
                    <Filterbox
                      name="workTaskCatalog"
                      fullMethodName={FullMethodName_ListWorkTaskCatalogs}
                      labelId="WT_WORKTASK_INPUT.workTaskCatalog"
                      columns={preset.columns}
                      itemType={catalogItemType}
                      value={workTaskCatalogItem}
                      validateOption={{ required: true }}
                      onChangeState={setWorkTaskCatalogItem}
                      searchOption={{
                        targets: 'displayName',
                        isLatestData: true,
                      }}
                      workingBlur={workingBlurWorktaskCatalog}
                      disabled={actionType === 'edit'}
                    />
                  </div>
                  <div className="w-25">
                    <Filterbox
                      name="managementOrganization"
                      columns={preset.columns}
                      fullMethodName={FullMethodName_ListComponentUnits}
                      labelId="WT_WORKTASK_INPUT.managementOrganization"
                      validateOption={{ required: true }}
                      workingBlur={workingBlurManagementOrganizationUnit}
                      itemType={organizationUnitsItemType}
                      value={
                        managementOrganizationItem ?? [
                          { value: '', displayName: '' },
                        ]
                      }
                      searchOption={orgSearchOption}
                      formatOption={{ mapKeyValue: 'ja' }}
                      onChangeState={setManagementOrganizationItem}
                    />
                  </div>
                  <div className="w-25">
                    <Filterbox
                      name="managementStaff"
                      columns={preset.columns}
                      fullMethodName={FullMethodName_ListStaffs}
                      labelId="WT_WORKTASK_INPUT.managementStaff"
                      itemType={staffItemType}
                      value={
                        managementStaffItem ?? [{ value: '', displayName: '' }]
                      }
                      searchOption={{
                        targets: 'displayName',
                        customQuery: managementStaffCustomQuery,
                      }}
                      onChangeState={setManagementStaffItem}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <Textbox
                      name="displayName"
                      columns={preset.columns}
                      className="field"
                      value={displayName}
                      type="text"
                      validateOption={{ required: true }}
                      labelId="WT_WORKTASK_INPUT.displayName"
                      onChangeState={setDisplayName}
                    />
                  </div>
                  <div className="w-25">
                    <DateSuggest
                      name="worktaskDueDateDt"
                      columns={preset.columns}
                      labelId="WT_WORKTASK_INPUT.worktaskDueDateDt"
                      validator={validateFreeDate(intl)}
                      onChangeState={setWorktaskDueDateDt}
                      value={worktaskDueDateDt}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <Filterbox
                      name="workOrganizationUnits"
                      fullMethodName={FullMethodName_ListComponentUnits}
                      labelId="WT_WORKTASK_INPUT.workOrganization"
                      columns={['workOrganizationUnits']}
                      validateOption={{ required: true }}
                      workingBlur={workingBlurWorkOrganizationUnit}
                      itemType={organizationUnitsItemType}
                      value={
                        workOrganizationItem ?? [{ value: '', displayName: '' }]
                      }
                      searchOption={orgSearchOption}
                      formatOption={{ mapKeyValue: 'ja' }}
                      onChangeState={setWorkOrganizationItem}
                    />
                  </div>
                  <div className="w-25">
                    <Filterbox
                      name="workStaff"
                      fullMethodName={FullMethodName_ListStaffs}
                      labelId="WT_WORKTASK_INPUT.workStaff"
                      columns={['workStaff']}
                      itemType={staffItemType}
                      value={workStaffItem ?? [{ value: '', displayName: '' }]}
                      searchOption={{
                        targets: 'displayName',
                        customQuery: workStaffCustomQuery,
                      }}
                      onChangeState={setWorkStaffItem}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <DateSuggest
                      name="worktaskTicketDueDateDt"
                      columns={preset.columns}
                      labelId="WT_WORKTASK_INPUT.worktaskTicketDueDateDt"
                      validator={validateFreeDate(intl)}
                      onChangeState={setWorktaskTicketDueDateDt}
                      value={worktaskTicketDueDateDt}
                      disabled={asapCheck.length > 0}
                    />
                  </div>
                  <div className="w-25 line-height-4rem">
                    <Checkbox
                      name="checkbox"
                      className="group"
                      items={[
                        {
                          value: '1',
                          displayName: GetMessage({
                            prefixId: 'WT_WORKTASK_INPUT',
                            id: 'asap',
                          }),
                        },
                      ]}
                      value={asapCheck}
                      onChangeState={setAsapCheck}
                      columns={['checkbox']}
                    />
                  </div>
                  <div className="w-50">
                    <Textbox
                      name="asapComment"
                      columns={preset.columns}
                      className="field"
                      value={asapComment}
                      type="text"
                      labelId="WT_WORKTASK_INPUT.asapComment"
                      onChangeState={setAsapComment}
                      disabled={asapCheck.length <= 0}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-75">
                    <Textarea
                      name="contents"
                      columns={preset.columns}
                      className="w-100 mh-high"
                      labelId="WT_WORKTASK_INPUT.contents"
                      value={contents}
                      onChangeState={setContents}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`input-blocktitle-outer ${
                accordionState.businessUnit ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('businessUnit')}
              >
                {GetMessage({ id: 'businessUnit', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.businessUnit ? '' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <Textbox
                      name="managementStaff"
                      columns={preset.columns}
                      className="field"
                      value={convertDisplayUserNameEmail(
                        managementStaffUser?.displayName,
                        managementStaffUser?.email
                      )}
                      type="text"
                      labelId="WT_WORKTASK_INPUT.managementStaff"
                      disabled={true}
                    />
                  </div>
                  <div className="w-25">
                    <Textbox
                      name="workStaff"
                      columns={preset.columns}
                      className="field"
                      value={convertDisplayUserNameEmail(
                        workStaffUser?.displayName,
                        workStaffUser?.email
                      )}
                      type="text"
                      labelId="WT_WORKTASK_INPUT.workStaff"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <Filterbox
                      name="systemNotificationUsers"
                      columns={preset.columns}
                      fullMethodName={FullMethodName_ListUserAttributes}
                      labelId="WT_WORKTASK_INPUT.systemNotificationUsers"
                      itemType={userItemType}
                      value={
                        systemNotificationUsersItem ?? [
                          { value: '', displayName: '' },
                        ]
                      }
                      multiple={true}
                      searchOption={{
                        targets: 'displayName',
                        isLatestData: true,
                      }}
                      onChangeState={setSystemNotificationUsersItem}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`input-blocktitle-outer ${
                accordionState.reference ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('reference')}
              >
                {GetMessage({ id: 'reference', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.reference ? '' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-75">
                    <Textarea
                      name="requestRemarks"
                      columns={preset.columns}
                      className="w-100 mh-high"
                      labelId="WT_WORKTASK_INPUT.requestRemarks"
                      value={requestRemarks}
                      onChangeState={setRequestRemarks}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line ">
                <div className="item-group-100 file-area">
                  <div className="w-50">
                    <SimpleListView
                      data={requestAttachments}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { id: 'attachmentList', viewId: VIEW_ID },
                            propertyName: 'filename',
                          },
                        ],
                      }}
                      actionOptions={{
                        onDelete: handleDeleteAttachFile,
                        onDeleteAll: handleDeleteAllAttachFile,
                        onRowClick: handleDownload,
                        onFullDownLoad: handleFullDownload,
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      name="attachFileButton"
                      buttonType="basic"
                      caption={labelAttachFile}
                      onClick={handleAttachFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="tempSaveButton"
                  buttonType="basic"
                  caption={labelTemporarySave}
                  onClick={handleConfirmTempSave}
                />
                {approval && (
                  <CaptionButton
                    name="approvalRequestButton"
                    buttonType="basic"
                    caption={labelApprovalRequest}
                    onClick={handleConfirmApprovalRequest}
                  />
                )}
                {!approval && (
                  <CaptionButton
                    name="taskActivateButton"
                    buttonType="basic"
                    caption={labelTaskActivate}
                    onClick={handleConfirmActivateWorkTask}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmDialogInfo.viewMessage}
        onDecision={confirmDialogInfo.onDecision}
        onCancel={() => setOpenConfirmDialog(false)}
      />
      <ApprovalRequestDialog
        isOpen={isOpenApprovalRequestDialog}
        inputOption={{
          FullMethodName_ListComponentUnits: FullMethodName_ListComponentUnits,
          FullMethodName_ListStaffs: FullMethodName_ListStaffs,
          requestReciptId: '',
        }}
        onDecision={handleApprovalRequest}
        onCancel={() => setOpenApprovalRequestDialog(false)}
      />
      <FileUploadDialog
        isOpen={isOpenFileUploadDialog}
        messageOption={{
          headerLabelId: { id: 'file_add', prefixId: 'DIALOG_TITLE' },
        }}
        fileUploadOption={{
          multiple: true,
          validateOption: {
            maxFileSizeInMebis: 50,
            maxFileCount: AttachFileCountLimit,
          },
        }}
        onDecision={uploadFiles}
        onCancel={() => setOpenFileUploadDialog(false)}
        onChangeLoadingState={(isLoading) => setLoading(isLoading)}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
