import Long from 'long';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ConfirmationDialog,
  ListView,
  ViewMenu,
  InitialFilter,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListDisasterSurveys,
  PageState,
  ViewId,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'BCP_DISASTER_SURVEY_LIST';
type DisasterSurvey = mtechnavi.api.bcp.DisasterSurvey;
export const BcpDisasterSurveyList = () => {
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const navi = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isReload, setReload] = useState(false);
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const [dialogMessageProps, setDialogMessageProps] = useState<MessageProps>({
    id: 'C0000001',
  });
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '削除' },
  };
  const completeMessage = {
    id: 'C0000001',
    value: { $1: '調査終了' },
  };
  const selectedId = useRef<string>('');
  const actionType = useRef<string>('');
  const disasterSurveyList = useRef<DisasterSurvey[]>([]);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  useEffect(() => {
    (async () => {
      try {
        const filter: InitialFilter = {
          info: [],
        };
        const date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        const initialDate = Long.fromNumber(date.getTime())
          .multiply(1000)
          .toNumber();
        filter.info.push({
          targetKey: 'occurredAt',
          targetValue: initialDate,
        });
        setInitialFilter(filter);

        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListDisasterSurveys,
          ]);
        // ja変換
        const jaColumn: string[] = [
          'category.displayNameLang',
          'status.displayNameLang',
          'regions.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const dateColumn = ['occurredAt'];
        const dateSch = getDayFormetterDisplaySchema(formatterSch, dateColumn, {
          dayOpts: {
            formatType: 'YYYY/MM/DD',
          },
        });

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(dateSch);
        setPreset(preset);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    })();
    // 初回起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    menuItems.push({
      name: 'noteadd',
      func: () => {
        handleMenuToInput();
      },
    });
    menuItems.push({
      name: 'description',
      func: (v) => {
        handleMenuToConfirmation(v);
      },
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        handleErrorCheckAndOpenModal('delete', v);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    menuItems.push({
      name: 'description',
      func: (v) => {
        handleMenuToConfirmation(v);
      },
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 災害調査完了
    menuItems.push({
      name: 'complete',
      func: (v?: string[]) => handleErrorCheckAndOpenModal('complete', v),
    });
    return menuItems;
  };

  const handleMenuToInput = () => {
    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: [],
      actionType: 'add',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/bcp/bcp-disaster-survey-input', { state });
  };

  const handleMenuToConfirmation = (prop?: string[]) => {
    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/bcp/bcp-disaster-survey-confirmation', { state });
  };

  const handleErrorCheckAndOpenModal = (
    menuAction: string,
    prop?: string[]
  ) => {
    if (!prop || prop.length === 0) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000023',
        }),
      ]);
      return;
    }
    if (prop.length > 1) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000073',
        }),
      ]);
      return;
    }
    const deleteItem = disasterSurveyList.current.find(
      (item) => item.disasterSurveyId === prop[0]
    );
    if (!deleteItem) {
      return;
    }
    // ステータスが見調査以外は削除しない
    if (menuAction === 'delete' && deleteItem.status?.systemName !== 'B00') {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000020',
        }),
      ]);
      return;
    }
    selectedId.current = prop[0];
    menuAction === 'delete'
      ? setDialogMessageProps(deleteMessage)
      : setDialogMessageProps(completeMessage);
    actionType.current = menuAction;
    setMenuModalIsOpen(true);
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  // 実際の削除、終了処理
  const handleAction = async () => {
    setLoading(true);
    setReload(false);
    const deleteRequest: mtechnavi.api.bcp.IDeleteDisasterSurveyRequest = {
      disasterSurveyId: selectedId.current,
    };
    const completeRequest: mtechnavi.api.bcp.ICompleteDisasterSurveyRequest = {
      disasterSurveyId: selectedId.current,
    };
    try {
      await window.App.services.ui.worker.apiCall({
        actionName:
          actionType.current === 'delete'
            ? 'deleteDisasterSurvey'
            : 'completeDisasterSurvey',
        request:
          actionType.current === 'delete' ? deleteRequest : completeRequest,
      });
      success([toastSuccess]);
      clearCheckBox();
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setMenuModalIsOpen(false);
    setLoading(false);
  };

  const onOriginalItem = (items: unknown) => {
    disasterSurveyList.current = items as unknown as DisasterSurvey[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="bcpDisasterSurveyList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'disasterSurveyId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListDisasterSurveys}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
          />
          <ConfirmationDialog
            isOpen={menuModalIsOpen}
            viewMessage={dialogMessageProps}
            onDecision={() => {
              handleAction();
            }}
            onCancel={() => setMenuModalIsOpen(false)}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
