import { useEffect, useState, CSSProperties } from 'react';
import './ZipCode.css';
import { TextBox } from '../Textbox/TextBox';
import { formatZipCode } from '../formatter';

export interface ZipCodeProps {
  name: string;
  className?: string;
  value?: string;
  validator?: (v: string) => string[];
  validateOption?: ZipCodeValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string) => void;
}

export interface ZipCodeValidateOption {
  required?: boolean;
}

// 郵便番号入力を表示
export function ZipCode(props: ZipCodeProps) {
  // Zipのクラス名
  const zipCodeClassName = props.className ?? '';
  const style = props.style ?? {};

  // オプショナルチェック
  const validator =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const changeParentState = props.onChangeState || (() => {});

  // コンポーネントに送るOnchangeState
  // 画面から渡したデータが表示されないケースを解決するための修正
  // const onChangeState = props.onChangeState;
  // const changeParentState = useCallback(
  //   (v: string) => {
  //     onChangeState && onChangeState(v);
  //   },
  //   [onChangeState]
  // );

  // state
  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => {
    // ハイフン区切りでフォーマットした値を連携
    changeParentState(formatZipCode(value));

    // value 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  return (
    <div className="ZipCode">
      <TextBox
        name={props.name}
        type="text"
        className={zipCodeClassName}
        value={value}
        validator={validator}
        formatter={formatZipCode}
        validateOption={{ required: required, maxLength: 8 }}
        disabled={props.disabled}
        onChangeState={setValue}
        style={style}
      ></TextBox>
    </div>
  );
}
