import { useState } from 'react';
import { ReactComponent as DownIcon } from '@material-design-icons/svg/filled/keyboard_arrow_down.svg';
import { DisplayNameLang } from '~/shared/utils/commonType';
import './PopUpTabList.css';
import { useIntl } from 'react-intl';

export interface PopUpListItem {
  displayNameLang?: DisplayNameLang;
  value: string;
}
export interface PopUpListTab {
  label: string;
  categoryId: string;
  items?: PopUpListItem[];
}
export interface SelectedPopUpListItem {
  categoryId: string;
  displayNameLang?: DisplayNameLang;
  value: string;
}

export interface PopUpListProps {
  tabs?: PopUpListTab[];
  onClick?: (item: SelectedPopUpListItem) => void;
}
export const PopUpTabList = ({ tabs, onClick }: PopUpListProps) => {
  const intl = useIntl();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number | null>(null);

  const handleMouseOver = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };
  const handleMouseLeave = () => {
    setSelectedTabIndex(null);
  };
  const handleClick = (categoryId: string, item: PopUpListItem) => {
    if (onClick) {
      onClick({
        ...item,
        categoryId,
      });
    }
  };

  return (
    <div className="PopUpTabList" onMouseLeave={handleMouseLeave}>
      <div className="tab-labels">
        {tabs?.map((tab, index) => (
          <div key={index} className="tab">
            <div className="label" onMouseOver={() => handleMouseOver(index)}>
              {tab.label} <DownIcon />
            </div>
          </div>
        ))}
      </div>
      <div className="tab-contents">
        {tabs?.map((tab, index) => (
          <ul
            key={index}
            className={`item-list ${
              index === selectedTabIndex ? 'show' : 'hide'
            }`}
          >
            {tab.items?.map((item) => (
              <li key={item.value}>
                <a onClick={() => handleClick(tab.categoryId, item)}>
                  {item.displayNameLang && item.displayNameLang[intl.locale]}
                </a>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};
