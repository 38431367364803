import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { SimpleListView } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleSimpleLists = () => {
  const [listData, setListData] = useState(
    Array(8)
      .fill({})
      .map((item, i) => ({
        id: i + 1,
        filename: `テスト${i + 1}.pdf`,
      }))
  );

  const listData2 = Array(7)
    .fill({})
    .map((item, i) => ({
      id: `ID-${i + 1}`,
      name: `テスト${i + 1}`,
      comment: `コメントコメントコメントコメントコメントコメントコメントコメントコメントコメント${
        i + 1
      }`,
      createdAt: dayjs(new Date()).format('YYYY/MM/DD'),
      memo: `表示しない${i + 1}`,
    }));

  const [simpleListViewState, setSimpleListViewState] = useState<string[]>([]);
  const handleCheckBoxChangeState = useCallback((state: string[]) => {
    setSimpleListViewState(state);
  }, []);

  return (
    <div className="categories">
      <h3 id="simple-list">
        シンプルリスト
        <br />
        <small>※ちょっとした配列データを一覧表示するリスト</small>
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <h4>追加・削除</h4>
            <SimpleListView
              data={listData}
              viewOptions={{
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
              }}
              actionOptions={{
                onDelete: (item) => {
                  setListData(listData.filter((v) => v.id !== item.id));
                },
                onDeleteAll: () => {
                  setListData([]);
                },
                onRowClick: () => {
                  console.info('Row Click!');
                },
                onFullDownLoad: () => {},
              }}
            />
            <CaptionButton
              buttonType="basic"
              caption="追加"
              name="add"
              onClick={() => {
                const id = listData.reduce(
                  (prev, item) => (prev > item.id ? prev : item.id),
                  0
                );
                setListData([
                  ...listData,
                  {
                    id: id + 1,
                    filename: `テスト${id + 1}.pdf`,
                  },
                ]);
              }}
            />
          </div>

          <div className="w-25">
            <h4>表示のみ</h4>
            <SimpleListView
              data={listData}
              viewOptions={{
                readonly: true,
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
              }}
              actionOptions={{
                onDelete: (item) => console.info('Delete!', item),
                onDeleteAll: () => console.info('Delete All!'),
                onRowClick: () => {
                  console.info('Row Click!');
                },
                onFullDownLoad: () => console.info('Full Download!'),
              }}
            />
          </div>

          <div className="w-25">
            <h4>高さ固定しない</h4>
            <SimpleListView
              data={listData}
              viewOptions={{
                readonly: true,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
              }}
              actionOptions={{
                onDelete: (item) => console.info('Delete!', item),
                onDeleteAll: () => console.info('Delete All!'),
                onRowClick: () => {
                  console.info('Row Click!');
                },
                onFullDownLoad: () => console.info('Full Download!'),
              }}
            />
          </div>

          <div className="w-25">
            <h4>ヘッダなし</h4>
            <SimpleListView
              data={listData}
              viewOptions={{
                readonly: true,
                omitHeader: true,
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
              }}
              actionOptions={{
                onDelete: (item) => console.info('Delete!', item),
                onDeleteAll: () => console.info('Delete All!'),
                onRowClick: () => {
                  console.info('Row Click!');
                },
                onFullDownLoad: () => console.info('Full Download!'),
              }}
            />
          </div>
        </div>
      </div>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <h4>複数カラム</h4>
            <SimpleListView
              data={listData2}
              viewOptions={{
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'name' },
                    propertyName: 'name',
                    width: '6rem',
                  },
                  {
                    header: { id: 'comment' },
                    propertyName: 'comment',
                  },
                  {
                    header: { id: 'createdAt' },
                    propertyName: 'createdAt',
                    width: '8rem',
                  },
                ],
              }}
              actionOptions={{
                onDelete: (item) => console.info('Delete!', item),
                onDeleteAll: () => console.info('Delete All!'),
                onFullDownLoad: () => console.info('Full Download!'),
              }}
            />
          </div>

          <div className="w-33">
            <h4>複数カラム・ヘッダ一つのみ</h4>
            <SimpleListView
              data={listData2}
              viewOptions={{
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'status' },
                    propertyName: 'name',
                    width: '6rem',
                  },
                  {
                    propertyName: 'comment',
                  },
                  {
                    propertyName: 'createdAt',
                    width: '8rem',
                  },
                ],
              }}
              actionOptions={{
                onDelete: (item) => console.info('Delete!', item),
                onDeleteAll: () => console.info('Delete All!'),
                onFullDownLoad: () => console.info('Full Download!'),
              }}
            />
          </div>

          <div className="w-20">
            <h4>チェックボックスあり</h4>
            <SimpleListView
              data={listData}
              viewOptions={{
                isVisibleCheckBox: true,
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
              }}
              actionOptions={{}}
              onChangeState={handleCheckBoxChangeState}
            />
          </div>
          <div className="w-15" style={{ marginTop: '3rem' }}>
            <pre className="output">
              {JSON.stringify(simpleListViewState, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
