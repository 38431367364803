import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  MessageProps,
} from '../..';
import { RadioButton } from '~/shared/components/ui/Radiobutton';
import { Textbox } from '~/shared/components/ui/Textbox';
import { CaptionButton } from '~/shared/components/ui//Button';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { getDateFormat } from '~/shared/utils/formatter';
import { convertDisplayUserNameEmail } from '~/shared/utils/converter';

const DialogId = 'flagSettingDialog';

interface FlagSettingMessageOption {
  headerLabelId: MessageProps;
}

export interface FlagSettingInputOption {
  flagProperties?: mtechnavi.api.survey.IFlagProperties;
}
export interface FlagSettingDialogProps {
  isOpen: boolean;
  messageOption?: FlagSettingMessageOption;
  inputOption?: FlagSettingInputOption;
  onDecision: (v: mtechnavi.api.survey.IFlagProperties) => void;
  onCancel: () => void;
}

/**
 * フラグダイアログ
 */
export const FlagSettingDialog = (props: FlagSettingDialogProps) => {
  const { isOpen, inputOption, onDecision, onCancel } = props;
  const { flagProperties } = inputOption ?? {};
  const intl = useIntl();

  // 画面入力値
  const [radio, setRadio] = useState('');

  const radioItemValues = ['setFlag', 'unsetFlag'];
  const radioItems = [
    {
      value: radioItemValues[0],
      displayName: GetMessageWithIntl(intl, {
        id: 'radio_setFlag',
        prefixId: DialogId,
      }),
    },
    {
      value: radioItemValues[1],
      displayName: GetMessageWithIntl(intl, {
        id: 'radio_unsetFlag',
        prefixId: DialogId,
      }),
    },
  ];

  const [comment, setComment] = useState<string>('');

  const formReset = () => {
    setComment('');
  };

  useEffect(() => {
    if (!isOpen) {
      formReset();
      return;
    }

    // 初期値セット
    if (flagProperties?.active) {
      setRadio(radioItemValues[0]);
    } else {
      setRadio(radioItemValues[1]);
    }
    setComment(flagProperties?.comment ?? '');

    // ダイアログ起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCancel = () => {
    onCancel();
  };

  const handleDecision = () => {
    const result: mtechnavi.api.survey.IFlagProperties = flagProperties ?? {};
    result.active = radio === radioItemValues[0];
    result.comment = comment;
    onDecision(result);
  };
  const getLatestUpdateInfo = () => {
    const updatedBy = convertDisplayUserNameEmail(
      flagProperties?.updatedProperties?.updatedBy?.displayName,
      flagProperties?.updatedProperties?.updatedBy?.email
    );
    const updatedAt = getDateFormat(
      flagProperties?.updatedProperties?.updatedAt ?? '',
      'YYYY/MM/DD HH:mm'
    );
    if (!updatedBy && !updatedAt) return '';
    const values: string[] = [];
    values.push(
      GetMessageWithIntl(intl, {
        id: 'latestUpdateInfo',
        prefixId: DialogId,
      })
    );
    values.push('：');
    values.push(updatedBy);
    if (!updatedAt) return values.join('');

    values.push(' ');
    values.push(updatedAt);
    return values.join('');
  };

  const elements = (
    <div className="detail-area">
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100 right-side-label">
            <span className="label">{getLatestUpdateInfo()}</span>
          </div>
        </div>
      </div>
      <div className="description-area"></div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <RadioButton
              name="flag"
              className="group"
              columns={['flag']}
              items={radioItems}
              value={radio}
              onChangeState={setRadio}
            ></RadioButton>
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-80">
            <Textbox
              name="comment"
              type="text"
              labelId={`${DialogId}.comment`}
              columns={['comment']}
              value={comment}
              validateOption={{ maxLength: 40 }}
              onChangeState={setComment}
            />
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={onCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'FlagSetting',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="FlagSettingDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
