/* eslint  @typescript-eslint/no-explicit-any: 0 */
import { useEffect, useState, useRef } from 'react';
import { Catalog, mtechnavi, rpcImpl } from '~/shared/libs/clientsdk';
import { useIntl } from 'react-intl';
import './Login.css';
import './TenantCreateRequest.css';
import {
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import { Textbox, Textarea, Checkbox } from '~/shared/components/ui';
import { TenantRequestConfirmDialog } from '~/shared/components/ui/Dialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { getExceptionMessage } from '~/shared/utils';
import { includeInputValidateError } from '~/shared/utils';

// 次フェーズ対応のためコメントアウト2024/04/15
// import * as pdfjs from 'pdfjs-dist';

export const invokeRequest = async (
  fullMethodName: string,
  requestBody?: any
) => {
  const [fullServiceName, methodName] = fullMethodName.split('/', 2);
  const $service = Catalog.lookupService(fullServiceName);
  const $method = $service.methods[methodName];
  const service = $service.create(rpcImpl);
  // XXX: rpcCallの内部実装で利用するため、専用の変数を生やす
  (service as any).__fullServiceName = fullServiceName;
  return await service.rpcCall(
    $method,
    $method.resolvedRequestType!.ctor as any,
    $method.resolvedResponseType!.ctor as any,
    requestBody ?? ({} as any),
    null as any
  );
};

export function TenantCreateRequest() {
  const intl = useIntl();
  const VIEW_ID = 'TENANT_CREATE_REQUEST';

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });

  const [isLoading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [tenantAdminName, setTenantAdminName] = useState<string>('');
  const [tenantAdminEmail, setTenantAdminEmail] = useState<string>('');
  const [terms, setTerms] = useState<string>();
  const [checkedTerms, setCheckedTerms] = useState<string[]>(['']);
  const [isDownloadTerms, setDownloadTerms] = useState<boolean>(false);
  const [isRequested, setRequested] = useState<boolean>(false);
  const [requestVerifiedAt, setRequestVerifiedAt] = useState<string>('');
  const [requestButtonCaption, setRequestButtonCaption] = useState<string>('');
  const [isClickableRequestButton, setClickableRequestButton] =
    useState<boolean>(false);
  const [isConfrimOpen, setConfirmOpen] = useState<boolean>(false);
  const [isNotFound, setNotFound] = useState<boolean>(false);

  const tenantRequestRef =
    useRef<mtechnavi.api.tenantprovision.ITenantRequest>();

  const termsFilePdfName = '/assets/termsofservice/terms.pdf';
  const FullMethodName_GetTenantRequest =
    'mtechnavi.api.tenantprovision.TenantProvisionService/GetTenantRequest';

  const footerRef = useRef<HTMLDivElement>(null);
  const requireArea = useRef(null);
  const [paramTenantId, setParamTenantId] = useState<string>('');

  useEffect(() => {
    const pathName = window.location.pathname;
    const splitPath = pathName.split('/');
    const tenantId = splitPath.length >= 3 ? splitPath[2] : '';
    setParamTenantId(tenantId);
    (async () => {
      //テナント申請IDからCloudRunを呼び出し、必要な情報を取得する
      try {
        const tenantRequestReq = (await invokeRequest(
          FullMethodName_GetTenantRequest,
          {
            tenantRequestId: tenantId,
          }
        )) as unknown as mtechnavi.api.tenantprovision.GetTenantRequestResponse;
        const tenantRequest: mtechnavi.api.tenantprovision.ITenantRequest =
          tenantRequestReq.tenantRequest ?? {};
        tenantRequestRef.current = tenantRequest;
        setNotFound(tenantRequest?.tenantRequestId ? false : true);
        setCompanyName(tenantRequest?.tenant?.displayName ?? '');
        setTenantAdminName(tenantRequest?.tenantAdmin?.displayName ?? '');
        setTenantAdminEmail(tenantRequest?.tenantAdmin?.email ?? '');

        setRequestVerifiedAt(tenantRequest?.requestVerifiedAt ?? '');
        setRequested(tenantRequest?.requestVerifiedAt ? true : false);
        setRequestButtonCaption(
          tenantRequest?.requestVerifiedAt ? '再申請' : '申請'
        );
      } catch (err) {
        console.error(err);
        setNotFound(true);
      }
      // 次フェーズ対応:利用規約から取得する
      // // ローカルファイルから利用規約を取得する
      // const task = await pdfjs.getDocument({
      //   url: termsFilePdfName,
      //   // pdfjsに同梱のcmapファイル群を指定
      //   // Note: pdfjsはApache-2.0ライセンス
      //   cMapPacked: true,
      //   cMapUrl: '/assets/Viewer/cmaps/',
      // });
      // const pdf = await task.promise;
      // const maxPages = pdf.numPages;

      // let pdfText = '';
      // for (let pageNumber = 1; pageNumber <= maxPages; pageNumber++) {
      //   const page = await pdf.getPage(pageNumber);
      //   const content = await page.getTextContent({
      //     disableCombineTextItems: true,
      //     includeMarkedContent: false,
      //   });
      //   const pageText = content.items
      //     .map((item) => ('str' in item ? item.str : ''))
      //     .join('\n');
      //   pdfText += pageText + '\n';
      // }
      // 固定文言で対応
      const pdfText = GetMessageWithIntl(intl, {
        prefixId: 'TENANT_CREATE_REQUEST',
        id: 'termsValue',
      });
      setTerms(pdfText);
      // ダウンロードは非表示
      setDownloadTerms(false);
    })();
  }, [intl]);

  useEffect(() => {
    setClickableRequestButton(checkedTerms.includes('1') ? true : false);
  }, [checkedTerms]);

  const handleDownloadTerms = () => {
    const link = document.createElement('a');
    link.download = '利用規約.pdf';
    link.href = termsFilePdfName;
    link.click();
    link.remove();
  };

  const handleRequest = async () => {
    if (includeInputValidateError(document, intl)) {
      return;
    }
    setConfirmOpen(true);
  };

  const handleRequestDecision = async () => {
    setLoading(true);
    try {
      const verifyFullMethodName =
        'mtechnavi.api.tenantprovision.TenantProvisionService/VerifyTenantRequest';
      tenantRequestRef.current!.tenant!.displayName = companyName;
      tenantRequestRef.current!.tenantAdmin!.displayName = tenantAdminName;
      tenantRequestRef.current!.tenantAdmin!.email = tenantAdminEmail;

      await invokeRequest(verifyFullMethodName, {
        tenantRequest: tenantRequestRef.current,
      });
      success([successMessage]);
      // 再度テナント情報を取得する
      const tenantRequestReq = (await invokeRequest(
        FullMethodName_GetTenantRequest,
        {
          tenantRequestId: paramTenantId,
        }
      )) as unknown as mtechnavi.api.tenantprovision.GetTenantRequestResponse;
      const tenantRequest: mtechnavi.api.tenantprovision.ITenantRequest =
        tenantRequestReq.tenantRequest ?? {};
      tenantRequestRef.current = tenantRequest;

      setRequestVerifiedAt(tenantRequest?.requestVerifiedAt ?? '');
      setRequested(tenantRequest?.requestVerifiedAt ? true : false);
      setRequestButtonCaption(
        tenantRequest?.requestVerifiedAt ? '再申請' : '申請'
      );
      setConfirmOpen(false);
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
  };

  const viewName = GetMessageWithIntl(intl, {
    id: '',
    viewId: VIEW_ID,
    prefixId: 'HEADER_TITLE',
  });
  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const footerElem = document.querySelector('.footer');
    // フッターがある場合
    if (footerElem) {
      // フッターが可変でリサイズされるため、contentクラス要素のフッタ用の余白を動的に調整する
      mainRef.current &&
        (mainRef.current.style.paddingBottom = `${footerElem.clientHeight}px`);
    }
  }, []);

  const NotFoundComponent = () => {
    return (
      <>
        <div>
          <h3> 申請画面の表示に失敗しました</h3>
          <div>
            下記が考えられます。
            <ul>
              <li>既にテナント作成の手続きが完了している</li>
              <li>有効期限切れ（招待メール受信から１ヶ月以上経過している）</li>
            </ul>
            受信メールの再確認をお願いします。
          </div>
        </div>
      </>
    );
  };

  const ReREquestComponent = () => {
    return (
      <div className="">
        申請状況 : 申請済み（申請日時：{requestVerifiedAt}）<br />
        一定時間経過しても申請受付メールが届いていない場合、再申請をお願いします。
      </div>
    );
  };

  // 未ログイン状態で通常画面とHeaderコンポーネントなどを使用すると色々とサービスが作成されていないなど弊害があるのでDOMを自分で作成
  return (
    <>
      <div id="back-ground-color-theme"></div>
      <article className="container">
        <header className="Header">
          <div>
            <div className="nav-btn"></div>
          </div>
          <div className="symbol">M-Tech Navi{` ${viewName}`}</div>
          <div className="header-btns"></div>
        </header>

        <div className="content">
          <div className="main" ref={mainRef}>
            <div className="TenantCreateRequest" ref={requireArea}>
              {isNotFound && NotFoundComponent()}
              {!isNotFound && (
                <>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <Textbox
                          name="companyName"
                          className="field"
                          value={companyName}
                          type="text"
                          labelId="TENANT_CREATE_REQUEST.companyName"
                          columns={['companyName']}
                          validateOption={{ required: true }}
                          onChangeState={setCompanyName}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-25">
                        <Textbox
                          name="tenantAdminName"
                          className="field"
                          value={tenantAdminName}
                          type="text"
                          labelId="TENANT_CREATE_REQUEST.tenantAdminName"
                          columns={['tenantAdminName']}
                          validateOption={{ required: true }}
                          onChangeState={setTenantAdminName}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <Textbox
                          name="tenantAdminEmail"
                          className="field"
                          value={tenantAdminEmail}
                          type="text"
                          labelId="TENANT_CREATE_REQUEST.tenantAdminEmail"
                          columns={['tenantAdminEmail']}
                          validateOption={{ required: true }}
                          onChangeState={setTenantAdminEmail}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-75">
                        <Textarea
                          name="terms"
                          className="w-100 mh-high"
                          value={terms}
                          labelId="TENANT_CREATE_REQUEST.terms"
                          columns={['terms']}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-25">
                        <Checkbox
                          name="termCheck"
                          className="group"
                          items={[
                            {
                              value: '1',
                              displayName: GetMessageWithIntl(intl, {
                                prefixId: 'TENANT_CREATE_REQUEST',
                                id: 'termsCheckbox',
                              }),
                            },
                          ]}
                          value={checkedTerms}
                          columns={['termCheck']}
                          onChangeState={setCheckedTerms}
                        />
                      </div>
                      <div className="w-50">
                        {isDownloadTerms && (
                          <CaptionButton
                            className="auto-reminder-setting-button"
                            name=""
                            caption="ダウンロード"
                            onClick={() => handleDownloadTerms()}
                            buttonType="basic"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="footer"
                    style={{ width: '100vw' }}
                    ref={footerRef}
                  >
                    <div
                      className="footer-contents"
                      style={{
                        width: '95vw',
                        paddingLeft: '5vw',
                      }}
                    >
                      <div className="input-line">
                        <div className="item-group-100">
                          <div className="w-12">
                            <CaptionButton
                              className="button-margin"
                              name=""
                              caption={requestButtonCaption}
                              onClick={() => {
                                handleRequest();
                              }}
                              buttonType="basic"
                              disabled={!isClickableRequestButton}
                            />
                          </div>
                          <div className="w-75">
                            {isRequested && ReREquestComponent()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TenantRequestConfirmDialog
                    isOpen={isConfrimOpen}
                    messageOption={{
                      headerLabelId: {
                        id: 'tenant_invite_confirm',
                        prefixId: 'DIALOG_TITLE',
                      },
                      messageLabelId: {
                        id: 'tenant_invite_confirm',
                        prefixId: 'DIALOG_DESCRIPTION',
                      },
                    }}
                    inputOption={{
                      companyName: companyName,
                      tenantAdminName: tenantAdminName,
                      tenantAdminEmail: tenantAdminEmail,
                    }}
                    onDecision={() => {
                      handleRequestDecision();
                    }}
                    onCancel={() => setConfirmOpen(false)}
                  />
                  <Toast />
                </>
              )}
            </div>
          </div>
        </div>
      </article>
      {isLoading && <LoadingIcon />}
    </>
  );
}
