import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  PageNavigation,
  SimpleIconListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBusinessUnitCertificates,
  PageState,
  StringDateToNumber,
  ViewId,
  getDateFormatWithTimezone,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import urlList from '~/shared/menu/path.json';
import {
  CertificateRegistrationDialog,
  CertificateRegistrationResult,
} from '~/shared/components/ui/Dialog/CertificateRegistrationDialog';
import './OcpBusinessUnitProfileInput.css';

interface ViewOcpCertificate {
  id: string; // key
  certificateData: mtechnavi.api.company.IBusinessUnitCertificate; // 非表示項目（更新用データ）
  displayName: string;
  obtained?: string;
  obtainedDt?: string;
  expirationDt?: string;
  scope?: string;
}

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_CERTIFICATE_INPUT';
export const OcpBusinessUnitCertificateInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);

  const intl = useIntl();
  const navi = useNavigate();
  const backPageUrl = useMemo(
    () =>
      urlList.find((v) => v.viewId === sourcePageInfo.current.sourceViewId)
        ?.path ?? '/',
    [sourcePageInfo]
  );
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  type TabName = 'certificate';
  const tabs: TabName[] = ['certificate'];
  const [tabPanel, setTabPanel] = useState<TabName>('certificate');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  const [isLoading, setLoading] = useState(false);
  const [isCertificateDialog, setCertificateDialog] = useState(false);
  const [selectId, setSelectId] = useState('');

  const [listData, setListData] = useState<ViewOcpCertificate[]>([]);
  const [businessUnitCertificate, setBusinessUnitCertificate] =
    useState<mtechnavi.api.company.IBusinessUnitCertificate>();
  const [updatedProperties, setUpdatedProperties] =
    useState<sharelib.IEmbeddedUpdatedProperties>();

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);
  // 確認ダイアログ（削除）
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState('');

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    return includeInputValidateError(document, intl, []);
  };

  // [実行処理]確定 認証情報登録ダイアログ
  const handleDecisionCertificate = (v: CertificateRegistrationResult) => {
    if (selectId) {
      listData.map((e) => {
        if (e.id === selectId) {
          e.certificateData = v.businessUnitCertificate;
          e.displayName = v.businessUnitCertificate.displayName
            ? v.businessUnitCertificate.displayName
            : v.businessUnitCertificate.certificate!.displayNameLang!.ja ?? '';
          e.obtained = v.businessUnitCertificate.obtained ? '○' : '-';
          e.obtainedDt = v.businessUnitCertificate.obtainedDt?.timestamp
            ? getDateFormatWithTimezone(v.businessUnitCertificate.obtainedDt)
            : '';
          e.expirationDt = v.businessUnitCertificate.expirationDt?.timestamp
            ? getDateFormatWithTimezone(v.businessUnitCertificate.expirationDt)
            : '';
          e.scope =
            (v.businessUnitCertificate.scopeProduct
              ? v.businessUnitCertificate.scopeProduct + ','
              : '') + (v.businessUnitCertificate.scopeBranch ?? '');
        }
      });
    } else {
      const data: ViewOcpCertificate = {
        id: (listData.length + 1).toString(),
        certificateData: v.businessUnitCertificate,
        displayName: v.businessUnitCertificate.displayName
          ? v.businessUnitCertificate.displayName
          : v.businessUnitCertificate.certificate!.displayNameLang!.ja ?? '',
        obtained: v.businessUnitCertificate.obtained ? '○' : '-',
        obtainedDt: v.businessUnitCertificate.obtainedDt?.timestamp
          ? getDateFormatWithTimezone(v.businessUnitCertificate.obtainedDt)
          : '',
        expirationDt: v.businessUnitCertificate.expirationDt?.timestamp
          ? getDateFormatWithTimezone(v.businessUnitCertificate.expirationDt)
          : '',
        scope:
          (v.businessUnitCertificate.scopeProduct
            ? v.businessUnitCertificate.scopeProduct + ','
            : '') + v.businessUnitCertificate.scopeBranch,
      };
      console.info(data);
      listData.push(data);
    }
    sortDataList(listData);
    setCertificateDialog(false);
  };

  const handleDelete = () => {
    setListData(listData.filter((v) => v.id !== deleteTargetId));
    setDeleteTargetId('');
    setDeleteConfirmOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveBusinessUnitCertificate();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const getBusinessUnitCertificateList = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitCertificates,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const saveBusinessUnitCertificate = async () => {
    const certificateList: mtechnavi.api.company.IBusinessUnitCertificate[] =
      [];

    listData.forEach((certificate) => {
      const request: mtechnavi.api.company.IBusinessUnitCertificate =
        certificate.certificateData;
      request.companyId = myCompanyInfo.current?.companyId;
      certificateList.push(request);
    });

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpCertificate',
      request: {
        businessUnitCertificates: certificateList,
        companyId: myCompanyInfo.current?.companyId ?? '',
      },
    });
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // ソート
  const sortDataList = (data: ViewOcpCertificate[]) => {
    const sortedData = data.sort(
      (val1, val2) =>
        StringDateToNumber(val2.expirationDt ?? '') -
        StringDateToNumber(val1.expirationDt ?? '')
    );
    setListData(
      sortedData.map(
        (v): ViewOcpCertificate => ({
          id: v.id,
          certificateData: v.certificateData,
          displayName: v.displayName,
          obtained: v.obtained,
          obtainedDt: v.obtainedDt,
          expirationDt: v.expirationDt,
          scope: v.scope,
        })
      )
    );
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        const certificateList = (await getBusinessUnitCertificateList())
          .items as mtechnavi.api.company.IBusinessUnitCertificate[];
        console.info(certificateList);

        if (certificateList) {
          const ocpList: ViewOcpCertificate[] = [];
          certificateList.forEach((r) => {
            const data: ViewOcpCertificate = {
              id:
                r.businessUnitCertificateId ?? (listData.length + 1).toString(),
              certificateData: r,
              displayName: r.displayName
                ? r.displayName
                : r.certificate!.displayNameLang!.ja ?? '',
              obtained: r.obtained ? '○' : '-',
              obtainedDt: r.obtainedDt?.timestamp
                ? getDateFormatWithTimezone(r.obtainedDt)
                : '',
              expirationDt: r.expirationDt?.timestamp
                ? getDateFormatWithTimezone(r.expirationDt)
                : '',
              scope:
                (r.scopeProduct ? r.scopeProduct + ',' : '') +
                (r.scopeBranch ? r.scopeBranch : ''),
            };
            setUpdatedProperties(r.updatedProperties!);
            ocpList.push(data);
          });
          sortDataList(ocpList);
        }
        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const renderCertificateComponent = () => {
    return (
      <>
        <div className="tab-info-body">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-80">
                <SimpleIconListView
                  data={listData}
                  viewOptions={{
                    previewRowCount: 10,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_CERTIFICATE_INPUT.displayName',
                        },
                        propertyName: 'displayName',
                        width: '20%',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_CERTIFICATE_INPUT.obtained',
                        },
                        propertyName: 'obtained',
                        width: '6rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_CERTIFICATE_INPUT.obtainedDt',
                        },
                        propertyName: 'obtainedDt',
                        width: '8rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_CERTIFICATE_INPUT.expirationDt',
                        },
                        propertyName: 'expirationDt',
                        width: '8rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_CERTIFICATE_INPUT.scope',
                        },
                        propertyName: 'scope',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'edit',
                        displayName: '編集',
                        func: (item: ViewOcpCertificate) => {
                          setSelectId(item.id);
                          setBusinessUnitCertificate(item.certificateData);
                          setCertificateDialog(true);
                        },
                      },
                      {
                        name: 'delete',
                        displayName: '削除',
                        func: (item: ViewOcpCertificate) => {
                          setDeleteTargetId(item.id);
                          setDeleteConfirmOpen(true);
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="w-20">
                <CaptionButton
                  buttonType="basic"
                  caption="新規行追加"
                  name=""
                  onClick={() => {
                    setSelectId('');
                    setBusinessUnitCertificate({});
                    setCertificateDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitCertificateInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: updatedProperties?.updatedAt ?? undefined,
                  content: updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => setBackConfirmOpen(true)}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('certificate') && renderCertificateComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      <CertificateRegistrationDialog
        isOpen={isCertificateDialog}
        messageOption={{}}
        inputOption={{
          displayModeType: 'decision',
          businessUnitCertificate: businessUnitCertificate,
        }}
        onCancel={() => {
          setCertificateDialog(false);
        }}
        onDecision={handleDecisionCertificate}
        onChangeLoadingState={(isLoading) => setLoading(isLoading)}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={viewMessageDelete}
        onDecision={() => {
          handleDelete();
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
