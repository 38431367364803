import Long from 'long';
import { useState } from 'react';
import { Amount } from '~/shared/components/parts/Amountbox/AmountBox';
import { Checkbox } from '~/shared/components/ui';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  EstimateResultDetailRegistrationDialog,
  EstimateResultDetailRegistrationDialogPropsInputOption,
} from '~/shared/components/ui/Dialog/EstimateResultDetailRegistrationDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { toNullableMonetaryAmount } from '~/shared/utils';

export const UISampleEstimateResultDetailRegistrationDialogs = () => {
  const [isOpen, setOpen] = useState(false);
  const [result, setResult] =
    useState<mtechnavi.api.estimation.IEstimateResultDetail | null>(null);
  const [quantity, setQuantity] = useState<Amount | null>({
    integralAmount: Long.fromNumber(10),
  });
  const [requiredMaterialCost, setRequiredMaterialCost] = useState(false);
  const [requiredProcessingCost, setRequiredProcessingCost] = useState(false);
  const [requiredAdministrativeCost, setRequiredAdministrativeCost] =
    useState(false);
  const [requiredFreightCost, setRequiredFreightCost] = useState(false);
  const inputOption: EstimateResultDetailRegistrationDialogPropsInputOption = {
    data: {
      ...result,
      estimateRequestDetail: {
        detailAutoName: Long.fromNumber(999),
        displayName: 'テスト品目',
        quantity: toNullableMonetaryAmount({
          currencyCode: '1',
          integralAmount: quantity?.integralAmount,
          fractionalAmount: quantity?.fractionalAmount,
        }),
        requiredMaterialCost,
        requiredProcessingCost,
        requiredAdministrativeCost,
        requiredFreightCost,
      },
    },
  };
  const resultText = result ? JSON.stringify(result, null, 2) : '';

  return (
    <div className="categories">
      <h3 id="dialog">明細内訳登録ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setOpen(true);
              }}
            />
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  <Amountbox
                    name=""
                    columns={['']}
                    labelId="quantity"
                    value={quantity}
                    onChangeState={setQuantity}
                  />
                  <Checkbox
                    name=""
                    columns={['']}
                    items={[{ displayName: '材料費必須', value: '1' }]}
                    onChangeState={(v) => {
                      setRequiredMaterialCost(!!v.length);
                    }}
                  />
                  <Checkbox
                    name=""
                    columns={['']}
                    items={[{ displayName: '加工費必須', value: '1' }]}
                    onChangeState={(v) => {
                      setRequiredProcessingCost(!!v.length);
                    }}
                  />
                  <Checkbox
                    name=""
                    columns={['']}
                    items={[{ displayName: '管理費必須', value: '1' }]}
                    onChangeState={(v) => {
                      setRequiredAdministrativeCost(!!v.length);
                    }}
                  />
                  <Checkbox
                    name=""
                    columns={['']}
                    items={[{ displayName: '運送費必須', value: '1' }]}
                    onChangeState={(v) => {
                      setRequiredFreightCost(!!v.length);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-66">
            <pre className="output">{resultText}</pre>
          </div>
        </div>
      </div>
      <EstimateResultDetailRegistrationDialog
        key={isOpen ? '1' : '0'}
        isOpen={isOpen}
        inputOption={inputOption}
        onDecision={(v) => {
          setResult(v);
          setOpen(false);
        }}
        onCancel={() => {
          setResult(null);
          setOpen(false);
        }}
      />
    </div>
  );
};
