import { mtechnavi } from '~/shared/libs/clientsdk';
import { DataFilterbox, DataFilterboxItem, Textarea, Textbox } from '..';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import {
  getProgramOptionFilterboxData,
  getProgramOptionFilterboxDataWithSystemName,
  includeInputValidateError,
} from '~/shared/utils';
import { CaptionButton } from '../Button';

const getCountrySystemName = (countryCode: string) => {
  return (
    window.App.services.ui.getNameOptionWithCode('A0000041', countryCode)
      .systemName ?? ''
  );
};

export interface BusinessUnitBranchFormOption {
  displayName?: string;
  address?: mtechnavi.api.company.IAddress;
  phoneNumber?: string;
  faxNumber?: string;
  overview?: string;
}

export interface BusinessUnitBranchProps {
  isOpen: boolean;
  inputOption?: BusinessUnitBranchFormOption;
  inputMode: boolean;
  onCancel: () => void;
  onDesicion: (result: BusinessUnitBranchFormOption) => void;
}

export const BusinessUnitBranchDialog = (props: BusinessUnitBranchProps) => {
  const { inputOption } = props;
  const intl = useIntl();
  const [displayName, setDisplayName] = useState<string>('');
  const [country, setCountry] = useState<DataFilterboxItem[]>([]);
  const [postCode, setPostCode] = useState<string>('');
  const [region, setRegion] = useState<DataFilterboxItem[]>([]);
  const [addressLine, setAddressLine] = useState<string>('');
  const [propertyName, setPropertyName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [faxNumber, setFaxNumber] = useState<string>('');
  const [overview, setOverview] = useState<string>('');
  const [isDisabled, setDisabled] = useState(false);

  const [countries, setCountries] = useState<DataFilterboxItem[]>([]);
  const [regions, setRegions] = useState<DataFilterboxItem[]>([]);

  // 入力チェック用
  const [workingBlurCountry, setWorkingBlurCountry] = useState<Date>();
  const [workingBlurRegion, setWorkingBlurRegion] = useState<Date>();
  const requiredCountryArea = useRef(null);
  const requiredRegionArea = useRef(null);
  const requiredDisplayNameArea = useRef(null);
  const requiredAddressLineArea = useRef(null);

  useEffect(() => {
    setCountries(getProgramOptionFilterboxData('A0000041'));
    // 既存データをフィルターするための一時的入れ物。
    // Stateにセットしても空になりFilterできないため
    const regionsFilterBoxData = getProgramOptionFilterboxDataWithSystemName(
      'A0000042',
      getCountrySystemName(inputOption?.address?.country?.code || '')
    );
    setRegions(regionsFilterBoxData);
    setDisabled(!props.inputMode ?? false);
    setDisplayName(inputOption?.displayName ?? '');
    // 入力モードではない場合、渡されたデータをそのまま表示する
    // Shareされた側ではcodeがshareされないことも考慮
    if (!props.inputMode ?? false) {
      setCountry([
        {
          value: inputOption?.address?.country?.code ?? '', //空になる場合があるが、表示のみのため許容
          displayName: inputOption?.address?.country?.displayNameLang?.ja ?? '',
        },
      ]);
      setRegion([
        {
          value: inputOption?.address?.region?.code ?? '', //空になる場合があるが、表示のみのため許容
          displayName: inputOption?.address?.region?.displayNameLang?.ja ?? '',
        },
      ]);
    } else {
      const selectedCountryCode = inputOption?.address?.country?.code;
      const selectedCountry = selectedCountryCode
        ? countries.filter((c) => c.value === selectedCountryCode)
        : [];
      setCountry(selectedCountry);

      const selectedRegionCode = inputOption?.address?.region?.code;
      const selectedRegion = selectedRegionCode
        ? regionsFilterBoxData.filter((p) => p.value === selectedRegionCode)
        : [];
      setRegion(selectedRegion);
    }
    setPostCode(inputOption?.address?.postalCode ?? '');

    const addressLines = inputOption?.address?.addressLines ?? [];
    setAddressLine(addressLines.length > 0 ? addressLines[0] : '');
    setPropertyName(addressLines.length > 1 ? addressLines[1] : '');
    setPhoneNumber(inputOption?.phoneNumber ?? '');
    setFaxNumber(inputOption?.faxNumber ?? '');
    setOverview(inputOption?.overview ?? '');

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inputOption]);

  const handleCancel = () => {
    props.onCancel();
    formReset();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const resultCountry = window.App.services.ui.getNameOptionWithCode(
      'A0000041',
      country[0].value ?? ''
    );
    const resultRegion = window.App.services.ui.getNameOptionWithCode(
      'A0000042',
      region[0].value ?? ''
    );
    const result: BusinessUnitBranchFormOption = {
      displayName,
      address: {
        country: resultCountry,
        postalCode: postCode,
        region: resultRegion,
        addressLines: [addressLine ?? '', propertyName ?? ''],
      },
      phoneNumber,
      faxNumber,
      overview,
    };
    props.onDesicion(result);
    formReset();
  };

  const formReset = () => {
    setDisplayName('');
    setCountry([]);
    setPostCode('');
    setRegion([]);
    setAddressLine('');
    setPropertyName('');
    setPhoneNumber('');
    setFaxNumber('');
    setOverview('');
    setWorkingBlurCountry(undefined);
    setWorkingBlurRegion(undefined);
  };

  const isInputError = () => {
    const targetElm = document.querySelector('.business-unit-branch-dialog');

    setWorkingBlurCountry(new Date());
    setWorkingBlurRegion(new Date());
    const countryValue = country.length > 0 ? country[0].value : '';
    const regionValue = region.length > 0 ? region[0].value : '';

    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: countryValue,
          ref: requiredCountryArea,
        },
        {
          value: regionValue,
          ref: requiredRegionArea,
        },
        {
          value: displayName ?? '',
          ref: requiredDisplayNameArea,
        },
        {
          value: addressLine ?? '',
          ref: requiredAddressLineArea,
        },
      ])
    ) {
      return true;
    }

    return false;
  };

  // 国が変更された時、国に一致する地域のみ表示する
  const resetRegions = (countryValue: DataFilterboxItem[]) => {
    const systemName = getCountrySystemName(
      countryValue.length ? countryValue[0].value : ''
    );
    setRegions(
      getProgramOptionFilterboxDataWithSystemName('A0000042', systemName)
    );
    setRegion([]);
  };

  const elements = (
    <div className="business-unit-branch-dialog">
      <div className="detail-area">
        <div className="contents-box">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-66">
                {/* 拠点名 */}
                <Textbox
                  name="displayName"
                  type="text"
                  value={displayName}
                  labelId="BusinessUnitBranchDialog.displayName"
                  onChangeState={setDisplayName}
                  columns={['displayName']}
                  validateOption={{ required: true }}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-33" ref={requiredCountryArea}>
                {/* 所在国 */}
                <DataFilterbox
                  data={countries}
                  labelId="BusinessUnitBranchDialog.country"
                  searchOption={{ targets: 'displayName' }}
                  name="country"
                  columns={['country']}
                  onChangeState={(v) => {
                    setCountry(v);
                    resetRegions(v);
                  }}
                  validateOption={{ required: true }}
                  value={country}
                  workingBlur={workingBlurCountry}
                  disabled={isDisabled}
                ></DataFilterbox>
              </div>
              <div className="w-33">
                {/* 郵便番号 */}
                <Textbox
                  name="postCode"
                  type="text"
                  value={postCode}
                  labelId="BusinessUnitBranchDialog.postCode"
                  columns={['postCode']}
                  onChangeState={setPostCode}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-33" ref={requiredCountryArea}>
                {/* 都道府県名 */}
                <DataFilterbox
                  data={regions}
                  labelId="BusinessUnitBranchDialog.region"
                  searchOption={{ targets: 'displayName' }}
                  name="region"
                  columns={['region']}
                  onChangeState={(v) => {
                    setRegion(v);
                  }}
                  validateOption={{ required: true }}
                  value={region}
                  workingBlur={workingBlurRegion}
                  disabled={isDisabled}
                ></DataFilterbox>
              </div>
              <div className="w-66">
                {/* 市区町村以下 */}
                <Textbox
                  name="addressLine"
                  type="text"
                  labelId="BusinessUnitBranchDialog.addressLine"
                  columns={['addressLine']}
                  value={addressLine}
                  onChangeState={setAddressLine}
                  validateOption={{ required: true }}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-66">
                {/* 建物名 */}
                <Textbox
                  name="propertyName"
                  type="text"
                  labelId="BusinessUnitBranchDialog.propertyName"
                  columns={['propertyName']}
                  value={propertyName}
                  onChangeState={setPropertyName}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-33">
                {/* 電話番号 */}
                <Textbox
                  name="phoneNumber"
                  type="text"
                  labelId="BusinessUnitBranchDialog.phoneNumber"
                  value={phoneNumber}
                  onChangeState={setPhoneNumber}
                  columns={['phoneNumber']}
                  disabled={isDisabled}
                />
              </div>
              <div className="w-33">
                {/* FAX番号 */}
                <Textbox
                  name="faxNumber"
                  type="text"
                  labelId="BusinessUnitBranchDialog.faxNumber"
                  value={faxNumber}
                  onChangeState={setFaxNumber}
                  columns={['faxNumber']}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* 事業内容 / 製造内容 */}
                <Textarea
                  name="overview"
                  className="w-100 mh-middle"
                  labelId="BusinessUnitBranchDialog.overview"
                  value={overview}
                  onChangeState={setOverview}
                  columns={['overview']}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
          {props.inputMode && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'decision',
              })}
              onClick={handleDecision}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: props.inputMode
        ? 'BUSINESS_UNIT_BRANCH_REGISTRATION'
        : 'BUSINESS_UNIT_BRANCH_CONFIRMATION',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };
  return <ModalDialogComponent {...openModalProps} />;
};
