import {
  ToastContainer,
  toast,
  Flip,
  ToastOptions,
  ToastContainerProps,
  Id,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';
import { useEffect } from 'react';

const defaultContainerId = 'page';
const option = (className: string, autoClose: false | number): ToastOptions => {
  return {
    position: 'top-right',
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Flip,
    theme: 'dark',
    className: className,
  };
};

export const success = (messages: string[]) => {
  const containerId = getToastTarget();
  for (const message of messages) {
    toast(message, { ...option('toast-success', 4000), containerId });
  }
};

export const error = (messages: string[]) => {
  const containerId = getToastTarget();
  for (const message of messages) {
    if (toast.isActive(message, containerId)) {
      // 表示中の同一メッセージのアニメーションだけを再発生させる。
      toast.update(message, { transition: undefined, containerId });
      toast.update(message, { transition: Flip, containerId });
      return;
    }
    toast(message, {
      ...option('toast-error', false),
      transition: Flip,
      toastId: message,
      containerId,
    });
  }
};

export const warning = (messages: string[]) => {
  const containerId = getToastTarget();
  for (const message of messages) {
    if (toast.isActive(message, containerId)) {
      // 表示中の同一メッセージのアニメーションだけを再発生させる。
      toast.update(message, { transition: undefined, containerId });
      toast.update(message, { transition: Flip, containerId });
      return;
    }
    toast(message, {
      ...option('toast-warning', false),
      transition: Flip,
      toastId: message,
      containerId,
    });
  }
};

const toastTargetStack: Id[] = [];
const getToastTarget = () => {
  return toastTargetStack.at(toastTargetStack.length - 1);
};

export function Toast(props: ToastContainerProps) {
  const containerId = props?.containerId || defaultContainerId;

  useEffect(() => {
    toastTargetStack.push(containerId);
    return () => {
      toastTargetStack.pop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ToastContainer
      {...props}
      containerId={props?.containerId || defaultContainerId}
    />
  );
}
