import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { ApprovalRejectionDialog } from '~/shared/components/ui/Dialog';

export const UISampleApprovalRejectionDialogs = () => {
  const [rejectionResult, setRejectionResult] = useState('');
  const [isShowRejection, setIsShowRejection] = useState(false);
  const [approvalResult, setApprovalResult] = useState('');
  const [isShowApproval, setIsShowApproval] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">承認/却下ダイアログ</h3>

      <h4>承認ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setApprovalResult('');
                setIsShowApproval(true);
              }}
            />
            <ApprovalRejectionDialog
              isOpen={isShowApproval}
              messageOption={{
                headerLabelId: {
                  id: 'approval',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'approval',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              inputOption={{
                approveOrganizationUnit: {
                  value: '12345',
                  displayName: 'XXX部門',
                },
                approveOrganizationStaff: {
                  value: '12345',
                  displayName: '山田花子',
                },
                requestComment: '承認お願いいたします',
                dialogType: 'approval',
              }}
              onDecision={(v) => {
                setApprovalResult(v);
                setIsShowApproval(false);
              }}
              onCancel={() => {
                setApprovalResult('');
                setIsShowApproval(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{approvalResult}</pre>
          </div>
        </div>
      </div>

      <h4>却下ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setRejectionResult('');
                setIsShowRejection(true);
              }}
            />
            <ApprovalRejectionDialog
              isOpen={isShowRejection}
              messageOption={{
                headerLabelId: {
                  id: 'rejection',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'rejection',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              inputOption={{
                approveOrganizationUnit: {
                  value: '12345',
                  displayName: 'XXX部門',
                },
                approveOrganizationStaff: {
                  value: '12345',
                  displayName: '山田花子',
                },
                requestComment: '承認お願いいたします',
                dialogType: 'rejection',
              }}
              onDecision={(v) => {
                setRejectionResult(v);
                setIsShowRejection(false);
              }}
              onCancel={() => {
                setRejectionResult('');
                setIsShowRejection(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{rejectionResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
