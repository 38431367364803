import { useIntl } from 'react-intl';
import { GetMessageWithIntl, MessageProps } from '../../parts/Message/Message';
import { CaptionButton } from '../Button/CaptionButton';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { useEffect, useRef, useState } from 'react';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
  FileUploaderValidateOption,
} from '../../file';
import { error } from '../../parts/Toast/Toast';
import { Textarea } from '../Textarea';
import { SimpleListView } from '../SimpleListView';
import { ConfirmationDialog } from './ConfirmationDialog';
import { AttachmentFile } from './CommonDialogTypes';

type ConfirmType = 'all' | 'file';

export interface FileCommentDialogMessageOption {
  dialogTitle: MessageProps;
  commentLabelId?: string;
  buttonType: MessageProps;
  simpleListViewHeader: MessageProps;
}

export interface FileCommentDialogFormOption {
  comment?: string;
  files?: AttachmentFile[];
}

export interface FileUploadCommentDialogDisplayOption {
  isSkipCommentArea?: boolean;
  isDnd?: boolean;
}

export interface FileCommentDialogUploaderOption {
  validateOption?: FileUploaderValidateOption;
}

export interface FileUploadCommentDialogProps {
  uploaderOption: FileCommentDialogUploaderOption;
  messageOption: FileCommentDialogMessageOption;
  inputOption: FileCommentDialogFormOption;
  displayOption?: FileUploadCommentDialogDisplayOption;
  isOpen: boolean;
  onDecision: (v: FileCommentDialogFormOption) => void;
  onCancel: () => void;
}

export const FileUploadWithCommentDialog = ({
  isOpen,
  uploaderOption,
  inputOption,
  messageOption,
  displayOption,
  onDecision,
  onCancel,
}: FileUploadCommentDialogProps) => {
  const intl = useIntl();
  const isSkipCommentArea = displayOption?.isSkipCommentArea ?? false;
  const isDnd = displayOption?.isDnd ?? false;
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);

  const [fileList, setFileList] = useState<AttachmentFile[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isErrorContain, setErrorContain] = useState(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmType | null>(null);
  const [confirmTarget, setConfirmTarget] = useState<AttachmentFile | null>(
    null
  );
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    setFileList(inputOption.files ?? []);
    setComment(inputOption.comment ?? '');
  }, [isOpen, inputOption.files, inputOption.comment]);

  const handleUploaded = (fileList: FileItem[]) => {
    setErrorContain(fileList.some((item) => item.status !== 'OK'));
    setTempFileList(fileList);
  };

  const handleAddList = () => {
    if (isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }

    if (
      (fileList.length && fileList.length + tempFileList.length) >
      (uploaderOption.validateOption?.maxFileCount ?? 10)
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000077', value: { $1: 10 } })]);
      return;
    }

    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      'B03'
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      'B03'
    );
    setFileList([
      ...fileList,
      ...tempFileList.map((file) => ({
        id: `${file.file.name ?? ''}`,
        category: category.length > 0 ? category[0] : {},
        assetId: '',
        filename: `${file.file.name ?? ''}`,
        url: file.url,
        mimeType: '',
        remarks: '',
        linkType: linkType.length > 0 ? linkType[0] : {},
      })),
    ]);
    uploaderRef.current?.clear();
  };

  const handleRemove = (item: AttachmentFile) => {
    setConfirmAction('file');
    setConfirmTarget(item);
  };

  const handleAllRemove = () => {
    setConfirmAction('all');
  };

  const handleConfirmed = (
    type: ConfirmType | null,
    target: AttachmentFile | null
  ) => {
    if (!type) {
      return;
    }
    if (type === 'all') {
      setFileList([]);
    } else {
      setFileList(fileList.filter((v) => v !== target));
    }

    setConfirmAction(null);
    setConfirmTarget(null);
  };

  const elements = (
    <div className="file-upload-with-comment-dialog">
      <div className="contents-box">
        {!isSkipCommentArea && (
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="comment"
                  className="w-100 mh-middle"
                  value={comment}
                  labelId={messageOption.commentLabelId}
                  columns={['comment']}
                  onChangeState={setComment}
                />
              </div>
            </div>
          </div>
        )}
        <div className="file-uploader-area">
          <ExtendFileUploader
            name="fileUploader"
            ref={uploaderRef}
            dndOption={{
              enabled: isDnd,
            }}
            multiple={false}
            validateOption={uploaderOption.validateOption}
            resultOption={{
              omitFooter: true,
            }}
            onUpload={handleUploaded}
            onChangeLoadingState={setLoading}
          />

          <div className="halfway-action-area">
            <CaptionButton
              name="add"
              buttonType="basic"
              disabled={isLoading}
              caption={GetMessageWithIntl(intl, {
                id: 'add_list',
                prefixId: 'DIALOG_CAPTION',
              })}
              onClick={handleAddList}
            />
          </div>

          <SimpleListView
            data={fileList}
            viewOptions={{
              previewRowCount: 3,
              keyColumn: 'url',
              columns: [
                {
                  propertyName: 'filename',
                  header: {
                    id: messageOption.simpleListViewHeader.id,
                    prefixId: messageOption.simpleListViewHeader.prefixId,
                  },
                },
              ],
            }}
            actionOptions={{
              onDelete: handleRemove,
              onDeleteAll: handleAllRemove,
            }}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => onCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            disabled={isLoading}
            caption={GetMessageWithIntl(intl, {
              id: messageOption.buttonType.id,
            })}
            onClick={() => {
              onDecision({
                comment,
                files: [...fileList],
              });
            }}
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={!!confirmAction}
        viewMessage={{
          id:
            confirmAction === 'all'
              ? 'confirmationDialogMessageDeleteAll'
              : 'confirmationDialogMessageDelete',
          prefixId: 'FileCommentDialog',
        }}
        onDecision={() => handleConfirmed(confirmAction, confirmTarget)}
        onCancel={() => setConfirmAction(null)}
      />
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: messageOption.dialogTitle.id,
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
