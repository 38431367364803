import { useEffect, useState } from 'react';
import { Radio } from '~/shared/components';
import { GetMessage } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { MessageProps } from '../..';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { useIntl } from 'react-intl';

export const OutputInfoTypeItems = [
  'requestManagementInfo',
  'answers',
  'allAnswers',
];
export const OutputVolumeItems = ['selectedOnly', 'all'] as const;

export type pageType =
  | 'none'
  | 'supplierBase'
  | 'workTask'
  | 'form'
  | 'estimate';
export type OutputVolume = typeof OutputVolumeItems[number];
export interface OutputDialogOutput {
  outputInfoType: string;
  outputVolume: OutputVolume;
}

interface OutputMessageOption {
  headerLabelId: MessageProps;
}

export interface OutputDialogProps {
  type?: pageType;
  isOpen: boolean;
  messageOption?: OutputMessageOption;
  onDecision: (v: OutputDialogOutput) => void;
  onCancel: () => void;
}

interface RadioOption {
  value: string;
  displayName: string;
}

export const OutputDialog = (props: OutputDialogProps) => {
  const intl = useIntl();
  const [radio, setRadio] = useState('');

  useEffect(() => {
    if (props.isOpen) {
      switch (props.type ?? 'none') {
        case 'none':
          break;
        case 'supplierBase':
          setRadio('requestManagementInfo');
          break;
        case 'workTask':
          setRadio('workTaskManagementInfo');
          break;
        case 'form':
          setRadio('formInfo');
          break;
        case 'estimate':
          setRadio('estimateOrders');
          break;
      }
    }
  }, [props.isOpen, intl, props.type]);

  const generateItems = (type: pageType) => {
    const items: RadioOption[] = [];
    switch (type) {
      case 'none':
        return items;
      case 'supplierBase':
        OutputInfoTypeItems.map((item) => {
          items.push({
            value: item,
            displayName: GetMessage({
              id: `radio_${item}`,
              prefixId: 'OutputDialog',
            }),
          });
        });
        return items;
      case 'workTask':
        items.push({
          value: 'workTaskManagementInfo',
          displayName: GetMessage({
            id: 'radio_workTaskManagementInfo',
            prefixId: 'OutputDialog',
          }),
        });
        return items;
      case 'form':
        items.push({
          value: 'formInfo',
          displayName: GetMessage({
            id: 'radio_formInfo',
            prefixId: 'OutputDialog',
          }),
        });
        return items;
      case 'estimate':
        // 発注データ
        items.push({
          value: 'estimateOrders',
          displayName: GetMessage({
            id: 'radio_estimateOrders',
            prefixId: 'OutputDialog',
          }),
        });
        return items;
    }
  };

  const radioItems: RadioOption[] = generateItems(props.type ?? 'none');

  const elements = (
    <div className="output-dialog">
      <div className="contents-box">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="radio outputType"
                className="group"
                items={radioItems}
                value={radio}
                validateOption={{ required: true }}
                onChangeState={setRadio}
              ></Radio>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="selectedOnlyBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'selected_only',
              prefixId: 'OutputDialog',
            })}
            onClick={() =>
              props.onDecision({
                outputInfoType: radio,
                outputVolume: 'selectedOnly',
              })
            }
          />
          <CaptionButton
            name="allBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'all',
              prefixId: 'OutputDialog',
            })}
            onClick={() =>
              props.onDecision({
                outputInfoType: radio,
                outputVolume: 'all',
              })
            }
            disabled={
              radio === OutputInfoTypeItems[1] ||
              radio === OutputInfoTypeItems[2]
            }
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => props.onCancel()}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: props.messageOption?.headerLabelId ?? {
      id: 'output',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
