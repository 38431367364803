import React from 'react';
import { CategorizedBranchRef } from './CategorizedBranch';
import { CategorizedTipCheckboxRef } from './CategorizedTipCheckbox';
import { DisplayNameLang } from '~/shared/utils/commonType';

export interface CheckItem {
  id?: string;
  displayNameLang?: DisplayNameLang;
}

export type CategorizedList = CategorizedBranchData[];

export type CategorizedItem = CategorizedBranchData | CategorizedTipData;
export interface CategorizedBranchData {
  CategorizedItemId: string; // 取り扱いやすいようカテゴリも ID を持つ
  displayNameLang?: DisplayNameLang;
  children: (CategorizedBranchData | CategorizedTipData)[];
}

export interface CategorizedTipData {
  CategorizedItemId: string;
  displayNameLang?: DisplayNameLang;
}

export const isCategorizedBranchList = (
  list: unknown[]
): list is CategorizedBranchData[] => {
  return list.every((item) =>
    isCategorizedBranch(item as CategorizedBranchData | CategorizedTipData)
  );
};
export const isCategorizedTipList = (
  list: unknown[]
): list is CategorizedTipData[] => {
  return list.every((item) =>
    isCategorizedTip(item as CategorizedBranchData | CategorizedTipData)
  );
};
export const isCategorizedBranch = (
  item: CategorizedBranchData | CategorizedTipData
): item is CategorizedBranchData => {
  return Object.hasOwn(item, 'children');
};
export const isCategorizedTip = (
  item: CategorizedBranchData | CategorizedTipData
): item is CategorizedTipData => {
  return !Object.hasOwn(item, 'children');
};

export const allCheckIdFormat = (parentId = '') => `${parentId}-allCheck`;

/**
 * CategorizedList を filterIds に指定された ID を内包するものだけに絞り込む。
 * 第一階層は filterIds の ID を内包しなくても維持される。
 */
export const filterCategorizedList = (
  listData: CategorizedList,
  filterIds: string[]
): CategorizedList => {
  return listData.map((category) => ({
    ...category,
    children: filterCategorizedListChildren(category.children, filterIds) || [],
  }));
};
const filterCategorizedListChildren = (
  listData: CategorizedBranchData[] | CategorizedTipData[],
  filterIds?: string[]
): CategorizedBranchData[] | CategorizedTipData[] | null => {
  if (isCategorizedBranchList(listData)) {
    const categories = listData
      .map((item) => {
        const children = filterCategorizedListChildren(
          item.children,
          filterIds
        );
        if (!children) {
          return null;
        }
        return {
          ...item,
          children: children,
        };
      })
      .filter((item) => !!item) as CategorizedBranchData[];
    if (categories.length === 0) {
      return null;
    }
    return categories;
  } else {
    if (
      filterIds &&
      !listData.some((item) =>
        filterIds.some((id) => id === item.CategorizedItemId)
      )
    ) {
      return null;
    }
    return listData;
  }
};

/**
 * 階層構造のデータを元に各要素の ref および、「フォーカス遷移可能な関連する要素の ID 」のデータを作成する。
 */
export interface RelationMap {
  [id: string]: RelationItem;
}
interface RelationItem {
  id: string;
  ref?: React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>;
  parentId?: string; // 親要素のID
  prevId?: string; // 直近の要素のID
  nextId?: string; // 直近の要素のID
  prevLayerId?: string; // 直近の別階層要素のID
  nextLayerId?: string; // 直近の別階層要素のID
  prevSiblingId?: string; // 直近の兄弟ID
  nextSiblingId?: string; // 直近の兄弟ID
}
interface ParentInfo {
  parent?: CategorizedItem;
  prev?: CategorizedItem;
  next?: CategorizedItem;
}
export const createRelationList = (
  list: CategorizedBranchData[]
): RelationMap => {
  const [relationList] = createChildRelation(list);
  const relationMap: RelationMap = {};
  relationList.forEach((item) => {
    relationMap[item.id] = item;
  });
  return relationMap;
};
const createChildRelation = (
  list: CategorizedItem[],
  parentInfo?: ParentInfo
): [RelationItem[], string, string] => {
  if (isCategorizedBranchList(list)) {
    let latestFirstReefId = '';
    let latestLastReefId = '';
    const results = list.flatMap((item, index): RelationItem[] => {
      const isFirst = index === 0;
      const isEnd = index === list.length - 1;
      const nextId = isCategorizedBranchList(item.children)
        ? item.children.at(0)?.CategorizedItemId
        : allCheckIdFormat(item.CategorizedItemId);
      const rel: RelationItem = {
        id: item.CategorizedItemId,
        ref: React.createRef(),
        parentId: parentInfo?.parent?.CategorizedItemId,
        prevId: isFirst
          ? parentInfo?.parent?.CategorizedItemId
          : latestLastReefId,
        prevSiblingId: isFirst
          ? undefined
          : list.at(index - 1)?.CategorizedItemId,
        nextSiblingId: list.at(index + 1)?.CategorizedItemId,
        nextId: nextId,
        prevLayerId: isFirst
          ? parentInfo?.parent?.CategorizedItemId
          : latestFirstReefId,
        nextLayerId: nextId,
      };
      const [children, childFirstReefId, childLastReefId] = createChildRelation(
        item.children,
        {
          parent: item,
          prev: isFirst ? parentInfo?.prev : list.at(index - 1),
          next: isEnd ? parentInfo?.next : list.at(index + 1),
        }
      );
      latestFirstReefId = childFirstReefId;
      latestLastReefId = childLastReefId;
      return [...children, rel];
    });
    return [results, latestFirstReefId, latestLastReefId];
  } else {
    const results: RelationItem[] = [
      {
        // "全て" チェック用の要素
        id: allCheckIdFormat(parentInfo?.parent?.CategorizedItemId),
        ref: React.createRef(),
        parentId: parentInfo?.parent?.CategorizedItemId,
        prevId: parentInfo?.parent?.CategorizedItemId,
        nextId: list.at(0)?.CategorizedItemId,
        prevLayerId: parentInfo?.parent?.CategorizedItemId,
        nextLayerId: parentInfo?.next?.CategorizedItemId,
      },
      ...list.flatMap((item, index): RelationItem[] => {
        const isFirst = index === 0;
        const rel: RelationItem = {
          id: item.CategorizedItemId,
          ref: React.createRef(),
          parentId: parentInfo?.parent?.CategorizedItemId,
          prevId: isFirst
            ? allCheckIdFormat(parentInfo?.parent?.CategorizedItemId)
            : list.at(index - 1)?.CategorizedItemId,
          nextId:
            list.at(index + 1)?.CategorizedItemId ||
            parentInfo?.next?.CategorizedItemId,
          prevLayerId: parentInfo?.parent?.CategorizedItemId,
          nextLayerId: parentInfo?.next?.CategorizedItemId,
        };
        return [rel];
      }),
    ];
    return [
      results,
      allCheckIdFormat(parentInfo?.parent?.CategorizedItemId),
      results.at(results.length - 1)?.id || '',
    ];
  }
};

// 対象の ID に一致するものだけをフラットな配列で返す
export const extractTargetIdItems = (
  data: CategorizedItem[],
  targetIds: string[]
): CheckItem[] => {
  if (isCategorizedBranchList(data)) {
    return data.flatMap((item) => {
      return extractTargetIdItems(item.children, targetIds);
    });
  } else {
    return data
      .filter((item) => targetIds.includes(item.CategorizedItemId))
      .map((item) => ({
        id: item.CategorizedItemId,
        displayNameLang: item.displayNameLang,
      }));
  }
};
