export interface GroupTreeItem<T> {
  id?: string;
  displayName?: string;
  isDisabled?: boolean;
  children?: GroupTreeItem<T>[];
  originItem?: T;
}

export const groupTreeSearch = <T>(
  tree: GroupTreeItem<T>[],
  searchWord: string
): string => {
  let matchId = '';
  tree.forEach((item) => {
    if (matchId) {
      return;
    }
    if (item.displayName?.match(searchWord)) {
      matchId = item.id || '';
      return;
    }
    if (item.children) {
      const result = groupTreeSearch(item.children, searchWord);
      matchId = result;
    }
  });
  return matchId;
};
