import { Container, Toast } from '~/shared/components';
import { Tabs } from '~/shared/components/ui';
import './DialogSample.css';

import { UISampleDialogs } from '../UISample/Interactive/Dialogs';
import { UISampleBusinessUnitDetailDialogs } from './samples/BusinessUnitDetailDialogs';
import { UISampleCommentDialogs } from './samples/CommentDialogs';
import { UISampleMemoDialogs } from './samples/MemoDialogs';
import { UISampleFileUploadDialogs } from './samples/FileUploadDialogs';
import { UISampleDeadlineChangeDialogs } from './samples/DeadlineChangeDialogs';
import { UISampleApprovalRequestDialogs } from './samples/ApprovalRequestDialogs';
import { UISampleApprovalRejectionDialogs } from './samples/ApprovalRejectionDialogs';
import { UISampleOutputDialogs } from './samples/OutputDialogs';
import { UISampleSystemNotificationSettingDialogs } from './samples/SystemNotificationSettingDialogs';
import { UISampleSurveyReplyDialogs } from './samples/SurveyReplyDialogs';
import { UISampleSurveyReplyDelegationDialogs } from './samples/SurveyReplyDelegationDialog';
import { UISampleManualReminderDialogs } from './samples/ManualReminderDialogs';
import { UISampleAutoReminderSettingDialogs } from './samples/AutoReminderSettingDialogs';
import { UISampleBusinessUnitCsvAdditionDialogs } from './samples/BusinessUnitCsvAddtionDialogs';
import { UISampleHistoryDialogs } from './samples/HistoryDialogs';
import { UISampleBusinessUnitDialogs } from './samples/BusinessUnitDailogs';
import { UISampleUserAttributeDialogs } from './samples/UserAttributeDialogs';
import { UISampleFileImportDialogs } from './samples/FileImportDialogs';
import { UISampleFileManagementDialogs } from './samples/FileManagementDialogs';
import { UISampleFileUploadWithCommentDialogs } from './samples/FileUploadWithCommentDialogs';
import { UISampleFileUploadWithMemoDialogs } from './samples/FileUploadWithMemoDialogs';
import { UISampleChangeStaffDialogs } from './samples/ChangeStaffDialogs';
import { UISampleSelectConnectingDialogs } from './samples/SelectConnectingDialogs';
import { UISampleBusinessUnitBranchDialogs } from './samples/BusinessUnitBranchDialogs';
import { UISampleBusinessUnitStrengthDialogs } from './samples/BusinessUnitStrengthDialogs';
import { UISampleBusinessUnitFacilityDialogs } from './samples/BusinessUnitFacilityDialogs';
import { UISampleFinancialRegistrationDialogs } from './samples/FinancialRegistrationDialogs';
import { UISampleCertificateRegistrationDialogs } from './samples/CertificateRegistrationDialogs';
import { UISampleContactDialogs } from './samples/ContactDialogs';
import { UISampleNotificationUpdateStartStopDialogs } from './samples/NotificationUpdateStartStopDialogs';
import { UISampleChangeNotificationDialogs } from './samples/ChangeNotificationDialogs';
import { UISampleDocumentRegistrationDialogs } from './samples/DocumentRegistrationDialogs';
import { UISampleFileInputImportDialogs } from './samples/FormInputImportDialogs';
import { UISampleFormSettingDialogs } from './samples/FormSettingDialogs';
import { UISampleSearchRegionDialogs } from './samples/SearchRegionDialogs';
import { UISampleSurveyRequestRegistrationDialogs } from './samples/SurveyRequestRegistrationDialogs';
import { UISampleBuildedInputFormDialogs } from './samples/BuildedInputFormDialogs';
import { UISampleResponsiblePersonRegistrationDialogs } from './samples/ResponsibePersonRegistrationDialogs';
import { UISampleSimpleListDialogs } from './samples/SimpleListDialogs';
import { UISampleSelectPlanDialogs } from './samples/SelectPlanDialogs';
import { UISampleEstimateSelectionDialogs } from './samples/EstimateSelectionDialogs';
import { UISampleSuppliedRegistrationDialogs } from './samples/SuppliedRegistrationDialogs';
import { UISampleEstimateResultDetailRegistrationDialogs } from './samples/EstimateResultDetailRegistrationDialogs';
import { UISampleDetailInputDialogs } from './samples/DetailInputDialogs';
import { UISampleDetailConfirmationDialogs } from './samples/DetailConfirmationDialogs';
import { UISampleEstimateBasicInfoRegistrationDialogs } from './samples/EstimateBasicInfoRegistrationDialogs';
import { UISampleFileUploadAppendDialogs } from './samples/FileUploadAppendDialogs';
import { UISampleFileUploadAppendListDialogs } from './samples/FileUploadAppendListDialogs';
import { UISampleOwnStaffDialogs } from './samples/OwnStaffDialogs';
import { UISampleFlagSettingDialogs } from './samples/FlagSettingDialogs';
import { UISampleNotificationSendTargetSettingDialogs } from './samples/NotificationSendTargetSettingDialogs';

const tabData = [
  { label: '確認ダイアログ', tabContent: <UISampleDialogs /> },
  { label: 'コメント付ダイアログ', tabContent: <UISampleCommentDialogs /> },
  { label: 'メモダイアログ', tabContent: <UISampleMemoDialogs /> },
  { label: '履歴ダイアログ', tabContent: <UISampleHistoryDialogs /> },
  {
    label: 'ファイル追加ダイアログ',
    tabContent: <UISampleFileUploadDialogs />,
  },
  {
    label: '取引先詳細ダイアログ',
    tabContent: <UISampleBusinessUnitDetailDialogs />,
  },
  { label: '回答送信ダイアログ', tabContent: <UISampleSurveyReplyDialogs /> },
  {
    label: '回答代行ダイアログ',
    tabContent: <UISampleSurveyReplyDelegationDialogs />,
  },
  {
    label: '回答期日変更系ダイアログ',
    tabContent: <UISampleDeadlineChangeDialogs />,
  },
  {
    label: '承認依頼ダイアログ',
    tabContent: <UISampleApprovalRequestDialogs />,
  },
  {
    label: '承認/却下ダイアログ',
    tabContent: <UISampleApprovalRejectionDialogs />,
  },
  { label: '出力ダイアログ', tabContent: <UISampleOutputDialogs /> },
  {
    label: 'システム通知設定ダイアログ',
    tabContent: <UISampleSystemNotificationSettingDialogs />,
  },
  {
    label: '手動催促ダイアログ',
    tabContent: <UISampleManualReminderDialogs />,
  },
  {
    label: '自動催促設定ダイアログ',
    tabContent: <UISampleAutoReminderSettingDialogs />,
  },
  {
    label: '取引先CSV追加ダイアログ',
    tabContent: <UISampleBusinessUnitCsvAdditionDialogs />,
  },
  {
    label: '取引先取込ダイアログ',
    tabContent: <UISampleBusinessUnitDialogs />,
  },
  {
    label: 'アカウント取込ダイアログ',
    tabContent: <UISampleUserAttributeDialogs />,
  },
  {
    label: 'ファイル取込ダイアログ',
    tabContent: <UISampleFileImportDialogs />,
  },
  {
    label: 'ファイル管理ダイアログ',
    tabContent: <UISampleFileManagementDialogs />,
  },
  {
    label: 'コメント付きファイルアップロードダイアログ',
    tabContent: <UISampleFileUploadWithCommentDialogs />,
  },
  {
    label: '備考付きファイルアップロードダイアログ',
    tabContent: <UISampleFileUploadWithMemoDialogs />,
  },
  { label: '担当変更ダイアログ', tabContent: <UISampleChangeStaffDialogs /> },
  {
    label: '紐付け分類ダイアログ（紐付けダイアログ）',
    tabContent: <UISampleSelectConnectingDialogs />,
  },
  {
    label: '拠点情報登録/確認ダイアログ',
    tabContent: <UISampleBusinessUnitBranchDialogs />,
  },
  {
    label: '得意分野情報登録/確認ダイアログ',
    tabContent: <UISampleBusinessUnitStrengthDialogs />,
  },
  {
    label: '設備情報登録/確認ダイアログ',
    tabContent: <UISampleBusinessUnitFacilityDialogs />,
  },
  {
    label: '決算情報登録ダイアログ',
    tabContent: <UISampleFinancialRegistrationDialogs />,
  },
  {
    label: '認証情報登録ダイアログ',
    tabContent: <UISampleCertificateRegistrationDialogs />,
  },
  { label: '連絡先系ダイアログ', tabContent: <UISampleContactDialogs /> },
  {
    label: '通知更新停止/再開ダイアログ',
    tabContent: <UISampleNotificationUpdateStartStopDialogs />,
  },
  {
    label: '更新通知ダイアログ',
    tabContent: <UISampleChangeNotificationDialogs />,
  },
  {
    label: '文書登録ダイアログ',
    tabContent: <UISampleDocumentRegistrationDialogs />,
  },
  {
    label: 'フォーム入力インポートダイアログ',
    tabContent: <UISampleFileInputImportDialogs />,
  },
  {
    label: 'フォーム設定ダイアログ',
    tabContent: <UISampleFormSettingDialogs />,
  },
  {
    label: 'フォーム入力系ダイアログ',
    tabContent: <UISampleBuildedInputFormDialogs />,
  },
  {
    label: '地域検索ダイアログ',
    tabContent: <UISampleSearchRegionDialogs />,
  },
  {
    label: '調査依頼追加ダイアログ',
    tabContent: <UISampleSurveyRequestRegistrationDialogs />,
  },
  {
    label: '責任者登録/確認ダイアログ',
    tabContent: <UISampleResponsiblePersonRegistrationDialogs />,
  },
  {
    label: 'シンプルリストビューダイアログ',
    tabContent: <UISampleSimpleListDialogs />,
  },
  {
    label: '予定選択ダイアログ',
    tabContent: <UISampleSelectPlanDialogs />,
  },
  {
    label: '選考ダイアログ',
    tabContent: <UISampleEstimateSelectionDialogs />,
  },
  {
    label: '明細登録ダイアログ',
    tabContent: <UISampleDetailInputDialogs />,
  },
  {
    label: '明細確認ダイアログ',
    tabContent: <UISampleDetailConfirmationDialogs />,
  },
  {
    label: '明細内訳登録ダイアログ',
    tabContent: <UISampleEstimateResultDetailRegistrationDialogs />,
  },
  {
    label: '支給品登録ダイアログ',
    tabContent: <UISampleSuppliedRegistrationDialogs />,
  },
  {
    label: '見積基本情報登録ダイアログ',
    tabContent: <UISampleEstimateBasicInfoRegistrationDialogs />,
  },
  {
    label:
      'ファイル追加ダイアログ（追加アップロード、呼び出し元と重複チェックあり）',
    tabContent: <UISampleFileUploadAppendDialogs />,
  },
  {
    label: 'リスト付きファイル追加ダイアログ（重複チェックあり）',
    tabContent: <UISampleFileUploadAppendListDialogs />,
  },
  {
    label: '自社担当ダイアログ',
    tabContent: <UISampleOwnStaffDialogs />,
  },
  {
    label: 'フラグダイアログ',
    tabContent: <UISampleFlagSettingDialogs />,
  },
  {
    label: '通知メール送信対象設定ダイアログ',
    tabContent: <UISampleNotificationSendTargetSettingDialogs />,
  },
];
export const DialogSample = () => {
  return (
    <Container>
      <div className="DialogSample">
        <Tabs tabItems={tabData} />
      </div>
      <Toast />
    </Container>
  );
};
