import { useState } from 'react';
import { LoadingIcon } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { DocumentRegistrationDialog } from '~/shared/components/ui/Dialog/DocumentRegistrationDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';

export const UISampleDocumentRegistrationDialogs = () => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [isShowDialog, setShowDialog] = useState(false);
  const [resultConfirm, setResultConfirm] = useState('');
  const [isShowConfirmDialog, setShowConfirmDialog] = useState(false);
  const [businessUnitDocument, setBusinessUnitDocument] =
    useState<mtechnavi.api.company.IBusinessUnitDocument>();

  const sampleBusinessUnitDocument: mtechnavi.api.company.IBusinessUnitDocument =
    {
      businessUnitDocumentId: 'xxxbusinessUnitDocumentId',
      businessUnitManagementId: 'xxxbusinessUnitManagementId',
      category: {
        code: '0',
        systemName: 'B00',
        categoryName: 'A4000010',
        displayNameLang: { ja: '議事録' },
      },
      attachment: {
        assetId: 'xxxassetId',
        category: {
          code: '3',
          systemName: 'B03',
          categoryName: 'A0000016',
          displayNameLang: { ja: 'xxx' },
        },
        filename: 'sample.csv',
        remarks: 'サンプル備考',
        mimeType: 'csv',
        linkType: {
          code: '3',
          systemName: 'B03',
          categoryName: 'A0000017',
          displayNameLang: { ja: 'xxx' },
        },
        attachedAt: Long.fromString('1'),
      },
      updatedProperties: {
        updatedAt: Long.fromString('1'),
        updatedBy: {
          displayName: 'sample name',
          email: 'sample@xxxxmail',
        },
      },
      createdAt: Long.fromString('1'),
      updatedAt: Long.fromString('1'),
      deletedAt: Long.fromString('1'),
    };

  return (
    <div className="categories">
      <h3 is="dialog">文書登録ダイアログ</h3>
      <h3 is="dialog">保存モード</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setBusinessUnitDocument(undefined);
                setResult('');
                setShowDialog(true);
              }}
            />
            <DocumentRegistrationDialog
              isOpen={isShowDialog}
              inputOption={{
                displayModeType: 'save',
                businessUnitDocument: businessUnitDocument,
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setShowDialog(false);
              }}
              onSave={(v) => {
                setBusinessUnitDocument(v.businessUnitDocument);
                setResult(
                  `出力しました。${JSON.stringify(
                    v,
                    function (key, value) {
                      if (this[key] instanceof Date) {
                        return this[key].toString();
                      }
                      return value;
                    },
                    2
                  )}`
                );
                setShowDialog(false);
              }}
              onEdit={() => {}}
              onDelete={() => {}}
              onChangeLoadingState={(isLoading) => setLoading(isLoading)}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
      <h3 is="dialog">表示モード</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResultConfirm('');
                setShowConfirmDialog(true);
              }}
            />
            <DocumentRegistrationDialog
              isOpen={isShowConfirmDialog}
              inputOption={{
                displayModeType: 'display',
                businessUnitDocument: sampleBusinessUnitDocument,
              }}
              onCancel={() => {
                setResultConfirm('キャンセルしました');
                setShowConfirmDialog(false);
              }}
              onSave={() => {}}
              onEdit={() => {
                setResult('');
                setShowConfirmDialog(false);
                setBusinessUnitDocument(sampleBusinessUnitDocument);
                setShowDialog(true);
              }}
              onDelete={() => {
                setBusinessUnitDocument(undefined);
                setResultConfirm('削除しました');
                setShowConfirmDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{resultConfirm}</pre>
          </div>
        </div>
      </div>
      {isLoading && <LoadingIcon />}
    </div>
  );
};
