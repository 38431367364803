import { useIntl } from 'react-intl';
import { GetMessageWithIntl, MessageProps } from '../../parts/Message/Message';
import { CaptionButton } from '../Button/CaptionButton';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { useEffect, useRef, useState } from 'react';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
  FileUploaderValidateOption,
} from '../../file';
import { error } from '../../parts/Toast/Toast';
import { Textbox } from '../Textbox';
import { AttachmentFile } from './CommonDialogTypes';

type CategorySystemCode = 'B01' | 'B02' | 'B03';
type LinkTypeSystemName = 'B01' | 'B02' | 'B03';

export interface FileMemoDialogMessageOption {
  dialogTitle: MessageProps;
  memoLabelId?: string;
  buttonType: MessageProps;
}

export interface FileMemoDialogFormOption {
  memo?: string;
  files?: AttachmentFile[];
  categorySystemName?: CategorySystemCode;
  linkTypeSystemName?: LinkTypeSystemName;
}

export interface FileMemoDialogDisplayOption {
  isDnd?: boolean;
  isMultiple?: boolean;
}

export interface FileMemoDialogUploaderOption {
  validateOption?: FileUploaderValidateOption;
}

export interface FileUploadMemoDialogProps {
  uploaderOption: FileMemoDialogUploaderOption;
  messageOption: FileMemoDialogMessageOption;
  inputOption: FileMemoDialogFormOption;
  displayOption?: FileMemoDialogDisplayOption;
  isOpen: boolean;
  onDecision: (v: FileMemoDialogFormOption) => void;
  onCancel: () => void;
}

export const FileUploadWithMemoDialog = ({
  isOpen,
  uploaderOption,
  inputOption,
  messageOption,
  displayOption,
  onDecision,
  onCancel,
}: FileUploadMemoDialogProps) => {
  const intl = useIntl();
  const isMultiple = displayOption?.isMultiple ?? false;
  const isDnd = displayOption?.isDnd ?? false;
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);

  const [isLoading, setLoading] = useState(false);
  const [isClickable, setClickable] = useState(false);
  const [isErrorContain, setErrorContain] = useState(false);
  const [memo, setMemo] = useState<string>('');

  const handleDecision = () => {
    setClickable(false);
    if (isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }

    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      inputOption.categorySystemName ?? 'B03'
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      inputOption.linkTypeSystemName ?? 'B03'
    );
    const uploadedFile: AttachmentFile[] = tempFileList.map((file) => {
      return {
        id: `${file.file.name ?? ''}`,
        category: category.length > 0 ? category[0] : {},
        assetId: '',
        filename: `${file.file.name ?? ''}`,
        url: file.url,
        mimeType: '',
        remarks: memo,
        linkType: linkType.length > 0 ? linkType[0] : {},
      };
    });
    onDecision({
      memo: memo,
      files: uploadedFile,
    });
  };

  useEffect(() => {
    setClickable(true);
  }, [tempFileList]);

  useEffect(() => {
    setTempFileList([]);
    setMemo('');
  }, [isOpen, inputOption.memo]);

  const handleUploaded = (fileList: FileItem[]) => {
    setErrorContain(fileList.some((item) => item.status !== 'OK'));
    setTempFileList(fileList);
  };

  const elements = (
    <div className="file-upload-with-comment-dialog">
      <div className="contents-box">
        <div className="file-uploader-area">
          <ExtendFileUploader
            name="fileUploader"
            ref={uploaderRef}
            dndOption={{
              enabled: isDnd,
            }}
            multiple={isMultiple}
            validateOption={uploaderOption.validateOption}
            resultOption={{
              omitFooter: true,
            }}
            onUpload={handleUploaded}
            onChangeLoadingState={setLoading}
          />

          <div className="memo-area">
            <div></div>
            <Textbox
              name="memo"
              type="text"
              value={memo}
              labelId={messageOption.memoLabelId}
              columns={['memo']}
              onChangeState={setMemo}
            />
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => onCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            disabled={isLoading || tempFileList.length <= 0 || !isClickable}
            caption={GetMessageWithIntl(intl, {
              id: messageOption.buttonType.id,
            })}
            onClick={() => {
              handleDecision();
            }}
          />
        </div>
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: messageOption.dialogTitle.id,
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
