import { CSSProperties } from 'react';
import { ShapeProps } from './ShapeProps';
import './Rect.css';

export const SHAPE_TYPE_RECT = 'Rectangle';

export const Rect = ({ protArea, isActive, onClick }: ShapeProps) => {
  const rangeStyle: CSSProperties = {
    top: protArea.y1 < protArea.y2 ? protArea.y1 : protArea.y2,
    left: protArea.x1 < protArea.x2 ? protArea.x1 : protArea.x2,
    width: Math.abs(protArea.x2 - protArea.x1),
    height: Math.abs(protArea.y2 - protArea.y1),
  };
  return (
    <div
      className={`Rect ${isActive ? 'active' : ''}`}
      style={rangeStyle}
      onClick={onClick}
    ></div>
  );
};
