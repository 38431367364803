import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { ChangeNotificationDialog } from '~/shared/components/ui/Dialog/ChangeNotificationDialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sampleChangeNotification: any[] = new Array(15)
  .fill(null)
  .map((v, i) => ({
    changeNotificationId: i,
    targetKey: '-',
    targetFieldPath: `項目${i + 1}`,
    oldValue: `項目A${i + 1}`,
    newValue: `項目B${i + 1}テスト太郎(test_developer_1111@xxxxx.xxx)`,
    remarks: `${i + 1}XXXXも確認が必要です。`,
    notifiedAt: i,
  }))
  .reverse();

export const UISampleChangeNotificationDialogs = () => {
  const [isShow, setShow] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">更新通知ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShow(true);
              }}
            />
            <ChangeNotificationDialog
              isOpen={isShow}
              inputOption={{
                data: sampleChangeNotification,
                keyColumn: 'changeNotificationId',
                columns: [
                  {
                    propertyName: 'targetKey',
                    width: '12rem',
                    header: {
                      prefixId: 'ChangeNotificationDialog',
                      id: 'targetKey',
                    },
                  },
                  {
                    propertyName: 'targetFieldPath',
                    width: '12rem',
                    header: {
                      prefixId: 'ChangeNotificationDialog',
                      id: 'targetFieldPath',
                    },
                  },
                  {
                    propertyName: 'oldValue',
                    width: '12rem',
                    header: {
                      prefixId: 'ChangeNotificationDialog',
                      id: 'oldValue',
                    },
                  },
                  {
                    propertyName: 'newValue',
                    width: '12rem',
                    header: {
                      prefixId: 'ChangeNotificationDialog',
                      id: 'newValue',
                    },
                  },
                  {
                    propertyName: 'remarks',
                    width: '12rem',
                    header: {
                      prefixId: 'ChangeNotificationDialog',
                      id: 'remarks',
                    },
                  },
                ],
              }}
              onCancel={() => {
                setShow(false);
              }}
              onDecision={() => {
                setShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output"></pre>
          </div>
        </div>
      </div>
    </div>
  );
};
