import { useState, useEffect } from 'react';
import './SimplePageNavigation.css';
import { IconButton } from '../Button';

interface PageInfo {
  isFirstPage: boolean;
  isMaxPage: boolean;
  pageNumber: number;
}
export interface SimplePageNavigationProps {
  className?: string;
  handleMovePage: (n: number) => void;
  pageInfo: PageInfo;
}

export function SimplePageNavigation(props: SimplePageNavigationProps) {
  const [isFirstPage, setFirstPage] = useState(true);
  const [isMaxPage, setMaxPage] = useState(false);

  useEffect(() => {
    setFirstPage(props.pageInfo.isFirstPage);
    setMaxPage(props.pageInfo.isMaxPage);
  }, [props.pageInfo.isFirstPage, props.pageInfo.isMaxPage]);

  const className = props.className ?? '';
  return (
    <div className={`SimplePageNavigation ${className}`}>
      <IconButton
        name="prevIcon"
        className="btn btn-normal"
        properties={[
          {
            name: 'prevIcon',
            propertyName: 'prevIcon',
            propertyValue: 'prevIcon',
          },
        ]}
        onClick={() => props.handleMovePage(props.pageInfo.pageNumber - 1)}
        iconType="prev"
        buttonType="basic"
        disabled={isFirstPage}
      />
      <div className="space"></div>
      <IconButton
        name="nextIcon"
        className="btn btn-normal"
        properties={[
          {
            name: 'nextIcon',
            propertyName: 'nextIcon',
            propertyValue: 'nextIcon',
          },
        ]}
        onClick={() => props.handleMovePage(props.pageInfo.pageNumber + 1)}
        iconType="next"
        buttonType="basic"
        disabled={isMaxPage}
      />
    </div>
  );
}
