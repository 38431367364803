import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '../..';
import { useCallback, useState } from 'react';
import './SelectPlanDialog.css';
import { ListColumnSetting, SimpleListView } from '../SimpleListView';
import { CaptionButton } from '../Button';

const DialogTitle = 'selectScheduleDialog';
const lsitViewHeaderInfo: ListColumnSetting[] = [
  {
    header: {
      id: 'trackingCode',
      prefixId: DialogTitle,
    },
    propertyName: 'trackingCode',
    width: '10rem',
  },
  {
    header: {
      id: 'desiredReceiveDt',
      prefixId: DialogTitle,
    },
    propertyName: 'desiredReceiveDt',
    width: '8rem',
  },
  {
    header: {
      id: 'displayName',
      prefixId: DialogTitle,
    },
    propertyName: 'displayName',
    width: '14rem',
  },
  {
    header: {
      id: 'modelNumber',
      prefixId: DialogTitle,
    },
    propertyName: 'modelNumber',
    width: '10rem',
  },
  {
    header: {
      id: 'quantityNumber',
      prefixId: DialogTitle,
    },
    propertyName: 'quantityNumber',
    width: '6rem',
  },
];

export interface DataViewType {
  id: string;
  trackingCode: string;
  desiredReceiveDt: string;
  displayName: string;
  modelNumber: string;
  quantityNumber: string;
}

export interface SelectPlanDialogProps {
  isOpen: boolean;
  data: DataViewType[];
  onDecision: (resultCode: string) => void;
  onCancel: () => void;
}
export const SelectPlanDialog = (props: SelectPlanDialogProps) => {
  const intl = useIntl();
  const [simpleListViewState, setSimpleListViewState] = useState<string[]>([]);

  const handleCheckBoxChangeState = useCallback((state: string[]) => {
    setSimpleListViewState(state);
  }, []);

  const handleCancel = () => {
    props.onCancel();
  };

  const handleDecision = () => {
    if (simpleListViewState.length === 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
      return;
    }
    if (simpleListViewState.length > 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
      return;
    }
    props.onDecision(simpleListViewState[0]);
  };

  const elements = (
    <div className="select-schedule-dialog">
      <div className="discription">
        <p>
          {GetMessageWithIntl(intl, {
            id: 'description',
            prefixId: 'selectScheduleDialog',
          })}
        </p>
      </div>
      <div className="view">
        <SimpleListView
          data={props.data}
          actionOptions={{}}
          viewOptions={{
            columns: lsitViewHeaderInfo,
            omitFooter: true,
            keyColumn: 'id',
            isVisibleCheckBox: true,
            previewRowCount: 5,
          }}
          onChangeState={handleCheckBoxChangeState}
        />
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => handleCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'decision' })}
          onClick={() => handleDecision()}
        />
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'selectSchedule',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="SelectScheduleDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
