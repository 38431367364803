import React, { useCallback } from 'react';
import { Arrow, SHAPE_TYPE_ARROW } from './Shapes/Arrow';
import { Rect, SHAPE_TYPE_RECT } from './Shapes/Rect';
import {
  viewRatioToPixel,
  ShapeInfo,
  useCommentPane,
  useCommentPaneDispatch,
} from './CommentPaneProvider';

import './Selections.css';

export const Selections = () => {
  const { viewerInfo, selection, draft, threadSet } = useCommentPane();
  const commentPaneDispatch = useCommentPaneDispatch();

  const handleClickShape = (threadId?: string | null) => {
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId,
    });
  };

  const renderCurrentShape = (
    shape: ShapeInfo,
    isActive: boolean,
    threadId?: string | null
  ) => {
    if (
      Math.abs(shape.x2 - shape.x1) < 1 &&
      Math.abs(shape.y2 - shape.y1) < 1
    ) {
      return null;
    }
    return renderThreadShape(shape, isActive, threadId);
  };

  const renderThreadShape = (
    shape: ShapeInfo,
    isActive: boolean,
    threadId?: string | null
  ) => {
    switch (shape.attributes.selectionShape) {
      case SHAPE_TYPE_RECT:
        return (
          <Rect
            protArea={shape}
            isActive={isActive}
            onClick={() => handleClickShape(threadId)}
          />
        );
      case SHAPE_TYPE_ARROW:
        return (
          <Arrow
            protArea={shape}
            isActive={isActive}
            onClick={() => handleClickShape(threadId)}
          />
        );
      default:
        return null;
    }
  };

  const realValueShape = useCallback(
    (relativeShape: ShapeInfo): ShapeInfo => {
      return {
        ...relativeShape,
        x1: viewRatioToPixel(relativeShape.x1, viewerInfo.width),
        y1: viewRatioToPixel(relativeShape.y1, viewerInfo.height),
        x2: viewRatioToPixel(relativeShape.x2, viewerInfo.width),
        y2: viewRatioToPixel(relativeShape.y2, viewerInfo.height),
      };
    },
    [viewerInfo.width, viewerInfo.height]
  );

  return (
    <div
      className={`Selections ${viewerInfo.isDragging ? 'dragging' : ''}`}
      style={{
        transform: `scale(${viewerInfo.scale}) translate(${viewerInfo.offset.x}px, ${viewerInfo.offset.y}px)`,
        width: '100%',
        height: '100%',
      }}
    >
      {threadSet.threadList.map((thread) => (
        <React.Fragment key={thread.threadId}>
          {thread.attachedPoint &&
            renderThreadShape(
              realValueShape({
                x1: thread.attachedPoint.x1 ?? 0,
                y1: thread.attachedPoint.y1 ?? 0,
                x2: thread.attachedPoint.x2 ?? 0,
                y2: thread.attachedPoint.y2 ?? 0,
                attributes: {
                  selectionShape:
                    thread.attachedPoint.attributes?.selectionShape || '',
                },
              }),
              thread.threadId === threadSet.activeThreadId,
              thread.threadId
            )}
        </React.Fragment>
      ))}
      {draft && renderCurrentShape(realValueShape(draft), true)}
      {renderCurrentShape(realValueShape(selection), true)}
    </div>
  );
};
