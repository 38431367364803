import { useState } from 'react';
import { Textarea } from '../../Textarea';
import { Textbox } from '../../Textbox';
import { IconButton } from '../../Button';
import { FormInputImportDialog } from '../../Dialog';
import './FormInputInformation.css';
import { FileItem } from '~/shared/components/file';
import { WorkFormTypes } from '../utils';

interface FormInformationProps<T> {
  formInformation: T;
  onClickDownload?: () => void | Promise<void>;
  onClickImport?: (result: FileItem[]) => void | Promise<void>;
}

export const FormInputInformation = <T extends WorkFormTypes>(
  props: FormInformationProps<T>
) => {
  const [isOpenImportDialog, setOpenImportDialog] = useState(false);

  const handleImportdialog = async (result: FileItem[]) => {
    if (!props.onClickImport) {
      return;
    }
    await props.onClickImport(result);
    setOpenImportDialog(false);
  };

  return (
    <>
      <div className="formInputInformation">
        <div className="header-icon-area">
          <div className="input-line headerIcons">
            {props.onClickDownload && (
              <IconButton
                name=""
                iconType="export"
                buttonType="basic"
                onClick={() => props.onClickDownload && props.onClickDownload()}
              />
            )}
            {props.onClickImport && (
              <IconButton
                name=""
                iconType="upload"
                buttonType="basic"
                onClick={() => setOpenImportDialog(true)}
              />
            )}
          </div>
        </div>
        <div className="form-name">{props.formInformation.displayName}</div>
        <div className="information-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-66">
                <Textarea
                  labelId="BUILDED_FORM_INPUT_DIALOG.formInformation.notice"
                  name="notice"
                  className="w-100 notice"
                  columns={['notice']}
                  value={props.formInformation?.notice ?? ''}
                  disabled={true}
                />
              </div>
              <div className="w-33">
                <Textbox
                  labelId="BUILDED_FORM_INPUT_DIALOG.formInformation.attribute1"
                  name="attribute1"
                  type="text"
                  columns={['attribute1']}
                  value={props.formInformation?.attribute1 ?? ''}
                  disabled={true}
                />
                <Textbox
                  labelId="BUILDED_FORM_INPUT_DIALOG.formInformation.attribute2"
                  name="attribute2"
                  type="text"
                  columns={['attribute2']}
                  value={props.formInformation?.attribute2 ?? ''}
                  disabled={true}
                />
                <Textbox
                  labelId="BUILDED_FORM_INPUT_DIALOG.formInformation.attribute3"
                  name="attribute3"
                  type="text"
                  columns={['attribute3']}
                  value={props.formInformation?.attribute3 ?? ''}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FormInputImportDialog">
        <FormInputImportDialog
          isOpen={isOpenImportDialog}
          onDecision={(result) => {
            handleImportdialog(result);
          }}
          onCancel={() => {
            setOpenImportDialog(false);
          }}
        />
      </div>
    </>
  );
};
