import { useState } from 'react';
import { Container, Toast } from '~/shared/components';
import { SearchMenuListViewDialog } from '~/shared/components/ui/SearchListViewDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

import { Schema, Textbox, getAltDisplaySchema } from '~/shared/components/ui';
import './MasterPostUnitSearch.css';
import { ViewId } from '~/shared/utils';
import { IconButton } from '~/shared/components/ui/Button/IconButton';

const VIEW_ID: ViewId = 'MASTER_POST_UNIT_SEARCH';

export type ViewItems =
  mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit[];

export function MasterPostUnitSearch() {
  const [limitNumber, setLimitNumber] = useState(0);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const handleClose = () => {
    setModalIsOpen(!baseModalIsOpen);
  };
  const isSelectAction = (items: ViewItems) => {
    let text = '';
    items.forEach((item) => {
      text = `${text}${
        text === '' ? '' : ','
      }${`${item.company?.displayNameLang?.ja}(${item.componentUnit?.postUnit?.displayNameLang?.ja})`}`;
    });
    setSearchTextValue(text);
    setModalIsOpen(!baseModalIsOpen);
  };

  const handleFormatter = (schema: Schema) => {
    // ja表示のみする処理
    const jaColumn = [
      'company.displayNameLang',
      'componentUnit.displayNameLang',
      'postUnit.postUnitType.displayNameLang',
    ];

    return getAltDisplaySchema(schema, jaColumn, 'ja');
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="MasterPostUnitSearch">
        <div className="search-text-box">
          <Textbox
            name="client"
            className="field"
            type="text"
            value={searchTextValue}
            labelId="client"
            onChangeState={() => {}}
            columns={['client']}
          />
          <div className="btn-box">
            <IconButton
              name="prevIcon"
              className="btn btn-basic"
              properties={[
                {
                  name: 'prevIcon',
                  propertyName: 'prevIcon',
                  propertyValue: 'prevIcon',
                },
              ]}
              onClick={handleClose}
              buttonType="basic"
              iconType="search"
            />
          </div>
        </div>
        {/* demo */}
        <div className="limit">
          選択個数の制限
          <input
            type="number"
            value={limitNumber}
            onChange={(e) => {
              setLimitNumber(Number(e.target.value));
            }}
          />
        </div>
        <SearchMenuListViewDialog
          viewId={VIEW_ID}
          fullMethodName={
            'FullMethodName' // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
          }
          isOpen={baseModalIsOpen}
          headerLabelId={{
            prefixId: 'DIALOG_TITLE',
            viewId: VIEW_ID,
          }}
          selectLimit={limitNumber}
          onCloseChange={() => handleClose()}
          onSelectChange={(items) =>
            isSelectAction(items as unknown as ViewItems)
          }
          onHandleFormatSchema={handleFormatter}
        />
      </div>
      <Toast />
    </Container>
  );
}
