import {
  INamespace,
  Method,
  ReflectionObject,
  Root,
  Service,
  Type,
} from 'protobufjs/light';
import protoJSON from './generated.schema.json';

const $root = Root.fromJSON(protoJSON as INamespace);
$root.resolveAll();
if (!!this) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (this as any).root = $root;
}

interface Object_ {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

function isObject(v: unknown): v is Object_ {
  return typeof v === 'object' && !Array.isArray(v);
}

interface nestedArrayAware {
  nestedArray: Array<ReflectionObject>;
}

function isNestedArrayAware(v: unknown): v is nestedArrayAware {
  return isObject(v) && 'nestedArray' in v;
}

function walk(ns: ReflectionObject): Array<ReflectionObject> {
  const l = [];
  if (isNestedArrayAware(ns)) {
    for (const v of ns.nestedArray) {
      l.push(...walk(v));
    }
  }
  l.push(ns);
  return l;
}

export namespace Catalog {
  export function services(): Array<Service> {
    return walk($root)
      .filter((v) => v instanceof Service)
      .map((v) => v as Service);
  }

  export function lookupService(fullServiceName: string): Service {
    return $root.lookupService(fullServiceName);
  }

  export function lookupType(fullTypeName: string): Type {
    return $root.lookupType(fullTypeName);
  }

  export function lookupMethod(fullMethodName: string): Method {
    const [fullServiceName, methodName] = fullMethodName.split('/', 2);
    const $service = lookupService(fullServiceName);
    return $service.methods[methodName];
  }
}
