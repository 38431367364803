import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
  error,
} from '../..';
import { CaptionButton } from '../Button';
import { useIntl } from 'react-intl';
import {
  DataFilterbox,
  DataFilterboxItem,
  Filterbox,
  FilterboxItem,
} from '../Filterbox';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_SharedListBusinessUnitBranchs,
  RequireInput,
  convertLongToString,
  convertStringToLong,
  getExceptionMessage,
  getProgramOptionFilterboxData,
  includeInputValidateError,
} from '~/shared/utils';
import { Textarea, Textbox } from '..';
import './BusinessUnitFacilityDialog.css';
import { validateOnlyNumberWithComma } from '~/shared/components/parts/validator';
import { CommaTypeFormatter } from '~/shared/components/parts/formatter';

export interface BusinessUnitFacilityFormOption {
  buFacilities?: mtechnavi.api.company.IBusinessUnitFacilities | null;
  companyId: string;
  isShared?: boolean;
}

export interface BusinessUnitFacilityDialogProps {
  isOpen: boolean;
  inputMode: boolean;
  inputOption: BusinessUnitFacilityFormOption;
  onCancel: () => void;
  onDecision: (result: mtechnavi.api.company.IBusinessUnitFacilities) => void;
}

export const facilitySizeUnitValues = ['sizeZ', 'sizeT', 'diameter'];

export const BusinessUnitFacilityDialog = (
  props: BusinessUnitFacilityDialogProps
) => {
  const intl = useIntl();

  const branchItemType = {
    value: 'businessUnitBranchId',
    displayName: 'displayName',
  };

  const FACILITY_CATEGORY_NAME = 'A4000003';
  const ACCURACY_CATEGORY_NAME = 'A4000004';
  const PROCESSABLE_MATERIALS_CATEGORY_NAME = 'A4000005';

  const radios: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'sizeZ',
        prefixId: 'BusinessUnitFacilityDialog',
      }),
      value: facilitySizeUnitValues[0],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'sizeT',
        prefixId: 'BusinessUnitFacilityDialog',
      }),
      value: facilitySizeUnitValues[1],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'diameter',
        prefixId: 'BusinessUnitFacilityDialog',
      }),
      value: facilitySizeUnitValues[2],
    },
  ];

  const { inputOption } = props;
  const [businessUnitBranch, setBusinessUnitBranch] = useState<FilterboxItem[]>(
    []
  );
  const [categorys, setCategorys] = useState<DataFilterboxItem[]>([]);
  const [manufacture, setManufacture] = useState<string>('');
  const [modelNumber, setModelNumber] = useState<string>('');
  const [assetsAmount, setAssetAmount] = useState<string>('');
  const [accuracy, setAccuracy] = useState<DataFilterboxItem[]>([]);
  const [processableTon, setProcessableTon] = useState<string>('');
  const [sizeX, setSizeX] = useState<string>('');
  const [sizeY, setSizeY] = useState<string>('');
  const [sizeMM, setSizeMM] = useState<string>('');
  const [unit, setUnit] = useState<string>('');
  const [processableMaterial, setProcessableMaterial] = useState<
    DataFilterboxItem[]
  >([]);
  const [remarks, setRemarks] = useState<string>('');
  const [isDisabled, setDisabled] = useState(false);

  const [facilityCategorys, accuracyList, processableMaterials] =
    useMemo(() => {
      const facilityCategorys = getProgramOptionFilterboxData(
        FACILITY_CATEGORY_NAME
      );
      const accuracyList = getProgramOptionFilterboxData(
        ACCURACY_CATEGORY_NAME
      );
      const processableMaterials = getProgramOptionFilterboxData(
        PROCESSABLE_MATERIALS_CATEGORY_NAME
      );
      return [facilityCategorys, accuracyList, processableMaterials];
    }, []);

  // 入力チェック用
  const [workingBlurCategorys, setWorkingBlurCategorys] = useState<Date>();
  const requiredCategoriesArea = useRef(null);
  const [workingBlurBranch, setWorkingBlurBranch] = useState<Date>();
  const requiredbranchArea = useRef(null);
  const [workingBlurUnit, setWorkingBlurUnit] = useState<Date>();
  const requiredUnitArea = useRef(null);

  useEffect(() => {
    setDisabled(!props.inputMode);
    (async () => {
      try {
        const businessUnitbranchId =
          inputOption?.buFacilities?.businessUnitBranchId ?? '';
        const businessUnitBranchs = (await window.App.services.ui.worker.filter(
          {
            action: 'query',
            fullMethodName: inputOption?.isShared
              ? FullMethodName_SharedListBusinessUnitBranchs
              : FullMethodName_ListBusinessUnitBranchs,
            filter: {
              businessUnitBranchId: { $eq: businessUnitbranchId },
            },
            sort: [],
          }
        )) as mtechnavi.api.company.ListBusinessUnitBranchsResponse;
        const businessUnitBranchRes = businessUnitBranchs.items[0];
        if (businessUnitBranchRes) {
          setBusinessUnitBranch([
            {
              value: businessUnitBranchRes.businessUnitBranchId ?? '',
              displayName: businessUnitBranchRes.displayName ?? '',
            },
          ]);
        } else {
          setBusinessUnitBranch([]);
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
      }
    })();

    // 入力モードではない場合、渡されたデータをそのまま表示する
    // Shareされた側ではcodeがshareされないことも考慮
    if (!props.inputMode ?? false) {
      setCategorys(
        inputOption?.buFacilities?.categorys?.map((v) => {
          return {
            value: v.code ?? '', //空になる場合があるが、表示のみのため許容
            displayName: v.displayNameLang?.ja ?? '',
          };
        }) ?? []
      );
      setAccuracy([
        {
          value: inputOption?.buFacilities?.accuracy?.code ?? '', //空になる場合があるが、表示のみのため許容
          displayName:
            inputOption?.buFacilities?.accuracy?.displayNameLang?.ja ?? '',
        },
      ]);
      setProcessableMaterial([
        {
          value: inputOption?.buFacilities?.processableMaterial?.code ?? '', //空になる場合があるが、表示のみのため許容
          displayName:
            inputOption?.buFacilities?.processableMaterial?.displayNameLang
              ?.ja ?? '',
        },
      ]);
    } else {
      const inputOpCategories = inputOption?.buFacilities?.categorys ?? [];
      const dataBoxFiliterItems =
        inputOpCategories.length > 0
          ? facilityCategorys.filter((value) => {
              if (inputOpCategories.some((v) => v.code === value.value)) {
                return value;
              }
            })
          : [];
      setCategorys(dataBoxFiliterItems);
      setAccuracy(
        inputOption?.buFacilities?.accuracy
          ? accuracyList.filter(
              (v) => v.value === inputOption.buFacilities?.accuracy?.code
            )
          : []
      );
      setProcessableMaterial(
        inputOption?.buFacilities?.processableMaterial
          ? processableMaterials.filter(
              (v) =>
                v.value === inputOption.buFacilities?.processableMaterial?.code
            )
          : []
      );
    }
    setManufacture(inputOption?.buFacilities?.manufacture ?? '');
    setModelNumber(inputOption?.buFacilities?.modelNumber ?? '');
    setProcessableTon(
      convertLongToString(inputOption.buFacilities?.processableTon)
    );
    setAssetAmount(convertLongToString(inputOption.buFacilities?.assetsAmount));
    setSizeX(convertLongToString(inputOption.buFacilities?.sizeX));
    setSizeY(convertLongToString(inputOption.buFacilities?.sizeY));
    setUnitTypeAndSize();
    setRemarks(inputOption?.buFacilities?.remarks ?? '');

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption]);

  const setUnitTypeAndSize = () => {
    if (inputOption?.buFacilities?.sizeZ) {
      setUnit(facilitySizeUnitValues[0]);
      setSizeMM(convertLongToString(inputOption.buFacilities.sizeZ));
    }
    if (inputOption?.buFacilities?.sizeT) {
      setUnit(facilitySizeUnitValues[1]);
      setSizeMM(convertLongToString(inputOption.buFacilities.sizeT));
    }
    if (inputOption?.buFacilities?.diameter) {
      setUnit(facilitySizeUnitValues[2]);
      setSizeMM(convertLongToString(inputOption.buFacilities.diameter));
    }
  };

  const handleCancel = () => {
    props.onCancel();
    formReset();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const resultCategorys: sharelib.INameOption[] = [];
    categorys.map((category) => {
      const resultCategory = window.App.services.ui.getNameOptionWithCode(
        'A4000003',
        category.value ?? ''
      );
      resultCategorys.push(resultCategory);
    });
    const resultAccuracy =
      accuracy.length > 0
        ? window.App.services.ui.getNameOptionWithCode(
            'A4000004',
            accuracy[0].value ?? ''
          )
        : null;
    const resultProcessablematerial =
      processableMaterial.length > 0
        ? window.App.services.ui.getNameOptionWithCode(
            'A4000005',
            processableMaterial[0].value ?? ''
          )
        : null;
    const resultSizeMM = convertStringToLong(sizeMM);

    const result: mtechnavi.api.company.IBusinessUnitFacilities = {
      businessUnitFacilitiesId:
        inputOption.buFacilities?.businessUnitFacilitiesId ?? null,
      companyId: inputOption.companyId,
      businessUnitBranchId:
        businessUnitBranch.length > 0 ? businessUnitBranch[0].value : '',
      categorys: resultCategorys,
      manufacture,
      modelNumber,
      assetsAmount: convertStringToLong(assetsAmount),
      processableTon: convertStringToLong(processableTon),
      accuracy: resultAccuracy,
      sizeX: convertStringToLong(sizeX),
      sizeY: convertStringToLong(sizeY),
      sizeZ: unit === facilitySizeUnitValues[0] ? resultSizeMM : null,
      sizeT: unit === facilitySizeUnitValues[1] ? resultSizeMM : null,
      diameter: unit === facilitySizeUnitValues[2] ? resultSizeMM : null,
      processableMaterial: resultProcessablematerial,
      remarks,
    };
    props.onDecision(result);
    formReset();
  };

  const isInputError = () => {
    const targetElm = document.querySelector('.business-unit-facility-dialog');

    setWorkingBlurBranch(new Date());
    setWorkingBlurCategorys(new Date());
    setWorkingBlurUnit(new Date());

    const branchValue =
      businessUnitBranch.length > 0 ? businessUnitBranch[0].value : '';
    const categoryValue = categorys.length > 0 ? categorys[0].value : '';
    const unitValue = unit ?? '';
    const validateErrorList: RequireInput[] = [];
    validateErrorList.push({
      value: categoryValue,
      ref: requiredCategoriesArea,
    });
    validateErrorList.push({
      value: branchValue,
      ref: requiredbranchArea,
    });
    if (sizeMM) {
      validateErrorList.push({
        value: unitValue,
        ref: requiredUnitArea,
      });
    }

    if (includeInputValidateError(targetElm, intl, validateErrorList)) {
      return true;
    }

    return false;
  };

  const formReset = () => {
    setBusinessUnitBranch([]);
    setCategorys([]);
    setManufacture('');
    setModelNumber('');
    setAssetAmount('');
    setAccuracy([]);
    setProcessableTon('');
    setSizeX('');
    setSizeY('');
    setSizeMM('');
    setUnit('');
    setProcessableMaterial([]);
    setRemarks('');
    setWorkingBlurBranch(undefined);
    setWorkingBlurCategorys(undefined);
    setWorkingBlurUnit(undefined);
  };

  // SizeMMの値が変更（クリア）されると、ラジオボタンも値もクリアされる
  useEffect(() => {
    if (sizeMM) {
      return;
    }
    setUnit('');
  }, [sizeMM]);

  const elements = (
    <div className="business-unit-facility-dialog">
      <div className="detail-area">
        <div className="contents-box">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                {/* 設備拠点 */}
                <Filterbox
                  name="businessUnitBranch"
                  fullMethodName={
                    inputOption?.isShared
                      ? FullMethodName_SharedListBusinessUnitBranchs
                      : FullMethodName_ListBusinessUnitBranchs
                  }
                  itemType={branchItemType}
                  columns={['businessUnitBranch']}
                  labelId="BusinessUnitFacilityDialog.businessUnitBranch"
                  validateOption={{ required: true }}
                  value={businessUnitBranch}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: {
                      companyId: { $eq: inputOption?.companyId ?? '' },
                    },
                  }}
                  workingBlur={workingBlurBranch}
                  disabled={isDisabled}
                  onChangeState={setBusinessUnitBranch}
                ></Filterbox>
              </div>
              <div className="w-50">
                {/* 設備分類 */}
                <DataFilterbox
                  data={facilityCategorys}
                  labelId="BusinessUnitFacilityDialog.categorys"
                  searchOption={{ targets: 'displayName' }}
                  name="categorys"
                  columns={['categorys']}
                  onChangeState={setCategorys}
                  validateOption={{ required: true }}
                  multiple={true}
                  value={categorys}
                  workingBlur={workingBlurCategorys}
                  disabled={isDisabled}
                ></DataFilterbox>
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                {/* メーカー */}
                <Textbox
                  name="manufacture"
                  type="text"
                  labelId="BusinessUnitFacilityDialog.manufacture"
                  columns={['manufacture']}
                  value={manufacture}
                  onChangeState={setManufacture}
                  validateOption={{ required: true }}
                  disabled={isDisabled}
                />
              </div>
              <div className="w-50">
                {/* 型式 */}
                <Textbox
                  name="modelNumber"
                  type="text"
                  labelId="BusinessUnitFacilityDialog.modelNumber"
                  columns={['modelNumber']}
                  value={modelNumber}
                  onChangeState={setModelNumber}
                  validateOption={{ required: true }}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-25">
                {/* 台数 */}
                <Textbox
                  name="assetsAmount"
                  type="text"
                  labelId="BusinessUnitFacilityDialog.assetsAmount"
                  className="right"
                  value={assetsAmount}
                  columns={['assetsAmount']}
                  onChangeState={setAssetAmount}
                  validateOption={{ required: true }}
                  validator={validateOnlyNumberWithComma(intl)}
                  disabled={isDisabled}
                  formatter={CommaTypeFormatter}
                />
              </div>
              <div className="w-25">
                {/* 精度 */}
                <DataFilterbox
                  data={accuracyList}
                  labelId="BusinessUnitFacilityDialog.accuracy"
                  searchOption={{ targets: 'displayName' }}
                  name="accuracy"
                  columns={['accuracy']}
                  onChangeState={setAccuracy}
                  value={accuracy}
                  disabled={isDisabled}
                ></DataFilterbox>
              </div>
              <div className="w-25">
                {/* 加工トン数 */}
                <Textbox
                  name="processableTon"
                  type="text"
                  labelId="BusinessUnitFacilityDialog.processableTon"
                  className="right"
                  value={processableTon}
                  columns={['processableTon']}
                  onChangeState={setProcessableTon}
                  validator={validateOnlyNumberWithComma(intl)}
                  disabled={isDisabled}
                  formatter={CommaTypeFormatter}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-25">
                {/* 加工サイズX */}
                <Textbox
                  name="sizeX"
                  type="text"
                  labelId="BusinessUnitFacilityDialog.sizeX"
                  className="right"
                  value={sizeX}
                  columns={['sizeX']}
                  onChangeState={setSizeX}
                  validator={validateOnlyNumberWithComma(intl)}
                  disabled={isDisabled}
                  formatter={CommaTypeFormatter}
                />
              </div>
              <div className="w-25">
                {/* 加工サイズY */}
                <Textbox
                  name="sizeY"
                  type="text"
                  labelId="BusinessUnitFacilityDialog.sizeY"
                  className="right"
                  value={sizeY}
                  columns={['sizeY']}
                  onChangeState={setSizeY}
                  validator={validateOnlyNumberWithComma(intl)}
                  disabled={isDisabled}
                  formatter={CommaTypeFormatter}
                />
              </div>
              <div className="w-50">
                <div className="input-line">
                  <div className="item-group-100 min-space">
                    <div className="w-50">
                      <Textbox
                        name="sizeMM"
                        type="text"
                        labelId="BusinessUnitFacilityDialog.sizeMM"
                        className="right"
                        value={sizeMM}
                        columns={['sizeMM']}
                        onChangeState={setSizeMM}
                        validator={validateOnlyNumberWithComma(intl)}
                        disabled={isDisabled}
                        formatter={CommaTypeFormatter}
                      />
                    </div>
                    <div className="w-66">
                      {/* 加工サイズmmZTφ ラジオ */}
                      <Radio
                        name="unit"
                        className="sizeUnit"
                        value={unit}
                        items={radios}
                        validateOption={{ required: sizeMM ? true : false }}
                        onChangeState={setUnit}
                        disabled={isDisabled}
                        workingBlur={workingBlurUnit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                {/* 加工可能な材料 */}
                <DataFilterbox
                  data={processableMaterials}
                  labelId="BusinessUnitFacilityDialog.processableMaterial"
                  searchOption={{ targets: 'displayName' }}
                  name="processableMaterial"
                  columns={['processableMaterial']}
                  onChangeState={setProcessableMaterial}
                  value={processableMaterial}
                  disabled={isDisabled}
                ></DataFilterbox>
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-75">
                {/* 備考 */}
                <Textarea
                  name="remarks"
                  className="w-100 mh-middle"
                  labelId="BusinessUnitFacilityDialog.remarks"
                  value={remarks}
                  onChangeState={setRemarks}
                  columns={['remarks']}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
          {props.inputMode && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'decision',
              })}
              onClick={handleDecision}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: props.inputMode
        ? 'BUSINESS_UNIT_FACILITY_REGISTRATION'
        : 'BUSINESS_UNIT_FACILITY_CONFIRMATION',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };
  return (
    <div className="BusinessUnitFacilityDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
