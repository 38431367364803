import {
  Container,
  GetMessageWithIntl,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import { GroupTreeEditor } from './GroupTreeEditor';
import { MockOrganization, generateMockData } from './mock';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useEffect, useRef, useState } from 'react';
import { GroupTreeItem } from './GroupTreeEditor/util';
import { convertGroupTree } from './mock';
import {
  OrganizationDetailDialog,
  OrganizationDetailDialogMode,
} from './OrganizationDetailDialog';
import { OrganizationMemberDialog } from './OrganizationMemberDialog';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { ConfirmationDialog } from '~/shared/components/ui';
import { useIntl } from 'react-intl';
import { FullMethodName_ListUserAttributes } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const OrganizationTreePage = () => {
  const auth = useAuth();
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const originData = useRef<MockOrganization[]>([]);
  const [mockData, setMockData] = useState<GroupTreeItem<MockOrganization>[]>(
    []
  );

  // ユーザーマスタ
  const [userList, setUserList] =
    useState<mtechnavi.api.tenantadmin.IUserAttribute[]>();

  const [detailMode, setDetailMode] =
    useState<OrganizationDetailDialogMode>('add');
  const [targetData, setTargetData] = useState<MockOrganization>();
  const [targetParents, setTargetParents] = useState<MockOrganization[]>();
  const [isOpenOrgDetailDialog, setOpenOrgDetailDialog] = useState(false);
  const [isOpenOrgMemberDialog, setOpenOrgMemberDialog] = useState(false);
  const [belongingUserList, setBelongingUserList] =
    useState<mtechnavi.api.tenantadmin.IUserAttribute[]>();

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const companyName = auth.tenant?.displayName || '';

  useEffect(() => {
    showLoading();
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 300));
      const flatData = generateMockData();
      setTreeData(flatData);

      const listRes = (await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListUserAttributes,
        filter: {},
        sort: [],
      })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;
      setUserList(listRes.items);

      hideLoading();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const traceParent = (
    target: MockOrganization | undefined
  ): MockOrganization[] => {
    if (!target || !target.parentOrganizationCode) {
      return [{ displayName: companyName }];
    }
    const parent = originData.current.find(
      (item) => item.organizationCode === target.parentOrganizationCode
    );
    if (!parent) {
      return [{ displayName: companyName }];
    }
    const parents = traceParent(parent);
    return [...parents, parent];
  };

  const isDuplicationCode = (orgCode: string): boolean => {
    return originData.current.some((item) => item.organizationCode === orgCode);
  };

  const setTreeData = (flatData: MockOrganization[]) => {
    originData.current = flatData;
    const treeData = convertGroupTree(flatData);
    setMockData(treeData);
  };

  // 追加
  const handleAddChild = (target: MockOrganization | undefined) => {
    setTargetData(target);
    setTargetParents([
      ...traceParent(target),
      { displayName: target?.displayName },
    ]);
    setDetailMode('add');
    setOpenOrgDetailDialog(true);
  };
  const handleAddChildDecision = (newOrg: MockOrganization) => {
    if (
      !newOrg.organizationCode ||
      isDuplicationCode(newOrg.organizationCode)
    ) {
      error(['使用されている組織コードです']);
      return;
    }
    const flatData = [...originData.current, newOrg];
    setTreeData(flatData);
    setOpenOrgDetailDialog(false);
  };

  // 詳細表示
  const handleDetail = (target: MockOrganization | undefined) => {
    setTargetData(target);
    setTargetParents(traceParent(target));
    setDetailMode('show');
    setOpenOrgDetailDialog(true);
  };

  // 編集
  const handleEdit = (target: MockOrganization | undefined) => {
    setTargetData(target);
    setTargetParents(traceParent(target));
    setDetailMode('edit');
    setOpenOrgDetailDialog(true);
  };
  const handleEditDecision = (editOrg: MockOrganization) => {
    const flatData = [
      ...originData.current.filter(
        (item) => item.organizationCode !== editOrg.organizationCode
      ),
      editOrg,
    ];
    setTreeData(flatData);
    setOpenOrgDetailDialog(false);
  };

  // 削除
  const handleDelete = async (target: MockOrganization | undefined) => {
    const message = {
      id: 'C0000001',
      value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
    };
    if (!(await confirmation(message))) {
      return;
    }
    const flatData = [
      ...originData.current.filter(
        (item) => item.organizationCode !== target?.organizationCode
      ),
    ];
    setTreeData(flatData);
    setTargetData(target);
  };

  // グループ内ユーザ編集
  const handleEditMember = async (target: MockOrganization | undefined) => {
    showLoading();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    hideLoading();
    setBelongingUserList([]);
    setOpenOrgMemberDialog(true);
    setTargetData(target);
  };
  const handleMemberEditDecision = () => {
    setOpenOrgMemberDialog(false);
  };

  const handleDrop = ({
    targetId,
    parentId,
  }: {
    targetId: string;
    parentId: string;
  }) => {
    console.log('handleDrop', targetId, parentId);
    const targetOrg = originData.current.find(
      (item) => item.organizationCode === targetId
    );
    if (targetOrg === undefined) {
      return;
    }
    const flatData: MockOrganization[] = [
      ...originData.current.filter(
        (item) => item.organizationCode !== targetOrg.organizationCode
      ),
      { ...targetOrg, parentOrganizationCode: parentId },
    ];
    setTreeData(flatData);
  };

  // 決定時の処理分岐用
  const orgDetailDecisionMap = {
    add: handleAddChildDecision,
    edit: handleEditDecision,
    show: () => {},
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps) => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise<boolean>((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  return (
    <>
      <Container>
        <div className="OrganizationTreeSamplePage">
          <GroupTreeEditor
            rootLabel={companyName}
            data={mockData}
            editActions={{
              onDetail: handleDetail,
              onEdit: handleEdit,
              onDelete: handleDelete,
              onEditMember: handleEditMember,
              onAddChild: handleAddChild,
            }}
            onDrop={handleDrop}
          />
        </div>
        <Toast />
      </Container>
      {/* 組織編集ダイアログ */}
      <OrganizationDetailDialog
        key={isOpenOrgDetailDialog ? '1' : ''} // 開閉する度に初期化する
        isOpen={isOpenOrgDetailDialog}
        inputOption={{
          mode: detailMode,
          data: targetData,
          parents: targetParents,
        }}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'OrganizationConfirmationDialog',
          },
        }}
        onDecision={orgDetailDecisionMap[detailMode]}
        onCancel={() => setOpenOrgDetailDialog(false)}
      />
      {/* 組織メンバー編集ダイアログ */}
      <OrganizationMemberDialog
        isOpen={isOpenOrgMemberDialog}
        inputOption={{
          userList,
          belongingUserList,
        }}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'MemberEditDialog',
          },
        }}
        onDecision={handleMemberEditDecision}
        onCancel={() => setOpenOrgMemberDialog(false)}
      />
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={() => {
          if (confirmPromiseRef.current) {
            confirmPromiseRef.current(true);
          }
          setOpenConfirmDialog(false);
        }}
        onCancel={() => {
          if (confirmPromiseRef.current) {
            confirmPromiseRef.current(false);
          }
          setOpenConfirmDialog(false);
        }}
      />
    </>
  );
};
