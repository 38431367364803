import {
  FullMethodName_ListBusinessUnits,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListPresetMenus,
  FullMethodName_ListPresets,
  FullMethodName_ListStaffAndComponentUnits,
  FullMethodName_ListStaffs,
} from '../utils/fullMethodName';

/** ログイン時に初期取得する API の FullMethodName */
export const InitialLoadMethods = [
  FullMethodName_ListPresetMenus,
  FullMethodName_ListPresets,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListBusinessUnits,
  FullMethodName_ListStaffs,
  FullMethodName_ListStaffAndComponentUnits,
];
