import { useMemo, useState } from 'react';
import { Checkbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  FormSettingDialog,
  FormSettingDialogResult,
} from '~/shared/components/ui/Dialog/FormSettingDialog';
import { getProgramOptionFilterboxData } from '~/shared/utils';

export const UISampleFormSettingDialogs = () => {
  const [isOpen, setOpen] = useState(false);
  const [result, setResult] = useState<FormSettingDialogResult>();
  const [dialogData, setDialogData] =
    useState<FormSettingDialogResult | null>();
  const [isDisabledRequired, setDisabledRequired] = useState(false);
  const [selectedPurposes, setSelectedPurposes] = useState<string[]>();
  const purposes = useMemo(() => {
    const options = getProgramOptionFilterboxData('A5000004');
    setSelectedPurposes(options.map((item) => item.value));
    return options;
  }, []);

  return (
    <div className="categories">
      <h3 id="dialog">フォーム設定ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Checkbox
              name=""
              labelId="BaseFormInformation.purposes"
              columns={['']}
              value={selectedPurposes}
              items={purposes}
              onChangeState={(v) => {
                setSelectedPurposes(v);
              }}
            />
            <Checkbox
              name=""
              labelId="FormSettingDialog.required"
              columns={['']}
              items={[{ displayName: '入力必須選択不可', value: '1' }]}
              onChangeState={(v) => {
                setDisabledRequired(!!v.length);
              }}
            />
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setDialogData(result && { ...result });
                setOpen(true);
              }}
            />
            <FormSettingDialog
              isOpen={isOpen}
              inputOption={{
                isDisabledRequired,
                dialogData,
                purposes:
                  selectedPurposes && selectedPurposes.length > 0
                    ? selectedPurposes
                    : undefined,
              }}
              messageOption={{
                headerLabelId: {
                  id: 'form-setting',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => {
                setResult(undefined);
                setOpen(false);
              }}
              onDecision={async (result) => {
                setResult(result);
                setOpen(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
