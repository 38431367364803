import { useState } from 'react';
import { DateSuggest, DateY, DateYM } from '~/shared/components/ui';
import { TimeH, TimeHM, TimeHMS } from '~/shared/components/ui/Times';
import {
  validateFreeDate,
  validatePreviousTime,
  validateTime,
} from '~/shared/components/parts/validator';
import { useIntl } from 'react-intl';

const TIME_SELECT_1 = '時間選択1';
const TIME_SELECT_2 = '時間選択2';
const TIME_SELECT_3 = '時間選択3';
const SUGGEST = '日付 サジェスト機能付き';
const YEAR_SELECT = '年選択';
const YM_SELECT = '年月選択';

export const UISampleDateInputs = () => {
  const intl = useIntl();
  // 時間選択
  const [timeh, setTimeh] = useState<Date | null>();
  const [timehm, setTimehm] = useState<Date | null>();
  const [timehms, setTimehms] = useState<Date | null>();

  // 日付選択
  const [suggest, setSuggest] = useState<Date | null>(null);
  const [year, setYear] = useState<Date | null>(null);
  // 日付サジェスト入力
  const [year_month, setYearMonth] = useState<Date | null>(null);
  const [nowYearMonth, setNowYearMonth] = useState<Date | null>(null);

  return (
    <div className="categories">
      <h3 id="date-inputs">日時選択・入力</h3>
      <h4>{TIME_SELECT_1}（必須入力、時間範囲指定:10~22時）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <TimeH
              name="hours"
              unselectedOption={{ isUnselected: true, value: '選択して下さい' }}
              validator={validateTime(intl, 10, 22)}
              validateOption={{ required: true }}
              onChangeState={setTimeh}
              columns={['hours']}
            ></TimeH>
          </div>
          <div className="w-66">
            <pre className="output">{timeh?.toString()}</pre>
          </div>
        </div>
      </div>

      <h4>
        {TIME_SELECT_2}(時分表示、初期値現在時刻、現在時刻以降の指定)
        <br />
        <small>※現在時刻はロード時に更新</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <TimeHM
              name="hm"
              value={new Date()}
              unselectedOption={{ isUnselected: true, value: '' }}
              validator={validatePreviousTime(intl, new Date())}
              onChangeState={setTimehm}
              columns={['hm']}
            ></TimeHM>
          </div>
          <div className="w-66">
            <pre className="output">{timehm?.toString()}</pre>
          </div>
        </div>
      </div>

      <h4>
        {TIME_SELECT_3}
        (時分秒、必須入力、初期値現在時刻、現在時刻以降の指定)
        <br />
        <small>※現在時刻はロード時に更新</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <TimeHMS
              name="hms"
              value={new Date()}
              validator={validatePreviousTime(intl, new Date())}
              unselectedOption={{ isUnselected: true, value: '未選択' }}
              validateOption={{ required: true }}
              onChangeState={setTimehms}
              columns={['hms']}
            ></TimeHMS>
          </div>
          <div className="w-66">
            <pre className="output">{timehms?.toString()}</pre>
          </div>
        </div>
      </div>

      <h4>{SUGGEST}（サジェスト、必須入力）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <DateSuggest
              name="dateSuggest"
              validator={validateFreeDate(intl)}
              validateOption={{ required: true }}
              onChangeState={setSuggest}
              columns={['dateSuggest']}
            ></DateSuggest>
          </div>
          <div className="w-66">
            <pre className="output">{suggest?.toString()}</pre>
          </div>
        </div>
      </div>

      <h4>{YEAR_SELECT}</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <DateY
              name="dateY"
              onChangeState={setYear}
              term={3}
              columns={['dateY']}
            ></DateY>
          </div>
          <div className="w-66">
            <pre className="output">{year?.toString()}</pre>
          </div>
        </div>
      </div>

      <h4>{YM_SELECT}(年月、必須入力)</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <DateYM
              name="dateYM"
              term={3}
              validateOption={{ required: true }}
              unselectedOption={{ isUnselected: true, value: '未選択' }}
              onChangeState={setYearMonth}
              columns={['dateYM']}
            ></DateYM>
          </div>
          <div className="w-66">
            <pre className="output">{year_month?.toString()}</pre>
          </div>
        </div>
      </div>
      <h4>{YM_SELECT}(本日日付)(年月、必須入力)</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <DateYM
              name="dateYM"
              term={3}
              validateOption={{ required: true }}
              value={new Date()}
              unselectedOption={{ isUnselected: true, value: '未選択' }}
              onChangeState={setNowYearMonth}
              columns={['dateYM']}
            ></DateYM>
          </div>
          <div className="w-66">
            <pre className="output">{nowYearMonth?.toString()}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
