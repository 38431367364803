import dayjs from 'dayjs';
import { useState } from 'react';
import { SimpleIconListView } from '~/shared/components/ui/SimpleListView';

export const UISampleSimpleIconLists = () => {
  const [listData, setListData] = useState(
    Array(8)
      .fill({})
      .map((item, i) => ({
        id: i + 1,
        filename: `テスト${i + 1}.pdf`,
      }))
  );

  const listData2 = Array(7)
    .fill({})
    .map((item, i) => ({
      id: `ID-${i + 1}`,
      name: `テスト${i + 1}`,
      comment: `コメントコメントコメントコメントコメントコメントコメントコメントコメントコメント${
        i + 1
      }`,
      createdAt: dayjs(new Date()).format('YYYY/MM/DD'),
      memo: `表示しない${i + 1}`,
    }));

  return (
    <div className="categories">
      <h3 id="simple-icon-list">
        シンプルアイコンリスト
        <br />
        <small>※ちょっとした配列データを一覧表示するリスト（アイコン）</small>
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-50">
            <SimpleIconListView
              data={listData}
              viewOptions={{
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
                omitFooter: true,
              }}
              actionOptions={{}}
              iconMenuOptions={{
                iconMenu: [
                  {
                    name: 'description',
                    displayName: '確認',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    func: (item: any) => {
                      console.info('description', item);
                    },
                  },
                  {
                    name: 'delete',
                    displayName: '削除',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    func: (item: any) => {
                      setListData(listData.filter((v) => v.id !== item.id));
                      console.info('delete', item);
                    },
                  },
                ],
              }}
            />
          </div>
          <div className="w-50">
            <SimpleIconListView
              data={listData}
              viewOptions={{
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'filename' },
                    propertyName: 'filename',
                  },
                ],
              }}
              actionOptions={{}}
              iconMenuOptions={{
                iconMenu: [],
              }}
            />
          </div>
        </div>
      </div>
      <h4>アイコンと削除混在</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-50">
            <SimpleIconListView
              data={listData2}
              viewOptions={{
                previewRowCount: 5,
                keyColumn: 'id',
                columns: [
                  {
                    header: { id: 'name' },
                    propertyName: 'name',
                    width: '6rem',
                  },
                  {
                    header: { id: 'comment' },
                    propertyName: 'comment',
                  },
                  {
                    header: { id: 'createdAt' },
                    propertyName: 'createdAt',
                    width: '8rem',
                  },
                ],
                omitFooter: true,
              }}
              actionOptions={{
                onDelete: (item) => {
                  console.info('Delete!', item);
                },
              }}
              iconMenuOptions={{
                iconMenu: [
                  {
                    name: 'description',
                    displayName: '確認',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    func: (item: any) => {
                      console.info('description', item);
                    },
                  },
                  {
                    name: 'delete',
                    displayName: '削除',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    func: (item: any) => {
                      console.info('delete', item);
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
