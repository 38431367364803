import { PropsWithChildren, useState } from 'react';
import './Accordion.css';

interface AccordionProps {
  title?: string;
  initialClose?: boolean;
}
export const Accordion = ({
  title,
  initialClose,
  children,
}: PropsWithChildren<AccordionProps>) => {
  const [isAccordionClose, setIsAccordionClose] = useState(initialClose);
  return (
    <div className={`Accordion ${isAccordionClose ? 'close' : ''}`}>
      <div className="accordion-title">
        <h3
          className="accordion-title-label"
          onClick={() => setIsAccordionClose(!isAccordionClose)}
        >
          {title}
        </h3>
      </div>
      <div className="accordion-body">{children}</div>
    </div>
  );
};
