/**
 * 数値型や Long 型との相互変換処理
 */

import Long from 'long';

// APIに情報登録時のstring->Longの変換
export function convertStringToLong(value: string | undefined | null) {
  if (!value) {
    return null;
  }
  const removedCommaValue = value.replaceAll(',', '');
  return Long.fromString(removedCommaValue);
}

// APIに情報登録時のLong->Stringの変換
export function convertLongToString(value: Long.Long | undefined | null) {
  if (value === null || value === undefined) {
    return '';
  }
  return Long.fromValue(value).toString();
}

export function convertLongToNumber(value: Long.Long | undefined | null) {
  if (value === null || value === undefined) {
    return undefined;
  }
  return Long.fromValue(value).toNumber();
}

// 文字列（3桁区切り、小数表示など数値に変換可能）を数値に変換する
export function convertNumber(value: string): number {
  // 3桁区切り文字列からカンマを削除
  const removedCommaValue = value.replaceAll(',', '');
  const num = Number(removedCommaValue);

  // Numberに変換できない場合
  if (Number.isNaN(num)) {
    throw new Error(`assertion error: ${value} は数値に変換できません`);
  }

  return num;
}

// APIに情報登録時のString->Numberの変換
export function convertStringToNumber(value: string | undefined | null) {
  if (value === null) {
    return null;
  }
  const num = Number(value);

  return isNaN(num) ? null : num;
}

// APIにint32で登録する際のコンバーター
export function convertApiInt32(v: string | undefined) {
  if (v === undefined) {
    return null;
  }

  const num = Number(v);

  return isNaN(num) ? null : num;
}
