import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { GetMessage, error } from '~/shared/components';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import { Textbox } from '../Textbox';
import { useCommentPane, useCommentPaneDispatch } from './CommentPaneProvider';
import './ThreadForm.css';
import { getExceptionMessage } from '~/shared/utils';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox';

export const ThreadForm = () => {
  const commentPaneDispatch = useCommentPaneDispatch();
  const { draft, assetSet } = useCommentPane();
  const [displayName, setDisplayName] = useState('');
  const [selectedShareScopeCode, setSelectedShareScopeCode] = useState('');
  const [isWorking, setWorking] = useState(false);
  const intl = useIntl();

  const shareScopeList = useMemo(
    () => window.App.services.ui.getNameOption('A0000038'),
    []
  );
  const shareScopeItems: DataFilterboxItem[] = useMemo(() => {
    return [
      ...shareScopeList
        // TODO: "発注元のみ" は後で調整する
        .filter(
          (item) => item.systemName === 'B01' || item.systemName === 'B02'
        )
        .map((item) => ({
          value: item.code || '',
          displayName:
            (item.displayNameLang && item.displayNameLang[intl.locale]) || '',
        })),
    ];
  }, [intl, shareScopeList]);

  const handleCancelThread = () => {
    commentPaneDispatch({ type: 'cancelThread' });
  };

  const handleRegisterThread = async () => {
    if (!draft || !assetSet.activeAssetId) {
      return;
    }

    const selectedShareScope = shareScopeList.find(
      (item) => item.code === selectedShareScopeCode
    );
    if (!selectedShareScope) {
      return;
    }

    const thread: mtechnavi.api.forum.IThread = {
      displayName,
      attachedPoint: draft,
      shareScope: selectedShareScope,
      associations: [assetSet.activeAssetId],
    };

    try {
      setWorking(true);
      const result = await window.App.services.forumService.createThread({
        thread,
        resourceReference: {
          typeName: 'mtechnavi.api.estimation.EstimateRequest',
          resourceId: assetSet.activeResourceId,
        },
      });
      commentPaneDispatch({
        type: 'addThread',
        thread: result,
      });
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  return (
    <div className="ThreadForm">
      <Textbox
        type="text"
        name="displayName"
        labelId="thread.displayName"
        onChangeState={(v) => {
          setDisplayName(v);
        }}
        validateOption={{ required: true }}
        columns={['displayName']}
        properties={[]}
        disabled={isWorking}
      />
      <DataFilterbox
        name="shareScope"
        labelId="thread.shareScope"
        columns={['shareScope']}
        multiple={false}
        data={shareScopeItems}
        validateOption={{ required: true }}
        onChangeState={(v) => {
          setSelectedShareScopeCode(v.length > 0 ? v[0].value : '');
        }}
        disabled={isWorking}
      />
      <div className="actions">
        <CaptionButton
          buttonType="cancel"
          name="cancel"
          caption={GetMessage({ id: 'cancel' })}
          onClick={handleCancelThread}
          disabled={isWorking}
        />
        <CaptionButton
          buttonType="basic"
          name="send"
          caption={GetMessage({ id: 'decision' })}
          onClick={handleRegisterThread}
          disabled={isWorking || !displayName || !selectedShareScopeCode}
        />
      </div>
    </div>
  );
};
