import { useState, useMemo, useEffect } from 'react';
import { FilterTerm } from '~/worker';
import { PresetItem } from '~/shared/services';
import { FilterViewQuery } from '../index';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui/Filterbox/DataFilterbox';

import { getPresetPropertyValue, checkDisalbedPresetProperty } from '../preset';
import { isFilterIn, isStringArray, toInFilter } from '../filter';

export const getFilteringFilterboxMultipleValues = (
  terms: FilterTerm[]
): string[] => {
  const selectedValues: string[] = [];
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      if (isFilterIn(v) && isStringArray(v.$in)) {
        selectedValues.push(...v.$in);
      }
    }
  }
  return selectedValues;
};

const dataFilterboxItem = (
  displayName: string,
  value: string
): DataFilterboxItem => {
  return {
    displayName: displayName,
    value: value,
  };
};

const toDataFilterboxItems = (
  opts: mtechnavi.api.programoption.IProgramOption[]
): DataFilterboxItem[] => {
  return opts.map((v) =>
    dataFilterboxItem(
      v.displayNameLang
        ? v.displayNameLang[window.App.config.langName] ?? ''
        : '',
      v.code ?? ''
    )
  );
};

const toDataFilterboxItemsByCodes = (
  items: DataFilterboxItem[],
  ...codes: string[]
): DataFilterboxItem[] => {
  return codes.map((code) => {
    const v = items.find((item) => item.value === code);
    return dataFilterboxItem(v?.displayName ?? '', v?.value ?? '');
  });
};

interface FilterInputFilterboxMultipleProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}

/**
 * 名称マスタコード値検索フィルタ
 *
 * 名称マスタコードを指定しているフィールドに対して、コード一致検索を行う。
 *
 * - プリセット
 *     - type='filterboxMultiple'
 *     - categoryName='名称マスタ.種別コード（e.g. "A0000XXX"）'
 */
export function FilterInputFilterboxMultiple(
  props: FilterInputFilterboxMultipleProps
) {
  const { labelId, column, presetItem, filterValue, onChangeFilter } = props;

  const [dataFilterboxValue, setDataFilterboxValue] = useState<
    Array<DataFilterboxItem>
  >([]);

  const [dataFilterboxItems, isDisabledColumn] = useMemo<
    [DataFilterboxItem[], boolean]
  >(() => {
    // フィルターボックスの初期値検索用
    const filterboxCategoryName = getPresetPropertyValue(
      presetItem?.property,
      column,
      'categoryName'
    );
    const dataFilterboxItems: DataFilterboxItem[] = [];
    // フィルタボックスが名称マスタの場合
    if (filterboxCategoryName) {
      const programOptions = window.App.services.ui.getProgramOption(
        filterboxCategoryName
      ) as mtechnavi.api.programoption.IProgramOption[];
      dataFilterboxItems.push(...toDataFilterboxItems(programOptions));
    }
    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    return [dataFilterboxItems, isDisabled];
  }, [column, presetItem?.property, presetItem?.children]);

  useEffect(() => {
    const codes = getFilteringFilterboxMultipleValues(
      filterValue.filterTerms[column] ?? []
    );
    setDataFilterboxValue(
      toDataFilterboxItemsByCodes(dataFilterboxItems, ...codes)
    );
  }, [column, filterValue.filterTerms, dataFilterboxItems]);

  const onChangeDataFilterboxItem = (selectedItems: DataFilterboxItem[]) => {
    const codes = selectedItems.map((v) => v.value);
    const filterTerms: FilterTerm[] = [];
    if (codes.length > 0) {
      filterTerms.push(toInFilter(column, codes));
    }
    setDataFilterboxValue(selectedItems);
    onChangeFilter && onChangeFilter(filterTerms);
  };

  return (
    <div className="input-area">
      <DataFilterbox
        data={dataFilterboxItems}
        value={dataFilterboxValue}
        labelId={labelId}
        multiple={true}
        searchOption={{ targets: 'displayName' }}
        name={column}
        columns={[column]}
        onChangeState={(v) => {
          onChangeDataFilterboxItem(v);
        }}
        disabled={isDisabledColumn}
      ></DataFilterbox>
    </div>
  );
}
