import { useToolContext } from '~/shared/contexts/ToolMenuProvider';

/**
 * フォーラム機能を利用するためのカスタムフック
 */
export const useForum = () => {
  const {
    typeName,
    resourceId,
    setTypeName,
    setResourceId,
    setActiveToolType,
  } = useToolContext();

  /** フォーラム UI を閉じる */
  const closeForum = () => {
    setActiveToolType('None');
  };

  return {
    typeName,
    resourceId,
    setTypeName,
    setResourceId,
    closeForum,
  };
};
