import { GetMessage } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import './BusinessUnitCsvAdditionDialog.css';
import { useEffect, useState, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { GetMessageWithIntl } from '../../parts/Message/Message';

export interface BusinessUnitManagementCsvItem {
  code: string;
  displayName: string;
}

export interface BusinessUnitManagementItem {
  message: string[];
  data: BusinessUnitManagementCsvItem[];
}

export interface BusinessUnitCsvAdditionOutputDialogProps {
  isOpen: boolean;
  onDecision: (v: BusinessUnitManagementItem) => void;
  onCancel: () => void;
}

export const BusinessUnitCsvAdditionDialog = (
  props: BusinessUnitCsvAdditionOutputDialogProps
) => {
  const intl = useIntl();
  const [message, setMessage] = useState<string[]>([]); // バリデーションエラー用
  const [csvFileData, setCsvFileData] = useState<string[][]>([]);
  const [csvFileName, setCsvFileName] = useState<string>();

  // CSVファイルの読み込み処理
  const parseCsv = (data: string): string[][] => {
    return data.split('\r\n').map((row) => row.split(','));
  };

  // input要素からのファイルアップロード処理
  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    // ファイルがない場合は処理終了
    if (!evt.target.files) {
      return;
    }
    setMessage([]);

    // Filelistを配列に変換
    const uploads: File[] = [];
    for (let i = 0; i < evt.target.files.length; i++) {
      uploads.push(evt.target.files[i]);
    }
    const validateMessage: string[] = [];
    uploads.forEach((v) => {
      // CSV以外のファイル形式の場合
      if (v.name.split('.').pop() !== 'csv') {
        validateMessage.push(
          GetMessageWithIntl(intl, { id: 'E0000005', value: { $1: v.name } })
        );
      }
    });
    setMessage(validateMessage);
    const targetFile: File = uploads[0];
    setCsvFileName(targetFile.name);

    // エラーがある場合、後続処理を行わない
    if (validateMessage.length > 0) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const result = reader.result;
        if (result) {
          const parseData = parseCsv(reader.result as string);
          setCsvFileData(parseData);
        }
      },
      false
    );
    await reader.readAsText(targetFile, 'shift_jis');

    // CSV形式のチェック
    evt.target.value = '';
  };

  const handleDecision = () => {
    // CSVフォーマットチェック
    // 更新データなし(ヘッダー含む2行必要)
    if (
      csvFileData.length > 0 &&
      csvFileData[csvFileData.length - 1].length > 0 &&
      csvFileData[csvFileData.length - 1][0] === ''
    ) {
      // ファイルが改行で終了（最終行が空）の場合、除外する
      csvFileData.pop();
    }

    const errorMessage: string[] = [];
    if (csvFileData.length <= 1) {
      errorMessage.push(GetMessageWithIntl(intl, { id: 'E1000010' }));
    }
    // 列数異常
    if (csvFileData[0].length !== 2) {
      errorMessage.push(GetMessageWithIntl(intl, { id: 'E0000022' }));
    }

    // CSV形式のエラーがあれば処理終了
    if (errorMessage.length > 0) {
      props.onDecision({ message: errorMessage, data: [] });
      return;
    }

    let isValidRequired = true;
    let isValidDuplicate = true;

    const data: BusinessUnitManagementCsvItem[] = [];
    const codes: string[] = [];
    csvFileData.map((row, index) => {
      // ヘッダーは詰め替え対象外
      if (index > 0) {
        if (row.length === 2) {
          const code = row[0];
          const displayName = row[1];

          // 取引先コード必須チェック
          if (code === '') {
            if (isValidRequired) {
              errorMessage.push(GetMessageWithIntl(intl, { id: 'E0000087' }));
            }
            isValidRequired = false;
            // 取引先コード重複チェック
          } else if (codes.includes(code)) {
            if (isValidDuplicate) {
              errorMessage.push(GetMessageWithIntl(intl, { id: 'E0000088' }));
            }
            isValidDuplicate = false;
            // 正常データのみ格納
          } else {
            codes.push(code);
            data.push({
              code: code,
              displayName: displayName,
            });
          }
        }
      }
    });
    props.onDecision({ message: errorMessage, data: data });
  };

  const handleExportFormat = () => {
    const link = document.createElement('a');
    link.download = '取引先指定取込.csv';
    link.href = '/assets/csvformat/BusinessUnitAdditionFormat.csv';
    link.click();
    link.remove();
  };

  const formReset = () => {
    setMessage([]);
    setCsvFileData([]);
    setCsvFileName('');
  };

  // フォームに初期値をセットする
  useEffect(() => {
    formReset();
  }, [props.isOpen]);

  const elements = (
    <div className="output-dialog">
      <div className="contents-box">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <CaptionButton
                name="selectedOnlyBtn"
                buttonType="basic"
                className="button"
                caption={GetMessage({
                  id: 'format_output',
                  prefixId: 'BusinessUnitCsvAdditionDialog',
                })}
                onClick={() => handleExportFormat()}
              />
            </div>
          </div>
        </div>
        <p className="upload-csv-label"></p>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100 file-uploader-button">
              {/* ファイル選択処理はGCSアップなど不要のため、自前で作成 */}
              <div className="FileUploaderArea">
                <div className="FileUploader">
                  <div className="FileUploaderSelectParts">
                    <label className="file-chooser">
                      ファイル選択
                      <input type="file" onChange={handleFileChange} />
                    </label>
                  </div>
                </div>
              </div>
              <ErrorMessage message={message}></ErrorMessage>
              <div className="fileName">{csvFileName}</div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              props.onCancel();
            }}
          />
          <CaptionButton
            name="cancelBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({ id: 'decision' })}
            onClick={() => handleDecision()}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'BUSINESS_UNIT_CSV_ADDITION',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
