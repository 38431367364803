import { useState } from 'react';
import { LoadingIcon } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { CertificateRegistrationDialog } from '~/shared/components/ui/Dialog/CertificateRegistrationDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UISampleCertificateRegistrationDialogs = () => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [isShowDialog, setShowDialog] = useState(false);
  const [resultConfirm, setResultConfirm] = useState('');
  const [isShowConfirmDialog, setShowConfirmDialog] = useState(false);
  const [businessUnitCertificate, setBusinessUnitCertificate] =
    useState<mtechnavi.api.company.IBusinessUnitCertificate>();

  return (
    <div className="categories">
      <h3 is="dialog">認証情報登録ダイアログ</h3>
      <h3 is="dialog">確定モード</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setShowDialog(true);
              }}
            />
            <CertificateRegistrationDialog
              isOpen={isShowDialog}
              messageOption={{}}
              inputOption={{
                displayModeType: 'decision',
                businessUnitCertificate: businessUnitCertificate,
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setShowDialog(false);
              }}
              onDecision={(v) => {
                setBusinessUnitCertificate(v.businessUnitCertificate);
                setResult(
                  `出力しました。${JSON.stringify(
                    v,
                    function (key, value) {
                      if (this[key] instanceof Date) {
                        return this[key].toString();
                      }
                      return value;
                    },
                    2
                  )}`
                );
                setShowDialog(false);
              }}
              onChangeLoadingState={(isLoading) => setLoading(isLoading)}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
      <h3 is="dialog">表示モード</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResultConfirm('');
                setShowConfirmDialog(true);
              }}
            />
            <CertificateRegistrationDialog
              isOpen={isShowConfirmDialog}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'certificateConfirmation',
                },
              }}
              inputOption={{
                displayModeType: 'display',
                businessUnitCertificate: businessUnitCertificate,
              }}
              onCancel={() => {
                setResultConfirm('キャンセルしました');
                setShowConfirmDialog(false);
              }}
              onDecision={(v) => {
                setResultConfirm(
                  `出力しました。${JSON.stringify(
                    v,
                    function (key, value) {
                      if (this[key] instanceof Date) {
                        return this[key].toString();
                      }
                      return value;
                    },
                    2
                  )}`
                );
                setShowConfirmDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{resultConfirm}</pre>
          </div>
        </div>
      </div>
      {isLoading && <LoadingIcon />}
    </div>
  );
};
