import { useCallback, useMemo, useState } from 'react';
import {
  MenuDataView,
  MenuActionItem,
  ViewMenu,
  MenuType,
  ListViewPageSetting,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  DataViewHeaderInfo,
  DataView,
} from '~/shared/components/ui/ListView/DataView';
import { ViewId } from '~/shared/utils';

const VIEW_ID: ViewId = 'UI_SAMPLE_DATA_VIEW';

const dataViewHeaderInfo: DataViewHeaderInfo[] = [
  {
    message: {
      viewId: VIEW_ID,
      id: 'header1',
    },
    width: '9rem',
  },
  {
    message: {
      viewId: VIEW_ID,
      id: 'header2',
    },
    width: '9rem',
  },
  {
    message: {
      viewId: VIEW_ID,
      id: 'header3',
    },
    width: '9rem',
  },
  {
    message: {
      viewId: VIEW_ID,
      id: 'header4',
    },
    width: '9rem',
  },
  {
    message: {
      viewId: VIEW_ID,
      id: 'header5',
    },
    width: '9rem',
  },
];

const dataViewData = {
  header1: 'AAAAAA',
  header2: 'BBBBBB',
  header3: 'CCCCCC',
  header4: 'DDDDDD',
  header5: 'EEEEEE',
};

const menuDataViewMenuItems: MenuType[] = [
  {
    actionName: 'edit',
    func: () => {},
    displayName: '編集',
  },
  {
    actionName: 'add',
    func: () => {},
    displayName: '追加',
  },
];

const headerIconItems: ViewMenu[] = [
  {
    name: 'noteadd',
    func: (v?: string[]) => {
      console.info('header add', v);
    },
    displayName: '追加',
  },
  {
    name: 'edit',
    func: (v?: string[]) => {
      console.info('header edit', v);
    },
    displayName: '編集',
  },
];

const listIconItems: ViewMenu[] = [
  {
    name: 'edit',
    func: (v?: string[]) => {
      console.info('item edit', v);
    },
    displayName: '編集',
  },
];

const CreateDataViewData = () => {
  const dataViewDataInfo: {
    header1: string;
    header2: string;
    header3: string;
    header4: string;
    header5: string;
  }[] = [];
  for (let i = 0; i < 20; i++) {
    dataViewDataInfo.push({
      header1: `${dataViewData.header1}-${i}`,
      header2: `${dataViewData.header2}-${i}`,
      header3: `${dataViewData.header3}-${i}`,
      header4: `${dataViewData.header4}-${i}`,
      header5: `${dataViewData.header5}-${i}`,
    });
  }
  return dataViewDataInfo;
};

const CreateDataViwIconMenuItems = () => {
  const menuActionItem: MenuActionItem[] = [];
  menuActionItem.push({
    menuActionType: 'headerIconMenu',
    menu: headerIconItems,
  });
  menuActionItem.push({
    menuActionType: 'listIconMenu',
    menu: listIconItems,
  });
  return menuActionItem;
};

export const UISampleDataViews = () => {
  const [menuDataViewState, setMenuDataViewState] = useState<string[]>([]);
  const [dataViewState, setDataViewState] = useState<ListViewPageSetting>({});
  const [initialChecks, setInitialChecks] = useState<string[]>(['AAAAAA-13']);
  const [initialCurrentPage, setInitialCurrentPage] = useState(3);
  const menuDataViewData = useMemo(() => CreateDataViewData(), []);
  const dataViewData = useMemo(() => CreateDataViewData(), []);

  const handleDataViewChangeState = useCallback(
    (state: ListViewPageSetting) => {
      setDataViewState(state);
    },
    []
  );

  return (
    <div className="categories">
      <h3 id="list-view">
        リストビュー
        <br />
        <small>※APIエンドポイントを指定してデータを閲覧するリスト</small>
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">TODO</div>
        </div>
      </div>

      <h3 id="data-view">
        データビュー
        <br />
        <small>※任意のデータを外部から与えて閲覧するリスト</small>
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">
            <MenuDataView
              pageSizeOption={{ pageSize: 5, isDisplay: true }}
              title="データビューサンプル"
              header={dataViewHeaderInfo}
              data={menuDataViewData}
              isVisibleCheckbox={true}
              allBurgerMenu={menuDataViewMenuItems}
              listBurgerMenu={menuDataViewMenuItems}
              menuTarget="header1"
              onChangeState={setMenuDataViewState}
            ></MenuDataView>
          </div>
          <div className="w-33">
            <pre className="output">
              {JSON.stringify(menuDataViewState, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h3 id="extend-data-view">
        拡張版データビュー
        <br />
        <small>※任意のデータを外部から与えて閲覧するリスト</small>
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">
            <DataView
              pageInfo={{
                pageSizeOption: { pageSize: 5, isDisplay: true },
                title: '拡張版データビューサンプル',
                data: dataViewData,
                header: dataViewHeaderInfo,
                menuTarget: 'header1',
                menuItem: CreateDataViwIconMenuItems(),
                initialChecks,
                initialCurrentPage,
              }}
              listSkipValue={{ isVisibleCheckbox: true }}
              onChangeState={handleDataViewChangeState}
            ></DataView>
            <CaptionButton
              buttonType="basic"
              name="check"
              caption="奇数行にチェック"
              onClick={() =>
                setInitialChecks(
                  dataViewData
                    .filter((item, index) => index % 2 !== 0)
                    .map((item) => item.header1)
                )
              }
            />
            <CaptionButton
              buttonType="basic"
              name="check"
              caption="チェックのクリア"
              onClick={() => setInitialChecks([])}
            />
            <CaptionButton
              buttonType="basic"
              name="check"
              caption="最初のページへ"
              onClick={() => setInitialCurrentPage(1)}
            />
            <CaptionButton
              buttonType="basic"
              name="check"
              caption="最後のページへ"
              onClick={() => setInitialCurrentPage(4)}
            />
          </div>
          <div className="w-33">
            <pre className="output">
              {JSON.stringify(dataViewState, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
