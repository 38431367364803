import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_UncompletedListWorkTaskTickets,
  FullMethodName_UncompletedListWorkTasks,
  PageState,
  movePage,
} from '~/shared/utils';
import { FilterRequest } from '~/worker';
import { SectionStateProps } from '../ui/TaskListView';
import { GetMessageWithIntl } from '../parts/Message/Message';
import './base.css';

const WORKTASK_CONFIRMATION_URL = '/work-task/wt-worktask-confirmation';

// 未完了作業の取得
const uncompletedListWorkTaskTickets =
  async (): Promise<mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse> => {
    const option: FilterRequest = {
      action: 'reload',
      fullMethodName: FullMethodName_UncompletedListWorkTaskTickets,
      filter: {},
      sort: [],
    };
    option.requestBody = {};
    try {
      return (await window.App.services.ui.worker.filter(
        option
      )) as mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse;
    } catch (err) {
      console.error('Error uncompletedListWorkTaskTickets', err);
      return (async () =>
        new mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse())();
    }
  };

// 未完了案件の取得
const uncompletedListWorkTasks =
  async (): Promise<mtechnavi.api.worktask.UncompletedListWorkTasksResponse> => {
    const option: FilterRequest = {
      action: 'reload',
      fullMethodName: FullMethodName_UncompletedListWorkTasks,
      filter: {},
      sort: [],
    };
    option.requestBody = {};
    try {
      return (await window.App.services.ui.worker.filter(
        option
      )) as mtechnavi.api.worktask.UncompletedListWorkTasksResponse;
    } catch (err) {
      console.error('Error uncompletedListWorkTasks', err);
      return (async () =>
        new mtechnavi.api.worktask.UncompletedListWorkTasksResponse())();
    }
  };

export interface TaskWorkTaskRequestProps extends SectionStateProps {}

export const TaskWorkTask = (props: TaskWorkTaskRequestProps) => {
  const intl = useIntl();
  const navi = useNavigate();
  const { onChangeLoadingState } = props;

  const [
    targetUncompletedListWorkTaskTicketIds,
    setTargetUncompletedListWorkTaskTicketIds,
  ] = useState<string[]>([]);
  const [
    targetUncompletedListWorkTaskIds,
    setTargetUncompletedListWorkTaskIds,
  ] = useState<string[]>([]);

  // 遷移処理
  const handleMovePage = (e: React.MouseEvent, ids: string[], url: string) => {
    e.preventDefault();
    if (!ids.length) return;
    const state: PageState = {
      ids: ids,
    };
    movePage(state, navi, url);
  };

  // 未完了作業
  const callUncompletedTicketsList = async () => {
    const { items } = await uncompletedListWorkTaskTickets();
    if (!items.length) {
      setTargetUncompletedListWorkTaskTicketIds([]);
      return;
    }
    setTargetUncompletedListWorkTaskTicketIds(
      items.map((item) => item.workTaskId ?? '')
    );
  };

  // 未完了案件
  const callUncompletedTaskList = async () => {
    const { items } = await uncompletedListWorkTasks();
    if (!items.length) {
      setTargetUncompletedListWorkTaskIds([]);
      return;
    }
    setTargetUncompletedListWorkTaskIds(
      items.map((item) => item.workTaskId ?? '')
    );
  };

  useEffect(() => {
    onChangeLoadingState && onChangeLoadingState(true);
    Promise.all([
      callUncompletedTicketsList(), // 未完了作業
      callUncompletedTaskList(), // 未完了案件
    ]).finally(() => {
      onChangeLoadingState && onChangeLoadingState(false);
    });
    // 正確にローディング状況を伝えることが困難なため、依存関係に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(targetUncompletedListWorkTaskTicketIds.length > 0 ||
        targetUncompletedListWorkTaskIds.length > 0) && (
        <div className="TaskList">
          <dl>
            <dt className="title">
              {GetMessageWithIntl(intl, { id: 'TaskList.title.workTask' })}
            </dt>
            {targetUncompletedListWorkTaskTicketIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetUncompletedListWorkTaskTicketIds,
                      WORKTASK_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.uncompletedWorkTaskTicket',
                  })}{' '}
                  {`（${targetUncompletedListWorkTaskTicketIds.length}）`}
                </a>
              </dd>
            )}
            {targetUncompletedListWorkTaskIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetUncompletedListWorkTaskIds,
                      WORKTASK_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.uncompletedWorkTask',
                  })}{' '}
                  {`（${targetUncompletedListWorkTaskIds.length}）`}
                </a>
              </dd>
            )}
          </dl>
        </div>
      )}
    </>
  );
};
