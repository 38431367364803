import { useRef, useState } from 'react';
import { Container } from '~/shared/components';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';

export function UserAdmin() {
  const userIdRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const emailVerifiedRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const displayNameRef = useRef<HTMLInputElement>(null);
  const userGroupIdsRef = useRef<HTMLInputElement>(null);
  const disabledRef = useRef<HTMLInputElement>(null);
  const createdAtRef = useRef<HTMLInputElement>(null);
  const updatedAtRef = useRef<HTMLInputElement>(null);

  const handleEdit = (user: mtechnavi.api.idp.IUser) => {
    userIdRef.current!.value = user.userId!;
    emailRef.current!.value = user.email!;
    emailVerifiedRef.current!.value = String(user.emailVerified ?? false);
    passwordRef.current!.value = user.password ?? '';
    displayNameRef.current!.value = user.displayName ?? '';
    userGroupIdsRef.current!.value = JSON.stringify(user.userGroupIds ?? []);
    disabledRef.current!.checked = !!user.disabled;
    createdAtRef.current!.value = user.createdAt!.toString(10);
    updatedAtRef.current!.value = user.updatedAt!.toString(10);
  };

  const [users, setUsers] = useState<Array<mtechnavi.api.idp.IUser>>([]);
  const handleListUsers = () => {
    (async () => {
      const res = await window.App.services.identity.listUsers({});
      setUsers(res.items);
    })();
  };
  const handleCreateUser = () => {
    (async () => {
      await window.App.services.identity.createUser({
        user: {
          email: emailRef.current!.value,
          password: passwordRef.current!.value,
          displayName: displayNameRef.current!.value,
        },
      });
    })();
  };
  const handleUpdateUser = () => {
    (async () => {
      await window.App.services.identity.updateUser({
        user: {
          userId: userIdRef.current!.value,
          email: emailRef.current!.value,
          emailVerified: emailVerifiedRef.current!.value === 'true',
          password: passwordRef.current!.value,
          displayName: displayNameRef.current!.value,
          userGroupIds: JSON.parse(userGroupIdsRef.current!.value),
          disabled: disabledRef.current!.checked,
          createdAt: Long.fromString(createdAtRef.current!.value),
          updatedAt: Long.fromString(updatedAtRef.current!.value),
        },
      });
    })();
  };
  const handleDeleteUser = () => {
    (async () => {
      await window.App.services.identity.deleteUser({
        user: {
          userId: userIdRef.current!.value,
          email: emailRef.current!.value,
          emailVerified: emailVerifiedRef.current!.value === 'true',
          password: passwordRef.current!.value,
          displayName: displayNameRef.current!.value,
          userGroupIds: JSON.parse(userGroupIdsRef.current!.value),
          disabled: disabledRef.current!.checked,
          createdAt: Long.fromString(createdAtRef.current!.value),
          updatedAt: Long.fromString(updatedAtRef.current!.value),
        },
      });
    })();
  };

  return (
    <Container>
      <div>
        ユーザー編集
        <button type="button" onClick={handleListUsers}>
          List
        </button>
        <button type="button" onClick={handleCreateUser}>
          Create
        </button>
        <button type="button" onClick={handleUpdateUser}>
          Update
        </button>
        <button type="button" onClick={handleDeleteUser}>
          Delete
        </button>
        <fieldset>
          <legend>ユーザ情報</legend>
          <label>
            userId: <input type="text" disabled ref={userIdRef} />
          </label>
          <br />
          <label>
            email: <input type="text" ref={emailRef} />
          </label>
          <br />
          <label>
            emailVerified: <input type="text" disabled ref={emailVerifiedRef} />
          </label>
          <br />
          <label>
            password: <input type="text" ref={passwordRef} />
          </label>
          <br />
          <label>
            displayName: <input type="text" ref={displayNameRef} />
          </label>
          <br />
          <label>
            userGroupIds: <input type="text" ref={userGroupIdsRef} />
          </label>
          <br />
          <label>
            disabled: <input type="checkbox" ref={disabledRef} />
          </label>
          <br />
          <label>
            createdAt: <input type="text" disabled ref={createdAtRef} />
          </label>
          <br />
          <label>
            updatedAt: <input type="text" disabled ref={updatedAtRef} />
          </label>
          <br />
        </fieldset>
      </div>
      <hr />
      <div>
        <div>
          {users.map((user) => (
            <div
              style={{ overflow: 'scroll', display: 'flex' }}
              key={user.userId}
            >
              <button type="button" onClick={() => handleEdit(user)}>
                Edit
              </button>
              <pre style={{ marginLeft: '1em' }}>
                {JSON.stringify(user, null, 2)}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
