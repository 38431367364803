import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from 'react';

interface TaskListLoadingState {
  isLoading: boolean;
  loadingSections: string[];
}
type Actions = ActionLoadingSection | ActionCompleteSection;
// セクションロード中登録
interface ActionLoadingSection {
  type: 'loadingSection';
  sectionName: string;
}
// セクションロード完了登録
interface ActionCompleteSection {
  type: 'completeSection';
  sectionName: string;
}

const initialState: TaskListLoadingState = {
  isLoading: false,
  loadingSections: [],
};

const reducer = (
  state: TaskListLoadingState,
  action: Actions
): TaskListLoadingState => {
  switch (action.type) {
    case 'loadingSection': {
      const loadingSections = [...state.loadingSections, action.sectionName];
      return {
        isLoading: loadingSections.length !== 0,
        loadingSections,
      };
    }
    case 'completeSection': {
      const loadingSections = state.loadingSections.filter(
        (section) => section !== action.sectionName
      );
      return {
        isLoading: loadingSections.length !== 0,
        loadingSections,
      };
    }
  }
};

const TaskListLoadingContext =
  createContext<TaskListLoadingState>(initialState);
const TaskListLoadingDispatchContext = createContext<React.Dispatch<Actions>>(
  () => {}
);

export const useTaskListLoading = () => {
  return useContext(TaskListLoadingContext);
};

export const useTaskListLoadingDispatch = () => {
  return useContext(TaskListLoadingDispatchContext);
};

export const TaskListLoadingProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  return (
    <TaskListLoadingContext.Provider value={state}>
      <TaskListLoadingDispatchContext.Provider value={dispatch}>
        {children}
      </TaskListLoadingDispatchContext.Provider>
    </TaskListLoadingContext.Provider>
  );
};
