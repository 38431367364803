import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListDisaster,
  FullMethodName_ListDisasterSurveys,
  PageState,
  ViewId,
  convertLongToDate,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  getViewIdPreset,
  getWorkerExceptionMessage,
  includeInputValidateError,
  convertLongToString,
  convertStringToLong,
} from '~/shared/utils';
import urlList from '~/shared/menu/path.json';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
  DateSuggest,
  FilterboxItem,
  LatestUpdateInfo,
  PageNavigation,
  SimpleListView,
  Textbox,
} from '~/shared/components/ui';
import { SearchRegionDialog } from '~/shared/components/ui/Dialog/SearchRegionDialog';
import { CaptionButton } from '~/shared/components/ui/Button';
import { validateFreeDate } from '~/shared/components/parts/validator';
import Long from 'long';
import './BcpDisasterSurveyInput.css';

const VIEW_ID: ViewId = 'BCP_DISASTER_SURVEY_INPUT';

type DisasterSurvey = mtechnavi.api.bcp.IDisasterSurvey;
type Disaster = mtechnavi.api.bcp.Disaster;
export const BcpDisasterSurveyInput = () => {
  const [isLoading, setLoading] = useState(false);
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);
  const actionType = useMemo(() => {
    return sourcePageInfo.current.actionType || 'add';

    // sourcePageInfo.current.actionType 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePageInfo.current.actionType]);
  const intl = useIntl();
  const navi = useNavigate();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const backPageUrl = useMemo(
    () =>
      urlList.find((v) => v.viewId === sourcePageInfo.current.sourceViewId)
        ?.path ?? '/',
    [sourcePageInfo]
  );
  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // アコーディオン
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    disasterInformation: true,
  });
  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // キャンセル
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  // 登録
  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
        viewId: VIEW_ID,
      }),
    },
  };
  // 都道府県削除
  const viewMessageDelete: MessageProps = {
    id: 'confirmationDialogMessageDelete',
    prefixId: VIEW_ID,
  };
  // 都道府県全件削除
  const viewMessageDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: VIEW_ID,
  };
  // 画面アイテム
  const [disasterSurvey, setDisasterSurvey] = useState<DisasterSurvey>();
  const [disaster, setDisaster] = useState<Disaster>();
  const [disasterSurveyAutoName, setSurveyDIsasterAutoName] = useState('');
  const [status, setStatus] = useState('');
  const [disasterDisplayName, setDisasterDisplayName] = useState('');
  const [occurredAt, setOccurredAt] = useState<Date | null>();
  const [category, setCategory] = useState<FilterboxItem[]>([]);
  const [externalSourceCategory, setExternalSourceCategory] = useState('');
  const [externalSourceName, setExternalSourceName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [regions, setRegions] = useState<sharelib.INameOption[]>([]);
  // ナヴィゲーションアイテム
  const islatestUpdateVisibleRef = useRef(false);
  const categoriesNO = window.App.services.ui.getNameOption('A6000001');
  const regionsNO = window.App.services.ui.getNameOption('A0000042');

  const [lastUpdateInfo, setLastUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: false,
  });

  //データフィルターボックスアイテム
  const [categories] = useMemo<[DataFilterboxItem[]]>(() => {
    const categories = getProgramOptionFilterboxData('A6000001');
    return [categories];
  }, []);

  // ダイアログ
  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログInfo
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });
  const [isOpenRegionsSelectDialogm, setOpenRegionSelectDialog] =
    useState(false);

  // 入力チェック
  const requireDisasterInformation = useRef(null);
  const requiredDisasterNameArea = useRef(null);
  const requiredOccurredAtArea = useRef(null);
  const requiredCategoryArea = useRef(null);
  const [workingBlurCategory, setWorkingBlurCategory] = useState<Date>();

  useEffect(() => {
    setLoading(true);
    try {
      // 画面IDに基づくPreset取得
      (async () => {
        const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
        setPreset(presetItem);
      })();
      if (
        actionType === 'add' &&
        sourcePageInfo.current.sourceViewId === 'BCP_DISASTER_LIST'
      ) {
        (async () => {
          const listDisasterRes = (await window.App.services.ui.worker.filter({
            action: 'query',
            fullMethodName: FullMethodName_ListDisaster,
            filter: {
              disasterId: { $eq: sourcePageInfo.current.ids![0] ?? '' },
            },
            sort: [],
          })) as mtechnavi.api.bcp.ListDisastersResponse;

          const disasterItem = listDisasterRes?.items[0] as Disaster;
          if (disasterItem?.disasterId) {
            setDisasterDisplayName(disasterItem?.content ?? '');
            setOccurredAt(convertLongToDate(disasterItem?.occurredAt));
            setCategory([
              {
                displayName: disasterItem.category?.displayNameLang?.ja ?? '',
                value: disasterItem.category?.code ?? '',
              },
            ]);
            setExternalSourceCategory(disasterItem?.externalSourceOrigin);
            setExternalSourceName(disasterItem?.externalSourceOriginDetail);
            setRegions(disasterItem?.regions);
          }
          setDisaster(disasterItem);
          setLastUpdateInfo({ isVisibleUpdateInfo: false });
        })();
      }
      if (actionType === 'edit') {
        islatestUpdateVisibleRef.current = true;
        (async () => {
          const listDisasterSurveyRes =
            (await window.App.services.ui.worker.filter({
              action: 'query',
              fullMethodName: FullMethodName_ListDisasterSurveys,
              filter: {
                disasterSurveyId: { $eq: sourcePageInfo.current.ids![0] ?? '' },
              },
              sort: [],
            })) as mtechnavi.api.bcp.ListDisastersResponse;

          const disasterSurveyItem = listDisasterSurveyRes
            ?.items[0] as DisasterSurvey;
          if (disasterSurveyItem?.disasterSurveyId === '') {
            setLoading(false);
            error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
            return;
          }
          setLastUpdateInfo({
            isVisibleUpdateInfo: true,
            attribute:
              disasterSurveyItem?.updatedProperties?.updatedBy?.email ?? '',
            attributeAt:
              disasterSurveyItem?.updatedProperties?.updatedAt ?? undefined,
            content:
              disasterSurveyItem?.updatedProperties?.updatedBy?.displayName ??
              '',
          });
          setDisasterSurvey(disasterSurveyItem);
        })();
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }
    setLoading(false);
  }, [
    intl,
    sourcePageInfo.current.sourceViewId,
    sourcePageInfo.current.ids,
    actionType,
  ]);

  // 初期値セット
  useEffect(() => {
    if (!disasterSurvey?.disasterSurveyId) {
      return;
    }
    setSurveyDIsasterAutoName(
      convertLongToString(disasterSurvey.disasterSurveyAutoName)
    );
    setStatus(disasterSurvey.status?.displayNameLang?.ja ?? '');
    setDisasterDisplayName(disasterSurvey.disasterDisplayName ?? '');
    setOccurredAt(convertLongToDate(disasterSurvey.occurredAt));
    setCategory([
      {
        displayName: disasterSurvey.category?.displayNameLang?.ja ?? '',
        value: disasterSurvey.category?.code ?? '',
      },
    ]);
    setExternalSourceCategory(disasterSurvey.externalSourceCategory ?? '');
    setExternalSourceName(disasterSurvey.externalSourceName ?? '');
    setRemarks(disasterSurvey.remarks ?? '');
    setRegions(disasterSurvey.regions ?? []);
  }, [disasterSurvey]);

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 災害調査確認に戻る
  //  どの画面から遷移していても保存時は確認画面へ遷移する
  const naviToConfirmationPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
    };

    navi('/bcp/bcp-disaster-survey-confirmation', { state });
  };

  const getSelectRegionDialogInputOption = (): string[] => {
    const regionCodes: string[] = [];
    regions.map((item) => {
      if (item.code) regionCodes.push(item.code);
    });
    return regionCodes;
  };

  const isInputError = (): boolean => {
    setWorkingBlurCategory(new Date());
    return includeInputValidateError(document, intl, [
      {
        value: disasterDisplayName ?? '',
        ref: requiredDisasterNameArea,
      },
      {
        value: occurredAt?.getDate().toString() ?? '',
        ref: requiredOccurredAtArea,
      },
      {
        value: category.length > 0 ? category[0].value : '',
        ref: requiredCategoryArea,
      },
    ]);
  };

  const sendAction = async () => {
    setLoading(true);
    const disasterId =
      sourcePageInfo.current.actionType === 'edit'
        ? disasterSurvey?.disasterId
        : disaster?.disasterId ?? null;
    const reqCategory = categoriesNO.find(
      (item) => item.code === category![0].value
    );
    const request: mtechnavi.api.bcp.IDisasterSurvey = disasterSurvey ?? {};
    request.disasterSurveyId = disasterSurvey?.disasterSurveyId ?? null;
    request.disasterId = disasterId;
    request.disasterSurveyAutoName = convertStringToLong(
      disasterSurveyAutoName
    );
    request.category = reqCategory;
    request.occurredAt = Long.fromString(
      occurredAt?.getTime().toString() ?? ''
    ).multiply(1000);
    request.disasterDisplayName = disasterDisplayName;
    request.regions = regions;
    request.externalSourceCategory = externalSourceCategory;
    request.externalSourceName = externalSourceName;
    request.remarks = remarks;
    request.updatedAt = disasterSurvey?.updatedAt ?? null;

    try {
      const res = (
        (await window.App.services.ui.worker.apiCall({
          actionName: 'saveDisasterSurvey',
          request,
        })) as mtechnavi.api.bcp.IDisasterSurvey[]
      ).at(0);
      if (sourcePageInfo.current.actionType === 'add') {
        sourcePageInfo.current.beforeStateIds = [res?.disasterSurveyId ?? ''];
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    naviToConfirmationPage();
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="bcpDisasterSurveyInput">
          <div className="header">
            <PageNavigation
              backpagePath="/bcp/disaster-survey-list"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: lastUpdateInfo,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setConfirmDialogInfo({
                  viewMessage: viewMessageCancel,
                  onDecision: backToPreviewPage,
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div
              className={`input-blocktitle-outer ${
                accordionState.disasterInformation ? '' : 'close'
              }`}
              ref={requireDisasterInformation}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('disasterInformation')}
              >
                {GetMessage({ id: 'disasterInformation', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.disasterInformation ? '' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  {/* 災害調査番号 */}
                  <div className="w-25 disasterSurveyAutoName">
                    <span>
                      {GetMessage({
                        prefixId: 'BCP_DISASTER_SURVEY_INPUT',
                        id: 'disasterSurveyAutoName',
                      })}
                    </span>
                    <span>{disasterSurveyAutoName}</span>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 災害調査ステータス */}
                  <div className="w-25 status">
                    {/* {ステータス} */}
                    <span>{status}</span>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40" ref={requiredDisasterNameArea}>
                    {/* 災害名 */}
                    <Textbox
                      name="disasterDisplayName"
                      className="field"
                      value={disasterDisplayName}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_INPUT.disasterDisplayName"
                      columns={preset.columns}
                      validateOption={{ required: true }}
                      onChangeState={setDisasterDisplayName}
                    />
                  </div>
                  <div className="w-20" ref={requiredOccurredAtArea}>
                    {/* 発生日 */}
                    <DateSuggest
                      name="occurredAt"
                      columns={preset.columns}
                      labelId="BCP_DISASTER_SURVEY_INPUT.occurredAt"
                      validator={validateFreeDate(intl)}
                      onChangeState={setOccurredAt}
                      value={occurredAt}
                      validateOption={{ required: true }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 災害分類 */}
                  <div className="w-20" ref={requiredCategoryArea}>
                    <DataFilterbox
                      data={categories}
                      name="category.code"
                      labelId="BCP_DISASTER_SURVEY_INPUT.category.code"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      value={category}
                      validateOption={{ required: true }}
                      workingBlur={workingBlurCategory}
                      onChangeState={setCategory}
                    />
                  </div>
                  {/* 外部連携情報種類 */}
                  <div className="w-20">
                    <Textbox
                      name="externalSourceCategory"
                      className="field"
                      value={externalSourceCategory}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_INPUT.externalSourceCategory"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  {/* 外部連携情報データ */}
                  <div className="w-40">
                    <Textbox
                      name="externalSourceName"
                      className="field"
                      value={externalSourceName}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_INPUT.externalSourceName"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 備考 */}
                  <div className="w-80">
                    <Textbox
                      name="remarks"
                      className="field"
                      value={remarks}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_INPUT.remarks"
                      columns={preset.columns}
                      onChangeState={setRemarks}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100 region-area">
                  <div className="w-50">
                    <SimpleListView
                      data={regions.map((item) => {
                        return {
                          code: item.code,
                          regionName: item.displayNameLang?.ja,
                        };
                      })}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { id: 'regionName' },
                            propertyName: 'regionName',
                          },
                        ],
                      }}
                      actionOptions={{
                        onDelete: (item: sharelib.NameOption) => {
                          setOpenConfirmDialog(true);
                          setConfirmDialogInfo({
                            viewMessage: viewMessageDelete,
                            onDecision: () => {
                              setRegions(
                                regions?.filter((v) => v.code !== item.code)
                              );
                              setOpenConfirmDialog(false);
                            },
                          });
                        },
                        onDeleteAll: () => {
                          setConfirmDialogInfo({
                            viewMessage: viewMessageDeleteAll,
                            onDecision: () => {
                              setRegions([]);
                              setOpenConfirmDialog(false);
                            },
                          });
                          setOpenConfirmDialog(true);
                        },
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      buttonType="basic"
                      caption="追加"
                      name=""
                      onClick={() => {
                        setOpenRegionSelectDialog(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="resetBtn"
                  caption="保存"
                  className="btn"
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setConfirmDialogInfo({
                      viewMessage: viewMessageSave,
                      onDecision: sendAction,
                    });
                    setOpenConfirmDialog(true);
                  }}
                  buttonType="basic"
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmDialogInfo.viewMessage}
          onDecision={confirmDialogInfo.onDecision}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
        ></ConfirmationDialog>
        <SearchRegionDialog
          isOpen={isOpenRegionsSelectDialogm}
          inputOption={{ regions: getSelectRegionDialogInputOption() }}
          onDecision={(result) => {
            const tmpRegions: sharelib.INameOption[] = [];
            result.regions?.map((code) => {
              const item = regionsNO.find((region) => region.code === code);
              if (item) tmpRegions.push(item);
            });
            setRegions(tmpRegions);
            setOpenRegionSelectDialog(false);
          }}
          onCancel={() => {
            setOpenRegionSelectDialog(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
