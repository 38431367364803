import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  PageNavigation,
  SimpleIconListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_ListBusinessUnitFacilitiess,
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import urlList from '~/shared/menu/path.json';
import {
  BusinessUnitFacilityDialog,
  BusinessUnitFacilityFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitFacilityDialog';
import './OcpBusinessUnitProfileInput.css';

interface ocpFacilitiesList {
  id: string;
  businessUnitFacilitiesId?: string;
  buFacilities: mtechnavi.api.company.IBusinessUnitFacilities;
  branchName: string;
  manufacture?: string;
  modelNumber?: string;
  assetsAmount?: string;
  updatedAt: Long.Long | null;
}

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_FACILITIES_INPUT';
export const OcpBusinessUnitFacilitiesInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);

  const intl = useIntl();
  const navi = useNavigate();
  const backPageUrl = useMemo(
    () =>
      urlList.find((v) => v.viewId === sourcePageInfo.current.sourceViewId)
        ?.path ?? '/',
    [sourcePageInfo]
  );
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  type TabName = 'facilities';
  const tabs: TabName[] = ['facilities'];
  const [tabPanel, setTabPanel] = useState<TabName>('facilities');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  const [isLoading, setLoading] = useState(false);
  const [isFacilitiesDialog, setFacilitiesDialog] = useState(false);
  const [selectId, setSelectId] = useState('');

  const [listData, setListData] = useState<ocpFacilitiesList[]>([]);
  const [facilitiesData, setFacilitiesData] =
    useState<BusinessUnitFacilityFormOption>({ companyId: '' });
  const [updatedProperties, setUpdatedProperties] =
    useState<sharelib.IEmbeddedUpdatedProperties>();

  // 取得データ格納用 自社企業（拠点）マスタ
  const businessUnitBranchsData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);
  // 確認ダイアログ（削除）
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState('');

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    return includeInputValidateError(document, intl, []);
  };

  const handleDelete = () => {
    setListData(listData.filter((v) => v.id !== deleteTargetId));
    setDeleteTargetId('');
    setDeleteConfirmOpen(false);
  };

  const listDataSort = (data: ocpFacilitiesList[]) => {
    data.sort((val1, val2) =>
      val1.branchName.localeCompare(val2.branchName, 'ja')
    );
    setListData(
      data.map(
        (v): ocpFacilitiesList => ({
          id: v.id,
          businessUnitFacilitiesId: v.businessUnitFacilitiesId,
          buFacilities: v.buFacilities,
          branchName: v.branchName,
          manufacture: v.manufacture,
          assetsAmount: v.assetsAmount,
          modelNumber: v.modelNumber,
          updatedAt: v.updatedAt,
        })
      )
    );
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveBusinessUnitFacilities();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const getBusinessUnitFacilitiesList = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitFacilitiess,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const getBusinessUnitBranch = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitBranchs,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const saveBusinessUnitFacilities = async () => {
    const facilitiesList: mtechnavi.api.company.IBusinessUnitFacilities[] = [];

    listData.forEach((facilities) => {
      const request: mtechnavi.api.company.IBusinessUnitFacilities =
        facilities.buFacilities;
      request.companyId = myCompanyInfo.current?.companyId;
      request.updatedAt = facilities.updatedAt;
      facilitiesList.push(request);
    });

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpFacilities',
      request: {
        businessUnitFacilitiess: facilitiesList,
        companyId: myCompanyInfo.current?.companyId ?? '',
      },
    });
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        businessUnitBranchsData.current = (await getBusinessUnitBranch())
          .items as mtechnavi.api.company.IBusinessUnitFacilities[];

        const facilitiesList = (await getBusinessUnitFacilitiesList())
          .items as mtechnavi.api.company.IBusinessUnitFacilities[];
        console.info(facilitiesList);
        if (facilitiesList) {
          const ocpList: ocpFacilitiesList[] = [];
          facilitiesList.forEach((list) => {
            const branch = businessUnitBranchsData.current.filter(
              (v) => v.businessUnitBranchId === list.businessUnitBranchId
            );

            const data: ocpFacilitiesList = {
              id:
                list.businessUnitFacilitiesId ??
                (listData.length + 1).toString(),
              businessUnitFacilitiesId: list.businessUnitFacilitiesId ?? '',
              buFacilities: list,
              branchName:
                branch && branch.length > 0 ? branch[0].displayName ?? '' : '',
              manufacture: list.manufacture ?? '',
              modelNumber: list.modelNumber ?? '',
              assetsAmount: list.assetsAmount
                ? list.assetsAmount?.low.toString()
                : '',
              updatedAt: list.updatedAt as Long.Long,
            };
            setUpdatedProperties(list.updatedProperties!);
            ocpList.push(data);
          });
          listDataSort(ocpList);
        }
        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const renderFacilitiesComponent = () => {
    return (
      <>
        <div className="tab-info-body">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-80">
                <SimpleIconListView
                  data={listData}
                  viewOptions={{
                    previewRowCount: 10,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FACILITIES_INPUT.branchName',
                        },
                        propertyName: 'branchName',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FACILITIES_INPUT.manufacture',
                        },
                        propertyName: 'manufacture',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FACILITIES_INPUT.modelNumber',
                        },
                        propertyName: 'modelNumber',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FACILITIES_INPUT.assetsAmount',
                        },
                        propertyName: 'assetsAmount',
                        align: 'right',
                        width: '6rem',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'edit',
                        displayName: '編集',
                        func: (item: ocpFacilitiesList) => {
                          const selectData: BusinessUnitFacilityFormOption = {
                            companyId: myCompanyInfo.current?.companyId ?? '',
                            buFacilities: item.buFacilities,
                          };
                          setSelectId(item.id);
                          setFacilitiesData(selectData);
                          setFacilitiesDialog(true);
                        },
                      },
                      {
                        name: 'delete',
                        displayName: '削除',
                        func: (item: ocpFacilitiesList) => {
                          setDeleteTargetId(item.id);
                          setDeleteConfirmOpen(true);
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="w-20">
                <CaptionButton
                  buttonType="basic"
                  caption="新規行追加"
                  name=""
                  onClick={() => {
                    setSelectId('');
                    setFacilitiesData({
                      companyId: myCompanyInfo.current?.companyId ?? '',
                    });
                    setFacilitiesDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitFacilitiesInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: updatedProperties?.updatedAt ?? undefined,
                  content: updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => setBackConfirmOpen(true)}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('facilities') && renderFacilitiesComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      <BusinessUnitFacilityDialog
        isOpen={isFacilitiesDialog}
        inputMode={true}
        inputOption={facilitiesData}
        onCancel={() => {
          setFacilitiesDialog(false);
        }}
        onDecision={(v) => {
          if (selectId) {
            listData.map((e) => {
              if (e.id === selectId) {
                e.manufacture = v.manufacture ?? '';
                e.modelNumber = v.modelNumber ?? '';
                e.assetsAmount = v.assetsAmount
                  ? v.assetsAmount?.low.toString()
                  : '';
                e.buFacilities = v;

                const branch = businessUnitBranchsData.current.filter(
                  (w) => w.businessUnitBranchId === v.businessUnitBranchId
                );
                e.branchName =
                  branch && branch.length > 0
                    ? branch[0].displayName ?? ''
                    : '';
              }
            });
          } else {
            const branch = businessUnitBranchsData.current.filter(
              (w) => w.businessUnitBranchId === v.businessUnitBranchId
            );

            const data: ocpFacilitiesList = {
              id: (listData.length + 1).toString(),
              businessUnitFacilitiesId: '',
              buFacilities: v,
              branchName:
                branch && branch.length > 0 ? branch[0].displayName ?? '' : '',
              manufacture: v.manufacture ?? '',
              modelNumber: v.modelNumber ?? '',
              assetsAmount: v.assetsAmount ? v.assetsAmount?.toString() : '',
              updatedAt: null,
            };
            listData.push(data);
          }
          listDataSort(listData);
          setFacilitiesDialog(false);
        }}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={viewMessageDelete}
        onDecision={() => {
          handleDelete();
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
