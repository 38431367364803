import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import {
  ConfirmationDialog,
  NavigationIconMenu,
  SimpleListView,
  PageNavigation,
  Textarea,
  Textbox,
} from '~/shared/components/ui';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListWorkTaskCatalogs,
  PageState,
  ViewId,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  getLocalStorageCheckboxData,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'WT_REQUEST_CATALOG_CONFIRMATION';

type IWorkTaskCatalog = mtechnavi.api.worktask.IWorkTaskCatalog;

export function WtRequestCatalogConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const handleError = useErrorHandler();
  const [isLoading, setLoading] = useState(false);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];

  const [item, setItem] = useState<IWorkTaskCatalog>();
  // PageInfo
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListWorkTaskCatalogs,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      workTaskCatalogId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('WT_REQUEST_CATALOG_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // pageデータ変更時の処理
  useEffect(() => {
    const items = page.originalItems as unknown as IWorkTaskCatalog[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();

    // 基本情報でセットした値を元に情報を取得
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListWorkTaskCatalogs,
      pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    });

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleMenuInput();
      },
    });
    // 削除
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: () => setMenuModalIsOpen(true),
    });

    return iconItems;
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListWorkTaskCatalogs,
      pageNumber: n,
    });
  };

  const handleMenuInput = () => {
    const ids = [] as string[];
    ids.push(item?.workTaskCatalogId ?? '');
    const state: PageState = {
      ids: ids ?? [],
      actionType: 'edit',
      sourceViewId: VIEW_ID,
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/work-task/wt-request-catalog-input', { state });
  };

  const handleMenuDelete = async () => {
    const localstorageValues = getLocalStorageCheckboxData(
      'WT_REQUEST_CATALOG_LIST',
      myEmail
    );
    const deleteItem: IWorkTaskCatalog[] = [];
    if (item?.workTaskCatalogId) {
      deleteItem.push(item as IWorkTaskCatalog);
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteWorkTaskCatalog',
        request: deleteItem,
      });
      success([toastSuccess]);
      saveLocalStorageCheckboxData(
        'WT_REQUEST_CATALOG_LIST',
        localstorageValues.filter((v) => v !== item?.workTaskCatalogId),
        myEmail
      );
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setMenuModalIsOpen(false);
    setLoading(false);
    navi('/work-task/wt-request-catalog-list');
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="header">
          <PageNavigation
            backpagePath="/work-task/wt-request-catalog-list"
            pageInfo={{
              isVisibleMoveNavi: true,
              isFirstPage,
              isMaxPage,
              pageNumber: page.pageNumber,
            }}
            iconItems={navigationIconItems()}
            handleMovePage={(n) => handleMovePage(n)}
            infoOption={{
              lastUpdateInfo: {
                isVisibleUpdateInfo: true,
                attribute: item?.workTaskCatalogCatalogUpdatedBy?.email ?? '',
                attributeAt: item?.workTaskCatalogCatalogUpdatedAt ?? undefined,
                content:
                  item?.workTaskCatalogCatalogUpdatedBy?.displayName ?? '',
              },
              issuerInfo: { isVisibleIssuerInfo: false },
            }}
          />
        </div>
        <div className="WorkTaskCatalogConfirmation">
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25">
                  <Textbox
                    name="code"
                    className="field"
                    value={item?.code ?? ''}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.code"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-33">
                  <Textbox
                    name="displayName"
                    className="field"
                    value={item?.displayName ?? ''}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.displayName"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-25">
                  <Textbox
                    name="category.code"
                    className="field"
                    value={item?.category?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.category.displayName"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  <Textarea
                    name="contents"
                    className="w-100 mh-middle"
                    disabled={true}
                    value={item?.contents ?? ''}
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.contents"
                    columns={preset.columns}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25">
                  <Textbox
                    name="approval"
                    className="field"
                    value={item?.approval ? '◯' : '-'}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.approval"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-25">
                  <Textbox
                    name="managementOrganization"
                    className="field"
                    value={
                      item?.managementOrganization?.displayNameLang?.ja ?? ''
                    }
                    type="text"
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.managementOrganization"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-25">
                  <Textbox
                    name="workOrganization"
                    className="field"
                    value={item?.workOrganization?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_CONFIRMATION.workOrganization"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  <SimpleListView
                    data={item?.commonAttachments ?? []}
                    viewOptions={{
                      readonly: true,
                      previewRowCount: 5,
                      keyColumn: 'id',
                      columns: [
                        {
                          header: { id: 'attachedFile' },
                          propertyName: 'filename',
                        },
                      ],
                    }}
                    actionOptions={{
                      onRowClick: (item) => {
                        autoDownloadFileOnlyName(
                          item.filename ?? '',
                          item.assetId ?? ''
                        );
                      },
                      onFullDownLoad: () => {
                        autoBulkDownload(
                          (item?.commonAttachments ?? []).map(
                            (item) => item.assetId ?? ''
                          ),
                          intl,
                          VIEW_ID
                        );
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={menuModalIsOpen}
          viewMessage={deleteMessage}
          messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
          onDecision={() => {
            handleMenuDelete();
          }}
          onCancel={() => setMenuModalIsOpen(false)}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
