import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  success,
  Toast,
} from '~/shared/components';
import {
  Preset,
  Property,
  ConfirmationDialog,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
  ListView,
  ViewMenu,
  MenuActionItem,
} from '~/shared/components/ui';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';

import './AdminAccountList.css';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useIntl } from 'react-intl';
import {
  getPresetAndSchema,
  getExceptionMessage,
  getWorkerExceptionMessage,
  FullMethodName_ListUserAttributes,
  PageState,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/shared/contexts/AuthProvider';

const VIEW_ID = 'ADMIN_ACCOUNT_LIST';

type UserAttribute = mtechnavi.api.tenantadmin.IUserAttribute;
export function AdminAccountList(): JSX.Element {
  const intl = useIntl();
  const navi = useNavigate();
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const deletetUserAndUserAttributeMessage = {
    id: 'C0000001',
    value: { $1: 'アカウント削除' },
  };
  const sendEmailUserCreatedMessage = {
    id: 'C0000001',
    value: { $1: '作成完了通知送信' },
  };
  const [isDialogOpen, setDialogOpen] = useState(false);
  const myEmail = useAuth().user?.email ?? '';
  const targetUsetAttributeIds = useRef<string[]>([]);

  const userAttributeList = useRef<UserAttribute[]>([]);
  const [isLoading, setLoading] = useState(false);

  const [
    isOpenDeletetUserAndUserAttributeConfirmDialog,
    setIsOpenDeletetUserAndUserAttributeConfirmDialog,
  ] = useState(false);
  const [
    isOpenSendEmailUserCreatedConfirmDialog,
    setIsOpenSendEmailUserCreatedConfirmDialog,
  ] = useState(false);

  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isReload, setReload] = useState(false);

  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'adminAccountListImport')
        ?.property ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
  }, [childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListUserAttributes,
          ]);
        const jaColumn = ['status.status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const dayCololmns = ['user.lastLoginAt'];
        const daySch = getDayFormetterDisplaySchema(formatterSch, dayCololmns, {
          dayOpts: { formatType: 'YYYY/MM/DD HH:mm' },
        });
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(daySch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'userAttribute',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'userAttribute',
    headerColumns: [],
  };

  const headerLabelId: MessageProps = {
    prefixId: 'DIALOG',
    id: 'ADMIN_ACCOUNT_LIST',
  };

  const handleMenu = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      actionType: 'add',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/admin-account-input', { state });
  };

  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/admin-account-confirmation', {
      state,
    });
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  // アカウントを削除する
  const handleDeletetUserAndUserAttribute = () => {
    (async () => {
      setLoading(true);
      setReload(false);
      try {
        const userAttributes = userAttributeList.current.filter((item) =>
          targetUsetAttributeIds.current.includes(item.userAttributeId || '')
        );
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteAdminAccountList',
          request: userAttributes,
        });
        success([successMessage]);
        clearCheckBox();
        setReload(true);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      } finally {
        setIsOpenDeletetUserAndUserAttributeConfirmDialog(false);
        setLoading(false);
      }
    })();
  };

  // アカウントに作成完了通知を送信する
  const handleSendEmailUserCreated = () => {
    (async () => {
      setLoading(true);
      setReload(false);
      try {
        const userAttributes = userAttributeList.current.filter((item) =>
          targetUsetAttributeIds.current.includes(item.userAttributeId || '')
        );

        const updates: Promise<mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse>[] =
          [];
        for (const userAttr of userAttributes) {
          if (!userAttr.user) continue;
          updates.push(
            window.App.services.tenantAdminService.sendEmailUserCreated({
              user: userAttr.user,
            })
          );
        }
        await Promise.all(updates);
        setReload(true);
        success([successMessage]);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setIsOpenSendEmailUserCreatedConfirmDialog(false);
        setLoading(false);
      }
    })();
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: (v?: string[]) => handleMenu(v),
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        targetUsetAttributeIds.current = v as string[];
        handleMenuConfirm(v);
      },
    });
    // アップロード
    menuItems.push({
      name: 'upload',
      func: () => setDialogOpen(true),
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        targetUsetAttributeIds.current = v as string[];
        setIsOpenDeletetUserAndUserAttributeConfirmDialog(true);
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        targetUsetAttributeIds.current = v as string[];
        handleMenuConfirm(v);
      },
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 作成通知送信
    menuItems.push({
      name: 'auto_reminder',
      func: (v?: string[]) => {
        targetUsetAttributeIds.current = v as string[];
        setIsOpenSendEmailUserCreatedConfirmDialog(true);
      },
    });
    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    userAttributeList.current = items as unknown as UserAttribute[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="AdminAccountList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'userAttributeId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListUserAttributes}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
            }}
            importDialogOption={{
              isDisplay: true,
              isDialogOpen,
              dialogPreset,
              headerLabelId: headerLabelId,
              handleExport: handleExport,
              handleImport: handleImport,
              onChangeState: (v) => {
                setDialogOpen(v);
              },
            }}
          />
          {/* 確認ダイアログ(アカウント削除) */}
          <ConfirmationDialog
            isOpen={isOpenDeletetUserAndUserAttributeConfirmDialog}
            viewMessage={deletetUserAndUserAttributeMessage}
            onDecision={() => handleDeletetUserAndUserAttribute()}
            onCancel={() =>
              setIsOpenDeletetUserAndUserAttributeConfirmDialog(false)
            }
          />
          {/* 確認ダイアログ(作成完了通知送信) */}
          <ConfirmationDialog
            isOpen={isOpenSendEmailUserCreatedConfirmDialog}
            viewMessage={sendEmailUserCreatedMessage}
            onDecision={() => handleSendEmailUserCreated()}
            onCancel={() => setIsOpenSendEmailUserCreatedConfirmDialog(false)}
          />
        </div>
        <Toast />
        {/* <ImportDialog
          isOpen={isDialogOpen}
          headerLabelId={headerLabelId}
          allIds={[]}
          ids={[]}
          preset={[]}
          handleExport={handleExport}
          handleImport={handleImport}
          onChangeState={(v) => {
            setDialogOpen(false);
          }}
          onHandleSuccess={(v) => {
            setDialogOpen(false);
          }}
          onChangeLoadingState={setLoading}
        /> */}
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
