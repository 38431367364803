/**
 * sharelib の汎用型に変換する処理
 */

import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListComponentUnits,
  FullMethodName_ListStaffs,
  FullMethodName_ListUserAttributes,
} from '../fullMethodName';

// APIでComponentUnitReference型で登録する際に利用
export async function convertComponentUnitReference(
  componentUnitId: string | undefined,
  displayName: string | undefined
): Promise<sharelib.IComponentUnitReference | null> {
  const result = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListComponentUnits,
    filter: {
      componentUnitId: {
        $eq: componentUnitId ?? '',
      },
    },
    sort: [],
  })) as mtechnavi.api.company.ListComponentUnitsResponse;

  // 該当するデータがなければ、表示上データが表示されていてもnullで登録する
  if (result.items.length === 0) {
    return null;
  }

  const item = result.items.shift();

  return {
    componentUnitId: item!.componentUnitId,
    componentUnitCode: item!.componentUnitCode,
    displayNameLang: { ja: displayName ?? '' }, //表示時の情報を登録
  };
}

// APIでUserReference型で登録する際に利用
export async function convertUserReference(
  email: string
): Promise<sharelib.IUserReference | null> {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListUserAttributes,
    filter: {
      'user.email': {
        $eq: email,
      },
    },
    sort: [],
  })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;
  // 該当するデータがなければ、表示上データが表示されていてもnullで登録する
  if (result.items.length === 0) {
    return null;
  }

  const item = result.items.shift();

  return {
    userId: item!.userAttributeId,
    email: item!.user!.email,
    displayName: item!.user!.displayName,
  };
}

// APIでStaffReference型で登録する際に利用
export async function convertStaffReference(
  staffId: string | undefined,
  displayName: string | undefined
): Promise<sharelib.IStaffReference | null> {
  const result = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListStaffs,
    filter: {
      staffId: {
        $eq: staffId ?? '',
      },
    },
    sort: [],
  })) as mtechnavi.api.company.ListStaffsResponse;

  // 該当するデータがなければ、表示上データが表示されていてもnullで登録する
  if (result.items.length === 0) {
    return null;
  }

  const item = result.items.shift();

  return {
    staffId: item!.staffId,
    staffCode: item!.staffCode,
    displayNameLang: { ja: displayName ?? '' }, //表示時の情報を登録
  };
}
