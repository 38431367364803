import React from 'react';
import './TaskListIconView.css';
import { GenerallyIconType } from '../../parts/Button/GenerallyIcons';
import { IconButton } from '../Button';

export interface TaskListIconViewProps {
  icon: GenerallyIconType;
  mode?: 'bell' | 'none';
  onClick?: () => void;
}

export function TaskListIconView(props: TaskListIconViewProps): JSX.Element {
  const { icon, mode = 'none', onClick } = props;
  return (
    <div className={`TaskListIconView ${mode}`}>
      <IconButton
        name="task-icon"
        buttonType="basic"
        iconType={icon}
        onClick={() => onClick && onClick()}
        properties={[]}
      />
      <div className="unread-indicator"></div>
    </div>
  );
}
