import { useEffect, useRef, useState } from 'react';
import { Container, Toast, error } from '~/shared/components';
import {
  Textbox,
  Tel,
  ZipCode,
  Checkbox,
  Image,
  SimplePageNavigation,
} from '~/shared/components/ui';

import {
  GetMessage,
  GetMessageComponent,
} from '~/shared/components/parts/Message/Message';
import { PresetItem } from '~/shared/services';
import './CompanyComponentUnit.css';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useIntl } from 'react-intl';
import {
  PageState,
  ViewId,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  FullMethodName_ListComponentUnits,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

const VIEW_ID: ViewId = 'MASTER_COMPONENT_UNIT_CONFIRMATION';

// 管理場所種別item 現状べた書き
function getComponentUnitItem(name: string) {
  return [
    {
      displayName: GetMessage({ id: name }),
      value: '1',
    },
  ];
}

export function CompanyComponentUnit() {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navi = useNavigate();
  const handleError = useErrorHandler();
  // 遷移元から値を受け取る
  const location = useLocation().state as PageState;

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListComponentUnits,
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      componentUnitId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('MASTER_COMPONENT_UNIT_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // 初回画面用データ
  useEffect(() => {
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListComponentUnits,
      pageNumber: 1,
    });

    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [item, setItem] = useState<mtechnavi.api.company.ComponentUnit>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.company.ComponentUnit[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListComponentUnits,
      pageNumber: n,
    });
  };

  // アコーディオン開閉処理
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    basic: true,
    place: true,
    linkage: true,
  });
  const handleAccordion = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <Container viewId={VIEW_ID}>
      <div className="CompanyComponentUnit">
        <div className="header"></div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="info">
            <div
              className={`input-blocktitle-outer ${
                accordionState.basic ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('basic')}
              >
                <GetMessageComponent id="basicInfoTitle" />
              </h3>
            </div>
            {accordionState.basic && (
              <div className="basic-info-body">
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <Textbox
                        name="displayNameLang.ja"
                        className="field"
                        value={item?.displayNameLang?.ja ?? ''}
                        type="text"
                        labelId="unitPlaceName"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                    <div className="w-25">
                      <Textbox
                        name="usable.displayNameLang.ja"
                        value={item?.usable?.displayNameLang?.ja ?? ''}
                        type="text"
                        labelId="usable"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <Textbox
                        name="displayNameLang.ja-kana"
                        value={item?.displayNameLang?.['ja-kana'] ?? ''}
                        type="text"
                        labelId="unitPlaceNameKana"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <Textbox
                        name="componentUnitCode"
                        value={item?.componentUnitCode}
                        type="text"
                        labelId="componentUnitCodeLabel"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <Tel
                        name="phoneNumber"
                        value={item?.phoneNumber}
                        labelId="phoneNumber"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                    <div className="w-25">
                      <Textbox
                        name="placeProperties.displayNameLang.ja"
                        value={item?.placeProperties?.displayNameLang?.ja ?? ''}
                        type="text"
                        labelId="place"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <ZipCode
                        name="address.postalCode"
                        value={item?.address?.postalCode ?? ''}
                        labelId="postCode"
                        columns={preset.columns}
                        disabled={true}
                      />
                      <Textbox
                        name="address.addressLine"
                        value={item?.address?.addressLine}
                        type="text"
                        labelId="address"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                    <div className="w-50">
                      <Image
                        name="placeProperties.image.assetId"
                        src={{
                          assetId: item?.placeProperties?.image?.assetId ?? '',
                        }}
                        className=""
                        columns={preset.columns}
                        labelId={{ prefixId: 'DIALOG', viewId: VIEW_ID }}
                        viewerControls={{ scaling: true, downloading: true }}
                        modalDialogControls={{ showCloseHeaderButton: true }}
                      ></Image>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`input-blocktitle-outer ${
                accordionState.place ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('place')}
              >
                <GetMessageComponent id="componentUnitTitle" />
              </h3>
            </div>
            {accordionState.place && (
              <div className="place-info-body">
                <div className="input-line">
                  <div>
                    {/* 返却データの該当部分が空だとチェックボックスをOFF、データがあればONなのでベタがき */}
                    <Checkbox
                      name="organizationUnit"
                      items={getComponentUnitItem('componentUnitOrganization')}
                      value={item?.organizationUnit?.categoryName ? '1' : ''}
                      columns={preset.columns}
                      disabled={true}
                    ></Checkbox>
                    <Checkbox
                      name="deliveryPoint"
                      items={getComponentUnitItem('componentUnitDelivery')}
                      value={item?.deliveryPoint?.categoryName ? '1' : ''}
                      columns={preset.columns}
                      disabled={true}
                    ></Checkbox>
                    <Checkbox
                      name="office"
                      items={getComponentUnitItem('componentUnitOffice')}
                      value={item?.office?.categoryName ? '1' : ''}
                      columns={preset.columns}
                      disabled={true}
                    ></Checkbox>
                    <div className="indent">
                      <Checkbox
                        name="officeProperties"
                        items={getComponentUnitItem('componentUnitHeadOffice')}
                        value={
                          // 本店および支店で`checked=true`にするかの判定を行う必要があるため、categoryNameだけでなくcodeも確認する
                          item?.officeProperties?.officeRank?.categoryName &&
                          item?.officeProperties?.officeRank?.code === '1'
                            ? '1'
                            : ''
                        }
                        columns={preset.columns}
                        disabled={true}
                      ></Checkbox>
                    </div>
                    <Checkbox
                      name="postUnit"
                      items={getComponentUnitItem('componentUnitPost')}
                      value={item?.postUnit?.categoryName ? '1' : ''}
                      columns={preset.columns}
                      disabled={true}
                    ></Checkbox>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`input-blocktitle-outer ${
                accordionState.linkage ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('linkage')}
              >
                <GetMessageComponent id="linkageItemTitle" />
              </h3>
            </div>
            {accordionState.linkage && (
              <div className="linkage-item-body">
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      {item?.linkageItem &&
                        item?.linkageItem.map((v, i) => {
                          return (
                            <Textbox
                              key={i}
                              name="linkageItem"
                              className="field"
                              value={v}
                              type="text"
                              labelId={`linkageItem${i + 1}`}
                              columns={preset.columns}
                              disabled={true}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <CaptionButton
                name=""
                caption="戻る"
                properties={[]}
                onClick={() => {
                  navi('/company-component-unit-list');
                }}
                buttonType="basic"
              />
              <SimplePageNavigation
                pageInfo={{
                  isFirstPage,
                  isMaxPage,
                  pageNumber: page.pageNumber,
                }}
                handleMovePage={handleMovePage}
              />
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </Container>
  );
}
