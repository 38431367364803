import { PropsWithoutRef } from 'react';
import Long from 'long';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  Amount,
  AmountBox,
  AmountBoxDisplayOption,
  AmountBoxValidateOption,
} from '~/shared/components/parts/Amountbox/AmountBox';
import { IconButton } from '~/shared/components/ui/Button';
import './base.css';
import { IntlShape } from 'react-intl';

interface FormInputAmountProps {
  intl: IntlShape;
  name: string;
  label?: string;
  description?: string;
  value?: mtechnavi.api.form.IFormControlNumber | null;
  validateOption?: AmountBoxValidateOption;
  displayOption?: AmountBoxDisplayOption;
  disabled?: boolean;
  onChange?: (v: mtechnavi.api.form.IFormControlNumber | null) => void;
}
export const FormInputAmount = (
  props: PropsWithoutRef<FormInputAmountProps>
) => {
  const value: Amount | null = props.value
    ? {
        integralAmount: Long.fromString(props.value.integralAmount || '0'),
        fractionalAmount: Number(props.value.fractionalAmount),
      }
    : null;

  const handleChangeValue = (value: Amount | null) => {
    if (!props?.onChange) {
      return;
    }
    if (!value) {
      props?.onChange(null);
      return;
    }
    const { fractionalAmount, integralAmount } = value;
    props?.onChange({
      fractionalAmount:
        fractionalAmount !== undefined ? `${fractionalAmount}` : null,
      integralAmount:
        integralAmount !== undefined ? integralAmount.toString() : null,
    });
  };

  return (
    <div className="FormInput FormInputAmount">
      <span className={props.label !== '' ? 'caption' : 'caption no-name'}>
        {props.label && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label topping-label-with-help ${
                props.disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.label || ''}
              {!!props.description && (
                <IconButton
                  name="help"
                  className="HelpIcon"
                  iconType="help"
                  buttonType="basic"
                  onClick={() => {}}
                  caption={props.description}
                />
              )}
            </div>
          </div>
        )}
      </span>
      <AmountBox
        name={props.name}
        value={value}
        validateOption={props.validateOption}
        displayOption={props.displayOption}
        disabled={!!props.disabled}
        onChangeState={handleChangeValue}
      />
    </div>
  );
};
