import { useState } from 'react';
import { CommentDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleNotificationUpdateStartStopDialogs = () => {
  const [startResult, setStartResult] = useState('');
  const [startComment, setStartComment] = useState('');
  const [isShowStart, setIsShowStart] = useState(false);
  const [stopResult, setStopResult] = useState('');
  const [stopComment, setStopComment] = useState('');
  const [isShowStop, setIsShowStop] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">通知更新停止/再開ダイアログ</h3>

      <h4>通知更新再開ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setStartResult('');
                setIsShowStart(true);
              }}
            />
            <CommentDialog
              isOpen={isShowStart}
              inputOption={{ comment: startComment }}
              inputStateOption={{ onChangeComment: setStartComment }}
              messageOption={{
                headerLabelId: {
                  id: 'notificationUpdateStart',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'notificationUpdateStart',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              onDecision={() => {
                setStartResult(`送信しました。コメント：${startComment}`);
                setStartComment('');
                setIsShowStart(false);
              }}
              onCancel={() => {
                setStartResult('キャンセル');
                setIsShowStart(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{startResult}</pre>
          </div>
        </div>
      </div>

      <h4>通知更新停止ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setStopResult('');
                setIsShowStop(true);
              }}
            />
            <CommentDialog
              isOpen={isShowStop}
              inputOption={{ comment: stopComment }}
              inputStateOption={{ onChangeComment: setStopComment }}
              messageOption={{
                headerLabelId: {
                  id: 'notificationUpdateStop',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'notificationUpdateStop',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              onDecision={() => {
                setStopResult(`送信しました。コメント：${stopComment}`);
                setStopComment('');
                setIsShowStop(false);
              }}
              onCancel={() => {
                setStopResult('キャンセル');
                setIsShowStop(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{stopResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
