import { useEffect, useRef, useState } from 'react';
import { Container, Toast, LoadingIcon, GetMessage } from '~/shared/components';
import {
  Accordion,
  Textbox,
  NavigationIconMenu,
  SimpleListView,
  GoogleCertificateSettingDialog,
} from '~/shared/components/ui';

import dayjs from 'dayjs';
import { ViewId, getMaxMainContentsHeight } from '~/shared/utils';
import { PageNavigation } from '~/shared/components/ui/PageNavigation/PageNavigation';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import { useAuth } from '~/shared/contexts/AuthProvider';
import Long from 'long';
import './AdminTenantSettingConfirmation.css';

const VIEW_ID: ViewId = 'ADMIN_TENANT_SETTING_CONFIRMATION';

export function AdminTenantSettingConfirmation() {
  // ログイン者の情報特定用email
  const auth = useAuth();
  const [isLoading, setLoading] = useState(false);

  // 遷移元から値を受け取る
  const [
    isOpenGoogleCertificateSettingDialog,
    setOpenGoogleCertificateSettingDialog,
  ] = useState(false);

  // 仮データ
  const licenseData = [
    {
      licenseEnabled: '○',
      licenseName: '調査依頼',
      licenseStartDt: 'XXXX/XX/XX',
      licenseEndDt: 'XXXX/XX/XX',
    },
    {
      licenseEnabled: '○',
      licenseName: '取引先管理',
      licenseStartDt: 'XXXX/XX/XX',
      licenseEndDt: 'XXXX/XX/XX',
    },
    {
      licenseEnabled: '-',
      licenseName: '見積管理',
      licenseStartDt: 'XXXX/XX/XX',
      licenseEndDt: 'XXXX/XX/XX',
    },
    {
      licenseEnabled: '-',
      licenseName: '指図案件',
      licenseStartDt: 'XXXX/XX/XX',
      licenseEndDt: 'XXXX/XX/XX',
    },
  ];

  // 初回画面用データ
  useEffect(() => {
    // 読み込みをしている風にするための偽装ローディング
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // preset
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleMenuInput();
      },
    });

    return iconItems;
  };

  // 編集
  const handleMenuInput = () => {
    console.debug('今後作成');
  };

  const getLabelId = (column: string): string => {
    return `${VIEW_ID}.${column}`;
  };

  const coa = new Date(dayjs('2024/3/31 12:34:56').format('YYYY/MM/DD HH:mm'));
  const nowDate = Long.fromNumber(coa.getTime()).multiply(1000);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="header">
          <PageNavigation
            backpagePath="/admin-account-list"
            pageInfo={{
              isUnvisibleBackNavi: true,
              isVisibleMoveNavi: false,
            }}
            iconItems={navigationIconItems()}
            infoOption={{
              lastUpdateInfo: {
                isVisibleUpdateInfo: true,
                attributeAt: nowDate,
                content: '鈴木太郎',
                attribute: 'suzuki@gmail.com',
              },
              issuerInfo: { isVisibleIssuerInfo: false },
            }}
          />
        </div>
        <div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  <Textbox
                    name="tenantName"
                    className="field"
                    value={auth.tenant?.displayName ?? ''}
                    type="text"
                    labelId={getLabelId('tenantName')}
                    columns={['tenantName']}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="input-line">
              <div className="item-group-100">
                <div className="w-20">
                  <Textbox
                    name="tenantCode"
                    className="field"
                    value={auth.tenant?.tenantCode ?? ''}
                    type="text"
                    labelId={getLabelId('tenantCode')}
                    columns={['tenantCode']}
                    disabled={true}
                  />
                </div>
                <div className="w-40">
                  <Textbox
                    name="tenantUrl"
                    className="field"
                    value={`https://${auth.tenant?.domain}`}
                    type="text"
                    labelId={getLabelId('tenantUrl')}
                    columns={['tenantUrl']}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <Accordion
              title={GetMessage({ id: 'companyTitle', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="companyName"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('companyName')}
                      columns={['companyName']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="adminName"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('adminName')}
                      columns={['adminName']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="adminEmail"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('adminEmail')}
                      columns={['adminEmail']}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="companyCountry"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('companyCountry')}
                      columns={['companyCountry']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="companyRegion"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('companyRegion')}
                      columns={['companyRegion']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="companyAddress"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('companyAddress')}
                      columns={['companyAddress']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="companyPhoneNumber"
                      className="field"
                      value={''}
                      type="text"
                      labelId={getLabelId('companyPhoneNumber')}
                      columns={['companyPhoneNumber']}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              title={GetMessage({ id: 'licenseTitle', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <SimpleListView
                      data={licenseData}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { viewId: VIEW_ID, id: 'licenseEnabled' },
                            propertyName: 'licenseEnabled',
                            width: '8%',
                          },
                          {
                            header: { viewId: VIEW_ID, id: 'licenseName' },
                            propertyName: 'licenseName',
                            width: '56%',
                          },
                          {
                            header: { viewId: VIEW_ID, id: 'licenseStartDt' },
                            propertyName: 'licenseStartDt',
                            width: '18%',
                          },
                          {
                            header: { viewId: VIEW_ID, id: 'licenseEndDt' },
                            propertyName: 'licenseEndDt',
                            width: '18%',
                          },
                        ],
                        omitFooter: false,
                      }}
                      actionOptions={{}}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            <Accordion
              title={GetMessage({ id: 'certificateTitle', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="certificateWork"
                      className="field"
                      value={'SSO'}
                      type="text"
                      labelId={getLabelId('certificateWork')}
                      columns={['certificateWork']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="ssoProvider"
                      className="field"
                      value={'Googleアカウント'}
                      type="text"
                      labelId={getLabelId('ssoProvider')}
                      columns={['ssoProvider']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="ssostatus"
                      className="field"
                      value={'設定済み'}
                      type="text"
                      labelId={getLabelId('ssostatus')}
                      columns={['ssostatus']}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20 attachment-area">
                    <div className="upload-button">
                      <CaptionButton
                        buttonType="basic"
                        caption={GetMessage({
                          id: 'ssostatusBtn',
                          viewId: VIEW_ID,
                        })}
                        name="add"
                        onClick={() => {
                          setOpenGoogleCertificateSettingDialog(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <GoogleCertificateSettingDialog
          isOpen={isOpenGoogleCertificateSettingDialog}
          inputOption={{
            ssosetting:
              '◯◯にてメタデータをダウンロードし、「メタデータアップロード」ボタンからアップロードしてください。',
            ssourl: 'https://XXXXXXXXXX',
            entityId: 'https://yyyyyyyy',
            cetificateExpandDt: '',
            certificateBody: '',
          }}
          onCancel={() => {
            setOpenGoogleCertificateSettingDialog(false);
          }}
          onDecision={() => {
            setOpenGoogleCertificateSettingDialog(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
