/*
  ListViewで、共通的に使用する関数
*/

import {
  BurgerMenuName,
  IconMenuName,
  MenuActionType,
  FooterMenuName,
} from './commonType';

import { error } from '../../parts/Toast/Toast';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { IntlShape } from 'react-intl';

/**
 * ヘッダーアクション実行時にチェックボックスが空でもエラーとしないMenuName
 */
export const NO_CHECK_ACTION_MENU_NAME: BurgerMenuName[] = [
  'add',
  'import',
  'staffImport',
  'estimateRequestInputAdd',
  'estimateResultList',
  'tag',
  'drawingInfoSetting',
  'businessUnitImport',
  'partialDeliveryAdd',
  'partialAmountAdd',
];

/**
 * ヘッダーアクション実行時にチェックボックスが空でもエラーとしないMenuName
 */
export const NO_CHECK_ICON_MENU_NAME: IconMenuName[] = [
  'noteadd',
  'back',
  'upload',
  'export',
  'recipients',
];

/**
 * フッターアクション実行時にチェックボックスが空でもエラーとしないMenuName
 */
export const NO_CHECK_FOOTER_MENU_NAME: FooterMenuName[] = ['input'];

export const isActionableMenu = (
  menuType: MenuActionType,
  menuName: BurgerMenuName | IconMenuName | FooterMenuName,
  ids: string[]
): boolean => {
  if (ids.length === 0) {
    switch (menuType) {
      case 'allBurgerMenu':
        if (!NO_CHECK_ACTION_MENU_NAME.includes(menuName as BurgerMenuName)) {
          return false;
        }
        break;
      case 'headerIconMenu':
        if (!NO_CHECK_ICON_MENU_NAME.includes(menuName as IconMenuName)) {
          return false;
        }
        break;
      case 'footerMenu':
        if (!NO_CHECK_FOOTER_MENU_NAME.includes(menuName as FooterMenuName)) {
          return false;
        }
        break;
      default:
        return true;
    }
  }
  return true;
};

export const createRandomKey = () => {
  return Math.random().toString(32).substring(2);
};

export const getMenuItemType = (
  presetName: string
): MenuActionType | undefined => {
  switch (presetName) {
    case 'allMenu':
      return 'allBurgerMenu';
    case 'listMenu':
      return 'listBurgerMenu';
    case 'iconHeaderMenu':
      return 'headerIconMenu';
    case 'iconListMenu':
      return 'listIconMenu';
    case 'footerMenu':
      return 'footerMenu';
    case 'footerInformation':
      return 'footerInformation';
    default:
      return;
  }
};

export const isValidMenuValue = (
  intl: IntlShape,
  menuType: MenuActionType,
  menuName: BurgerMenuName | IconMenuName | FooterMenuName,
  ids: string[]
): boolean => {
  if (!isActionableMenu(menuType, menuName, ids)) {
    error([
      GetMessageWithIntl(intl, {
        id: 'E0000023',
      }),
    ]);
    return false;
  }
  return true;
};
