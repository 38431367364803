import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { Checkbox } from '~/shared/components/ui';
import { IconButton } from '~/shared/components/ui/Button';

interface GroupTreeControlsProps {
  isShowDisabled: boolean;
  onOpenAll: () => void;
  onCloseAll: () => void;
  onToggleShowDisable: (isShow: boolean) => void;
}

/**
 * ツリー全体操作 UI コンポーネント
 */
export const GroupTreeControls = ({
  isShowDisabled,
  onOpenAll,
  onCloseAll,
  onToggleShowDisable,
}: GroupTreeControlsProps) => {
  const intl = useIntl();

  return (
    <div className="GroupTreeControls">
      <IconButton
        name="import"
        buttonType="basic"
        iconType="upload"
        caption="取込"
        onClick={() => {}}
      />
      <div className="link-button" onClick={onOpenAll}>
        {GetMessageWithIntl(intl, {
          id: 'openAll',
        })}
      </div>
      <div className="link-button" onClick={onCloseAll}>
        {GetMessageWithIntl(intl, {
          id: 'closeAll',
        })}
      </div>
      <Checkbox
        name="isShowDisabled"
        className="group"
        items={[
          {
            value: '1',
            displayName: '使用不可を表示',
          },
        ]}
        value={isShowDisabled ? '1' : ''}
        columns={['isShowDisabled']}
        onChangeState={(checkedValues) =>
          onToggleShowDisable(checkedValues.includes('1'))
        }
      />
    </div>
  );
};
