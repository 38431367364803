import { IntlShape } from 'react-intl';
import {
  CommmonAttachedFileSystemCode,
  createAttachmentAsset,
  getExceptionMessage,
} from '~/shared/utils';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { FileItem } from './CommonType';
import Long from 'long';

export const DEFAULT_MAX_FILE_LIMIT = 1000;

export const UNIT_MiB = Math.pow(1000, 2); //バイトからMBに変換(10進数)

// Filelistを配列に変換
export const fileListToArray = (fileList: FileList) => {
  const l: File[] = [];
  for (let i = 0; i < fileList.length; i++) {
    l.push(fileList[i]);
  }
  return l;
};

// ファイルのバリデーション
export const validateFile = (
  intl: IntlShape,
  uploads: File[],
  extensions: string[],
  maxSize: number //byte単位で渡す
): [File[], string[]] => {
  const msg: string[] = [];

  uploads.forEach((v) => {
    // 許可していないファイル形式があった場合
    if (
      extensions.length > 0 &&
      !extensions.includes(v.name.split('.').pop() ?? '')
    ) {
      msg.push(
        GetMessageWithIntl(intl, { id: 'E0000005', value: { $1: v.name } })
      );
    }

    // 指定サイズよりファイルサイズが大きいものがあった場合
    if (maxSize > 0 && maxSize < v.size) {
      msg.push(
        GetMessageWithIntl(intl, {
          id: 'E0000006',
          value: {
            $1: v.name,
            $2: Math.floor((v.size / UNIT_MiB) * 10) / 10,
            $3: maxSize / UNIT_MiB,
          },
        })
      );
    }
  });

  // 問題のないFile[]とエラーメッセージを返す
  return [
    uploads.filter((v) => {
      return (
        (!extensions.length ||
          extensions.includes(v.name.split('.').pop() ?? '')) &&
        (maxSize <= 0 || maxSize >= v.size)
      );
    }),
    msg,
  ];
};

//アップロード処理
export const uploadFiles = async (
  files: File[],
  setFileItems: (v: FileItem[]) => void,
  intl: IntlShape
) => {
  const items = ((): Array<FileItem> => {
    return files.map((v) => {
      return {
        status: 'uploading',
        file: v,
      };
    });
  })();
  setFileItems(items);
  const tasks = items.map(async (item) => {
    const tempItem = item;
    try {
      const signedUrl =
        await window.App.services.assetInventory.generateWritableSignedUrl({
          expires: Long.fromValue(3600),
          contentType: tempItem.file.type,
        });
      await putObjectViaSignedUrl(signedUrl.url, tempItem.file);
      tempItem.status = 'OK';
      tempItem.url = signedUrl.url;
    } catch (err) {
      getExceptionMessage(intl, err);
      console.error(err);
      tempItem.status = 'failure';
    } finally {
      setFileItems([
        ...items.filter((v) => v.file !== tempItem.file),
        tempItem,
      ]);
    }
    return tempItem;
  });
  return Promise.all(tasks);
};

const putObjectViaSignedUrl = async (
  signedUrl: string,
  file: File
): Promise<void> => {
  const res = await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });
  if (!!res.ok) {
    return;
  }
  throw new Error(`${res.status} ${res.statusText}: ${await res.text()}`);
};

/**
 * input[type=file] の入力値をアップロードし、登録した IAsset を返却する。
 */
export const uploadAndCreateAsset = async (
  files: File[],
  systemCode: CommmonAttachedFileSystemCode,
  setFileItems: (v: FileItem[]) => void,
  intl: IntlShape
) => {
  const uploadedFiles = await uploadFiles(files, setFileItems, intl);
  if (uploadedFiles.some((file) => file.status !== 'OK')) {
    return [];
  }
  return await createAttachmentAsset({ files: uploadedFiles }, systemCode);
};
