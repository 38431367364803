import { PresetItem, Property as PresetProperty } from '~/shared/services';

export const getMultiplePresetItem = (
  name: string,
  presetItems?: PresetItem[]
): PresetItem[] => {
  return (presetItems ?? []).filter((v) => v.name === name);
};

export const getPresetItem = (
  name: string,
  presetItems?: PresetItem[]
): PresetItem => {
  return presetItems?.find((v) => v.name === name) ?? { name: name };
};

export const getPresetItemColums = (
  name: string,
  presetItems?: PresetItem[]
): string[] => {
  const presetItem = getPresetItem(name, presetItems);
  return presetItem?.columns ?? [];
};

const existProperty = (columnName: string, propertyName: string) => {
  return (v: PresetProperty) => {
    return v.propertyName === propertyName && v.name === columnName;
  };
};

export const getPresetProperty = (
  presetProperty: PresetProperty[] | undefined,
  columnName: string,
  propertyName: string
) => {
  return presetProperty?.find(existProperty(columnName, propertyName));
};

export const getPresetPropertyValue = (
  presetProperty: PresetProperty[] | undefined,
  columnName: string,
  propertyName: string
): string => {
  const property = getPresetProperty(presetProperty, columnName, propertyName);
  return property?.propertyValue ?? '';
};

export const getPresetPropertysByColumnName = (
  presetProperty: PresetProperty[] | undefined,
  columnName: string
): PresetProperty[] => {
  return presetProperty?.filter((v) => v.name === columnName) ?? [];
};

export const getPresetPropertysByPropertyName = (
  presetProperty: PresetProperty[] | undefined,
  propertyName: string
): PresetProperty[] => {
  return presetProperty?.filter((v) => v.propertyName === propertyName) ?? [];
};

export const checkDisalbedPresetProperty = (
  column: string,
  presetItems?: PresetItem[]
): boolean => {
  const disabledColumn = getPresetItemColums('disabledFilterItem', presetItems);
  return disabledColumn.includes(column);
};
