import { useEffect, useState } from 'react';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
} from '~/shared/components';
import { ViewId, FullMethodName_ListCompanyContents } from '~/shared/utils';
import { Tabs } from '~/shared/components/ui';
import './CompanyConfirmation.css';
import { CompanyConfirmationBasic } from './parts/CompanyConfirmationBasic';
import { CompanyConfirmationSkill } from './parts/CompanyConfirmationSkill';
import { CompanyConfirmationBranch } from './parts/CompanyConfirmationBranch';
import { CompanyConfirmationStrength } from './parts/CompanyConfirmationStrength';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLocation } from 'react-router-dom';
import { BusinessUnitAttributes } from '../converter';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { ConfirmationPagination } from './parts/ConfirmationPagination';
import { CompanySearchLocationState } from '../util';

const VIEW_ID: ViewId = 'POC_COMPANY_CONFIRMATION';
const LIST_VIEW_ID: ViewId = 'POC_COMPANY_LIST';
export const CompanyConfirmation = () => {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  // 遷移元から値を受け取る
  const location = (useLocation().state as CompanySearchLocationState) ?? [];

  const [isLoading, setLoading] = useState(false);

  const [currentCompany, setCurrentCompany] =
    useState<mtechnavi.api.pocsearchcompany.IViewCompany>();
  const [skillTreeData, setSkillTreeData] = useState<BusinessUnitAttributes>();

  const labelContact = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'contact',
  });

  useEffect(() => {
    (async () => {
      const attributesRawData = await fetch(
        '/assets/conditions/BusinessUnitAttribute.json'
      );
      const attributes =
        (await attributesRawData.json()) as BusinessUnitAttributes;
      setSkillTreeData(attributes);
    })();
  }, []);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="CompanyConfirmation">
          <div className="header">
            <ConfirmationPagination
              viewId={VIEW_ID}
              listViewId={LIST_VIEW_ID}
              fullMethodName={FullMethodName_ListCompanyContents}
              requestBody={{
                companyIds: location.pageSetting?.ids ?? [],
              }}
              filter={{
                companyId: { $in: location.pageSetting?.ids ?? [] },
              }}
              myEmail={myEmail}
              currentItemId={currentCompany?.companyId ?? ''}
              onChangeItem={(v) => {
                setCurrentCompany(
                  (v as mtechnavi.api.pocsearchcompany.IViewCompany[]).pop()
                );
              }}
              onChangeLoadingState={setLoading}
            />
            <CaptionButton
              buttonType="basic"
              name="contact"
              className="contact"
              caption={labelContact}
              onClick={() => {}}
            />
          </div>
          <div className="company-name">
            {currentCompany?.profile?.displayNameLangJa}
          </div>
          <Tabs
            tabItems={[
              {
                label: '基本情報',
                tabContent: (
                  <CompanyConfirmationBasic
                    viewId={VIEW_ID}
                    profile={currentCompany?.profile ?? {}}
                    certificates={currentCompany?.certificates ?? []}
                    financials={currentCompany?.financials ?? []}
                  />
                ),
              },
              {
                label: '拠点 / 設備',
                tabContent: (
                  <CompanyConfirmationBranch
                    viewId={VIEW_ID}
                    facilitiess={currentCompany?.facilities ?? []}
                    branches={currentCompany?.branchs ?? []}
                  />
                ),
              },
              {
                label: '強み',
                tabContent: (
                  <CompanyConfirmationStrength
                    viewId={VIEW_ID}
                    strengths={currentCompany?.strengths ?? []}
                    facilitiess={currentCompany?.facilities ?? []}
                    branches={currentCompany?.branchs ?? []}
                  />
                ),
              },
              {
                label: '技術情報',
                tabContent: (
                  <CompanyConfirmationSkill
                    viewId={VIEW_ID}
                    skills={currentCompany?.skills ?? []}
                    skillTree={skillTreeData}
                  />
                ),
              },
            ]}
          />
        </div>
      </Container>
      <Toast />
      {isLoading && <LoadingIcon />}
    </>
  );
};
