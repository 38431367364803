import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button';
import { Textarea } from '~/shared/components/ui';
import { Radio, RadioItem } from '~/shared/components/parts/Radio/Radio';
import { includeInputValidateError } from '~/shared/utils';

export type SurveyReplyDialogMode =
  | 'confirmation'
  | 'reply'
  | 'unnecessary'
  | 'estimateResult';
type SurveyReplyStatus =
  | 'confirmed'
  | 'completed'
  | 'partial_replyed'
  | 'unnecessary'
  | 'sendReply'
  | 'decline'
  | '';

export interface SurveyReplyDialogOutputOption {
  initialItem?: SurveyReplyStatus;
  isDisabled?: boolean;
  mode: SurveyReplyDialogMode;
  isCanPartialReply: boolean;
}

export interface SurveyReplyDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface SurveyReplyResult {
  replyStatus: SurveyReplyStatus;
  comment: string;
}

export interface SurveyReplyDialogProps {
  isOpen: boolean;
  outputOption: SurveyReplyDialogOutputOption;
  messageOption: SurveyReplyDialogMessageOption;
  onDecision: (result: SurveyReplyResult) => void;
  onCancel: () => void;
}

export const SurveyReplyDialog = ({
  isOpen,
  outputOption,
  messageOption,
  onDecision,
  onCancel,
}: SurveyReplyDialogProps) => {
  const intl = useIntl();
  const [replyStatus, setReplyStatus] =
    useState<SurveyReplyStatus>('completed');
  const [comment, setComment] = useState<string>('');
  // 必須チェック用にBlurするための変数
  const [workingBlurReply, setWorkingBlurReply] = useState<Date>();
  const requiredReplyArea = useRef(null);

  const messagePrefix = 'SurveyReplyDialog';

  const generateRadioItems = (
    mode: SurveyReplyDialogMode,
    isCanPartialReply: boolean
  ) => {
    const items: RadioItem[] = [];
    if (mode === 'confirmation') {
      items.push({
        displayName: GetMessageWithIntl(intl, {
          id: 'confirmation',
          prefixId: messagePrefix,
        }),
        value: 'confirmed',
      });
    }
    if (mode === 'reply') {
      items.push({
        displayName: GetMessageWithIntl(intl, {
          id: 'full_reply',
          prefixId: messagePrefix,
        }),
        value: 'completed',
      });
    }
    if (isCanPartialReply) {
      items.push({
        displayName: GetMessageWithIntl(intl, {
          id: 'partial_reply',
          prefixId: messagePrefix,
        }),
        value: 'partial_replyed',
      });
    }
    if (mode === 'estimateResult') {
      items.push({
        displayName: GetMessageWithIntl(intl, {
          id: 'send_reply',
          prefixId: messagePrefix,
        }),
        value: 'sendReply',
      });
      items.push({
        displayName: GetMessageWithIntl(intl, {
          id: 'decline',
          prefixId: messagePrefix,
        }),
        value: 'decline',
      });
    }
    return items;
  };

  const partialReplyStatus: RadioItem[] = generateRadioItems(
    outputOption.mode,
    outputOption.isCanPartialReply
  );

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.survey-reply-dialog');

    // ラジオボタン項目を強制的にBlurする
    setWorkingBlurReply(partialReplyStatus.length > 0 ? new Date() : undefined);
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: partialReplyStatus.length > 0 ? replyStatus : 'unnecessary',
          ref: requiredReplyArea,
        },
      ])
    ) {
      return true;
    }
    return false;
  };

  const handleDecision = (result: SurveyReplyResult) => {
    if (isInputError()) {
      return;
    }
    setWorkingBlurReply(undefined);
    onDecision(result);
  };

  useEffect(() => {
    if (isOpen) {
      if (outputOption.initialItem) {
        setReplyStatus(outputOption.initialItem);
      } else {
        setReplyStatus('');
      }
      setComment('');
    }
  }, [isOpen, outputOption.initialItem]);

  const elements = (
    <div className="comment-dialog survey-reply-dialog">
      <div className="comment-area">
        <div className="input-line" ref={requiredReplyArea}>
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="partial_reply"
                value={replyStatus}
                items={partialReplyStatus}
                validateOption={{ required: true }}
                onChangeState={(v) => {
                  setReplyStatus(v as SurveyReplyStatus);
                }}
                workingBlur={workingBlurReply}
                disabled={outputOption.isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="reply_comment"
                labelId={`${messagePrefix}.reply_comment`}
                columns={['reply_comment']}
                className="w-100 mh-middle"
                onChangeState={setComment}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="high"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'send' })}
          onClick={() => handleDecision({ replyStatus, comment })}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
