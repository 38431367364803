import {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CategorizedBranchRef } from './CategorizedBranch';
import { CategorizedTipCheckboxRef } from './CategorizedTipCheckbox';
import { CheckItem, allCheckIdFormat } from './util';
import './CategorizedCheckbox.css';

interface CategorizedBranchCheckboxKeyActions {
  onLeft?: (id: string) => void;
  onRight?: (id: string) => void;
  onUp?: (id: string) => void;
  onDown?: (id: string) => void;
}
export interface CategorizedBranchCheckboxProps {
  parentRef?: React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>;
  categoryId: string;
  containIds: CheckItem[];
  label: string;
  checked: boolean;
  disabled?: boolean;
  discreet?: boolean;
  onChange?: (containIds: CheckItem[], isChecked: boolean) => void;
  keyActions?: CategorizedBranchCheckboxKeyActions;
}
export interface CategorizedBranchCheckboxRef {
  focus: () => void;
  open: () => void;
  isOpen: () => boolean;
}
export const CategorizedBranchCheckbox = forwardRef(
  (props: CategorizedBranchCheckboxProps, ref) => {
    const inputId = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isChecked, setChecked] = useState<boolean>(!!props.checked);
    const checkId = useMemo(
      () => allCheckIdFormat(props.categoryId),
      [props.categoryId]
    );

    const handleChange = (eventElement: HTMLInputElement) => {
      setChecked(eventElement.checked);
      if (props?.onChange) {
        props?.onChange(props.containIds, eventElement.checked);
      }
    };

    const setFocus = () => {
      inputRef.current?.focus();
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'ArrowUp': {
          props.keyActions?.onUp && props.keyActions.onUp(checkId);
          break;
        }
        case 'ArrowDown': {
          props.keyActions?.onDown && props.keyActions.onDown(checkId);
          break;
        }
        case 'ArrowLeft': {
          props.keyActions?.onLeft && props.keyActions.onLeft(checkId);
          break;
        }
        case 'ArrowRight': {
          props.keyActions?.onRight && props.keyActions.onRight(checkId);
          break;
        }
      }
    };

    useEffect(() => setChecked(props.checked), [props.checked]);

    // 外から開閉を操作できるようにする
    useImperativeHandle(
      ref,
      (): CategorizedBranchCheckboxRef => ({
        focus: () => {
          // 親カテゴリを展開する
          props.parentRef?.current?.open();
          // DOMが表示されてからフォーカスをセットする
          setTimeout(() => setFocus(), 100);
        },
        open: () => {
          props.parentRef?.current?.open();
        },
        isOpen: () => props.parentRef?.current?.isOpen() || false,
      })
    );

    return (
      <div
        className={`CategorizedCheckbox CategorizedBranchCheckbox ${
          props.discreet ? 'discreet' : ''
        }`}
      >
        <input
          type="checkbox"
          id={inputId}
          value={checkId}
          checked={isChecked}
          disabled={props.disabled}
          onKeyDown={handleKeydown}
          onChange={(event) => handleChange(event.target)}
          ref={inputRef}
        />
        <label htmlFor={inputId}>{props.label}</label>
      </div>
    );
  }
);
