import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  MessageProps,
  error,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useIntl } from 'react-intl';
import { useMemo, useRef, useState, useEffect } from 'react';
import {
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui/Filterbox';
import {
  FullMethodName_ListUserAttributes,
  getExceptionMessage,
} from '~/shared/utils';
import { convertDisplayUserNameEmail } from '~/shared/utils/converter';

const DialogId = 'ownStaffNotificationSettingDialog';

interface OwnStaffNotificationSettingMessageOption {
  headerLabelId: MessageProps;
}

export interface OwnStaffNotificationSettingInputOption {
  notificationUsers: sharelib.IUserReference[];
}
export interface OwnStaffNotificationSettingDialogProps {
  isOpen: boolean;
  messageOption?: OwnStaffNotificationSettingMessageOption;
  inputOption?: OwnStaffNotificationSettingInputOption;
  onDecision: (v: sharelib.IUserReference[]) => void;
  onCancel: () => void;
}

/**
 * 自社担当通知設定ダイアログ
 */
export const OwnStaffNotificationSettingDialog = (
  props: OwnStaffNotificationSettingDialogProps
) => {
  const { isOpen, inputOption, onDecision, onCancel } = props;
  const { notificationUsers } = inputOption ?? {};
  const intl = useIntl();

  // 画面入力値
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    DataFilterboxItem[]
  >([]);
  // 選択中のユーザー
  const selectedUsers = useRef<sharelib.IUserReference[]>([]);

  // ユーザーマスタ
  const users = useRef<mtechnavi.api.tenantadmin.IUserAttribute[]>([]);
  /**
   * ユーザーマスタ取得
   * 1件目の ID が空の場合はエラーとする。
   */
  const listUsers = async () => {
    const listRes = (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListUserAttributes,
      filter: {},
      sort: [],
    })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

    return new Promise<mtechnavi.api.tenantadmin.ListUserAttributesResponse>(
      (resolve, reject) => {
        const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
        if (firstItem?.userAttributeId === '') {
          reject({ id: 'E0000070' });
          return;
        }
        resolve(listRes);
      }
    );
  };

  // DataFilterbox用(システム通知先)
  const systemNotificationUsersList = useMemo(
    () =>
      users.current
        ? users.current!.map(
            (v): DataFilterboxItem => ({
              displayName: convertDisplayUserNameEmail(
                v.user?.displayName,
                v.user?.email
              ),
              value: v.user?.userId ?? '',
            })
          ) ?? []
        : [],
    // 想定通りの動作をしているためlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users.current]
  );

  // 画面データ取得
  useEffect(() => {
    (async () => {
      try {
        // 共通で取得するもの
        const [listUsersRes] = await Promise.all([
          listUsers(), // ユーザーマスタ
        ]);
        users.current = listUsersRes.items || [];
      } catch (err) {
        error(getExceptionMessage(intl, err));
      }
    })();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formReset = () => {
    setSystemNotificationUsers([]);
  };

  // フォームに初期値をセットする
  useEffect(() => {
    if (!isOpen) {
      formReset();
      return;
    }

    const initUsers: DataFilterboxItem[] = [];
    const initUserReferences: sharelib.IUserReference[] = [];
    notificationUsers?.forEach((v) => {
      if (!initUsers.some((w) => w.value === v.userId)) {
        initUsers.push({
          displayName: convertDisplayUserNameEmail(v.displayName, v.email),
          value: v.userId ?? '',
        });
        initUserReferences.push(v);
      }
    });

    setSystemNotificationUsers(initUsers);
    selectedUsers.current = initUserReferences;
    // ダイアログ起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCancel = () => {
    onCancel();
  };

  const handleDecision = () => {
    onDecision(selectedUsers.current);
  };

  const handleChangeNotificationUsers = async (
    selectedItems: DataFilterboxItem[]
  ) => {
    setSystemNotificationUsers(selectedItems);
    const userIds = selectedItems.map((v) => v.value);
    const result = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListUserAttributes,
      filter: {
        'user.userId': {
          $in: userIds,
        },
      },
      sort: [],
    })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;
    const items = result.items.map((v) => {
      return {
        userId: v.user?.userId ?? '',
        email: v.user?.email ?? '',
        displayName: v.user?.displayName ?? '',
      } as sharelib.IUserReference;
    });
    selectedUsers.current = items;
  };

  const elements = (
    <div className="detail-area">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessageWithIntl(intl, {
              prefixId: DialogId,
              id: 'description',
            })}
          </span>
        </p>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <DataFilterbox
              data={systemNotificationUsersList}
              name="systemNotificationUsers"
              labelId={`${DialogId}.systemNotificationUsers`}
              columns={['systemNotificationUsers']}
              searchOption={{
                targets: 'displayName',
              }}
              onChangeState={handleChangeNotificationUsers}
              value={systemNotificationUsers}
              multiple={true}
            />
          </div>
        </div>
      </div>
      <div className="button-area top-space-10rem">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={onCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'OwnStaffNotificationSetting',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="OwnStaffNotificationSettingDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
