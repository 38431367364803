import { IconButton } from '~/shared/components/ui/Button';

export const UISampleIconVariations = () => {
  return (
    <div className="categories">
      <h3 id="icon-variations">定義済アイコンバリエーション</h3>
      <h4>機能・概念</h4>
      <div className="input-line iconVariations">
        <div>
          <span>account</span>
          <IconButton
            iconType="account"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>
            recipients
            <br />
            (受信者)
          </span>
          <IconButton
            iconType="recipients"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>assignment</span>
          <IconButton
            iconType="assignment"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>notifications</span>
          <IconButton
            iconType="notifications"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>forum</span>
          <IconButton
            iconType="forum"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>files</span>
          <IconButton
            iconType="files"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>決定的なデータの操作</h4>
      <div className="input-line iconVariations">
        <div>
          <span>registration</span>
          <IconButton
            iconType="registration"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>send</span>
          <IconButton
            iconType="send"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>delete</span>
          <IconButton
            iconType="delete"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>一時的な追加 / 削除</h4>
      <div className="input-line iconVariations">
        <div>
          <span>add</span>
          <IconButton
            iconType="add"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>cancel</span>
          <IconButton
            iconType="cancel"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>clear</span>
          <IconButton
            iconType="clear"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>リストなどの操作</h4>
      <div className="input-line iconVariations">
        <div>
          <span>first</span>
          <IconButton
            iconType="first"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>prev</span>
          <IconButton
            iconType="prev"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>next</span>
          <IconButton
            iconType="next"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>last</span>
          <IconButton
            iconType="last"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>up</span>
          <IconButton
            iconType="up"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>down</span>
          <IconButton
            iconType="down"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>遷移</h4>
      <div className="input-line iconVariations">
        <div>
          <span>close</span>
          <IconButton
            iconType="close"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>back</span>
          <IconButton
            iconType="back"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>その他のアクション</h4>
      <div className="input-line iconVariations">
        <div>
          <span>search</span>
          <IconButton
            iconType="search"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>refresh</span>
          <IconButton
            iconType="refresh"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>download</span>
          <IconButton
            iconType="download"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>upload</span>
          <IconButton
            iconType="upload"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>logout</span>
          <IconButton
            iconType="logout"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>more</span>
          <IconButton
            iconType="more"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>read_more</span>
          <IconButton
            iconType="read_more"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>edit</span>
          <IconButton
            iconType="edit"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>history</span>
          <IconButton
            iconType="history"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>clear_list</span>
          <IconButton
            iconType="clear_list"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>チェック / 既読</h4>
      <div className="input-line iconVariations">
        <div>
          <span>checked</span>
          <IconButton
            iconType="checked"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>unchecked</span>
          <IconButton
            iconType="unchecked"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>read</span>
          <IconButton
            iconType="read"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>unread</span>
          <IconButton
            iconType="unread"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>mark_as_read</span>
          <IconButton
            iconType="mark_as_read"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>フラグ</h4>
      <div className="input-line iconVariations">
        <div>
          <span>flag_on</span>
          <IconButton
            iconType="flag_on"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
        <div>
          <span>flag_off</span>
          <IconButton
            iconType="flag_off"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>

      <h4>その他記号</h4>
      <div className="input-line iconVariations">
        <div>
          <span>password</span>
          <IconButton
            iconType="password"
            buttonType="basic"
            name=""
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};
