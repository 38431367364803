import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_CheckUsableForum,
  FullMethodName_ListComments,
  FullMethodName_ListMarkers,
  FullMethodName_ListThreads,
} from '~/shared/utils';
import { FilterResult } from '~/worker';
import { ResourceInfo } from './util';

/** リソースに対して利用可能な親スレッドIDを取得する */
export const getUsableBaseThreadId = async ({
  typeName,
  resourceId,
}: ResourceInfo) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_CheckUsableForum,
    filter: {},
    sort: [],
    requestBody: { typeName, resourceId },
  })) as FilterResult<mtechnavi.api.forum.ICheckUsableForumResponse>;
  return result?.items?.at(0)?.baseThreadId || '';
};

/** 親スレッドに紐づくスレッドの取得 */
export const getThreadList = async (baseThreadId?: string) => {
  if (!baseThreadId) {
    return { items: [] };
  }
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListThreads,
    filter: {},
    sort: [{ updatedAt: 'desc' }],
    requestBody: { baseThreadId },
  })) as mtechnavi.api.forum.IListThreadsResponse;
};

/** 親スレッドに紐づく全スレッドのコメントの取得 */
export const getCommentList = async (baseThreadId?: string) => {
  if (!baseThreadId) {
    return { items: [] };
  }
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListComments,
    filter: {},
    sort: [{ threadId: 'asc' }, { contributedAt: 'asc' }],
    requestBody: { baseThreadId },
  })) as mtechnavi.api.forum.IListCommentsResponse;
};

/** 親スレッドに紐づく全スレッドのマーカーの取得 */
export const getMarkerList = async (baseThreadId?: string) => {
  if (!baseThreadId) {
    return { items: [] };
  }
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListMarkers,
    filter: {},
    sort: [],
    requestBody: { baseThreadId },
  })) as mtechnavi.api.forum.IListMarkersResponse;
};
