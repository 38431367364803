import { CSSProperties } from 'react';
import './LinkTextbox.css';
import { IconButton } from '../../ui/Button';
import { openWindowOrTab } from '~/shared/utils';

export interface LinkTextboxProps {
  name: string;
  className?: string;
  value?: string | null;
  style?: CSSProperties;
  isExternalLink?: boolean;
}

export function LinkTextbox(props: LinkTextboxProps) {
  return (
    <>
      <div className="LinkTextbox">
        <div
          className={`main-area basic-input-border ${
            props.className ? props.className : ''
          }`}
          style={props.style}
        >
          <div className="text-area">{props.value}</div>
          <div className="icon-area">
            <IconButton
              name="openInNew"
              iconType="open_in_new"
              buttonType="danger"
              onClick={() =>
                props.value
                  ? openWindowOrTab(props.value, '', props.isExternalLink)
                  : {}
              }
              disabled={!props.value}
            />
          </div>
        </div>
      </div>
    </>
  );
}
