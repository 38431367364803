import { Route, Routes, Navigate } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { WtRequestCatalogList as WtRequestCatalogListPage } from './pages/WtRequestCatalogList';
import { WtRequestCatalogConfirmation as WtRequestCatalogConfirmationPage } from './pages/WtRequestCatalogConfirmation';
import { WtRequestCatalogInput as WtRequestCatalogInputPage } from './pages/WtRequestCatalogInput';
import { WtWorkTaskInput as WtWorkTaskInputPage } from './pages/WtWorkTaskInput';
import { WtWorkTaskConfirmation as WtWorkTaskConfirmationPage } from './pages/WtWorkTaskConfirmation';
import { WtWorkTaskList as WtWorkTaskListPage } from './pages/WtWorkTaskList';

export const WorkTaskRoutes = () => {
  return (
    <Routes>
      <Route
        path="/wt-request-catalog-list"
        element={
          <RequireAuth>
            <WtRequestCatalogListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/wt-request-catalog-confirmation"
        element={
          <RequireAuth>
            <WtRequestCatalogConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/wt-request-catalog-input"
        element={
          <RequireAuth>
            <WtRequestCatalogInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/wt-worktask-input"
        element={
          <RequireAuth>
            <WtWorkTaskInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/wt-worktask-confirmation/*"
        element={
          <RequireAuth>
            <WtWorkTaskConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/wk-worktask-list"
        element={
          <RequireAuth>
            <WtWorkTaskListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/wk-worktask-approval-list"
        element={
          <RequireAuth>
            <WtWorkTaskListPage />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
