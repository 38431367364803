import React, { CSSProperties, ReactNode } from 'react';
import { GenerallyIconType, getGenerallyIconElement } from './GenerallyIcons';
import './IconButton.css';
import { exportGoogleAnalytics } from '~/shared/utils';

export type ButtonType = 'high' | 'basic' | 'sub' | 'cancel' | 'danger';

export interface IconButtonProps {
  name: string;
  icon?: ReactNode;
  iconType?: GenerallyIconType;
  buttonType?: ButtonType;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  caption?: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const IconButton = (props: IconButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onClick(e);
    // GoogleAnalyticsへデータ送信
    exportGoogleAnalytics('button', { targetName: props.name });
  };

  return (
    <button
      type="button"
      className={`IconButton ${props.buttonType || ''} ${
        props.className || ''
      }`}
      onClick={handleClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      disabled={props.disabled}
      style={props.style}
    >
      {!props.icon && props.iconType && getGenerallyIconElement(props.iconType)}
      {props.icon || null}
    </button>
  );
};
