import { useIntl } from 'react-intl';
import { GetMessageWithIntl, MessageProps } from '../../parts/Message/Message';
import { CaptionButton } from '../Button/CaptionButton';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { useEffect, useRef, useState } from 'react';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
  FileUploaderValidateOption,
} from '../../file';
import { error } from '../../parts/Toast/Toast';
import { SimpleListView } from '../SimpleListView';
import { ConfirmationDialog } from './ConfirmationDialog';
import { AttachmentFile } from './CommonDialogTypes';

type ConfirmType = 'all' | 'file';

export interface FileUploadAppendListDialogMessageOption {
  dialogTitle: MessageProps;
  buttonType: MessageProps;
  simpleListViewHeader: MessageProps;
}

export interface FileUploadAppendListDialogFormOption {
  files?: AttachmentFile[];
}

export interface FileUploadAppendListDialogDisplayOption {
  isDnd?: boolean;
}

export interface FileUploadAppendListDialogUploaderOption {
  validateOption?: FileUploaderValidateOption;
}

export interface FileUploadAppendListDialogProps {
  uploaderOption: FileUploadAppendListDialogUploaderOption;
  messageOption: FileUploadAppendListDialogMessageOption;
  inputOption: FileUploadAppendListDialogFormOption;
  displayOption?: FileUploadAppendListDialogDisplayOption;
  isOpen: boolean;
  onDecision: (v: FileUploadAppendListDialogFormOption) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export const FileUploadAppendListDialog = ({
  isOpen,
  uploaderOption,
  inputOption,
  messageOption,
  displayOption,
  onDecision,
  onCancel,
  onChangeLoadingState,
}: FileUploadAppendListDialogProps) => {
  const intl = useIntl();
  const isDnd = displayOption?.isDnd ?? false;
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);

  const [fileList, setFileList] = useState<AttachmentFile[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmType | null>(null);
  const [confirmTarget, setConfirmTarget] = useState<AttachmentFile | null>(
    null
  );
  const [
    isOverwriteConfirmationDialogOpen,
    setOverwriteConfirmationDialogOpen,
  ] = useState(false);

  const viewFileList = fileList
    .map((file, _, self) => {
      const isDuplicated =
        self.filter((item) => item.filename === file.filename).length > 1;
      const isAdd = !file.assetId; // assetId があれば保存済みとみなす
      return {
        ...file,
        duplicated: isDuplicated ? 'NG' : '',
        add: isAdd ? '◯' : '',
      };
    })
    .sort((a, b) => {
      const sortDuplicated = (a.duplicated ? 0 : 1) - (b.duplicated ? 0 : 1);
      if (sortDuplicated !== 0) {
        return sortDuplicated;
      }
      if (a.filename !== b.filename) {
        return a.filename > b.filename ? 1 : -1;
      }
      return (a.add ? 0 : 1) - (b.add ? 0 : 1);
    });

  const overwriteConfirmationMessage: MessageProps = {
    prefixId: 'fileUploadAppendListDialog',
    id: 'overWriteConfirmationMessage',
  };

  useEffect(() => {
    setFileList(inputOption.files ?? []);
  }, [isOpen, inputOption.files]);

  const handleUploaded = (fileList: FileItem[]) => {
    // アップロード対象の状態が変わるたびに発火されるので、一部のファイルがまだアップロード中の場合は処理しない
    if (
      fileList.length === 0 ||
      fileList.some((item) => item.status === 'uploading')
    ) {
      return;
    }
    setTempFileList(fileList);
    handleAddList(fileList);
  };

  const handleAddList = (tempFileList: FileItem[]) => {
    if (tempFileList.some((item) => item.status === 'failure')) {
      return;
    }
    if (
      (fileList.length &&
        fileList.length +
          tempFileList.filter((item) => item.status === 'OK').length) >
      (uploaderOption.validateOption?.maxFileCount ?? 10)
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000077', value: { $1: 10 } })]);
      return;
    }

    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      'B03'
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      'B03'
    );
    const newFiles = uploaderRef.current?.picksSuccessful() ?? [];
    setFileList([
      ...fileList,
      ...newFiles.map((file) => ({
        id: `${file.url ?? ''}`,
        category: category.length > 0 ? category[0] : {},
        assetId: '',
        filename: `${file.file.name ?? ''}`,
        url: file.url,
        mimeType: '',
        remarks: '',
        linkType: linkType.length > 0 ? linkType[0] : {},
      })),
    ]);
    setOverwriteConfirmationDialogOpen(false);
  };

  const handleRemove = (item: AttachmentFile) => {
    setConfirmAction('file');
    setConfirmTarget(item);
  };

  const handleAllRemove = () => {
    setConfirmAction('all');
  };

  const handleConfirmed = (
    type: ConfirmType | null,
    target: AttachmentFile | null
  ) => {
    if (!type) {
      return;
    }
    if (type === 'all') {
      setFileList([]);
    } else {
      if (target?.assetId) {
        setFileList(fileList.filter((v) => v.assetId !== target?.assetId));
      } else {
        setFileList(fileList.filter((v) => v.url !== target?.url));
      }
    }

    setConfirmAction(null);
    setConfirmTarget(null);
  };

  const handleDecision = () => {
    if (tempFileList.some((item) => item.status === 'failure')) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }
    const duplicateNameItems = viewFileList.filter((item) => !!item.duplicated);
    if (duplicateNameItems.length > 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000174' })]);
      return;
    }
    onDecision({
      files: [...fileList],
    });
  };

  const elements = (
    <div className="file-upload-with-comment-dialog">
      <div className="contents-box">
        <div className="file-uploader-area">
          <ExtendFileUploader
            name="fileUploader"
            ref={uploaderRef}
            dndOption={{
              enabled: isDnd,
            }}
            multiple={true}
            validateOption={uploaderOption.validateOption}
            resultOption={{
              omitFooter: true,
            }}
            onUpload={handleUploaded}
            onChangeLoadingState={(v) => {
              onChangeLoadingState && onChangeLoadingState(v);
              setLoading(v);
            }}
          />
          <div className="halfway-action-area"></div>
          <SimpleListView
            data={viewFileList}
            viewOptions={{
              readonly: tempFileList.some(
                (item) => item.status === 'uploading'
              ),
              previewRowCount: 5,
              keyColumn: 'id',
              columns: [
                {
                  propertyName: 'add',
                  header: { id: 'add' },
                  width: '5rem',
                },
                {
                  propertyName: 'duplicated',
                  header: { id: 'duplicated' },
                  width: '5rem',
                },
                {
                  propertyName: 'filename',
                  header: {
                    id: messageOption.simpleListViewHeader.id,
                    prefixId: messageOption.simpleListViewHeader.prefixId,
                  },
                },
              ],
            }}
            actionOptions={{
              onDelete: handleRemove,
              onDeleteAll: handleAllRemove,
            }}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => onCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            disabled={isLoading}
            caption={GetMessageWithIntl(intl, {
              id: messageOption.buttonType.id,
            })}
            onClick={handleDecision}
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={!!confirmAction}
        viewMessage={{
          id:
            confirmAction === 'all'
              ? 'confirmationDialogMessageDeleteAll'
              : 'confirmationDialogMessageDelete',
          prefixId: 'FileCommentDialog',
        }}
        onDecision={() => handleConfirmed(confirmAction, confirmTarget)}
        onCancel={() => setConfirmAction(null)}
      />
      <ConfirmationDialog
        isOpen={isOverwriteConfirmationDialogOpen}
        viewMessage={overwriteConfirmationMessage}
        onDecision={() => handleAddList(tempFileList)}
        onCancel={() => setOverwriteConfirmationDialogOpen(false)}
      />
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: messageOption.dialogTitle.id,
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
