import { mtechnavi } from '~/shared/libs/clientsdk';
import { AssetInfo } from './CommentPaneProvider';
import { CommentResourceId } from '../Viewer';
import {
  FullMethodName_ListComments,
  FullMethodName_ListThreads,
} from '~/shared/utils';

export const getAssetInfoListByResourceIds = async (
  resourceIdList: CommentResourceId[]
) => {
  if (!resourceIdList || resourceIdList.length <= 0) {
    return;
  }
  const assetIdList = resourceIdList?.flatMap(([, assetIdList]) => assetIdList);
  const res = await window.App.services.assetInventory.listAssets({
    ids: assetIdList,
  });
  return resourceIdList?.flatMap(([resourceId, assetIdList]) => {
    return assetIdList.reduce((prev, assetId) => {
      const asset = res.items.find((item) => item.assetId == assetId);
      if (!asset) {
        return prev;
      }
      const isDuplicated = prev.some(
        (v) => v.resourceId === resourceId && v.asset === asset
      );
      if (!isDuplicated) {
        prev.push({ resourceId, asset });
      }
      return prev;
    }, [] as AssetInfo[]);
  });
};

export const getThreadListByResourceWithAsset = async (
  resourceType: string,
  resourceId: string,
  assetId: string
) => {
  return window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListThreads,
    requestBody: {
      resourceReference: {
        typeName: resourceType,
        resourceId: resourceId,
      },
      associations: [assetId],
    },
    filter: {},
    sort: [
      {
        createdAt: 'desc',
      },
    ],
  });
};

export const getListComments = async (threadId?: string | null) => {
  return window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListComments,
    requestBody: {
      threadId: threadId,
    },
    filter: {},
    sort: [{ contributedAt: 'asc' }],
  });
};

export const deleteCommentByCommentId = async (
  commentId?: string | null,
  deleteText?: string
) => {
  return window.App.services.forumService.updateComment({
    comment: {
      commentId: commentId,
      text: deleteText,
      replyCommentId: '',
    },
  });
};

// 後で対応4/14 一旦lint除外
export const getCompaniesByEstimateRequestId = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  estimateRequestId: string
): Promise<mtechnavi.api.company.ICompany[]> => {
  // TODO:ESTIMATION PROTO MERGE BUILD ERROR DA!
  return getCompanyInfo('');
  //   const estimateResp =
  //   // await window.App.services.estimateOrdererService.getEstimateRequest({
  //   //   estimateRequestId: estimateRequestId,
  //   // });
  // const companyIds = estimateResp.estimateUnits.map(
  //     (item) => item.companyId || ''
  //   );
  //   estimateResp.issuerCompany?.companyId &&
  //     companyIds.push(estimateResp.issuerCompany.companyId); // 発注元企業IDも含める
  //   return getCompanyInfo(...companyIds);
};

const getSelfCompany =
  async (): Promise<mtechnavi.api.company.ICompany | null> => {
    const company = await window.App.services.companyService.getCompany({});
    if (!company) {
      return null;
    }
    return company;
  };

const getCompanyInfo = async (
  ...ids: string[]
): Promise<mtechnavi.api.company.ICompany[]> => {
  return await Promise.all([getSelfCompany()]).then(([selfCompany]) => {
    return [
      selfCompany || {},
      // FIXME: companysセット処理
      // PublicListの削除のみ一次対応、別エンドポイントで吸収される想定とのこと
      ...(ids.map((v) => {
        if (v !== selfCompany?.companyId) return { companyId: v };
      }) as mtechnavi.api.company.ICompany[]),
    ];
  });
};
