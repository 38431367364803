import { useState } from 'react';
import { Textarea, Textbox } from '~/shared/components/ui';
import { validateIncludeProhibit } from '~/shared/components/parts/validator';
import { useIntl } from 'react-intl';

const NORMAL = '標準';
const INITIAL_TEXT = '初期値あり';
const PROHIBIT_TEXT = '禁止文字';
const LIMITED_TEXT = '文字数制限';
const DISABLE_TEXT = '無効化';
const TEXT_AREA = 'テキストエリア';

const INITIAL_WORD = 'ABC123';

export const UISampleTextInputs = () => {
  const intl = useIntl();
  const [text, setText] = useState('');
  const [initialText, setInitialText] = useState(INITIAL_WORD);
  const [prohibitText, setProhibitText] = useState('');
  const [limitedText, setLimitedText] = useState('');
  const [disabledText, setDisabledText] = useState('');

  // テキストエリア
  const [textArea, setTextArea] = useState('');

  return (
    <div className="categories">
      <h3 id="text-inputs">テキスト入力</h3>
      <h4>{NORMAL}</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="normal"
              className="normal"
              type="text"
              isCounter={true}
              onChangeState={setText}
              placeholder="please, input text"
              columns={['normal']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{text}</pre>
          </div>
        </div>
      </div>

      <h4>{INITIAL_TEXT}</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="default"
              type="text"
              value={INITIAL_WORD}
              onChangeState={setInitialText}
              columns={['default']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{initialText}</pre>
          </div>
        </div>
      </div>

      <h4>{PROHIBIT_TEXT}: abc123</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="prohibit"
              type="text"
              validator={validateIncludeProhibit(intl, 'abc123')}
              onChangeState={setProhibitText}
              columns={['prohibit']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{prohibitText}</pre>
          </div>
        </div>
      </div>

      <h4>{LIMITED_TEXT}（5〜10文字）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="range"
              type="text"
              validateOption={{ minLength: 5, maxLength: 10 }}
              onChangeState={setLimitedText}
              columns={['range']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{limitedText}</pre>
          </div>
        </div>
      </div>

      <h4>{DISABLE_TEXT}</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              name="disabled"
              type="text"
              disabled={true}
              onChangeState={setDisabledText}
              columns={['disabled']}
            ></Textbox>
          </div>
          <div className="w-66">
            <pre className="output">{disabledText}</pre>
          </div>
        </div>
      </div>

      <h4>{TEXT_AREA}（必須入力 文字数:5〜10文字 禁止文字:#@）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textarea
              name="textarea"
              validator={validateIncludeProhibit(intl, '#@')}
              isCounter={true}
              validateOption={{ minLength: 5, maxLength: 10, required: true }}
              onChangeState={setTextArea}
              columns={['textarea']}
            ></Textarea>
          </div>
          <div className="w-66">
            <pre className="output">{textArea}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
