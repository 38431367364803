import { useState } from 'react';
import { Container, Toast } from '~/shared/components';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  Property,
  Schema,
  Textbox,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import './BlueprintBlueprintSearchList.css';
import { FullMethodName_ListBlueprints, ViewId } from '~/shared/utils';
import { IconButton } from '~/shared/components/ui/Button/IconButton';
import { SearchMenuListViewDialog } from '~/shared/components/ui/SearchListViewDialog';

const VIEW_ID: ViewId = 'BLUEPRINT_BLUEPRINT_SEARCH';

export type ViewItems = mtechnavi.api.blueprint.Blueprint[];
const ITEM_NAME = 'blueprintNumber';

export function BlueprintBlueprintSearchList() {
  const [limitNumber, setLimitNumber] = useState(0);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const handleClose = () => {
    setModalIsOpen(!baseModalIsOpen);
  };
  const isSelectAction = (items: ViewItems) => {
    let text = '';
    items.forEach((item) => {
      text = `${text}${text === '' ? '' : ','}${item.displayNameLang.ja}`;
    });
    setSearchTextValue(text);
    setModalIsOpen(!baseModalIsOpen);
  };

  // カラムの読み替え処理
  const onHandleFormatSchema = (schema: Schema): Property[] => {
    // ja表示のみする処理
    const jaColumn = [
      'displayNameLang',
      'revisionProperties.staff.displayNameLang',
    ];
    const formatterSch = getAltDisplaySchema(schema, jaColumn, 'ja');
    const dayCololmns = ['revisionProperties.revisedAt'];
    return getDayFormetterDisplaySchema(formatterSch, dayCololmns, {
      dayOpts: { formatType: 'YYYY/MM/DD HH:mm' },
    });
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="BlueprintBlueprintSearchList">
        <div className="search-text-box">
          <Textbox
            name={ITEM_NAME}
            className="field"
            type="text"
            value={searchTextValue}
            labelId={ITEM_NAME}
            onChangeState={() => {}}
            columns={[ITEM_NAME]}
          />
          <div className="btn-box">
            <IconButton
              name="prevIcon"
              className="btn btn-basic"
              properties={[
                {
                  name: 'prevIcon',
                  propertyName: 'prevIcon',
                  propertyValue: 'prevIcon',
                },
              ]}
              onClick={handleClose}
              buttonType="basic"
              iconType="search"
            />
          </div>
        </div>
        {/* demo */}
        <div className="limit">
          選択個数の制限
          <input
            type="number"
            value={limitNumber}
            onChange={(e) => {
              setLimitNumber(Number(e.target.value));
            }}
          />
        </div>
        <SearchMenuListViewDialog
          viewId={VIEW_ID}
          fullMethodName={FullMethodName_ListBlueprints}
          isOpen={baseModalIsOpen}
          headerLabelId={{
            prefixId: 'DIALOG',
            viewId: VIEW_ID,
          }}
          selectLimit={limitNumber}
          onCloseChange={() => handleClose()}
          onSelectChange={(items) =>
            isSelectAction(items as unknown as ViewItems)
          }
          onHandleFormatSchema={onHandleFormatSchema}
        />
      </div>
      <Toast />
    </Container>
  );
}
