import { useState } from 'react';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
  MessageProps,
  GetMessageComponent,
} from '~/shared/components';
import './ConfirmationDialog.css';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  viewMessage: MessageProps;
  messageLabelId?: MessageProps;
  onDecision?: () => void;
  onCancel?: () => void;
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const [comment, setComment] = useState('');
  const view = (
    <div className="content-box">
      <div className="message">
        <GetMessageComponent {...props.viewMessage} />
      </div>
      <div className="btns">
        <CaptionButton
          buttonType="cancel"
          className="buttonBox"
          caption="キャンセル"
          name=""
          onClick={() => {
            props.onCancel && props.onCancel();
          }}
        />
        <CaptionButton
          buttonType="basic"
          className="buttonBox"
          caption="確定"
          name="backBtn"
          onClick={() => {
            props.onDecision!();
          }}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel && props.onCancel();
    },
    send: () => props.onDecision!(),
    modalIsOpen: props.isOpen,
    comment: comment,
    onChangeState: setComment,
    headerLabelId: { prefixId: 'CONFIRM_DIALOG_TITLE', id: 'HEADER_MESSAGE' },
    messageLabelId: props.messageLabelId ?? {},
    elements: view,
  };

  return (
    <div className="ConfirmationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
}
