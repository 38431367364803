import { useState } from 'react';
import { Container } from '~/shared/components';

export function UserSetting() {
  const [password, setPassword] = useState('');
  const handleChangePassword = () => {
    (async () => {
      const me = await window.App.services.identity.getUser({
        userId: 'me',
      });
      me.password = password;
      await window.App.services.identity.updateUser({
        user: me,
      });
    })();
  };
  return (
    <Container>
      <div>
        <fieldset>
          <legend>ユーザー設定</legend>
          <div>
            <label>
              パスワード:
              <input
                type="password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
              <button onClick={handleChangePassword}>変更</button>
            </label>
          </div>
        </fieldset>
      </div>
    </Container>
  );
}
