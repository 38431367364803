import {
  Ref,
  RefObject,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { GroupTreeNode, GroupTreeNodeRef } from './GroupTreeNode';
import { GroupTreeEdge } from './GroupTreeEdge';
import { GroupTreeItem } from './util';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';
import { GroupTreeEditActions, GroupTreeEditPane } from './GroupTreeEditPane';

export interface GroupTreeRootRef {
  open: () => void;
  close: () => void;
}

export interface GroupTreeGlobalProps<T> {
  rootLabel?: string;
  searchWord?: string;
  focusOnId?: string;
  data: GroupTreeItem<T>[];
  isShowDisabled?: boolean;
  editActions: GroupTreeEditActions<T>;
  onDrop?: (data: { targetId: string; parentId: string }) => void;
}
export type GroupTreeRootProps<T> = GroupTreeGlobalProps<T> & {
  onSelect?: () => void;
};
/**
 * ツリーのルートコンポーネント
 */
export const GroupTreeRoot = forwardRef(
  <T,>(
    {
      rootLabel,
      searchWord,
      focusOnId,
      data,
      isShowDisabled,
      editActions,
      onDrop,
    }: GroupTreeRootProps<T>,
    ref: Ref<GroupTreeRootRef> | undefined
  ) => {
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const level = 1;

    const refList = useMemo(() => {
      const entries = data
        .filter((item) => !!item.children)
        .map((item): [string, RefObject<GroupTreeNodeRef>] => [
          item.id || '',
          createRef<GroupTreeNodeRef>(),
        ]);
      return Object.fromEntries(entries);
    }, [data]);

    const handleSelect = (selectId: string | null) => {
      setSelectedGroup(selectId);
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', '');
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const targetId = e.dataTransfer.getData('text/plain');
      const parentId = '';
      if (targetId === parentId) {
        return;
      }
      onDrop && onDrop({ targetId, parentId });
    };
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    useImperativeHandle(
      ref,
      (): GroupTreeNodeRef => ({
        open: () => {
          data.forEach((item) => {
            refList[item.id || '']?.current?.open();
          });
        },
        close: () => {
          data.forEach((item) => {
            refList[item.id || '']?.current?.close();
          });
        },
      })
    );

    useEffect(() => {
      setSelectedGroup(focusOnId ?? null);
      if (!focusOnId) {
        return;
      }
      const target = document.getElementById(`id-${focusOnId}`);
      target?.focus();
      // window.scroll({
      //   top: (target?.offsetTop || 0) - 9,
      //   behavior: 'smooth',
      // });
    }, [focusOnId]);

    return (
      <div
        className={`GroupTreeItemRoot ${
          selectedGroup === '' ? 'selected' : ''
        }`}
        onClick={() => {
          handleSelect('');
        }}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="GroupLabel GroupRootLabel">
          <span className="groupLabelIcon">
            {getGenerallyIconElement('company')}
          </span>
          <span>{rootLabel}</span>
          <GroupTreeEditPane
            data={{}}
            editActions={{
              onAddChild: editActions.onAddChild,
            }}
          />
        </div>
        {data.length > 0 && (
          <div className="ChildTree">
            {data.map((item) => {
              if (!isShowDisabled && item.isDisabled) {
                return;
              }
              const hasChild = item && (item.children || []).length > 0;
              if (!hasChild) {
                return (
                  <GroupTreeEdge
                    key={item.id}
                    data={item}
                    level={level}
                    searchWord={searchWord}
                    selectedGroup={selectedGroup}
                    onSelectGroup={handleSelect}
                    editActions={editActions}
                    onDrop={onDrop}
                  />
                );
              }
              return (
                <GroupTreeNode
                  key={item.id}
                  data={item}
                  level={level}
                  searchWord={searchWord}
                  isShowDisabled={isShowDisabled}
                  selectedGroup={selectedGroup}
                  onSelectGroup={handleSelect}
                  editActions={editActions}
                  onDrop={onDrop}
                  ref={refList[item.id || '']}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
