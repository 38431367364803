import React, {
  MouseEvent,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  PropsWithChildren,
} from 'react';
import { useRenderer } from './renderer';
import { useDragging, Coord, DraggingInfo } from './dragging';
import './Viewer.css';
import { ReactComponent as UndoIcon } from '@material-design-icons/svg/filled/crop_free.svg';
import { ReactComponent as ZoomOutIcon } from '@material-design-icons/svg/filled/remove.svg';
import { ReactComponent as ZoomInIcon } from '@material-design-icons/svg/filled/add.svg';
import { ReactComponent as KeyboardArrowRightIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as KeyboardArrowLeftIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { LoadingIcon } from '../LoadingIcon/LoadingIcon';
import { useCommentPaneDispatch } from '../CommentPane';
import { autoDownloadFile } from '~/shared/utils';
import { IconButton } from '../Button';

export type DragMode = 'move' | 'selection';

export interface ViewerProps {
  assetId: string;
  fileName: string;
  className?: string;
  width: string;
  height: string;
  controls?: ViewerControls;
  dragMode?: DragMode;
}

export interface SelectionInfo {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface OffsetInfo {
  x: number;
  y: number;
}

interface ViewerControls {
  pager?: boolean | JSX.Element;
  scaling?: boolean | JSX.Element;
  rangeShape?: boolean | JSX.Element;
  downloading?: boolean | JSX.Element;
  extraControls?: JSX.Element | JSX.Element[];
}

export interface ViewerRef {
  resetOffset(): void;
  changeDragMode(mode: DragMode): void;
  dragMode: DragMode;
}

type Props = ViewerProps & PropsWithChildren;
export const Viewer = forwardRef((props: Props, ref) => {
  // Viewerコンポーネントのクラス名
  const className = props.className ?? '';
  const srcURL = `/mtechnavi.api.assetinventory.AssetProxy/${props.assetId}`;

  const [state, render] = useRenderer(srcURL);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoading, setLoading] = useState(false);
  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
  });
  const [dragMode, setDragMode] = useState<DragMode>(props.dragMode || 'move');
  const [isShiftKeyDown, setIsShiftKeyDown] = useState(false);
  const commentPaneDispatch = useCommentPaneDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageNumber === 0) {
      return;
    }
    if (!canvasRef.current) {
      return;
    }
    const el = canvasRef.current!;
    const ctx = el.getContext('2d');
    if (!ctx) {
      throw new Error('unsupported browser');
    }
    render(ctx, pageNumber);
  }, [canvasRef, render, pageNumber]);
  useEffect(() => {
    if (state.status === 'loading') {
      setPageNumber(0);
    } else if (pageNumber === 0) {
      setPageNumber(1);
    }

    // ローディングアイコン制御
    if (state.status === 'loading' || state.status === 'rendering') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [state.status, pageNumber]);

  const handlePrevPage = () => {
    const n = pageNumber - 1;
    if (n > 0) {
      setPageNumber(n);
    }
  };
  const maxPageNumber = state.status === 'OK' ? state.maxPageNumber : 0;
  const handleNextPage = () => {
    const n = pageNumber + 1;
    if (n <= maxPageNumber) {
      setPageNumber(n);
    }
  };

  const [scale, setScale] = useState<number>(1);
  const handleZoomReset = () => {
    setScale(1);
    resetDrag();
  };
  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };
  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };
  const scalePercent = `${Math.round(scale * 100)}%`;

  const handleDownload = () => {
    autoDownloadFile(srcURL, props.fileName);
  };

  const handleDragEnd = useCallback(
    (state: DraggingInfo) => {
      commentPaneDispatch({
        type: 'changeOffset',
        offset: { ...state.offset },
      });
    },
    [commentPaneDispatch]
  );

  const handleSelectionEnd = useCallback(
    (state: DraggingInfo) => {
      commentPaneDispatch({
        type: 'endSelection',
        selection: dragStateToSelectionInfo(state),
      });
    },
    [commentPaneDispatch]
  );
  const [dragState, dragTrigger, resetDrag] = useDragging({
    coord: Coord.Offset,
    complete: handleDragEnd,
  });
  const [rangeState, rangeTrigger] = useDragging({
    coord: Coord.Offset,
    complete: handleSelectionEnd,
  });
  const handleDragStart = (evt_: MouseEvent<unknown>) => {
    // 左クリックでのドラッグ開始に反応
    // See https://w3schools.com/jsref/event_which.asp
    const evt = evt_.nativeEvent;
    if (evt.which === 1) {
      if (
        (evt.shiftKey && dragMode === 'move') ||
        (!evt.shiftKey && dragMode === 'selection')
      ) {
        rangeTrigger(evt);
      } else {
        dragTrigger(evt);
      }
    }
  };
  useEffect(() => {
    const offset = {
      x: dragState.offset.x + (dragState.delta?.x || 0),
      y: dragState.offset.y + (dragState.delta?.y || 0),
    };
    setOffset(offset);
    if (!dragState.dragStart && !dragState.delta) {
      commentPaneDispatch({ type: 'changeOffset', offset: offset });
    } else {
      commentPaneDispatch({ type: 'dragging', offset: offset });
    }
  }, [commentPaneDispatch, dragState]);
  useEffect(() => {
    if (!rangeState.delta) {
      return;
    }
    commentPaneDispatch({
      type: 'changeSelection',
      selection: dragStateToSelectionInfo(rangeState),
    });
  }, [commentPaneDispatch, rangeState]);
  useEffect(() => {
    commentPaneDispatch({ type: 'changeScale', scale: scale });
  }, [commentPaneDispatch, scale]);
  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      if (!entries[0]) {
        return;
      }
      const { clientWidth, clientHeight } = entries[0].target;
      commentPaneDispatch({
        type: 'viewerResize',
        size: { width: clientWidth, height: clientHeight },
      });
    });
    observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, [commentPaneDispatch]);

  useEffect(() => {
    const handleKeydown = (evt: globalThis.KeyboardEvent) => {
      if (evt.key === 'Shift') {
        setIsShiftKeyDown(true);
      }
    };
    const handleKeyup = (evt: globalThis.KeyboardEvent) => {
      if (evt.key === 'Shift') {
        setIsShiftKeyDown(false);
      }
    };
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  }, []);

  const dragStateToSelectionInfo = (_range: DraggingInfo) => {
    return {
      x1: (_range.dragStart?.x || 0) - (_range.dragStart?.offsetX || 0),
      y1: (_range.dragStart?.y || 0) - (_range.dragStart?.offsetY || 0),
      x2:
        (_range.dragStart?.x || 0) -
        (_range.dragStart?.offsetX || 0) +
        (_range.delta?.x || 0),
      y2:
        (_range.dragStart?.y || 0) -
        (_range.dragStart?.offsetY || 0) +
        (_range.delta?.y || 0),
    };
  };

  useImperativeHandle(ref, () => ({
    resetOffset() {
      resetDrag();
    },
    changeDragMode(mode: DragMode) {
      setDragMode(mode);
    },
    dragMode,
  }));

  const renderPager = () => {
    if (typeof props.controls?.pager !== 'boolean' || !props.controls?.pager) {
      return props.controls?.pager || <></>;
    }
    return (
      <>
        <KeyboardArrowLeftIcon
          className={
            pageNumber <= 1 ? 'disabled-svg-icon arrow-icon' : 'arrow-icon'
          }
          onClick={handlePrevPage}
        />
        <span>
          {pageNumber} / {maxPageNumber}
        </span>
        <KeyboardArrowRightIcon
          className={
            maxPageNumber <= pageNumber
              ? 'disabled-svg-icon arrow-icon'
              : 'arrow-icon'
          }
          onClick={handleNextPage}
        />
      </>
    );
  };

  const renderScaling = () => {
    if (
      typeof props.controls?.scaling !== 'boolean' ||
      !props.controls?.scaling
    ) {
      return props.controls?.scaling || <></>;
    }
    return (
      <div className="scaling-icon-area">
        <div>
          <ZoomInIcon onClick={handleZoomIn}></ZoomInIcon>
        </div>
        <div>
          <UndoIcon onClick={handleZoomReset}></UndoIcon>
        </div>
        <div>
          <ZoomOutIcon onClick={handleZoomOut}></ZoomOutIcon>
        </div>
      </div>
    );
  };

  const renderRangeShape = () => {
    if (
      typeof props.controls?.rangeShape !== 'boolean' ||
      !props.controls?.rangeShape
    ) {
      return props.controls?.rangeShape || <></>;
    }
    return <></>;
  };

  const renderDownloading = () => {
    if (
      typeof props.controls?.downloading !== 'boolean' ||
      !props.controls?.downloading
    ) {
      return props.controls?.downloading || <></>;
    }
    return (
      <IconButton
        name="downloadIcon"
        className="btn btn-normal"
        buttonType="basic"
        properties={[
          {
            name: 'downloadIcon',
            propertyName: 'downloadIcon',
            propertyValue: 'downloadIcon',
          },
        ]}
        onClick={handleDownload}
        iconType="download"
      />
    );
  };

  const renderExtraControls = () => {
    if (!(props.controls?.extraControls instanceof Array)) {
      return props.controls?.extraControls || <></>;
    }
    return <>{props.controls?.extraControls.map((control) => control)}</>;
  };

  return (
    <div className={`Viewer ${className}`}>
      {props.controls && (
        <div className="controls" style={{ width: props.width }}>
          {renderPager()}
          {props.controls?.scaling && <span>{scalePercent}</span>}
          {renderRangeShape()}
          {renderDownloading()}
        </div>
      )}

      <div
        className="view-area"
        style={{
          width: props.width,
          height: props.height,
        }}
        ref={wrapperRef}
      >
        {renderScaling()}
        {renderExtraControls()}
        <canvas
          className={`document ${dragMode} ${isShiftKeyDown ? 'flip' : ''}`}
          style={{
            transform: `scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
          }}
          width={640}
          height={480}
          onMouseDown={handleDragStart}
          ref={canvasRef}
        />
        {props.children}
      </div>
      {isLoading && <LoadingIcon />}
    </div>
  );
});
