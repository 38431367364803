/* eslint  @typescript-eslint/no-explicit-any: 0 */
import { Message, Method, RPCImplCallback, rpc } from 'protobufjs/light';

interface Object_ {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

function isObject(v: unknown): v is Object_ {
  return typeof v === 'object' && !Array.isArray(v);
}

// See https://grpc.github.io/grpc/core/md_doc_statuscodes.html
export enum StatusCode {
  OK = 0,
  CANCELLED = 1,
  UNKNOWN = 2,
  INVALID_ARGUMENT = 3,
  DEADLINE_EXCEEDED = 4,
  NOT_FOUND = 5,
  ALREADY_EXISTS = 6,
  PERMISSION_DENIED = 7,
  RESOURCE_EXHAUSTED = 8,
  FAILED_PRECONDITION = 9,
  ABORTED = 10,
  OUT_OF_RANGE = 11,
  UNIMPLEMENTED = 12,
  INTERNAL = 13,
  UNAVAILABLE = 14,
  DATA_LOSS = 15,
  UNAUTHENTICATED = 16,
}

export namespace StatusCode {
  export function from(v: string | number): StatusCode {
    const n = typeof v === 'number' ? v : parseInt(v, 10);
    return Object.values(StatusCode).includes(n) ? n : StatusCode.UNKNOWN;
  }
}

interface RpcErrorResponse {
  code: StatusCode;

  message: string;
}

function isRpcErrorResponse(v: unknown): v is RpcErrorResponse {
  if (!isObject(v)) {
    return false;
  }
  return 'code' in v && typeof v.code === 'number';
}

export class RpcError extends Error {
  constructor(
    public code: StatusCode,
    public message_: string,
    public validationError: ValidationError | null
  ) {
    super(
      `rpc error: code=${
        StatusCode[code]
      }(${code}) message=${message_} validationError=${JSON.stringify(
        validationError
      )}`
    );
    Object.defineProperty(this, 'name', {
      configurable: true,
      enumerable: false,
      value: this.constructor.name,
      writable: true,
    });
  }
}

// ライブラリ側がjs前提での処理になっており、型が滅茶苦茶
// なので基本anyでjs的に記述する
(rpc.Service.prototype.rpcCall as any) = async function rpcCall(
  method: any,
  requestCtor: any,
  responseCtor: any,
  request: any,
  callback: never
): Promise<any> {
  if (!request) {
    throw TypeError('request must be specified');
  }
  if (!!callback) {
    throw TypeError('callback must not be specified');
  }
  // @ts-ignore
  const that: rpc.Service = this;

  // requestは規定の型に揃える

  const req: Message<any> =
    request instanceof requestCtor ? request : requestCtor.fromObject(request);

  try {
    const fullMethodName = [(that as any).__fullServiceName, method.name].join(
      '/'
    );
    const data = await invoke(fullMethodName, req.toJSON());
    const res = responseCtor.fromObject(data);
    that.emit('data', res, method);
    return res;
  } catch (err) {
    that.emit('error', err, method);
    throw err;
  }
};

async function invoke(fullMethod: string, req: Object_): Promise<Object_> {
  const res = await fetch('/' + fullMethod, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req),
  });
  const [code, message, validationError] = await parseGrpcStatus(res);
  switch (code) {
    case StatusCode.OK:
      break;
    default:
      console.info(StatusCode[code], message, validationError);
      throw new RpcError(code, message, validationError);
  }
  return await res.json();
}

export interface Descriptor {
  // バリデーションエラーを発生させたフィールド名。
  // フィールド名が特定できなかった場合、空文字となる。
  field?: string;
  // エラーコード値
  messageName?: string;
  // エラー引数
  messageArgs?: string[];
}

interface ValidationError {
  descriptors: Descriptor[];
}

function isValidationError(v: unknown): v is ValidationError {
  if (!isObject(v)) {
    return false;
  }
  return 'descriptors' in v && Array.isArray(v.descriptors);
}

const parseValidationError = (error: string | null): ValidationError | null => {
  if (!error) return null;
  const err = JSON.parse(error);
  if (!isValidationError(err)) {
    return null;
  }
  return err;
};

async function parseGrpcStatus(
  res: Response
): Promise<[StatusCode, string, ValidationError | null]> {
  const code = res.headers.get('grpc-status');
  const message = res.headers.get('grpc-message');
  const error = res.headers.get('x-validation-error');
  const validationError = parseValidationError(error);
  if (code !== null) {
    return [StatusCode.from(code), message ?? '', validationError];
  }

  let body;
  try {
    body = await res.text();
  } catch (err) {
    console.error('failed to retrieve http response body:', err);
    body = '{}';
  }
  let data;
  try {
    data = JSON.parse(body);
  } catch (err) {
    console.error('failed to JSON.parse http response body:', err);
    data = {};
  }
  if (!isObject(data)) {
    console.error(
      `http response is not a json object from ${res.url} ${res.status} - ${res.statusText}`
    );
    return [StatusCode.UNKNOWN, 'UNKNOWN', validationError];
  }
  // assume { code: number; message: string; }
  if (isRpcErrorResponse(data)) {
    return [StatusCode.from(data.code), data.message, validationError];
  } else {
    return [StatusCode.UNKNOWN, 'UNKNOWN', validationError];
  }
}

// 呼び出されない予定の関数で中身はエラー吐き出しの身のため、lint除外
/* eslint-disable @typescript-eslint/no-unused-vars */
export function rpcImpl(
  method: Method | rpc.ServiceMethod<Message<never>, Message<never>>,
  requestData: Uint8Array,
  callback: RPCImplCallback
): void {
  throw new Error('この関数は呼ばれないはず');
}
