import { useIntl } from 'react-intl';
import { Property } from 'csstype';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import './base.css';
import './ChangeNotificationDialog.css';
import { getDateFormat } from '~/shared/utils';

interface ListColumnSetting {
  header?: MessageProps;
  propertyName: string;
  width?: string;
  align?: Property.TextAlign;
}

export interface ChangeNotificationDialogInputOption {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  keyColumn: string;
  columns: ListColumnSetting[];
}

export interface ChangeNotificationDialogProps {
  isOpen: boolean;
  inputOption: ChangeNotificationDialogInputOption;
  onDecision: () => void;
  onCancel: () => void;
}

const makeTemplateColumnStyles = (
  columns: ListColumnSetting[]
): React.CSSProperties => {
  return {
    gridTemplateColumns: `${columns
      .map((column) => column.width || '1fr')
      .join(' ')}`,
  };
};

export const ChangeNotificationDialog = ({
  isOpen,
  inputOption,
  onCancel,
  onDecision,
}: ChangeNotificationDialogProps) => {
  const intl = useIntl();

  const elements = (
    <div className="change-notification-dialog">
      <div className="detail-area">
        <div className="info-area">
          {GetMessageWithIntl(intl, {
            prefixId: 'ChangeNotificationDialog',
            id: 'notifiedAt',
          })}
          ：
          {` ${getDateFormat(
            (inputOption.data.length ? inputOption.data[0].notifiedAt : '') ??
              '',
            'YYYY/MM/DD'
          )}`}
        </div>
        <div className="scrollable-area">
          {
            <div
              className="change-notification-header"
              style={makeTemplateColumnStyles(inputOption.columns)}
            >
              {inputOption.columns.map((column) => (
                <div key={column.propertyName}>
                  {column.header ? GetMessage(column.header) : ''}
                </div>
              ))}
            </div>
          }
          <ul className="change-notification-list">
            {inputOption.data.map((item) => {
              const key = item[inputOption.keyColumn];
              return (
                <li
                  key={key}
                  style={makeTemplateColumnStyles(inputOption.columns)}
                >
                  {inputOption.columns.map((column) => (
                    <div
                      className="cell"
                      key={column.propertyName}
                      style={{
                        textAlign: column?.align,
                      }}
                    >
                      {item[column.propertyName]}
                    </div>
                  ))}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: 'cancel',
          })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="confirmedBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, {
            prefixId: 'ChangeNotificationDialog',
            id: 'confirmed',
          })}
          onClick={() => onDecision()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'changeNotification',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="ChangeNotificationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
