import { Route, Routes, Navigate } from 'react-router-dom';
// FIXME:参照の命名ルール統一対応予定 20204/04/23現在
// worktask以前の開発では下記1の状態になっている
// 1 import { XXXX as XXXXPage } from './pages/XXXX';
// 2 import { XXXX } from './pages/XXXX';
import { IfmFormDefinitionList } from './pages/IfmFormDefinitionList';
import { IfmFormDefinitionInput } from './pages/IfmFormDefinitionInput';
import { IfmFormDefinitionConfirmation } from './pages/IfmFormDefinitionConfirmation';
import { IfmSampleFormDefinitionList } from './pages/IfmSampleFormDefinitionList';
import { RequireAuth } from '~/shared/contexts/AuthProvider';

export const FormRoutes = () => {
  return (
    <Routes>
      <Route
        path="/ifm-form-definition-list"
        element={
          <RequireAuth>
            <IfmFormDefinitionList />
          </RequireAuth>
        }
      />
      <Route
        path="/ifm-form-definition-input"
        element={
          <RequireAuth>
            <IfmFormDefinitionInput />
          </RequireAuth>
        }
      />
      <Route
        path="/ifm-form-definition-confirmation/*"
        element={
          <RequireAuth>
            <IfmFormDefinitionConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/ifm-sample-form-definition-list"
        element={
          <RequireAuth>
            <IfmSampleFormDefinitionList />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
