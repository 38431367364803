import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

const DebugContext = createContext<boolean>(false);

/** デバッグモードを管理するための Provider */
export const DebugProvider = ({ children }: PropsWithChildren) => {
  const [isDebugMode, setDebugMode] = useState(false);
  const search = window.location.search;

  useEffect(() => {
    const params = new URLSearchParams(search);
    setDebugMode(params.get('debug') === 'on');
  }, [search]);

  return (
    <DebugContext.Provider value={isDebugMode}>
      {children}
    </DebugContext.Provider>
  );
};

/** デバッグモード用のクエリパラメータ */
export const DebugParam = { debug: 'on' };

/** デバッグモード利用側向けの hooks */
export const useDebug = () => {
  const isDebugMode = useContext(DebugContext);
  return { isDebugMode };
};
