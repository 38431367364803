import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  SearchRegionDialog,
  SearchRegionDialogInputOption,
} from '~/shared/components/ui/Dialog/SearchRegionDialog';

export const UISampleSearchRegionDialogs = () => {
  const [result, setResult] = useState<SearchRegionDialogInputOption>({
    regions: [],
  });
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">地域検索ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult({});
                setIsShowConfirm(true);
              }}
            />
            <SearchRegionDialog
              isOpen={isShowConfirm}
              inputOption={{ regions: ['26', '1'] }}
              onDecision={(v) => {
                setResult(v);
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setResult({});
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
