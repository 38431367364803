import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  Container,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
} from '~/shared/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import {
  BuildedForm,
  BuildedFormRef,
} from '~/shared/components/ui/FormBuilder/BuildedForm';
import { BaseFormInformation } from '~/shared/components/ui/FormBuilder/parts/BaseFormInformation';
import { NavigationIconMenu } from '~/shared/components/ui/PageNavigation/PageNavigation';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  getWorkerExceptionMessage,
  ViewId,
  getExceptionMessage,
  getLocalStorageCheckboxData,
  saveLocalStorageCheckboxData,
  FullMethodName_ListBaseForms,
  FullMethodName_ListBaseFormSettings,
  FullMethodName_ListBaseFormSettingItems,
  backToBaseViewPage,
} from '~/shared/utils';
import { FilterRequest } from '~/worker';
import { getTargetId, moveInputPage } from '../utils';
import { ConfirmationPagination } from './parts/ConfirmationPagination';

const VIEW_ID: ViewId = 'IFM_FORM_DEFINITION_CONFIRMATION';
const LIST_VIEW_ID: ViewId = 'IFM_FORM_DEFINITION_LIST';
const INPUT_VIEW_ID: ViewId = 'IFM_FORM_DEFINITION_INPUT';

export function IfmFormDefinitionConfirmation() {
  const navi = useNavigate();
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];

  // Loading制御用
  const [isLoading, setLoading] = useState(false);
  const [isLoadingBaseFormSetting, setLoadingBaseFormSetting] = useState(false);
  const [isLoadingBaseFormSettingItem, setLoadingBaseFormSettingItem] =
    useState(false);

  // メインデータ（取引先管理/基本情報）
  const [item, setItem] = useState<mtechnavi.api.form.IBaseForm>();
  // 取得データ格納用 フォーム設定（構成）データ
  const baseFormSettingData = useRef<mtechnavi.api.form.IBaseFormSetting>();
  // 取得データ格納用 フォーム設定（項目）データ
  const baseFormSettingItemsData = useRef<
    mtechnavi.api.form.IBaseFormSettingItem[]
  >([]);

  // 表示項目制御
  const buildedFormRef = useRef<BuildedFormRef>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  // 削除 確認ダイアログ
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  // 項目表示制御
  const [isEditable, setEditable] = useState(false);
  const [isDeletable, setDeletable] = useState(false);

  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const deleteMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  // ヘッダアイコン設定
  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];

    if (isEditable) {
      // 編集
      iconItems.push({
        name: 'edit',
        displayName: '編集',
        func: () => {
          handleIfmFormDefinitionInput();
        },
      });
    }
    if (isDeletable) {
      // 削除
      iconItems.push({
        name: 'delete',
        displayName: '削除',
        func: () => {
          setDeleteConfirmOpen(true);
        },
      });
    }

    return iconItems;
  };

  // [実行処理]フォーム登録
  const handleIfmFormDefinitionInput = () => {
    moveInputPage(
      navi,
      location,
      VIEW_ID,
      INPUT_VIEW_ID,
      item?.baseFormId ?? '',
      pageNumber
    );
  };

  // [実行処理]削除処理
  const handleDelete = async () => {
    const localstorageValues = getLocalStorageCheckboxData(
      LIST_VIEW_ID,
      myEmail
    );
    const deleteId = item?.baseFormId ?? '';
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBaseFormWithRelatedItems',
        request: [deleteId],
      });

      success([successMessage]);
      saveLocalStorageCheckboxData(
        LIST_VIEW_ID,
        localstorageValues.filter((v) => v !== item?.baseFormId),
        myEmail
      );
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setDeleteConfirmOpen(false);
    setLoading(false);
    backToBaseViewPage(navi, location, VIEW_ID);
  };

  // 制御対象のコントロールの非活性(非表示)化
  const disableAllItems = () => {
    setEditable(false);
    setDeletable(false);
  };

  // [データ取得] 全データ取得
  const listAllData = async () => {
    // 以下のデータ取得はメインデータ（BaseForm）取得後の前提

    // フォーム設定（構成）データ取得
    (async () => {
      setLoadingBaseFormSetting(true);
      await listBaseFormSettingsData();
    })();

    // フォーム設定（項目）データ取得
    (async () => {
      setLoadingBaseFormSettingItem(true);
      await listBaseFormSettingItemsData();
    })();
  };

  // [データ取得] フォーム設定（構成）データ取得
  const listBaseFormSettingsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBaseFormSettings,
        filter: {
          // BEのフィルタ処理未対応のため
          baseFormId: { $eq: item?.baseFormId ?? '' },
        },
        requestBody: {
          baseFormIds: [item?.baseFormId ?? ''],
        },
        sort: [], // 1件しか取得されない想定
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.form.IListBaseFormSettingsResponse;
      const listItem: mtechnavi.api.form.IBaseFormSetting[] =
        resList.items ?? [];
      baseFormSettingData.current = listItem.length > 0 ? listItem[0] : {};
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingBaseFormSetting(false);
    }
  };

  // [データ取得] フォーム設定（項目）データ取得
  const listBaseFormSettingItemsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBaseFormSettingItems,
        filter: {
          // BEのフィルタ処理未対応のため
          baseFormId: { $eq: item?.baseFormId ?? '' },
        },
        requestBody: {
          baseFormIds: [item?.baseFormId ?? ''],
        },
        sort: [{ 'item.sort': 'asc' }],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.form.IListBaseFormSettingItemsResponse;
      const listItem: mtechnavi.api.form.IBaseFormSettingItem[] =
        resList.items ?? [];
      baseFormSettingItemsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingBaseFormSettingItem(false);
    }
  };

  // メインデータ変更時
  useEffect(() => {
    if (!item) {
      return;
    }
    // 対象データ取得
    (async () => {
      await listAllData();
    })();

    // ログインユーザーの所属部門による制御が必要な内容
    (async () => {
      const userOrganizationUnits =
        await window.App.services.ui.getUserOrganizationUnits(myEmail);

      // ログインユーザーの所属部門ID
      const userOrganizationUnitId: string =
        userOrganizationUnits.length > 0 &&
        userOrganizationUnits[0].componentUnit
          ? userOrganizationUnits[0].componentUnit.componentUnitId ?? ''
          : '';
      // ログインユーザーの所属部門=管理部門の場合 true
      const isManagementOrganization: boolean =
        (item?.formProperties?.managementOrganization?.componentUnitId &&
          userOrganizationUnitId &&
          item?.formProperties?.managementOrganization.componentUnitId ===
            userOrganizationUnitId) ||
        false;

      // ボタン制御対象を非活性化
      disableAllItems();

      // 管理部門の場合に対象になるもの
      if (isManagementOrganization) {
        setDeletable(true);
        setEditable(true);
      }
    })();

    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  // Loading制御
  useEffect(() => {
    // ひとつでもLoadingならアイコン表示
    setLoading(isLoadingBaseFormSetting || isLoadingBaseFormSettingItem);

    // isLodingXX 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingBaseFormSetting, isLoadingBaseFormSettingItem]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="IfmFormDefinitionConfirmation">
          <div className="header">
            <ConfirmationPagination
              viewId={VIEW_ID}
              listViewId={LIST_VIEW_ID}
              requestBody={{
                baseFormIds: getTargetId(location, VIEW_ID) ?? [],
              }}
              filter={{
                baseFormId: { $in: getTargetId(location, VIEW_ID) ?? [] },
              }}
              myEmail={myEmail}
              fullMethodName={FullMethodName_ListBaseForms}
              pageNavigationIconItems={getPageNavigationIconItems()}
              lastUpdateInfo={{
                isVisibleUpdateInfo: true,
                attribute:
                  item?.formProperties?.updatedProperties?.updatedBy?.email ??
                  '',
                attributeAt:
                  item?.formProperties?.updatedProperties?.updatedAt ??
                  undefined,
                content:
                  item?.formProperties?.updatedProperties?.updatedBy
                    ?.displayName ?? '',
              }}
              currentItemId={item?.baseFormId ?? ''}
              onChangeItem={(v) => {
                setItem((v as mtechnavi.api.form.IBaseForm[]).pop());
              }}
              onChangeLoadingState={setLoading}
              onChangePage={setPageNumber}
            />
          </div>
          <div className="info">
            <div className="basic-info-body">
              <div className="form-information">
                <BaseFormInformation
                  formProperties={item?.formProperties ?? {}}
                  usable={item?.usable ?? false}
                />
              </div>
              <div className="form-area">
                <BuildedForm
                  formPages={baseFormSettingData.current?.formPages || []}
                  formSettingItems={baseFormSettingItemsData.current}
                  ref={buildedFormRef}
                  readonly={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* 削除 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isDeleteConfirmOpen}
          viewMessage={deleteMessage}
          onDecision={() => {
            handleDelete();
          }}
          onCancel={() => {
            setDeleteConfirmOpen(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
