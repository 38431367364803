import { useCallback, useEffect, useReducer, useState } from 'react';

export interface PointSource {
  offsetX: number;
  offsetY: number;

  clientX: number;
  clientY: number;

  pageX: number;
  pageY: number;

  screenX: number;
  screenY: number;
}

interface ScreenPointSource {
  screenX: number;
  screenY: number;
}

export interface Point {
  x: number;
  y: number;
}

export type Coord = (v: PointSource) => Point;

export namespace Coord {
  export function Offset(v: PointSource): Point {
    return {
      x: v.offsetX,
      y: v.offsetY,
    };
  }

  export function Client(v: PointSource): Point {
    return {
      x: v.clientX,
      y: v.clientY,
    };
  }

  export function Page(v: PointSource): Point {
    return {
      x: v.pageX,
      y: v.pageY,
    };
  }

  export function Screen(v: PointSource): Point {
    return {
      x: v.screenX,
      y: v.screenY,
    };
  }
}

export interface DraggingInfo {
  offset: Point;
  dragStart: {
    x: number;
    y: number;
    offsetX: number;
    offsetY: number;
  } | null;
  delta: Point | null;
}

interface State {
  offset: Point;
  dragStart: {
    x: number;
    y: number;
    // xとscreenXとの差分
    offsetX: number;
    // yとscreenYとの差分
    offsetY: number;
  } | null;
  delta: Point | null;
}

const initialState: State = {
  offset: { x: 0, y: 0 },
  dragStart: null,
  delta: null,
};

interface ActionDragStart {
  type: 'dragStart';

  coord: Coord;

  source: PointSource;
}

interface ActionDragging {
  type: 'dragging';

  source: ScreenPointSource;
}

interface ActionDragEnd {
  type: 'dragEnd';

  setResult: (state: DraggingInfo) => void;

  source: ScreenPointSource;
}

interface ActionReset {
  type: 'reset';
}

type Action = ActionDragStart | ActionDragging | ActionDragEnd | ActionReset;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'dragStart': {
      const p = action.coord(action.source);
      console.info('p', p);
      console.info('source', action.source);
      return {
        ...state,
        dragStart: {
          x: action.source.screenX,
          y: action.source.screenY,
          offsetX: action.source.screenX - p.x,
          offsetY: action.source.screenY - p.y,
        },
      };
    }
    case 'dragging':
      if (!state.dragStart) {
        return state;
      }
      console.info('x:', action.source.screenX - state.dragStart.x);
      console.info('y:', action.source.screenY - state.dragStart.y);
      return {
        ...state,
        delta: {
          x: action.source.screenX - state.dragStart.x,
          y: action.source.screenY - state.dragStart.y,
        },
      };
    case 'dragEnd':
      if (!state.dragStart) {
        return state;
      }
      action.setResult(state);
      return {
        dragStart: null,
        delta: null,
        offset: {
          x: state.offset.x + (action.source.screenX - state.dragStart.x),
          y: state.offset.y + (action.source.screenY - state.dragStart.y),
        },
      };
    case 'reset':
      return { ...initialState };
    default:
      throw new Error(`unknown action: ${action}`);
  }
}

export type DragStartTrigger = (v: PointSource) => void;
export type DragResetTrigger = () => void;

export interface useDraggingProps {
  coord: Coord;
  complete?: (state: DraggingInfo) => void;
}

export function useDragging(
  props: useDraggingProps
): [State, DragStartTrigger, DragResetTrigger] {
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [result, setResult] = useState<DraggingInfo | null>(null);
  useEffect(() => {
    const handleDragging = (evt: globalThis.MouseEvent) => {
      dispatch({ type: 'dragging', source: evt });
    };
    window.addEventListener('mousemove', handleDragging);
    return () => {
      window.removeEventListener('mousemove', handleDragging);
    };
  }, []);
  useEffect(() => {
    const handleDragEnd = (evt: globalThis.MouseEvent) => {
      dispatch({
        type: 'dragEnd',
        setResult,
        source: evt,
      });
    };
    window.addEventListener('mouseup', handleDragEnd);
    return () => {
      window.removeEventListener('mouseup', handleDragEnd);
    };
  }, []);
  useEffect(() => {
    if (result && props.complete) {
      props.complete(result);
    }

    // result 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const trigger = useCallback(
    (v: PointSource) => {
      dispatch({
        type: 'dragStart',
        coord: props.coord,
        source: v,
      });
    },
    [props.coord]
  );
  const reset = () => {
    dispatch({ type: 'reset' });
  };
  return [state, trigger, reset];
}
