import protobufjs from 'protobufjs/minimal';
import Long from 'long';
import { mtechnavi } from './generated';

// 生成コードからサービス名が取得できないため、入れ込む
const l = {
  'mtechnavi.api.idp.Identity': mtechnavi.api.idp.Identity,
  'mtechnavi.api.assetinventory.AssetInventory':
    mtechnavi.api.assetinventory.AssetInventory,
  'mtechnavi.api.dlock.Locker': mtechnavi.api.dlock.Locker,
  'mtechnavi.api.uicontroller.UiController':
    mtechnavi.api.uicontroller.UiController,
  'mtechnavi.api.company.CompanyService': mtechnavi.api.company.CompanyService,
  'mtechnavi.api.company.ComponentUnitService':
    mtechnavi.api.company.ComponentUnitService,
  'mtechnavi.api.company.BusinessUnitService':
    mtechnavi.api.company.BusinessUnitService,
  'mtechnavi.api.company.BusinessUnitManagementService':
    mtechnavi.api.company.BusinessUnitManagementService,
  'mtechnavi.api.company.StaffService': mtechnavi.api.company.StaffService,
  'mtechnavi.api.company.PublicCompanyService':
    mtechnavi.api.company.PublicCompanyService,
  'mtechnavi.api.blueprint.BlueprintService':
    mtechnavi.api.blueprint.BlueprintService,
  'mtechnavi.api.blueprint.TransactionUnitService':
    mtechnavi.api.blueprint.TransactionUnitService,
  'mtechnavi.api.programoption.ProgramOptionService':
    mtechnavi.api.programoption.ProgramOptionService,
  'mtechnavi.api.estimation.EstimationService':
    mtechnavi.api.estimation.EstimationService,
  'mtechnavi.api.estimation.EstimateSenderService':
    mtechnavi.api.estimation.EstimateSenderService,
  'mtechnavi.api.estimation.EstimateReceiverService':
    mtechnavi.api.estimation.EstimateReceiverService,
  'mtechnavi.api.forum.ForumService': mtechnavi.api.forum.ForumService,
  'mtechnavi.api.tenantadmin.TenantAdminService':
    mtechnavi.api.tenantadmin.TenantAdminService,
  'mtechnavi.api.survey.SurveyService': mtechnavi.api.survey.SurveyService,
  'mtechnavi.api.survey.SurveySenderService':
    mtechnavi.api.survey.SurveySenderService,
  'mtechnavi.api.survey.SurveyReceiverService':
    mtechnavi.api.survey.SurveyReceiverService,
  'mtechnavi.api.survey.SurveyTaskListService':
    mtechnavi.api.survey.SurveyTaskListService,
  'mtechnavi.api.survey.SurveyIOService': mtechnavi.api.survey.SurveyIOService,
  'mtechnavi.api.tenantprovision.TenantProvisionService':
    mtechnavi.api.tenantprovision.TenantProvisionService,
  'mtechnavi.api.worktask.WorkTaskService':
    mtechnavi.api.worktask.WorkTaskService,
  'mtechnavi.api.worktask.WorkTaskTaskListService':
    mtechnavi.api.worktask.WorkTaskTaskListService,
  'mtechnavi.api.form.BaseFormService': mtechnavi.api.form.BaseFormService,
  'mtechnavi.api.form.FormService': mtechnavi.api.form.FormService,
  'mtechnavi.api.form.SampleWorkFormService':
    mtechnavi.api.form.SampleWorkFormService,
  'mtechnavi.api.pocsearchcompany.PocSearchCompanyService':
    mtechnavi.api.pocsearchcompany.PocSearchCompanyService,
  'mtechnavi.api.bcp.BcpService': mtechnavi.api.bcp.BcpService,
  'mtechnavi.api.license.LicenseService': mtechnavi.api.license.LicenseService,
};
Object.entries(l).forEach((entry) => {
  const [name, ctor] = entry;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ctor.prototype as any).__fullServiceName = name;
});

// int64にlong.jsを利用する設定
protobufjs.util.Long = Long;
protobufjs.configure();

export * from './catalog';
export * from './generated';
export * from './rpcimpl';
