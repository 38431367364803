import React, {
  PropsWithoutRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { GetMessageWithIntl, error } from '~/shared/components';
import {
  CommmonAttachedFileSystemCode,
  convertLongToString,
  convertAmountToString,
  convertStringToNumber,
} from '~/shared/utils';
import {
  FormInputTextBox,
  FormInputFile,
  FormInputTextarea,
  FormInputAmount,
  FormInputCheckbox,
  FormInputDate,
} from './inputs';
import {
  BuildFormInputType,
  FormValueItem,
  NON_VALUE_INPUT_TYPES,
  convertToFormValue,
  isBuildFormInputType,
} from './utils';
import './BuildedForm.css';
import { IFormSettingItem } from './';
import { FormInputSelect } from './inputs/FormInputSelect';

const formValuesToPersistValues = (
  formPages: mtechnavi.api.form.IFormPage[],
  formSettingItems: IFormSettingItem[],
  formValues: BuildedFormValues
): mtechnavi.api.form.IFormValue[] => {
  return formPages.flatMap(
    (page) =>
      formSettingItems
        .filter((v) => v.formPageId === page.formPageId)
        .map((item): mtechnavi.api.form.IFormValue => {
          const itemValue = Object.entries(formValues).find(
            ([id]) => id === item.formSettingItemId
          );
          return {
            formSettingItemId: item.formSettingItemId || '',
            itemAutoName: item.item?.itemAutoName,
            input: {
              controlValue: JSON.stringify(itemValue ? itemValue[1].value : {}),
              inputType: item.item?.inputType || '',
            },
          };
        })
        .filter(
          (item) => !NON_VALUE_INPUT_TYPES.includes(item.input?.inputType || '')
        ) || []
  );
};

const persistValuesToFormValues = (
  persistValues?: mtechnavi.api.form.IFormValue[]
): BuildedFormValues => {
  if (!persistValues) {
    return {};
  }
  const values: BuildedFormValues = {};
  persistValues.forEach((val) => {
    if (!val.formSettingItemId || !val.input?.inputType) {
      return;
    }
    const typedValue = convertToFormValue(
      val.input?.inputType,
      JSON.parse(val.input?.controlValue || '{}')
    );
    if (!typedValue) {
      return;
    }
    values[val.formSettingItemId] = typedValue;
  });
  return values;
};

const formValueToRequireCheckValue = (itemValue?: FormValueItem) => {
  switch (itemValue?.type as BuildFormInputType) {
    case 'text':
      return (
        (itemValue?.value as mtechnavi.api.form.IFormControlText)
          ?.displayName || ''
      );
    case 'textarea':
      return (
        (itemValue?.value as mtechnavi.api.form.IFormControlTextArea)
          ?.displayName || ''
      );
    case 'date':
      return (
        (itemValue?.value as mtechnavi.api.form.IFormControlDate)?.date || ''
      );
    case 'toggle':
      return (
        (itemValue?.value as mtechnavi.api.form.IFormControlToggle)?.on || ''
      );
    case 'number':
      return (
        convertAmountToString(
          // 実際は IFormControlInt 型
          itemValue?.value ? (itemValue?.value as sharelib.IAmount) : undefined
        ) || ''
      );
    case 'file':
      return (
        (itemValue?.value as mtechnavi.api.form.IFormControlFile)?.fileName ||
        ''
      );
    case 'select':
      return (
        (itemValue?.value as mtechnavi.api.form.IFormControlSelect)?.item
          ?.value || ''
      );
    case 'selectMultiple':
      return (
        (itemValue?.value
          ? (
              itemValue?.value as mtechnavi.api.form.IFormControlSelectMultiple | null
            )?.items || []
          : []
        )
          .map((v) => v.value)
          .join(',') || ''
      );
    default:
      return (itemValue?.value as string) || '';
  }
};

export interface BuildedFormRef {
  getValues: () => mtechnavi.api.form.IFormValue[] | undefined;
}

interface BuildedFormValues {
  [itemId: string]: FormValueItem;
}

interface BuildedFormProps {
  formPages: mtechnavi.api.form.IFormPage[];
  formSettingItems: IFormSettingItem[];
  initialValues?: mtechnavi.api.form.IFormValue[] | null;
  readonly?: boolean;
  assetSystemCode?: CommmonAttachedFileSystemCode;
  isSkippedRequireCheck?: boolean;
}
export const BuildedForm = forwardRef(
  (
    {
      formPages,
      formSettingItems,
      initialValues,
      readonly,
      assetSystemCode,
      isSkippedRequireCheck,
    }: PropsWithoutRef<BuildedFormProps>,
    ref
  ) => {
    const intl = useIntl();
    const formAreaRef = useRef<HTMLDivElement>(null);
    const [currentPageId, setCurrentPageId] = useState(
      formPages.length > 0 ? formPages[0].formPageId : ''
    );
    const [formValue, setFormValue] = useState<BuildedFormValues>({});
    const [workingBlur, setWorkingBlur] = useState<Date>();
    const AllowedFileExtensionCode = 'A5000005';

    const changeFormValue = (
      itemId: string | null | undefined,
      type: string | null | undefined,
      value: unknown
    ) => {
      if (!itemId || !type) {
        return;
      }
      const typedValue = convertToFormValue(type, value);
      if (!typedValue) {
        return;
      }
      setFormValue({ ...formValue, [itemId]: typedValue });
    };

    const handleChangePage = useCallback((pageId: string) => {
      if (!formAreaRef) {
        return;
      }
      // エラーが既に発生していたら処理中断
      const errorClassName = '.visible-error-message';
      const isInputError = !!formAreaRef.current!.querySelector(errorClassName);
      // 入力項目でエラーが発生した場合はエラートーストを表示して、該当箇所までスクロール
      if (isInputError) {
        error([
          GetMessageWithIntl(intl, {
            id: 'E0000002',
          }),
        ]);
        formAreaRef.current!.querySelector(errorClassName)!.scrollIntoView();
        return;
      }
      setCurrentPageId(pageId);
      setWorkingBlur(undefined);
      // 起動時から処理内容が変わらない処理なのでlintから除外させる
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isInputError = (
      pages: mtechnavi.api.form.IFormPage[],
      formSettinItems: IFormSettingItem[],
      values: BuildedFormValues
    ): boolean => {
      if (!formAreaRef) {
        return false;
      }

      // エラーが既に発生していたら処理中断
      const errorClassName = '.visible-error-message';
      const isInputError = !!formAreaRef.current!.querySelector(errorClassName);
      // 入力項目でエラーが発生した場合はエラートーストを表示して、該当箇所までスクロール
      if (isInputError) {
        error([
          GetMessageWithIntl(intl, {
            id: 'E0000002',
          }),
        ]);
        formAreaRef.current!.querySelector(errorClassName)!.scrollIntoView();
        return true;
      }

      // 必須チェック不要な場合は以降の処理を行わない
      if (isSkippedRequireCheck) return false;

      setWorkingBlur(new Date());

      // 固有の処理が必要なため includeInputValidateError を使用しない
      let isInputErrorPage = false;
      pages.map((page) => {
        const targets = (
          formSettinItems.filter((v) => v.formPageId === page.formPageId) || []
        )
          .filter((item) => item.item?.required)
          .map((item) => ({
            value: formValueToRequireCheckValue(
              values[item.formSettingItemId || '']
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref: formAreaRef as any,
          }));

        // 必須エラーを必ず表示するためのユーザー視点の処理
        const inputElements = formAreaRef.current!.querySelectorAll('input');
        const textareaElements =
          formAreaRef.current!.querySelectorAll('textarea');
        inputElements.forEach((item: HTMLInputElement) => {
          item.focus();
          item.blur();
        });
        textareaElements.forEach((item: HTMLTextAreaElement) => {
          item.focus();
          item.blur();
        });

        const target = targets.find((v) => v.value === '');
        if (target) {
          error([
            GetMessageWithIntl(intl, {
              id: 'E0000111',
              value: { $1: page.displayName ?? '' },
            }),
          ]);
          isInputErrorPage = true;
        }
      });
      return isInputErrorPage;
    };

    useImperativeHandle(
      ref,
      (): BuildedFormRef => ({
        getValues: () => {
          if (isInputError(formPages, formSettingItems, formValue)) {
            return;
          }
          return formValuesToPersistValues(
            formPages,
            formSettingItems,
            formValue
          );
        },
      })
    );

    useEffect(() => {
      if (!initialValues) {
        setFormValue({});
      } else {
        const formValues: mtechnavi.api.form.IFormValue[] = [];
        initialValues.map((value) => {
          formValues.push(value);
        });
        setFormValue(persistValuesToFormValues(formValues));
      }
    }, [formPages, initialValues]);

    useEffect(() => {
      if (formPages.length > 0 && formPages[0].formPageId) {
        handleChangePage(formPages[0].formPageId);
      }
    }, [formPages, handleChangePage]);

    useEffect(() => {
      if (isSkippedRequireCheck) {
        setWorkingBlur(undefined);
      }
    }, [isSkippedRequireCheck]);

    return (
      <div className="BuildedForm" ref={formAreaRef}>
        <div className="tabPanelContents">
          {formPages.map((page) => (
            <div
              className={`tabPanel ${
                page.formPageId === currentPageId ? 'current' : ''
              }`}
              key={page.formPageId}
              onClick={() => {
                handleChangePage(page.formPageId?.toString() || '');
              }}
            >
              {page.displayName}
            </div>
          ))}
        </div>

        {formPages.map((page) => (
          <React.Fragment key={page.formPageId}>
            {page.formPageId === currentPageId && (
              <div className="form-area">
                {(
                  formSettingItems.filter(
                    (v) => v.formPageId === page.formPageId
                  ) || []
                ).map((item) => {
                  const inputType = item.item?.inputType;
                  if (!isBuildFormInputType(inputType)) {
                    return;
                  }
                  return (
                    <div
                      key={
                        item.baseFormSettingItemId
                          ? item.baseFormSettingItemId
                          : item.formSettingItemId
                      }
                      className={`form-item form-item-size-${
                        !['textarea', 'labelMultiLine'].includes(inputType)
                          ? item.item
                            ? convertLongToString(item.item.itemSize)
                            : ''
                          : 'textarea'
                      }`}
                    >
                      {inputType === 'text' && (
                        <FormInputTextBox
                          type="text"
                          name={item.item?.itemAutoName || ''}
                          label={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          validateOption={{
                            required: !!item.item?.required,
                            isSkippedRequireCheck: isSkippedRequireCheck,
                          }}
                          disabled={readonly}
                          onChange={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          value={
                            formValue[item.formSettingItemId || '']
                              ?.value as mtechnavi.api.form.IFormControlText
                          }
                        />
                      )}

                      {inputType === 'textarea' && (
                        <FormInputTextarea
                          name={item.item?.itemAutoName || ''}
                          label={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          validateOption={{
                            required: !!item.item?.required,
                            isSkippedRequireCheck: isSkippedRequireCheck,
                          }}
                          disabled={readonly}
                          onChange={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          value={
                            formValue[item.formSettingItemId || '']
                              ?.value as mtechnavi.api.form.IFormControlTextArea
                          }
                        />
                      )}

                      {inputType === 'number' && (
                        <FormInputAmount
                          intl={intl}
                          name={item.item?.itemAutoName || ''}
                          label={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          disabled={readonly}
                          validateOption={{
                            required: !!item.item?.required,
                            length: {
                              min:
                                convertStringToNumber(item.item?.minNum) ||
                                undefined,
                              max:
                                convertStringToNumber(item.item?.maxNum) ||
                                undefined,
                            },
                            isSkippedRequireCheck: isSkippedRequireCheck,
                          }}
                          displayOption={{
                            digits:
                              convertStringToNumber(item.item?.precisionNum) ||
                              undefined,
                          }}
                          onChange={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          value={
                            formValue[item.formSettingItemId || '']
                              ?.value as mtechnavi.api.form.IFormControlNumber
                          }
                        />
                      )}

                      {inputType === 'date' && (
                        <FormInputDate
                          name={item.item?.itemAutoName || ''}
                          label={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          disabled={readonly}
                          validateOption={{
                            required: !!item.item?.required,
                            isSkippedRequireCheck: isSkippedRequireCheck,
                          }}
                          onChange={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          value={
                            formValue[item.formSettingItemId || '']
                              ?.value as mtechnavi.api.form.IFormControlDate
                          }
                        />
                      )}

                      {(inputType === 'select' ||
                        inputType === 'selectMultiple') && (
                        <FormInputSelect
                          name={item.item?.itemAutoName || ''}
                          label={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          workingBlur={workingBlur}
                          validateOption={{
                            required: !!item.item?.required,
                            isSkippedRequireCheck: isSkippedRequireCheck,
                          }}
                          multiple={item.item?.inputType === 'selectMultiple'}
                          disabled={readonly}
                          data={
                            item.item?.selectItems?.map((item) => {
                              return JSON.parse(item);
                            }) || []
                          }
                          onChange={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          value={
                            formValue[item.formSettingItemId || '']?.value as
                              | mtechnavi.api.form.IFormControlSelectMultiple
                              | mtechnavi.api.form.IFormControlSelect
                          }
                        />
                      )}

                      {inputType === 'file' && (
                        <FormInputFile
                          name={item.item?.itemAutoName || ''}
                          label={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          required={!!item.item?.required}
                          readonly={readonly}
                          assetSystemCode={assetSystemCode}
                          value={
                            formValue[item.formSettingItemId || '']
                              ?.value as mtechnavi.api.form.IFormControlFile
                          }
                          validateOption={{
                            allowedFileExtensions:
                              (item.item?.fileTypes ?? []).map(
                                (v) =>
                                  window.App.services.ui.getNameOptionWithCode(
                                    AllowedFileExtensionCode,
                                    v ?? ''
                                  ).displayNameLang?.ja ?? ''
                              ) ?? [],
                            maxFileSizeInMebis: 50,
                            maxFileCount: 10,
                          }}
                          onChange={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          workingBlur={workingBlur}
                          isSkippedRequireCheck={isSkippedRequireCheck}
                        />
                      )}

                      {inputType === 'toggle' && (
                        <FormInputCheckbox
                          name={item.item?.itemAutoName || ''}
                          caption={item.item?.displayName || ''}
                          description={item.item?.description || ''}
                          validateOption={{
                            required: !!item.item?.required,
                            isSkippedRequireCheck: isSkippedRequireCheck,
                          }}
                          disabled={readonly}
                          onChangeState={(v) =>
                            changeFormValue(
                              item.formSettingItemId,
                              item.item?.inputType,
                              v
                            )
                          }
                          value={
                            formValue[item.formSettingItemId || '']
                              ?.value as mtechnavi.api.form.IFormControlToggle
                          }
                          workingBlur={workingBlur}
                        />
                      )}

                      {inputType === 'label' && (
                        <div>{item.item?.displayName}</div>
                      )}
                      {inputType === 'labelMultiLine' && (
                        <FormInputTextarea
                          name={item.item?.itemAutoName || ''}
                          disabled={true}
                          value={
                            {
                              displayName: item?.item?.displayName,
                            } as mtechnavi.api.form.IFormControlLabelMultiLine
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
);
