import { useState, CSSProperties, useEffect } from 'react';
import './Radio.css';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage } from '../Message/Message';

export interface RadioProps {
  name: string;
  className?: string;
  items: RadioItem[];
  value?: string;
  validator?: (v: string) => string[];
  validateOption?: RadioValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  isVertical?: boolean;
  onChangeState?: (arg: string) => void;
  workingBlur?: Date;
}

export interface RadioItem {
  value: string;
  displayName: string;
}

export interface RadioValidateOption {
  isSkippedValidation?: boolean; // このコンポーネントで設定されているバリデーションを行うかどうか
  required?: boolean;
}

export function Radio(props: RadioProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  // radio要素のクラス名
  const radioClassName = props.className ?? '';
  const style = props.style ?? {};
  // radio縦並びがTrueの場合
  const vertical = props.isVertical ? 'vertical' : '';

  // オプショナルチェック
  const validate =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;

  // useState
  const [message, setMessage] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const changeParentState = props.onChangeState ?? (() => {});

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  const handleChange = (radio: HTMLInputElement) => {
    setValue(radio.value);
    changeParentState(radio.value);

    // 固有バリデーションを行うかどうか
    if (isSkippedValidation) {
      // 固有バリデーションはスキップして、親要素でのバリデーションを行う
      return;
    }

    const errorMessage: string[] = [];

    // 必須入力チェック
    if (required && !radio.checked) {
      // 入力必須で未入力の場合はエラーメッセージを出して以降の処理はスキップ
      setMessage([REQUIRED_MESSAGE]);
      return;
    }

    // バリデーション
    errorMessage.push(...validate(radio.value));

    setMessage([...errorMessage]);
  };

  /*
   ラジオボタンの必須チェックを行うために、強制的にチェック状態の変更と同様の処理を行う。
   用途としては回答送信ダイアログで出力内容を未選択の状態で確定ボタンを押した時のチェックを行うなど。
  */
  useEffect(() => {
    if (!props.workingBlur) {
      return;
    }
    const errorMessage: string[] = [];
    // 必須入力チェック
    if (required && !value) {
      // 入力必須で未入力の場合はエラーメッセージを出して以降の処理はスキップ
      setMessage([REQUIRED_MESSAGE]);
      return;
    }
    // バリデーション
    errorMessage.push(...validate(value));
    setMessage([...errorMessage]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workingBlur]);

  return (
    <div className="Radio">
      {/* ラジオボタン */}
      {props.items.map((val, i) => {
        return (
          <label key={i} className={vertical}>
            <input
              name={props.name}
              className={radioClassName}
              type={'radio'}
              onChange={(event) => handleChange(event.target)}
              value={val.value}
              checked={value === val.value}
              disabled={props.disabled}
              style={style}
            />
            {val.displayName}
          </label>
        );
      })}
      {/* エラー表示 */}
      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
