import { useRef, useState } from 'react';
import { Container } from '~/shared/components';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';

export function UserGroupAdmin() {
  const userGroupIdRef = useRef<HTMLInputElement>(null);
  const userGroupCodeRef = useRef<HTMLInputElement>(null);
  const displayNameRef = useRef<HTMLInputElement>(null);
  const roleNamesRef = useRef<HTMLInputElement>(null);
  const createdAtRef = useRef<HTMLInputElement>(null);
  const updatedAtRef = useRef<HTMLInputElement>(null);

  const handleEdit = (v: mtechnavi.api.idp.IUserGroup) => {
    userGroupIdRef.current!.value = v.userGroupId!;
    userGroupCodeRef.current!.value = v.userGroupCode!;
    displayNameRef.current!.value = v.displayName ?? '';
    roleNamesRef.current!.value = JSON.stringify(v.roleNames ?? []);
    createdAtRef.current!.value = v.createdAt!.toString(10);
    updatedAtRef.current!.value = v.updatedAt!.toString(10);
  };

  const [items, setItems] = useState<Array<mtechnavi.api.idp.IUserGroup>>([]);
  const handleList = () => {
    (async () => {
      const res = await window.App.services.identity.listUserGroups({});
      setItems(res.items);
    })();
  };
  const handleCreate = () => {
    (async () => {
      await window.App.services.identity.createUserGroup({
        userGroup: {
          userGroupCode: userGroupCodeRef.current!.value,
          displayName: displayNameRef.current!.value,
          roleNames: JSON.parse(roleNamesRef.current!.value),
        },
      });
    })();
  };
  const handleUpdate = () => {
    (async () => {
      await window.App.services.identity.updateUserGroup({
        userGroup: {
          userGroupId: userGroupIdRef.current!.value,
          userGroupCode: userGroupCodeRef.current!.value,
          displayName: displayNameRef.current!.value,
          roleNames: JSON.parse(roleNamesRef.current!.value),
          createdAt: Long.fromString(createdAtRef.current!.value),
          updatedAt: Long.fromString(updatedAtRef.current!.value),
        },
      });
    })();
  };
  const handleDelete = () => {
    (async () => {
      await window.App.services.identity.deleteUserGroup({
        userGroup: {
          userGroupId: userGroupIdRef.current!.value,
          userGroupCode: userGroupCodeRef.current!.value,
          displayName: displayNameRef.current!.value,
          roleNames: JSON.parse(roleNamesRef.current!.value),
          createdAt: Long.fromString(createdAtRef.current!.value),
          updatedAt: Long.fromString(updatedAtRef.current!.value),
        },
      });
    })();
  };

  return (
    <Container>
      <div>
        ユーザーグループ編集
        <button type="button" onClick={handleList}>
          List
        </button>
        <button type="button" onClick={handleCreate}>
          Create
        </button>
        <button type="button" onClick={handleUpdate}>
          Update
        </button>
        <button type="button" onClick={handleDelete}>
          Delete
        </button>
        <fieldset>
          <legend>ユーザーグループ情報</legend>
          <label>
            userGroupId: <input type="text" disabled ref={userGroupIdRef} />
          </label>
          <br />
          <label>
            userGroupCode: <input type="text" ref={userGroupCodeRef} />
          </label>
          <br />
          <label>
            displayName: <input type="text" ref={displayNameRef} />
          </label>
          <br />
          <label>
            roleNames: <input type="text" ref={roleNamesRef} />
          </label>
          <br />
          <label>
            createdAt: <input type="text" disabled ref={createdAtRef} />
          </label>
          <br />
          <label>
            updatedAt: <input type="text" disabled ref={updatedAtRef} />
          </label>
          <br />
        </fieldset>
      </div>
      <hr />
      <div>
        <div>
          {items.map((v) => (
            <div
              style={{ overflow: 'scroll', display: 'flex' }}
              key={v.userGroupId}
            >
              <button type="button" onClick={() => handleEdit(v)}>
                Edit
              </button>
              <pre style={{ marginLeft: '1em' }}>
                {JSON.stringify(v, null, 2)}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
