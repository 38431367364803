import React, {
  Component,
  ErrorInfo,
  PropsWithChildren,
  useState,
} from 'react';
import { RpcError, StatusCode } from '~/shared/libs/clientsdk';
import { signOut as firebaseSignOut } from 'firebase/auth';

function isRpcError(err: unknown): err is RpcError {
  return err instanceof RpcError;
}

function isError(err: unknown): err is Error {
  return err instanceof Error;
}

interface ErrorObject extends Error {
  code: StatusCode;
}

const parseError = (error: unknown): ErrorObject | null => {
  if (isRpcError(error)) {
    return error;
  }
  if (isError(error)) {
    for (const [status, code] of Object.entries(StatusCode)) {
      if (!error.message.includes(`code=${status}`)) continue;
      console.info(`${status}: ${code}`);
      return {
        name: error.name,
        code: code as StatusCode,
        message: error.message,
      };
    }
  }
  return null;
};

type ErrorBoundaryProps = {};
type ErrorBoundaryState = {
  error: unknown;
};

const initialState: ErrorBoundaryState = {
  error: null,
};

// 子コンポーネントでthrowされたエラーを取得する
class ErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state = initialState;

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: event.reason,
    });
  };

  componentDidMount() {
    // 非同期処理でrejectされたエラーを取得
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler
    );
  }

  async componentDidUpdate() {
    const err = parseError(this.state.error);
    if (!err) return;
    switch (err.code) {
      case StatusCode.UNAUTHENTICATED:
        // 未認証(401)の場合は、ログアウト & ログイン画面に遷移
        await firebaseSignOut(window.App.firebaseApps.auth);
        window.location.replace('/sign-in');
        break;
      default:
        console.error(this.state.error);
    }
  }

  static getDerivedStateFromError(error: Error) {
    console.error('ErrorBoundary getDerivedStateFromError: ', error);
    return { error: error } as ErrorBoundaryState;
  }

  // implements ErrorBoundary
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('ErrorBoundary did catch error    : ', error);
    console.error('ErrorBoundary did catch errorInfo: ', errorInfo);
  }

  render() {
    const err = parseError(this.state.error);
    if (err) {
      switch (err?.code) {
        case StatusCode.UNAUTHENTICATED:
          return <></>;
        default:
          console.error(this.state.error);
      }
    }
    return <>{this.props.children}</>;
  }
}

// イベントハンドラーで発生したエラーをコンポーネント内でthrowされたエラーとして発火させる
export function useErrorHandler<Error = unknown>(): React.Dispatch<Error> {
  const [state, setState] = useState<{
    error: Error | null;
    hasError: boolean;
  }>({
    error: null,
    hasError: false,
  });
  const [retryCount, setRetryCount] = useState<number>(1);
  if (state.hasError) {
    if (retryCount > 2) {
      throw state.error;
    }
  }
  return (error: Error) => {
    const err = parseError(error);
    if (err) {
      setState({
        error,
        hasError: true,
      });
      setRetryCount(retryCount + 1);
    }
  };
}

export default ErrorBoundary;
