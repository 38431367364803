import {
  Children,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import {
  RenderSectionView,
  SectionView,
  SectionViewProps,
} from './SectionView';

const isSectionViewElement = (
  element: unknown
): element is ReactElement<PropsWithChildren<SectionViewProps>> => {
  return (
    typeof element === 'object' &&
    isValidElement(element) &&
    element.type === SectionView
  );
};

interface SectionState {
  taskTotal: number;
}

export interface State {
  sectionStates: Map<string, SectionState>;
}

interface ActionCount {
  type: 'count';
  name: string;
  taskTotal: number;
}

export type Action = ActionCount;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'count': {
      const sectionState = state.sectionStates.get(action.name) ?? {};
      state.sectionStates.set(action.name, {
        ...sectionState,
        taskTotal: action.taskTotal,
      });
      return {
        ...state,
      };
    }
    default:
      throw new Error(`unhandled action.type=${action.type}`);
  }
}

export interface SectionListViewProps {}

export function SectionListView(
  props: PropsWithChildren<SectionListViewProps>
): JSX.Element {
  const { children } = props;

  Children.forEach(children, (element) => {
    if (!element) {
      return;
    }
    if (!isSectionViewElement(element)) {
      throw new Error(
        'All component children of <SectionListView> must be a <SectionView>'
      );
    }
  });
  return <div className="SectionListView">{children}</div>;
}

export interface RenderSectionListViewProps {
  trigger: number;
  onChangeState?: (v: State) => void;
}

export function RenderSectionListView(
  props: PropsWithChildren<RenderSectionListViewProps>
): JSX.Element {
  const { children, trigger, onChangeState } = props;

  const [state, dispatch] = useReducer(reducer, {
    sectionStates: new Map<string, SectionState>(),
  });

  useEffect(() => {
    onChangeState && onChangeState(state);
  }, [state, onChangeState]);

  const onChangeTaskCount = useCallback((name: string, total: number) => {
    dispatch({
      type: 'count',
      name: name,
      taskTotal: total,
    });
  }, []);

  const renderSectionViews: ReactNode[] = [];
  Children.forEach(children, (element, idx) => {
    if (!element) {
      return;
    }
    if (isSectionViewElement(element)) {
      renderSectionViews.push(
        <RenderSectionView
          key={idx}
          trigger={trigger}
          onChangeSectionTaskCount={onChangeTaskCount}
        >
          {element.props.children}
        </RenderSectionView>
      );
    }
  });
  return <div className="SectionListView">{renderSectionViews}</div>;
}
