import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DataFilterbox,
  DataFilterboxItem,
  LatestUpdateInfo,
  PageNavigation,
  Textbox,
  Textarea,
  Checkbox,
  SimpleListView,
  ConfirmationDialog,
  FileUploadDialog,
  Filterbox,
  FilterboxItem,
} from '~/shared/components/ui';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  AttachmentItems,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListWorkTaskCatalogs,
  PageState,
  ViewId,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  convertApiStringWIthFillBlank,
  convertComponentUnitReference,
  convertComponentUnitReferenceToFilterboxItem,
  convertStringWithTrim,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  getViewIdPreset,
  getWorkerExceptionMessage,
  handleCommonFIleUpload,
  includeInputValidateError,
} from '~/shared/utils';
import urlList from '~/shared/menu/path.json';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import Long from 'long';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './WtRequestCatalogInput.css';

const VIEW_ID: ViewId = 'WT_REQUEST_CATALOG_INPUT';

type WorkTaskCatalog = mtechnavi.api.worktask.WorkTaskCatalog;
type IWorkTaskCatalog = mtechnavi.api.worktask.IWorkTaskCatalog;

export function WtRequestCatalogInput() {
  const intl = useIntl();
  const navi = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const toastSuccess = GetMessage({ id: 'I0000001' });

  // 遷移元から値を受け取る
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = useRef(sourcePageInfo.actionType);
  const [backPagePath, setBackPagePath] = useState(
    '/work-task/wt-request-catalog-list'
  );

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージオプション
  // 登録
  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
        viewId: VIEW_ID,
      }),
    },
  };
  // キャンセル
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  // 添付ファイル削除
  const viewMessageDelete: MessageProps = {
    id: 'confirmationDialogMessageDelete',
    prefixId: VIEW_ID,
  };
  // 添付ファイル全件削除
  const viewMessageDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: VIEW_ID,
  };
  // 確認ダイアログオプション
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  // 画面アイテム
  const [workTaskCatalog, setWorkTaskCatalog] = useState<WorkTaskCatalog>();
  // ナヴィゲーションアイテム
  const islatestUpdateVisibleRef = useRef(false);
  const categoriesNO = window.App.services.ui.getNameOption('A2000001');
  const [code, setCode] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [categoryCode, setCategoryCode] = useState<DataFilterboxItem[]>([]);
  const [contents, setContents] = useState('');
  const [approval, setApproval] = useState<string[]>([]);
  const [managementOrganization, setManagementOrganization] = useState<
    FilterboxItem[]
  >([]);
  const [workOrganization, setWorkOrganization] = useState<FilterboxItem[]>([]);
  const [fileAttachment, setFileAttachment] = useState<AttachmentItems[]>([]);
  //データフィルターボックスアイテム
  const [categories] = useMemo<[DataFilterboxItem[]]>(() => {
    const categories = getProgramOptionFilterboxData('A2000001');
    return [categories];
  }, []);
  //フィルターボックスType
  const organaizationUnitsItemType = {
    value: 'componentUnitId',
    displayName: 'displayNameLang',
  };
  const [lastUpdateInfo, setLastUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: false,
  });
  //Disabled
  const [isCodeDisabled, setCodeDisabled] = useState(false);

  // 入力チェック用
  const requiredCodeArea = useRef(null);
  const requiredDisplayNameArea = useRef(null);

  useEffect(() => {
    setLoading(true);
    // 戻るボタンのURLをセット
    setBackPagePath(
      urlList.find((v) => v.viewId === sourcePageInfo.sourceViewId)?.path ??
        '/work-task/wt-request-catalog-list'
    );
    if (actionType.current === 'edit') {
      islatestUpdateVisibleRef.current = true;
      (async () => {
        try {
          // カタログ依頼を取得
          const listWorkTaskCatalogRes =
            (await window.App.services.ui.worker.filter({
              action: 'query',
              fullMethodName: FullMethodName_ListWorkTaskCatalogs,
              filter: {
                workTaskCatalogId: { $eq: sourcePageInfo.ids![0] ?? '' },
              },
              sort: [],
            })) as mtechnavi.api.worktask.ListWorkTaskCatalogResponse;

          const workTaskCatalogItem = listWorkTaskCatalogRes
            ?.items[0] as WorkTaskCatalog;

          if (workTaskCatalogItem?.workTaskCatalogId === '') {
            setLoading(false);
            error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
            return;
          }

          setWorkTaskCatalog(workTaskCatalogItem);
        } catch (err) {
          error(getExceptionMessage(intl, err));
        }
      })();
      setCodeDisabled(true);
    }
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
      setPreset(presetItem);
    })();
    setLoading(false);
  }, [intl, sourcePageInfo.sourceViewId, sourcePageInfo.ids]);

  // 初期値セット
  useEffect(() => {
    setCode(workTaskCatalog?.code ?? '');
    const cateogry = workTaskCatalog?.category?.displayNameLang?.ja
      ? categories?.filter(
          (v) =>
            v.displayName === workTaskCatalog?.category?.displayNameLang?.ja
        )
      : [];
    setDisplayName(convertStringWithTrim(workTaskCatalog?.displayName));
    setCategoryCode(cateogry);
    setContents(convertStringWithTrim(workTaskCatalog?.contents));
    setApproval(workTaskCatalog?.approval ? ['1'] : []);
    const managementOrganizationItem: FilterboxItem[] = [];
    workTaskCatalog?.managementOrganization
      ? managementOrganizationItem.push(
          convertComponentUnitReferenceToFilterboxItem(
            workTaskCatalog.managementOrganization
          ) ?? { value: '', displayName: '' }
        )
      : [];
    setManagementOrganization(managementOrganizationItem);
    const workOrganizationItem: FilterboxItem[] = [];
    workTaskCatalog?.workOrganization
      ? workOrganizationItem.push(
          convertComponentUnitReferenceToFilterboxItem(
            workTaskCatalog.workOrganization
          ) ?? { value: '', displayName: '' }
        )
      : [];
    setWorkOrganization(workOrganizationItem);
    setLastUpdateInfo({
      isVisibleUpdateInfo: workTaskCatalog ? true : false,
      attributeAt:
        workTaskCatalog?.workTaskCatalogCatalogUpdatedAt ?? Long.fromNumber(0),
      content:
        workTaskCatalog?.workTaskCatalogCatalogUpdatedBy?.displayName ?? '',
      attribute: workTaskCatalog?.workTaskCatalogCatalogUpdatedBy?.email ?? '',
    });
    putCommonRequestAttachmentItem(workTaskCatalog?.commonAttachments ?? []);

    // workTaskCatalog変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workTaskCatalog]);

  const putCommonRequestAttachmentItem = (
    attachmentItems: sharelib.IAttachment[]
  ) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setFileAttachment([...resultItems]);
  };

  // 登録、変更処理
  const sendAction = async () => {
    // カタログコード重複チェック
    if (actionType.current === 'add') {
      const listWorkTaskCatalogRes =
        (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListWorkTaskCatalogs,
          filter: {
            code: { $eq: code },
          },
          sort: [],
        })) as mtechnavi.api.worktask.ListWorkTaskCatalogResponse;
      if (listWorkTaskCatalogRes.items.length) {
        error([GetMessageWithIntl(intl, { id: 'E0000072' })]);
        return;
      }
    }
    // 実際に送る処置
    sendInputAction();
  };

  const sendInputAction = async () => {
    const categoryDisplayName =
      categoryCode.length > 0 ? categoryCode[0].displayName : '';
    const req: IWorkTaskCatalog[] = [
      {
        workTaskCatalogId:
          actionType.current == 'add'
            ? null
            : workTaskCatalog?.workTaskCatalogId,
        code: actionType.current === 'add' ? code : null,
        displayName: convertApiStringWIthFillBlank(displayName),
        category: categoriesNO.find(
          (v) => v.displayNameLang?.ja === categoryDisplayName
        ),
        contents: convertApiStringWIthFillBlank(contents),
        approval: approval[0] && approval[0] === '1' ? true : false,
        managementOrganization:
          managementOrganization.length > 0
            ? (await convertComponentUnitReference(
                managementOrganization[0].value || '',
                managementOrganization[0].displayName || ''
              )) ?? {}
            : null,
        workOrganization:
          workOrganization.length > 0
            ? (await convertComponentUnitReference(
                workOrganization[0].value || '',
                workOrganization[0].displayName || ''
              )) ?? {}
            : null,
        commonAttachments: fileAttachment,
        workTaskCatalogCatalogUpdatedAt: null,
        workTaskCatalogCatalogUpdatedBy: null,
        createdAt: null,
        updatedAt:
          actionType.current === 'add' ? null : workTaskCatalog?.updatedAt,
        deletedAt: null,
      },
    ];
    setLoading(true);
    try {
      const result = (await window.App.services.ui.worker.apiCall({
        actionName: 'saveWorkTaskCatalog',
        request: req,
      })) as WorkTaskCatalog[];
      success([toastSuccess]);
      sourcePageInfo.actionType = 'edit';
      actionType.current = 'edit';
      result.map((updatedCatalog) => {
        let resultCatalog: IWorkTaskCatalog = {};
        if (Array.isArray(updatedCatalog)) {
          if (updatedCatalog.length > 0) {
            resultCatalog = updatedCatalog[0];
          }
        } else {
          resultCatalog = updatedCatalog;
        }
        setWorkTaskCatalog(resultCatalog as WorkTaskCatalog);
      });
      setCodeDisabled(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setLoading(false);
    setOpenConfirmDialog(false);
    backToPreviewPage();
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPagePath, { state });
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="WorkTaskCatalogInput">
          <div className="header">
            <PageNavigation
              backpagePath="/work-task/wt-request-catalog-list"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: lastUpdateInfo,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setConfirmDialogInfo({
                  viewMessage: viewMessageCancel,
                  onDecision: backToPreviewPage,
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25" ref={requiredCodeArea}>
                  <Textbox
                    name="code"
                    className="field"
                    value={code}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_INPUT.code"
                    columns={preset.columns}
                    disabled={isCodeDisabled}
                    validateOption={{ required: true }}
                    onChangeState={setCode}
                  />
                </div>
                <div className="w-33" ref={requiredDisplayNameArea}>
                  <Textbox
                    name="displayName"
                    className="field"
                    value={displayName}
                    type="text"
                    labelId="WT_REQUEST_CATALOG_INPUT.displayName"
                    columns={preset.columns}
                    validateOption={{ required: true }}
                    onChangeState={setDisplayName}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={categories}
                    name="category.code"
                    labelId="WT_REQUEST_CATALOG_INPUT.category.code"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={categoryCode}
                    onChangeState={setCategoryCode}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  <Textarea
                    name="contents"
                    className="w-100 mh-middle"
                    value={contents}
                    labelId="WT_REQUEST_CATALOG_INPUT.contents"
                    columns={preset.columns}
                    onChangeState={setContents}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25 line-height-4rem">
                  <Checkbox
                    name="checkbox"
                    className="group"
                    items={[
                      {
                        value: '1',
                        displayName: GetMessage({
                          prefixId: 'WT_REQUEST_CATALOG_INPUT',
                          id: 'approval',
                        }),
                      },
                    ]}
                    value={approval}
                    columns={['checkbox']}
                    onChangeState={setApproval}
                  />
                </div>
                <div className="w-25">
                  <Filterbox
                    name="managementOrganizationUnits"
                    fullMethodName={FullMethodName_ListComponentUnits}
                    labelId="WT_REQUEST_CATALOG_INPUT.managementOrganization"
                    columns={['managementOrganizationUnits']}
                    itemType={organaizationUnitsItemType}
                    value={
                      managementOrganization ?? [{ value: '', displayName: '' }]
                    }
                    searchOption={{
                      targets: 'displayName',
                      customQuery: {
                        'organizationUnit.systemName': { $eq: 'B02' },
                      },
                    }}
                    formatOption={{ mapKeyValue: 'ja' }}
                    onChangeState={setManagementOrganization}
                  />
                </div>
                <div className="w-25">
                  <Filterbox
                    name="workOrganizationUnits"
                    fullMethodName={FullMethodName_ListComponentUnits}
                    labelId="WT_REQUEST_CATALOG_INPUT.workOrganization"
                    columns={['workOrganizationUnits']}
                    itemType={organaizationUnitsItemType}
                    value={workOrganization ?? [{ value: '', displayName: '' }]}
                    searchOption={{
                      targets: 'displayName',
                      customQuery: {
                        'organizationUnit.systemName': { $eq: 'B02' },
                      },
                    }}
                    formatOption={{ mapKeyValue: 'ja' }}
                    onChangeState={setWorkOrganization}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100 file-area">
                <div className="w-50">
                  <SimpleListView
                    data={fileAttachment ?? []}
                    viewOptions={{
                      previewRowCount: 5,
                      columns: [
                        {
                          header: { id: 'attachedFile' },
                          propertyName: 'filename',
                        },
                      ],
                    }}
                    actionOptions={{
                      onDelete: (item: AttachmentItems) => {
                        setOpenConfirmDialog(true);
                        setConfirmDialogInfo({
                          viewMessage: viewMessageDelete,
                          onDecision: () => {
                            setFileAttachment(
                              fileAttachment?.filter((v) => v.id !== item.id)
                            );
                            setOpenConfirmDialog(false);
                          },
                        });
                      },
                      onDeleteAll: () => {
                        setConfirmDialogInfo({
                          viewMessage: viewMessageDeleteAll,
                          onDecision: () => {
                            setFileAttachment([]);
                            setOpenConfirmDialog(false);
                          },
                        });
                        setOpenConfirmDialog(true);
                      },
                      onRowClick: (item: AttachmentItems) => {
                        autoDownloadFileOnlyName(
                          item.filename ?? '',
                          item.assetId ?? ''
                        );
                      },
                      onFullDownLoad: () => {
                        autoBulkDownload(
                          (fileAttachment ?? []).map(
                            (item) => item.assetId ?? ''
                          ),
                          intl,
                          VIEW_ID
                        );
                      },
                    }}
                  />
                </div>
                <div className="w-20">
                  <CaptionButton
                    buttonType="basic"
                    caption="ファイル追加"
                    name=""
                    onClick={() => {
                      if (fileAttachment && fileAttachment.length >= 10) {
                        error([
                          GetMessageWithIntl(intl, {
                            id: 'E0000077',
                            value: { $1: 10 },
                          }),
                        ]);
                        return;
                      }
                      setOpenFileUploadDialog(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                name="resetBtn"
                caption="保存"
                className="btn"
                onClick={() => {
                  // 入力エラーチェック
                  if (
                    includeInputValidateError(document, intl, [
                      { value: code ?? '', ref: requiredCodeArea },
                      {
                        value: displayName ?? '',
                        ref: requiredDisplayNameArea,
                      },
                    ])
                  ) {
                    return;
                  }
                  setConfirmDialogInfo({
                    viewMessage: viewMessageSave,
                    onDecision: sendAction,
                  });
                  setOpenConfirmDialog(true);
                }}
                buttonType="basic"
              />
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmDialogInfo.viewMessage}
          onDecision={confirmDialogInfo.onDecision}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
        ></ConfirmationDialog>
        <FileUploadDialog
          isOpen={isOpenFileUploadDialog}
          messageOption={{
            headerLabelId: {
              id: 'file_add',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          fileUploadOption={{
            multiple: true,
            validateOption: {
              maxFileSizeInMebis: 50,
              maxFileCount: 10,
            },
          }}
          onCancel={() => {
            setOpenFileUploadDialog(false);
          }}
          onDecision={async (result) => {
            const attchmentfiels = await handleCommonFIleUpload(
              fileAttachment,
              result,
              'B06',
              intl
            );
            setFileAttachment(attchmentfiels);
            setOpenFileUploadDialog(false);
          }}
          onChangeLoadingState={(isLoading) => {
            setLoading(isLoading);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
