import { IconWithCaptionButton } from '~/shared/components/ui/Button';

export const UISampleIconWithCaptionButtons = () => {
  return (
    <div className="categories">
      <h3 id="icon-caption-buttons">アイコン + キャプションボタン</h3>
      <h4>enabled</h4>
      <div className="input-line">
        <IconWithCaptionButton
          buttonType="high"
          caption="high"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          properties={[
            {
              name: '',
              propertyName: 'textAlign',
              propertyValue: 'center',
            },
          ]}
        />
        <IconWithCaptionButton
          buttonType="basic"
          caption="basic"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          properties={[
            {
              name: '',
              propertyName: 'textAlign',
              propertyValue: 'center',
            },
          ]}
        />
        <IconWithCaptionButton
          buttonType="sub"
          caption="sub"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
        />
        <IconWithCaptionButton
          buttonType="danger"
          caption="danger"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
        />
        <IconWithCaptionButton
          buttonType="cancel"
          caption="cancel"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
        />
        <IconWithCaptionButton
          caption="no style"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
        />
      </div>
      <h4>disabled</h4>
      <div className="input-line">
        <IconWithCaptionButton
          buttonType="high"
          caption="high"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          disabled={true}
        />
        <IconWithCaptionButton
          buttonType="basic"
          caption="basic"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          properties={[
            {
              name: '',
              propertyName: 'disabled',
              propertyValue: true,
            },
          ]}
        />
        <IconWithCaptionButton
          buttonType="sub"
          caption="sub"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          disabled={true}
          properties={[
            {
              name: '',
              propertyName: 'textAlign',
              propertyValue: 'center',
            },
          ]}
        />
        <IconWithCaptionButton
          buttonType="danger"
          caption="danger"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          disabled={true}
        />
        <IconWithCaptionButton
          buttonType="cancel"
          caption="cancel"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          disabled={true}
        />
        <IconWithCaptionButton
          caption="no style"
          iconType="search"
          name=""
          onClick={() => {
            console.debug('IconWithCaptionButton');
          }}
          disabled={true}
        />
      </div>
    </div>
  );
};
