/**
 * ワーカーに関連する処理
 */

import type { FilterRequest } from '~/worker';
import { isRpcError } from '../utils';

type ReloadPromiseFn<T> = () => Promise<T>;

const defaultRetries = 3; // デフォルトリトライ回数
async function retryPromiseAll<T>(
  values: ReloadPromiseFn<T>[],
  retries?: number
): Promise<Awaited<T>[]> {
  return Promise.all(values.map((f) => retry<T>(f, retries ?? defaultRetries)));
}

export async function workerReloads(
  fullMethodNames: string[],
  retries?: number
): Promise<void> {
  await retryPromiseAll(
    fullMethodNames.map((v) => reload(v)),
    retries
  );
}

function reload(
  fullMethodName: string,
  request?: { [k: string]: unknown }
): ReloadPromiseFn<void> {
  return async (): Promise<void> => {
    const option: FilterRequest = {
      action: 'reload',
      fullMethodName: fullMethodName,
      filter: {},
      sort: [],
    };
    request && (option.requestBody = request);
    await window.App.services.ui.worker.filter(option);
  };
}

async function retry<T>(fn: () => Promise<T>, retries: number): Promise<T> {
  let lastError: unknown;
  for (let i = 0; i <= retries; i++) {
    try {
      return await fn();
    } catch (error) {
      lastError = error;
    }
  }
  if (isRpcError(lastError)) {
    throw lastError;
  } else {
    throw new RetryError(`All retries failed. LastError: ${lastError!}`);
  }
}

class RetryError extends Error {
  constructor(message: string) {
    super(message);
  }
}
