// 3桁区切り
export const CommaTypeFormatter = (value: string | number | null): string => {
  if (value === null) {
    return '';
  }
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: 20,
    });
  }
  if (!value) {
    return '';
  }
  const num = Number(value);

  if (Number.isNaN(num)) {
    return value;
  } else {
    return num.toLocaleString(undefined, {
      maximumFractionDigits: 20,
    });
  }
};

// ハイフン表示の郵便番号
export function formatZipCode(v: string) {
  // ハイフンを除去
  const removed = v.replaceAll('-', '');

  // 配列化
  const splited = removed.split('');

  // 空文字や郵便番号の書式を満たさない場合は空文字を返却
  if (splited.length !== 7) {
    return '';
  }

  return `${splited.slice(0, 3).join('')}-${splited.slice(3).join('')}`;
}
