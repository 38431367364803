import { CSSProperties, useMemo } from 'react';
import { Property } from '~/shared/services';
import {
  CaptionButton as CaptionButtonComponent,
  ButtonType,
} from '~/shared/components/parts/Button/CaptionButton';
import { getButtonProperties } from './utils';

export interface CaptionButtonProps {
  name: string;
  caption?: string;
  buttonType?: ButtonType;
  className?: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  properties?: Array<Property>;
  isNoAnalytics?: boolean;
}

export const CaptionButton = (props: CaptionButtonProps) => {
  const [buttonStyle, disabled] = useMemo<[CSSProperties, boolean]>(() => {
    return getButtonProperties(props.properties, props.disabled);
  }, [props.properties, props.disabled]);

  return (
    <>
      <CaptionButtonComponent
        name={props.name}
        className={props.className}
        caption={props.caption}
        buttonType={props.buttonType}
        style={buttonStyle}
        onClick={props.onClick}
        disabled={disabled}
        isNoAnalytics={props.isNoAnalytics}
      />
    </>
  );
};
