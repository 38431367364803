import {
  CSSProperties,
  Fragment,
  useState,
  useEffect,
  useRef,
  RefObject,
  useMemo,
  createRef,
} from 'react';
import { ReactComponent as KeyboardArrowRightIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as FirstPage } from '@material-design-icons/svg/filled/first_page.svg';
import { ReactComponent as KeyboardArrowLeftIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { ReactComponent as LastPage } from '@material-design-icons/svg/filled/last_page.svg';
import { ReactComponent as RefreshIcon } from '@material-design-icons/svg/filled/refresh.svg';

import './base.css';
import './MenuDataView.css';
import {
  GetMessageComponent,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { Button } from '~/shared/components/Button';
import { Select } from '~/shared/components/parts/Select/Select';
import { ReactComponent as Menu } from '@material-design-icons/svg/filled/arrow_drop_down.svg';
import { ReactComponent as HeaderMenu } from '@material-design-icons/svg/filled/menu.svg';
import { Checkbox } from '~/shared/components/parts/Checkbox/Checkbox';
import { error } from '~/shared/components/parts/Toast/Toast';
import { useIntl } from 'react-intl';
import { setMenuEventHandle, setMenuListStyle } from '../common';

// メニューアクション実行時にチェックボックスが空でもエラーとしないMenuName
const NO_CHECK_ACTION_MENU_NAME = [
  'add',
  'partialDeliveryAdd',
  'partialAmountAdd',
];

export interface MenuType {
  actionName: string;
  func: (v?: string[]) => void;
  displayName?: string;
  disabledCondFn?: (v: {}) => boolean;
}

export interface ExtendCheckboxOption {
  itemWidth: string;
  message?: MessageProps;
  targetId: string;
  func: (v?: string) => void;
  checkedValues?: string[];
}

interface MenuDataViewProps {
  title?: string;
  data?: Object[];
  header: HeaderInfo[];
  pageSize?: number;
  pageSizeOption?: PageSizeOption;
  maxMenuColumn?: string;
  menuTarget?: string;
  extendCheckboxOption?: ExtendCheckboxOption[];
  onChangeState?: (arg: string[]) => void;
  onReload?: () => void;
  allBurgerMenu?: MenuType[];
  listBurgerMenu?: MenuType[];
  isVisibleCheckbox?: boolean;
  isActionListOnly?: {
    all?: boolean;
    menu?: boolean;
    check?: boolean;
  };
}

interface PageSizeOption {
  pageSize?: number;
  isDisplay?: boolean;
}

export interface HeaderInfo {
  message: MessageProps;
  width?: string; // カラムの表示幅
  margin?: string; // 現状、明細部分の右寄せを行うためにmarginを設定している
}

export function MenuDataView(props: MenuDataViewProps) {
  const { maxMenuColumn = '3' } = props;
  const intl = useIntl();
  const [contents, setContents] = useState<{}[]>([]);
  const [displayContents, setDisplayContents] = useState<{}[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    props.pageSizeOption?.pageSize ?? 20
  );

  const [viewLabelItems, setViewLabelItems] = useState<Array<string>>([]);
  const [parentSelectItems, setParentSelectItems] = useState<Array<string>>([]);
  const onParentChangeState = props.onChangeState ?? (() => {});
  const [headerSelect, setHeaderSelect] = useState<string[]>([]);

  const selectMenuId = (selectedCheck?: number) => {
    const ids: string[] = [];
    displayContents.map((item, index) => {
      const isSelected = selectedCheck
        ? index === selectedCheck
        : parentSelectItems[index] === '1';
      if (isSelected) {
        const targetValue = item[props.menuTarget as keyof typeof item];
        if (targetValue) ids.push(targetValue);
      }
    });
    return ids;
  };

  // header部分のチェックボックス処理
  useEffect(() => {
    const tmpSelected: string[] = [];
    [...Array(pageSize)].forEach(() => {
      tmpSelected.push(headerSelect[0] === '1' ? '1' : '');
    });
    setParentSelectItems(tmpSelected);
    onParentChangeState(tmpSelected);

    // headerSelect 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerSelect]);

  useEffect(() => {
    const tmpSelected: string[] = [];
    [...Array(pageSize)].forEach(() => {
      tmpSelected.push('');
    });
    setParentSelectItems(tmpSelected);
    onParentChangeState(tmpSelected);

    // pageSize 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    onParentChangeState(parentSelectItems);

    // parentSelectItems 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentSelectItems]);

  useEffect(() => {
    const data = props.data ?? [];
    setContents(data); // 全データ
    const slice = data.slice(0, pageSize) ?? [];
    setDisplayContents(slice); // 表示されるデータ

    const maxPage = Math.max(Math.ceil(data.length / pageSize), 1);
    setMaxPage(maxPage);

    const list = props.header.map((items) => items.message.id ?? '');
    setViewLabelItems(list);

    // props.data, pageSize 変更時にのみ起動したいためlint除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, pageSize]);

  const headerMenuBoxRef = useRef<HTMLLIElement>(null);
  const listMenuBoxRefs: Map<number, RefObject<HTMLLIElement>> = useMemo(() => {
    return new Map<number, RefObject<HTMLLIElement>>();
  }, []);
  displayContents.forEach((_, idx) => {
    listMenuBoxRefs.set(idx, createRef());
  });
  useEffect(() => {
    // ヘッダメニューのイベントハンドラを設定
    const headerMenuBoxElement = headerMenuBoxRef?.current;
    if (headerMenuBoxElement) {
      setMenuEventHandle(headerMenuBoxElement);
    }
    // 一覧メニューのイベントハンドラを設定
    displayContents.forEach((_, idx) => {
      const listMenuBoxElement = listMenuBoxRefs.get(idx)?.current;
      if (!listMenuBoxElement) return;
      setMenuListStyle(contentsRef, listMenuBoxElement, maxMenuColumn);
      setMenuEventHandle(listMenuBoxElement);
    });
  }, [displayContents, listMenuBoxRefs, maxMenuColumn]);

  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), maxPage);
    const offset = (pageNumber - 1) * pageSize;
    setDisplayContents(contents.slice(offset, offset + pageSize));
    setCurrentPage(n);

    const tmpSelected: string[] = [];
    [...Array(pageSize)].forEach(() => {
      tmpSelected.push('');
    });
    onParentChangeState(tmpSelected);
  };

  const ListNavgate = () => {
    return (
      <div className="navgate">
        {props.pageSizeOption?.isDisplay && (
          <div className="count-number">
            {(contents ?? []).length}件中
            <label htmlFor="selectItems" className="label">
              <Select
                name=""
                value={[String(pageSize)]}
                items={[
                  { value: '10', displayName: '10' },
                  { value: '20', displayName: '20' },
                  { value: '30', displayName: '30' },
                  { value: '40', displayName: '40' },
                  { value: '50', displayName: '50' },
                ]}
                validateOption={{ isSkippedValidation: true }}
                onChangeState={(e) => setPageSize(parseInt(e[0] ?? 10, 10))}
              ></Select>
            </label>
            <span className="unit">件表示</span>
          </div>
        )}
        <div className="pages-btns">
          <Button
            name="firstPageIcon"
            className="btn btn-normal"
            properties={[
              {
                name: 'firstPageIcon',
                propertyName: 'firstPageIcon',
                propertyValue: 'firstPageIcon',
              },
            ]}
            buttonType="icon"
            send={() => handleMovePage(1)}
            styleType="normal"
            icon={<FirstPage />}
          />
          <Button
            name="prevIcon"
            className="btn btn-normal"
            properties={[
              {
                name: 'prevIcon',
                propertyName: 'prevIcon',
                propertyValue: 'prevIcon',
              },
            ]}
            buttonType="icon"
            send={() => handleMovePage(currentPage - 1)}
            styleType="normal"
            icon={
              <KeyboardArrowLeftIcon
                className={`${currentPage === 1 ? 'disabled-svg-icon' : ''}`}
              />
            }
            disabled={currentPage === 1}
          />
          <span className="page-counter">
            {currentPage} / {maxPage}
          </span>
          <Button
            name="nextIcon"
            className="btn btn-normal"
            properties={[
              {
                name: 'nextIcon',
                propertyName: 'nextIcon',
                propertyValue: 'nextIcon',
              },
            ]}
            buttonType="icon"
            send={() => handleMovePage(currentPage + 1)}
            styleType="normal"
            icon={
              <KeyboardArrowRightIcon
                className={`${
                  currentPage === maxPage ? 'disabled-svg-icon' : ''
                }`}
              />
            }
            disabled={currentPage === maxPage}
          />
          <Button
            name="lastPageIcon"
            className="btn btn-normal"
            properties={[
              {
                name: 'lastPageIcon',
                propertyName: 'lastPageIcon',
                propertyValue: 'lastPageIcon',
              },
            ]}
            buttonType="icon"
            send={() => handleMovePage(maxPage)}
            styleType="normal"
            icon={<LastPage />}
          />
          {/* リロード関数があればアイコンを表示 */}
          {props.onReload && (
            <Button
              name="headReloadIcon"
              className="btn btn-normal"
              properties={[
                {
                  name: 'headReloadIcon',
                  propertyName: 'headReloadIcon',
                  propertyValue: 'headReloadIcon',
                },
              ]}
              buttonType="icon"
              send={() => {
                props.onReload && props.onReload();
              }}
              styleType="cancel"
              icon={<RefreshIcon />}
            />
          )}
        </div>
      </div>
    );
  };

  const setHeaderWidth = () => {
    let text = '';
    if (props.allBurgerMenu || props.listBurgerMenu) text += '4rem ';
    if (props.isVisibleCheckbox) text += '4rem ';
    if (props.extendCheckboxOption) {
      text += props.extendCheckboxOption
        .map((op) => {
          return op.itemWidth;
        })
        .join(' ');
    }
    text += props.header
      .map((v) => {
        return v.width ?? '5rem';
      })
      .join(' ');
    return text;
  };

  const listViewStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: setHeaderWidth(),
    alignItems: 'center',
    flex: 1,
  };

  const contentsRef = useRef<HTMLDivElement>(null);

  const propertyDataViewNumberColumnStyle = (
    propertyName: string
  ): CSSProperties => {
    const numberColumn = props.header.find(
      (v) => v.message.id === propertyName
    )?.margin;
    if (numberColumn) {
      return {
        margin: numberColumn,
      };
    } else {
      return {};
    }
  };

  return (
    <div className="ListView DataView">
      {props.title && <h3 className="area-title">{props.title}</h3>}
      <div className="list-header">
        <ListNavgate />
      </div>
      <div className="contents" ref={contentsRef}>
        {/* ヘッダー */}
        <div className="list-caption">
          <div style={listViewStyle}>
            {props.isActionListOnly?.all && (
              <div className="list-header-cell burgerMenu"></div>
            )}
            {!props.isActionListOnly?.all &&
              (props.allBurgerMenu || props.listBurgerMenu) && (
                <div className="list-header-cell burgermenu">
                  <ul className="list">
                    <li className="menu-box" ref={headerMenuBoxRef}>
                      <a href="#">
                        <HeaderMenu />
                      </a>
                      <ul className="menu-list">
                        {props.allBurgerMenu?.map((v, index) => {
                          return (
                            <li key={index} className="menu-item">
                              <a
                                onClick={() => {
                                  const ids = selectMenuId();
                                  if (
                                    !NO_CHECK_ACTION_MENU_NAME.includes(
                                      v.actionName
                                    ) &&
                                    ids.length === 0
                                  ) {
                                    error([
                                      GetMessageWithIntl(intl, {
                                        id: 'E0000023',
                                      }),
                                    ]);
                                    return;
                                  }

                                  v.func(ids);
                                }}
                              >
                                {[v.displayName]}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            {props.isActionListOnly?.all && props.isVisibleCheckbox && (
              <div className="list-header-cell check"></div>
            )}
            {!props.isActionListOnly?.all && props.isVisibleCheckbox && (
              <div className="list-header-cell check">
                <Checkbox
                  name="all"
                  items={[{ value: '1', displayName: '' }]}
                  onChangeState={setHeaderSelect}
                  validateOption={{ isSkippedValidation: true }}
                />
              </div>
            )}
            {props.extendCheckboxOption &&
              props.extendCheckboxOption.map((op, i) => (
                <div className="list-header-cell" key={'extendCheck' + i}>
                  {op.message && <GetMessageComponent {...op.message} />}
                </div>
              ))}
            {props.header.map((header, i) => (
              <div className="list-header-cell" key={i}>
                <GetMessageComponent {...header.message} />
              </div>
            ))}
          </div>
        </div>
        {/* コンテンツ */}
        <div className="list-content">
          <div style={listViewStyle}>
            {displayContents.map((content, i) => (
              <Fragment key={`displayContents${i}`}>
                {(props.allBurgerMenu || props.listBurgerMenu) && (
                  <div
                    className={`burgermenu item${
                      i % 2 === 0 ? ' even' : ' odd'
                    }`}
                  >
                    <ul className="list">
                      <li className="menu-box" ref={listMenuBoxRefs.get(i)}>
                        <a href="#">
                          <Menu className="menuIcon" />
                        </a>
                        <ul className="menu-list">
                          {props.listBurgerMenu?.map((v, index) => {
                            const disabled =
                              !!v.disabledCondFn && v.disabledCondFn(content);
                            return (
                              <li key={index} className="menu-item">
                                <a
                                  className={`${
                                    disabled ? 'disabled-menu-item' : ''
                                  }`}
                                  onClick={() => {
                                    if (disabled) return;
                                    v.func([
                                      content[
                                        props.menuTarget as keyof typeof content
                                      ],
                                    ]);
                                  }}
                                >
                                  {[v.displayName]}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                )}
                {props.isVisibleCheckbox && (
                  <div
                    className={`item check${i % 2 === 0 ? ' even' : ' odd'}`}
                  >
                    <Checkbox
                      name={'list_' + i}
                      value={
                        parentSelectItems[i] !== '' ? parentSelectItems[i] : []
                      }
                      items={[{ value: '1', displayName: '' }]}
                      onChangeState={(props: string[]) => {
                        const tmp = props;
                        parentSelectItems[i] = tmp.length > 0 ? tmp[0] : '';
                        const newListSelect: string[] = [];
                        parentSelectItems.map((v) => {
                          newListSelect.push(v);
                        });
                        onParentChangeState(newListSelect);
                      }}
                      validateOption={{ isSkippedValidation: true }}
                    />
                  </div>
                )}
                {props.extendCheckboxOption &&
                  props.extendCheckboxOption.map((op, opIndex) => (
                    <div
                      key={opIndex}
                      className={`item check${i % 2 === 0 ? ' even' : ' odd'}`}
                    >
                      <Checkbox
                        name={(op?.message?.id ?? '') + opIndex}
                        items={[{ value: '1', displayName: '' }]}
                        value={[
                          op.checkedValues?.includes(
                            content[op?.targetId as keyof typeof content]
                          )
                            ? '1'
                            : '',
                        ]}
                        onChangeState={() => {
                          op.func(
                            content[op?.targetId as keyof typeof content]
                          );
                        }}
                        validateOption={{ isSkippedValidation: true }}
                      />
                    </div>
                  ))}
                {Object.entries(content).map(([key, value], number) => {
                  if (!viewLabelItems.includes(key)) return;
                  return (
                    <div
                      key={`info${number}`}
                      className={`item${i % 2 === 0 ? ' even' : ' odd'}`}
                    >
                      <div
                        className="viewText"
                        style={propertyDataViewNumberColumnStyle(key)}
                      >
                        {value as string}
                      </div>
                      <div className="description">{value as string}</div>
                    </div>
                  );
                })}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="list-footer">
        <ListNavgate />
      </div>
    </div>
  );
}
