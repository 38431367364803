import { Tabs } from '~/shared/components/ui';

const TabA = () => (
  <>
    <div className="categories">
      <h3 id="text-inputs">タブA内容</h3>
      <p>[何らかのコンテンツ]</p>
    </div>
  </>
);

const TabB = () => (
  <>
    <div className="categories">
      <h3 id="text-inputs">タブB内容</h3>
      <p>[何らかのコンテンツ]</p>
    </div>
  </>
);

export const UISampleTabs = () => {
  return (
    <div className="categories">
      <h3 id="tabs">タブ</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <Tabs
              tabIndex={1}
              tabItems={[
                {
                  label: 'タブA',
                  tabContent: <TabA />,
                },
                {
                  label: 'タブB',
                  tabContent: <TabB />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
