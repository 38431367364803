import { mtechnavi } from '~/shared/libs/clientsdk';
import { getDateFormatWithTimezone } from '~/shared/utils';

/**
 * 希望納期のフォーマット処理
 *  e.g. 2023/11/30 納入日指定（補足あり）/ AM11時ごろ
 */
export const formatDisplayDesiredReceivedDt = (
  data: mtechnavi.api.estimation.IEstimateRequest | null | undefined
): string => {
  if (!data) return '';

  const values: string[] = [];

  const date = getDateFormatWithTimezone(data.desiredReceiveDt);
  const type = data.desiredReceiveType?.displayNameLang?.ja;
  const remarks = data.desiredReceiveRemarks;

  if (date) {
    values.push(date);
    values.push(' ');
  }
  if (type) {
    values.push(type);
  }
  if (type && remarks) {
    values.push(' / ');
  }
  if (remarks) {
    values.push(remarks);
  }

  return values.join('');
};

/**
 * 納入先のフォーマット処理
 *  e.g. BBB工場（〒111-1111 愛知県名古屋市〇〇〇〇〇〇1-1-1）
 */
export const formatDisplayDeliveryPoint = (
  data: mtechnavi.api.estimation.IEstimateRequest | null | undefined
): string => {
  if (!data) return '';

  const values: string[] = [];

  const displayName = data.deliveryPoint?.displayNameLang?.ja;
  const postalCode = data.deliveryAddress?.postalCode;
  const addressLine = data.deliveryAddress?.addressLine;

  // 自社拠点の場合
  if (data.deliveryPointType?.systemName === 'B01') {
    if (displayName) {
      values.push(displayName);
    }
    if (postalCode || addressLine) {
      values.push('(');
    }
    if (postalCode) {
      values.push('〒');
      values.push(postalCode);
      values.push(' ');
    }
    if (addressLine) {
      values.push(addressLine);
    }
    if (postalCode || addressLine) {
      values.push(')');
    }
  } else {
    if (postalCode) {
      values.push('〒');
      values.push(postalCode);
      values.push(' ');
    }
    if (addressLine) {
      values.push(addressLine);
    }
  }

  return values.join('');
};

/**
 * 問合せ情報のフォーマット処理
 *  e.g. 調達部 企画管理課 愛知 太郎
 */
export const formatDisplayContactProperties = (
  data: mtechnavi.api.estimation.IEstimateRequest | null | undefined
): string => {
  if (!data) return '';

  const values: string[] = [];

  const organizationUnit = data.contactOrganizationUnit?.displayNameLang?.ja;
  const staff = data.contactStaff?.displayNameLang?.ja;

  if (organizationUnit) {
    values.push(organizationUnit);
    values.push(' ');
  }
  if (staff) {
    values.push(staff);
  }

  return values.join('');
};
