import { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import {
  UserAdditionDialog,
  UserAdditionResult,
} from '~/shared/components/ui/Dialog/UserAdditionDialog';
import {
  Textbox,
  SimpleListView,
  DataFilterbox,
  DataFilterboxItem,
  CommentDialog,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import './ContactRegistrationDialog.css';
import {
  includeInputValidateError,
  FullMethodName_ListBusinessUnitBranchs,
  convertDisplayUserNameEmail,
  getRandomStringId,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { error } from '~/shared/components/parts/Toast/Toast';

export type ContactRegistrationDisplayModeType = 'save' | 'send' | 'display';

export interface ContactRegistrationDialogInputOption {
  displayModeType: ContactRegistrationDisplayModeType;
  companyId: string;
  // 依頼時情報
  requestDocumentRecipientAddress?: boolean;
  requestNotificationUser?: boolean;
  // 更新処理用非表示項目
  id?: string;
  // 画面表示用項目
  displayName?: string;
  businessUnitBranchProperties?: mtechnavi.api.company.IBusinessUnitBranchProperties;
  alias?: string;
  notificationUsers?: mtechnavi.api.company.BusinessUnitContact.INotificationUser[];
  comment?: string;
}

export interface ContactRegistrationResult {
  id?: string;
  displayName?: string;
  businessUnitBranchProperties?: mtechnavi.api.company.IBusinessUnitBranchProperties;
  alias?: string;
  notificationUsers?: mtechnavi.api.company.BusinessUnitContact.INotificationUser[];
  comment?: string;
}

export interface ContactRegistrationDialogProps {
  isOpen: boolean;
  inputOption?: ContactRegistrationDialogInputOption;
  onDecision: (result: ContactRegistrationResult) => void;
  onCancel: () => void;
}

export const ContactRegistrationDialog = ({
  isOpen,
  inputOption,
  onDecision,
  onCancel,
}: ContactRegistrationDialogProps) => {
  const intl = useIntl();
  const [displayName, setDisplayName] = useState<string>('');
  const [businessUnitBranch, setBusinessUnitBranch] = useState<
    DataFilterboxItem[]
  >([]);
  const [isModeTypeDisplay, setModeTypeDisplay] = useState<boolean>(false);
  const [isModeTypeSend, setModeTypeSend] = useState<boolean>(false);
  // 取得データ格納用 拠点データ
  const businessUnitBranchPropertiesData =
    useRef<mtechnavi.api.company.IBusinessUnitBranchProperties>({});
  const [address, setAddress] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [faxNumber, setFaxNumber] = useState<string>('');
  const [alias, setAlias] = useState<string>('');

  interface extendNotificationUsers
    extends mtechnavi.api.company.BusinessUnitContact.INotificationUser {
    id: string;
  }

  const [notificationUsers, setNotificationUsers] = useState<
    extendNotificationUsers[]
  >([]);
  const [comment, setComment] = useState<string>('');

  // 必須制御用
  const requiredDisplayNameArea = useRef(null);
  const requiredBusinessUnitBranchArea = useRef(null);
  const requiredNotificationUsersArea = useRef(null);
  const [workingBlurBusinessUnitBranch, setWorkingBlurBusinessUnitBranch] =
    useState<Date>();

  // 送信ダイアログ
  const [isSendDialogOpen, setSendDialogOpen] = useState(false);

  // 担当追加ダイアログ
  const [isUserAdditionOpen, setUserAdditionOpen] = useState(false);

  // DataFilterbox用(拠点)
  // 拠点マスタ
  const businessUnitBranchsData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);
  const [businessUnitBranchItems, setBusinessUnitBranchItems] = useState<
    DataFilterboxItem[]
  >([]);

  // SimpleListView用
  interface notificationUserType {
    id: string; // key
    userId: string;
    category: string; // category.displayName.ja
    user: string; // user.displayName
  }
  const [notificationUserItems, setNotificationUserItems] = useState<
    notificationUserType[]
  >([]);

  // IAdressが共通化（sharelib.IAddress）されたタイミングで共通処理に移動することを検討
  const joinAddress = (address: mtechnavi.api.company.IAddress) => {
    if (!address) return '';
    const ary: string[] = [];
    ary.push(address.region?.displayNameLang?.ja ?? '');
    if (address.addressLines && address.addressLines?.length > 0) {
      address.addressLines.map((v) => ary.push(v));
    }
    return ary.join('');
  };

  const clearDisplay = () => {
    setDisplayName('');
    setBusinessUnitBranch([]);
    businessUnitBranchPropertiesData.current = {};
    setAddress('');
    setPhoneNumber('');
    setFaxNumber('');
    setAlias('');
    setNotificationUsers([]);
    setComment('');
    setWorkingBlurBusinessUnitBranch(undefined);
  };

  const handleCancel = () => {
    onCancel();
    clearDisplay();
  };

  const handleDecision = () => {
    const result: ContactRegistrationResult = {
      id: inputOption?.id,
      displayName: displayName,
      businessUnitBranchProperties: businessUnitBranchPropertiesData.current,
      alias: alias,
      notificationUsers: notificationUsers,
      comment: comment,
    };

    onDecision(result);
    setSendDialogOpen(false);
  };

  const handleAddNotificationUser = (result: UserAdditionResult) => {
    const notificationUserCategory =
      window.App.services.ui.getNameOptionWithSystemName(
        'A4000006',
        result.category === 'main' ? 'B01' : 'B02'
      );

    const users: mtechnavi.api.company.BusinessUnitContact.INotificationUser[] =
      [];
    notificationUsers.map((v) => {
      if (!result.users.some((w) => w.userId == v.user?.userId)) {
        users.push({
          category: v.category,
          user: v.user,
        });
      }
    });
    result.users.map((v) =>
      users.push({
        category:
          notificationUserCategory.length > 0
            ? notificationUserCategory[0]
            : {},
        user: v,
      })
    );

    if (users.length > 10) {
      error([GetMessageWithIntl(intl, { id: 'E0000117' })]);
    } else {
      const extendUsers: extendNotificationUsers[] = [];
      users.map((v) =>
        extendUsers.push({
          id: getRandomStringId(),
          category: v.category,
          user: v.user,
        })
      );
      setNotificationUsers(extendUsers);
    }
    setUserAdditionOpen(false);
  };

  const handleRemoveNotificationUser = (item: notificationUserType) => {
    setNotificationUsers(notificationUsers.filter((v) => v.id !== item.id));
  };

  const handleAllRemoveNotificationUser = () => {
    setNotificationUsers([]);
  };

  // 起動モード変更時
  useEffect(() => {
    setModeTypeDisplay((inputOption?.displayModeType ?? '') === 'display');
    setModeTypeSend((inputOption?.displayModeType ?? '') === 'send');
  }, [inputOption?.displayModeType]);

  // ダイアログ起動時
  useEffect(() => {
    if (isOpen) {
      clearDisplay();
      setDisplayName(inputOption?.displayName ?? '');
      businessUnitBranchPropertiesData.current =
        inputOption?.businessUnitBranchProperties ?? {};

      if ((inputOption?.displayModeType ?? '') === 'display') {
        setBusinessUnitBranch([
          {
            displayName:
              inputOption?.businessUnitBranchProperties?.displayName ?? '',
            value:
              inputOption?.businessUnitBranchProperties?.businessUnitBranchId ??
              '',
          },
        ]);
        setAddress(
          joinAddress(inputOption?.businessUnitBranchProperties?.address ?? {})
        );
        setPhoneNumber(
          inputOption?.businessUnitBranchProperties?.phoneNumber ?? ''
        );
        setFaxNumber(
          inputOption?.businessUnitBranchProperties?.faxNumber ?? ''
        );
        // メッセージは確認モードのみ表示
        setComment(inputOption?.comment ?? '');
      } else {
        (async () => {
          // 拠点マスタ
          const listBranchRes = (await window.App.services.ui.worker.filter({
            action: businessUnitBranchsData.current.length ? 'query' : 'reload',
            fullMethodName: FullMethodName_ListBusinessUnitBranchs,
            filter: {
              companyId: { $eq: inputOption?.companyId ?? '' },
            },
            sort: [],
          })) as mtechnavi.api.company.ListBusinessUnitBranchsResponse;

          const itemBranch: mtechnavi.api.company.IBusinessUnitBranch =
            listBranchRes?.items.length > 0 ? listBranchRes?.items[0] : {};
          if (itemBranch?.businessUnitBranchId === '') {
            return;
          }

          const items: DataFilterboxItem[] = listBranchRes.items
            ? listBranchRes.items!.map(
                (v): DataFilterboxItem => ({
                  displayName: v.displayName ?? '',
                  value: v.businessUnitBranchId ?? '',
                })
              ) ?? []
            : [];

          businessUnitBranchsData.current = listBranchRes.items;
          setBusinessUnitBranchItems(items);

          // 最新の拠点情報で表示する
          const r = businessUnitBranchsData.current.find(
            (v) =>
              v.businessUnitBranchId ===
              inputOption?.businessUnitBranchProperties?.businessUnitBranchId
          );
          if (r) {
            setBusinessUnitBranch([
              {
                displayName: r.displayName ?? '',
                value: r.businessUnitBranchId ?? '',
              },
            ]);
            setAddress(joinAddress(r.address ?? {}));
            setPhoneNumber(r.phoneNumber ?? '');
            setFaxNumber(r.faxNumber ?? '');
          }
        })();
      }
      setAlias(inputOption?.alias ?? '');
      const users: extendNotificationUsers[] = [];
      if (inputOption?.notificationUsers) {
        inputOption?.notificationUsers.map((user) => {
          users.push({
            id: getRandomStringId(),
            category: user.category,
            user: user.user,
          });
        });
      }
      setNotificationUsers(users);
    }
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // 拠点変更時
  useEffect(() => {
    if (isModeTypeDisplay) return;

    setAddress('');
    setPhoneNumber('');
    setFaxNumber('');

    if (businessUnitBranch.length > 0) {
      const val: mtechnavi.api.company.IBusinessUnitBranch | undefined =
        inputOption && businessUnitBranchsData.current
          ? businessUnitBranchsData.current.find(
              (v) => v.businessUnitBranchId === businessUnitBranch[0].value
            )
          : {};
      if (val) {
        businessUnitBranchPropertiesData.current = {
          businessUnitBranchId: val.businessUnitBranchId,
          displayName: val.displayName,
          address: val.address,
          phoneNumber: val.phoneNumber,
          faxNumber: val.faxNumber,
        };
        setAddress(joinAddress(val.address ?? {}));
        setPhoneNumber(val.phoneNumber ?? '');
        setFaxNumber(val.faxNumber ?? '');
      }
    } else {
      businessUnitBranchPropertiesData.current = {};
    }
    // businessUnitBranch変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitBranch]);

  // 通知先アカウント変更時
  useEffect(() => {
    const items: notificationUserType[] = [];
    notificationUsers.map((v) =>
      items.push({
        id: v.id ?? getRandomStringId(),
        userId: v.user?.userId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        user: convertDisplayUserNameEmail(v.user?.displayName, v.user?.email),
      })
    );
    setNotificationUserItems(items);
  }, [notificationUsers]);

  // 送信ダイアログ起動時
  useEffect(() => {
    setComment('');

    // isSendDialogOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendDialogOpen]);

  const isInputError = (): boolean => {
    // 拠点選択・通知先アカウント件数チェック
    if (
      !inputOption?.requestDocumentRecipientAddress &&
      !inputOption?.requestNotificationUser &&
      businessUnitBranch.length === 0 &&
      notificationUsers.length === 0
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000100' })]);
      return true;
    }
    // 拠点選択チェック
    // 未入力エラーの表示よりも優先する
    if (
      inputOption?.requestDocumentRecipientAddress &&
      businessUnitBranch.length === 0
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000101' })]);
      return true;
    }
    // 通知先アカウント件数チェック
    if (
      inputOption?.requestNotificationUser &&
      notificationUsers.length === 0
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000097' })]);
      return true;
    }

    // 必須入力チェック
    setWorkingBlurBusinessUnitBranch(new Date());

    const inputValidationCheckList = [
      {
        value: displayName ?? '',
        ref: requiredDisplayNameArea,
      },
    ];

    if (inputOption?.requestDocumentRecipientAddress) {
      inputValidationCheckList.push({
        value: businessUnitBranch.length > 0 ? businessUnitBranch[0].value : '',
        ref: requiredBusinessUnitBranchArea,
      });
    }

    const targetElm = document.querySelector('.contact-registration-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }

    return false;
  };

  const elements = (
    <div className="contact-registration-dialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <div className="input" ref={requiredDisplayNameArea}>
                <Textbox
                  name="displayName"
                  labelId="ContactRegistrationDialog.displayName"
                  type="text"
                  validateOption={{ required: true }}
                  columns={['displayName']}
                  value={displayName}
                  onChangeState={setDisplayName}
                  disabled={
                    isModeTypeDisplay || (inputOption?.displayName ?? '') !== ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'documentRecipientAddress',
                prefixId: 'ContactRegistrationDialog',
              })}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <div className="input" ref={requiredBusinessUnitBranchArea}>
                <DataFilterbox
                  data={businessUnitBranchItems}
                  name="businessUnitBranch"
                  labelId="ContactRegistrationDialog.businessUnitBranch"
                  columns={['businessUnitBranch']}
                  validateOption={{
                    required: inputOption?.requestDocumentRecipientAddress,
                  }}
                  searchOption={{ targets: 'displayName' }}
                  onChangeState={setBusinessUnitBranch}
                  value={businessUnitBranch}
                  disabled={isModeTypeDisplay}
                  workingBlur={workingBlurBusinessUnitBranch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <div className="input">
                <Textbox
                  name="address"
                  labelId="ContactRegistrationDialog.address"
                  type="text"
                  columns={['address']}
                  value={address}
                  disabled={true}
                />
              </div>
            </div>
            <div className="w-33">
              <div className="input">
                <Textbox
                  name="alias"
                  labelId="ContactRegistrationDialog.alias"
                  type="text"
                  columns={['alias']}
                  value={alias}
                  onChangeState={setAlias}
                  disabled={isModeTypeDisplay}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <div className="input">
                <Textbox
                  name="phoneNumber"
                  labelId="ContactRegistrationDialog.phoneNumber"
                  type="text"
                  columns={['phoneNumber']}
                  value={phoneNumber}
                  disabled={true}
                />
              </div>
            </div>
            <div className="w-33">
              <div className="input">
                <Textbox
                  name="faxNumber"
                  labelId="ContactRegistrationDialog.faxNumber"
                  type="text"
                  columns={['faxNumber']}
                  value={faxNumber}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'notificationUsers',
                prefixId: 'ContactRegistrationDialog',
              })}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100" ref={requiredNotificationUsersArea}>
            <div className="w-66">
              <div className="notification-user-list-area">
                <SimpleListView
                  data={notificationUserItems}
                  viewOptions={{
                    readonly: isModeTypeDisplay,
                    omitFooter: true,
                    previewRowCount: 3,
                    keyColumn: 'id',
                    columns: [
                      {
                        propertyName: 'category',
                        width: '8rem',
                        header: {
                          id: 'category',
                          prefixId: 'ContactRegistrationDialog',
                        },
                      },
                      {
                        propertyName: 'user',
                        header: {
                          id: 'user',
                          prefixId: 'ContactRegistrationDialog',
                        },
                      },
                    ],
                  }}
                  actionOptions={{
                    onDelete: handleRemoveNotificationUser,
                    onDeleteAll: handleAllRemoveNotificationUser,
                  }}
                />
              </div>
            </div>
            <div className="w-33">
              <div className="add-button">
                {!isModeTypeDisplay && (
                  <CaptionButton
                    name="addBtn"
                    className="button-bottom-margin"
                    caption={GetMessageWithIntl(intl, { id: 'add' })}
                    onClick={() => {
                      if (notificationUserItems.length === 10) {
                        error([GetMessageWithIntl(intl, { id: 'E0000117' })]);
                      } else {
                        setUserAdditionOpen(true);
                      }
                    }}
                    buttonType="basic"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          {!isModeTypeDisplay && (
            <CaptionButton
              name="decisionBtn"
              buttonType={isModeTypeSend ? 'high' : 'basic'}
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: isModeTypeSend ? 'send' : 'save',
              })}
              onClick={() => {
                if (isInputError()) {
                  return;
                }
                if (isModeTypeSend) {
                  setSendDialogOpen(true);
                } else {
                  handleDecision();
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: isModeTypeDisplay
      ? {
          prefixId: 'DIALOG_TITLE',
          id: 'contactConfirmation',
        }
      : {
          prefixId: 'DIALOG_TITLE',
          id: 'contactRegistration',
        },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <div className="ContactRegistrationDialog">
        <ModalDialogComponent {...openModalProps} />
      </div>
      {/* 担当追加ダイアログ */}
      <UserAdditionDialog
        isOpen={isUserAdditionOpen}
        onCancel={() => {
          setUserAdditionOpen(false);
        }}
        onDecision={handleAddNotificationUser}
      />
      {/* 送信 ダイアログ */}
      <CommentDialog
        isOpen={isSendDialogOpen}
        inputOption={{
          modeType: 'send',
          butonType: 'high',
          comment: comment,
        }}
        inputStateOption={{ onChangeComment: setComment }}
        messageOption={{
          headerLabelId: {
            id: 'send',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'C0000004',
            value: {
              $1: GetMessageWithIntl(intl, { id: 'send' }),
            },
          },
          captionLabelId: {
            id: 'comment',
            prefixId: 'SendDialog',
          },
          decisionLabelId: { id: 'send' },
        }}
        onDecision={handleDecision}
        onCancel={() => {
          setSendDialogOpen(false);
        }}
      />
    </>
  );
};
