import { PropsWithoutRef } from 'react';
import {
  Textarea,
  TextareaValidateOption,
} from '~/shared/components/parts/Textarea/Textarea';
import { IconButton } from '~/shared/components/ui/Button';
import './base.css';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface FormInputTextareaProps {
  name: string;
  label?: string;
  description?: string;
  value?:
    | mtechnavi.api.form.IFormControlTextArea
    | mtechnavi.api.form.IFormControlLabelMultiLine
    | null;
  validateOption?: TextareaValidateOption;
  disabled?: boolean;
  onChange?: (v: mtechnavi.api.form.IFormControlTextArea) => void;
}
export const FormInputTextarea = (
  props: PropsWithoutRef<FormInputTextareaProps>
) => {
  const value: string | undefined = props.value?.displayName || undefined;
  const handleChangeValue = (value: string) => {
    if (!props?.onChange) {
      return;
    }
    props?.onChange({
      displayName: value,
    });
  };
  return (
    <div className="FormInput FormInputTextarea">
      <span className={props.label !== '' ? 'caption' : 'caption no-name'}>
        {props.label && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label topping-label-with-help ${
                props.disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.label || ''}
              {!!props.description && (
                <IconButton
                  name="help"
                  className="HelpIcon"
                  iconType="help"
                  buttonType="basic"
                  onClick={() => {}}
                  caption={props.description}
                />
              )}
            </div>
          </div>
        )}
      </span>
      <Textarea
        name={props.name}
        value={value}
        validateOption={props.validateOption}
        disabled={!!props.disabled}
        onChangeState={handleChangeValue}
      />
    </div>
  );
};
