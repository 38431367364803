import React, { PropsWithChildren } from 'react';
import { TaskListIconView } from './TaskListIconView';
import { TaskListView } from './TaskListView';
import { SectionListView } from './SectionListView';
import { SectionView } from './SectionView';
import { TaskListLoadingProvider } from './TaskListLoadingProvider';

export interface TaskListProps {
  mode?: 'bell' | 'none';
  open?: boolean;
  onClick?: () => void;
  onChange?: (v: number) => void;
}
export const TaskList = ({
  children,
  mode,
  open,
  onClick,
  onChange,
}: PropsWithChildren<TaskListProps>) => {
  return (
    <>
      <TaskListLoadingProvider>
        <TaskListIconView icon="insert_comment" mode={mode} onClick={onClick} />
        <TaskListView open={open} onChange={onChange}>
          <SectionListView>
            <SectionView>{children}</SectionView>
          </SectionListView>
        </TaskListView>
      </TaskListLoadingProvider>
    </>
  );
};
