import './ToolMenu.css';
import { ToolBar } from './ToolBar';
import { useToolContext } from '~/shared/contexts/ToolMenuProvider';
import { Forum } from './Forum';
import { toolItems } from './config';
import { getGenerallyIconElement } from '../parts/Button/GenerallyIcons';
import { IconButton } from '../parts/Button/IconButton';
import { TooltipAreaProvider } from '../parts/Tooltip';

export function ToolMenu() {
  const { activeToolType, setActiveToolType } = useToolContext();

  const handleClose = () => {
    setActiveToolType('None');
  };

  const ToolItems = {
    Forum: <Forum />,
  };

  const renderIcon = (toolType: string) => {
    const activeToolIcon = toolItems.find(
      (item) => item.name === toolType
    )?.icon;
    if (!activeToolIcon) {
      return;
    }
    return (
      <>
        {getGenerallyIconElement(activeToolIcon)}
        {getGenerallyIconElement('next')}
      </>
    );
  };

  return (
    <div className="ToolMenu">
      <TooltipAreaProvider>
        {activeToolType === 'None' ? (
          <ToolBar />
        ) : (
          <div className="tool-contents">
            <div className="tool-header" onClick={(e) => e.stopPropagation()}>
              <IconButton
                className="tool-close"
                name="close"
                buttonType="basic"
                icon={renderIcon(activeToolType)}
                onClick={handleClose}
              />
            </div>
            <div className="tool-content">{ToolItems[activeToolType]}</div>
          </div>
        )}
      </TooltipAreaProvider>
    </div>
  );
}
