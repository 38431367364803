import { useEffect, useRef, useState } from 'react';
import { Container, GetMessage, Toast, error } from '~/shared/components';
import {
  Textbox,
  SimplePageNavigation,
  Textarea,
} from '~/shared/components/ui';
import { PresetItem } from '~/shared/services';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useIntl } from 'react-intl';
import {
  PageState,
  ViewId,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  FullMethodName_ListTransactionUnits,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

const VIEW_ID: ViewId = 'MASTER_TRANSACTION_UNIT_CONFIRMATION';

export function MasterTransactionUnit() {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navi = useNavigate();
  const handleError = useErrorHandler();
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListTransactionUnits,
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      transactionUnitId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage(
      'MASTER_TRANSACTION_UNIT_LIST',
      myEmail
    ),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();

    // 基本情報でセットした値を元に情報を取得
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListTransactionUnits,
      pageNumber: 1,
    });

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [item, setItem] = useState<mtechnavi.api.blueprint.TransactionUnit>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.blueprint.TransactionUnit[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListTransactionUnits,
      pageNumber: n,
    });
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <Container viewId={VIEW_ID}>
      <div className="MaterTransactionUnit">
        <div className="header">
          <SimplePageNavigation
            pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
            handleMovePage={handleMovePage}
          />
        </div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="info">
            <div className="basic-info-body">
              <div className="inputfields">
                <div className="field-block middle">
                  <Textbox
                    name="transactionUnitCode"
                    className="field"
                    value={item?.transactionUnitCode ?? ''}
                    type="text"
                    labelId="transactionUnitCode"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="displayNameLang.ja"
                    className="field"
                    value={item?.displayNameLang.ja ?? ''}
                    type="text"
                    labelId={`${VIEW_ID}.displayNameLang.ja`}
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="blueprintReference.blueprintNumber"
                    className="field"
                    value={item?.blueprintReference?.blueprintNumber ?? ''}
                    type="text"
                    labelId="blueprintReference.blueprintNumber"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="blueprintReference.branchNumber"
                    className="field"
                    value={item?.blueprintReference?.branchNumber ?? ''}
                    type="text"
                    labelId="blueprintReference.branchNumber"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="blueprintReference.revision"
                    className="field"
                    value={`${
                      item?.blueprintReference?.revision ??
                      GetMessage({ id: 'latestRevision' })
                    }`}
                    type="text"
                    labelId="blueprintReference.revision"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="catalogReference.modelNumber"
                    className="field"
                    value={item?.catalogReference?.modelNumber ?? ''}
                    type="text"
                    labelId="catalogReference.modelNumber"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="catalogReference.manufacture"
                    className="field"
                    value={item?.catalogReference?.manufacture ?? ''}
                    type="text"
                    labelId="catalogReference.manufacture"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="field-block middle">
                  <Textbox
                    name="quantityUnit.displayNameLang.ja"
                    value={item?.quantityUnit?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="quantityUnit.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="processs.displayNameLang.ja"
                    value={
                      item?.processs
                        .map((v) => v.displayNameLang!.ja)
                        .join(',') ?? ''
                    }
                    type="text"
                    labelId="processs.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="category1.displayNameLang.ja"
                    value={item?.category1?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="category1.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="category2.displayNameLang.ja"
                    value={item?.category2?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="category2.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="category3.displayNameLang.ja"
                    value={item?.category3?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="category3.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="field-block minor">
                  <Textbox
                    name="usable.displayNameLang.ja"
                    value={item?.usable?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="usable.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="field-block long">
                  <Textarea
                    name="contactMatter"
                    value={item?.contactMatter ?? ''}
                    labelId="contactMatter"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <CaptionButton
              className="button-margin"
              name=""
              caption="戻る"
              properties={[]}
              onClick={() => {
                navi('/master-transaction-unit-list');
              }}
              buttonType="basic"
            />
            <SimplePageNavigation
              pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
              handleMovePage={handleMovePage}
            />
          </div>
        </div>
      </div>
      <Toast />
    </Container>
  );
}
