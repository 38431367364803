import {
  ModalDialogComponent,
  ModalDialogComponentProps,
  GetMessage,
} from '~/shared/components';
import { Textarea, Textbox } from '~/shared/components/ui';
import './GoogleCertificateSettingDialog.css';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

export interface GoogleCetificateSettingItem {
  ssosetting?: string;
  ssourl?: string;
  entityId?: string;
  cetificateExpandDt?: string;
  certificateBody?: string;
}

export interface GoogleCertificateSettingDialogProps {
  inputOption: GoogleCetificateSettingItem;
  isOpen: boolean;
  onDecision: () => void;
  onCancel: () => void;
}

export function GoogleCertificateSettingDialog(
  props: GoogleCertificateSettingDialogProps
) {
  const getLabelId = (column: string): string => {
    return `googleCetificateSettingDialog.${column}`;
  };
  const { isOpen, inputOption, onCancel, onDecision } = props;
  const view = (
    <div className="content-box">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {/* 設定方法 */}
              <Textarea
                name="ssosetting"
                labelId={getLabelId('ssosetting')}
                value={inputOption.ssosetting}
                columns={['ssosetting']}
                className="w-100 mh-middle"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <CaptionButton
                buttonType="basic"
                className="w-15"
                caption={GetMessage({
                  id: 'metadataUpload',
                  prefixId: 'googleCetificateSettingDialog',
                })}
                name="metadata"
                onClick={() => {
                  console.debug('メタデータアップロードクリック');
                }}
              />
            </div>
          </div>
        </div>

        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              {/* SSOのURL */}
              <Textbox
                name="ssourl"
                className="field"
                value={inputOption.ssourl}
                type="text"
                labelId={getLabelId('ssourl')}
                columns={['ssourl']}
                disabled={true}
              />
            </div>
            <div className="w-50">
              {/* エンティティID */}
              <Textbox
                name="entityId"
                className="field"
                value={inputOption.entityId}
                type="text"
                labelId={getLabelId('entityId')}
                columns={['entityId']}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              {/* 証明書の有効期限 */}
              <Textbox
                name="cetificateExpandDt"
                className="field"
                value={inputOption.cetificateExpandDt}
                type="text"
                labelId={getLabelId('cetificateExpandDt')}
                columns={['cetificateExpandDt']}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {/* 証明書の内容 */}
              <Textarea
                name="certificateBody"
                labelId={getLabelId('certificateBody')}
                value={inputOption.certificateBody}
                columns={['certificateBody']}
                className="w-100 mh-middle"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              onCancel && onCancel();
            }}
          />
          <CaptionButton
            name="DecisionBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({ id: 'save' })}
            onClick={() => onDecision()}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel && onCancel();
    },
    send: () => onDecision!(),
    modalIsOpen: isOpen,
    headerLabelId: { prefixId: 'DIALOG_TITLE', id: 'googleCetificateSetting' },
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="GoogleCertificateSettingDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
}
