import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Textarea, Textbox } from '~/shared/components/ui';
import { FilterboxItem } from '../Filterbox';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { CaptionButton } from '../Button/CaptionButton';
import './base.css';

export type DialogType = 'approval' | 'rejection';

export interface ApprovalRejectionDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
}

export interface ApprovalRejectionInputOption {
  approveOrganizationUnit: FilterboxItem;
  approveOrganizationStaff: FilterboxItem;
  requestComment: string;
  dialogType: DialogType;
  isHighAffect?: boolean;
}

export interface ApprovalRejectionDialogProps {
  isOpen: boolean;
  messageOption: ApprovalRejectionDialogMessageOption;
  inputOption: ApprovalRejectionInputOption;
  onDecision: (v: string) => void;
  onCancel: () => void;
}

export const ApprovalRejectionDialog = (
  props: ApprovalRejectionDialogProps
) => {
  const intl = useIntl();
  const { isOpen, inputOption, messageOption } = props;
  const [approverComment, setApproverComment] = useState<string>('');

  const view = (
    <div className="approval-rejection-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          {messageOption.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="approveOrganizationUnit"
                  labelId="approvalRejectionDialog.approval_organization_unit"
                  columns={['approveOrganizationUnit']}
                  type="text"
                  value={inputOption.approveOrganizationUnit.displayName}
                  disabled={true}
                />
              </div>
              <div className="w-50">
                <Textbox
                  name="approveOrganizationStaff"
                  labelId="approvalRejectionDialog.approval_organization_staff"
                  columns={['approveOrganizationStaff']}
                  type="text"
                  value={inputOption.approveOrganizationStaff.displayName}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="requestComment"
                labelId="approvalRejectionDialog.request_comment"
                value={inputOption.requestComment}
                columns={['requestComment']}
                className="w-100 mh-middle"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="approverComment"
                labelId="approvalRejectionDialog.approver_comment"
                value=""
                columns={['approverComment']}
                className="w-100 mh-middle"
                onChangeState={setApproverComment}
              />
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => props.onCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType={inputOption.isHighAffect ? 'high' : 'basic'}
            className="button"
            caption={GetMessage({
              prefixId: 'DIALOG_CAPTION',
              id: inputOption.dialogType,
            })}
            onClick={() => {
              props.onDecision(approverComment);
            }}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
