import React, { useCallback, useEffect, useState } from 'react';
import './Tabs.css';

export interface TabItem {
  label?: string; // タブ名
  tabContent?: React.ReactNode; // タブの内容である要素
}

export interface TabsProps {
  tabIndex?: number; // 利用側から表示タブを指定するプロパティ
  tabItems?: TabItem[]; // タブの構成情報
  onChangeSelecetedTabIndex?: (arg: number) => void;
}
export const Tabs = ({
  tabItems,
  tabIndex,
  onChangeSelecetedTabIndex,
}: TabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    tabIndex || 0
  );

  const handleTabChange = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  useEffect(() => {
    if (tabIndex === undefined) {
      return;
    }
    setSelectedTabIndex(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    onParentSelectedTabIndex(selectedTabIndex);
  }, [selectedTabIndex]);

  const onParentSelectedTabIndex = useCallback(
    (arg: number) => {
      onChangeSelecetedTabIndex && onChangeSelecetedTabIndex(arg);
    },
    [onChangeSelecetedTabIndex]
  );

  return (
    <div className="Tabs">
      <div className="tabPanelContents">
        {tabItems?.map((tab, index) => (
          <div
            key={index}
            className={`tabPanel ${
              index === selectedTabIndex ? 'current' : ''
            }`}
            onClick={() => handleTabChange(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-contents">
        {tabItems
          ?.filter((tab, index) => index === selectedTabIndex)
          .map((tab, index) => (
            <React.Fragment key={index}>{tab.tabContent}</React.Fragment>
          ))}
      </div>
    </div>
  );
};
