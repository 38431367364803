import {
  Children,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  RenderSectionListView,
  SectionListView,
  SectionListViewProps,
  State,
} from './SectionListView';

import './TaskListView.css';

const isSectionListElement = (
  element: unknown
): element is ReactElement<PropsWithChildren<SectionListViewProps>> => {
  return (
    typeof element === 'object' &&
    isValidElement(element) &&
    element.type === SectionListView
  );
};

interface TaskListViewProps {
  open?: boolean;
  trigger?: number;
  onChange?: (v: number) => void;
}

export function TaskListView(
  props: PropsWithChildren<TaskListViewProps>
): JSX.Element {
  const { children, open = false, trigger = 0, onChange } = props;

  const [sctionState, setSctionState] = useState<State>();

  useEffect(() => {
    if (!sctionState) return;
    onChange &&
      onChange(
        // タスク数を集計
        Array.from(sctionState.sectionStates.values()).reduce(
          (total, v) => total + v.taskTotal,
          0
        )
      );
  }, [sctionState, onChange]);

  const handleChangeState = useCallback((v: State): void => {
    setSctionState(v);
  }, []);

  let renderSectionList: ReactNode;
  Children.forEach(children, (element, idx) => {
    if (!element) {
      return;
    }
    if (isSectionListElement(element)) {
      if (renderSectionList) {
        throw new Error(
          '<SectionList> must be used only once as child component'
        );
      }
      renderSectionList = (
        <RenderSectionListView
          key={idx}
          trigger={trigger}
          onChangeState={handleChangeState}
        >
          {element.props.children}
        </RenderSectionListView>
      );
    }
  });
  return (
    <div className={`TaskListView ${open ? 'open' : ''}`}>
      {open && renderSectionList}
    </div>
  );
}
