/**
 * Amount 型との相互変換処理
 */

import Long from 'long';
import { Amount } from '../commonType';
import { Amounts } from '../';
import { sharelib } from '~/shared/libs/clientsdk';

/**
 * Amount を文字列に変換する
 */
export function convertAmountToString(
  value: Amounts | undefined | null,
  isComma?: boolean
) {
  if (value === null || value === undefined) {
    return '';
  }
  const integralAmount = value?.integralAmount
    ? Long.fromValue(value.integralAmount).toString()
    : '0';
  const fractionalAmount = value?.fractionalAmount
    ? `.${String(value.fractionalAmount).padStart(4, '0')}`
    : '';
  const amount = Number(`${integralAmount}${fractionalAmount}`);

  return isComma ? amount.toLocaleString() : amount.toString();
}

/**
 * 文字列を Amount に変換する
 */
export const convertStringToAmount = (
  stringNum?: string | null
): sharelib.IAmount => {
  const initialAmount = {
    integralAmount: Long.fromNumber(0),
    fractionalAmount: 0,
  };
  if (!stringNum) {
    return initialAmount;
  }
  const [integral, fractional] = stringNum.split('.');
  const integralAmount = Long.fromValue(integral);
  const fractionalAmount = Number(
    (fractional || '').padEnd(4, '0').substring(0, 4)
  );
  if (Number.isNaN(integralAmount) || Number.isNaN(fractionalAmount)) {
    return initialAmount;
  }
  return {
    integralAmount: integralAmount,
    fractionalAmount: fractionalAmount,
  };
};

// MonetaryAmount型に変換（APIに情報登録時に利用）
export function convertMonetaryAmount(
  amountValue: Amounts | undefined | null
): sharelib.IMonetaryAmount | null {
  if (!amountValue) {
    return null;
  }
  const currencyCode = 'JPY'; // 現在は固定（2022/12/14）

  return {
    ...amountValue,
    currencyCode,
  };
}

// APIでQuantityAmount型で登録する際に利用
export function convertQuantityAmount(
  unitCode: string,
  quantity: string | undefined
): sharelib.IQuantityAmount {
  const amount = convertAmount(quantity);
  return {
    unit: window.App.services.ui.getNameOptionWithCode('A0000005', unitCode),
    integralAmount: amount.integralAmount,
    fractionalAmount: amount.fractionalAmount,
  };
}

export function convertAmount(value: string | undefined): Amount {
  if (!value) {
    return {
      integralAmount: null,
      fractionalAmount: null,
    };
  }
  const split = value.split('.');

  return {
    integralAmount: Long.fromString((split.shift() ?? '0').replaceAll(',', '')), // 基本的に数値のみが入力されるが、念の為3桁区切りのカンマを除去
    fractionalAmount: Number(split.shift()?.slice(0, 4).padEnd(4, '0') ?? 0),
  };
}

/** IQuantityAmount を INullableQuantityAmount に変換する */
export const toNullableQuantityAmount = (
  amount?: sharelib.IQuantityAmount | null
): sharelib.INullableQuantityAmount | null => {
  if (!amount) {
    return null;
  }
  return {
    unit: amount.unit,
    amountNum: convertAmountToString(amount),
  };
};

/** INullableQuantityAmount を IQuantityAmount に変換する */
export const toQuantityAmount = (
  nullableAmount?: sharelib.INullableQuantityAmount | null
): sharelib.IQuantityAmount | null => {
  if (!nullableAmount) {
    return null;
  }
  return {
    unit: nullableAmount.unit,
    ...convertStringToAmount(nullableAmount.amountNum),
  };
};

/** IMonetaryAmount を INullableMonetaryAmount に変換する */
export const toNullableMonetaryAmount = (
  amount?: sharelib.IMonetaryAmount | null
): sharelib.INullableMonetaryAmount | null => {
  if (!amount) {
    return null;
  }
  return {
    currencyCode: amount.currencyCode,
    amountNum: convertAmountToString(amount),
  };
};

/** INullableMonetaryAmount を IMonetaryAmount に変換する */
export const toMonetaryAmount = (
  nullableAmount?: sharelib.INullableMonetaryAmount | null
): sharelib.IMonetaryAmount | null => {
  if (!nullableAmount) {
    return null;
  }
  return {
    currencyCode: nullableAmount.currencyCode,
    ...convertStringToAmount(nullableAmount.amountNum),
  };
};

/**
 * 基準桁を扱うための定数
 */
export const BASE_DIGIT_MILLION = 1000000;
export const BASE_DIGIT_THOUSAND = 1000;
export type BaseDigit = typeof BASE_DIGIT_MILLION | typeof BASE_DIGIT_THOUSAND;

/**
 * baseDigit を基準桁とした値を、1の位を基準桁とした値に変換する
 * @param amount
 * @param baseDigit
 * @returns
 */
export const convertBaseDigitToOnce = (
  amount: Amounts | null,
  baseDigit: BaseDigit
): Amounts | null => {
  if (!amount) {
    return null;
  }
  return {
    ...amount,
    integralAmount: amount.integralAmount
      ? Long.fromValue(amount.integralAmount).multiply(baseDigit)
      : amount.integralAmount,
  };
};
/**
 * 1の位を基準桁とした値を、 baseDigit を基準桁とした値に変換する
 * @param amount
 * @param baseDigit
 * @returns
 */
export const convertBaseDigitOnceTo = (
  amount: Amounts | null,
  baseDigit: BaseDigit
): Amounts | null => {
  if (!amount) {
    return null;
  }
  return {
    ...amount,
    integralAmount: amount.integralAmount
      ? Long.fromValue(amount.integralAmount!).divide(baseDigit)
      : amount.integralAmount,
  };
};

/**
 * Long 型を Amount 互換の形式に変換する
 */
export const convertLongToAmount = (
  value: Long.Long | null | undefined
): Amounts | null => {
  if (!value) {
    return null;
  }
  return {
    integralAmount: Long.fromValue(value),
  };
};

/**
 * Amount 型を Long に変換する。
 * Long は整数部しか表現できないため、小数点以下は切り捨てられる。
 */
export const convertAmountToLong = (
  value: Amounts | null | undefined
): Long.Long | null => {
  if (!value?.integralAmount) {
    return null;
  }
  return Long.fromValue(value.integralAmount);
};
