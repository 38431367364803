import './LoadingOverlay.css';

/**
 * ローディングオーバレイの実体
 */
// See https://digipress.info/tech/css-spinner-animation-demo/
export const LoadingOverlay = () => {
  return (
    <div id="loading-icon-back-layer">
      <div className="spinner type1">
        <span>Loading...</span>
      </div>
    </div>
  );
};
