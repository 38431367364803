import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { GetMessage, error } from '~/shared/components';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import { getExceptionMessage } from '~/shared/utils';
import { DataFilterboxItem, Textbox } from '../../ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './ForumThreadForm.css';
import { ForumTitleMaxLength } from './utils/util';

interface ForumThreadFormProps {
  onDecision?: (thread: mtechnavi.api.forum.IThread) => void;
  onCancel?: () => void;
}

/**
 * スレッド追加フォームコンポーネント
 */
export const ForumThreadForm = ({
  onDecision,
  onCancel,
}: ForumThreadFormProps) => {
  const [displayName, setDisplayName] = useState('');
  // 下方にあるコメントアウト箇所で使用。一時的にlint除外2024/04/16
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedShareScopeCode, setSelectedShareScopeCode] = useState('B01');
  const [isWorking, setWorking] = useState(false);
  const intl = useIntl();

  const shareScopeList = useMemo(
    () => window.App.services.ui.getNameOption('A0000038'),
    []
  );
  // 下方にあるコメントアウト箇所で使用。一時的にlint除外2024/04/16
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shareScopeItems: DataFilterboxItem[] = useMemo(() => {
    return [
      ...shareScopeList
        // TODO: "発注元のみ" は後で調整する
        .filter(
          (item) => item.systemName === 'B01' || item.systemName === 'B02'
        )
        .map((item) => ({
          value: item.systemName || '',
          displayName:
            (item.displayNameLang && item.displayNameLang[intl.locale]) || '',
        })),
    ];
  }, [intl, shareScopeList]);

  const handleCancelThread = () => {
    onCancel && onCancel();
  };

  const handleRegisterThread = async () => {
    if (!onDecision) {
      return;
    }

    const shareScope = shareScopeList.find(
      (item) => item.systemName === selectedShareScopeCode
    );
    if (!shareScope) {
      return;
    }

    try {
      setWorking(true);
      await onDecision({
        displayName,
        shareScope,
      });
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  return (
    <div className="ForumThreadForm">
      <Textbox
        type="text"
        name="displayName"
        labelId="thread.displayName"
        onChangeState={(v) => {
          setDisplayName(v);
        }}
        validateOption={{ required: true, maxLength: ForumTitleMaxLength }}
        columns={['displayName']}
        properties={[]}
        disabled={isWorking}
      />
      {/* TODO: 指図案件追加対応時に復帰 */}
      {/* <DataFilterbox
        name="shareScope"
        labelId="thread.shareScope"
        columns={['shareScope']}
        multiple={false}
        data={shareScopeItems}
        validateOption={{ required: true }}
        onChangeState={(v) => {
          setSelectedShareScopeCode(v.length > 0 ? v[0].value : '');
        }}
        disabled={isWorking}
      /> */}
      <div className="actions">
        <CaptionButton
          buttonType="cancel"
          name="cancel"
          caption={GetMessage({ id: 'cancel' })}
          onClick={handleCancelThread}
          disabled={isWorking}
        />
        <CaptionButton
          buttonType="basic"
          name="send"
          caption={GetMessage({ id: 'decision' })}
          onClick={handleRegisterThread}
          disabled={isWorking || !displayName}
        />
      </div>
    </div>
  );
};
