import {
  Children,
  createElement,
  FunctionComponent,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { SectionStateProps } from './types';

import './SectionView.css';
import {
  useTaskListLoading,
  useTaskListLoadingDispatch,
} from './TaskListLoadingProvider';

const isTaskListItemElement = (
  element: unknown
): element is ReactElement<
  PropsWithChildren<SectionStateProps>,
  FunctionComponent<{ name: string } | SectionStateProps>
> => {
  return (
    typeof element === 'object' &&
    isValidElement<PropsWithChildren<SectionStateProps>>(element)
  );
};

export interface SectionViewProps {}

export const SectionView = (
  props: PropsWithChildren<SectionViewProps>
): JSX.Element => {
  const { children } = props;
  return <div className="SectionView">{children}</div>;
};

interface RenderSectionViewProps {
  trigger: number;
  onChangeSectionTaskCount: (name: string, total: number) => void;
}

export const RenderSectionView = (
  props: PropsWithChildren<RenderSectionViewProps>
): JSX.Element => {
  const { children, trigger, onChangeSectionTaskCount } = props;

  const { isLoading } = useTaskListLoading();
  const taskListLoadingDispatch = useTaskListLoadingDispatch();

  const handleChangeLoading = useCallback(
    (sectionName: string, isChildLoading: boolean) => {
      if (isChildLoading) {
        taskListLoadingDispatch({ type: 'loadingSection', sectionName });
      } else {
        taskListLoadingDispatch({ type: 'completeSection', sectionName });
      }
    },
    [taskListLoadingDispatch]
  );

  const renderSectionViewChildren: ReactNode[] = [];
  Children.forEach(children, (element, idx) => {
    if (!element) {
      return;
    }
    if (isTaskListItemElement(element)) {
      const onChangeTaskCount = useCallback(
        (v: number) => {
          onChangeSectionTaskCount(element.type.name, v);
        },
        [element.type.name]
      );
      const onChangeLoadingState = (isLoading: boolean) => {
        handleChangeLoading(element.type.name, isLoading);
      };
      const sectionViewChild = createElement(
        element.type,
        {
          ...element.props,
          key: idx,
          trigger: trigger,
          onChangeTaskCount: onChangeTaskCount,
          onChangeLoadingState,
        },
        element.props.children
      );
      renderSectionViewChildren.push(sectionViewChild);
    }
  });
  return (
    <div className="SectionView">
      {renderSectionViewChildren}
      {isLoading && <span className="loading">loading…</span>}
    </div>
  );
};
