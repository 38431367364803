import React, { useState } from 'react';
import { Container } from '~/shared/components';
import {
  CommentableViewer,
  CommentResourceId,
} from '~/shared/components/ui/Viewer/CommentableViewer';

export function FileComment() {
  const [idList, setIdList] = useState<CommentResourceId[]>([]);
  const [resourceId, setResourceId] = useState<string>(
    '626a612c-262f-4183-9700-b4ba7c269532'
  );
  const [resourceType, setResourceType] = useState<string>(
    'mtechnavi.api.estimation.EstimateRequest'
  );

  const handleList = () => {
    (async () => {
      const res = await window.App.services.assetInventory.listAssets({});
      const resourceList = res.items.map(
        (item) => [resourceId, [item.assetId]] as CommentResourceId
      );
      setIdList(resourceList);
    })();
  };

  return (
    <Container>
      <div>
        <div>
          <span>リソースタイプ：</span>
          <input
            style={{ width: '50%' }}
            onChange={(e) => setResourceType(e.target.value)}
            value={resourceType}
          />
        </div>
        <div>
          <span>リソースID：</span>
          <input
            style={{ width: '50%' }}
            onChange={(e) => setResourceId(e.target.value)}
            value={resourceId}
          />
        </div>
        <button type="button" onClick={handleList}>
          アセット全取得 ( リソース関係なく全取得します )
        </button>
      </div>
      <hr />
      <CommentableViewer resourceIdList={idList} resourceType={resourceType} />
      <hr />
    </Container>
  );
}
