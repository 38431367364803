import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { DateSuggest, Textarea } from '~/shared/components/ui';
import { validateFreeDate } from '~/shared/components/parts/validator';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { ReactComponent as ArrowIcon } from '@material-design-icons/svg//filled/arrow_right_alt.svg';
import './base.css';
import { includeInputValidateError } from '~/shared/utils';

export interface DeadlineChangeApprovalDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
}

export interface DeadlineChangeApprovalDialogOutputOption {
  currentDeadline: Date | null;
  requestDeadline: Date;
  requestComment: string;
}

export interface DeadlineChangeApprovalResult {
  deadline: Date | null;
  comment: string;
}

export interface DeadlineChangeApprovalDialogProps {
  isOpen: boolean;
  messageOption: DeadlineChangeApprovalDialogMessageOption;
  outputOption: DeadlineChangeApprovalDialogOutputOption;
  onDecision: (result: DeadlineChangeApprovalResult) => void;
  onCancel: () => void;
}

export const DeadlineChangeApprovalDialog = ({
  isOpen,
  messageOption,
  outputOption,
  onDecision,
  onCancel,
}: DeadlineChangeApprovalDialogProps) => {
  const intl = useIntl();
  const [deadline, setDeadline] = useState<Date | null>(null);
  const [comment, setComment] = useState<string>('');
  const requiredDeadlineArea = useRef(null);

  const handleCancel = () => {
    onCancel();
  };

  const handleDecision = (result: DeadlineChangeApprovalResult) => {
    if (isInputError()) {
      return;
    }
    onDecision(result);
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector(
      '.deadline-change-approval-dialog'
    );
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: deadline?.toDateString() ?? '',
          ref: requiredDeadlineArea,
        },
      ])
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isOpen) {
      setDeadline(outputOption.requestDeadline);
      setComment('');
    } else {
      setDeadline(null);
      setComment('');
    }
  }, [isOpen, outputOption.requestDeadline]);

  const elements = (
    <div className="deadline-change-approval-dialog">
      <div className="description-area">
        <p>
          {messageOption.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <p className="requested-deadline">
            {GetMessageWithIntl(intl, {
              id: 'requested_deadline',
              prefixId: 'DeadlineChangeApprovalDialog',
            })}
            : {dayjs(outputOption.requestDeadline).format('YYYY/MM/DD')}
          </p>
          <div className="input-line">
            <div className="item-group-100 value-compare-group">
              <div className="input">
                <DateSuggest
                  labelId="DeadlineChangeDialog.current_deadline"
                  name="current_deadline"
                  columns={['current_deadline']}
                  value={outputOption.currentDeadline}
                  disabled={true}
                ></DateSuggest>
              </div>
              <ArrowIcon className="action-icon" />
              <div className="input" ref={requiredDeadlineArea}>
                <DateSuggest
                  labelId="DeadlineChangeDialog.deadline"
                  name="deadline"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: true }}
                  onChangeState={setDeadline}
                  value={outputOption.requestDeadline}
                  columns={['deadline']}
                ></DateSuggest>
              </div>
            </div>
          </div>

          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="requestComment"
                  labelId="DeadlineChangeApprovalDialog.requestComment"
                  columns={['requestComment']}
                  className="w-100 mh-normal"
                  value={outputOption.requestComment}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="comment"
                  labelId="comment"
                  columns={['comment']}
                  className="w-100 mh-normal"
                  onChangeState={setComment}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="high"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'send' })}
            onClick={() => handleDecision({ deadline, comment })}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
