import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { SimpleListView, Textarea, Image } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  ViewId,
  getDateFormat,
  getDateFormatWithTimezone,
  AttachmentItems,
  autoBulkDownload,
  autoDownloadFileOnlyName,
} from '~/shared/utils';

interface AttachmentViewType {
  assetId: string;
  filename: string;
  remarks: string;
}

interface EstimateRequestTabItemProps {
  viewId: ViewId;
  estimateResult?: mtechnavi.api.estimation.IEstimateResult;
  estimateResultDetails?: mtechnavi.api.estimation.IEstimateResultDetail[];
  onChangeLoadingState?: (arg: boolean) => void;
  handleReload?: () => void;
}

export const EstimateRequestTabItem = ({
  viewId,
  estimateResult,
}: EstimateRequestTabItemProps) => {
  const intl = useIntl();

  const farmatAttachmentViewListData = () => {
    const viewList: AttachmentViewType[] = [];
    estimateResult?.estimateRequest?.attachments?.map((v) => {
      viewList.push({
        assetId: v.assetId ?? '',
        filename: v.filename ?? '',
        remarks: v.remarks ?? '',
      });
    });
    return viewList;
  };

  const formatContactuser = () => {
    const contactInfoList: string[] = [];
    estimateResult?.estimateRequestUnit?.contactUsers?.map((user) => {
      contactInfoList.push(`${user?.displayName}(${user?.email})`);
    });
    return contactInfoList.join(',');
  };
  return (
    <>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-100">
            {/* TO */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.to',
                prefixId: viewId,
              })}
            </span>
            {formatContactuser()}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            {/* 管理番号 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.trackingCode',
                prefixId: viewId,
              })}
            </span>
            {estimateResult?.estimateRequest?.trackingCode ?? ''}
          </div>
          <div className="w-33">
            {/* 依頼日 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.sendedAt',
                prefixId: viewId,
              })}
            </span>
            {getDateFormat(
              estimateResult?.estimateRequest?.sendedAt ?? '',
              'YYYY/MM/DD'
            )}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            {/* 発注予定 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.orderPlan',
                prefixId: viewId,
              })}
              {estimateResult?.estimateRequest?.orderPlanDt?.accuracy
                ? `${getDateFormatWithTimezone(
                    estimateResult.estimateRequest?.orderPlanDt
                  )} ${
                    estimateResult?.estimateRequest?.orderPlanRemarks
                      ? '(' +
                        estimateResult?.estimateRequest?.orderPlanRemarks +
                        ')'
                      : ''
                  }`
                : estimateResult?.estimateRequest?.orderPlanRemarks ?? ''}
            </span>
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            {/* 要求元 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.requester',
                prefixId: viewId,
              })}
            </span>
            {estimateResult?.estimateRequest?.requesterDepartment ?? ''}
            &nbsp;
            {estimateResult?.estimateRequest?.requesterResponsible ?? ''}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            {/* 優先事項 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.topPriorityContent',
                prefixId: viewId,
              })}
            </span>
            {estimateResult?.estimateRequest?.topPriorityContent
              ?.displayNameLang?.ja ?? ''}
          </div>
          <div className="w-33">
            {/* 発注目的 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.purpose',
                prefixId: viewId,
              })}
            </span>
            {estimateResult?.estimateRequest?.purpose?.displayNameLang?.ja ??
              ''}
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">
            {/* お知らせ事項 */}
            <Textarea
              name="notice"
              value={estimateResult?.estimateRequest?.notice ?? ''}
              labelId={`${viewId}.estimateRequest.notice`}
              columns={['notice']}
              className="w-100 mh-middle"
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            {/* 納入場所 */}
            <span className="label">
              {GetMessageWithIntl(intl, {
                id: 'estimateRequest.deliveryPlace',
                prefixId: viewId,
              })}
            </span>
            {estimateResult?.estimateRequest?.deliveryPlace ?? ''}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-40">
            <div>
              {/* 納入先電話番号 */}
              <span className="label">
                {GetMessageWithIntl(intl, {
                  id: 'estimateRequest.deliveryPhoneNumber',
                  prefixId: viewId,
                })}
              </span>
              {estimateResult?.estimateRequest?.deliveryPhoneNumber ?? ''}
            </div>
          </div>
          <div className="w-33">
            {/* 納入先の地図（画像表示） */}
            <div className="map-upload">
              <Image
                name="deliveryPlaceImage"
                src={{
                  assetId:
                    estimateResult?.estimateRequest?.deliveryImage?.assetId ??
                    estimateResult?.estimateRequest?.deliveryAttachment
                      ?.assetId ??
                    '',
                }}
                columns={['deliveryPlaceImage']}
                labelId={{
                  id: 'estimateRequest.deliveryImage',
                  prefixId: viewId,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="input-line mb-2">
        <div className="item-group-100">
          <div className="w-66">
            <SimpleListView
              data={farmatAttachmentViewListData()}
              actionOptions={{
                onRowClick: (item: AttachmentItems) => {
                  autoDownloadFileOnlyName(
                    item.filename ?? '',
                    item.assetId ?? ''
                  );
                },
                onFullDownLoad: () => {
                  autoBulkDownload(
                    (farmatAttachmentViewListData() ?? []).map(
                      (item) => item.assetId ?? ''
                    ),
                    intl,
                    viewId
                  );
                },
              }}
              viewOptions={{
                columns: [
                  {
                    propertyName: 'filename',
                    header: {
                      id: 'attachmentSimpleViewList.filename',
                      prefixId: viewId,
                    },
                  },
                  {
                    propertyName: 'remarks',
                    header: {
                      id: 'attachmentSimpleViewList.remarks',
                      prefixId: viewId,
                    },
                    readonly: true,
                  },
                ],
                previewRowCount: 5,
                keyColumn: 'assetId',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
