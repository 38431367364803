/**
 * 履歴データ関連
 */

import Long from 'long';
import { IntlShape } from 'react-intl';
import { GetMessageWithIntl, error } from '~/shared/components';
import {
  getDateFormat,
  FullMethodName_ListHistorys,
  FullMethodName_ListWorkTaskHistorys,
  FullMethodName_ListBusinessUnitManagementHistorys,
  FullMethodName_ListEstimationHistorys,
  getExceptionMessage,
} from '~/shared/utils';
import { FilterRequest } from '~/worker';

export interface HistoryItems {
  history_id: string;
  slip_category: string;
  occurred_at: string;
  content: String;
  level?: Long.Long;
}

interface IHistory {
  historyId?: string | null;
  historyCategoryName?: string | null;
  contentName?: string | null;
  contentParamaters?: string[] | null;
  occurredAt?: Long | null;
  level?: Long | null;
}

export type typeName =
  | 'mtechnavi.api.survey.SurveyBaseRequest'
  | 'mtechnavi.api.survey.SurveyRequest'
  | 'mtechnavi.api.survey.SurveyReception'
  | 'mtechnavi.api.worktask.WorkTask'
  | 'mtechnavi.api.company.BusinessUnitManagement'
  | 'mtechnavi.api.company.BusinessUnitContact'
  | 'mtechnavi.api.estimation.EstimateResult'
  | 'mtechnavi.api.estimation.EstimateManagement'
  | '';

/*
 履歴データ取得処理
 */
export async function getHistoryItems(
  intl: IntlShape,
  typeName: typeName,
  recordIds: string[]
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let fullMethodName: any;
    switch (typeName) {
      case 'mtechnavi.api.survey.SurveyBaseRequest':
      case 'mtechnavi.api.survey.SurveyRequest':
      case 'mtechnavi.api.survey.SurveyReception':
        fullMethodName = FullMethodName_ListHistorys;
        break;
      case 'mtechnavi.api.worktask.WorkTask':
        fullMethodName = FullMethodName_ListWorkTaskHistorys;
        break;
      case 'mtechnavi.api.company.BusinessUnitManagement':
      case 'mtechnavi.api.company.BusinessUnitContact':
        fullMethodName = FullMethodName_ListBusinessUnitManagementHistorys;
        break;
      case 'mtechnavi.api.estimation.EstimateManagement':
      case 'mtechnavi.api.estimation.EstimateResult':
        fullMethodName = FullMethodName_ListEstimationHistorys;
        break;
      default:
        return [];
    }

    const option: FilterRequest = {
      action: 'reload',
      fullMethodName: fullMethodName,
      filter: {},
      requestBody: {
        typeName: typeName,
        recordIds: recordIds,
      },
      sort: [{ occurredAt: 'desc' }],
    };
    const response = (await window.App.services.ui.worker.filter(option))
      .items as IHistory[];

    const datas: HistoryItems[] = (response ?? []).map((item) => {
      type ParamValues = { [key: string]: string };
      const paramValues: ParamValues = {};
      if (item.contentParamaters && item.contentParamaters?.length > 0) {
        for (let i = 0; i < (item.contentParamaters ?? []).length; i++) {
          paramValues[`_${i + 1}`] = item.contentParamaters[i];
        }
      }

      const result: HistoryItems = {
        history_id: item.historyId ?? '',
        slip_category: GetMessageWithIntl(intl, {
          id: item.historyCategoryName ?? '',
        }),
        occurred_at: getDateFormat(item.occurredAt ?? '0', 'YYYY/MM/DD HH:mm'),
        content: GetMessageWithIntl(intl, {
          id: item.contentName ?? '',
          value: paramValues,
        }),
        level: item.level ?? Long.fromValue(0),
      };
      return result;
    });
    return datas;
  } catch (err) {
    error(getExceptionMessage(intl, err));
    throw err;
  }
}

/*
 通知エリア表示判定
 */
export function isShowNotificationArea(historys: HistoryItems[]): boolean {
  return (
    historys &&
    historys.length > 0 &&
    Long.fromValue(historys[0].level ?? Long.fromValue(0)).toNumber() > 0
  );
}
