import { useState } from 'react';
import {
  Schema,
  getAltDisplaySchema,
  getCommaTypeNumberSchema,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  InitialFilterOption,
  ConnectingDialog,
} from '~/shared/components/ui/Dialog/ConnectingDialog';
import { SurveyRequestRegistrationDialog } from '~/shared/components/ui/Dialog/SurveyRequestRegistrationDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  FullMethodName_ListSurveyBaseRequestContents,
  FullMethodName_ListSurveyRequestContents,
} from '~/shared/utils';
import { AggregateStage } from '~/worker';

export const UISampleSurveyRequestRegistrationDialogs = () => {
  const [isShowDialog, setShowDialog] = useState(false);
  const [surveyResult, setSurveyResult] = useState<string[]>([]);
  const [isShowConnectDialog, setShowConnectDialog] = useState(false);
  const myEmail = useAuth().user?.email ?? '';

  const aggregateStages: AggregateStage[] = [
    {
      // 未承認一覧のフィルタ用に、予定データへ担当者マスタを紐づける
      $lookup: {
        from: FullMethodName_ListSurveyBaseRequestContents,
        localField: 'surveyRequest.surveyBaseRequestId',
        foreignField: 'surveyBaseRequest.surveyBaseRequestId',
        as: 'mergedContents',
        option: {
          useQuery: false,
        },
      },
    },
  ];

  const getFormmaterSchema = (schema: Schema): Schema => {
    const jaColumn: string[] = ['surveyRequest.status.displayName'];
    const jaSch = getAltDisplaySchema(schema, jaColumn, 'ja');
    const commaSch = getCommaTypeNumberSchema(jaSch, [
      'mergedContents.totalRequestUnit',
    ]);
    return commaSch;
  };

  const initialfilterItems: InitialFilterOption = {
    info: [
      {
        targetKey: 'surveyRequest.createdProperties.createdBy.email',
        targetValue: myEmail,
      },
    ],
  };

  return (
    <div className="categories">
      <h3 id="dialog">調査依頼追加ダイアログ（調査依頼紐付けダイアログ）</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowDialog(true);
              }}
            />
            <SurveyRequestRegistrationDialog
              isOpen={isShowDialog}
              surveyCount={0}
              onDecision={(v) => {
                if (v === 'newSurvey') {
                  setShowDialog(false);
                  setSurveyResult(['新規登録画面を開きます']);
                } else {
                  setShowDialog(false);
                  setShowConnectDialog(true);
                }
              }}
              onCancel={() => {
                setShowDialog(false);
              }}
            />
            <div className="ConnectingDialog">
              <ConnectingDialog
                FullMethodNameForExtendListView={
                  FullMethodName_ListSurveyRequestContents
                }
                FullMethodNames={[
                  FullMethodName_ListSurveyRequestContents,
                  FullMethodName_ListSurveyBaseRequestContents,
                ]}
                aggregateStagesOption={{
                  aggregateStages: aggregateStages,
                  mergedShema: true,
                }}
                initialFilterOption={initialfilterItems}
                keyId={'surveyRequest.surveyRequestId'}
                messageOption={{
                  headerOption: {
                    id: 'disasterSurveySearch',
                    prefixId: 'DIALOG_TITLE',
                  },
                }}
                isOpen={isShowConnectDialog}
                onDecision={(result: string[]) => {
                  setSurveyResult(result);
                  setShowConnectDialog(false);
                }}
                onCancel={() => {
                  setSurveyResult([]);
                  setShowConnectDialog(false);
                }}
                viewId={'DISASTER_SURVEY_SEARCH'}
                formatSchemaInfo={(schema: Schema) =>
                  getFormmaterSchema(schema)
                }
              />
            </div>
          </div>
          <div className="w-66">
            <pre className="output">
              {surveyResult && JSON.stringify(surveyResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
