import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { ViewId } from '~/shared/utils';
import {
  BusinessUnitAttributes,
  convertToCategorizedList,
} from '../../converter';
import {
  CategorizedList,
  CategorizedBranchData,
  CategorizedTipData,
  isCategorizedBranchList,
} from '~/shared/components/ui/CategorizedCheckList';

const convertCompactSkillInfo = (
  intl: IntlShape,
  list: CategorizedList,
  ids: string[]
): CompactSkillInfo[] => {
  return list.flatMap((item) => {
    const results = convertCompactSkillChild(intl, item.children || [], ids);
    if (Array.isArray(results)) {
      return results.map((result) => ({
        ...result,
        rootCategoryName: item?.displayNameLang
          ? item?.displayNameLang[intl.locale]
          : '',
      }));
    } else {
      return {
        ...results,
        rootCategoryName: item?.displayNameLang
          ? item?.displayNameLang[intl.locale]
          : '',
      };
    }
  });
};
const convertCompactSkillChild = (
  intl: IntlShape,
  list: CategorizedBranchData[] | CategorizedTipData[],
  ids: string[]
): CompactSkillInfo[] | CompactSkillInfo => {
  if (isCategorizedBranchList(list)) {
    return list.flatMap((item) => {
      const results = convertCompactSkillChild(intl, item.children || [], ids);
      if (Array.isArray(results)) {
        return results.map((result) => ({
          ...result,
          childCategoryName: `${
            item?.displayNameLang ? item?.displayNameLang[intl.locale] : ''
          } > ${result.childCategoryName}`,
        }));
      } else {
        return {
          ...results,
          childCategoryName: item?.displayNameLang
            ? item?.displayNameLang[intl.locale]
            : '',
        };
      }
    });
  } else {
    return {
      skills: list
        .filter((item) => ids.some((v) => v === item.CategorizedItemId))
        .map((item) => {
          return item?.displayNameLang
            ? item?.displayNameLang[intl.locale]
            : '';
        })
        .join('、'),
    };
  }
};

interface CompactSkillInfo {
  rootCategoryName?: string;
  childCategoryName?: string;
  skills?: string;
}

interface CompanyConfirmationSkillProps {
  viewId: ViewId;
  skills?: mtechnavi.api.pocsearchcompany.ISkill[];
  skillTree?: BusinessUnitAttributes;
}
export const CompanyConfirmationSkill = ({
  skills,
  skillTree,
}: CompanyConfirmationSkillProps) => {
  const intl = useIntl();

  const skillData = useMemo(() => {
    const categorizedList: CategorizedList = [];
    const categorizedItem = convertToCategorizedList(skillTree?.children);
    categorizedList.push(...categorizedItem);
    return convertCompactSkillInfo(
      intl,
      categorizedList,
      skills?.at(0)?.skillItemIds ?? []
    );
  }, [intl, skills, skillTree]);

  return (
    <>
      <ul className="skill-list">
        {skillData
          .filter((v) => v.skills)
          .map((item, index) => (
            <li key={index}>
              <div className="root-category">
                {index === 0
                  ? item.rootCategoryName
                  : skillData.at(index - 1)?.rootCategoryName ===
                    item.rootCategoryName
                  ? ''
                  : item.rootCategoryName}
              </div>
              <div className="child-category">{item.childCategoryName}</div>
              <div className="skills">{item.skills}</div>
            </li>
          ))}
      </ul>
    </>
  );
};
