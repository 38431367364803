import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ConfirmationDialog,
  ListView,
  ViewMenu,
  ImportDialogHandleFormat,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
  getJoinedAndAltColumnSchema,
  getJoinedColumnSchema,
} from '~/shared/components/ui';
import {
  DataViewType,
  SelectPlanDialog,
} from '~/shared/components/ui/Dialog/SelectPlanDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import {
  FullMethodName_ListEstimateRequestPlans,
  PageState,
  ViewId,
  getDateFormatWithTimezone,
  getExceptionMessage,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_PLAN_LIST';
type EstimateRequestPlan = mtechnavi.api.estimation.EstimateRequestPlan;
export function EstEstimateRequestPlanList() {
  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [presetViewId, setPresetViewId] = useState<ViewId>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();
  const [isReload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const listItemRef = useRef<EstimateRequestPlan[]>([]);
  // 操作対象ID
  const [targetIds, setTargetIds] = useState<string[] | null>(null);

  // ダイアログ
  const [isShowDelete, setShowDelete] = useState(false);
  const [isShowComplete, setShowComplete] = useState(false);
  const [isShowUncomplete, setShowUncomplete] = useState(false);
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);
  const [isSelectPlandialogOpen, setSelectPlanDialogOpen] = useState(false);
  const [selectPlanDialogData, setSelectPlanDialogData] = useState<
    DataViewType[]
  >([]);

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });
  const invalidStatusMessage = GetMessageWithIntl(intl, { id: 'E0000130' });

  const headerLabelId: MessageProps = {
    prefixId: 'DIALOG_TITLE',
    id: 'ESTIMATE_PLAN_IMPORT',
  };

  const handleExport: ImportDialogHandleFormat = {
    name: 'estimateRequestPlans',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'estimateRequestPlans',
    headerColumns: [],
  };

  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      presetItems?.find((v) => v.name === 'EstEstimateRequestPlanList')
        ?.property ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
  }, [presetItems]);

  const getRecordData = (
    ids: string[] | null
  ): EstimateRequestPlan[] | undefined => {
    return listItemRef?.current.filter((item) =>
      ids?.includes(item.estimateRequestPlanId)
    );
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        handleMoveToConfirmation(v);
      },
    });
    // アップロード
    menuItems.push({
      name: 'upload',
      func: () => {
        setImportDialogOpen(true);
      },
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        if (v && v.length > 1) {
          error([excessTargetMessage]);
          return;
        }
        if (v) {
          setTargetIds(v);
        }
        setShowDelete(true);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        handleMoveToConfirmation(v);
      },
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 依頼登録
    menuItems.push({
      name: 'estimateRequestInputAdd',
      func: (v?: string[]) => {
        if (!v || v.length === 0) {
          error([unselectedMessage]);
          return;
        }
        const targets = getRecordData(v);
        if (targets?.some((item) => item.status?.code === '1')) {
          error([invalidStatusMessage]);
          return;
        }
        const tmp: DataViewType[] = [];
        targets?.map((item) => {
          const obj: DataViewType = {
            id: item.estimateRequestPlanId,
            trackingCode: item.sapWorkOrder?.trackingCode ?? '',
            desiredReceiveDt: getDateFormatWithTimezone(
              item.sapWorkOrder?.desiredReceiveDt
            ),
            displayName: item.displayNameLang?.ja ?? '',
            modelNumber: item.sapWorkOrder?.freeItem ?? '',
            quantityNumber:
              (item.sapWorkOrder?.quantity?.amount ?? '') +
                (item.sapWorkOrder?.quantity?.unit ?? '') ?? '',
          };
          tmp.push(obj);
        });
        setSelectPlanDialogData(tmp);
        setSelectPlanDialogOpen(true);
      },
    });
    // 完了
    menuItems.push({
      name: 'complete',
      func: (v?: string[]) => {
        if (v) {
          setTargetIds(v);
        }
        setShowComplete(true);
      },
    });
    // 未完了
    menuItems.push({
      name: 'uncomplete',
      func: (v?: string[]) => {
        if (v) {
          setTargetIds(v);
        }
        setShowUncomplete(true);
      },
    });
    return menuItems;
  };

  const handleMoveToConfirmation = (prop?: string[]) => {
    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navigate('/estimate/est-estimate-request-plan-confirmation', { state });
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        // preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListEstimateRequestPlans,
          ]);

        // ja表示のみする処理
        const jaColumn: string[] = [
          'status.displayNameLang',
          'displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const accuracyDateColumn = [
          'sapWorkOrder.desiredReceiveDt',
          'sapWorkOrder.receiverProperties.receivedDt',
        ];
        const accuracyDateSch = getDayFormetterDisplaySchema(
          formatterSch,
          accuracyDateColumn,
          {
            dayOpts: {
              formatType: 'YYYY/MM/DD',
              isAccuracy: true,
            },
          }
        );
        const joinedRequesterPropertiesSch = getJoinedAndAltColumnSchema(
          accuracyDateSch,
          [
            'sapWorkOrder.requesterProperties.organizationUnit.displayNameLang',
            'sapWorkOrder.requesterProperties.staff.displayNameLang',
          ],
          [
            `sapWorkOrder.requesterProperties.organizationUnit.displayNameLang.${intl.locale}`,
            `sapWorkOrder.requesterProperties.staff.displayNameLang.${intl.locale}`,
          ],
          ' ',
          true
        );
        const joinedAmountSch = getJoinedColumnSchema(
          joinedRequesterPropertiesSch,
          ['sapWorkOrder.quantity.amount', 'sapWorkOrder.quantity.unit'],
          ''
        );

        // 取得した情報をセット
        setPresetItems(childrenPresetItem);
        setSchema(joinedAmountSch);
        setPreset(preset);
        setPresetViewId(VIEW_ID);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [intl]);

  const handleDelete = async () => {
    const target = getRecordData(targetIds);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteEstimateRequestPlan',
        request: target[0],
      });

      setShowDelete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleComplete = async () => {
    const targets = getRecordData(targetIds);
    if (!targets) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeEstimateRequestPlan',
        request: targets,
      });

      setShowComplete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleUncomplete = async () => {
    const targets = getRecordData(targetIds);
    if (!targets) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'uncompleteEstimateRequestPlan',
        request: targets,
      });

      setShowUncomplete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstimateManagementSummaryList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListEstimateRequestPlans}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: 'estimateRequestPlanId',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current = items as EstimateRequestPlan[]),
            }}
            isSuspendReload={VIEW_ID !== presetViewId}
            importDialogOption={{
              isDisplay: true,
              isDialogOpen: isImportDialogOpen,
              dialogPreset: dialogPreset,
              isFileTypeSelectBox: true,
              userMasterCategoryName: 'A0000026',
              headerLabelId: headerLabelId,
              handleExport: handleExport,
              handleImport: handleImport,
              onChangeState: (v) => {
                setImportDialogOpen(v);
              },
            }}
          />

          <ConfirmationDialog
            isOpen={isShowDelete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'delete',
                }),
              },
            }}
            onDecision={handleDelete}
            onCancel={() => setShowDelete(false)}
          />
          <ConfirmationDialog
            isOpen={isShowComplete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'complete',
                }),
              },
            }}
            onDecision={handleComplete}
            onCancel={() => setShowComplete(false)}
          />
          <ConfirmationDialog
            isOpen={isShowUncomplete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'uncomplete',
                }),
              },
            }}
            onDecision={handleUncomplete}
            onCancel={() => setShowUncomplete(false)}
          />
          <SelectPlanDialog
            isOpen={isSelectPlandialogOpen}
            data={selectPlanDialogData}
            onDecision={(v: string) => {
              if (!v) {
                error([unselectedMessage]);
                return;
              }

              const otherIds = selectPlanDialogData
                .filter((item) => item.id !== v)
                .map((w) => w.id);

              const ids = [v, ...otherIds];

              const state: PageState = {
                sourceViewId: VIEW_ID,
                actionType: 'add', // 予定選択ダイアログからの登録
                ids: ids,
                baseViewOption: { sourceViewId: VIEW_ID },
              };
              navigate('/estimate/est-estimate-request-input', { state });
              setSelectPlanDialogOpen(false);
            }}
            onCancel={() => setSelectPlanDialogOpen(false)}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
