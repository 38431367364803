import Long from 'long';
import { Amounts } from '~/shared/utils';

const FractionalBaseDigit = 10000;

/**
 * Amount の加算。
 * ２つの Amount を合計します。型は 1 つ目の引数を引き継ぎます。
 */
export const addAmount = <T extends Amounts>(a: T, b: Amounts): T => {
  let integralAmount = a?.integralAmount?.add(b?.integralAmount || 0);
  let fractionalAmount =
    (a?.fractionalAmount || 0) + (b?.fractionalAmount || 0);
  if (fractionalAmount >= FractionalBaseDigit) {
    integralAmount = integralAmount?.add(
      Math.floor(fractionalAmount / FractionalBaseDigit)
    );
    fractionalAmount %= FractionalBaseDigit;
  }
  return { ...a, integralAmount, fractionalAmount };
};

/**
 * Amount の減算。
 * 1つ目の Amount から２つ目の Amount を引きます。型は 1 つ目の引数を引き継ぎます。
 */
export const subtractAmount = <T extends Amounts>(a: T, b: Amounts): T => {
  let integralAmount = a?.integralAmount?.sub(b?.integralAmount || 0);
  let fractionalAmount =
    (a?.fractionalAmount || 0) - (b?.fractionalAmount || 0);
  if (fractionalAmount < 0) {
    integralAmount = integralAmount?.add(
      Math.floor(fractionalAmount / FractionalBaseDigit)
    );
    fractionalAmount += FractionalBaseDigit;
  }
  return { ...a, integralAmount, fractionalAmount };
};

/**
 * Amount の乗算。
 * １つ目の Amount を multiplier 倍にします。型は 1 つ目の引数を引き継ぎます。
 * multiplier は小数値に対応していません。
 */
export const multiplyAmount = <T extends Amounts>(
  amount: T,
  multiplierParam: Amounts | Long | number | string
): T => {
  if (multiplierParam === null || multiplierParam == undefined) {
    return amount;
  }
  const multiplier = isAmounts(multiplierParam)
    ? multiplierParam?.integralAmount || 1
    : multiplierParam;
  let integralAmount = amount?.integralAmount?.multiply(multiplier);
  let fractionalAmount =
    (amount?.fractionalAmount || 0) * Long.fromValue(multiplier).toNumber();
  if (fractionalAmount >= FractionalBaseDigit) {
    integralAmount = integralAmount?.add(
      Math.floor(fractionalAmount / FractionalBaseDigit)
    );
    fractionalAmount %= FractionalBaseDigit;
  }
  return { ...amount, integralAmount, fractionalAmount };
};

/**
 * Amount の除算。
 * １つ目の Amount を divisor で割ります。型は 1 つ目の引数を引き継ぎます。
 * divisor は小数値に対応していません。
 */
export const divideAmount = <T extends Amounts>(
  amount: T,
  divisorParam: Amounts | Long | number | string,
  /**
   * 除算モード。
   * roundDown(切り捨て)以外は必要になったら実装する想定。
   * 追加時にはこのコメントを更新してください。
   */
  mode: 'roundDown'
): T => {
  if (divisorParam === null || divisorParam == undefined) {
    return amount;
  }
  switch (mode) {
    case 'roundDown': {
      const divisor = isAmounts(divisorParam)
        ? divisorParam?.integralAmount || 1
        : divisorParam;
      const integralAmount = amount?.integralAmount?.divide(divisor);
      return { ...amount, integralAmount, fractionalAmount: 0 };
    }
  }
};

/**
 * Amount の合計。
 * 引数の複数の Amount を合計します。型は 1 つ目の引数を引き継ぎます。
 */
export const totalAmounts = <T extends Amounts>(...amounts: T[]): T => {
  let integralAmount = new Long(0);
  let fractionalAmount = 0;
  amounts.forEach((amount) => {
    if (amount?.integralAmount) {
      integralAmount = integralAmount.add(
        Long.fromValue(amount.integralAmount)
      );
    }
    if (amount?.fractionalAmount) {
      fractionalAmount += amount.fractionalAmount;
    }
  });
  if (fractionalAmount >= FractionalBaseDigit) {
    integralAmount = integralAmount?.add(
      Math.floor(fractionalAmount / FractionalBaseDigit)
    );
    fractionalAmount %= FractionalBaseDigit;
  }
  return { ...(amounts.at(0) || ({} as T)), integralAmount, fractionalAmount };
};

export const isAmounts = (value: unknown): value is Amounts => {
  if (value === undefined || value === null) {
    return true;
  }
  return Object.hasOwn(value as Object, 'integralAmount');
};
