import { sharelib } from '~/shared/libs/clientsdk';

// (依頼)回答期日変更可能なステータスか
export const isCanBeDueDateChangeRequest = (
  status?: sharelib.INameOption | null
) => {
  return ['B04', 'B05', 'B10'].includes(status?.systemName ?? '');
};

// 破棄可能なステータスか
export const isCanBeDiscard = (status?: sharelib.INameOption | null) => {
  return ['B04', 'B05', 'B10'].includes(status?.systemName ?? '');
};

// 編集可能なステータスか
export const isCanBeEdit = (status?: sharelib.INameOption | null) => {
  return ['B00', 'B02'].includes(status?.systemName ?? '');
};

// 通知設定可能なステータスか
export const isCanBeNotificationSetting = (
  status?: sharelib.INameOption | null
) => {
  return ['B04', 'B05', 'B06', 'B07', 'B08', 'B10'].includes(
    status?.systemName ?? ''
  );
};

// 修正依頼可能なステータスか
export const isCanBeChangeRequest = (status?: sharelib.INameOption | null) => {
  return ['B05', 'B06', 'B07'].includes(status?.systemName ?? '');
};

// 社内メモ設定可能なステータスか
export const isCanBeCompanyMemo = (status?: sharelib.INameOption | null) => {
  return ['B04', 'B05', 'B06', 'B07', 'B08', 'B10'].includes(
    status?.systemName ?? ''
  );
};

// 催促設定可能なステータスか
export const isCanBeReminderSetting = (
  status?: sharelib.INameOption | null
) => {
  return ['B04', 'B05', 'B10'].includes(status?.systemName ?? '');
};

// 回答期日変更可能なステータスか
export const isCanBeDeadlineChange = (status?: sharelib.INameOption | null) => {
  return ['B04', 'B05', 'B10'].includes(status?.systemName ?? '');
};

// 回答代行可能なステータスか
export const isCanBeReplyDelegation = (
  status?: sharelib.INameOption | null
) => {
  return ['B04', 'B05', 'B06', 'B07', 'B08', 'B10'].includes(
    status?.systemName ?? ''
  );
};

// 依頼終了可能なステータスか
export const isCanBeComplete = (status?: sharelib.INameOption | null) => {
  return ['B04', 'B05', 'B06', 'B07', 'B10'].includes(status?.systemName ?? '');
};
