import {
  FilterExpression,
  FilterTerm,
  FilterAnd,
  FilterOr,
  FilterNot,
  FilterElemMatch,
  FilterPrimitive,
  FilterOperator,
} from '~/worker';
import { Property as PresetProperty } from '~/shared/services';
import { getPresetPropertysByPropertyName } from './preset';
import { toTextboxFilterTerms } from './FilterInputItems';

export function toFilterAnd(v: FilterTerm[]): FilterAnd {
  return { $and: v };
}

export function toFilterOr(v: FilterTerm[]): FilterOr {
  return { $or: v };
}

export function toEqualFilter(
  name: string,
  value: FilterPrimitive
): FilterTerm {
  return { [name]: { $eq: value } };
}

export function toNotEqualFilter(
  name: string,
  value: FilterPrimitive
): FilterTerm {
  return { [name]: { $ne: value } };
}

export function toLikeFilter(name: string, value: FilterPrimitive): FilterTerm {
  return { [name]: { $like: value } };
}

export function toGteFilter(name: string, value: FilterPrimitive): FilterTerm {
  return { [name]: { $gte: value } };
}

export function toLteFilter(name: string, value: FilterPrimitive): FilterTerm {
  return { [name]: { $lte: value } };
}

export function toBoolFilter(name: string, value: boolean): FilterTerm {
  if (value) {
    return toEqualFilter(name, value);
  } else {
    return toNotEqualFilter(name, value);
  }
}

export function toInFilter(
  name: string,
  values: FilterPrimitive[]
): FilterTerm {
  return {
    [name]: { $in: values },
  };
}

export const toFilterElemMatchFilter = (
  name: string,
  values: FilterPrimitive[],
  optFn: (v: FilterPrimitive) => FilterOperator
): FilterTerm => {
  return {
    [name]: {
      $elemMatch: {
        $or: values.map(optFn),
      },
    },
  };
};

export function isFilterAnd(v: FilterExpression): v is FilterAnd {
  return '$and' in v && Array.isArray(v.$and);
}

export function isFilterOr(v: FilterExpression): v is FilterOr {
  return '$or' in v && Array.isArray(v.$or);
}

export function isFilterNot(v: FilterExpression): v is FilterNot {
  return '$not' in v;
}

export function isObject(v: unknown): v is Record<string, unknown> {
  return typeof v === 'object' && !Array.isArray(v);
}

export function isString(v: unknown): v is string {
  return typeof v === 'string';
}

export function isNumber(v: unknown): v is number {
  return typeof v === 'number';
}

export function isBoolean(v: unknown): v is boolean {
  return typeof v === 'boolean';
}

export function isStringArray(v: unknown[]): v is string[] {
  return v.every((s) => isString(s));
}

export function isFilterEq(v: unknown): v is { $eq: unknown } {
  return isObject(v) && '$eq' in v;
}

export function isFilterNe(v: unknown): v is { $ne: unknown } {
  return isObject(v) && '$ne' in v;
}

export function isFilterIn(v: unknown): v is { $in: Array<unknown> } {
  return isObject(v) && '$in' in v && Array.isArray(v['$in']);
}

export function isFilterLike(v: unknown): v is { $like: unknown } {
  return isObject(v) && '$like' in v;
}

export function isFilterGte(v: unknown): v is { $gte: unknown } {
  return isObject(v) && '$gte' in v;
}

export function isFilterLte(v: unknown): v is { $lte: unknown } {
  return isObject(v) && '$lte' in v;
}

export function isFilterElemMatch(v: unknown): v is FilterElemMatch {
  return isObject(v) && '$elemMatch' in v;
}

export function isFilterElemMatchOr(v: unknown): v is {
  $or: Array<FilterExpression | FilterOperator>;
} {
  return isObject(v) && '$or' in v && Array.isArray(v.$or);
}

type FilterType = FilterAnd | FilterOr;
type FilterTypeConverter = (
  v: FilterTerm[],
  propertyType?: string
) => FilterType;

const toTextboxFilter = (
  terms: FilterTerm[],
  propertyType = 'string'
): FilterType => {
  const values = terms.reduce((exprs, filterTerm) => {
    for (const [column, term] of Object.entries(filterTerm)) {
      if (isFilterLike(term) && isString(term.$like)) {
        exprs.push(
          ...toTextboxFilterTerms(column, term.$like, (v) => {
            return propertyType === 'string' ? { $like: v } : { $eq: v };
          })
        );
        return exprs;
      }
      if (isFilterEq(term) && isString(term.$eq)) {
        exprs.push(
          ...toTextboxFilterTerms(column, term.$eq, (v) => {
            return { $eq: v };
          })
        );
        return exprs;
      }
    }
    return exprs;
  }, [] as FilterTerm[]);
  return { $or: values };
};

const filterTypeConverters = new Map<string, FilterTypeConverter>([
  ['text', toTextboxFilter],
  ['filterboxMultiple', toFilterOr],
  ['apiFilterboxMultiple', toFilterOr],
  ['apiFilterboxBoolean', toFilterOr],
  ['fromtodate', toFilterAnd],
  ['fromtonumber', toFilterAnd],
]);

export const getColumnFilterTypeConverters = (
  presetProperty: PresetProperty[] | undefined
): Map<string, FilterTypeConverter> => {
  const columnFilterConverters = new Map<string, FilterTypeConverter>();
  const filterTypePropertys = getPresetPropertysByPropertyName(
    presetProperty,
    'type'
  );
  for (const p of filterTypePropertys) {
    const converter = filterTypeConverters.get(p.propertyValue);
    if (!converter) continue;
    columnFilterConverters.set(p.name, converter);
  }
  return columnFilterConverters;
};

export const flattenFilterExpression = (
  v: FilterExpression
): Array<FilterTerm> => {
  const filterTerms: Array<FilterTerm> = [];
  const flatten = (val: FilterExpression) => {
    if (isFilterAnd(val)) {
      val.$and.forEach(flatten);
      return;
    }
    if (isFilterOr(val)) {
      val.$or.forEach(flatten);
      return;
    }
    if (isFilterNot(val)) {
      flatten(val.$not);
      return;
    }
    filterTerms.push(val);
  };
  flatten(v);
  return filterTerms;
};
