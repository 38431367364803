import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '~/shared/contexts/AuthProvider';
import { PropsWithChildren } from 'react';
import { ToolMenuProvider } from '~/shared/contexts/ToolMenuProvider';
import { EstimateRoutes } from './estimate/Routes';
import { CoreRoutes } from './core/Routes';
import { DebugRoutes } from './debug/Routes';
import { SupplierBaseRoutes } from './supplierBase/Routes';
import { ThemeProvider } from '~/shared/contexts/ThemeProvider';
import { WorkTaskRoutes } from './workTask/Route';
import { FormRoutes } from './form/Routes';
import { CompanyRoutes } from './company/Route';
import { BcpRoutes } from './bcp/Routes';
import { LoadingProvider } from '~/shared/contexts/LoadingProvider';
import { DebugProvider } from '~/shared/contexts/DebugProvider';

export function NotenatApp() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<CoreRoutes />} />
      </Routes>
    </Router>
  );
}

export function App() {
  return (
    <GlobalProviders>
      <Router>
        <Routes>
          <Route path="/*" element={<CoreRoutes />} />
          <Route path="/estimate/*" element={<EstimateRoutes />} />
          <Route path="/supplier-base/*" element={<SupplierBaseRoutes />} />
          <Route path="/work-task/*" element={<WorkTaskRoutes />} />
          <Route path="/form/*" element={<FormRoutes />} />
          <Route path="/bcp/*" element={<BcpRoutes />} />
          <Route path="/company/*" element={<CompanyRoutes />} />
          <Route path="/debug/*" element={<DebugRoutes />} />
        </Routes>
      </Router>
    </GlobalProviders>
  );
}

/** アプリケーション全体で利用する Provider をまとめて設定する */
const GlobalProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <DebugProvider>
        <ThemeProvider>
          <LoadingProvider>
            <ToolMenuProvider>{children}</ToolMenuProvider>
          </LoadingProvider>
        </ThemeProvider>
      </DebugProvider>
    </AuthProvider>
  );
};
