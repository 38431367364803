import { Route, Routes, Navigate } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { SurveyRequestReceiverList as SurveyRequestReceiverListPage } from './pages/SurveyRequestReceiverList';
import { SurveyRequestReceiverConfirmation as SurveyRequestReceiverConfirmationPage } from './pages/SurveyRequestReceiverConfirmation';
import { SurveyRequestSenderConfirmation as SurveyRequestSenderConfirmationPage } from './pages/SurveyRequestSenderConfirmation';
import { SurveyRequestSenderList as SurveyRequestSenderListPage } from './pages/SurveyRequestSenderList';
import { SurveyRequestSenderDetailList as SurveyRequestSenderDetailListPage } from './pages/SurveyRequestSenderDetailList';
import { SurveyRequestCatalogList as SurveyRequestCatalogListPage } from './pages/SurveyRequestCatalogList';
import { SurveyRequestCatalogConfirmation as SurveyRequestCatalogConfirmationPage } from './pages/SurveyRequestCatalogConfirmation';
import { SurveyRequestSenderInput as SurveyRequestSenderInputPage } from './pages/SurveyRequestSenderInput';
import { SurveyRequestCatalogInput as SurveyRequestCatalogInputPage } from './pages/SurveyRequestCatalogInput';

export const SupplierBaseRoutes = () => {
  return (
    <Routes>
      <Route
        path="/survey-request-list"
        element={
          <RequireAuth>
            <SurveyRequestSenderListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-sender-detail-list"
        element={
          <RequireAuth>
            <SurveyRequestSenderDetailListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-catalog-list"
        element={
          <RequireAuth>
            <SurveyRequestCatalogListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-catalog-confirmation"
        element={
          <RequireAuth>
            <SurveyRequestCatalogConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-catalog-input"
        element={
          <RequireAuth>
            <SurveyRequestCatalogInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-receiver-list"
        element={
          <RequireAuth>
            <SurveyRequestReceiverListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-receiver-confirmation/*"
        element={
          <RequireAuth>
            <SurveyRequestReceiverConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-sender-input"
        element={
          <RequireAuth>
            <SurveyRequestSenderInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-sender-confirmation/*"
        element={
          <RequireAuth>
            <SurveyRequestSenderConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-approval-list"
        element={
          <RequireAuth>
            <SurveyRequestSenderListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/survey-request-sender-approval-detail-list"
        element={
          <RequireAuth>
            <SurveyRequestSenderDetailListPage />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
