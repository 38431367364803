import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GetMessage, GetMessageWithIntl } from '../../parts/Message/Message';
import { CaptionButton } from '../Button';
import { FileItem, FileUploader } from '../../file';
import { ModalDialogComponent, ModalDialogComponentProps } from '../..';
import { error } from '../../parts/Toast/Toast';

import './FormInputImportDialog.css';

export interface FormInputImportDialogProps {
  isOpen: boolean;
  onDecision: (result: FileItem[]) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export const FormInputImportDialog = (props: FormInputImportDialogProps) => {
  const intl = useIntl();
  const [excelFileItem, setExcelFileItem] = useState<FileItem[]>([]);

  //アップロード処理
  const [isUploadingContain, setUploadingContain] = useState(false);
  const [isErrorContain, setErrorContain] = useState(false);

  // ファイル添付時処理
  const handleUploaded = (fileList: FileItem[]) => {
    setUploadingContain(fileList.some((item) => item.status === 'uploading'));
    setErrorContain(fileList.some((item) => item.status === 'failure'));
    setExcelFileItem(fileList);
  };

  const handleDecision = () => {
    if (isUploadingContain || isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000103' })]);
      return;
    }
    props.onDecision(excelFileItem);
  };

  const formReset = () => {
    setExcelFileItem([]);
  };

  // フォームに初期値をセットする
  useEffect(() => {
    formReset();
  }, [props.isOpen]);

  const elements = (
    <div className="output-dialog">
      <div className="contents-box">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="file-uploader">
                <div className="fileupload-button-margin">
                  <FileUploader
                    name="fileUploader"
                    multiple={false}
                    validateOption={{ allowedFileExtensions: ['xlsx'] }}
                    onUpload={handleUploaded}
                    onChangeLoadingState={props.onChangeLoadingState}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              props.onCancel();
            }}
          />
          <CaptionButton
            name="DecisionBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({ id: 'import' })}
            onClick={() => handleDecision()}
            disabled={!excelFileItem || excelFileItem.length == 0}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'FORM_INPUT_IMPORT',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
