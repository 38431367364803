import { useState, CSSProperties, useMemo } from 'react';
import './Select.css';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage } from '../Message/Message';
import { getErrorBorderClassName } from '~/shared/utils';

export interface SelectProps {
  name: string;
  className?: string;
  items: SelectItem[];
  value?: string[] | string;
  unselectedOption?: UnselectedOption;
  validator?: (v: string[]) => string[];
  validateOption?: SelectValidateOption;
  multiple?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string[]) => void;
}

export interface SelectItem {
  value: string;
  displayName: string;
}

export interface UnselectedOption {
  isUnselected?: boolean;
  value?: string;
}

export interface SelectValidateOption {
  isSkippedValidation?: boolean; // このコンポーネントで設定されているバリデーションを行うかどうか
  required?: boolean;
}

// 未選択項目のvalue
export const UNSELECTED_VALUE = '-1';

// HTMLOptionsCollectionを変換
function getSelectedValues(opts: HTMLOptionsCollection): string[] {
  const l = [];
  for (let i = 0; i < opts.length; i++) {
    const opt = opts.item(i);
    if (opt?.selected) {
      l.push(opt.value);
    }
  }
  return l;
}

// 未選択項目の作成関数定義
function getSelectBoxItems(
  items: SelectItem[],
  isUnselected = false,
  value = ''
) {
  return [
    ...(isUnselected
      ? [
          {
            value: UNSELECTED_VALUE,
            displayName: value,
          },
        ]
      : []),
    ...items,
  ];
}

// 未選択項目バリデーション
export function isUnselectedValidate(
  value: string[] | string,
  isAllRequired = false
) {
  if (Array.isArray(value) && isAllRequired) {
    // 全てのセレクトボックスが未選択項目の場合
    return value.every((v) => v === UNSELECTED_VALUE);
  } else if (Array.isArray(value) && !isAllRequired) {
    // 複数のセレクトボックスの片方でも未選択項目がある場合
    return value.some((v) => v === UNSELECTED_VALUE);
  } else {
    // セレクトボックスを単独で用いている場合
    return value === UNSELECTED_VALUE;
  }
}

export function Select(props: SelectProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  // selectのクラス名
  const selectClassName = props.className ?? '';

  // オプショナルチェック
  const validator =
    props.validator ||
    (() => {
      return [];
    });
  const required = props.validateOption?.required ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;
  const multiple = props.multiple ?? false;
  const style = props.style ?? {};
  const changeParentState = props.onChangeState ?? (() => {});
  const isUnselected = props.unselectedOption?.isUnselected ?? false;
  const unselectedValue = props.unselectedOption?.value ?? '';
  const items = getSelectBoxItems(props.items, isUnselected, unselectedValue);

  // useState
  const [message, setMessage] = useState<string[]>([]);
  const value = useMemo<string[]>(
    () => (typeof props.value === 'string' ? [props.value] : props.value ?? []),
    [props.value]
  );

  // 初期値の設定がない場合の初期処理
  // useEffect(() => {
  //   if (value) {
  //     // 初期値を親要素へ連携
  //     changeParentState(value);
  //   } else {
  //     // 初期値がない場合は、一番最初の項目を親要素へ連携
  //     const initSelectedValue = items[0].value ?? '';
  //     changeParentState([initSelectedValue]);
  //     setValue([initSelectedValue]);
  //   }
  // }, []);

  const handleChange = (changeValue: HTMLOptionsCollection) => {
    const selectedValues = getSelectedValues(changeValue);
    // 親要素へ値を渡す
    changeParentState(selectedValues);

    // 固有バリデーションを行うかどうか
    if (isSkippedValidation) {
      // 固有バリデーションはスキップして、親要素でのバリデーションを行う
      return;
    }

    // 必須入力チェック
    if (required && isUnselectedValidate(selectedValues)) {
      setMessage([REQUIRED_MESSAGE]);
      return;
    }

    // 外部からもらったバリデーションを実施、メッセージを追加
    setMessage(validator(selectedValues));
  };

  return (
    <div className="Select">
      <div className={multiple ? '' : 'single'}>
        <select
          name={props.name}
          className={`${selectClassName} ${getErrorBorderClassName(message)}`}
          onChange={(event) => {
            handleChange(event.target.options);
          }}
          value={multiple ? value : value[0]}
          multiple={multiple}
          required={required}
          disabled={props.disabled}
          style={style}
        >
          {/* optionタグ */}
          {items.map((val, i) => {
            return (
              <option key={i} value={val.value}>
                {val.displayName}
              </option>
            );
          })}
        </select>
      </div>

      {/* エラー表示 */}
      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
