import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  Filterbox,
  FilterboxItem,
  DataFilterbox,
  DataFilterboxItem,
  PageNavigation,
  Textbox,
  Checkbox,
  LatestUpdateInfo,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListBaseForms,
  FullMethodName_ListBaseFormSettings,
  FullMethodName_ListBaseFormSettingItems,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListItemAutoNameManagements,
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  getWorkerExceptionMessage,
  includeInputValidateError,
  convertComponentUnitReference,
  convertComponentUnitReferenceToFilterboxItem,
  getProgramOptionFilterboxDataWithSystemName,
} from '~/shared/utils';
import { SaveBaseFormRequestParam } from 'worker/saveworker/form/saveBaseForm';
import {
  FormBuilder,
  FormPreviewDialog,
  FormPreviewDialogRef,
  IFormSetting,
  IFormSettingItem,
} from '~/shared/components/ui/FormBuilder';
import './IfmFormDefinitionInput.css';
import { FilterRequest } from '~/worker';
import { backToPreviewPage } from '../utils';
import { useAuth } from '~/shared/contexts/AuthProvider';

const VIEW_ID: ViewId = 'IFM_FORM_DEFINITION_INPUT';
export const IfmFormDefinitionInput = () => {
  const intl = useIntl();
  const auth = useAuth();
  const navi = useNavigate();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);
  const actionType = useMemo(
    () => sourcePageInfo.current.actionType,
    [sourcePageInfo]
  );

  // データフィルターボックス制御用
  const PurposeCode = 'A5000004';
  const FormType1Code = 'A5000001';
  const FormType2Code = 'A5000002';
  const FormType3Code = 'A5000003';
  const [purposes, formType1s, formType2s, formType3s] = useMemo<
    [
      DataFilterboxItem[],
      DataFilterboxItem[],
      DataFilterboxItem[],
      DataFilterboxItem[]
    ]
  >(() => {
    const purposes = getProgramOptionFilterboxData(PurposeCode);
    const formType1s = getProgramOptionFilterboxData(FormType1Code);
    const formType2s = getProgramOptionFilterboxData(FormType2Code);
    const formType3s = getProgramOptionFilterboxData(FormType3Code);
    return [purposes, formType1s, formType2s, formType3s];
  }, []);

  //フィルターボックスType
  const organaizationUnitsItemType = {
    value: 'componentUnitId',
    displayName: 'displayNameLang',
  };

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };

  // Loading制御用
  const [isLoading, setLoading] = useState(false);
  const [isLoadingBaseFormSetting, setLoadingBaseFormSetting] = useState(false);
  const [isLoadingBaseFormSettingItem, setLoadingBaseFormSettingItem] =
    useState(false);
  const [isLoadingItemAutoNameManagement, setLoadingItemAutoNameManagement] =
    useState(false);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);

  // 画面項目（ヘッダ情報）
  const [lastUpdateInfo, setLastUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: false,
  });
  const formAreaRef = useRef(null);
  const [displayName, setDisplayName] = useState('');
  const [purpose, setPurpose] = useState<DataFilterboxItem[]>([]);
  const [formType1, setFormType1] = useState<DataFilterboxItem[]>([]);
  const [formType2, setFormType2] = useState<DataFilterboxItem[]>([]);
  const [formType3, setFormType3] = useState<DataFilterboxItem[]>([]);
  const [managementOrganization, setManagementOrganization] = useState<
    DataFilterboxItem[]
  >([]);
  const [description, setDescription] = useState('');
  const [usableCheck, setUsableCheck] = useState<string[]>();

  const formBuilderAreaRef = useRef<HTMLDivElement>(null);
  const formPreviewDialogRef = useRef<FormPreviewDialogRef>(null);
  // メインデータ
  const baseFormData = useRef<mtechnavi.api.form.IBaseForm>();
  const [formSetting, setFormSetting] = useState<IFormSetting>({
    formPages: [],
  });
  const [formSettingItems, setFormSettingItems] = useState<IFormSettingItem[]>(
    []
  );
  const [itemAutoNameManagementItems, setItemAutoNameManagementItems] =
    useState<mtechnavi.api.form.IItemAutoNameManagement[]>([]);

  // 入力チェック
  const requiredDisplayNameArea = useRef(null);
  const requiredManagementOrganizationArea = useRef(null);
  const [
    workingBlurManagementOrganization,
    setWorkingBlurManagementOrganization,
  ] = useState<Date>();

  // エラーチェック
  const isInputError = (): boolean => {
    setWorkingBlurManagementOrganization(new Date());

    const inputValidationCheckList = [
      {
        value: displayName ?? '',
        ref: requiredDisplayNameArea,
      },
      {
        value:
          managementOrganization.length > 0
            ? managementOrganization[0].value
            : '',
        ref: requiredManagementOrganizationArea,
      },
    ];

    if (includeInputValidateError(document, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  const getBaseFormList = async () => {
    return (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListBaseForms,
      filter: {
        // BEのフィルタ処理未対応のため
        baseFormId: { $eq: sourcePageInfo.current.ids![0] ?? '' },
      },
      requestBody: {
        baseFormIds: [sourcePageInfo.current.ids![0] ?? ''],
      },
      sort: [], // 1件のみ取得想定のため不要
    })) as mtechnavi.api.form.ListBaseFormsResponse;
  };

  // [データ取得] 全データ取得
  const listAllData = async () => {
    // 以下のデータ取得はメインデータ（BaseForm）取得後の前提

    // フォーム設定（構成）データ取得
    (async () => {
      setLoadingBaseFormSetting(true);
      await listBaseFormSettingsData();
    })();

    // フォーム設定（項目）データ取得
    (async () => {
      setLoadingBaseFormSettingItem(true);
      await listBaseFormSettingItemsData();
    })();

    // 複写モード時は以降の取得は行わない
    if (actionType == 'copy') return;

    // 型チェック用データ取得
    (async () => {
      setLoadingItemAutoNameManagement(true);
      await listItemAutoNameManagementsData();
    })();
  };

  // [データ取得] フォーム設定（構成）データ取得
  const listBaseFormSettingsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBaseFormSettings,
        filter: {
          // BEのフィルタ処理未対応のため
          baseFormId: { $eq: sourcePageInfo.current.ids![0] ?? '' },
        },
        requestBody: {
          baseFormIds: [baseFormData.current?.baseFormId ?? ''],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.form.IListBaseFormSettingsResponse;
      const listItem: mtechnavi.api.form.IBaseFormSetting[] =
        resList.items ?? [];
      if (listItem.length > 0) {
        // 複写モード時のみ対象項目をクリアする
        if (actionType === 'copy') {
          const tempSetting = listItem[0];
          tempSetting.baseFormId = '';
          tempSetting.baseFormSettingId = '';
          tempSetting.updatedAt = undefined;
          tempSetting.createdAt = undefined;
          setFormSetting(tempSetting);
        } else {
          setFormSetting(listItem[0]);
        }
      } else {
        setFormSetting({});
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingBaseFormSetting(false);
    }
  };

  // [データ取得] フォーム設定（項目）データ取得
  const listBaseFormSettingItemsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBaseFormSettingItems,
        filter: {},
        requestBody: {
          baseFormIds: [baseFormData.current?.baseFormId ?? ''],
        },
        sort: [{ 'item.sort': 'asc' }],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.form.IListBaseFormSettingItemsResponse;
      const listItem: mtechnavi.api.form.IBaseFormSettingItem[] =
        resList.items ?? [];

      if (listItem.length > 0) {
        // 複写モード時のみ対象項目をクリアする
        if (actionType === 'copy') {
          const tempSettingItems = listItem;
          tempSettingItems.map((v) => {
            v.baseFormId = '';
            v.baseFormSettingId = '';
            v.baseFormSettingItemId = v.item?.itemAutoName; //仮番
            v.updatedAt = undefined;
            v.createdAt = undefined;
          });
          setFormSettingItems(tempSettingItems);
        } else {
          setFormSettingItems(listItem);
        }
      } else {
        setFormSettingItems([]);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingBaseFormSettingItem(false);
    }
  };

  // [データ取得] 型チェックデータ取得
  const listItemAutoNameManagementsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListItemAutoNameManagements,
        filter: {},
        requestBody: {
          baseFormId: baseFormData.current?.baseFormId ?? '',
        },
        sort: [],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.form.IListItemAutoNameManagementsResponse;
      const listItem: mtechnavi.api.form.IItemAutoNameManagement[] =
        resList.items ?? [];

      if (listItem.length > 0) {
        setItemAutoNameManagementItems(listItem);
      } else {
        setItemAutoNameManagementItems([]);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingItemAutoNameManagement(false);
    }
  };

  const handleFormSettingChange = useCallback((setting: IFormSetting) => {
    setFormSetting(setting || {});
  }, []);

  const handleFormSettingItemsChange = useCallback(
    (settingItems: IFormSettingItem[]) => {
      setFormSettingItems(settingItems);
    },
    []
  );

  // フォーム設定の保存
  const handleSaveBaseForm = async () => {
    if (isInputError()) {
      return;
    }

    setLoading(true);
    const requestBaseForm: SaveBaseFormRequestParam = {
      baseForm: {
        baseFormId: baseFormData?.current?.baseFormId,
        formProperties: await createFormProperties(),
        usable: usableCheck?.length && usableCheck[0] === '1' ? true : false,
        updatedAt: baseFormData?.current?.updatedAt,
      },
      baseFormSetting: formSetting,
      baseFormSettingItems: formSettingItems,
    };

    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveBaseForm',
        request: requestBaseForm,
      });

      getBaseFormList();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage(navi, sourcePageInfo.current, VIEW_ID);
  };

  const createFormProperties = async () => {
    const resultPurposes: sharelib.INameOption[] = [];
    purpose.map((purpose) => {
      const resultPurpose = window.App.services.ui.getNameOptionWithCode(
        PurposeCode,
        purpose.value ?? ''
      );
      resultPurposes.push(resultPurpose);
    });

    const resultFormType1: sharelib.INameOption = formType1.length
      ? window.App.services.ui.getNameOptionWithCode(
          FormType1Code,
          formType1[0].value ?? ''
        )
      : {};

    const resultFormType2: sharelib.INameOption = formType2.length
      ? window.App.services.ui.getNameOptionWithCode(
          FormType2Code,
          formType2[0].value ?? ''
        )
      : {};

    const resultFormType3: sharelib.INameOption = formType3.length
      ? window.App.services.ui.getNameOptionWithCode(
          FormType3Code,
          formType3[0].value ?? ''
        )
      : {};

    const baseFormProperties: mtechnavi.api.form.IFormProperties = {
      systemForm: baseFormData?.current?.formProperties?.systemForm ?? false,
      purposes: resultPurposes,
      managementOrganization:
        managementOrganization.length > 0
          ? (await convertComponentUnitReference(
              managementOrganization[0].value || '',
              managementOrganization[0].displayName || ''
            )) ?? {}
          : null,
      displayName: displayName,
      formType1: resultFormType1,
      formType2: resultFormType2,
      formType3: resultFormType3,
      description: description,
    };

    return baseFormProperties;
  };

  // フォームのプレビュー
  const handleFormPreview = async () => {
    if (!formSetting) {
      return;
    }

    formPreviewDialogRef.current?.open(
      await createFormProperties(),
      usableCheck?.length && usableCheck[0] === '1' ? true : false,
      formSetting,
      formSettingItems
    );
  };

  // 管理組織に所属部門をセット
  const setUserOrganizationUnits = async () => {
    const organizationUnits =
      await window.App.services.ui.getUserOrganizationUnits(
        auth.user?.email || ''
      );
    const managementOrganizationItem: FilterboxItem[] = [];
    managementOrganizationItem.push(
      convertComponentUnitReferenceToFilterboxItem(
        organizationUnits?.at(0)?.componentUnit
      ) ?? { value: '', displayName: '' }
    );
    setManagementOrganization(managementOrganizationItem);
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // プリセット取得
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      })();
      // 新規モード時は管理組織、使用用途のセットのみ行い以降の処理を行わない
      if (actionType === 'add') {
        (async () => {
          await setUserOrganizationUnits();
          setLoading(false);
          return;
        })();
        const initialPurpose = getProgramOptionFilterboxDataWithSystemName(
          'A5000004',
          'B02'
        );
        setPurpose(initialPurpose);
      }

      (async () => {
        const baseFormList = (await getBaseFormList())
          .items as mtechnavi.api.form.IBaseForm[];
        if (!baseFormList || !baseFormList.length) {
          setLoading(false);
          return;
        }
        baseFormData.current = baseFormList[0];

        // フォーム設定情報を取得する
        await listAllData();

        // 複写モード時のみ対象項目をクリア（初期化）する
        if (actionType === 'copy') {
          baseFormData.current.baseFormId = '';
          baseFormData.current.updatedAt = undefined;
          baseFormData.current.createdAt = undefined;
          baseFormData.current.formProperties!.managementOrganization =
            undefined;
          baseFormData.current.usable = false;
        }

        // 最終更新情報
        setLastUpdateInfo({
          isVisibleUpdateInfo: baseFormData.current.baseFormId ? true : false,
          attributeAt:
            baseFormData?.current?.formProperties?.updatedProperties
              ?.updatedAt ?? undefined,
          content:
            baseFormData?.current?.formProperties?.updatedProperties?.updatedBy
              ?.displayName ?? '',
          attribute:
            baseFormData?.current?.formProperties?.updatedProperties?.updatedBy
              ?.email ?? '',
        });

        // 画面項目初期セット
        setDisplayName(baseFormData.current.formProperties?.displayName ?? '');

        const filterboxItemPurpose = baseFormData.current?.formProperties
          ?.purposes
          ? baseFormData.current?.formProperties?.purposes.map((v) => {
              return {
                value: v.code ?? '',
                displayName: v.displayNameLang?.ja ?? '',
              };
            })
          : [];
        setPurpose(filterboxItemPurpose);

        const filterboxItemFormType1 = baseFormData.current?.formProperties
          ?.formType1
          ? [
              {
                value:
                  baseFormData.current?.formProperties?.formType1?.code ?? '',
                displayName:
                  baseFormData.current?.formProperties?.formType1
                    ?.displayNameLang?.ja ?? '',
              },
            ]
          : [];
        setFormType1(filterboxItemFormType1);

        const filterboxItemFormType2 = baseFormData.current?.formProperties
          ?.formType2
          ? [
              {
                value:
                  baseFormData.current?.formProperties?.formType2?.code ?? '',
                displayName:
                  baseFormData.current?.formProperties?.formType2
                    ?.displayNameLang?.ja ?? '',
              },
            ]
          : [];
        setFormType2(filterboxItemFormType2);

        const filterboxItemFormType3 = baseFormData.current?.formProperties
          ?.formType3
          ? [
              {
                value:
                  baseFormData.current?.formProperties?.formType3?.code ?? '',
                displayName:
                  baseFormData.current?.formProperties?.formType3
                    ?.displayNameLang?.ja ?? '',
              },
            ]
          : [];
        setFormType3(filterboxItemFormType3);

        // 複写モード時は所属部門をセットする
        if (actionType === 'copy') {
          await setUserOrganizationUnits();
        } else {
          const managementOrganizationItem: FilterboxItem[] = [];
          baseFormData.current?.formProperties?.managementOrganization
            ? managementOrganizationItem.push(
                convertComponentUnitReferenceToFilterboxItem(
                  baseFormData.current?.formProperties?.managementOrganization
                ) ?? { value: '', displayName: '' }
              )
            : [];
          setManagementOrganization(managementOrganizationItem);
        }

        setDescription(baseFormData.current.formProperties?.description ?? '');
        setUsableCheck(baseFormData.current.usable ? ['1'] : []);

        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      setLoading(false);
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // Loading制御
  useEffect(() => {
    // ひとつでもLoadingならアイコン表示
    setLoading(
      isLoadingBaseFormSetting ||
        isLoadingBaseFormSettingItem ||
        isLoadingItemAutoNameManagement
    );

    // isLodingXX 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingBaseFormSetting,
    isLoadingBaseFormSettingItem,
    isLoadingItemAutoNameManagement,
  ]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="IfmFormDefinitionInput">
          <div className="header">
            <PageNavigation
              backpagePath="/"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: lastUpdateInfo,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setBackConfirmOpen(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  <Textbox
                    labelId="IFM_FORM_DEFINITION_INPUT.displayName"
                    name="displayName"
                    columns={preset.columns}
                    value={displayName}
                    type="text"
                    validateOption={{ required: true }}
                    onChangeState={setDisplayName}
                  />
                </div>
                <div className="w-25">
                  <Textbox
                    labelId="IFM_FORM_DEFINITION_INPUT.systemType"
                    name="systemType"
                    columns={preset.columns}
                    value={
                      baseFormData.current?.formProperties?.systemForm
                        ? GetMessageWithIntl(intl, {
                            id: 'system',
                          })
                        : GetMessageWithIntl(intl, {
                            id: 'user',
                          })
                    }
                    type="text"
                    disabled={true}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={purposes}
                    name="purposes"
                    labelId="IFM_FORM_DEFINITION_INPUT.purposes"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={purpose}
                    onChangeState={setPurpose}
                    multiple={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25">
                  <DataFilterbox
                    data={formType1s}
                    name="formType1"
                    labelId="IFM_FORM_DEFINITION_INPUT.formType1"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={formType1}
                    onChangeState={setFormType1}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={formType2s}
                    name="formType2"
                    labelId="IFM_FORM_DEFINITION_INPUT.formType2"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={formType2}
                    onChangeState={setFormType2}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={formType3s}
                    name="formType3"
                    labelId="IFM_FORM_DEFINITION_INPUT.formType3"
                    columns={preset.columns}
                    searchOption={{ targets: 'displayName' }}
                    value={formType3}
                    onChangeState={setFormType3}
                  />
                </div>
                <div className="w-25">
                  <div ref={requiredManagementOrganizationArea}>
                    <Filterbox
                      name="managementOrganization"
                      fullMethodName={FullMethodName_ListComponentUnits}
                      labelId="IFM_FORM_DEFINITION_INPUT.managementOrganization"
                      columns={preset.columns}
                      itemType={organaizationUnitsItemType}
                      value={
                        managementOrganization ?? [
                          { value: '', displayName: '' },
                        ]
                      }
                      searchOption={{
                        targets: 'displayName',
                        customQuery: {
                          'organizationUnit.systemName': { $eq: 'B02' },
                        },
                      }}
                      formatOption={{ mapKeyValue: 'ja' }}
                      onChangeState={setManagementOrganization}
                      validateOption={{ required: true }}
                      workingBlur={workingBlurManagementOrganization}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-66">
                  <Textbox
                    labelId="IFM_FORM_DEFINITION_INPUT.description"
                    name="description"
                    columns={preset.columns}
                    value={description}
                    type="text"
                    onChangeState={setDescription}
                  />
                </div>
                <div className="w-33">
                  <div className="usable-area">
                    <Checkbox
                      name="usableCheck"
                      className="group"
                      items={[
                        {
                          value: '1',
                          displayName: GetMessageWithIntl(intl, {
                            prefixId: 'IFM_FORM_DEFINITION_INPUT',
                            id: 'usable',
                          }),
                        },
                      ]}
                      value={usableCheck}
                      onChangeState={setUsableCheck}
                      columns={preset.columns}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-builder-area" ref={formBuilderAreaRef}>
              <label className="area-label">
                {GetMessageWithIntl(intl, {
                  prefixId: 'IFM_FORM_DEFINITION_INPUT',
                  id: 'formSetting',
                })}
              </label>
              <FormBuilder
                initialFormSetting={formSetting}
                onChangeFormSetting={handleFormSettingChange}
                initialFormSettingItems={formSettingItems}
                onChangeFormSettingItems={handleFormSettingItemsChange}
                itemAutoNameManagementItems={itemAutoNameManagementItems}
              />
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                name="preview"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, { id: 'preview' })}
                onClick={() => handleFormPreview()}
              />
              <CaptionButton
                name="save"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, { id: 'save' })}
                onClick={handleSaveBaseForm}
              />
            </div>
          </div>
        </div>
        <FormPreviewDialog ref={formPreviewDialogRef} />
        {/* 戻る 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isBackConfirmOpen}
          viewMessage={viewMessageBack}
          onDecision={() => {
            backToPreviewPage(navi, sourcePageInfo.current, VIEW_ID);
          }}
          onCancel={() => {
            setBackConfirmOpen(false);
          }}
        />
        {/* 保存 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isSaveConfirmOpen}
          viewMessage={viewMessageSave}
          onDecision={() => {
            handleSaveBaseForm();
          }}
          onCancel={() => {
            setSaveConfirmOpen(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
