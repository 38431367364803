import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { AutoReminderSettingDialog } from '~/shared/components/ui/';

export const UISampleAutoReminderSettingDialogs = () => {
  const [displayResult, setDisplayResult] = useState('');
  const [isShowDisplay, setIsShowDisplay] = useState(false);
  const [confirmResult, setConfirmResult] = useState('');
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [saveResult, setSaveResult] = useState('');
  const [isShowSave, setIsShowSave] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">自動催促設定ダイアログ</h3>
      <h4>表示モード</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setDisplayResult('');
                setIsShowDisplay(true);
              }}
            />
            <AutoReminderSettingDialog
              isOpen={isShowDisplay}
              inputOption={{
                deadline: new Date('2023/05/31'),
                displayModeType: 'display',
              }}
              onDecision={(v) => {
                setDisplayResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setIsShowDisplay(false);
              }}
              onCancel={() => {
                setDisplayResult('キャンセルしました');
                setIsShowDisplay(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{displayResult}</pre>
          </div>
        </div>
      </div>
      <h4>確定モード</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setConfirmResult('');
                setIsShowConfirm(true);
              }}
            />
            <AutoReminderSettingDialog
              isOpen={isShowConfirm}
              inputOption={{
                deadline: new Date('2023/05/25'),
                displayModeType: 'decision',
              }}
              onDecision={(v) => {
                setConfirmResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setConfirmResult('キャンセルしました');
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{confirmResult}</pre>
          </div>
        </div>
      </div>
      <h4>保存モード</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setSaveResult('');
                setIsShowSave(true);
              }}
            />
            <AutoReminderSettingDialog
              isOpen={isShowSave}
              inputOption={{
                deadline: new Date('2023/05/31'),
                displayModeType: 'save',
              }}
              onDecision={(v) => {
                setSaveResult(`出力しました。${JSON.stringify(v, null, 2)}`);
                setIsShowSave(false);
              }}
              onCancel={() => {
                setSaveResult('キャンセルしました');
                setIsShowSave(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{saveResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
