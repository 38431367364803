import { useIntl } from 'react-intl';
import { Property } from 'csstype';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import './base.css';
import './HistoryDialog.css';

interface ListColumnSetting {
  header?: MessageProps;
  propertyName: string;
  width?: string;
  align?: Property.TextAlign;
}

export interface HistoryDialogInputOption {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  keyColumn: string;
  columns: ListColumnSetting[];
}

export interface HistoryDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface HistoryDialogProps {
  isOpen: boolean;
  messageOption: HistoryDialogMessageOption;
  inputOption: HistoryDialogInputOption;
  onCancel: () => void;
}

const makeTemplateColumnStyles = (
  columns: ListColumnSetting[]
): React.CSSProperties => {
  return {
    gridTemplateColumns: `${columns
      .map((column) => column.width || '1fr')
      .join(' ')}`,
  };
};

export const HistoryDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onCancel,
}: HistoryDialogProps) => {
  const intl = useIntl();

  const elements = (
    <div className="history-dialog">
      <div className="detail-area">
        <div className="scrollable-area">
          {
            <div
              className="history-header"
              style={makeTemplateColumnStyles(inputOption.columns)}
            >
              {inputOption.columns.map((column) => (
                <div key={column.propertyName}>
                  {column.header ? GetMessage(column.header) : ''}
                </div>
              ))}
            </div>
          }
          <ul className="history-list">
            {inputOption.data.map((item) => {
              const key = item[inputOption.keyColumn];
              return (
                <li
                  key={key}
                  style={makeTemplateColumnStyles(inputOption.columns)}
                >
                  {inputOption.columns.map((column) => (
                    <div
                      className="cell"
                      key={column.propertyName}
                      style={{
                        textAlign: column?.align,
                      }}
                    >
                      {item[column.propertyName]}
                    </div>
                  ))}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, {
            prefixId: 'DIALOG_CAPTION',
            id: 'return',
          })}
          onClick={() => onCancel()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="HistoryDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
