/**
 * バリデーション関連
 */

import { IntlShape } from 'react-intl';
import { GetMessageWithIntl, error } from '~/shared/components';

export interface RequireInput {
  value: string;
  ref: React.RefObject<HTMLInputElement>;
}
/* 
  バリデートエラーを表示するためだけにfocus()、blur()を行い、
  必須チェックは親要素側でチェックするなど処理が煩雑なので下記処理を行なっている
*/
// textbox等の入力エラーチェック及びその付随処理
export function includeInputValidateError(
  document: Document | Element | null,
  intl: IntlShape,
  requireItems?: RequireInput[] // 必須項目
) {
  if (document === null) {
    return false;
  }

  // 必須エラーを必ず表示するためのユーザー視点の処理
  const inputElements = document.querySelectorAll('input');
  const textareaElements = document.querySelectorAll('textarea');
  inputElements.forEach((item: HTMLInputElement) => {
    item.focus();
    item.blur();
  });
  textareaElements.forEach((item: HTMLTextAreaElement) => {
    item.focus();
    item.blur();
  });

  /*
    上記のfocus(),blur()処理によって表示されたエラーは取得できないことがあるので
    必須のエラー処理判定はrequireItemsを用いて行う
  */
  if (includeRequireError(requireItems ?? [], intl)) {
    return true;
  }

  // 既に表示されているエラーに対する処理
  const errorClassName = '.visible-error-message';
  const isInputError = !!document.querySelector(errorClassName);
  // 入力項目でエラーが発生した場合はエラートーストを表示して、該当箇所までスクロール
  if (isInputError) {
    error([
      GetMessageWithIntl(intl, {
        id: 'E0000002',
      }),
    ]);
    document.querySelector(errorClassName)!.scrollIntoView();
  }

  return isInputError;
}

function includeRequireError(targets: RequireInput[], intl: IntlShape) {
  const target = targets.find((v) => v.value === '');
  if (target) {
    error([
      GetMessageWithIntl(intl, {
        id: 'E0000002',
      }),
    ]);
    target.ref.current?.scrollIntoView();
  }

  return !!target;
}

/**
 * パスワードチェック
 * ・最小文字数：10文字
 * ・英字と数字から最低1文字ずつ使用
 * ・使用可能文字は英(大/小)数字記号(特定のもののみ)
 * ・使用可能記号：$ * ? ! @ # % & > < _
 * @param password パスワード
 */
export function validatePassword(password: string): boolean {
  const reg = new RegExp(
    '^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9$*?!@#%&><_]{10,}$'
  );
  return reg.test(password);
}
