import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  DeadlineChangeDialog,
  DeadlineChangeResult,
} from '~/shared/components/ui/Dialog';
import { DeadlineChangeApprovalDialog } from '~/shared/components/ui/Dialog/DeadlineChangeApprovalDialog';

export const UISampleDeadlineChangeDialogs = () => {
  const [isShowDeadlineChange, setShowDeadlineChange] = useState(false);
  const [changeResult, setChangeResult] = useState<DeadlineChangeResult | null>(
    null
  );

  const [isShowDeadlineRequest, setShowDeadlineRequest] = useState(false);
  const [requestResult, setRequestResult] =
    useState<DeadlineChangeResult | null>(null);

  const [isShowDeadlineChangeApproval, setShowDeadlineChangeApproval] =
    useState(false);
  const [approvalResult, setApprovalResult] =
    useState<DeadlineChangeResult | null>(null);

  return (
    <div className="categories">
      <h3 id="dialog">回答期日変更系ダイアログ</h3>

      <h4>回答期日変更</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowDeadlineChange(true);
              }}
            />
            <DeadlineChangeDialog
              isOpen={isShowDeadlineChange}
              outputOption={{
                currentDeadline: new Date(),
              }}
              messageOption={{
                headerLabelId: {
                  id: 'deadline_change',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'deadline_change',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              onCancel={() => {
                setChangeResult(null);
                setShowDeadlineChange(false);
              }}
              onDecision={(result) => {
                setChangeResult(result);
                setShowDeadlineChange(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {changeResult && JSON.stringify(changeResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>回答期日変更依頼</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowDeadlineRequest(true);
              }}
            />
            <DeadlineChangeDialog
              isOpen={isShowDeadlineRequest}
              outputOption={{
                currentDeadline: changeResult?.deadline || new Date(),
              }}
              messageOption={{
                headerLabelId: {
                  id: 'deadline_change_request',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'deadline_change_request',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              onCancel={() => {
                setRequestResult(null);
                setShowDeadlineRequest(false);
              }}
              onDecision={(result) => {
                setRequestResult(result);
                setShowDeadlineRequest(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {requestResult && JSON.stringify(requestResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>(依頼)回答期日変更</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowDeadlineChangeApproval(true);
              }}
            />
            <DeadlineChangeApprovalDialog
              isOpen={isShowDeadlineChangeApproval}
              outputOption={{
                currentDeadline: changeResult?.deadline || new Date(),
                requestComment: requestResult?.comment || '',
                requestDeadline: requestResult?.deadline || new Date(),
              }}
              messageOption={{
                headerLabelId: {
                  id: 'deadline_change_approval',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'deadline_change_approval',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              onCancel={() => {
                setApprovalResult(null);
                setShowDeadlineChangeApproval(false);
              }}
              onDecision={(result) => {
                setApprovalResult(result);
                setShowDeadlineChangeApproval(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {approvalResult && JSON.stringify(approvalResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
