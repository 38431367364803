import { useMemo, CSSProperties, useCallback } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  NumberBoxValidateOption,
  NumberBox as NumberboxComponent,
} from '~/shared/components/parts/NumberBox/NumberBox';
import { getProperty } from '../common';

export interface NumberboxProps {
  name: string;
  className?: string;
  labelId?: string;
  value?: number;
  placeholder?: string;
  disabled?: boolean;
  validator?: (v: number) => string[];
  formatter?: (v: number) => string;
  converter?: (v: number) => number;
  validateOption?: NumberBoxValidateOption;
  properties?: Array<Property>;
  columns?: Array<string>;
  onChangeState?: (arg?: number) => void;
}

export function Numberbox(props: NumberboxProps) {
  const { onChangeState } = props;
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;

  const onChange = useCallback(
    (v?: number) => {
      onChangeState && onChangeState(v);
    },
    [onChangeState]
  );
  return (
    <div style={viewStyle}>
      <span className={props.labelId !== '' ? 'caption' : 'caption no-name'}>
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.labelId !== '' ? (
                <GetMessageComponent id={props.labelId} />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </span>
      <NumberboxComponent
        name={props.name}
        className={props.className}
        value={props.value}
        placeholder={props.placeholder}
        validator={props.validator}
        validateOption={props.validateOption}
        formatter={props.formatter}
        converter={props.converter}
        disabled={disabled ? true : false}
        style={componentStyle}
        onChangeState={onChange}
      ></NumberboxComponent>
    </div>
  );
}
