import { Container, Toast } from '~/shared/components';
import { useState } from 'react';
import { ReactComponent as ListIcon } from '@material-design-icons/svg/filled/list.svg';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import { IconWithCaptionButton } from '~/shared/components/parts/Button/IconWithCaptionButton';
import './UISample.css';
import { UISampleCaptionButtons } from './Buttons/CaptionButtons';
import { UISampleIconButtons } from './Buttons/IconButtons';
import { UISampleIconWithCaptionButtons } from './Buttons/IconWithCaptionButtons';
import { UISampleIconVariations } from './Buttons/IconVariations';
import { UISampleTextInputs } from './Inputs/TextInputs';
import { UISampleSelectInputs } from './Inputs/SelectInputs';
import { UISampleFormattedInputs } from './Inputs/FormattedInputs';
import { UISampleDateInputs } from './Inputs/DateInputs';
import { UISampleFileUploads } from './Inputs/FileUploads';
import { UISampleMisc } from './Misc/Misc';
import { UISampleDataViews } from './Views/DataViews';
import { UISampleSimpleLists } from './Views/SimpleLists';
import { UISampleSimpleIconLists } from './Views/SimpleIconLists';
import { UISampleExtendFileUploads } from './Inputs/ExtendFileUploads';
import { UISampleLayouts } from './Layouts/Layouts';
import { UISampleDialogs } from './Interactive/Dialogs';
import { UISampleAccordion } from './Layouts/Accordion';
import { UISamplePageNavigation } from './PageNavigation/PageNavigation';
import { UISampleListViews } from './Views/ListViews';
import { UISampleTabs } from './Layouts/Tabs';
import { Link } from 'react-router-dom';
import { UISamplePageLoading } from './Loading/Loading';
import { UISamplePageOldLoading } from './Loading/OldLoading';

interface NavItem {
  label: string;
  id: string;
}
type NavParentItem = NavItem & {
  children: NavItem[];
};
const navData: NavParentItem[] = [
  {
    id: 'layouts',
    label: 'レイアウト',
    children: [
      { id: 'flex-layouts', label: 'フレキシブル レイアウト' },
      { id: 'accordion', label: 'アコーディオン' },
      { id: 'tabs', label: 'タブ' },
    ],
  },
  {
    id: 'loading',
    label: 'ローディング',
    children: [
      { id: 'loading', label: 'ローディング' },
      { id: 'old-loading', label: '旧ローディング' },
    ],
  },
  {
    id: 'navigation',
    label: 'ナビゲーション',
    children: [{ id: 'pagination', label: '改良版ページネーション' }],
  },
  {
    id: 'lists',
    label: 'リスト',
    children: [
      { id: 'list-view', label: 'リストビュー' },
      { id: 'data-view', label: 'データビュー' },
      { id: 'extend-data-view', label: '拡張版データビュー' },
      { id: 'extend-list-view', label: '拡張版リストビュー' },
      { id: 'simple-list', label: 'シンプルリスト' },
      { id: 'simple-icon-list', label: 'シンプルアイコンリスト' },
    ],
  },
  {
    id: 'dialogs',
    label: 'ダイアログ',
    children: [{ id: 'dialog', label: '確認ダイアログ' }],
  },
  {
    id: 'inputs',
    label: '入力項目',
    children: [
      { id: 'text-inputs', label: 'テキスト入力' },
      { id: 'formatted-inputs', label: '特定フォーマット入力' },
      { id: 'selection-inputs', label: '選択入力' },
      { id: 'date-inputs', label: '日時選択・入力' },
    ],
  },
  {
    id: 'other-inputs',
    label: 'その他の入力',
    children: [
      { id: 'file-uploads', label: 'ファイルアップロード' },
      { id: 'extends-file-uploads', label: '拡張版ファイルアップロード' },
    ],
  },
  {
    id: 'buttons',
    label: 'ボタン',
    children: [
      { id: 'caption-buttons', label: 'キャプションボタン' },
      { id: 'icon-buttons', label: 'アイコンボタン' },
      { id: 'icon-caption-buttons', label: 'アイコン + キャプションボタン' },
      { id: 'icon-variations', label: '定義済アイコンバリエーション' },
    ],
  },
  {
    id: 'old-buttons',
    label: '旧ボタン',
    children: [{ id: 'old-buttons', label: 'ボタン (旧)' }],
  },
  {
    id: 'others',
    label: 'その他',
    children: [],
  },
];

export const UISample = () => {
  const [isShowNav, setShowNav] = useState(true);

  const handleToggleNav = () => {
    setShowNav(!isShowNav);
  };

  return (
    <Container>
      <div className="UISample">
        <h2 id="layouts">レイアウト</h2>
        <UISampleLayouts />
        <UISampleAccordion />
        <UISampleTabs />

        <h2 id="loading">ローディング</h2>
        <UISamplePageLoading />
        <UISamplePageOldLoading />

        <h2 id="navigation">ナビゲーション</h2>
        <UISamplePageNavigation />

        <h2 id="lists">リスト</h2>
        <UISampleDataViews />
        <UISampleListViews />
        <UISampleSimpleLists />
        <UISampleSimpleIconLists />

        <h2 id="dialogs">ダイアログ</h2>
        <UISampleDialogs />
        <div className="input-line">
          <p>
            ※各種ダイアログの実装確認は
            <Link to="/debug/dialog-sample?debug=on">
              ダイアログサンプルページ
            </Link>
            を参照のこと
          </p>
        </div>

        <h2 id="inputs">入力項目</h2>
        <UISampleTextInputs />
        <UISampleFormattedInputs />
        <UISampleSelectInputs />
        <UISampleDateInputs />

        <h2 id="other-inputs">その他の入力</h2>
        <UISampleFileUploads />
        <UISampleExtendFileUploads />

        <h2 id="buttons">ボタン</h2>
        <UISampleCaptionButtons />
        <UISampleIconButtons />
        <UISampleIconWithCaptionButtons />
        <UISampleIconVariations />

        <h2 id="others">その他</h2>
        <UISampleMisc />

        {/* ページ内ナビゲーション */}
        <div className={`floatNavigation ${isShowNav ? 'show' : 'hide'}`}>
          <ul className="page-nav-list">
            {navData.map((item) => (
              <li key={item.id}>
                <a href={`#${item.id}`}>{item.label}</a>
                {item.children.length > 0 && (
                  <ul>
                    {item.children.map((child) => (
                      <li key={child.id}>
                        <a href={`#${child.id}`}>{child.label}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <IconWithCaptionButton
            name="page-nav-button"
            className="page-nav-button"
            buttonType="basic"
            icon={isShowNav ? <CloseIcon /> : <ListIcon />}
            onClick={handleToggleNav}
          />
        </div>
      </div>
      <Toast />
    </Container>
  );
};
