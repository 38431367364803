import { useEffect, useState, useMemo } from 'react';
import type { FilterPrimitive, FilterTerm } from '~/worker';
import { Textbox } from '~/shared/components/ui/Textbox';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { FilterViewQuery } from '../index';
import {
  isFilterGte,
  isFilterLte,
  isNumber,
  isString,
  toGteFilter,
  toLteFilter,
} from '../filter';
import { checkDisalbedPresetProperty } from '../preset';

type NumberRange = {
  from?: string | null;
  to?: string | null;
};

interface FilterInputFromToNumberProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}

export const getFilteringFromToNumberValues = (
  terms: FilterTerm[]
): { from?: string | number; to?: string | number } => {
  const value: { from?: string | number; to?: string | number } = {};
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      if (isFilterGte(v) && (isString(v.$gte) || isNumber(v.$gte))) {
        value.from = v.$gte;
      }
      if (isFilterLte(v) && (isString(v.$lte) || isNumber(v.$lte))) {
        value.to = v.$lte;
      }
    }
  }
  return value;
};

export const toFromToNumberFilters = (
  name: string,
  values: FilterPrimitive[]
): FilterTerm[] => {
  return values.reduce((terms, v) => {
    if (!terms.length) {
      terms.push(toGteFilter(name, v));
    } else {
      terms.push(toLteFilter(name, v));
    }
    return terms;
  }, [] as FilterTerm[]);
};

/**
 * 数量/数値検索フィルタ
 *
 * Longフィールドにgreater than equal, less than equalの絞り込み検索を行う
 *
 * - プリセット
 *     - type='fromtonumber'
 */
export function FilterInputFromToNumber(props: FilterInputFromToNumberProps) {
  const { labelId, column, presetItem, filterValue, onChangeFilter } = props;
  const [numberRange, setNumberRange] = useState<NumberRange>({});

  const [columnProperty, isDisabledColumn] = useMemo<
    [PresetProperty[], boolean]
  >(() => {
    const columnProperty: PresetProperty[] = presetItem?.property
      ? presetItem.property.filter((v) => v.name === column)
      : [];

    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    return [columnProperty, isDisabled];
  }, [presetItem?.property, column, presetItem?.children]);

  useEffect(() => {
    const fromToNumberValue = getFilteringFromToNumberValues(
      filterValue.filterTerms[column] ?? []
    );

    setNumberRange({
      from: fromToNumberValue.from?.toString(),
      to: fromToNumberValue.to?.toString(),
    });
  }, [column, filterValue.filterTerms]);

  const fromNumberGteFilterTerm = (from: string): FilterTerm => {
    return {
      [column]: {
        $gte: from,
      },
    };
  };
  const toNumberLteFilterTerm = (to: string): FilterTerm => {
    return {
      [column]: {
        $lte: to,
      },
    };
  };
  const onChangeFromNumberState = (amount: string | null) => {
    const range = { ...numberRange, from: amount };
    const filterTerms: FilterTerm[] = [];
    range?.from && filterTerms.push(fromNumberGteFilterTerm(range.from));
    range?.to && filterTerms.push(toNumberLteFilterTerm(range.to));
    onChangeFilter && onChangeFilter(filterTerms);
    setNumberRange(range);
  };

  const onChangeToNumberState = (amount: string | null) => {
    const range = { ...numberRange, to: amount };
    const filterTerms: FilterTerm[] = [];
    range?.from && filterTerms.push(fromNumberGteFilterTerm(range.from));
    range?.to && filterTerms.push(toNumberLteFilterTerm(range.to));
    onChangeFilter && onChangeFilter(filterTerms);
    setNumberRange(range);
  };

  return (
    <div className="put-line">
      <Textbox
        name={column}
        type="text"
        value={numberRange.from}
        columns={[column]}
        labelId={labelId}
        onChangeState={(v) => {
          onChangeFromNumberState(v);
        }}
        properties={columnProperty}
        disabled={isDisabledColumn}
      />
      <div className="aid">〜</div>
      <Textbox
        name={column}
        type="text"
        value={numberRange.to}
        columns={[column]}
        labelId={labelId}
        onChangeState={(v) => {
          onChangeToNumberState(v);
        }}
        properties={columnProperty}
        disabled={isDisabledColumn}
      />
    </div>
  );
}
