import { useEffect, useState, useMemo } from 'react';
import type { FilterPrimitive, FilterTerm } from '~/worker';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { FilterViewQuery } from '../index';
import { DateSuggest } from '~/shared/components/ui/Dates';
import Long from 'long';
import dayjs from 'dayjs';
import {
  isFilterGte,
  isFilterLte,
  isString,
  isNumber,
  toGteFilter,
  toLteFilter,
} from '../filter';
import { checkDisalbedPresetProperty } from '../preset';

type DateRange = {
  from?: Date | null;
  to?: Date | null;
};

interface FilterInputFromToDateProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}

export const getFilteringFromToDateValues = (
  terms: FilterTerm[]
): { from?: string; to?: string } => {
  const value: { from?: string; to?: string } = {};
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      if (isFilterGte(v)) {
        if (isString(v.$gte)) {
          value.from = v.$gte;
        }
        if (isNumber(v.$gte)) {
          value.from = Number(v.$gte).toString();
        }
      }
      if (isFilterLte(v)) {
        if (isString(v.$lte)) {
          value.to = v.$lte;
        }
        if (isNumber(v.$lte)) {
          value.to = Number(v.$lte).toString();
        }
      }
    }
  }
  return value;
};

export const toFromToDateFilters = (
  name: string,
  values: FilterPrimitive[]
): FilterTerm[] => {
  return values.reduce((terms, v) => {
    if (!terms.length) {
      terms.push(toGteFilter(name, v));
    } else {
      terms.push(toLteFilter(name, v));
    }
    return terms;
  }, [] as FilterTerm[]);
};

/**
 * 日付の範囲指定検索フィルタ
 *
 * 日付フィールドに範囲指定で検索を行う。
 *
 * - プリセット
 *     - type='fromtodate'
 */
export function FilterInputFromToDate(props: FilterInputFromToDateProps) {
  const {
    labelId, // 項目名の辞書対応
    column, // 項目のキーになるidと同義
    presetItem, // preset による項目制御用
    filterValue, // 呼出元から渡された値をセットする用 （セット時はonChangeFilterが発火しないようにすること）
    onChangeFilter, // 値を変更した場合に呼出元に伝えるためのトリガー（呼出元で格納する際 filterValue に渡してループしないよう注意）
  } = props;
  const [dateRangeFrom, setDateRangeFrom] = useState<Date | null>(null);
  const [dateRangeTo, setDateRangeTo] = useState<Date | null>(null);

  const [columnProperty, isDisabledColumn] = useMemo<
    [PresetProperty[], boolean]
  >(() => {
    const columnProperty: PresetProperty[] = presetItem?.property
      ? presetItem.property.filter((v) => v.name === column)
      : [];

    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    return [columnProperty, isDisabled];
  }, [presetItem?.property, column, presetItem?.children]);

  useEffect(() => {
    const fromToDateValue = getFilteringFromToDateValues(
      filterValue.filterTerms[column] ?? []
    );
    setDateRangeFrom(convertToDate(fromToDateValue.from));
    setDateRangeTo(convertToDate(fromToDateValue.to));
  }, [column, filterValue.filterTerms]);

  const convertToUnixMicro = (milisec: number): Long => {
    return Long.fromNumber(milisec).multiply(1000);
  };

  const convertToDate = (microsec?: string): Date | null => {
    if (!microsec) return null;
    const milisec = Long.fromString(microsec).divide(1000).toNumber();
    const convertDate = dayjs(milisec).format('YYYY/MM/DD');
    return new Date(convertDate);
  };

  const toDateGteFilterTerm = (from: Date): FilterTerm => {
    return {
      [column]: {
        $gte: convertToUnixMicro(new Date(from).getTime()).toString(),
      },
    };
  };
  const toStartDate = (day: Date): Date => {
    const convertDate = dayjs(day).format('YYYY/MM/DD');
    return new Date(convertDate);
  };
  const toLastDateLteFilterTerm = (to: Date): FilterTerm => {
    return {
      [column]: {
        $lte: convertToUnixMicro(
          new Date(toStartDate(to)).setDate(to.getDate() + 1)
        )
          .sub(1)
          .toString(),
      },
    };
  };
  const onChangeFromDateState = (date: Date | null) => {
    const range = { from: date, to: dateRangeTo };
    onChangeState(range);
  };

  const onChangeToDateState = (date: Date | null) => {
    const range = { from: dateRangeFrom, to: date };
    onChangeState(range);
  };

  const onChangeState = (range: DateRange | null) => {
    const filterTerms: FilterTerm[] = [];
    range?.from && filterTerms.push(toDateGteFilterTerm(range.from));
    range?.to && filterTerms.push(toLastDateLteFilterTerm(range.to));
    onChangeFilter && onChangeFilter(filterTerms);
  };

  return (
    <div className="put-line">
      <DateSuggest
        name={column + '_from'}
        value={dateRangeFrom}
        columns={[column + '_from']}
        labelId={labelId}
        onChangeState={(v) => {
          setDateRangeFrom(v);
          onChangeFromDateState(v);
        }}
        properties={columnProperty}
        disabled={isDisabledColumn}
      />
      <div className="aid">〜</div>
      <DateSuggest
        name={column + '_to'}
        value={dateRangeTo}
        columns={[column + '_to']}
        onChangeState={(v) => {
          setDateRangeTo(v);
          onChangeToDateState(v);
        }}
        properties={columnProperty}
        disabled={isDisabledColumn}
      />
    </div>
  );
}
