import { Schema } from '~/shared/components/ui';
import { Catalog } from '~/shared/libs/clientsdk';
import type { Field, Type } from 'protobufjs/light';

export function getSchema(fullMethodName: string): Schema {
  const method = Catalog.lookupMethod(fullMethodName);
  if (!method) {
    return [];
  }
  const responseType = method.resolvedResponseType!;
  const estimations = responseType.fields['items'];
  if (!estimations) {
    return [];
  }
  if (!estimations.repeated) {
    throw new Error(
      `the response type ${responseType.fullName}.estimations is not repeated.`
    );
  }

  const type = estimations.resolvedType! as Type;
  return getFlatSchema(type.fieldsArray);
}

export function getSchemaByTypeName(typeName: string): Schema {
  const type = Catalog.lookupType(typeName);
  if (!type) {
    return [];
  }
  return getFlatSchema(type.fieldsArray);
}

const getFlatSchema = (fields: Field[], parent?: string[]) => {
  const schema: Schema = [];
  if (!fields) return schema;

  fields.map((f: Field) => {
    if (f.resolvedType) {
      const fTyp = f.resolvedType! as Type;
      const parentNames: string[] = [];
      if (parent) {
        parentNames.push(...parent);
      }
      parentNames.push(f.name);
      schema.push(...getFlatSchema(fTyp.fieldsArray, parentNames));
    } else {
      const parentNames: string[] = parent ?? [];
      const joinName =
        parentNames.length > 0 ? parentNames.join('.') + '.' + f.name : f.name;
      schema.push({
        name: joinName,
        typeName: f.type,
      });
    }
  });
  return schema;
};
