import { useState } from 'react';
import { FileUploadWithCommentDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleFileManagementDialogs = () => {
  const [result, setResult] = useState('');
  const [isShowDialog, setShowDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">ファイル管理ダイアログ</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setShowDialog(true);
              }}
            />
            <FileUploadWithCommentDialog
              uploaderOption={{
                validateOption: {
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              inputOption={{}}
              displayOption={{
                isSkipCommentArea: true,
                isDnd: true,
              }}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'FILE_MANAGEMENT',
                },
                buttonType: {
                  id: 'save',
                },
                simpleListViewHeader: {
                  prefixId: 'FileManagementDialog',
                  id: 'simpleListViewHeader',
                },
              }}
              isOpen={isShowDialog}
              onDecision={(v) => {
                setResult(`出力しました。${v.comment}${v.files}`);
                setShowDialog(false);
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setShowDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
