import { PropsWithoutRef } from 'react';
import {
  DateSuggest as DateSuggestComponent,
  DatesUnselectedOption,
  DatesValidateOption,
} from '~/shared/components/parts/Dates/Dates';
import { IconButton } from '~/shared/components/ui/Button';
import './base.css';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { convertStringToDate } from '../utils';
import dayjs from 'dayjs';

interface FormInputDateProps {
  name: string;
  label?: string;
  description?: string;
  value?: mtechnavi.api.form.IFormControlDate | null;
  validateOption?: DatesValidateOption;
  unselectedOption?: DatesUnselectedOption;
  disabled?: boolean;
  formatter?: (v: string) => string;
  validator?: (v: string) => string[];
  onChange?: (v: mtechnavi.api.form.IFormControlDate | null) => void;
}
export const FormInputDate = (props: PropsWithoutRef<FormInputDateProps>) => {
  const value = props.value?.date
    ? convertStringToDate(props.value?.date)
    : null;
  const handleChangeValue = (value: Date | null) => {
    if (!props?.onChange) {
      return;
    }
    if (!value) {
      props?.onChange(null);
      return;
    }
    props?.onChange({
      date: dayjs(value).format('YYYY/MM/DD'),
    });
  };
  return (
    <div className="FormInput FormInputDate">
      <span className={props.label !== '' ? 'caption' : 'caption no-name'}>
        {props.label && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label topping-label-with-help ${
                props.disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.label || ''}
              {!!props.description && (
                <IconButton
                  name="help"
                  className="HelpIcon"
                  iconType="help"
                  buttonType="basic"
                  onClick={() => {}}
                  caption={props.description}
                />
              )}
            </div>
          </div>
        )}
      </span>
      <DateSuggestComponent
        name={props.name}
        value={value}
        validator={props.validator}
        formatter={props.formatter}
        validateOption={props.validateOption}
        disabled={!!props.disabled}
        onChangeState={handleChangeValue}
      ></DateSuggestComponent>
    </div>
  );
};
