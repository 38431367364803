import { useEffect, useMemo, useState, useRef } from 'react';
import { Container, error } from '~/shared/components';
import {
  Textbox,
  SimplePageNavigation,
  FILE_VIEWER_LOCALE_STORAGE_PREFIX,
  Textarea,
} from '~/shared/components/ui';
import Long from 'long';

import { PresetItem } from '~/shared/services';
import './BlueprintBlueprintConfirmation.css';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '~/shared/contexts/AuthProvider';
import { useIntl } from 'react-intl';
import {
  ViewId,
  PageState,
  getSortSettingFromLocalStorage,
  getExceptionMessage,
  setLocalStorage,
  openWindowOrTab,
  getDateFormat,
  FullMethodName_ListBlueprints,
  getWorkerExceptionMessage,
  getMaxMainContentsHeight,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

const VIEW_ID: ViewId = 'BLUEPRINT_BLUEPRINT_CONFIRMATION';

export function BlueprintBlueprintConfirmation() {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navi = useNavigate();
  const handleError = useErrorHandler();
  // 遷移元から値を受け取る
  const location = useLocation().state as PageState;

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListBlueprints,
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      blueprintId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('BLUEPRINT_BLUEPRINT_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // 初回画面用データ
  useEffect(() => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListBlueprints,
    });

    // viewIDに紐付くpresetを取得
    (async () => {
      try {
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );

        setPreset(presetItem);

        //単位取得はAPIの実装後
        await window.App.services.programOptionService.listProgramOptions({});
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const [item, setItem] = useState<mtechnavi.api.blueprint.Blueprint>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.blueprint.Blueprint[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListBlueprints,
      pageNumber: n,
    });
  };

  const sizeUnits: sharelib.INameOption[] = useMemo(
    () => window.App.services.ui.getNameOption('A0000027'),
    []
  );
  const toSize = (item: sharelib.IDistantAmount | null | undefined) => {
    if (!item?.integralAmount && !item?.fractionalAmount) {
      return '';
    }
    const integralAmount = item.integralAmount
      ? Long.fromValue(item.integralAmount).toString()
      : '0';
    const fractionalAmount = item.fractionalAmount
      ? `.${String(item.fractionalAmount).padStart(4, '0')}`
      : '';
    let unit = '';
    if (integralAmount !== '0' || !fractionalAmount) {
      unit = getUnit(item.unit);
    }
    return `${integralAmount}${fractionalAmount} ${unit}`;
  };

  const getUnit = (
    distantAmountUnit: sharelib.DistantAmount.Unit | null | undefined
  ): string => {
    const lookupUnit = (name: string): string => {
      const unit = sizeUnits.find(
        (v: sharelib.INameOption) => v.systemName === name
      );
      return unit?.displayNameLang?.ja ?? '';
    };
    switch (distantAmountUnit) {
      case sharelib.DistantAmount.Unit.METER:
        return lookupUnit('B01');
      case sharelib.DistantAmount.Unit.CENTI_METER:
        return lookupUnit('B02');
      case sharelib.DistantAmount.Unit.MILLI_METER:
        return lookupUnit('B03');
      case sharelib.DistantAmount.Unit.MICRO_METER:
        return lookupUnit('B04');
      case sharelib.DistantAmount.Unit.NANO_METER:
        return lookupUnit('B05');
      default:
        return '';
    }
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="BlueprintBlueprintConfirmation">
        <div className="header">
          <SimplePageNavigation
            pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
            handleMovePage={handleMovePage}
          />
        </div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="info">
            <div className="status-box">
              <div className="status-result">
                <Textbox
                  name="statusResult"
                  className="field"
                  disabled={true}
                  type="text"
                  value={
                    item?.blueprintStatus?.status?.displayNameLang?.ja ?? ''
                  }
                  labelId="addStatus"
                  columns={preset.columns}
                />
              </div>
            </div>

            <div className="basic-info-body">
              <div className="inputfields">
                <div className="middle">
                  <Textbox
                    name="blueprintNumber"
                    className="field"
                    value={item?.blueprintNumber ?? ''}
                    type="text"
                    labelId="blueprintNumber"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="branchNumber"
                    value={item?.branchNumber ?? ''}
                    type="text"
                    labelId="branchNumber"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="revision"
                    value={String(item?.revision ?? '')}
                    type="text"
                    labelId="revision"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="middle">
                  <Textbox
                    name="displayNameLang.ja"
                    className="field"
                    value={item?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="blueprintContentType.displayNameLang.ja"
                    value={
                      item?.blueprintContentType?.displayNameLang?.ja ?? ''
                    }
                    type="text"
                    labelId="blueprintContentType.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="category.displayNameLang.ja"
                    value={item?.category?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="category.displayNameLang.ja"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>

                <div className="middle">
                  <Textbox
                    name="designCompany"
                    className="field"
                    value={item?.designCompany ?? ''}
                    type="text"
                    labelId="designCompany"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="confidentialLevel"
                    className="field"
                    value={item?.confidentialLevel ?? ''}
                    type="text"
                    labelId="confidentialLevel"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.hasCadData"
                    value={
                      item?.partDrawingProperties?.hasCadData ? '○' : '-' ?? '-'
                    }
                    type="text"
                    labelId="partDrawingProperties.hasCadData"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="inputfields">
                <div className="middle">
                  <Textbox
                    name="partDrawingProperties.categorys1"
                    className="field"
                    value={item?.partDrawingProperties?.categorys![0] ?? ''}
                    type="text"
                    labelId="partDrawingProperties.categorys1"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.categorys2"
                    className="field"
                    value={item?.partDrawingProperties?.categorys![1] ?? ''}
                    type="text"
                    labelId="partDrawingProperties.categorys2"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="middle">
                  <Textbox
                    name="partDrawingProperties.categorys3"
                    className="field"
                    value={item?.partDrawingProperties?.categorys![2] ?? ''}
                    type="text"
                    labelId="partDrawingProperties.categorys3"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.categorys4"
                    className="field"
                    value={item?.partDrawingProperties?.categorys![3] ?? ''}
                    type="text"
                    labelId="partDrawingProperties.categorys4"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="middle">
                  <Textbox
                    name="partDrawingProperties.categorys5"
                    className="field"
                    value={item?.partDrawingProperties?.categorys![4] ?? ''}
                    type="text"
                    labelId="partDrawingProperties.categorys5"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.categorys6"
                    className="field"
                    value={item?.partDrawingProperties?.categorys![5] ?? ''}
                    type="text"
                    labelId="partDrawingProperties.categorys6"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="inputfields">
                <div className="middle">
                  <Textbox
                    name="partDrawingProperties.equipment"
                    className="field"
                    value={item?.partDrawingProperties?.equipment ?? ''}
                    type="text"
                    labelId="partDrawingProperties.equipment"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.dimension.x.fractionalAmount"
                    className="field"
                    value={toSize(item?.partDrawingProperties?.dimension?.x)}
                    type="text"
                    labelId="partDrawingProperties.dimension.x.fractionalAmount"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.dimension.y.fractionalAmount"
                    className="field"
                    value={toSize(item?.partDrawingProperties?.dimension?.y)}
                    type="text"
                    labelId="partDrawingProperties.dimension.y.fractionalAmount"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.dimension.z.fractionalAmount"
                    className="field"
                    value={toSize(item?.partDrawingProperties?.dimension?.z)}
                    type="text"
                    labelId="partDrawingProperties.dimension.z.fractionalAmount"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.diameter.fractionalAmount"
                    className="field"
                    value={toSize(item?.partDrawingProperties?.diameter)}
                    type="text"
                    labelId="partDrawingProperties.diameter.fractionalAmount"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="middle">
                  <Textbox
                    name="partDrawingProperties.shape"
                    className="field"
                    value={item?.partDrawingProperties?.shape ?? ''}
                    type="text"
                    labelId="partDrawingProperties.shape"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.material"
                    className="field"
                    value={item?.partDrawingProperties?.material ?? ''}
                    type="text"
                    labelId="partDrawingProperties.material"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.surfaceTreatment"
                    className="field"
                    value={
                      item?.partDrawingProperties?.surfaceTreatment ? '○' : '-'
                    }
                    type="text"
                    labelId="partDrawingProperties.surfaceTreatment"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.requestHardness"
                    className="field"
                    value={item?.partDrawingProperties?.requestHardness ?? ''}
                    type="text"
                    labelId="partDrawingProperties.requestHardness"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="partDrawingProperties.inspection"
                    className="field"
                    value={item?.partDrawingProperties?.inspection ? '○' : '-'}
                    type="text"
                    labelId="partDrawingProperties.inspection"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="inputfields">
                <div className="long">
                  <Textarea
                    name="remarks"
                    className="field"
                    value={item?.remarks ?? ''}
                    labelId="remarks"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="inputfields">
                <div className="middle">
                  <Textbox
                    name="revisionProperties.revisedAt"
                    className="field"
                    value={
                      getDateFormat(
                        item?.revisionProperties?.revisedAt as Long,
                        'YYYY/MM/DD HH:mm:ss'
                      ) ?? ''
                    }
                    type="text"
                    labelId="revisionProperties.revisedAt"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="revisionProperties.managementNumber"
                    className="field"
                    value={String(
                      item?.revisionProperties?.managementNumber ?? ''
                    )}
                    type="text"
                    labelId="revisionProperties.managementNumber"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="revisionProperties.comment"
                    className="field"
                    value={item?.revisionProperties?.comment ?? ''}
                    type="text"
                    labelId="revisionProperties.comment"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="middle">
                  <Textbox
                    name="revisionProperties.organizationUnit"
                    className="field"
                    value={
                      item?.revisionProperties?.organizationUnit
                        ?.displayNameLang?.ja ?? ''
                    }
                    type="text"
                    labelId="revisionProperties.organizationUnit"
                    columns={preset.columns}
                    disabled={true}
                  />
                  <Textbox
                    name="revisionProperties.staff"
                    className="field"
                    value={
                      item?.revisionProperties?.staff?.displayNameLang?.ja ?? ''
                    }
                    type="text"
                    labelId="revisionProperties.staff"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="blockBtn">
              <CaptionButton
                className="button-margin"
                name=""
                caption="戻る"
                properties={[]}
                onClick={() => {
                  navi('/blueprint-blueprint-list');
                }}
                buttonType="basic"
              />
              <CaptionButton
                className="button-margin"
                name=""
                caption="図面ビューワー"
                properties={[]}
                onClick={() => {
                  const key = setLocalStorage(
                    FILE_VIEWER_LOCALE_STORAGE_PREFIX,
                    [item?.blueprintFile?.assetId ?? '']
                  );
                  openWindowOrTab('file-viewer', key);
                }}
                buttonType="basic"
              />
            </div>
            <SimplePageNavigation
              pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
              handleMovePage={handleMovePage}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
