import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { FlagSettingDialog } from '~/shared/components/ui/Dialog/FlagSettingDialog';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';

export const UISampleFlagSettingDialogs = () => {
  const [resultConfirm, setResultConfirm] = useState('');
  const [isShowFlagSettingDialog, setShowFlagSettingDialog] = useState(false);

  const flagProperties: mtechnavi.api.survey.IFlagProperties = {
    active: true,
    comment: '確認用',
    updatedProperties: {
      updatedAt: '1712620690606927' as unknown,
      updatedBy: {
        userId: 'testId-1',
        email: 'debug@mail.xxx',
        displayName: '確認用ユーザー',
      } as sharelib.IEmbeddedUser,
    } as sharelib.IEmbeddedUpdatedProperties,
  };

  return (
    <div className="categories">
      <h3 is="dialog">フラグダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResultConfirm('');
                setShowFlagSettingDialog(true);
              }}
            />
            <FlagSettingDialog
              isOpen={isShowFlagSettingDialog}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'FlagSetting',
                },
              }}
              inputOption={{ flagProperties: flagProperties }}
              onCancel={() => {
                setResultConfirm('キャンセルしました');
                setShowFlagSettingDialog(false);
              }}
              onDecision={(v) => {
                setResultConfirm(
                  `出力しました。${JSON.stringify(
                    v,
                    function (key, value) {
                      if (this[key] instanceof Date) {
                        return this[key].toString();
                      }
                      return value;
                    },
                    2
                  )}`
                );
                setShowFlagSettingDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{resultConfirm}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
