import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  ConnectingDialogOutputOption,
  ConnectingType,
  WorkTaskConnectingDialog,
} from '~/shared/components/ui/Dialog/WorkTaskConnectingDialog';
import { SelectConnectingDialog } from '~/shared/components/ui/Dialog/SelectConnectingDialog';

export const UISampleSelectConnectingDialogs = () => {
  const [result, setResult] = useState('');
  const [isShowDialog, setShowDialog] = useState(false);
  const [connectResult, setConnectResult] = useState<
    ConnectingDialogOutputOption[]
  >([]);
  const [isShowConnectDialog, setShowConnectDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">紐付け分類ダイアログ（紐付けダイアログ）</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setShowDialog(true);
              }}
            />
            <SelectConnectingDialog
              isOpen={isShowDialog}
              onDecision={(v) => {
                setResult(v);
                setShowDialog(false);
                setShowConnectDialog(true);
              }}
              onCancel={() => {
                setResult('');
                setShowDialog(false);
              }}
            />
            <div className="ConnectingDialog">
              <WorkTaskConnectingDialog
                connectingType={result as ConnectingType}
                orderNumber="12345"
                isOpen={isShowConnectDialog}
                onDecision={(result) => {
                  setConnectResult(result);
                  setShowConnectDialog(false);
                }}
                onCancel={() => {
                  setConnectResult([]);
                  setShowConnectDialog(false);
                }}
              />
            </div>
          </div>
          <div className="w-66">
            <pre className="output">
              {connectResult && JSON.stringify(connectResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
