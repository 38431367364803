import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Checkbox,
  DataFilterbox,
  DataFilterboxItem,
  Textarea,
} from '~/shared/components/ui';
import { useIntl } from 'react-intl';
import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { getProgramOptionFilterboxData } from '~/shared/utils/converter';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { includeInputValidateError } from '~/shared/utils';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
export type ManualReminderDialogMode = 'send' | 'save';

export interface ManualReminderDialogInputOption {
  modeType?: ManualReminderDialogMode;
}

export interface ManualReminderDialogOutputOption {
  reminderMessageType: sharelib.INameOption | null;
  reminderMessage: string;
  sendTarget: mtechnavi.api.survey.ISendTargetSetting;
}
export interface ManualReminderDialogProps {
  isOpen: boolean;
  onDecision: (v: ManualReminderDialogOutputOption) => void;
  onCancel: () => void;
  isClickableState: boolean;
  onChangeClickableState: (isClickable: boolean) => void;
  inputOption?: ManualReminderDialogInputOption;
}

const MessageTypeCode = 'A1000003';
const InitialMessageCode = 'A1000004';

export const ManualReminderDialog = (props: ManualReminderDialogProps) => {
  const intl = useIntl();
  const [messageTypes, initialMessage] = useMemo<
    [DataFilterboxItem[], DataFilterboxItem[]]
  >(() => {
    const messageTypes = getProgramOptionFilterboxData(MessageTypeCode);
    const initialMessage = getProgramOptionFilterboxData(InitialMessageCode);
    return [messageTypes, initialMessage];
  }, []);
  const [reminderMessageType, setMessageType] = useState<DataFilterboxItem[]>(
    []
  );
  const [reminderMessage, setReminderMessage] = useState<string>('');
  const { isOpen } = props;
  const [isSendConfirmOpen, setSendConfirmOpen] = useState(false);
  const [sendTargetBusinessUnit, setSendTargetBusinessUnit] = useState<
    string[]
  >(['1']);
  const [sendTargetResponsibleUser, setSendTargetResponsibleUser] = useState<
    string[]
  >(['1']);
  const [sendTargetMainContact, setSendTargetMainContact] = useState<string[]>(
    []
  );

  const inputAreaRef = useRef(null);

  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };

  const handleCancel = () => {
    props.onCancel();
  };

  useEffect(() => {
    props.onChangeClickableState(true);
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleDecision = () => {
    props.onDecision({
      reminderMessageType:
        reminderMessageType.length > 0
          ? window.App.services.ui.getNameOptionWithCode(
              MessageTypeCode,
              reminderMessageType[0].value
            )
          : null,
      reminderMessage,
      sendTarget: {
        activeBusinessUnit:
          sendTargetBusinessUnit.length > 0 && sendTargetBusinessUnit[0] === '1'
            ? true
            : false,
        activeResponsibleUser:
          sendTargetResponsibleUser.length > 0 &&
          sendTargetResponsibleUser[0] === '1'
            ? true
            : false,
        activeMainContact:
          sendTargetMainContact.length > 0 && sendTargetMainContact[0] === '1'
            ? true
            : false,
      },
    });
    setSendConfirmOpen(false);
  };

  const handleChangeMessageType = (v: DataFilterboxItem[]) => {
    setMessageType(v);
    if (v.length === 0) {
      return;
    }
    const message = initialMessage.find((msg) => msg.value === v[0].value);
    if (!message) {
      return;
    }
    setReminderMessage(message.displayName);
  };

  const resetForm = () => {
    setMessageType([]);
    setReminderMessage('');
    setSendTargetMainContact([]);
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.manual-reminder-dialog');
    const validateInputs = [
      {
        value: reminderMessage ?? '',
        ref: inputAreaRef,
      },
    ];
    return includeInputValidateError(targetElm, intl, validateInputs);
  };

  useEffect(() => {
    resetForm();
  }, [props.isOpen]);

  const elements = (
    <div className="manual-reminder-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'manual_reminder',
            })}
          </span>
        </p>
      </div>
      <div className="detail-area" ref={inputAreaRef}>
        <div className="input-line">
          <div className="item-group-100">
            <DataFilterbox
              data={messageTypes}
              name="reminderMessageType"
              labelId="manualReminderDialog.reminder_message_type"
              columns={['reminderMessageType']}
              searchOption={{ targets: 'displayName' }}
              onChangeState={handleChangeMessageType}
            />
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="comment"
                labelId="manualReminderDialog.reminder_message"
                validateOption={{ required: true }}
                value={reminderMessage}
                columns={['comment']}
                className="w-100 mh-middle"
                onChangeState={setReminderMessage}
              />
            </div>
          </div>
        </div>
        <p className="send-target-label">
          {GetMessage({
            prefixId: 'manualReminderDialog',
            id: 'sendTargetLabel',
          })}
        </p>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25 send-target-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'manualReminderDialog',
                      id: 'sendTarget.businessUnit',
                    }),
                  },
                ]}
                value={sendTargetBusinessUnit}
                onChangeState={setSendTargetBusinessUnit}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-33 send-target-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'manualReminderDialog',
                      id: 'sendTarget.responsibleUser',
                    }),
                  },
                ]}
                value={sendTargetResponsibleUser}
                onChangeState={setSendTargetResponsibleUser}
                columns={['checkbox']}
                disabled={true}
              ></Checkbox>
            </div>
            <div className="w-30 send-target-checkbox">
              <Checkbox
                name="checkbox"
                className="group"
                items={[
                  {
                    value: '1',
                    displayName: GetMessage({
                      prefixId: 'manualReminderDialog',
                      id: 'sendTarget.mainContact',
                    }),
                  },
                ]}
                value={sendTargetMainContact}
                onChangeState={setSendTargetMainContact}
                columns={['checkbox']}
              ></Checkbox>
            </div>
          </div>
        </div>
        <div className="button-area top-space-10rem">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => {
              handleCancel();
            }}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="high"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'send' })}
            onClick={() => {
              props.onChangeClickableState(false);
              if (isInputError()) {
                props.onChangeClickableState(true);
                return;
              }

              if (props.inputOption?.modeType === 'send') {
                setSendConfirmOpen(true);
              } else {
                handleDecision();
              }
            }}
            disabled={!props.isClickableState}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id: 'MANUAL_REMINDER',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
      {/* 送信 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSendConfirmOpen}
        viewMessage={sendMessage}
        onDecision={() => {
          handleDecision();
        }}
        onCancel={() => {
          props.onChangeClickableState(true);
          setSendConfirmOpen(false);
        }}
      />
    </>
  );
};
