import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { File as FilePage } from './pages/File';
import { List as ListPage } from './pages/List';
import { FileComment as FileCommentPage } from './pages/FileComment';
import { UISample as UISamplePage } from './pages/UISample';
import { DialogSample as DialogSamplePage } from './pages/DialogSample';
import { Dialog as DialogPage } from './pages/Dialog';
import { MasterPostUnitSearch as MasterPostUnitSearchPage } from './pages/MasterPostUnitSearch';
import { TransactionUnitSearchList as TransactionUnitSearchListPage } from './pages/TransactionUnitSearchList';
import { BlueprintBlueprintSearchList as BlueprintBlueprintSearchListPage } from './pages/BlueprintBlueprintSearchList';
import { CategorizedCheckSample as CategorizedCheckSamplePage } from './pages/CategorizedCheckSample';
import { OrganizationTreePage } from './pages/OrganizationTree/OrganizationTreePage';

export const DebugRoutes = () => {
  return (
    <Routes>
      <Route
        path="/categorized-checks"
        element={
          <RequireAuth>
            <CategorizedCheckSamplePage />
          </RequireAuth>
        }
      />
      <Route
        path="/file"
        element={
          <RequireAuth>
            <FilePage />
          </RequireAuth>
        }
      />
      <Route
        path="/ui-sample"
        element={
          <RequireAuth>
            <UISamplePage />
          </RequireAuth>
        }
      />
      <Route
        path="/dialog-sample"
        element={
          <RequireAuth>
            <DialogSamplePage />
          </RequireAuth>
        }
      />
      <Route
        path="/file-comment"
        element={
          <RequireAuth>
            <FileCommentPage />
          </RequireAuth>
        }
      />
      <Route
        path="/list"
        element={
          <RequireAuth>
            <ListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/dialog"
        element={
          <RequireAuth>
            <DialogPage />
          </RequireAuth>
        }
      />
      <Route
        path="/master-post-unit-search"
        element={
          <RequireAuth>
            <MasterPostUnitSearchPage />
          </RequireAuth>
        }
      />
      <Route
        path="/transaction-unit-search-list"
        element={
          <RequireAuth>
            <TransactionUnitSearchListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/blueprint-blueprint-search-list"
        element={
          <RequireAuth>
            <BlueprintBlueprintSearchListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/org-tree"
        element={
          <RequireAuth>
            <OrganizationTreePage />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
