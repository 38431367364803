import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import './DetailInputDialog.css';
import { Textbox } from '../Textbox';
import { Checkbox } from '../Checkbox';
import { Amountbox } from '../Amountbox';
import { useEffect, useMemo, useState, useRef } from 'react';
import {
  FullMethodName_ListTransactionUnits,
  FullMethodName_ListBlueprints,
  FullMethodName_ListAssets,
  getExceptionMessage,
  getProgramOptionFilterboxData,
  openWindowOrTab,
  setLocalStorage,
  saveInitialLocalStorageData,
  handleFIleUpload,
  includeInputValidateError,
} from '~/shared/utils';
import {
  convertDatetime,
  convertDate,
  convertStringToAmount,
  convertAmountToString,
  convertLongToString,
} from '~/shared/utils/converter';
import { FilterRequest } from '~/worker';
import {
  DataFilterbox,
  Filterbox,
  DataFilterboxItem,
  FilterboxItem,
} from '../Filterbox';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { IconButton } from '../Button';
import { Textarea } from '../Textarea';
import {
  Schema,
  getAltDisplaySchema,
  MenuActionItem,
  ViewMenu,
  FILE_VIEWER_LOCALE_STORAGE_PREFIX,
  getDayFormetterDisplaySchema,
  FileUploadDialog,
  SuppliedRegistrationDialog,
} from '~/shared/components/ui';
import { SearchListViewDialog } from '~/shared/components/ui/SearchListViewDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';

const UNIT_CATEGORY_NAME = 'A0000005';
const PROCESS_CATEGORY_NAME = 'A0000006';
const TRANSACTION_UNIT_ITEM_TYPE = {
  value: 'transactionUnitId',
  displayName: 'displayNameLang',
};
export type InputMode = 'new' | 'edit';
export interface DetailInputDialogInputOption {
  inputMode: InputMode;
  detailItem: EstimateDetailInfo;
}

export interface EstimateDetailInfo
  extends mtechnavi.api.estimation.IEstimateRequestDetail {
  filterboxItemTransactionUnit?: FilterboxItem; // 品目
  filterboxItemQuantityUnit?: DataFilterboxItem[]; // 数量/単位
  filterboxItemProcesss?: DataFilterboxItem[]; // 工程
}

interface DetailoInputDialogProps {
  isOpen: boolean;
  inputOption: DetailInputDialogInputOption;
  onDecision: (result: EstimateDetailInfo) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}
export const DetailInputDialog = ({
  isOpen,
  inputOption,
  onDecision,
  onCancel,
  onChangeLoadingState,
}: DetailoInputDialogProps) => {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const [isLoading, setLoading] = useState(false);

  // 活性非活性フラグ
  const [isSearchBlueprintDisable, setSearchBlueprintDisable] =
    useState<boolean>(true); // 図番検索アイコン
  const [isAttachmentDisable, setAttachmentDisable] = useState<boolean>(false); // ファイル添付アイコン
  const [isFileDeleteDisable, setFileDeleteDisable] = useState<boolean>(false); // ファイル削除アイコン

  const [detailInfo, setDetailInfo] = useState<EstimateDetailInfo>({});

  // 必須チェック
  const requiredQuantity = useRef(null);
  const [workingBlurQuantityUnit, setWorkingBlurQuantityUnit] =
    useState<Date>();

  /** ダイアログオープン系 ここから */
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);

  const [
    isOpenSearchTransactionUnitsDialog,
    setOpenSearchTransactionUnitsDialog,
  ] = useState(false);
  const [isOpenSearchBlueprintDialog, setOpenSearchBlueprintDialog] =
    useState(false);
  const [isOpenSuppliedRegistrationDialog, setOpenSuppliedRegistrationDialog] =
    useState(false);
  /** ダイアログオープン系 ここまで */

  const [unitCategorys, processCategorys] = useMemo(() => {
    const facilityCategorys = getProgramOptionFilterboxData(UNIT_CATEGORY_NAME);
    const accuracyList = getProgramOptionFilterboxData(PROCESS_CATEGORY_NAME);
    return [facilityCategorys, accuracyList];
  }, []);

  // 初期表示
  useEffect(() => {
    // 新規
    if (inputOption.inputMode === 'new') {
      setSearchBlueprintDisable(true);
      setAttachmentDisable(false);
      setFileDeleteDisable(true);
    } else {
      // 図番がNULLの場合
      if (!inputOption.detailItem.blueprint?.blueprintNumber) {
        setSearchBlueprintDisable(true);
        setAttachmentDisable(false);
        if (inputOption.detailItem.attachment?.filename) {
          setFileDeleteDisable(false);
        } else {
          setFileDeleteDisable(true);
        }
      } else {
        setSearchBlueprintDisable(false);
        setAttachmentDisable(true);
        setFileDeleteDisable(true);
      }
    }
    setDetailInfo(inputOption.detailItem);
    setWorkingBlurQuantityUnit(undefined);
    // ダイアログオープン時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // アセット変更時
  useEffect(() => {
    if (
      !detailInfo.blueprint?.blueprintNumber &&
      detailInfo.attachment?.assetId
    ) {
      setFileDeleteDisable(false);
    } else {
      setFileDeleteDisable(true);
    }
    // assetId 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailInfo.attachment?.assetId]);

  const handleCancel = () => {
    onCancel();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    // 図番がNULLでない、かつ、更新番号がNULLの場合
    if (
      !detailInfo.blueprint?.blueprintNumber &&
      detailInfo.blueprint?.revision
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000036' })]);
      return;
    }
    onDecision(detailInfo);
  };

  // エラーチェック
  const isInputError = (): boolean => {
    setWorkingBlurQuantityUnit(new Date());

    const inputValidationCheckList = [
      {
        value: detailInfo.quantity?.amountNum ?? '',
        ref: requiredQuantity,
      },
      {
        value:
          detailInfo.filterboxItemQuantityUnit &&
          detailInfo.filterboxItemQuantityUnit.length
            ? detailInfo.filterboxItemQuantityUnit[0].value
            : '',
        ref: requiredQuantity,
      },
    ];

    const targetElm = document.querySelector('.detail-input-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  // 図番検索ダイアログのフィルター用クエリ作成
  function getBlueprintQuery(blueprintNumber: string, branchNumber: string) {
    return {
      type: 'and',
      info: [
        {
          targetKey: 'blueprintNumber',
          targetValue: blueprintNumber,
        },
        {
          targetKey: 'branchNumber',
          targetValue: branchNumber,
        },
      ],
    };
  }

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        handleSeachBlueprintViewer(v!);
      },
    });
    return menuItems;
  };

  const searchBlueprintBurgerMenuEvent = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  // ビューワー処理
  const handleSeachBlueprintViewer = async (v: string[]) => {
    setLoading(true);
    // blueprintIDを元にassetIDを取得
    const option: FilterRequest = {
      action: 'query',
      fullMethodName: FullMethodName_ListBlueprints,
      filter: {
        blueprintId: {
          $in: v,
        },
      },
      sort: [],
    };
    const bluePrint = (await window.App.services.ui.worker.filter(
      option
    )) as mtechnavi.api.blueprint.ListBlueprintsResponse;
    const assetIds = bluePrint.items.map((v) => v.blueprintFile?.assetId);
    const key = setLocalStorage(
      FILE_VIEWER_LOCALE_STORAGE_PREFIX,
      (assetIds ?? []) as string[]
    );
    setLoading(false);
    openWindowOrTab('file-viewer', key);
  };
  const handleFileViewer = (assetId: string) => {
    const key = setLocalStorage(FILE_VIEWER_LOCALE_STORAGE_PREFIX, [assetId]);
    openWindowOrTab('file-viewer', key);
  };
  //Loading制御
  useEffect(() => {
    onChangeLoadingState && onChangeLoadingState(isLoading);
    // Loading関連（isLoading）変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const elements = (
    <div className="detail-input-dialog">
      <div className="detail-area">
        <p className="detail-number">
          {GetMessageWithIntl(intl, {
            id: 'detailNumber',
            prefixId: 'detailInputDialog',
          })}
          {convertLongToString(detailInfo.detailAutoName)}
        </p>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25">
              <div className="input-line no-space">
                <div className="item-group-100 no-space">
                  {/* 品目 */}
                  <Filterbox
                    labelId="detailInputDialog.transactionUnit"
                    name="transactionUnit"
                    fullMethodName={FullMethodName_ListTransactionUnits}
                    itemType={TRANSACTION_UNIT_ITEM_TYPE}
                    searchOption={{
                      targets: 'displayName',
                      isLatestData: true,
                    }}
                    columns={['transactionUnit']}
                    value={
                      detailInfo.filterboxItemTransactionUnit
                        ? [detailInfo.filterboxItemTransactionUnit]
                        : []
                    }
                    displayOption={{
                      displayedItems: 'displayName',
                      pageSize: 5,
                      sort: [
                        {
                          ['displayNameLang.ja']: 'asc',
                        },
                      ],
                    }}
                    onChangeState={async (v: FilterboxItem[]) => {
                      const target = v.shift();
                      try {
                        const result =
                          (await window.App.services.ui.worker.filter({
                            action: 'query',
                            fullMethodName: FullMethodName_ListTransactionUnits,
                            filter: {
                              transactionUnitId: {
                                $eq: target?.value ?? '',
                              },
                            },
                            sort: [],
                          })) as mtechnavi.api.blueprint.ListTransactionUnitsResponse;
                        const item = result.items.shift() ?? {};
                        const prosessList = item.processs?.map((v) => v.code);
                        setDetailInfo({
                          ...detailInfo,
                          transactionUnit: {
                            ...item,
                            displayNameLang: item.displayNameLang?.ja,
                          },
                          filterboxItemTransactionUnit: target,
                          filterboxItemQuantityUnit:
                            unitCategorys.filter(
                              (v) => v.value === item.quantityUnit?.code
                            ) ?? [],
                          quantity: {
                            ...detailInfo.quantity,
                            unit: item.quantityUnit?.code
                              ? window.App.services.ui.getNameOptionWithCode(
                                  'A0000005',
                                  item.quantityUnit?.code ?? ''
                                )
                              : {},
                          },
                          displayName: item.displayNameLang?.ja ?? '',
                          blueprint: {
                            ...item.blueprintReference,
                            revision: null,
                          },
                          filterboxItemProcesss: processCategorys.filter((v) =>
                            prosessList?.includes(v.value)
                          ),
                          processs: item.processs ?? [],
                          modelNumber: item.catalogReference?.modelNumber ?? '',
                          manufacture: item.catalogReference?.manufacture ?? '',
                          contactMatter: item.contactMatter ?? '',
                          attachment: undefined,
                        });
                        if (!item.blueprintReference?.blueprintNumber) {
                          setSearchBlueprintDisable(true);
                          setAttachmentDisable(false);
                        } else {
                          setSearchBlueprintDisable(false);
                          setAttachmentDisable(true);
                        }
                        setFileDeleteDisable(true);
                        //  選択情報を入力項目にセット
                      } catch (err) {
                        error(getExceptionMessage(intl, err));
                        throw err;
                      }
                    }}
                    formatOption={{ mapKeyValue: 'ja' }}
                  />
                  <div className="w-16">
                    <div className="side-search-icon">
                      {/* アイコン */}
                      <IconButton
                        name="searchTransactionDialog"
                        iconType="search"
                        caption="検索"
                        buttonType="basic"
                        onClick={() => {
                          saveInitialLocalStorageData(
                            'MASTER_TRANSACTION_UNIT_SEARCH',
                            myEmail
                          );
                          setOpenSearchTransactionUnitsDialog(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-25">
              {/* 品目名 */}
              <Textbox
                name="transactionUnit.displayName"
                type="text"
                labelId="detailInputDialog.transactionUnit.displayName"
                columns={['transactionUnit.displayName']}
                value={detailInfo.displayName}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    displayName: v,
                  })
                }
              />
            </div>
            <div className="w-25" ref={requiredQuantity}>
              <div className="input-line no-space">
                <div className="item-group-100 no-space">
                  {/* 数量 */}
                  <Amountbox
                    name="quantityFractionalAmount"
                    labelId="detailInputDialog.quantityFractionalAmount"
                    columns={['quantityFractionalAmount']}
                    displayOption={{
                      digits: 4,
                      isCommaFormat: true,
                    }}
                    validateOption={{ required: true }}
                    value={
                      detailInfo.quantity?.amountNum
                        ? convertStringToAmount(detailInfo.quantity?.amountNum)
                        : {}
                    }
                    onChangeState={(v) =>
                      setDetailInfo({
                        ...detailInfo,
                        quantity: {
                          ...detailInfo.quantity,
                          amountNum: convertAmountToString(v),
                        },
                      })
                    }
                  />
                  {/* 単位 */}
                  <DataFilterbox
                    labelId="detailInputDialog.quantityUnit.displayNameLang"
                    data={unitCategorys}
                    searchOption={{ targets: 'displayName' }}
                    name="quantityUnit.displayNameLang"
                    columns={['quantityUnit.displayNameLang']}
                    validateOption={{ required: true }}
                    value={detailInfo.filterboxItemQuantityUnit}
                    onChangeState={(v) =>
                      setDetailInfo({
                        ...detailInfo,
                        filterboxItemQuantityUnit: v,
                        quantity: {
                          ...detailInfo.quantity,
                          unit: v
                            ? window.App.services.ui.getNameOptionWithCode(
                                'A0000005',
                                v[0].value ?? ''
                              )
                            : {},
                        },
                      })
                    }
                    workingBlur={workingBlurQuantityUnit}
                  />
                </div>
              </div>
            </div>
            <div className="w-25 mt-13">
              {/* 検査証必要 */}
              <Checkbox
                name="inspection"
                items={[
                  {
                    value: '1',
                    displayName: GetMessageWithIntl(intl, {
                      prefixId: 'detailInputDialog',
                      id: 'inspection',
                    }),
                  },
                ]}
                columns={['inspection']}
                value={detailInfo.inspection ? ['1'] : []}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    inspection: v ? v[0] === '1' : false,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <div className="input-line no-space">
                <div className="item-group-100 no-space">
                  <div className="w-50">
                    {/* 図番 */}
                    <Textbox
                      name="blueprintNumber"
                      type="text"
                      disabled={true}
                      value={detailInfo.blueprint?.blueprintNumber}
                      labelId="detailInputDialog.blueprintNumber"
                      columns={['blueprintNumber']}
                    />
                  </div>
                  <div className="w-25">
                    {/* 枝番 */}
                    <Textbox
                      name="branchNumber"
                      type="text"
                      disabled={true}
                      value={detailInfo.blueprint?.branchNumber}
                      labelId="detailInputDialog.branchNumber"
                      columns={['branchNumber']}
                    />
                  </div>
                  <div className="w-25">
                    {/* 更新番号 */}
                    <Textbox
                      name="revision"
                      type="text"
                      disabled={true}
                      value={
                        detailInfo.blueprint?.revision
                          ? detailInfo.blueprint?.revision.toString()
                          : ''
                      }
                      labelId="detailInputDialog.revision"
                      columns={['revision']}
                    />
                  </div>
                  <div className="side-search-icon">
                    {/* アイコン */}
                    <IconButton
                      name="searchTransactionDialog"
                      iconType="search"
                      caption="検索"
                      buttonType="basic"
                      onClick={() => {
                        saveInitialLocalStorageData(
                          'BLUEPRINT_BLUEPRINT_SEARCH',
                          myEmail
                        );
                        setOpenSearchBlueprintDialog(true);
                      }}
                      disabled={isSearchBlueprintDisable}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="input-line no-space">
                <div className="item-group-100 no-space file-line">
                  <div className="w-100">
                    {/* ファイル添付 */}
                    <CaptionButton
                      name={'supply'}
                      caption={GetMessageWithIntl(intl, {
                        id: 'attachment.upload',
                        prefixId: 'detailInputDialog',
                      })}
                      buttonType="basic"
                      onClick={() => {
                        setOpenFileUploadDialog(true);
                      }}
                      disabled={isAttachmentDisable}
                    />
                    <IconButton
                      name="fileDelete"
                      className="btn btn-normal gap"
                      properties={[
                        {
                          name: 'fileDelete',
                          propertyName: 'fileDelete',
                          propertyValue: 'fileDelete',
                        },
                      ]}
                      onClick={() => {
                        setDetailInfo({
                          ...detailInfo,
                          ...{ attachment: undefined },
                        });
                      }}
                      iconType="delete"
                      buttonType="danger"
                      disabled={isFileDeleteDisable}
                    />
                    <a
                      onClick={() => {
                        if (detailInfo.attachment?.assetId) {
                          handleFileViewer(detailInfo.attachment?.assetId);
                        }
                      }}
                      className="fileName gap"
                    >
                      {detailInfo.attachment?.filename ?? ''}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              {/* 工程 */}
              <DataFilterbox
                labelId="detailInputDialog.process"
                data={processCategorys}
                multiple={true}
                searchOption={{ targets: 'displayName' }}
                name="process"
                columns={['process']}
                value={detailInfo.filterboxItemProcesss}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    filterboxItemProcesss: v,
                    processs: v.map((w) => {
                      return window.App.services.ui.getNameOptionWithCode(
                        'A0000006',
                        w.value ?? ''
                      );
                    }),
                  })
                }
              />
            </div>
            <div className="w-25">
              {/* 型式 */}
              <Textbox
                name="transactionUnitNumber"
                type="text"
                labelId="detailInputDialog.transactionUnitNumber"
                columns={['transactionUnitNumber']}
                value={detailInfo.modelNumber}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    modelNumber: v,
                  })
                }
              />
            </div>
            <div className="w-25">
              {/* メーカー */}
              <Textbox
                name="manufacturer"
                type="text"
                labelId="detailInputDialog.manufacturer"
                columns={['manufacturer']}
                value={detailInfo.manufacture}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    manufacture: v,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              {/* 連絡事項 */}
              <Textarea
                name="contactMatter"
                labelId="detailInputDialog.contactMatter"
                columns={['contactMatter']}
                className="w-100 mh-middle"
                value={detailInfo.contactMatter ?? ''}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    contactMatter: v,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* 支給品 */}
              <CaptionButton
                name={'supply'}
                caption={GetMessageWithIntl(intl, {
                  id: 'supply',
                  prefixId: 'detailInputDialog',
                })}
                buttonType="basic"
                onClick={() => {
                  setOpenSuppliedRegistrationDialog(true);
                }}
              />
            </div>
            <div className="w-25 mt-06">
              {/* 支給品有り */}
              <Checkbox
                name="suppliedParts"
                labelId=""
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'suppliedParts',
                      prefixId: 'detailInputDialog',
                    }),
                    value: '1',
                  },
                ]}
                columns={['suppliedParts']}
                value={detailInfo.suppliedParts ? ['1'] : []}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            {/* 必須回答費目ラベル */}
            <p>
              {GetMessageWithIntl(intl, {
                id: 'requiredAnswerItem',
                prefixId: 'detailInputDialog',
              })}
            </p>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            {/* 必須回答費目ラベル */}
            <div className="w-20">
              <Checkbox
                name="requiredAnswerItem.materialFee"
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'requiredAnswerItem.materialFee',
                      prefixId: 'detailInputDialog',
                    }),
                    value: '1',
                  },
                ]}
                columns={['requiredAnswerItem.materialFee']}
                value={detailInfo.requiredMaterialCost ? ['1'] : []}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    requiredMaterialCost: v ? v[0] === '1' : false,
                  })
                }
              />
            </div>
            <div className="w-20">
              <Checkbox
                name="requiredAnswerItem.processingFee"
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'requiredAnswerItem.processingFee',
                      prefixId: 'detailInputDialog',
                    }),
                    value: '1',
                  },
                ]}
                columns={['requiredAnswerItem.processingFee']}
                value={detailInfo.requiredProcessingCost ? ['1'] : []}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    requiredProcessingCost: v ? v[0] === '1' : false,
                  })
                }
              />
            </div>
            <div className="w-20">
              <Checkbox
                name="requiredAnswerItem.managementFee"
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'requiredAnswerItem.managementFee',
                      prefixId: 'detailInputDialog',
                    }),
                    value: '1',
                  },
                ]}
                columns={['requiredAnswerItem.managementFee']}
                value={detailInfo.requiredAdministrativeCost ? ['1'] : []}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    requiredAdministrativeCost: v ? v[0] === '1' : false,
                  })
                }
              />
            </div>
            <div className="w-20">
              <Checkbox
                name="requiredAnswerItem.transportationFee"
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'requiredAnswerItem.transportationFee',
                      prefixId: 'detailInputDialog',
                    }),
                    value: '1',
                  },
                ]}
                columns={['requiredAnswerItem.transportationFee']}
                value={detailInfo.requiredFreightCost ? ['1'] : []}
                onChangeState={(v) =>
                  setDetailInfo({
                    ...detailInfo,
                    requiredFreightCost: v ? v[0] === '1' : false,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'decision' })}
          onClick={() => handleDecision()}
        />
      </div>
      <div className="small-dialog">
        <FileUploadDialog
          isOpen={isOpenFileUploadDialog}
          messageOption={{
            headerLabelId: {
              id: 'file_add',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          fileUploadOption={{
            multiple: false,
            validateOption: {
              maxFileSizeInMebis: 50,
              maxFileCount: 1,
            },
          }}
          onCancel={() => {
            setOpenFileUploadDialog(false);
          }}
          onDecision={async (result) => {
            const attchmentfiels = await handleFIleUpload(
              [],
              result,
              'B11',
              'B01',
              'B02',
              intl
            );
            setDetailInfo({
              ...detailInfo,
              ...{
                attachment: attchmentfiels[0],
              },
            });

            setOpenFileUploadDialog(false);
          }}
          onChangeLoadingState={(isLoading) => {
            setLoading(isLoading);
          }}
        />
        <SuppliedRegistrationDialog
          isOpen={isOpenSuppliedRegistrationDialog}
          inputOption={{
            suppliedParts: detailInfo.suppliedParts ?? false,
            suppliesDt: convertDate(detailInfo.suppliesDt || null),
            suppliesRemarks: detailInfo.suppliesRemarks ?? '',
          }}
          onDecision={(v) => {
            setDetailInfo({
              ...detailInfo,
              suppliedParts: v.suppliedParts,
              suppliesDt: convertDatetime(v.suppliesDt || null, 'YYYY/MM/DD'),
              suppliesRemarks: v.suppliesRemarks,
            });
            setOpenSuppliedRegistrationDialog(false);
          }}
          onCancel={() => {
            setOpenSuppliedRegistrationDialog(false);
          }}
        />
      </div>
      {/* 品目詳細検索用ダイアログ */}
      <div className="search-dialog">
        <SearchListViewDialog
          viewId={'MASTER_TRANSACTION_UNIT_SEARCH'} // 項目検索のVIEW_IDを直接使用した方がスッキリしそうなので変則的な形でpresetを使用
          fullMethodName={FullMethodName_ListTransactionUnits}
          isOpen={isOpenSearchTransactionUnitsDialog}
          headerLabelId={{
            prefixId: 'DIALOG_TITLE',
            viewId: 'MASTER_TRANSACTION_UNIT_SEARCH',
          }}
          selectLimit={1}
          onCloseChange={() => setOpenSearchTransactionUnitsDialog(false)}
          onSelectChange={(items) => {
            // 返却情報がそのまま利用できるので、入力項目にセット
            const item = (items.shift() ??
              {}) as mtechnavi.api.blueprint.TransactionUnit;
            const prosessList = item.processs?.map((v) => v.code);
            setDetailInfo({
              ...detailInfo,
              filterboxItemTransactionUnit: {
                value: item.transactionUnitId,
                displayName: item.displayNameLang?.ja,
              } as FilterboxItem,
              transactionUnit: {
                ...item,
                displayNameLang: item.displayNameLang?.ja,
              },
              filterboxItemQuantityUnit:
                unitCategorys.filter(
                  (v) => v.value === item.quantityUnit?.code
                ) ?? [],
              quantity: {
                ...detailInfo.quantity,
                unit: item.quantityUnit?.code
                  ? window.App.services.ui.getNameOptionWithCode(
                      'A0000005',
                      item.quantityUnit?.code ?? ''
                    )
                  : {},
              },
              displayName: item.displayNameLang?.ja ?? '',
              blueprint: { ...item.blueprintReference, revision: null },
              filterboxItemProcesss: processCategorys.filter((v) =>
                prosessList?.includes(v.value)
              ),
              processs: item.processs ?? [],
              modelNumber: item.catalogReference?.modelNumber ?? '',
              manufacture: item.catalogReference?.manufacture ?? '',
              contactMatter: item.contactMatter ?? '',
              attachment: undefined,
            });

            if (!item.blueprintReference?.blueprintNumber) {
              setSearchBlueprintDisable(true);
              setAttachmentDisable(false);
            } else {
              setSearchBlueprintDisable(false);
              setAttachmentDisable(true);
            }
            setFileDeleteDisable(true);
            setOpenSearchTransactionUnitsDialog(false);
          }}
          menuTarget={'transactionUnitId'}
          onHandleFormatSchema={(schema: Schema) => {
            // ja表示のみする処理
            const jaColumn = ['displayNameLang', 'processs.displayNameLang'];
            return getAltDisplaySchema(schema, jaColumn, 'ja');
          }}
        />
      </div>

      {/* 図面検索ダイアログ */}
      <div className="search-dialog">
        <SearchListViewDialog
          viewId={'BLUEPRINT_BLUEPRINT_SEARCH'}
          fullMethodName={FullMethodName_ListBlueprints}
          isOpen={isOpenSearchBlueprintDialog}
          headerLabelId={{
            prefixId: 'DIALOG_TITLE',
            id: 'SEARCH_BLUEPRINT_DIALOG',
          }}
          selectLimit={1}
          menuEvent={searchBlueprintBurgerMenuEvent()}
          onCloseChange={() => setOpenSearchBlueprintDialog(false)}
          onSelectChange={async (items) => {
            const item =
              items.shift() as unknown as mtechnavi.api.blueprint.Blueprint;

            let blueprintAttachment: sharelib.IAttachment = {};
            // 図面IDが存在する場合
            if (item.blueprintFile?.assetId) {
              // assetの詳細情報を取得
              const option: FilterRequest = {
                action: 'reload',
                fullMethodName: FullMethodName_ListAssets,
                requestBody: {
                  ids: [item.blueprintFile?.assetId],
                },
                filter: {
                  assetId: {
                    $eq: item.blueprintFile?.assetId ?? '',
                  },
                },
                sort: [],
              };

              try {
                const assetRes = (await window.App.services.ui.worker.filter(
                  option
                )) as mtechnavi.api.assetinventory.ListAssetsResponse;
                console.info('assetIDでの検索結果:', assetRes);
                blueprintAttachment = assetRes.items.shift() ?? {};
              } catch (err) {
                error(getExceptionMessage(intl, err));
                throw err;
              }
            }
            setDetailInfo({
              ...detailInfo,
              blueprint: {
                blueprintNumber: item.blueprintNumber,
                branchNumber: item.branchNumber,
                revision: item.revision.toString(),
              },
              inspection: item.partDrawingProperties?.inspection,
              attachment: blueprintAttachment,
            });
            setOpenSearchBlueprintDialog(false);
          }}
          menuTarget={'blueprintId'}
          initialFilterItems={getBlueprintQuery(
            detailInfo.blueprint?.blueprintNumber ?? '',
            detailInfo.blueprint?.branchNumber ?? ''
          )}
          onHandleFormatSchema={(schema: Schema) => {
            const jaColumn = [
              'displayNameLang',
              'revisionProperties.staff.displayNameLang',
            ];
            const formatterSch = getAltDisplaySchema(schema, jaColumn, 'ja');
            const dayCololmns = ['revisionProperties.revisedAt'];
            return getDayFormetterDisplaySchema(formatterSch, dayCololmns, {
              dayOpts: { formatType: 'YYYY/MM/DD HH:mm' },
            });
          }}
        />
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id: 'detailInput',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="DetailInputDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
