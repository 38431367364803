/**
 * API に都合が良い値にフォールバックする
 */

// APIにstringで登録する際のコンバーター
export function convertApiString(v: string | undefined) {
  if (!v) {
    return null;
  }

  return v;
}

// APIにstringで登録する際のコンバーター(空文字つめ)
export function convertApiStringWIthFillBlank(v: string | undefined) {
  if (!v) {
    return ' ';
  }

  return v;
}

// APIから取得したstringを取得する際のコンバーター(空白除去)
export function convertStringWithTrim(v: string | null | undefined) {
  if (!v) {
    return '';
  }

  return v.trimEnd();
}
