import { useState } from 'react';
import { Checkbox, Textbox } from '~/shared/components/ui';
import { Accordion } from '~/shared/components/ui';

export const UISampleAccordion = () => {
  const [isAccordionClose, setIsAccordionClose] = useState(false);
  const [isInitialClose, setInitialClose] = useState(false);
  return (
    <div className="categories">
      <h3 id="accordion">アコーディオン</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <div
              className={`input-blocktitle-outer ${
                isAccordionClose ? 'close' : ''
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => setIsAccordionClose(!isAccordionClose)}
              >
                タイトル
              </h3>
            </div>
            <div
              className={`input-blockbody ${isAccordionClose ? 'close' : ''}`}
            >
              <div className="sample-inner">inner contents</div>
            </div>
          </div>
        </div>
      </div>

      <h4>アコーディオンコンポーネント</h4>
      <Checkbox
        name=""
        columns={['']}
        items={[
          {
            displayName: '初期状態を閉じた状態とする',
            value: '1',
          },
        ]}
        onChangeState={(v) => {
          setInitialClose(!!v.length);
        }}
      />
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <Accordion
              title="タイトル"
              initialClose={isInitialClose}
              key={isInitialClose ? 0 : 1} //
            >
              <div className="sample-inner">
                <p>inner contents</p>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-100">
                      <Textbox
                        name="dummy"
                        type="text"
                        labelId="dummy"
                        columns={['dummy']}
                        placeholder="開閉では状態が消えないことを示すための入力項目 ( key の変更で作り直されるとリセットされる )"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};
