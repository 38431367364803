import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import { Container, Toast, error } from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Textbox, SimplePageNavigation } from '~/shared/components/ui';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';

import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  ViewId,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  FullMethodName_ListProgramOptions,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
const VIEW_ID: ViewId = 'MASTER_PROGRAMOPTION_CONFIRMATION';

export function MasterProgramOptionConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navi = useNavigate();
  const handleError = useErrorHandler();
  const intl = useIntl();
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListProgramOptions,
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      programOptionId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('MASTER_PROGRAMOPTION_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();

    // 基本情報でセットした値を元に情報を取得
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListProgramOptions,
      pageNumber: 1,
    });

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [item, setItem] =
    useState<mtechnavi.api.programoption.IProgramOption>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.programoption.IProgramOption[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListProgramOptions,
      pageNumber: n,
    });
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <Container viewId={VIEW_ID}>
      <div className="MasterProgramoptionConfirmation">
        <div className="header"></div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="info">
            <div className="basic-info-body">
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-33">
                    <Textbox
                      name="categoryName"
                      className="field"
                      value={item?.categoryName ?? ''}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.categoryName"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-33">
                    <Textbox
                      name="category"
                      className="field"
                      value={item?.category?.displayNameLang?.ja ?? ''}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.category"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-33">
                    <Textbox
                      name="code"
                      className="field"
                      value={item?.code ?? ''}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.code"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-33">
                    <Textbox
                      name="displayNameLang"
                      className="field"
                      value={item?.displayNameLang?.ja ?? ''}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.displayNameLang"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-33">
                    <Textbox
                      name="order"
                      className="field right"
                      value={String(item?.order ?? '')}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.order"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-33">
                    <Textbox
                      name="systemName"
                      className="field"
                      value={item?.systemName ?? ''}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.systemName"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-33">
                    <Textbox
                      name="system"
                      className="field"
                      value={item?.system?.displayNameLang?.ja ?? ''}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.system"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-33">
                    <Textbox
                      name="userEditable"
                      value={item?.userEditable ? '◯' : '-'}
                      type="text"
                      labelId="MASTER_PROGRAMOPTION_CONFIRMATION.userEditable"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <CaptionButton
              className="button-margin"
              name=""
              caption="戻る"
              onClick={() => {
                navi('/master-programoption-list');
              }}
              buttonType="cancel"
            ></CaptionButton>
            <SimplePageNavigation
              pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
              handleMovePage={handleMovePage}
            />
          </div>
        </div>
      </div>
      <Toast />
    </Container>
  );
}
