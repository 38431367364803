import { useEffect, useState } from 'react';
import { SessionCard } from '~/shared/components/idp';
import './Header.css';
import { ReactComponent as Menu } from '@material-design-icons/svg/filled/menu.svg';
import { TaskList } from '../TaskListView';
import {
  TaskMessageResources,
  TaskSurveyRequest,
  TaskWorkTask,
} from '~/shared/components/taskListItems';

import { GetMessage } from '~/shared/components/parts/Message/Message';
import { ViewId } from '~/shared/utils';
import { IconButton } from '../Button';
import { TooltipAreaProvider } from '../../parts/Tooltip';

export interface HeaderProps {
  handleSideMenu: () => void;
  handleHeaderMenu: (isVisibleContents: boolean) => void;
  headerMenuSwich?: boolean;
  viewId?: ViewId;
}

export function Header(props: HeaderProps) {
  const headerItems = ['commnet', 'notifications', 'account'];
  const [menuView, setMenuView] = useState('off');
  const [taskTotal, setTaskTotal] = useState<number>(0);

  useEffect(() => {
    if (!props.headerMenuSwich) {
      setMenuView('off');
    }
  }, [props.headerMenuSwich]);

  const navAction = () => {
    props.handleSideMenu();
  };

  const handleOpenTaskList = () => {
    menuViewSwitch(headerItems[0]);
  };

  const menuViewSwitch = (type: string) => {
    if (headerItems.includes(type) && menuView !== type) {
      setMenuView(type);
      props.handleHeaderMenu(true);
    } else {
      setMenuView('off');
      props.handleHeaderMenu(false);
    }
  };

  const viewName = GetMessage({
    id: '',
    viewId: props.viewId ?? '',
    prefixId: props.viewId ? 'HEADER_TITLE' : '',
  });

  return (
    <header className="Header">
      <TooltipAreaProvider>
        <div>
          <div className="nav-btn">
            <div
              className="btn-outer"
              style={{ height: '2.6em', width: '2.6em' }}
              onClick={navAction}
            >
              <Menu />
            </div>
          </div>
        </div>
        <div className="symbol">M-Tech Navi{` ${viewName}`}</div>
        <div className="header-btns">
          {/* タスクリスト */}
          <TaskList
            mode={taskTotal > 0 ? 'bell' : 'none'}
            open={menuView === headerItems[0]}
            onClick={handleOpenTaskList}
            onChange={(v) => {
              setTaskTotal(v);
            }}
          >
            <TaskMessageResources />
            <TaskSurveyRequest />
            <TaskWorkTask />
          </TaskList>

          {/* お知らせ */}
          <IconButton
            name="Notifications"
            buttonType="basic"
            iconType="notifications"
            onClick={() => menuViewSwitch(headerItems[1])}
            properties={[
              {
                name: 'Notifications',
                propertyName: 'Notifications',
                propertyValue: 'Notifications',
              },
            ]}
          />

          {/* アカウント */}
          <IconButton
            name="AccountCircle"
            className="accountCircle"
            buttonType="basic"
            iconType="account"
            onClick={() => menuViewSwitch(headerItems[2])}
            properties={[
              {
                name: 'AccountCircle',
                propertyName: 'AccountCircle',
                propertyValue: 'AccountCircle',
              },
            ]}
          />
          {menuView === headerItems[1] && (
            <div className="menu-info">
              <div className="notifications">お知らせ</div>
            </div>
          )}
          {menuView === headerItems[2] && (
            <div className="menu-info">
              <SessionCard />
            </div>
          )}
        </div>
      </TooltipAreaProvider>
    </header>
  );
}
