import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  success,
  error,
} from '~/shared/components';
import {
  Accordion,
  AutoReminderSettingDialog,
  Checkbox,
  NavigationIconMenu,
  SimpleListView,
} from '~/shared/components/ui';
import { ConfirmationDialog, Textbox, Textarea } from '~/shared/components/ui';
import { PageNavigation } from '~/shared/components/ui';
import {
  FullMethodName_ListSurveyRequestCatalogs,
  PageState,
  ViewId,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  getWorkerExceptionMessage,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
} from '~/shared/utils';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './SurveyRequestCatalogConfirmation.css';

const VIEW_ID: ViewId = 'SURVEY_REQUEST_CATALOG_CONFIRMATION';

export function SurveyRequestCatalogConfirmation() {
  type SurveyRequestCatalog = mtechnavi.api.survey.ISurveyRequestCatalog;

  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const handleError = useErrorHandler();
  const [isLoading, setLoading] = useState(false);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListSurveyRequestCatalogs,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {},
    requestBody: {
      surveyRequestCatalogIds: location.ids ?? [],
    },
    sort: getSortSettingFromLocalStorage(
      'SURVEY_REQUEST_CATALOG_LIST',
      myEmail
    ),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();

    // 基本情報でセットした値を元に情報を取得
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListSurveyRequestCatalogs,
      pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    });

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [item, setItem] = useState<SurveyRequestCatalog>();
  // PageInfo
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // pageデータ変更時の処理
  useEffect(() => {
    const items = page.originalItems as unknown as SurveyRequestCatalog[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const [autoReminderDialogModalIsOpen, setAutoReminderModalIsOpen] =
    useState(false);

  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleMenuInput();
      },
    });
    // 削除
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: () => setMenuModalIsOpen(true),
    });

    return iconItems;
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListSurveyRequestCatalogs,
      pageNumber: n,
    });
  };

  const handleMenuInput = () => {
    const ids = [] as string[];
    ids.push(item?.surveyRequestCatalogId ?? '');
    const state: PageState = {
      ids: ids ?? [],
      actionType: 'edit',
      sourceViewId: VIEW_ID,
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/supplier-base/survey-request-catalog-input', { state });
  };

  const handleMenuDelete = async () => {
    const deleteItem: mtechnavi.api.survey.ISurveyRequestCatalog[] = [];
    if (item?.surveyRequestCatalogId) {
      deleteItem.push(item as mtechnavi.api.survey.ISurveyRequestCatalog);
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'delete',
        request: deleteItem,
      });
      success([toastSuccess]);
    } catch (err) {
      getWorkerExceptionMessage(intl, err);
    }
    setMenuModalIsOpen(false);
    setLoading(false);
    navi('/supplier-base/survey-request-catalog-list');
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestCatalogConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/supplier-base/survey-request-catalog-list"
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              iconItems={navigationIconItems()}
              handleMovePage={(n) => handleMovePage(n)}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: item?.surveyRequestCatalogUpdatedBy?.email ?? '',
                  attributeAt: item?.surveyRequestCatalogUpdatedAt ?? undefined,
                  content:
                    item?.surveyRequestCatalogUpdatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25">
                  <Textbox
                    name="code"
                    className="field"
                    value={item?.code ?? ''}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.code"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-33">
                  <Textbox
                    name="displayName"
                    className="field"
                    value={item?.displayName ?? ''}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.displayName"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-25">
                  <Textbox
                    name="category.code"
                    className="field"
                    value={item?.category?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.category.displayName"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  <Textarea
                    name="guidance"
                    className="w-100 mh-middle"
                    disabled={true}
                    value={item?.guidance ?? ''}
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.guidance"
                    columns={preset.columns}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-16">
                  <Textbox
                    name="approval"
                    className="field"
                    value={item?.approval ? '◯' : '-'}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.approval"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-16">
                  <Textbox
                    name="completeCondition"
                    className="field"
                    value={item?.completeCondition?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.completeCondition"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-16">
                  <Textbox
                    name="partialReply"
                    className="field"
                    value={item?.partialReply ? '◯' : '-'}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.partialReply"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-16">
                  <Textbox
                    name="decline"
                    className="field"
                    value={item?.decline ? '◯' : '-'}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.decline"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-16">
                  <Textbox
                    name="decline"
                    className="field"
                    value={item?.requiredAttachment ? '◯' : '-'}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.requiredAttachment"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-16">
                  <CaptionButton
                    className="auto-reminder-setting-button"
                    name=""
                    caption="自動催促設定"
                    onClick={() => setAutoReminderModalIsOpen(true)}
                    buttonType="basic"
                  ></CaptionButton>
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  <Textbox
                    name="requester1"
                    className="field"
                    value={item?.requesters?.[0] ?? ''}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.requester1"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-40">
                  <Textbox
                    name="requester2"
                    className="field"
                    value={item?.requesters?.[1] ?? ''}
                    type="text"
                    labelId="SURVEY_REQUEST_CATALOG_CONFIRMATION.requester2"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  <SimpleListView
                    data={item?.commonAttachments ?? []}
                    viewOptions={{
                      readonly: true,
                      previewRowCount: 5,
                      keyColumn: 'id',
                      columns: [
                        {
                          header: { id: 'commonAttachedFile' },
                          propertyName: 'filename',
                        },
                      ],
                    }}
                    actionOptions={{
                      onRowClick: (item) => {
                        autoDownloadFileOnlyName(
                          item.filename ?? '',
                          item.assetId ?? ''
                        );
                      },
                      onFullDownLoad: () => {
                        autoBulkDownload(
                          (item?.commonAttachments ?? []).map(
                            (item) => item.assetId ?? ''
                          ),
                          intl,
                          VIEW_ID
                        );
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <Accordion
              title={GetMessageWithIntl(intl, {
                id: 'requestDetail',
                viewId: VIEW_ID,
              })}
            >
              <div className="input-line checkbox-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Checkbox
                      name="requestFormUsable"
                      columns={['requestFormUsable']}
                      items={[
                        {
                          displayName: GetMessageWithIntl(intl, {
                            id: 'requestFormUsable',
                            viewId: VIEW_ID,
                          }),
                          value: '1',
                        },
                      ]}
                      value={item?.requestFormUsable ? ['1'] : []}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line checkbox-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Checkbox
                      name="resultFormUsable"
                      columns={['resultFormUsable']}
                      items={[
                        {
                          displayName: GetMessageWithIntl(intl, {
                            id: 'resultFormUsable',
                            viewId: VIEW_ID,
                          }),
                          value: '1',
                        },
                      ]}
                      value={item?.resultFormUsable ? ['1'] : []}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={menuModalIsOpen}
          viewMessage={deleteMessage}
          messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
          onDecision={() => {
            handleMenuDelete();
          }}
          onCancel={() => setMenuModalIsOpen(false)}
        />
        <AutoReminderSettingDialog
          inputOption={{
            displayModeType: 'display',
            autoReminderSetting: item?.autoReminderSettings?.length
              ? item?.autoReminderSettings[0]
              : {},
          }}
          isOpen={autoReminderDialogModalIsOpen}
          onDecision={() => setAutoReminderModalIsOpen(false)}
          onCancel={() => setAutoReminderModalIsOpen(false)}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
