import React, {
  PropsWithRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { FormPageBuilder } from './FormPageBuilder';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';
import { TextBox, error, GetMessageWithIntl } from '~/shared/components';
import { IconButton } from '~/shared/components/ui/Button';
import './FormBuilder.css';
import { numberingTempId, TypeTempAutoNames } from './utils';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import { IFormSetting, IFormSettingItem } from './';
import Long from 'long';
import { convertLongToNumber } from '~/shared/utils/converter';

interface FormBuilderProps {
  initialFormSetting?: IFormSetting;
  onChangeFormSetting?: (formSetting: IFormSetting) => void;
  initialFormSettingItems?: IFormSettingItem[];
  onChangeFormSettingItems?: (formSettingItems: IFormSettingItem[]) => void;
  itemAutoNameManagementItems?: mtechnavi.api.form.IItemAutoNameManagement[];
}
export const FormBuilder = ({
  initialFormSetting,
  onChangeFormSetting,
  initialFormSettingItems,
  onChangeFormSettingItems,
  itemAutoNameManagementItems,
}: PropsWithRef<FormBuilderProps>) => {
  const intl = useIntl();
  const addIcon = useMemo(() => getGenerallyIconElement('add'), []);
  const [isChangeAddPage, setChangeAddPage] = useState(false);

  // 削除 確認ダイアログ
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const deleteMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  const [targetFormPage, setTargetFormPage] =
    useState<mtechnavi.api.form.IFormPage>();

  const [formSetting, setFormSetting] = useState<IFormSetting>({
    formPages: [],
  });
  const [formSettingItems, setFormSettingItems] = useState<IFormSettingItem[]>(
    []
  );

  // 採番処理用
  const typeCheckList = useMemo(() => {
    const typeCheckList: mtechnavi.api.form.IItemAutoNameManagement[] =
      itemAutoNameManagementItems ?? [];
    return typeCheckList;
  }, [itemAutoNameManagementItems]);
  const [tempAutoNames, setTempAutoNames] = useState<TypeTempAutoNames>({
    values: [],
  });

  const onChangeHandler = useCallback(
    (formSetting: IFormSetting) => {
      if (onChangeFormSetting) {
        onChangeFormSetting(formSetting);
      }
    },
    [onChangeFormSetting]
  );

  const onChangeFormSettingItemsHandler = useCallback(
    (settingItems: IFormSettingItem[]) => {
      if (onChangeFormSettingItems) {
        onChangeFormSettingItems(settingItems);
      }
    },
    [onChangeFormSettingItems]
  );

  const handlePageAdd = useCallback(() => {
    if (formSetting.formPages && formSetting.formPages.length >= 8) {
      error([GetMessageWithIntl(intl, { id: 'E0000109' })]);
      return;
    }
    const newForm = {
      ...formSetting,
      formPages: [
        ...(formSetting.formPages || []),
        {
          formPageId: numberingTempId(), // 永続化されるまでの仮のページID
          displayName: GetMessageWithIntl(intl, {
            prefixId: 'FORM_BUILDER',
            id: 'defaultPageName',
          }),
          sort: null,
        },
      ],
    };
    setFormSetting(newForm);
    onChangeHandler({ ...newForm });
    setChangeAddPage(true);
    // formSetting変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSetting, onChangeHandler]);

  const handlePageChange = (
    pageId: string | null | undefined,
    items: IFormSettingItem[]
  ) => {
    const otherFormPages = formSettingItems.filter(
      (page) => page.formPageId !== pageId
    );
    const newFormSettingItems = items;
    setFormSettingItems([...otherFormPages, ...newFormSettingItems]);
    onChangeFormSettingItemsHandler([
      ...otherFormPages,
      ...newFormSettingItems,
    ]);
  };

  const handleChangePageName = (
    pageId: string | null | undefined,
    displayName: string
  ) => {
    const alterFormPages = formSetting.formPages?.map((page) => {
      if (page.formPageId !== pageId) {
        return page;
      }
      return {
        ...page,
        displayName,
      };
    });
    const newForm = formSetting;
    newForm.formPages = alterFormPages;
    setFormSetting(newForm);
    onChangeHandler({ ...newForm });
  };

  const handleDeletePage = (pageId: string | null | undefined) => {
    const alterFormPages = formSetting.formPages?.filter(
      (page) => page.formPageId !== pageId
    );

    // 削除したページ内の項目は除外する
    const alterFormSettingItems = formSettingItems.filter((v) =>
      (alterFormPages ?? []).some((w) => w.formPageId === v.formPageId)
    );
    setFormSettingItems(alterFormSettingItems);
    onChangeFormSettingItemsHandler(alterFormSettingItems);

    const newForm = {
      ...formSetting,
      formPages: alterFormPages,
    };
    setFormSetting(newForm);
    onChangeHandler({ ...newForm });
    setDeleteConfirmOpen(false);
  };

  const handleTempAutoNamesChange = (v: TypeTempAutoNames) => {
    setTempAutoNames(v);
    if (
      (convertLongToNumber(formSetting.latestItemAutoNameSequenceNumber) ?? 0) <
      (v.maxCount ?? 0)
    ) {
      const newForm = {
        ...formSetting,
        latestItemAutoNameSequenceNumber: Long.fromNumber(v.maxCount ?? 0),
      };
      setFormSetting(newForm);
      onChangeHandler({ ...newForm });
    }
  };

  const handleMoveAddPageHeader = (ref: React.RefObject<HTMLDivElement>) => {
    if (isChangeAddPage) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setChangeAddPage(false);
    }
  };

  useEffect(() => {
    if (formSetting.formPages?.length === 0) {
      handlePageAdd();
    }
  }, [formSetting.formPages?.length, handlePageAdd]);

  useEffect(() => {
    if (!initialFormSetting) {
      return;
    }
    setFormSetting(initialFormSetting);
  }, [initialFormSetting]);

  useEffect(() => {
    if (!initialFormSettingItems) {
      return;
    }
    setFormSettingItems(initialFormSettingItems);

    const values = initialFormSettingItems.map((v) => {
      return {
        prefix: 'c',
        counter: 0, // 手動採番と同様の扱いとする
        displayName: v.item?.itemAutoName ?? '',
      };
    });
    setTempAutoNames({
      ...tempAutoNames,
      values: values,
    });

    // initialFormSettingItems 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormSettingItems]);

  return (
    <div className="FormBuilder">
      <div className="page-list-container">
        {formSetting.formPages?.map((page) => (
          <React.Fragment key={page.formPageId}>
            <div className="form-page-tab">
              <TextBox
                name="page-name"
                type="text"
                value={page.displayName}
                validateOption={{ maxLength: 10 }}
                onChangeState={(v) => handleChangePageName(page.formPageId, v)}
              />
              <IconButton
                name="deletePage"
                className="deletePage"
                iconType="clear"
                buttonType="basic"
                onClick={() => {
                  setTargetFormPage(page);
                  setDeleteConfirmOpen(true);
                }}
              />
            </div>
            <FormPageBuilder
              formPageId={page.formPageId ?? ''}
              formItems={
                formSettingItems.filter(
                  (v) => v.formPageId === page.formPageId
                ) ?? []
              }
              formAllItems={formSettingItems}
              onChange={(items) => handlePageChange(page.formPageId, items)}
              numberingOption={{
                typeCheckList: typeCheckList,
                tempAutoNames: tempAutoNames,
                maxCounter:
                  convertLongToNumber(
                    formSetting.latestItemAutoNameSequenceNumber
                  ) ?? 0,
                onChangeTempAutoNames: handleTempAutoNamesChange,
              }}
              onChangePageHeaderRef={handleMoveAddPageHeader}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="page-list-footer">
        <button
          className="CaptionButton form-page-add"
          onClick={() => handlePageAdd()}
        >
          {addIcon} ページ追加
        </button>
      </div>
      <div className="form-next-page"></div>
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={deleteMessage}
        onDecision={() => {
          handleDeletePage(targetFormPage?.formPageId);
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
    </div>
  );
};
