import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { SurveyReplyDialog, SurveyReplyResult } from '~/shared/components/ui';

export const UISampleSurveyReplyDialogs = () => {
  const [replyResult, setReplyResult] = useState<SurveyReplyResult | null>(
    null
  );
  const [isDialogReplyShow, setDialogReplyShow] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<SurveyReplyResult | null>(null);
  const [isDialogConfirmationShow, setDialogConfirmationShow] = useState(false);
  const [canPartialReplyResult, setCanPartialReplyResult] =
    useState<SurveyReplyResult | null>(null);
  const [isDialogCanPartialReplyShow, setDialogCanPartialReplyShow] =
    useState(false);
  const [result, setResult] = useState<SurveyReplyResult | null>(null);
  const [isDialogShow, setDialogShow] = useState(false);
  const [estimateReplyResult, setEstiamteReplyResult] =
    useState<SurveyReplyResult | null>(null);
  const [isDialogEstimateReplyShow, setDialogEstimateReplyShow] =
    useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">回答送信ダイアログ</h3>
      <h4 id="dialog">回答送信ダイアログ,回答完了,部分回答なし</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setReplyResult(null);
                setDialogReplyShow(true);
              }}
            />
            <SurveyReplyDialog
              isOpen={isDialogReplyShow}
              outputOption={{
                mode: 'reply',
                isCanPartialReply: false,
              }}
              messageOption={{
                headerLabelId: {
                  id: 'survey_reply',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => {
                setDialogReplyShow(false);
              }}
              onDecision={(result) => {
                setDialogReplyShow(false);
                setReplyResult(result);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {replyResult && JSON.stringify(replyResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4 id="dialog">回答送信ダイアログ,確認完了,部分回答あり</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setConfirmationResult(null);
                setDialogConfirmationShow(true);
              }}
            />
            <SurveyReplyDialog
              isOpen={isDialogConfirmationShow}
              outputOption={{
                mode: 'confirmation',
                isCanPartialReply: true,
              }}
              messageOption={{
                headerLabelId: {
                  id: 'survey_reply',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => {
                setDialogConfirmationShow(false);
              }}
              onDecision={(result) => {
                setDialogConfirmationShow(false);
                setConfirmationResult(result);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {confirmationResult &&
                JSON.stringify(confirmationResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4 id="dialog">回答送信ダイアログ,確認不要,部分回答なし</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult(null);
                setDialogShow(true);
              }}
            />
            <SurveyReplyDialog
              isOpen={isDialogShow}
              outputOption={{
                mode: 'unnecessary',
                isCanPartialReply: false,
              }}
              messageOption={{
                headerLabelId: {
                  id: 'survey_reply',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => {
                setDialogShow(false);
              }}
              onDecision={(result) => {
                setDialogShow(false);
                setResult(result);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
      <h4 id="dialog">回答送信ダイアログ,確認不要,部分回答あり</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setCanPartialReplyResult(null);
                setDialogCanPartialReplyShow(true);
              }}
            />
            <SurveyReplyDialog
              isOpen={isDialogCanPartialReplyShow}
              outputOption={{
                mode: 'unnecessary',
                isCanPartialReply: true,
              }}
              messageOption={{
                headerLabelId: {
                  id: 'survey_reply',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => {
                setDialogCanPartialReplyShow(false);
              }}
              onDecision={(result) => {
                setDialogCanPartialReplyShow(false);
                setCanPartialReplyResult(result);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {canPartialReplyResult &&
                JSON.stringify(canPartialReplyResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
      <h4 id="dialog">回答送信ダイアログ,見積管理、見積回答確認</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEstiamteReplyResult(null);
                setDialogEstimateReplyShow(true);
              }}
            />
            <SurveyReplyDialog
              isOpen={isDialogEstimateReplyShow}
              outputOption={{
                mode: 'estimateResult',
                isCanPartialReply: false,
              }}
              messageOption={{
                headerLabelId: {
                  id: 'survey_reply',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => {
                setDialogEstimateReplyShow(false);
              }}
              onDecision={(result) => {
                setDialogEstimateReplyShow(false);
                setEstiamteReplyResult(result);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {canPartialReplyResult &&
                JSON.stringify(canPartialReplyResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
