/*
  ListViewで、表示情報をschemaに付加する処理
*/

import { Schema } from '.';
import {
  FormatterOptions,
  alterDisplayFormatter,
  booleanDataFormatter,
  commaTypeNumber,
  dayFormatter,
  distantAmountNumber,
  joinFormatter,
  joinUserInfoFormatter,
  longNullZeroFormatter,
} from './formatter';

// カラムを結合して表示するためのSchema処理
export function getJoinedColumnSchema(
  sch: Schema,
  targetColumn: string[],
  joinValue?: string,
  isFlatten?: boolean
) {
  return sch.map((v) => {
    if (targetColumn.includes(v.name)) {
      return {
        ...v,
        formatter: joinFormatter(
          joinValue !== undefined ? joinValue : ',',
          targetColumn,
          {
            toString: undefined,
            isFlatten,
          }
        ),
      };
    } else {
      return v;
    }
  });
}

/**
 * カラムを結合して表示する。
 * 結合したい対象が displayNameLang であるなど、単純に参照できない場合にこちらを利用する。
 */
export const getJoinedAndAltColumnSchema = (
  sch: Schema,
  targetColumns: string[],
  altColumns: string[],
  joinValue?: string,
  isFlatten?: boolean
) => {
  return sch.map((v) => {
    if (targetColumns.includes(v.name)) {
      return {
        ...v,
        formatter: joinFormatter(
          joinValue !== undefined ? joinValue : ',',
          altColumns,
          {
            toString: undefined,
            isFlatten,
          }
        ),
      };
    } else {
      return v;
    }
  });
};

// カラムを結合して表示するためのSchema処理
export function getJoinedUserNameEmailSchema(
  sch: Schema,
  userNameColumn: string,
  emailColumn: string
) {
  return sch.map((v) => {
    const columns = [userNameColumn, emailColumn];
    if (columns.some((column) => column === v.name)) {
      return {
        ...v,
        formatter: joinUserInfoFormatter([userNameColumn, emailColumn]),
      };
    } else {
      return v;
    }
  });
}

// displayNameLangのような特定のカラムで、指定されたデータを代わりに表示するためのSchema処理
export function getAltDisplaySchema(
  sch: Schema,
  targetColumn: string[],
  altColumn: string,
  opts?: FormatterOptions
) {
  return sch.map((v) => {
    const isReplace = opts?.replaceOpts?.isReplace ?? false;
    if (isReplace) {
      const beforeValue = opts?.replaceOpts?.beforeValue ?? '';
      if (targetColumn.includes(v.name) && v.name === beforeValue) {
        return {
          ...v,
          formatter: alterDisplayFormatter(altColumn, v.name, opts),
        };
      }
    }
    if (targetColumn.includes(v.name)) {
      return {
        ...v,
        formatter: alterDisplayFormatter(altColumn, v.name),
      };
    } else {
      return v;
    }
  });
}

// 日付表示するためのSchema処理
export function getDayFormetterDisplaySchema(
  sch: Schema,
  targetColumn: string[],
  opts?: FormatterOptions
) {
  return sch.map((v) => {
    const setName = v.name.replace('.timestamp', '');
    if (targetColumn.includes(setName) && opts!) {
      return {
        ...v,
        formatter: dayFormatter(opts, setName),
      };
    } else {
      return v;
    }
  });
}

// Amounts型を3桁区切で表示するためのSchema処理
export function getAmountNumberFormetterSchema(
  sch: Schema,
  targetColumn: string[]
) {
  return sch.map((v) => {
    const setName = v.name.replace('.integralAmount', '');
    if (targetColumn.includes(setName)) {
      return {
        ...v,
        formatter: distantAmountNumber(setName),
      };
    } else {
      return v;
    }
  });
}

// 文字列を3桁区切で表示するためのSchema処理
export function getCommaTypeNumberSchema(sch: Schema, targetColumn: string[]) {
  return sch.map((v) => {
    if (targetColumn.includes(v.name)) {
      return {
        ...v,
        formatter: commaTypeNumber(v.name),
      };
    } else {
      return v;
    }
  });
}

// booleanを記号に置き換えて表示するためのSchema処理
export function getBooleanDataFormetterSchema(
  sch: Schema,
  targetColumn: string[],
  formattToString?: boolean
) {
  return sch.map((v) => {
    if (targetColumn.includes(v.name)) {
      return {
        ...v,
        formatter: booleanDataFormatter(v.name, formattToString ?? false),
      };
    } else {
      return v;
    }
  });
}

export function getLongDateFormatterSchema(
  sch: Schema,
  targetColumn: string[]
) {
  return sch.map((v) => {
    if (targetColumn.includes(v.name)) {
      return {
        ...v,
        formatter: dayFormatter(
          { dayOpts: { formatType: 'YYYY/MM/DD HH:mm' } },
          v.name
        ),
      };
    } else {
      return v;
    }
  });
}

// Longで空の場合に0扱いとするSchema処理
export function getLongNullZeroSchema(sch: Schema, targetColumn: string[]) {
  return sch.map((v) => {
    if (targetColumn.includes(v.name)) {
      return {
        ...v,
        formatter: longNullZeroFormatter(v.name),
      };
    } else {
      return v;
    }
  });
}
