export class AppError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class AssertionError extends AppError {}

export class Codes {
  static readonly OK = 'OK';
  static readonly ERROR_BAD_REQUEST = 'BAD_REQUEST';
  static readonly ERROR_UNAUTHORIZED = 'UNAUTHORIZED';
  static readonly ERROR_FORBIDDEN = 'FORBIDDEN';
  static readonly ERROR_UNKNOWN = 'UNKNOWN';

  constructor() {
    throw new AssertionError();
  }
}

export class ApiError extends AppError {
  errorCode: string;

  additionalMessage?: string;

  constructor(bodyText: string, message?: string) {
    super(message);

    const errInfo = (() => {
      try {
        return JSON.parse(bodyText);
      } catch (_) {
        return {
          errorCode: Codes.ERROR_UNKNOWN,
          additionalMessage: bodyText,
        };
      }
    })();
    this.errorCode = errInfo?.errorCode || Codes.ERROR_UNKNOWN;
    this.additionalMessage = errInfo?.additionalMessage;
  }
}
