import { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  error,
  Toast,
  LoadingIcon,
  success,
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components';
import {
  MenuListView,
  Preset,
  MenuItem,
  Property,
  ConfirmationDialog,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getProgramOptions } from '~/shared/services';
import {
  PageState,
  getExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListProgramOptions,
} from '~/shared/utils';
const VIEW_ID = 'MASTER_PROGRAMOPTION_LIST';

type ProgramOption = mtechnavi.api.programoption.ProgramOption;
export function MasterProgramOptionList() {
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const navi = useNavigate();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Array<Property>>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const handleExport: ImportDialogHandleFormat = {
    name: 'masterProgramOpiton',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'masterProgramOpiton',
    headerColumns: [],
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);
  const [isReload, setReload] = useState(false);
  const successMessage = GetMessage({ id: 'I0000001' });
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };
  const masterProgramOptionList = useRef<ProgramOption[]>([]);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState({
    id: '',
    msg: deleteMessage,
    func: () => {},
  });

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'ProgramOptionList'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );

    // prest 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);
  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListProgramOptions,
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'displayNameLang',
          'category.displayNameLang',
          'system.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['userEditable'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
    };
    navi('/master-programoption-confirmation', {
      state,
    });
  };

  // メニューの「編集」処理
  const handleMenuEdit = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      actionType: 'edit',
    };
    navi('/master-programoption-input', {
      state,
    });
  };

  // メニューの「複写追加」処理
  const handleMenuCopy = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      actionType: 'copy',
    };
    navi('/master-programoption-input', {
      state,
    });
  };

  const handleMenuImport = () => {
    // 取引ダイアログを表示
    setIsDialogOpen(true);
  };

  const deleteAction = async (selectedProgramOption: ProgramOption) => {
    setLoading(true);
    setReload(false);
    try {
      //KEY（種別コード＋システムコード）で集計
      const filteredLatestTargetProgramOptions =
        (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListProgramOptions,
          filter: {
            $and: [
              { categoryName: { $eq: selectedProgramOption.categoryName } },
              { systemName: { $eq: selectedProgramOption.systemName } },
            ],
          },
          sort: [],
        })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

      //件数が1件になった状態で該当明細の削除する場合はエラーを表示して続行不可
      if (filteredLatestTargetProgramOptions.items.length <= 1) {
        error([GetMessageWithIntl(intl, { id: 'E0000063' })]);
        return;
      }

      //選択された最新のProgramOption
      const selectedLatestProgramOption =
        filteredLatestTargetProgramOptions.items.filter((item) => {
          return (
            item.programOptionId?.includes(
              selectedProgramOption.programOptionId
            ) && item.userEditable
          );
        });

      //選択されたものと最新を比較して編集不可に変更されている場合はエラーを表示して続行不可
      if (selectedLatestProgramOption.length === 0) {
        error([GetMessageWithIntl(intl, { id: 'E0000064' })]);
        return;
      }

      const res =
        await window.App.services.programOptionService.deleteProgramOption({
          programOption: selectedProgramOption,
        });
      if (res) {
        window.App.programoptions = await getProgramOptions();
        success([successMessage]);
        setMenuModalIsOpen(false);
        setReload(true);
      }
    } catch (err) {
      console.error('err', err);
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // 確認
    menuItems.push({
      name: 'confirm',
      func: (v?: string[]) => handleMenuConfirm(v),
    });

    // 名称マスタ取込
    menuItems.push({
      name: 'import',
      func: () => handleMenuImport(),
    });

    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        const programOptionItems = masterProgramOptionList.current?.filter(
          (item) => v?.includes(item.programOptionId!)
        );
        setConfirmationDialogInfo({
          id: 'deleteDialogTitle',
          msg: deleteMessage,
          func: () => {
            programOptionItems?.forEach((item) => {
              deleteAction(item ?? []);
            });
          },
        });
        setMenuModalIsOpen(true);
      },
    });

    // 編集
    menuItems.push({
      name: 'edit',
      func: (v?: string[]) => handleMenuEdit(v),
    });

    // 複写追加
    menuItems.push({
      name: 'copy',
      func: (v?: string[]) => handleMenuCopy(v),
    });
    return menuItems;
  };

  const menuViewlist = burgerMenuEvent().map((v) => v.name);
  return (
    <>
      <Container viewId={VIEW_ID}>
        <MenuListView
          headerTitle={{ viewId: VIEW_ID }}
          isReload={isReload}
          fullMethodName={FullMethodName_ListProgramOptions}
          schema={schema}
          preset={preset}
          presetItems={childrenPresetItem}
          menuEvent={burgerMenuEvent()}
          listSkipType={{
            isTotal: true,
            isOutput: true,
          }}
          menuTarget={'programOptionId'}
          menuViewlist={menuViewlist}
          importDialogOption={{
            isDisplay: true,
            isDialogOpen,
            dialogPreset,
            headerLabelId: {
              viewId: VIEW_ID,
              id: 'import',
            },
            handleExport,
            handleImport,
            onChangeState: setIsDialogOpen,
          }}
          onOriginalItemState={(items) =>
            (masterProgramOptionList.current =
              items as unknown as ProgramOption[])
          }
        />
        <ConfirmationDialog
          isOpen={menuModalIsOpen}
          viewMessage={confirmationDialogInfo.msg}
          messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
          onDecision={() => {
            confirmationDialogInfo.func();
          }}
          onCancel={() => setMenuModalIsOpen(false)}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
