/**
 * API 由来の型を UI 向けの型に変換する処理
 */

import { FilterboxItem } from '~/shared/components/ui';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  CategorizedBranchData,
  CategorizedList,
  CategorizedTipData,
} from '~/shared/components/ui/CategorizedCheckList';
import { checkEmptyObject } from '../util';

// APIから得たデータ（StaffReference型）をFilterboxItem型に変換
export function convertStaffReferenceToFilterboxItem(
  staffReference: sharelib.IStaffReference | null | undefined
): FilterboxItem | undefined {
  if (checkEmptyObject(staffReference)) {
    return undefined;
  }

  return {
    value: staffReference!.staffId ?? '',
    displayName: staffReference!.displayNameLang?.ja ?? '',
  };
}

// APIから得たデータ（ComponentUnitReference型）をFilterboxItem型に変換
export function convertComponentUnitReferenceToFilterboxItem(
  componentUnitReference: sharelib.IComponentUnitReference | null | undefined
): FilterboxItem | undefined {
  if (checkEmptyObject(componentUnitReference)) {
    return undefined;
  }

  return {
    value: componentUnitReference!.componentUnitId ?? '',
    displayName: componentUnitReference!.displayNameLang?.ja ?? '',
  };
}

// componentUnitをfilterboxに変換
export function convertComponentFilterboxItems(
  items: mtechnavi.api.company.IComponentUnit[]
) {
  return items
    .map((v) => {
      if (v.componentUnitId !== '' || (v.displayNameLang?.ja ?? '') !== '') {
        return {
          value: v.componentUnitId,
          displayName: v.displayNameLang?.ja,
        };
      }
    })
    .filter((v) => !!v) as FilterboxItem[];
}

export function getProgramOptionFilterboxData(categoryName: string) {
  const programOptionRes = window.App.services.ui.getProgramOption(
    categoryName
  ) as mtechnavi.api.programoption.IProgramOption[];

  return programOptionRes.map((v) => ({
    displayName: v.displayNameLang?.ja ?? '',
    value: v.code ?? '',
  }));
}

// NameOptionからFilterboxItemを返す
export function convertNameOptionToFilterboxItem(
  nameOption: sharelib.INameOption | null | undefined
): FilterboxItem | undefined {
  if (checkEmptyObject(nameOption)) {
    return undefined;
  }

  return {
    value: nameOption!.code ?? '',
    displayName: nameOption!.displayNameLang?.ja ?? '',
  };
}

// programOptionをDataFilterboxで利用
export function getProgramOptionFilterboxDataWithSystemName(
  categoryName: string,
  systemName: string
) {
  const programOptionRes =
    window.App.services.ui.getProgramOptionWithSystemName(
      categoryName,
      systemName
    ) as mtechnavi.api.programoption.IProgramOption[];

  return programOptionRes.map((v) => ({
    displayName: v.displayNameLang?.ja ?? '',
    value: v.code ?? '',
  }));
}

/**
 * BE からのデータを CategorizedCheckList で扱うデータに変換する。
 * カテゴリに識別可能な ID を割り振る。
 */
type SkillTree = mtechnavi.api.company.IBusinessUnitSkillTree;
export type SkillCategoryData = Omit<
  mtechnavi.api.company.IBusinessUnitSkillItem,
  'businessUnitSkillItemId'
>;
export type SkillTipData = Omit<
  mtechnavi.api.company.IBusinessUnitSkillItem,
  'children'
>;
export const convertSkillTreeToCategorizedList = (
  listData: SkillTree
): CategorizedList => {
  return (
    listData.skillItems?.map((category, index): CategorizedBranchData => {
      const id = `category-${index}`;
      return {
        CategorizedItemId: id,
        displayNameLang: category.displayNameLang,
        children: convertSkillTreeChildren(category.children || [], id),
      };
    }) || []
  );
};
const convertSkillTreeChildren = (
  listData: SkillCategoryData[] | SkillTipData[],
  parentId: string
): CategorizedBranchData[] | CategorizedTipData[] => {
  if (isSkillCategoryDataList(listData)) {
    return listData.map((item, index): CategorizedBranchData => {
      const id = `${parentId}-${index}`;
      return {
        CategorizedItemId: id,
        displayNameLang: item.displayNameLang,
        children: convertSkillTreeChildren(item?.children || [], id),
      };
    });
  } else {
    return listData.map(
      (item): CategorizedTipData => ({
        CategorizedItemId: item.businessUnitSkillItemId || '',
        displayNameLang: item.displayNameLang,
      })
    );
  }
};
export const isSkillCategoryDataList = (
  list: unknown[]
): list is SkillCategoryData[] => {
  return list.every(
    (item) =>
      !Object.hasOwn(
        item as SkillCategoryData | SkillTipData,
        'businessUnitSkillItemId'
      )
  );
};

export const replaceProhibitedCharacters = (str: string) => {
  return str.replace(/[¥/:"?<>|\\]/g, '_');
};
